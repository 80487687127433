import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { BugOutlined, CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import { searchUsers } from "../../../store/httpCalls";
import SearchDropdown from "../../../components/CustomDropdown/SearchDropdown";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

const BillPaymentWebForm = ({ showModal,setCategories, categories, hideModal, title, wallets , userId,className}) => {
    let d = new Date();
    let ref = d.getTime();
  const initialState = {
    tranCrncy: "NGN",
    eventId: "COMPAYM",
    transactionCategory: "COMMISSION",
    userId: userId,
    fullName:"",
    amount:"",
    emailOrPhoneNo:'',
    recipientName: "",
    customerDebitAccountNo:"",
    paymentReference: `WAYA${ref}`,
    tranNarration: "bulk waya web transfer",
    cat: '',
    billerList: [],
    billerInfo: [],
    billerItems: [],
    plan: '',
    accountNo: '',
    phone: '',
    walletOp: [],
  };





  const [data, setData] = useState(initialState);
  const [transactions, setTransactions] = useState([{ ...initialState }]);
  const [transactions1, setTransactions1] = useState([]);

  const [selectedWallet, setSelectedWallet] = useState({});
  const [options, setOptions] = useState([]);
  const [walletList, setWalletList] = useState([]);
  const [query, setQuery] = useState('');
  
  
  const [page, setPage] = useState(1);
  const clearState = () => {
    setData({ ...initialState });
    setWalletList([]);
  };
  useEffect(() => {
    console.log('cta',categories);
  }, [selectedWallet]);

  useEffect(()=>{
    
  },[data])

  const handleChange = (event, value) => {
    const isEmpty = checkProperties(data);
    if (isEmpty) {
      Swal.fire("Warning", "Please fill all the fields", "warning");
    } else {
      console.log(data);
      event.preventDefault();

      setTransactions((prevState) => [data, ...prevState]);
      setPage(transactions.length + 1);
      setQuery('');
      clearState();
    }
  };
  const handlePaginateChange = (event, pageV) => {
    const currentIndex = pageV - 1;
    setPage(pageV);
    setData(transactions.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(transactions);
  };


  const getBillersByCategories = async () => {
    showLoader();
    let  res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${data.cat}`)
    
    if (res?.status) {
      hideLoader();
      setData({...data, billerList:res?.data});
      console.log(res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };

  const getBillerItems = async () => {
    showLoader();   
     let res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${data.billerInfo.categoryId}/biller/${data.billerInfo.billerId}`)
     if (res?.status) {
      hideLoader();
      setData({...data, billerItems:res?.data?.items});
      console.log('biller materials',res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };



useEffect(()=>{
  getBillersByCategories()
  getBillerItems()
},[data.cat, data.billerInfo])

  useEffect(() => {
    console.log(transactions);
    let dataRec = transactions;
    dataRec[page - 1] = data;
    setTransactions(dataRec);

  }, [data]);

  const handleRemoveUser = async () => {
    let current = page === 1 ? 1 : page - 1;
    await setTransactions((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...initialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };

  const handleTransaction = async () => {
    let newtranData =[]
    for (let i=0;i<transactions.length;i++){

      newtranData.push({
        "amount": transactions[i].amount,
        "billerId": transactions[i].billerInfo.billerId,
        "categoryId": transactions[i].billerInfo.categoryId,
        "data": [
          {
            "name": "phone",
            "value": transactions[i].phone
          },
          {
            "name": "amount",
            "value": transactions[i].amount
          },
          {
            "name": "total_amount",
            "value": transactions[i].amount
          },
          {
            "name": "account_number",
            "value": transactions[i].accountNo
          },
          {
            "name": "smartcard_number",
            "value": transactions[i].accountNo
          },
          {
            "name": "plan",
            "value": transactions[i].plan
          },
          {
            "name": "pinValue",
            "value": transactions[i].plan
          },
          {
            "name": "bundles",
            "value": transactions[i].plan
          },
          {
            "name": "account_number",
            "value": transactions[i].accountNo
          },
          {
            "name": "numberOfPins",
            "value": transactions[i].accountNo
          }
        ],
        "sourceWalletAccountNumber": transactions[i].customerDebitAccountNo,
        "username": transactions[i].selectedUserId

      })

    }


    showLoader();
    /*
    const response = await httpPost(
      `${customBaseUrl.commissionUrl}/api/v1/transfer/multiple-user-commission`,
      transactions
    );*/
      console.log('new Tra',newtranData)
    const response = await httpPost(
        `${customBaseUrl.billPaymentUrl}/api/v1/admin/bulk-user-bills-payment`,
        newtranData
        );
        
    if (response.status) {
      console.log(response)
      hideLoader();
      Swal.fire('Done', response.message, 'success');
      hideModal(false)
    } else {
      console.log(response)
      hideLoader();
      Swal.fire('Error', response.message, 'error');
    }
  };



  const handleSelectOption = async (selectedUser) => {
    let fullName= `${selectedUser.firstName} ${selectedUser.surname}`
    setData({...data,  fullName});
    setWalletList([]);
    await getWallets(selectedUser.userId,fullName);

    setQuery(fullName);
   /* setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);*/
    setOptions([]);
  };


  const handleSearch = async (e) => {
    setQuery(e.target.value);
    setData({...data, fullName: e.target.value})
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }

  const getWallets = async (userId, fullName) => {
    setData({...data, selectedUserId: userId})
   //  return alert(fullName);
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}`
      );
     // alert(JSON.stringify(res));
      if (res?.status) {
        setWalletList(res.data);
       setData({...data, walletOp: res.data, selectedUserId: userId,  fullName});
      }
    };
  

    const  accountType = { 
      betting: 'betting',
      electricity: 'Meter no',
      cabletv: 'Smartcard no',
      airtime: 'Phone no',
      vehiclepaper: 'Vehicle paper',
      insurance: "General Insurance"
       }
    

  return (
    <Modal
    className={className}
    centered
    visible={showModal}
    onOk={() => hideModal(false)}
    onCancel={() => hideModal(false)}
    width={800}
    footer={0}
    closable={false}
    title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >


        
      <Wrapper class='row g-3 needs-validation border mb-2 p-3'>
      <div class='contentBar-items m-2'>
     

          <label for='validationCustom01' class='form-label'>
            Payment type
          </label>
          
          <select 
        class='form-select border'   
         value={data.cat} onChange={(e)=>{setData({...data, cat:e.target.value})}}>
            <option>Select Category</option>       
            {categories?.map((item,index)=>
             <option  value={item.categoryId} >
               {item.categoryName}
             </option>
            )}

          </select>       
          
        </div>
       

        <div class='contentBar-items m-2'>
          
        <label for='validationCustom01' class='form-label'>
            Vendor
          </label>
          <select 
            class='form-select border'
            id='validationCustom06'
            required
          value={JSON.stringify(data.billerInfo)} onChange={(e)=>{ setData({...data, billerInfo:JSON.parse(e.target.value)})}}>
          <option value="">select vendor</option>
          {data.billerList.map((biller, index) => (
            <option key={index} value={JSON.stringify(biller)}>
              {biller?.billerName}
            </option>
          ))}
        </select>
        </div>



        <div className="contentBar-items m-2">
          {data.billerItems?.map((item, index) => (
            <div key={index} className="contentBar-item">
              {item?.subItems.length>0 && 
                (<div key={index} className="contentBar-item-subItem">
                  <div className="contentBar-item-subItem-name">{item?.paramName}</div>
                  <select
                   class='form-select border'
                   id='validationCustom06'
                   value={data.plan}
                  onChange={(e)=>{ setData({...data, plan:e.target.value})}}>
                    <option value="">Select</option>
                    {item.subItems.map((subItem, index) => (
                    <option value={subItem.id}>{`${subItem.name}  - ${subItem?.amount?'N':''}${subItem?.amount?subItem?.amount:''}`}</option>
                    ))}
                  </select>
                  </div>)} 
              </div>))}
        </div>

        <div class='contentBar-items m-2'>
        <label for='validationCustom07' class='form-label'>
            Phone
          </label>
        <input 
         class='form-control'
        type="text"  value={data.phone}  onChange={(e)=>setData({...data, phone:e.target.value})}/>
        </div>
        {data.cat!=='airtime' && data.cat !== 'databundle' && 
        
        <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
        {accountType[data.cat]}
        </label>
        <input
        class='form-control'
        type="text"  value={data.accountNo}  onChange={(e)=>setData({...data, accountNo:e.target.value})}/>
       </div>}
       <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
          Amount
          </label>
        <input
        class='form-control'
        type="text"  value={data.amount}  onChange={(e)=>setData({...data, amount:e.target.value})}/>
       </div>
     
        <div style={{ position: 'relative' }} class="col-md-6">
          <label for='validationCustom01' class='form-label'>
            Sender's Name {data.selectedUserId}
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            required
            onChange={handleSearch}
            value={data.fullName}
          />
                    {//}  <Button onClick={()=>{setQuery('');setData({...data, fullName:''})}}>x</Button>
             }
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>         
          
         
         <div class='col-md-6'>
          <label for='validationCustom06' class='form-label'>
            Select sender's wallet to perform this transaction
          </label>

            <select

            onChange={(e) =>{ console.log('userId',e.target.value); setData({ ...data, customerDebitAccountNo: JSON.parse(e.target.value).accountNo })}}
             className='border border-dark rounded form-select'
             value={data.customerDebitAccountNo?JSON.stringify(data.walletOp?.filter((item) =>item.accountNo === data.customerDebitAccountNo)[0]):""}
            >
              <option value='' >select wallet</option>
              {data?.walletOp.map((option) => (
              <option key={option.accountNo} value={JSON.stringify(option)}>
                {option.acct_name +' - '+option.accountNo}
              </option>
            ))
              }             


            </select>

          <div class='invalid-feedback'>Wallet account is required.</div>
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleTransaction}
        >
          Proceed
        </Button> */}
      </div>
      <div style={{ marginTop: "10px" }} className="row">
        <div
          style={{
            
           
          }}
          className="col-md-2"
        >
          <Button
            className="iconUI"
            style={{ color: "#C4C4C4", border: "1px solid #C4C4C4" }}
            onClick={handleChange}
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <Button
            className="iconUI"
            onClick={handleRemoveUser}
            style={{ color: "#FF4B01", border: "1px solid #FF4B01" }}
            icon={<CloseOutlined />}
          >
            Remove 
          </Button>
        </div>
        <div className="col-md-6">
          <Button
            className="iconUI"
            type="ghost"
            onClick={handleTransaction}
            style={{ color: "#fff", background: "#FF4B01", float: "right" }}
          >
            Proceed
          </Button>
        </div>
      </div>

      <div className="row" style={{ marginTop: "7px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Stack spacing={2}>
            <Pagination
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                alignSelf: "center",
              }}
              count={transactions.length}
              page={page}
              onChange={handlePaginateChange}
            />
          </Stack>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Modal>
  );
};

export default BillPaymentWebForm;
