import React, {useState} from 'react';
import OtpInput from 'react-otp-input';
import Wrapper from './style';

const Summary = ({ displayData, handleSubmit, otp, setOtp, pin, setPin, setViewReject, handleContinue }) => {
  const { receiverName, amount, senderName, tranFees, bank, reason, reference } =
    displayData;
  const [action, setAction] = useState(false);
  const [viewPin, setViewPin] = useState(false);
  const [otpError, setOtpError] = useState(false);


  const handleSettle = async () => {
    setViewPin(false); 
    setAction('settle')
    handleContinue()
  }
  return (
    <Wrapper>
      <div className='title'>Summary</div>
      <div className='content'>
      <div className='c-item'>
            <div className='c-title'>Reference Code</div>
            <div className='c-det'>{reference || ''}</div>
          </div>
        <div className='ci-cont'>
          <div className='c-item'>
            <div className='c-title'>Sending to</div>
            <div className='c-det'>{receiverName || ''}</div>
          </div>
          <div className='c-item'>
            <div className='c-title'>Amount</div>
            <div className='c-det'>NGN {amount || 0}.00</div>
          </div>
        </div>
        <div className='ci-cont'>
          <div className='c-item'>
            <div className='c-title'>From</div>
            <div className='c-det'>{senderName || ''}</div>
          </div>
          <div className='c-item'>
            {tranFees?<div>
            <div className='c-title'>Transaction Fees</div>
            <div className='c-det'>NGN {tranFees }.00</div>
            </div>:''}
          </div>
        </div>
        <div
          className='ci-cont'
          style={{
            marginBottom: '0px',
          }}
        >
          <div className='c-item'>
            <div className='c-title'>Beneficiary Bank</div>
            <div className='c-det'>{bank || 'WAYA Bank'}</div>
          </div>
          <div className='c-item'>
            <div className='c-title'>Description </div>
            <div style={{width:100}}>{reason || ''}</div>
          </div>
        </div>
      </div>
          {viewPin &&<div>
      <div className='otp-info' style={{alignItems:'center'}}>
        Please input your 4 digit pin to continue transaction
      </div>

      <OtpInput
        containerStyle='otp-cont'
        inputStyle='otp-input'
        value={pin}
        onChange={(value) => setPin(value)}
        numInputs={4}
        isInputSecure={true}
        // separator={<span> {`  -  `} </span>}
      />

      <div style={{marginLeft:'45%'}}>
        <button className='btn btn-danger' onClick={handleSettle}>Continue</button>
      </div>
      </div>}
      {action === 'settle' &&<div>
      <div className='otp-info'>
        Please input your 6 digit pin to complete transaction
      </div>

      <OtpInput
        containerStyle='otp-cont'
        inputStyle='otp-input'
        value={otp}
        onChange={(value) => setOtp(value)}
        numInputs={6}
        // separator={<span> {`  -  `} </span>}
      />

      <div style={{marginLeft:'45%'}}>
        <button className='btn btn-danger' onClick={() => handleSubmit()}>Confirm</button>
      </div>
      </div>}
      {action =='' && !viewPin && <div style={{display:'flex', flexDirection:'row', alignItems:'center', margin:30, justifyContent:'space-between', width:'100%'}}>
      <div className='sum-bot'>
        <button className=' btn btn-danger' onClick={() =>setViewReject(true) }>Reject Request</button>
      </div>
      <div className='sum-bot'>
        <button className='btn btn-primary' onClick={() => setViewPin(true)}>Settle Payment</button>
      </div>
      </div>}
    </Wrapper>
  );
};

export default Summary;
