import React, { useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import moment from "moment";
import Swal from "sweetalert2";

const initial = {
  transactionType: 'credit',
  userType: 'corporate',
  amount: 0
}
const SMUsersTransactions = ({ showModal, hideModal, dataList, file, accountNo}) => {
  const [data, setData] = useState(initial);
  const [filteredData, setFilteredData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [pin, setPin] = useState('')

const handleTransaction = async (e) => {
  e.preventDefault();
  showLoader();
  const response = await httpPost(
    `${customBaseUrl.smWalletUrl}/api/v1/wallet/sendmoney/transaction-simulated`,
    data
  );
  if (response.status === true) {
    hideLoader();
    Swal.fire('Done', response?.message)
    //NotificationManager.success(response?.message)
    //setFiltered(response.data.content)   
    hideModal(false);
  } else {
    NotificationManager.error(response?.message)
    Swal.fire('Done', response?.message)
    hideLoader();
  }
};

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <form onSubmit={handleTransaction}>
        <div className="title">PERFORM TRANSACTION FOR SM USERS</div>
        <div className="cw-content">
          <div className="input-content border-dark">
            <div className="label">TRANSACTION TYPE</div>
            <select required value={data?.transactionType}
              onChange={(e)=>setData({...data, transactionType: e.target.value })}
            name="" id="" className="border border-dark">
              <option value="credit">CREDIT</option>
              <option value="debit">DEBIT</option>
            </select>
          </div>
          <div className="input-content border-dark">
            <div className="label">USER TYPE</div>
            <select 
            value={data?.userType}
             onChange={(e)=>setData({...data, userType: e.target.value })}
            required name="" id="" className="border border-dark">
              <option value="corporate">BUSINESS</option>
              <option value="user">INDIVIDUAL</option>

            </select>
          </div>
          <div className="input-content border-dark">
            <div className="label">AMOUNT</div>
            <input 
              onChange={(e)=>setData({...data, amount: e.target.value })}
            value ={data?.amount}
            type="number" min={0} className="form-input" required/>
          </div>
          <div className="input-content border-dark">
            <div className="label">NUMBER OF SM USERS to {data.transactionType.toUpperCase()}</div>
            <input 
             onChange={(e)=>setData({...data, numberOfUsersTransaction: e.target.value })}
             value={data?.numberOfUsersTransaction}
            type='number' min={0} className="form-input" required/>
          </div>
          {/*<div className="input-content border-dark">
            <div className="label">TRANSACTION PIN</div>
            <input 
              onChange={(e)=>setPin(e.target.value)}
              value={pin ?? ''} type='password' className="form-input" required/>
          </div> */}
        
        </div>
        <div className="text-center">
            <button className="btn"
                style={{
                    marginTop: '50px',
                    marginLeft: "auto",
                    marginRight: "auto",
                    background: "#FF4B01",
                    color: "#fff",
                    borderRadius: "3px",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 25px",
                  }}
            >
                PROCEED
            </button>
        </div>
        </form>
      </Wrapper>
    </Modal>
  );
};

export default SMUsersTransactions;
