import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpPostFormData, httpPut } from '../../../store/http';
import { NotificationManager } from 'react-notifications';

const CreateDispute = ({ showModal, hideModal, getDispute, status, disputeTypes}) => {

    const [view, setView] = useState('default')
    const [refundAmount, setRefundAmount] = useState('')
    const [accept, setAccept] = useState(true)
    const [action, setAction] = useState('OPENED')
    const [adminId, setAdminId] = useState('');
    const [postData, setPostData] = useState({})

  const handleSubmit = async () => {
    console.log('post Data', postData);
    if (!postData?.categoryId || !postData?.disputeDescription || !postData?.disputeSubject || !postData?.disputeType || !postData?.file || !postData?.transactionId || !postData?.userId ) return    NotificationManager.error('All fields must be filled');

    showLoader();
    const formData = new FormData();
    formData.append('categoryId', postData?.categoryId);
    formData.append('disputeDescription', postData?.disputeDescription);
    formData.append('disputeSubject', postData?.disputeSubject);
    formData.append('disputeType', postData?.disputeType);
    formData.append('transactionAmount', postData?.transactionAmount);
    formData.append('file', postData?.file);
    formData.append('transactionId', postData?.transactionId);
    formData.append('userId', postData?.userId);

    // const url = `${customBaseUrl.wayapayDispute}/dispute`;
    const res = await httpPostFormData(`${customBaseUrl.wayapayDispute}/dispute-extended/${postData.categoryId}`,
      formData, );
    hideLoader();
    if (res.status) {
      Swal.fire('Success', res.message)
      hideModal(false)
      getDispute();
    }else{
        Swal.fire('Done', res?.message)
    }
    console.log('Dispute ticket', res);
  };



  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      title="Add Dispute"
      onCancel={() => {
        hideModal(false);
      }}
      width='30%'
    >
      <Wrapper>
        <div className="cw-content">
        <div className="pg-1-1">
          <div className="pg-1-input">
            <select
            required
              id=""
              onChange={(e) => {
                const { category } = JSON.parse(e.target.value);
                setPostData({
                  ...postData,
                  disputeType: category,
                  categoryId: JSON.parse(e.target.value).id,
                });
              }}
            >
              <option>Select Dispute Type</option>
              {disputeTypes.length>0 && disputeTypes?.map((el) => (
                <option key={el} value={JSON.stringify(el)}>
                  {el.category}
                </option>
              ))}
            </select>
          </div>
        </div>
        {postData.disputeType === 'Payment Dispute' && (
          <div className="pg-1-1">
            <div className="">Dispute Amount</div>
            <div
             className='input-content'
             >
              <input
              className='input-content'
                type="number"
                value={postData.transactionAmount}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    transactionAmount: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="pg-1-1">
          <div className="">Dispute Subject</div>
          <div className="input-content">
            <input
            required
             className='input-content'
              type="text"
              value={postData.disputeSubject}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  disputeSubject: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="bold">Dispute Description</div>
          <div className="input-content">
          <textarea
          required
           value={postData.disputeDescription}
           onChange={(e) =>
             setPostData({
               ...postData,
               disputeDescription: e.target.value,
             })
            }
           cols="40"
           rows="5"/>
           
          </div>
        </div>
        <div className="pg-1-1">
          <div className="">Transaction ID</div>
          <div className="input-content">
            <input
              type="text"
              required
              value={postData.transactionId}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  transactionId: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="pg-1-1">
          <div className="">Attach File</div>
          <div className="input-content">
            <input
            required
              type="File"
              onChange={(e) =>
                setPostData({
                  ...postData,
                  file: e.target.files[0],
                })
              }
            />
          </div>
        </div>
        
        <div className="pg-1-1">
          <div className="">User Id</div>
          <div className="input-content">
            <input
             className='input-content'
              type="text"
              value={postData.userId}
              onChange={(e) =>
                setPostData({
                  ...postData,
                  userId: e.target.value,
                })
              }
              required
            />
          </div>
          </div>
        
          <button type='submit' onClick={handleSubmit} className='btn btn-danger'
          >
            Submit
        </button>
        </div>
      </Wrapper>

     
    </Modal>
  );
};

export default CreateDispute;
