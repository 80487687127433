import { ArrowLeftOutlined, MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import _ from "lodash";
import {
  List,
  Typography,
  Divider,
  Card,
  Row,
  Col,
  Menu,
  Space,
  Button,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../utils/loader";
import { ListItemText } from "@mui/material";
import useStyles from "./style";
import { getArraryString, transformName } from "../../utils/helper";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import CreateCustomRole from "./modals/CreateCustomRole";
import Swal from "sweetalert2";
import { ProfileContext } from "../../store/context/ProfileContext";
import UpdateRole from "./modals/updateRole";
import NewUnit from "./modals/NewUnit";
import UpdateMenu from "./modals/updateMenu";
import { Edit } from "@mui/icons-material";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
const ManageMenu = ({setShowMenu, permMenu, defMenu, getMenus, getMenusPerm}) => {
  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);

  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [roleUsers, setRoleUsers] = useState([]);
  const [rolePrivileges, setRolePrivileges] = useState([]);
  const [roleCannotList, setRoleCannotList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [totalPrivillages, setTotalPrivillages] = useState(0)
  const [showUpdateRole, setShowUpdateRole] = useState(false)
  const [showUnitModal, setShowUnitModal] = useState(false)
  const [showUpdateMenu, setShowUpdateMenu] =useState(false)
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [menus, setMenus] = useState([])
  const [roleData, setRoleData] = useState({
    privileges: [],
    name: "",
    description: "",
    userId: profile.userId
  }); //privileges: [], name: ""
  const [menuData, setMenuData] = useState([])
  const [menuId, setMenuId] = useState(null);
  const [roleUpdateData, setRoleUpdateData] = useState({
    privileges: [],
    roleId: selectedRole.id
  }); 
  const [pagination, setPagination] = useState({ current: 0, pageSize: 20 });

  // integrate the endpoint to create a custom role
  const handleSubmitCreateRole = async () => {
    if (roleData.name === "") {
      Swal.fire({
        title: "Oops...",
        text: "Please enter a role name",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    if (roleData.privileges.length === 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please select at least one privilege",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    if (roleData.description === "") {
      Swal.fire({
        title: "Oops...",
        text: "Please enter a role description",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/manage-user/create-custom-role`,
      roleData
    );
    if (response.status == true) {
      hideLoader()
      Swal.fire({
        title: "Success!",
        text: "Role created successfully",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        hideLoader()
        setShowUpdateRole(false)
      });
    } else {
      Swal.fire({
        title: "Oops...",
        text: response.message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      hideLoader()

    }
    hideLoader()

  };


  const handleUpdateUnit = async () => {
    console.log('menu', menuData)

    if (menuData.length === 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please select at least one privilege",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    /*if (roleData.description === "") {
      Swal.fire({
        title: "Oops...",
        text: "Please enter a role description",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }*/
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.roleUrl}/api/v1/menu/${selectedMenu?.id}/permission/${menuData?.id}/create`
    );
    if (response.status == true) {
      hideLoader()
      Swal.fire({
        title: "Success!",
        text: response?.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        hideLoader()
        getMenusPerm();
        setShowUpdateMenu(false)
      });
    } else {
      Swal.fire({
        title: "Oops...",
        text: response.message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      hideLoader()

    }
    hideLoader()

  };

  const updateRole = async () => {
    if (roleUpdateData.privileges.length === 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please select at least one privilege",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
   
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/roles-privileges/assign-role-privilege?roleId=${selectedRole.id}`,
      roleUpdateData.privileges
    );
    if (response.statusCode == 'OK') {
      Swal.fire({
        title: "Success!",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        setShowUpdateRole(false)
      });
      getRoles()
    } else {
      hideLoader()
      Swal.fire({
        title: "Oops...",
        text: response.message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
    hideLoader()
  };

  
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    let pages = Math.floor(totalPrivillages/pagination.pageSize)
    if(newPage==='next' && pagination.current<pages){
      setPagination({ ...pagination, current: pagination.current+1 });
      await getPreviledges(pagination.current+1, pagination.pageSize);


    }else if(newPage==='back' && pagination.current>0) {
      setPagination({ ...pagination, current: pagination.current-1 });
      await getPreviledges(pagination.current-1, pagination.pageSize);

    }

  };

  const getPreviledges = async (page, size) => {
    showLoader();
    const pages =  page? page: page===0?0: pagination.current;
    const limit = size ? size : pagination.pageSize;
    let response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/privileges/all-privileges?page=${pages}&size=${limit}`
    );
    let body = response.body.data;
    setPrivileges(body.privileges);
    setTotalPrivillages(body?.totalItems)
      hideLoader();
  };
  

  useEffect(() => {
    getPreviledges(0,20);
    //getMenus()
  }, []);

  const getPermission = (menu) => {
    let perm = []
   let permission =  permMenu.filter(e=>e?.menus?.name === menu)
   console.log('menuuu permission', permission);
   // et allPrivileges = roles.map((x) => x.privileges);
   //  let allPrivilegesFlat = _.flatten(allPrivileges);
    // allPrivileges = mergeDistinctArrayObjects(allPrivilegesFlat);
    setRolePrivileges(
     permission
    );
  };
  const handleSetSelectedRolePrivileges = (role) => {
    setSelectedRole(role);
    //setRolePrivileges(role.privileges);
    getPermission(role?.name);
  };

  useEffect(() => {
    console.log(roleCannotList);
  }, [roleCannotList]);

  const mergeDistinctArrayObjects = (array) => {
    var merged = _.merge(_.keyBy(array, "id"), _.keyBy(array, "id"));
    return _.values(merged);
  };

  const getRoles = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/roles/roles-with-privileges`
    );
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setRoles(response);
    handleSetSelectedRolePrivileges(response[0]);
  };

  const getUsersByRole = async (roleId) => {
    showLoader();
    const response = await httpGet(`/api/v1/admin/users/role/${roleId}`);
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setRoleUsers(response);
  };
  useEffect(() => {
    if(!selectedRole){
    handleSetSelectedRolePrivileges(defMenu[0]);
    }else{
        handleSetSelectedRolePrivileges(selectedRole)
    }
  }, [permMenu]);
  const dropDownOptions = ["Delete", "Add Permission"];
  const handleDropdownAction = (item, menu) => {
    if (item === "Delete") handleDelete(menu?.id);
    if (item === "Add Permission"){
        setShowUpdateMenu(true);
        setSelectedMenu(menu);
    } 
  };


  const handleDelete = async (id) => {
    showLoader();
    const response = await httpDelete(`${customBaseUrl?.roleUrl}/api/v1/menu/delete/${id}`);
    hideLoader();
    if (response.status){
        hideLoader();
        Swal.fire('Done', response?.message)
        getMenus();
    } else {
        hideLoader();
        Swal.fire('Error', response?.message)
    }
    console.log('del', id);
  }
  return (
    <>
      <div >
        <div style={{display:'flex', flexDirection: 'row'}}>
        <div
          className=""
          style={{ cursor: "pointer" }}
          onClick={() => setShowMenu(false)}
        >
          <span className="me-1">
            <ArrowLeftOutlined />
          </span>
          back
        </div>
        <div style={{float:'right', marginRight: 0, marginLeft: 'auto'}}>
          <button className="btn btn-primary" style={{marginRight: 5}} onClick={()=>setShowUnitModal(true)}>
          <PlusCircleOutlined className={classes.buttonIcon} />

            Add new Menu
          </button>
          {/*<button className="btn btn-primary" onClick={() => setShowUpdateMenu(true)}>
          <PlusCircleOutlined className={classes.buttonIcon} />
            Add Permission to Menu
          </button>
            */}
          </div>
        </div>
        <br />
        <div style={{ marginTop: "10px" }}>
          <Row>
            <Col flex={"200px"}>
              <Divider orientation="left">Menus</Divider>
              <Menu
                theme="light"
                mode="vertical"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
              >
                {defMenu.map((menu) => {
                  return (
                    <Menu.Item
                      key={menu.id}
                      onClick={() => handleSetSelectedRolePrivileges(menu)}
                    >
                        <div>
                      {transformName(getArraryString([menu?.name]))}
                      <div style={{float: 'right'}}>
                      <AntDropdown
                        dropDownOptions={dropDownOptions}
                        handleDropdownAction={(item) => handleDropdownAction(item, menu)}
                        isArrow
                    />
                      </div>
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
              <Divider orientation="left"/>
              {/*<Menu
                theme="light"
                mode="vertical"
                style={{ border: "0" }}
                
              >
                
                <Menu.Item>
                <Button
                className={classes.addCustomRoleButton}
                size="middle"
                style={{ color: "#828282", width: "100%" }}
                onClick={()=>setShowUnitModal(true)}
                icon={<PlusCircleOutlined className={classes.buttonIcon} />}
                ghost
              >
                Add Menu
              </Button>
                </Menu.Item>             
               
              </Menu>
              {/* <Divider dashed={true} /> */}
            
            </Col>
            <Col flex={"70%"}>
              <Divider orientation="left">
                <ListItemText
                  primaryTypographyProps={{ align: "left", variant: "h5" }}
                  primary={transformName(
                    getArraryString([selectedRole.name])
                  )}
                  secondary="This menu has the privileges listed below."
                ></ListItemText>
              </Divider>

              <List
                style={{ marginTop: "0px" }}
                header={
                  <div style={{ color: "#828282" }}>
                    {/* <b>10 Members with this role</b>: Adeyanju Akorede, Philip
                    Templar, Adeyanju Gabriel, Clever Eziogor, Samuel Aniefiok,
                    John Mary, Adeyanju Akorede, Philip Templar, Adeyanju
                    Gabriel, Clever Eziogor, John Mary */}
                  </div>
                }
                bordered
                dataSource={[{}]}
                renderItem={(itemGr) => (
                  <Row>
            <Col flex={"100%"}>
                      <List
                        bordered
                        style={{ marginTop: "0px" }}
                        dataSource={rolePrivileges}
                       
                        renderItem={(item) => (
                          <>
                          <List.Item> {transformName(getArraryString([item?.permission?.description]))}</List.Item>
                          </>
                        )}
                      ></List>
                      {/*<button>
                      <PlusCircleOutlined onClick={()=>setShowUpdateRole(true)}/>
                        Add Permission
                      </button>

                   {/*} </Col>
                    <Col span={10}>
                      <List
                        bordered
                        style={{ marginLeft: "10px" }}
                        dataSource={roleCannotList}
                        header={
                          <div style={{ color: "#C67777", fontWeight: "500" }}>
                            {transformName(
                              getArraryString([selectedRole.description])
                            )}{" "}
                            Cannot
                          </div>
                        }
                        renderItem={(item) => (
                          <List.Item> {transformName(getArraryString([item?.permission?.description]))}</List.Item>
                        )}
                      >
                        </List>*/}
                    </Col>
                  </Row>
                )}
              />
            </Col>
          </Row>
        </div>
      </div>
      {showModal ? (
        <CreateCustomRole
          showModal={showModal}
          setShowModal={setShowModal}
          privileges={privileges}
          roleData={roleData}
          setRoleData={setRoleData}
          handleSubmit={handleSubmitCreateRole}
          handleLoadMore={handleChangePage} totalPrivillages={totalPrivillages} pagination={pagination}
        />
      ) : null}
      {setShowUpdateRole ? (
        <UpdateRole
          showModal={showUpdateRole}
          setShowModal={setShowUpdateRole}
          privileges={roleCannotList}
          roleData={roleUpdateData}
          setRoleData={setRoleUpdateData}
          selectedRole={selectedRole}
          handleSubmit={updateRole}
          handleLoadMore={handleChangePage} totalPrivillages={totalPrivillages} pagination={pagination}
        />
      ) : null}
      {
        showUnitModal ? (
          <NewUnit
          setShowUnitModal={setShowUnitModal}
          showUnitModal={showUnitModal}
          getMenus={getMenus}
          />
        ) : null
      }
      {showUpdateMenu ? (
        <UpdateMenu
          showModal={showUpdateMenu}
          setShowModal={setShowUpdateMenu}
          privileges={privileges}
          roleData={roleData}
          setRoleData={setRoleData}
          handleSubmit={handleUpdateUnit}
          handleLoadMore={handleChangePage} totalPrivillages={totalPrivillages} pagination={pagination}
          menus={defMenu}
          menuData={menuData}
          setMenuData = {setMenuData}
          setMenuId = {setMenuId}
          selectedMenu = {selectedMenu}
        />
      ) : null}
    </>
  );
};

export default ManageMenu;
