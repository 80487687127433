import * as React from 'react';
import {alpha , styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';

const ColoredSwitcher = (props) => {
    const { label, id, keyValue,checked,
      data, dataIndex,type,
       color, onChange, style, defaultValue, ...rest } = props;
  const ColoredSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: color,
      "&:hover": {
        backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: color,
    },
  }));

  const labelText = { inputProps: { 'aria-label': label } };
  
  return (
    <React.Fragment key={keyValue}>
       { type !== 'biller' ? (<label   style={style}>{label}</label>) : null } 
      <ColoredSwitch  id={id} checked={data.active ?? data?.isActive} {...labelText} onChange={()=>{
        onChange(dataIndex, !data.active ?? !data?.isActive, data)
      }}    />
    </React.Fragment>
  );
};
ColoredSwitcher.defaultProps = {
   label: '',
   color: '',
   style: {},
   id: '',
   keyValue: '',
   defaultValue: false,
   checked: false,
   data: {},
   dataIndex: 0,
   type: '',
  };
  ColoredSwitcher.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    defaultValue: PropTypes.bool,
    id: PropTypes.string,
    keyValue: PropTypes.string,
    checked: PropTypes.bool,
    data: PropTypes.object,
    dataindex: PropTypes.number,
    type: PropTypes.string,
  }
export default ColoredSwitcher;
