import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Breadcrumb } from "antd";
import { useIdleTimer } from 'react-idle-timer'

import wayabankLogo from "../../assets/images/newlog.svg";
import "./index.css";
import getMenuData from "../../services/menu";

import TopNavDropDown from "./TopNavDropdown";
import PinModal from "../Modal/Pin/PinModal";
import { customBaseUrl, httpGet } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";
import { ProfileContext, ProfileContextProvider } from "../../store/context/ProfileContext";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const Index = (props) => {  
  const { children } = props;
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const timeout = 1000 * 60 * 5;
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)
  const [showTimeOutModal, setShowTimeOutModal] = useState(false)
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [notifications, setNotification] = useState([]);
  const { profile } = useContext(ProfileContext);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [otp, setOtp] = useState('')
  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () =>{
     setIsIdle(true)
     //setShowTimeOutModal(true)
    // return alert('You are idle for more than 1 minute')
     localStorage.clear();
     window.location.href = '/';    
     }
    const handleSubmit = async () => {
      if(otp === ''){
        return Swal.fire("Please enter OTP")
      }
      showLoader();
        let res = await httpGet(`${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`)
        if(res.status){
          setOtp('')
          hideLoader();
            setShowTimeOutModal(false)           
        }else{
          Swal.fire('Oops!', res.message, 'error').then(() => {
          setOtp('')
          localStorage.clear();
          window.location.href = '/'; 

          })
          hideLoader();

        }

    }

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })
  const getNotification = async (page, size) =>{
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let res =await httpGet(`${customBaseUrl.notificationUrl}/in-app-notifications/${profile.userId}?page=${pages}`)

    if(res?.status){
      console.log('notification', res?.data?.notifications)
      setNotification(res?.data?.notifications);
      setTotalNotifications(res?.data?.totalNumberOfInAppNotifications)

    }
  }


  useEffect(() => {
    getNotification()
    setRemaining(getRemainingTime())
    setLastActive(getLastActiveTime())
    setElapsed(getElapsedTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
      setLastActive(getLastActiveTime())
      setElapsed(getElapsedTime())
    }, 1000)
  }, [])

  const onCollapse = (collapsed) => {
    console.log(collapsed);
    setCollapsed(collapsed);
  };

  const menuData = getMenuData();
  return (
    <ProfileContextProvider>
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ background: "#fff" }}
        width={270}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginLeft: "-20px",
            alignItems: "center",
            height: "97px",
          }}
        >
          <img
            src={wayabankLogo}
            alt="wayaLogo"
            style={{
              height: "36px",
              width: "60px",
              width: collapsed ? "85%" : "100%",
              marginLeft: collapsed ? "40px" : "0px",
              marginTop: collapsed ? "-15px" :"0px"
            }}
          />
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
        >
          {menuData.map((item) => {
            if (item.children) {
              return (
                <SubMenu key={item.key} title={item.title} icon={item.icon}>
                  {item.children.map((subItem) => {
                    return (
                      <Menu.Item
                        key={subItem.key}
                        onClick={() => navigate(subItem.url)}
                      >
                        {subItem.title}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.key}
                onClick={() => navigate(item.url)}
                icon={item.icon}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            // padding: '47px',
            background: "#fff",
            display: "flex",
            justifyContent: "end",
            // height: '97px',
            alignItems: "center",
          }}
        >
          <TopNavDropDown  
          notifications={notifications} 
          setNotification={setNotification} 
          totalNotifications={totalNotifications}
           setTotalNotifications={setTotalNotifications}
           pagination={pagination}
           setPagination={setPagination}
           getNotification={getNotification}
           />
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
       
      {showTimeOutModal &&
      <PinModal
      showModal={showTimeOutModal}
      hideModal = {setShowTimeOutModal}
      otp={otp}
      setOtp={setOtp}
      title="Session has been idle for too long"
      description="Please enter your PIN to continue"
      buttonLabel="Unlock"
      handleSubmit={handleSubmit}
      isIdle={isIdle}
      numInputs={4}
      pinMode={true}
      />
      /*&& (
        
        <div className="modal-container">
          
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <h3>Session Timeout</h3>
                </div>
                <div className="modal-close">
                  <span onClick={() => setShowTimeOutModal(false)}>&times;</span>
                </div>
            </div>
            <div className="modal-body">
              <p>Your session has expired. Please login again.</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={() => setShowTimeOutModal(false)}>OK</button>
            </div>
          </div>
        </div>
      )*/}
      </Layout>
      
    </Layout>
    </ProfileContextProvider>
  );
};

export default Index;
