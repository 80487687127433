import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Button, Row } from 'antd';
import useStyles from './styles';
import UserIcon from '../../assets/icons/userOrange.svg';
import Check from '../../assets/icons/check.svg';
import NotCheck from '../../assets/icons/notCheck.svg';
import { ArrowBack, Download } from '@mui/icons-material';
import { hideLoader, showLoader } from '../../utils/loader';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';
import Swal from 'sweetalert2';
import PinModal from '../../components/Modal/Pin/PinModal';
import LoanHistory from './loanhistory';

const EditWRapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoanSubmission = ({data, hide, getReq}) => {
    const roles = localStorage.getItem("approverRole");
    const [showPin, setShowPin] = useState(false);
    const [otp, setOtp] = useState('');
    const [submissionDetails, setSubmissionDetails] = useState([]);
    const [revData, setRevData] = useState([]);
    const [showCredit, setShowCredit] = useState(false);
    const [report, setReport] = useState([]);

    const getDetails = async () => {
        showLoader();
        //  const email = localStorage.getItem("email")
        const res = await httpGet(
          `${customBaseUrl.loanUrl}/api/v1/loan/single/loan?id=${data.id}`
        );
        if (res.status === true) {
            hideLoader();
           setSubmissionDetails(res.data);
          //setApprovers(res.data)
        }
      }
      const handleVerify = async () => {
        const pData = {
          bankAutoDebitVerified: revData.autoDebit,
          bankStatementVerified: revData.bankStatement,
          creditCheckVerified: revData.creditCheck,
          guarantorVerified: revData.guarantor,
          homeAddressVerified: revData.homeAdd,
          idVerified: revData.idCard,
          kycVerified: revData.kyc,
          workplaceVerified: revData.workplace
        };
        // showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/approver/loan/verify?loanId=${data?.id}`,
          pData
        );
        // if (res.status === true) {
        //     hideLoader();
        //    // Swal.fire('Done', res.message);
        //    //   hide(false);
        //   //setApprovers(res.data)
        // } else {
        //     hideLoader();
        //     //  Swal.fire('Oops!', res.message);
        // }
      }
      const handleAnalystApproval = async (status) => {
        showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/approver/credit-analyst/approve/loan?approvalStatus=${status}&id=${data?.id}`
        );
        if (res.status === true) {
            hideLoader();
            handleVerify();
           // Swal.fire('Done', res.message);
           setShowPin(true)
           //   hide(false);
          //setApprovers(res.data)
        } else {
            hideLoader();
            Swal.fire('Oops!', res.message);
        }
      }
      const handleControllerApproval = async (status) => {
        showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/admin/controller/approve/${data.id}?approvalStatus=${status}`
        );
        if (res.status === true) {
            hideLoader();
           // Swal.fire('Done', res.message);
           setShowPin(true)
           //hide(false);
          //setApprovers(res.data)
        } else {
            hideLoader();
            Swal.fire('Oops!', res.message);
        }
      }
      const handleMDApproval = async (status) => {
        showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/admin/md/${data.id}/approve?approvalStatus=${status}`
        );
        if (res.status === true) {
            hideLoader();
           //   Swal.fire('Done', res.message);
           setShowPin(true)
           //   hide(false);
          //setApprovers(res.data)
        } else {
            hideLoader();
            Swal.fire('Oops!', res.message);
        }
      }

      const handleValidate = async () => {
        showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/approver/otp/verify?approver=${
            roles==='Controller' 
                ? 'controller'
                : roles==='Credit analyst' 
                ? 'creditAnalyst'
                : roles==='MD'
                ? 'MD' :
                ''
          }&loanId=${data.id}&otp=${otp}`
        );
        if (res == true) {
            hideLoader();
           Swal.fire('Done', res?.message ?? 'Successful' );
           getDetails();
           hide(false);
           getReq();
          //setApprovers(res.data)
        } else {
            hideLoader();
            Swal.fire('Oops!', res.message);
        }
      }
      const handleCreditCheck = async () => {
        showLoader();
        const res = await httpGet(
          `${customBaseUrl.loanUrl}/api/v1/admin/customer/report?id=${data.id}`
        );
        if (res.status === true) {
            hideLoader();
           setShowCredit(true);
           setReport(res.data);
          //setApprovers(res.data)
        } else {
            hideLoader();
            Swal.fire('Oops!', res.message);
        }
      }

    const downloadEmployeeData = (ur) => {
      fetch(ur)
        .then(response => {
          response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'STAT';
            a.click();
          });
        });
    }
    useEffect(() => {
        getDetails();
    }, []);
  const styles = useStyles();
  return (
    <EditWRapper>
      <div className={styles.topDiv}>
        <ArrowBack onClick={() => hide(false)} />
        <div className={styles.title}>Loan Approval Submission</div>
        <div className={styles.titleDet}>
          <div className={styles.grow} />
          <Button
            size="medium"
            style={{ background: "#ff6700", fontSize: "10px", color: "#fff" }}
            onClick={()=> roles==='Controller' 
                ? handleControllerApproval('Approved')
                : roles==='Credit analyst' 
                ? handleAnalystApproval('Approved')
                : roles==='MD'
                ? handleMDApproval('Approved') :
                ''
            }
            // disabled={submissionDetails?.loanStatus === 'First Level Approved' || submissionDetails?.loanStatus === 'Second Level Approval Pending' && roles ==='Collector' ? false 
            // : submissionDetails?.loanStatus === 'Second Level Approved'|| submissionDetails?.loanStatus === 'Third Level Approval Pending' && roles==='MD' ? false 
            // : submissionDetails?.loanStatus === 'Pending' || submissionDetails?.loanStatus === 'First Level Approval Pending' && roles==='Credit analyst' ? false : true}
          >
            Approve
          </Button>
           <Button
            size="medium"
            style={{ background: "#ff0000", fontSize: "10px", color: "#fff" }}
            onClick={()=> roles==='Controller' 
                ? handleControllerApproval('Declined')
                : roles==='Credit analyst' 
                ? handleAnalystApproval('Declined')
                : roles==='MD'
                ? handleMDApproval('Declined') :
                ''
            }
          >
            Decline
          </Button>
          {/* <Button
            size="medium"
            style={{  fontSize: "10px", color: "#ff6700" }}
            // onClick={()=> hide(true)}
          >
            Request Revision
          </Button> */}
        </div>
      </div>
    <div className="row">
    <div className="col-6 col-sm-6">
        Bank Statement or Payment Slip
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <input value={submissionDetails?.bankStatement ? 'Bank Statement' : ''} disabled className={styles.inputInput} />
            <a href={submissionDetails?.bankStatement} target='_blank'>
              <Download onClick={() => downloadEmployeeData(submissionDetails?.bankStatement)} />
            </a>
        </div>
        {submissionDetails?.paySlip?.doc1 ? 
        <>
         <div style={{ display: 'flex', flexDirection: 'row'}}>
            <input value={'Payslip ' + 1} disabled className={styles.inputInput} />
            <a href={submissionDetails?.paySlip?.doc1} target='_blank'>
            <Download />
            </a>
        </div>
        </>:''
        }
        {submissionDetails?.paySlip?.doc2 ? 
        <>
         <div style={{ display: 'flex', flexDirection: 'row'}}>
            <input value={'Payslip ' + 2} disabled className={styles.inputInput} />
            <a href={submissionDetails?.paySlip?.doc2} target='_blank'>
            <Download />
            </a>
        </div>
        </>:''
        }
        {submissionDetails?.paySlip?.doc3 ? 
        <>
         <div style={{ display: 'flex', flexDirection: 'row'}}>
            <input value={'Payslip ' + 3} disabled className={styles.inputInput} />
            <a href={submissionDetails?.paySlip?.doc3} target='_blank'>
            <Download />
            </a>
        </div>
        </>:''
        }
        <div className='my-3'>
        Guarantor' documents
        {submissionDetails?.guarantorDetails?.length > 0 ?
        submissionDetails?.guarantorDetails.map((e)=> 
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <input value={e.firstName + ' ' + e?.lastName} disabled className={styles.inputInput} />
            <a href={e?.govtId} target='_blank'>
            <Download />
            </a>
        </div>
        )
        : ''}
        </div>
        <div className='my-3'>
        Government documents
        
        <div style={{ display: 'flex', flexDirection: 'row'}}>
            <input value="Government Id" disabled className={styles.inputInput} />
            <a href={submissionDetails?.govtId} target='_blank'>
            <Download />
            </a>
        </div>
        
        </div>
        <div className='my-3'>        
        <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer'}}
          onClick={handleCreditCheck}
        >
          Credit Check
        </div>
        
        </div>
    </div>
    <div className="col-6 col-sm-6">
        <div>
        <h5 className='font-weight-bold'>Loan approval level</h5>
        <h6 className='font-weight-light'>Status of loan approval passed</h6>
        <div className='row'>
            <div className='col-8'>
            <h6>1st level approval- Credit analyst</h6>
            </div>
            <div className='col-4'>
            <h6
                className={`${submissionDetails?.loanStatus === 'First Level Approved' 
                ||submissionDetails?.loanStatus === 'Second Level Approved'
                || submissionDetails?.loanStatus === 'Third Level Approved'? 'text-success' : 'text-warning'}`}
            >{
            submissionDetails?.loanStatus === 'First Level Approved' 
            ||submissionDetails?.loanStatus === 'Second Level Approved'
            || submissionDetails?.loanStatus === 'Third Level Approved'? 'Approved' : 'Pending'}</h6>
            </div>
        </div>
        <div className='row'>
        <div className='col-8'>
            <h6>2nd level approval- Controller</h6>
            </div>
            <div className='col-4'>
            <h6
                 className={`${submissionDetails?.loanStatus === 'Second Level Approved'
                 || submissionDetails?.loanStatus === 'Third Level Approved'? 'text-success' : 'text-warning'}`}
            >{submissionDetails?.loanStatus === 'Second Level Approved'
            || submissionDetails?.loanStatus === 'Third Level Approved'? 'Approved' : 'Pending'}</h6>
            </div>
        </div>
        <div className='row'>
        <div className='col-8'>
            <h6>3rd level approval- MD</h6>
            </div>
            <div className='col-4'>
            <h6
             className={`${submissionDetails?.loanStatus === 'Third Level Approved'? 'text-success' : 'text-warning'}`}
            >{submissionDetails?.loanStatus === 'Third Level Approved'? 'Approved' : 'Pending'}</h6>
            </div>
        </div>

            
        </div>
        <div className="my-5">
         <h5 className='font-weight-bold'>Confirm Submission</h5>
            <div className='row'>
              <div className='col-6'>
              <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>KYC Verification</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify all KYC level before checking
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.kyc}
                      onClick={(e) => setRevData({ ...revData, kyc: e.target.checked })} 
                    />
                  </div>
                </div>
                <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Home Address</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify users home address
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.homeAdd}
                      onClick={(e) => setRevData({ ...revData, homeAdd: e.target.checked })} 
                    />
                  </div>
                </div>
                <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Guarantor Contacted</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify users guarantor
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.gurantor}
                      onClick={(e) => setRevData({ ...revData, guarantor: e.target.checked })} 
                    />
                  </div>
                </div>
                <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Credit Check Verification</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify credit check
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.creditCheck}
                      onClick={(e) => setRevData({ ...revData, creditCheck: e.target.checked })} 
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
              <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Bank Statement Verification</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify all bank statement drpped by the user
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.bankStatement}
                      onClick={(e) => setRevData({ ...revData, bankStatement: e.target.checked })} 
                    />
                  </div>
                </div>
                <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Workplace Verification</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify users workplace
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.workplace}
                      onClick={(e) => setRevData({ ...revData, workplace: e.target.checked })} 
                    />
                  </div>
                </div>
                <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Id Verification</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify all dropped ids
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.idCard}
                      onClick={(e) => setRevData({ ...revData, idCard: e.target.checked })} 
                    />
                  </div>
                </div>
                <div
                className='border px-3'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:'space-between',
                }}>   
                 <div>
                    <h6>Bank Account for auto debit</h6>
                    <h6 style={{ fontSize: 8}}>
                       Verify the bank accunt for auto debit
                    </h6>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      checked={revData.autoDebit}
                      onClick={(e) => setRevData({ ...revData, autoDebit: e.target.checked })} 
                    />
                  </div>
                </div>
                
              </div>
            </div>
        </div>
    </div>
    </div>
    <div className='row my-3'>
        <div className="col-6 col-sm-6">
        <h5 className='font-weight-bold'>General Information</h5>
            <div className="row">
                <div className='col-4'>
                    <h6 className='font-weight-bold'>First Name</h6>
                    <p>{data?.firstName ?? '-'}</p>
                </div>
                <div className='col-4'>
                <h6 className='font-weight-bold'>Middle Name</h6>
                    <p>{data?.middleName ?? '-'}</p>
                </div>
                <div className='col-4'>
                <h6 className='font-weight-bold'>Last Name</h6>
                    <p>{data?.lastName ?? '-'}</p>
                </div>

            </div>
            <div className="row">
                <div className='col-4'>
                    <h6 className='font-weight-bold'>Date of birth</h6>
                    <p>{data?.dateOfBirth ?? '-'}</p>
                </div>
                <div className='col-4'>
                <h6 className='font-weight-bold'>Gender</h6>
                    <p>{data?.gender ?? '-'}</p>
                </div>
                <div className='col-4'>
                <h6 className='font-weight-bold'>Address</h6>
                    <p>{data?.address ?? '-'}</p>
                </div>

            </div>
            <div className="row">
                <div className='col-6'>
                    <h6 className='font-weight-bold'>Email address</h6>
                    <p>{data?.emailAddress ?? '-'}</p>
                </div>
                <div className='col-6'>
                <h6 className='font-weight-bold'>Phone Number</h6>
                    <p>{data?.phoneNumber ?? '-'}</p>
                </div>

            </div>
        </div>
    </div>
    {showPin&& <PinModal
                
        showModal={showPin}
        hideModal={setShowPin}
        otp={otp}
        setOtp={setOtp}
        numInputs={6}
        separator=''
        buttonLabel='Verify'
        title='Verify OTP'
        description='Please input the OTP sent to your Email'
        handleSubmit={handleValidate}
        pinMode={true}

        />}
        {showCredit ? 
          <LoanHistory
            showMoadal={showCredit}
            hideModal={setShowCredit}
            data={report}
          />
          : ''  
      }
    </EditWRapper>
  );
};

export default LoanSubmission;
