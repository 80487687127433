import React,{useState} from 'react';
import { Modal } from 'antd';
import { hideLoader, showLoader } from '../../utils/loader';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';
import Swal from 'sweetalert2';



const  UpdateKYC = ( {userId, showUpdateModal,getKycStatus, setShowUpdateModal, hideModal}) => {

    const [tier, setTier] = useState('');

   
    const handleUpdateTier = async () => {
        showLoader();
        
         const res = await httpPost(
            `${customBaseUrl.kycUrl}/kyc/updateUserKycStatus`,
            {
              tierType: tier,
              userId
            }
         );

         if (res.status) {
            getKycStatus();
            hideLoader();
            setShowUpdateModal(false)
            Swal.fire('success', res.message , 'success')
        }else {
            console.log(res)
            hideLoader();
            Swal.fire("Oops!", res.message, "error");
        }
    }
  return (
    <Modal
      centered
      onOk={() => {setShowUpdateModal(false)}}
      onCancel={()=>{setShowUpdateModal(false)}}
      width={400}
      footer={0}
      visible={showUpdateModal}

    >
      <h4 className='text-center'> Update User Tier Level </h4>
      <div className='text-center mt-5'>
      <label >Select Tier</label>
                <select className="form-control" value={tier} onChange={(e) => setTier(e.target.value)}>
                    <option value="">Select Tier</option>
                    <option value="tier1">Tier 1</option>
                    <option value="tier2">Tier 2</option>
                    <option value="tier3">Tier 3</option>
                    <option value="tier4">Tier 4</option>
                </select>
      </div>     
      <div style={{marginTop:20, marginLeft: '30%', alignItem:'center', justifyContent:'center'}}>
        <button className='btn btn-primary' onClick={handleUpdateTier} >Update KYC</button>
    </div>
    </Modal>
  );
}

export default UpdateKYC;