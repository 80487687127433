import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { styled } from '@mui/material';
import qs from "qs";
import Swal from "sweetalert2";
import SelectUser from './selectUser';
import { Modal } from 'antd';


export default function SendOtp({ showModal, setShowModal }) {
  const styles = useStyles();
  const [currentPage, setCurrentPage] = useState('default');
  const [users, setUsers] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
  });
  const [viewSelectUser, setViewSelectUser] = useState(false)




  // const handleSendOtpPasswordByEmail = async (id) => {
  //   await Swal.fire({

  //     showConfirmButton: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "Cancel",
  //     icon: 'warning',
  //     preConfirm: async (id1) => {
  //       const response = await httpGet(`${customBaseUrl.authUrl}/api/v1/password/change-password/byEmail?email=${email}`);
  //       if (!response.status) {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops...',
  //           text: `${response.message}`,
  //           timer: 1500
  //           //footer: '<a href="">Why do I have this issue?</a>'
  //         })

  //       }
  //       return response.status;
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   }
  //   ).then((result) => {
  //     if (result.isConfirmed) {
  //       // showLoader();

  //       Swal.fire('Deleted', '', 'success');
  //     } else
  //       Swal.fire(' Cancelled', '', 'error')

  //   })
  // }



  const handleSendOtpPasswordByEmail = async () => {
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/password/change-password/byEmail?email=${email}`
    );
    setShowModal(false)
    if (response.status === true) {

      Swal.fire({
        icon: 'success',
        title: 'OTP Sent',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })
    }
  }

  const handleSendOtpPasswordByPhone = async () => {
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/password/change-password/byPhone?phoneNumber=${phoneNumber}`
    );
    setShowModal(false)
    if (response.status === true) {
      console.log('data', response.data)
      Swal.fire({
        icon: 'success',
        title: 'OTP Sent',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    }
  }

  const handleSendOtpByEmail = async () => {
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/change-pin/byEmail?email=${email}`
    );
    setShowModal(false)
    if (response.status === true) {
      console.log('data', response.data)
      Swal.fire({
        icon: 'success',
        title: 'OTP Sent',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    }
  }
  const handleSendOtpByPhone = async () => {
    console.log('phoneNumber', phoneNumber)
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/change-pin/byPhone?phoneNumber=${phoneNumber}`

    );
    setShowModal(false)
    if (response.status === true) {
      console.log('data', response.data)
      Swal.fire({
        icon: 'success',
        title: 'OTP Sent',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        showConfirmButton: false,
        timer: 1500,
        width: 200,
      })

    }
  }



  const getUsers = async () => {

    const res = await httpGet(
      `${customBaseUrl.authUrl}api/v1/admin/users`
    );
    setUsers(res?._embedded?.userResponse);


  }







  // const getRandomuserParams = (params) => ({
  //   size: params.pagination.pageSize ?? pagination.pageSize,
  //   page: params.pagination.current ?? pagination.current,
  // });

  // const handleGetUsers = async (params = {}) => {
  //   const res = await httpGet(
  //     `${customBaseUrl.authUrl}/api/v1/admin/users?${qs.stringify(
  //       getRandomuserParams(params)
  //     )}`
  //   );
  //   if (res?._embedded?.userResponse === undefined) {
  //     let query = getRandomuserParams(params);
  //     const totalRecords = res?.page.totalElements;
  //     const previousPagesRecords = (res?.page.number - 1) * res?.page.size;
  //     const remainingRecords = totalRecords - previousPagesRecords;
  //     if (remainingRecords > 0) {
  //       query.size = remainingRecords;

  //       const res2 = await httpGet(
  //         `${customBaseUrl.authUrl}/api/v1/admin/users?${qs.stringify(
  //           query
  //         )}`
  //       );
  //       setUsers(res2?._embedded?.userResponse);

  //       setPagination({
  //         ...params.pagination,
  //         total: res2.page.totalElements,
  //       });
  //     } else {
  //       //NotificationManager.warning("no more records");
  //       return;
  //     }
  //   } else {
  //     console.log('records', res?._embedded?.userResponse);
  //     setUsers(res?._embedded?.userResponse);

  //     setPagination({
  //       ...params.pagination,
  //       total: res.page.totalElements,
  //     });
  //   }
  // };



  useEffect(() => getUsers(), [])
  // useEffect(() => handleGetUsers({ pagination }), []);



  return (
    <>
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          keyboard={false}
          size="md"
          centered
          footer={false}
        >
         

            <div className={styles.mTitle}>Send OTP </div>
           
              <>
                <div className={styles.btnCont}>
                  <button className={styles.bcItem} onClick={()=>{setCurrentPage('passEmail');setViewSelectUser(true)}}>
                  Send OTP for Password Via Email

                  </button>        
                  <button className={styles.bcItem} onClick={()=>{setCurrentPage('passPhone');setViewSelectUser(true)}}>
                  Send OTP for Password Via Phone

                  </button>      <button className={styles.bcItem} onClick={()=>{setCurrentPage('pinEmail');setViewSelectUser(true)}}>
                  Send OTP for PIN Via Email

                  </button>      <button className={styles.bcItem} onClick={()=>{setCurrentPage('pinPhone');setViewSelectUser(true)}}>
                  Send OTP for PIN Via Phone

                  </button>           
                
                </div>
              </>
            

            {viewSelectUser && 
            <SelectUser
              showModal={viewSelectUser}
              hideModal = {setViewSelectUser}
              currentPage={currentPage}
              setShowModal={setShowModal}
              />
          }

        </Modal>
    </>
  );
}
