import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { createUseStyles, useTheme } from 'react-jss';
import { Switch, Input, Button, Modal, Checkbox, Radio, Row, Tabs } from "antd";
import { PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import qs from "qs";
import AntTable from "../../components/CustomTable/AntTable";
import { MdRadioButtonUnchecked, MdOutlineContentCopy } from 'react-icons/md';
import { BsCheckCircle } from 'react-icons/bs';
import Wrapper, { useMatStyles } from "./style";
import banner from "../../assets/images/banner.png";
import WalletCard from "../../components/CustomCard/walletCard";
import Check from "../../assets/images/check.svg";
import Blocked from "../../assets/images/blocked.svg";
import Exp from "../../assets/images/export.svg";
import { LeftArrow, RightArrow } from "./arrows";
import del from "../../assets/images/delete.svg";
import settings from "../../assets/images/settings.svg";
import CreateWallet from "./modal/createWallet";
import PerformTransaction from "./modal/performTransaction";
import ExportData from "./modal/exportData";
import FundWallet from "./modal/fundWallet";
import WayaOfficialSettings from "./modal/wayaOfficialSettings";
import { hideLoader, showLoader } from "../../utils/loader";
import { ProfileContext } from "../../store/context/ProfileContext";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import Layout from "../../components/Layout";
import { currencyFormat, transformName } from "../../utils/helper";
import themeColor from "../../theme/color";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import PerformTransactionExcel from "./modal/PerformTransactionExcel";
import PerformTransactionForms from "./modal/PerformTransactionForms";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import PinModal from "../../components/Modal/Pin/PinModal";
import WalletForms from "./modal/createWalletForms";
import { ArrowBack, Filter, Filter1Outlined, FilterList, Settings } from "@mui/icons-material";
import TransactionHistory from "./transactionHistory";
import WalletIcon from '../../assets/icons/wallet-icon';
import SmartPhoneIcon from '../../assets/icons/smart-phone-icon';
import TempUserIcon from '../../assets/icons/temp-user-icon';
import CoinIcon from '../../assets/icons/coin-icon';
import image from '../../assets/images/user-image.png';
import { StatCards } from "../dashboard/StatCards";
import CreateSMU from "./modal/createSMUser";
import SMUsersTransactions from "./modal/smUsersTransaction";
import BusinessConfig from "./modal/businessConfig";
import IndividualConfig from "./modal/individualConfig";


const WayaOfficial = () => {
  const roles= localStorage.getItem('roles')
  const theme = useTheme();
  const classes = createUseStyles({ theme });
  let navigate = useNavigate();
  const { TabPane } = Tabs;
  const { profile } = useContext(ProfileContext);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showPerform, setShowPerform] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showFundWallet, setShowFundWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [wayaTransactions, setWayaTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState({});
  const [totalTransaction, setTotalTransaction] = useState(0)
  const [isAdmin, setIsadmin] =useState(false)
  const [showCreateMultiWallet, setShowCreateMultiWallet] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [validateOTP, setValidateOTP] = useState('');
  const [checked, setChecked] = useState(true);
  const [showBusConfig, setShowBusConfig] = useState(false);
  const [showIndConfig, setShowIndCofig] = useState(false);

  const [showBulkExcelTransaction, setShowBulkExcelTransaction] =
    useState(false);
  const [showFormTransaction, setShowFormTransaction] = useState(false);
  const [showSMUsers, setShowSMUsers] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "id",
    searchText: "",
  });
  const [filter, setFilter] = useState('')
  const [otp, setOtp]= useState('')
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [totalTrans, setTotalTrans] = useState(0)
  const [overallAnalysis, setOverall] = useState([]);
  const [categoryAnalysis, setCategoryAnalysis] = useState([]);
  const [totalDebitTrans, setTotalDebitTran] = useState(0)
  const [totalCreditTrans, setTotalCredTrans] = useState(0)
  const [walletId, setWalletId] = useState('');
  const [walletNo, setWalletNo] = useState(0);
  const [selectedAccountName, setSelectedAccountName] = useState('')
  const [history, setHistory] = useState(false);
  const [showCreateSM, setShowCreateSM] = useState(false);

  const styles = useMatStyles();
  const [cardList, setCardList] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const handleDefaultAccount = async (acctNo) => {
    showLoader();
    const defaultWallet = {
      // newDefaultAcctNo: acctNo,
      newDefaultAcctNo: walletId,
      userId: profile?.userId,
    };
    if (walletId){
      setWalletId(walletId);
    }else{
      setWalletId(acctNo);
    }
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/user/account/toggle`,
      defaultWallet
    );

    if (res.status) {
      getWallets();
      Swal.fire(`Accouunt Number ${acctNo} is set to default`);
      hideLoader();
    } else {
      Swal.fire(res.message);
      hideLoader();
    }
  };

  const updateCurrentSlide = (index) => {
    setWalletNo(index);
  };

  const handleDeleteWallet = async (acctNo) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/closure`,
      {
        customerAccountNo: acctNo,
      }
    );
    if (res.status) {
      getWallets();
      hideLoader();
      Swal.fire("Deleted!", res.message, "success");
    } else {
      hideLoader();
      Swal.fire(res.message);
    }
  };

  const CheckBox = ({ status }) => {
    return (
      <div style={{ color: '#828282' }} className="last_one">
        {' '}
        <span style={{ marginRight: 30, marginLeft: 10 }}>
          {status ? (
            <BsCheckCircle color="#ff6700" size={15} />
          ) : (
            <MdRadioButtonUnchecked size={15} />
          )}
        </span>
      </div>
    );
  };

  const handleDropDown = (type, item) => {
    // console.log(type);
    if (type === "fund") {
      setShowFundWallet(true);
      setSelectedWallet(item.accountNo);
      setSelectedAccountName(item?.acct_name);
    }
    if (type === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: themeColor.primary,
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteWallet(item.accountNo);
        }
      });
    }

    if (type === "primary") {
      handleDefaultAccount(item.accountNo);
    }
    if (type === "block") {
      // handleOpenBlock(item.id);
    }
  };

  const handleDeleteUser = async () => {
    showLoader();
    const response = await httpDelete(
      `${customBaseUrl.authUrl}/api/v1/user/delete/${profile?.userId}`
    );

    hideLoader();
    const { status, message } = response.data;
    if (status === false) {
      return Swal.fire("error", message, "error");
    }
    Swal.fire(message);
  };

  // console.log(profile);

  const getWallets = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/admin/get-waya-official-account`
    );
    const Bankres = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/BK`
    );
    const Cardres = await httpGet(
      `${customBaseUrl.cardUrl}/card/list/${profile.userId}`
    );

    if (res?.status) {
      setWalletList(res.data);
    }
    if (res?.status) {
      setBankList(Bankres.data);
    }
    if (Cardres?.status) {
      setCardList(Cardres.data);
    }
  };

  const handleChangeRowsPerPage = async (value) => {
    // console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getWayaTransaction(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getWayaTransaction(newPage, pagination.pageSize);
  };

  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const getWayaTransaction = async (page, size) => {
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/official/transaction?${filter?`filter=${filter}&`:''}page=${pages}&size=${limit}`
    );
    /*const transacRes = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/transactions/${walletId}?page=${pages}&size=${limit}`
    );*/
    if (res?.status) {
      //console.log('transRes', transacRes);
      hideLoader();
      setLoading(false);
      console.log('transaction', res.data)
      setTotalTransaction(res.data.totalItems)
      setWayaTransactions(res.data.transaction);
      console.log('transRes1', wayaTransactions);
    }
    setLoading(false);
    hideLoader();
  };

  const fetchOverview = async () => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/overall-based-analysis`
    );
    
    if (res?.status) {
      //setFiltered(response.data.content)
      setOverall(res.data);
      hideLoader();
    } else {
      console.log(res)
      hideLoader();   
     }

     };

     const fetchCategory = async () => {
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/category-based-analysis`
      );
      
      if (res?.status) {
        //setFiltered(response.data.content)
        setCategoryAnalysis(res.data);
        hideLoader();
      } else {
        console.log(res)
        hideLoader();   
       }
  
       };


  const handleValidate = async () => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/authenticate-waya/otp-verify/${Number(otp)}`
   );
    if (res.status) {
      NotificationManager.success("Sucessful");
      setIsadmin(true)
      setIsModalVisible(false)
      hideLoader();

    } else {
      hideLoader();
      // console.log('otp err', res)
      navigate("../dashboard");
      NotificationManager.error(res.error);
    }
  }

  const sendOTP = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/authenticate-waya/otp-send`
    );
    if (res.status = true) {
      NotificationManager.success("OTP sent");
    }
  }
  const handleOtp = () => {
    console.log('roles', roles)
    if(roles.indexOf('ROLE_ADMIN_OWNER') !==-1 || roles.indexOf('ROLE_ADMIN_SUPER') !==-1){
      if (!isAdmin){
        setIsModalVisible(true);
            sendOTP()  
      }
      }else{
        window.open('/dashboard','_self')
        NotificationManager.error('Not authorized');
      }
   
  }
  
  const getStat= async()=>{
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-official-credit-count`
    );
    if (res.status) {
      setTotalTrans(res.data)
    }
    const res1 = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-official-debit-transaction-count`
    );
    if (res1.status) {
      setTotalDebitTran(res1.data)
    }
    const res2 = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-official-debit-credit-count`
    );
    if (res2.status) {
      setTotalCredTrans(res2.data)
    }
  }
  
  const overall = [
    {
      icon: <WalletIcon />,
      title: 'Total Balance',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalBalance)}</h6>, count: overallAnalysis?.overallAnalysis?.countResponse?.totalBalance ?? <>-</>},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Deposit',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalDeposit)}</h6>, count: overallAnalysis?.overallAnalysis?.countResponse?.countDeposit},
      id: 2,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Withdrawal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalWithdrawal)}</h6>, count: overallAnalysis?.overallAnalysis?.countResponse?.countWithdrawal},
      id: 3,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Revenue',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalRevenue)}</h6>, count: overallAnalysis?.overallAnalysis?. countResponse?.totalRevenue},
      id: 4,
    },
    /*{
      icon: <CoinIcon />,
      title: 'Total Commission to Merchant',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 5,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Commission to Aggregator',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 6,
    },  */
  ];
  const category = [
    {
      icon: <WalletIcon />,
      title: 'Total Bills Payment- BAXI',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.billsPaymentTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.billsCount},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Bills Payment- Quickteller',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.quicketllerPayment)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.quicktellerCount},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Outbound External Transfer',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.outboundExternalTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.outboundExternalCount},
      id: 2,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Outbound Internal Transfer',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.outboundInternalTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.outboundInternalCount},
      id: 3,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Inbound Transfer - NIP',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.nipInbountTrans?? 0)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.nipCount},
      id: 4,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Inbound Transfer - WayaQuick',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.totalWayaQuickTrans?? 0)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.wayaquickCount??'-'},
      id: 5,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Inbound Transfer - Paystack',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.totalPaystackTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.paystackCount},
      id: 6,
    },
   
  ];
  useEffect(() => {    
    handleOtp()
   }, []);
 
 
  useEffect(() => {
    getStat();
    fetchCategory();
    fetchOverview();
    getWayaTransaction();
    

    if(walletList > 0){
      setWalletNo();
      updateCurrentSlide()
    }

    if (profile?.userId) {
      getWallets();

    }
  }, [profile, filter, walletId]); // eslint-disable-line react-hooks/exhaustive-deps

  
  const handleReverse = async (row) => {
    showLoader();
    // return  alert(JSON.stringify(row))
    let data = {
      tranId: row.tranId,
      tranDate: row.tranDate,
      tranCrncy: "NGN",
    }
    // `${customBaseUrl.withdrawalUrl}/admin/withdrawal/reverseWithdrawalTransaction`,

    let response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/reverse`,
      data
    )

    if (response.status) {
      hideLoader();
      Swal.fire('Success', response.message);

    } else {
      Swal.fire('Error', response.message);
      hideLoader();

    }
  };

  const handleChecked = () =>{
    if (walletNo){
      setChecked(true)
    }
  }

  const dropDownOptions = ["Reverse"];
  const handleDropdownAction = (item, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleReverse(row);
      }
    });


    if (item === "View") viewTransactions(item);
  };

  const viewTransactions = () => {
    return ''
  }


  const columns = [
    {
      headerName: "Id",
      field: "id",
      sorter: true,
      width: 150,
      headerClassName: styles.tableHeader,

    },
    {
      headerName: "Account No",
      field: "acctNum",
      sorter: true,
      width: 150,
      headerClassName: styles.tableHeader,

    },
    {
      headerName: "SENDER",
      field: "senderName",
      sorter: true,
      width: 150,
      headerClassName: styles.tableHeader,

    },
    {
      field: "partTranType",
      headerName: "Type",
      width: 70,
      editable: false,
      sortable: true,
      description: "Status Column",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div>
            <span
              style={{
                color: `${row.partTranType === "C" ? "#27AE60" : "#EB5757"}`,
              }}
            >
              {row.partTranType === "C" ? "Credit" : "Debit"}
            </span>
          </div>
        );
      },
    },
    {
      headerName: "CATEGORY",
      field: "tranCategory",
      width: 200,
      headerClassName: styles.tableHeader,
      

    },
    {
      headerName: "NARRATION",
      field: "tranNarrate",
      width: 300,
      headerClassName: styles.tableHeader,
      

    },
    {
      headerName: "RECIPIENT",
      field: "receiverName",
      width: 200,
      headerClassName: styles.tableHeader,
      

    },
    {
      headerName: "AMOUNT",
      field: "tranAmount",
      sorter: true,
      width: 100,
      headerClassName: styles.tableHeader,
      
    },

    {
      headerName: "DATE CREATED",
      field: "createdAt",
      width: 200,
      headerClassName: styles.tableHeader,

    },
    {
      field: "",
      headerName: " ",
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (

        row.tranCategory !== 'REVERSAL' ? <Button onClick={(item) => handleDropdownAction(item, row)}>reverse</Button> : ''
      ),
    },
  ];

  const smuOption = [
    "BUSINESS",
    "INDIVIDUAL",
  ];
  const handleSmuOption = (item) => {
   // if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){
    if (item === "BUSINESS") setShowBusConfig(true);
    if (item === "INDIVIDUAL") setShowIndCofig(true);
    /*}else{
      NotificationManager.error('Not authorized');
    }*/
  };

  const performTransactionOptions = [
    "Single User",
    "Excel Upload",
    "Web Forms",
    "SM Users"
  ];
  const handlePerformTransactions = (item) => {
   // if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){
    if (item === "Single User") setShowPerform(true);
    if (item === "Excel Upload") setShowBulkExcelTransaction(true);
    if (item === "Web Forms") setShowFormTransaction(true);
    if (item === "SM Users") setShowSMUsers(true);

    /*}else{
      NotificationManager.error('Not authorized');
    }*/
  };

  const walletOptions = [
    "Single Wallet",
    "Multiple Wallet"
  ];
  const handleWalletAction = (item) => {
    if (item === "Single Wallet") setShowCreateWallet(true);
    if (item === "Multiple Wallet") setShowCreateMultiWallet(true);

  };
  const transactionOptions = [
    "All",
    "Debit",
    "Credit"

  ];
  const handleTransactionOption = (item) => {
    if (item === "Debit") setFilter("D");
    if (item === "Credit") setFilter("C");
    if (item === "All") setFilter("");


  };

  

  return (
    <Layout>
      <Wrapper>
      
        {!history?<div className="banner-div">
          <img src={banner} alt="banner" className="img-div" />

          <div className="banner-texts">
            <div className="bt-top">
              <div className="bt-item">
                <div
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "22px",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {profile.firstName} {profile.lastName} ({profile.phoneNumber})
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#16B079",
                  }}
                >
                  ONLINE
                </div>
              </div>
              <div className="bt-item">
                <div
                  className="top"
                  style={{
                    color: "#FFCAB7",
                  }}
                >
                  USER ID
                </div>
                <div className="bottom">{profile.userId}</div>
              </div>
              <div className="bt-item">
                <div
                  style={{
                    color: "#FFCAB7",
                  }}
                  className="top"
                >
                  EMAIL ADDRESS
                </div>
                <div className="bottom">{profile.email}</div>
              </div>
              <div className="bt-item">
                <div className="top">ROLE</div>
                <div className="bottom">Super Admin</div>
              </div>
              <div className="bt-item">
                <div className="top">WAYAGRAM</div>
                <div className="bottom">wayaofficial</div>
              </div>
            </div>
            <div className="bt-bottom">
              <div onClick={() => setShowDropDown(!showDropDown)}>
                Waya Official Account Settings
              </div>
              {showDropDown && (
                <div
                  className="bt-dropDown"
                  onMouseLeave={() => setShowDropDown(false)}
                >
                  <div
                    className="btd-item"
                    onClick={() => setShowSettings(true)}
                  >
                    <img src={settings} alt="settings" />
                    <div>More Settings</div>
                  </div>
                  <div className="btd-item">
                    <img src={del} alt="delete" />
                    <div
                      style={{
                        color: "#FF0000",
                      }}
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDeleteUser();
                          }
                        });
                      }}
                    >
                      Delete Waya Official account
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>:""}
        {/*<div className="preCard-div">
          <div className="title">Wallets & Virtual Accounts</div>
          <div className="switch-box">
            <div className="text">Hide Balances </div>
            <Switch
              size="small"
              checked={!showBalance}
              onChange={() => {
                setShowBalance(!showBalance);
              }}
            />
          </div>
          <div className="grow" />
          <div className="pd-btn">
            <AntDropdown
             dropDownOptions={walletOptions}
             handleDropdownAction={handleWalletAction}
            ><span>
              <PlusCircleOutlined/> 
              Create Wallet/Virtual Account
             </span>
            </AntDropdown>
          </div>
        </div>*/}
        {/*<ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onWheel={onWheel}
        >
          {walletList?.map((wallet, i) => {
            if (wallet?.walletDefault) {
              return (
                <div className="wocard-item">
                  <WalletCard
                    item={`${wallet.id}wallet`}
                    key={`${wallet.id}wallet`}
                    dropDownItem={[
                      { label: "Fund Wallet", id: "fund", style: "" },
                      { label: "Block Wallet", id: "block", style: "" },
                      {
                        label: "Delete Wallet",
                        id: "delete",
                        style: "text-danger",
                      },
                    ]}
                    icon={
                       wallet.acct_cls_flg
                          ? Check
                          : ''
                    }
                    title="Primary Wallet"
                    accName={wallet?.acct_name}
                    accNumber={wallet?.accountNo}
                    amount={
                      showBalance
                        ? currencyFormat(wallet?.clr_bal_amt)
                        : "********"
                    }
                    handleDropDown={handleDropDown}
                    data={wallet}
                  />
                  {/* <Checkbox onChange={handleWalletId}>Click here to view transaction</Checkbox>  */}
               {/*} </div>
              );
            }
            if (!wallet?.walletDefault) {
              return (
                <div className=" d-flex align-middle wocard-item">
                  {/* <Radio
                    className=""
                    defaultChecked={false}
                    checked={walletNo === i ? checked : ''}
                    onChange={() =>{
                      setWalletId(wallet.accountNo);
                      updateCurrentSlide(i)
                    }}
                  /> */}
                 {/*} <WalletCard
                    item={`${wallet.id}wallet`}
                    key={`${wallet.id}wallet`}
                    dropDownItem={[
                      {
                        label: "Set as Primary Wallet",
                        id: "primary",
                        style: "",
                      },
                      { label: "Fund Wallet", id: "fund", style: "" },
                      { label: "Block Wallet", id: "block", style: "" },
                      {
                        label: "Delete Wallet",
                        id: "delete",
                        style: "text-danger",
                      },
                    ]}
                    title="Secondary Wallet"
                    setWalletId={setWalletId}
                    walletId={walletId}
                    totalTrans={totalTrans}
                    setChecked={setChecked}
                    checked={walletNo === i ? checked : ''}
                    walletAcct={wallet.accountNo}
                    accName={wallet?.acct_name}
                    updateCurrentSlide={() => {updateCurrentSlide(i)}}
                    accNumber={wallet?.accountNo}
                    description={wallet?.description}
                    official={true}
                    amount={
                      showBalance
                        ? currencyFormat(wallet?.clr_bal_amt)
                        : "********"
                    }
                    icon={
                       wallet.acct_cls_flg
                          ? ""
                          : ""
                    }
                    data={wallet}
                    handleDropDown={handleDropDown}
                  />
                  {/* <CheckBox 
                    onClick={() => {
                      updateCurrentSlide(i);
                    }} 
                  status={walletNo === i} /> *//*}
                </div>
              );
            }
            if (wallet?.gl_code === "21105") {
              return (
                <div className="wocard-item">
                  <WalletCard
                    item={`${wallet.id}wallet`}
                    key={`${wallet.id}wallet`}
                    dropDownItem={[
                      {
                        label: "Set as Primary Wallet",
                        id: "primary",
                        style: "",
                      },
                      { label: "Fund Wallet", id: "fund", style: "" },
                      { label: "Block Wallet", id: "block", style: "" },
                      {
                        label: "Delete Wallet",
                        id: "delete",
                        style: "text-danger",
                      },
                    ]}
                    title="Commision Wallet"
                    accName={wallet?.acct_name}
                    accNumber={wallet?.accountNo}
                    amount={
                      showBalance
                        ? currencyFormat(wallet?.clr_bal_amt)
                        : "********"
                    }
                    handleDropDown={handleDropDown}
                    blocked={wallet.acct_cls_flg}
                    data={wallet}
                  />
                </div>
              );
            }
            return "";
          })}
        </ScrollMenu>*/}
        
        {!history?<div className="woCard-div">
          <div className="title mr-2" style={{marginRight: 10, backgroundColor: history?'':'gray'}} onClick={()=>setHistory(false)}>
            All Transactions
          </div>
          <div className="title" style={{ backgroundColor: history?'gray':''}} onClick={()=>setHistory(true)}>
            Accounts History
          </div>
        </div>:
        <ArrowBack onClick={()=>setHistory(false)}/>
        }
        {history?<TransactionHistory/>:<>        
        <div className="info-div">
        {overall?.map(e =>
        <div className="id-item" style={{marginRight: 5}}>
        <div className="id-left">{e?.title}</div>
        <br/>
        <div className="id-right">{e?.value?.value}</div>
        <br/>
        <div className="id-right">{e?.value?.count}</div>
      </div>
        ) }
          </div>
          <div className="info-div">
        {category?.map(e =>
        <div className="id-item "  style={{marginRight: 5, height: 200}}>
        <div className="id-left">{e?.title}</div>
        <br/>
        <div className="id-right">{e?.value?.value}</div>
        <br/>
        <div className="id-right">{e?.value?.count}</div>
      </div>
        ) }
          </div>
          {/*<div className="info-div">

          <div className="id-item">
            <div className="id-left">Total Transactions</div>
            <br/>
            <div className="id-right">{currencyFormat(totalTrans)}</div>
          </div>
          <div className="id-item">
            <div className="id-left">Amount Sent Out</div>
            <br/>
            <div className="id-right">{currencyFormat(totalDebitTrans)}</div>
          </div>
          <div className="id-item">
            <div className="id-left">Amount Received</div>
            <br/>
            <div className="id-right">{currencyFormat(totalCreditTrans)}</div>
          </div>
        </div>*/}
        <div className="table-div">
          <div className="title">Recent Transactions</div>
          <div className="search-row">
            <select
              className="search-input"
              onChange={(event) => {
                setSearch({ ...search, searchBy: event.target.value });
              }}
            >
              <option value="disabled" disabled>
                Search By...
              </option>
              {columns
                .filter((x) => x.field !== ("tranAmount"||"id"))
                .map((header) => (
                  <option value={header.field}>
                    {transformName(header.headerName)}
                  </option>
                ))}
            </select>
            <Input
              className="search-input"
              //   size="large"
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              placeholder="Search Users by Name, Email or Date"
              prefix={<SearchOutlined className="search-icon" />}
            />
            <div style={{marginLeft:10}} >
                          <AntDropdown
             dropDownOptions={transactionOptions}
             handleDropdownAction={handleTransactionOption}
            ><span>
              <FilterList/> 
              
             </span>
            </AntDropdown>
            </div>
            <AntDropdown
              size="medium"
              dropDownOptions={smuOption}
              handleDropdownAction={handleSmuOption}
            >
                
                <Button className="update-button-SMU">
                  <Settings />
                  <div> SMU Configuration </div>
                </Button>
            </AntDropdown>
            <div className="grow" />
            <Button
              className="update-button-1"
              style={{background:"#FF6700"}}
              onClick={() => setShowCreateSM(true)}
            >
              <PlusCircleFilled />
              <div>Create SM Users</div>
            </Button>
            <Button
              className="update-button-1"
              onClick={() => setShowExport(true)}
            >
              <img src={Exp} alt="export" />
              <div>Export Data</div>
            </Button>
            <AntDropdown
              size="medium"
              dropDownOptions={performTransactionOptions}
              handleDropdownAction={handlePerformTransactions}
            >
              <Button className="update-button">Perform Transaction</Button>
            </AntDropdown>
            
          </div>
          {/*} <div className="tableFilter">
            <select
              className="tfItem"
              defaultValue="disabled"
              onChange={() => {}}
            >
              <option value="disabled" disabled>
                File Type
              </option>
            </select>
            <select
              className="tfItem"
              defaultValue="disabled"
              onChange={() => {}}
            >
              <option value="disabled" disabled>
                Document Type
              </option>
            </select>
            </div>*/}
          <div className="mt-3">
            {wayaTransactions.length ? (
              <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                  className={styles.table}
                  density="comfortable"
                //  rows={wayaTransactions?.fil} TypetranAmount
                  rows={search.searchText ? wayaTransactions.filter(req => req?.id==(search?.searchText) || req?.senderName?.toLowerCase()?.includes(search?.searchText.toLowerCase())|| req?.receiverName?.toLowerCase()?.includes(search?.searchText.toLowerCase())|| req?.tranNarrate?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.tranCategory?.toLowerCase()?.includes(search?.searchText.toLowerCase())|| req?.partTranType?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.createdAt.includes(search?.searchText) || req?.tranAmount === (search?.searchText.toLowerCase())) :
                  wayaTransactions}
                /*  rows ={wayaTransactions?.filter((request) =>
                    request[search?.searchBy]
                      ?.toLowerCase()
                      .includes(search?.searchText?.toLowerCase())
                  )}*/
                  columns={columns}
                  page={pagination.current}
                  paginationMode="server"
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    handleChangeRowsPerPage(newPageSize)
                  }
                  onPageChange={(newPage) => handleChangePage(newPage)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  components={{ Toolbar: GridToolbar }}
                  rowCount={totalTransaction}

                />
              </div>
            ) : ""}

            {/*}  <AntTable
              pagination={null}
              users={wayaTransactions.filter((user) =>
                user[search?.searchBy] && user[search?.searchBy].toLowerCase().includes(search?.searchText.toLowerCase())
              )}
              loading={loading}
              handleGetNext={getWayaTransaction}
              columns={columns}
              />*/}
          </div>
        </div>
        {showCreateWallet ? (
          <CreateWallet
            showModal={showCreateWallet}
            hideModal={setShowCreateWallet}
            getWallets={getWallets}
          />
        ) : (
          ""
        )}
        {showPerform ? (
          <PerformTransaction
            showModal={showPerform}
            hideModal={setShowPerform}
            wallets={walletList}
            banks={bankList}
            title="Wallet Transfer with Waya Official"
            userId={profile?.userId}
          />
        ) : (
          ""
        )}
        {showExport ? (
          <ExportData showModal={showExport} hideModal={setShowExport} dataList={wayaTransactions} />
        ) : (
          ""
        )}
        {showFundWallet ? (
          <FundWallet
            selectedWallet={selectedWallet}
            selectedAccountName={selectedAccountName}
            setSelectedWallet={setSelectedWallet}
            walletList={walletList}
            showModal={showFundWallet}
            hideModal={setShowFundWallet}
            userId={profile?.userId}
            cardList={cardList}
            email={profile.email}
          />
        ) : (
          ""
        )}
        {showSettings ? (
          <WayaOfficialSettings
            walletList={walletList}
            showModal={showSettings}
            hideModal={setShowSettings}
          />
        ) : (
          ""
        )}
        {showBulkExcelTransaction ? (
          <PerformTransactionExcel
            showModal={showBulkExcelTransaction}
            hideModal={setShowBulkExcelTransaction}
            wallets={walletList}
            title="Bulk Wallet Transfer with Waya Official"
            userId={profile?.userId}
          />
        ) : (
          ""
        )}
        {showFormTransaction ? (
          <PerformTransactionForms
            showModal={showFormTransaction}
            hideModal={setShowFormTransaction}
            wallets={walletList}
            banks={bankList}
            title="Web Forms Transfer with Waya Official"
            userId={profile?.userId}
          />
        ) : (
          ""
        )}
        {showCreateMultiWallet&&
        <WalletForms
        showModal={showCreateMultiWallet}
        hideModal = {setShowCreateMultiWallet}
        />
        }

            
             {isModalVisible&& <PinModal
                
                showModal={isModalVisible}
                hideModal={setIsModalVisible}
                otp={otp}
                setOtp={setOtp}
                numInputs={6}
                separator=''
                buttonLabel='Verify'
                title='Verify OTP'
                description='Please input the OTP sent to your Phone'
                handleSubmit={handleValidate}
                pinMode={true}

              />}
        {
          showCreateSM ? 
          <CreateSMU
            showModal={showCreateSM}
            hideModal={setShowCreateSM}
          /> : ''
        }
        {
          showBusConfig ? 
          <BusinessConfig
            user = 'corporate'
            showModal={showBusConfig}
            hideModal={setShowBusConfig}
          /> : ''
        }
        {
          showIndConfig ? 
          <BusinessConfig
            user='user'
            showModal={showIndConfig}
            hideModal={setShowIndCofig}
          /> : ''
        }
        {
          showSMUsers ? 
          <SMUsersTransactions
            showModal={showSMUsers}
            hideModal={setShowSMUsers}
          /> : ''
        }
       {/*} <Modal title="Kindly enter OTP sent to you" 
        visible={isModalVisible}

        

        
        //</Wrapper>onCancel=handleCancel}

          footer={[]}
        >
          <div >
            <div className="d-flex justify-content-center">

            

              <TextField
                id="standard-number"
                value={validateOTP}
                type="number"
                onChange={(e) => { setValidateOTP(e.target.value) }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
            </div>

            <div className="d-flex justify-content-center my-3">
              <Button type="primary" danger onClick={handleValidate}>
                Validate OTP
              </Button>
            </div>

            <p className="d-flex justify-content-center" style={{ color: 'red' }}
              onClick={sendOTP}
            >Didn't get OTP</p>
          </div>
              </Modal>*/}

              </>}
      </Wrapper>
    </Layout>
  );
};
const onWheel = (apiObj, ev) => {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    // console.log(apiObj, ev);
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    // console.log(apiObj, ev);
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    // console.log(apiObj, ev);
    apiObj.scrollPrev();
  }
};
export default WayaOfficial;
