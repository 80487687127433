import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';

const FilterTransactions = ({ showModal, hideModal, searchData, clearFilter, setSearchData, handleFilters }) => {

  const { RangePicker } = DatePicker;

  const [fundingType, setFundingType] = useState('transferFromWayaOfficialAccount');
  const [fundingWallet, setFundingWallet] = useState('');
  const [data, setData] = useState(
    {
      tranCrncy: "NGN",
      eventId: "COMPAYM",
    });

  const [fundingCard, setFundingCard] = useState('');

  // let initial = {
  //   searchCategory,
  //   searchType,
  //   searchAccount,
  //   searchVendo,
  //   searchStatus,
  //   searchAmount,
  //   searchDate,

  // }



  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      width='15%'
    >
      <Wrapper>
        <div className="cw-content">

          <div >


            <div className="input-content">
              <div className="label">Transaction Category</div>
              <select value={searchData.searchCategory} onChange={(e) => { setSearchData({ ...searchData, searchCategory: e.target.value }) }} className='label border border-dark size="2"  col-md-10'>
                <option value='' >Select category</option>
                <option value='funding'>FUNDING</option>
                <option value='transfer'>INTERNAL TRANSFER</option>
                <option value='withdraw'>WITHDRAW</option>
                {//<option value='commission'>COMMISSION</option>
                // <option value='reversal'>REVERSAL</option>
                //<option value='airtime_topup'>AIRTIME</option>
                //  <option value='data'>DATA</option>
                //  <option value='utility'>UTILITY</option>
                }

              </select>
            </div>
            <div className="input-content">
              <div className="label">Transaction Type</div>
              <select value={searchData.searchType} onChange={(e) => { setSearchData({ ...searchData, searchType: e.target.value }) }} className='label border border-dark col-md-10'>
                <option value=''>Show All</option>
                <option value='c'>CREDIT</option>
                <option value='d'>DEBIT</option>
              </select>
            </div>
            {/*<div className="input-content">
    <div className="label">Account{searchData.searchType}</div>
      <select value={searchData.searchAccount} onChange={(e) =>{setSearchData({...searchData, searchAccount:e.target.value })} } className='label border border-dark col-md-8'>
       <option value=''>MIFOS</option>
     </select>
  </div>
  <div className="input-content">
    <div className="label">Vendo</div>
      <select value={searchData.searchVendo} onChange={(e) =>setSearchData({...searchData, searchVendo:e.target.value })} className='label border border-dark col-md-10'>
      <option value='opay'>OPAY</option>
        </select>
  </div>*/}
            <div className="input-content">
              <div className="label">Status</div>
              <select value={searchData.searchStatus} onChange={(e) => setSearchData({ ...searchData, searchStatus: e.target.value })} className='label border border-dark col-md-10'>
                <option value=''>Show All</option>
              </select>
            </div>
            {/*<div className="input-content label">
              <div className="label">Amount</div>
              <input value={searchData.searchAmount} onChange={(e) => { setSearchData({ ...searchData, searchAmount: e.target.value }) }} className="input-content label" />
            </div>*/}
            <div className="label">Date</div>
            <div className='label'>
              <RangePicker value={searchData.searchDate} onChange={(date) => { setSearchData({ ...searchData, searchDate: date }) }} />
            </div>
          </div>

        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'stretch',
          justifyContent: 'stretch',
        }}
      >
        <Button
          style={{
            fontSize: '8px',
            alignItems: 'center',
            width: 70
          }}
          onClick={clearFilter}
        >Clear</Button>
        <Button
          style={{
            background: '#FF4B01',
            color: '#fff',
            fontSize: '8px',
            alignItems: 'center',
            width: 70
          }}
          onClick={handleFilters}
        >
          Apply filter
        </Button>
      </div>
    </Modal>
  );
};

export default FilterTransactions;
