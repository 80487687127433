import React, { useContext, useEffect, useState } from "react";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";
import BankAccount from "../CustomCard/bankAccount";
import { NotificationManager } from "react-notifications";
import AddAccount from "./modal/AddAccount";
import Wrapper from "./style";
import '../ManageCardList/index.scss';
import AddBankAccount from "../../views/WayaOfficial/modal/AddBankAccount";
const ManageBankAccount = ({ userId }) => {
  const [account, setAccounts] = useState([]);
  const [activeProvider, setActiveProvider] = useState(1);
  const dropDownItem = [
    { id: "deleteAccount", label: "Delete Account", style: "text-danger" },
  ];
  const [showAddModal, setShowAddModal] = useState(false);

  const handleDropDown = (id, item) => {
    if (id === "deleteAccount") {
      deleteAccount(userId, item?.accountNumber, item?.accountName);
    }
  };

  const getAccounts = async () => {
    console.log(activeProvider);
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/list/${userId}`
    );
    if (res?.status) {
      setAccounts(res?.data);
    }
  };

  const deleteAccount = async (userId, accountNumber, cardName) => {
    showLoader();
    /*
    const res = await httpDelete(
      `${customBaseUrl.cardUrl}/bankAccount/delete/${userId}/${accountNumber}`
    );*/
    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify({userId, accountNumber}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "DELETEBANKACCOUNT",
      customerName: cardName
    } )

    if (res?.status) {
      getAccounts();
      hideLoader();
      Swal(res?.message);
    } else {
      hideLoader();
      Swal(res?.message);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  console.log(account);
  return (
    <Wrapper>
      <div className="linkedCard">
        <div className="card-div d-flex">
          {account?.map((item) => (
            <div
              key={Math.random}
              style={{
                width: "32%",
                marginRight: "41px",
                marginBottom: "20px",
              }}
            >
              <BankAccount
                accName={item?.accountName}
                accNumber={item?.accountNumber}
                dropDownItem={dropDownItem}
                handleDropDown={handleDropDown}
                data={item}
              />
            </div>
          ))}
        </div>
        <div className="add-div" onClick={() => setShowAddModal(true)}>
          <div>+</div>
          <div>ADD ACCOUNT</div>
        </div>

        {showAddModal ? (
        <AddAccount hideModal={setShowAddModal} showModal={showAddModal} userId={userId} getAccounts={getAccounts}/>         
        ) : (
          ""
        )}
      </div>
    </Wrapper>
  );
};

export default ManageBankAccount;
