import styled from 'styled-components';
import { createUseStyles } from 'react-jss';
export const CardWrapper = styled.div`
  .ant-card-head {
    border: none;
  }
  span {
    font-weight: 800;
  }

  .ant-card-body {
    padding: 18px 18px 10px 18px;
  }
`;



export const useMatStyles = createUseStyles(() => ({
  searchRow: {
    display: 'flex',
    height: '46px',
    alignItems: 'center',
    marginBottom: '12px',
    width: '20%',
    marginLeft: '10px',
  },
  modal:{
    marginTop: 50, 
    minWidth: "600px" 
  },
  modalBody: {
    marginTop: '60px',
    marginLeft: '50px',
    marginRight: '50px'
  },
  selectType: {
    marginRight: '-70px',
    marginTop: '6px',
    borderRadius: '6px !important',
    // border: '0 !important',
    height: '100%',
    width: '171px',
    padding: '10px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FF4B01',
    background: '#FFEAE1',
    border: '1px solid #FF4B01 !important',
    boxSizing: 'border-box',
  },
  grow: {
    flexGrow: 1,
  },
  searchInput: {
    height: '100%',
    width: '279px',
    borderRadius: '6px',
    background: '#F5F5F5 !important',
    border: '1px solid #FF4B01',
    boxSizing: 'border-box',
    color: '#978C8A',
  },

  searchIcon: {
    background: '#F5F5F5',
    color: '#FF4B01',
  },
  buttonIcon:{
    marginBottom: '10px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  manageButtonIcon: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '3px',
    color: '#828282',
  },
  manageAdminButton:{
    height: '80%',
    borderRadius: '6px !important',
    textAlign: 'center',
    background: '#F2F2F2',
    color: '#333333',
    border: '1px solid #E5E5E5',
    marginRight: '18px',
    boxShadow: '0px 0px 0px 0px #E5E5E5',
    fontSize: '9px',
    fontWeight: '700',
  
    "&:hover":{
      background: '#F2F2F2',
      color: '#333333',
    },
    "&:focus":{
      background: '#F2F2F2',
      color: '#333333',
    }
  },

  addCustomRoleButton:{
    borderRadius: '5px !important',
    textAlign: 'center',
    background: '#FF4B01',
    color: '#fff',
    "&:hover":{
      background: '#FF4B01',
      color: '#fff',
    },
    "&:focus":{
      background: '#FF4B01',
      color: '#fff',
    }
  },
  updateButton: {
    height: '80%',
    borderRadius: '5px !important',
    textAlign: 'center',
    background: '#FF4B01',
    color: '#fff',
    "&:hover":{
      background: '#FF4B01',
      color: '#fff',
    },
    "&:focus":{
      background: '#FF4B01',
      color: '#fff',
    }
  },
  dashboardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    alignItems: 'center',
  },

  tierList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  searchInput: {
    height: '100%',
    width: '100%',
    marginTop: '10px',
    borderRadius: '6px',
    background: '#f5f5f5 !important',
    border: '1px solid #ff4b01',
    boxSizing: 'border-box',
    outline: 'none',
    color: '#978c8a',
    ':focus':{
      outline: 'none',
      outlineWidth: '0px',
    },
    ':hover':{
      outline: 'none',
      outlineWidth: '0px',
    }
  },
  searchIcon :{
    background: '#f5f5f5',
    color: '#ff4b01'
  },
  grow: {
    flexGrow: 1
  },
  dashItem: {
    background: '#FBFBFB',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    width: '33%',
    display: 'flex',
    height: '140px',
    padding: '20px',
    marginRight: '5px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  diTop: {
    marginBottom: '36px',
    fontWeight: '600',
    fontSize: '10px',
    fontFamily: 'Inter',
    lineHeight: '19px',
    color: '#1C1C1C',
  },
  dibot: {
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '33px',
    color: '#1C1C1C',
  },
  tierItem: {
    background: '#FFFFFF',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    width: '24%',
    alignItems: 'center',
  },
  lti: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: '#263238',
  },
  rti: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
  },
  tableFilter: {
    display: 'flex',
    marginBottom: '20px',
  },
  tfItem: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '3px !important',
    border: '0 !important',
    marginRight: '10px',
    color: '#9E9E9E',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    padding: '8px',
    width: '203px',
  },
  modalTitle: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    marginBottom: '22px',
  },
  inputWrap: {},
  inputCont: {
    dispaly: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  inputCont2: {
    dispaly: 'flex',
    width: '48%',
    flexDirection: 'column',
  },
  inputLabel: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#000000',
    marginBottom: '4.5px',
  },
  inputInput: {
    background: '#FFFFFF',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5px !important',
    width: '100%',
    padding: '8px !important',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '12px',
  },
  inputContFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  shortTextStyle: {
    wordWrap: 'break-word',
    maxWidth: 150,
    fontWeight: 'normal',
  },
  longTextStyle: {
    wordWrap: 'break-word',
    maxWidth: 200,
    fontWeight: 'normal',
  },
  modal:{
    borderRadius: '50px !important',
    fontFamily: 'Inter',
    
  },
  tableHeader: {
    background: "#ff6700",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    // lineHeight: "15px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#ffffff",
  },
  table: {
    background: "#FFFFFF",
    borderRadius: "10px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#666666",
  }
}));

const Wrapper = styled.div`
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 48px;
  }
  .cw-content {
    display: flex;
    flex-direction: column;
    .input-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 15px;

      .label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
        margin-bottom: 9px;
      }
      input,
      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 8px;
        outline: 0px;
      }
    }
  }
`;

export default Wrapper;
