import React, {useState, useEffect} from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Button from '../../Button/Button';
import Wrapper from './style';


export default function PinModal(props) {
  const {
    showModal,
    hideModal,
    otp,
    setOtp,
    separator,
    title,
    description,
    buttonLabel,
    isResendOtp,
    isResendPin,
    handleSubmit,
    loading,
    numInputs,
    resendOtp,
    setModalAction,
    setShowResendOtp,
    pinMode
  } = props;
  const [locationKeys, setLocationKeys] = useState([]);

 // const history = useHistory();

  
  return (
    <Modal
      id='pin-modal'
      visible={showModal}
      closable={false}
      footer={null}
      centered
      // onCancel={() => {
      //   hideModal(false);
      // }}
      
      maskClosable={pinMode?false:true}

    >
        <Wrapper>

      <div className='modal-body-rs p-2 pb-3'>
        {/* <div className="header-sec-modal">
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/back.png"
            alt=""
          />
          <img className="header-sec-logo" src="./assets/appLogo.png" alt="" />
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/x.png"
            alt=""
          />
        </div> */}

        <div className='waya-modal-body mt-3 center'>
          <h4 className='modal-header-sub-data mb-0 center'>{title}</h4>

          <p className='modal-header-sub-data text-secondary'>{description}</p>

          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            numInputs={numInputs}
            isInputSecure
            containerStyle='otp-cont'
            inputStyle='otp-input'

          />

          <div className='w-100 mt-5 text-center'>
            <Button
              type='button'
              className='btn btn-primary btn-lg w-50'
              loading={loading}
              disabled={loading || false}
              onClick={handleSubmit}
              content={buttonLabel}
            />
          </div>
          <div className={!isResendOtp ? 'd-none' : 'mt-3 text-center'}>
            <a
              className={!isResendOtp ? 'd-none' : 'text-dark decoration-none'}
              onClick={(e) => {
                e.preventDefault();
                // hideModal(false);
                resendOtp(true);
              }}
              href='/#'
            >
              Resend Code
            </a>
          </div>
          <div className={!isResendPin ? 'd-none' : 'mt-5 mb-3 text-center'}>
            <a
              href='/#'
              className={!isResendPin ? 'd-none' : 'text-dark decoration-none'}
              onClick={(e) => {
                e.preventDefault();
                setModalAction('resetPin');
                hideModal(false);
                setShowResendOtp(true);
                // getResetPinOtp(profile.email);
                // setShowResetPin(true);
                // hideModal(false);
              }}
            >
              Reset Pin
            </a>
          </div>
        </div>
      </div>
      </Wrapper>
    </Modal>
  );
}

PinModal.defaultProps = {
  separator: <span>-</span>,
  title: '',
  description: '',
  buttonLabel: 'Login',
  numInputs: 4,
  isResendOtp: false,
};

PinModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  // center: PropTypes.bool.isRequired,
  otp: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  separator: PropTypes.node,
  buttonLabel: PropTypes.string,
  isResendOtp: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  numInputs: PropTypes.number,
  resendOtp: PropTypes.func.isRequired,
};
