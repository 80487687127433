import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge, Switch } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost, httpPut } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "../request-log/export";
import { Filter, FilterList, ThreeDRotation } from "@mui/icons-material";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import FraudEvent from "./fraudevents";

const FraudManagement = () => {
  let roles= localStorage.getItem('roles')
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();

  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const styles = useMatStyles();
  const [logStat, setLogStat] = useState([])
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [totalRequest, setTotalRequest] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [ total, setTotal] = useState(0);
  const [active, setActive] = useState(0);
  const [inActive, setInActive] = useState(0);
  const [status, setStatus] = useState('All')
  const [isEvent, setIsEvent] = useState(false);


  const getRequest = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let request;
    request = await httpGet(
      `${customBaseUrl.fraudUrl}/api/v1/fraud-rule`
    )
    if (request?.status) {
      hideLoader()
      console.log('rules', request);
      setAllRequests(request?.data);
      setTotalRequest(request?.data?.length)
      getStat();
    }else{
      hideLoader();
      console.log(request)
    }
    hideLoader();
  };

  const getStat = async () => {
    if (allRequests?.length > 0) {
      setTotal(allRequests.length)
    const active =  allRequests?.filter(e=>e.activated)
    const inActive =  allRequests?.filter(e=>!e.activated)
    setActive(active.length);
    setInActive(inActive.length);

    }
  }
  
  useEffect(() => {
    //getStat();
    getRequest();
}, [status]);

  

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getRequest(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getRequest(newPage, pagination.pageSize);
  };


  const handleToggle = async (row) => {
    showLoader();
   row['activated'] = !row?.activated;
   let res = await httpPut(`${customBaseUrl.fraudUrl}/api/v1/fraud-rule/edit`,
   row
   );

   if (res?.status) {
    hideLoader();
    getRequest();
   }else {
    console.log(res);
    hideLoader();
   }
   hideLoader();

  }

  var searchInput = useRef({});
  const columns = [    
    

    { field: 'ruleName',
      headerName: "Rule Title",
      width: 300,
      headerClassName: styles.tableHeader,

    },
     
    /*{ field: 'description',
    headerName: "Rule Descrition",
    width: 200,
    headerClassName: styles.tableHeader,    
    },*/
   
    { field: 'maximumAttempt',
    headerName: "No of Attempts",
    headerClassName: styles.tableHeader,
    width: 300 
    },
    /*{ field: 'actionP',
    headerName: "Action Performed",
    headerClassName: styles.tableHeader,
    width: 200 
    }, */
    { field: 'activated',
    headerName: "Action",
    headerClassName: styles.tableHeader,
    width: 300,
    renderCell: ({row}) => <Switch style={{ float: "right" }} checked={row?.activated} onClick={()=>handleToggle(row)} />
    }, 
   
  ];


  
  return (
    <Layout>

<div style={tool}>
      <Row type="flex" justify="end" align="middle">     
            <Button
              style={{ fontSize: "10px", width:100, background: !isEvent?"#27AE60":"", color: !isEvent?"#fff":"" }}
              onClick={() => setIsEvent(false)}

            >
              {" "}
              Fraud Rules
            </Button>
            <Button
              style={{ fontSize: "10px", width:100,  background: isEvent?"#27AE60":"", color: isEvent?"#fff":""  }}
              onClick={() => setIsEvent(true)}
            >
              {" "}
              Fraud Events
            </Button>
           
        </Row>
      </div>




      {!isEvent?<>
      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>All Fraud Rules</p>
          <h2 className="text-left">{totalRequest}</h2>
        </Card.Grid>  
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Active Fraud Rules</p>
          <h2 className="text-left">{active}</h2>
        </Card.Grid>  
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Inactive Fraud Rules</p>
          <h2 className="text-left">{inActive}</h2>
        </Card.Grid>       
      </Card>      
      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allRequests?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allRequests}
            columns={columns}
            page={pagination.current}
          //  paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalRequest}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      </>: <FraudEvent/>}
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests}  />
      }
    
    </Layout>
  );
};

const gridStyle = {
  width: "18%",
  textAlign: "center",
  backgroundColor: "#fff",
  fontWeight: "bold",
  marginLeft: "10px",
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '30px',
  justifyContent:'space-between',
  flexDirection: 'column'
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default FraudManagement;
