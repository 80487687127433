import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { ProfileContext } from '../../store/context/ProfileContext';
import { httpGet, httpPost, customBaseUrl } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import Summary from '../SummaryPage/summary';
import BulkWidrawalForm from './BulkWithrawalForm';
import { WithdrawalWrapper as Wrapper } from './style';

export const Withdrawal = ({
  bankAccountList,
  userId,
  walletList,
  bankList,
  charges,
  record
  //setCurrentView,
}) => {
  const [withdrawMethod, setWithdrawMethod] = useState('savedBeneficiary');
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [postData, setPostData] = useState({});
  const [currentView, setCurrentView] = useState('default');
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [otp, setOtp] = useState('');
  const { profile } = useContext(ProfileContext);
  const {displayData, setDisplayData} = useState({})
  const {transactionType, setTransactionType} =useState('hhhh')
  let {selectedRecipientType, setSelectedRecipientType} = useState("Single User")
  const getBeneficiaryList = async () => {
    const res = await httpGet(
      `${customBaseUrl.withdrawalUrl}/getWithdrawalBen?userId=${userId}`
    );
    if (res?.status) {
      setBeneficiaryList(res.data);
    }
  };

  const resolveBank = async (value) => {
    
    showLoader();
    if (value === '') {
      Swal.fire('Account Number is required');
      return;
    }
    const newPostData = {
      accountNumber: value,
      bankCode: postData.bankCode,
      // bankName: postData.bankName,
    };

    let res;
    if (withdrawMethod === 'wayaBankAccount') {
      res = await httpGet(
        `${customBaseUrl.walletUrl}api/v1/wallet/info/${value}`
      );
  
    }else{
      res = await httpPost(
        `${customBaseUrl.cardUrl}/bankAccount/resolveAccountNumber/WD`,
        newPostData
      );
  
    }
    if (res.status) {
      hideLoader();
      setPostData({
        ...postData,
        crAccountName: withdrawMethod==='wayaBankAccount'?res.data.acct_name :res.data,
        crAccount: value,
      });
    } else {
      Swal.fire(res.message);
    }
    hideLoader();
  };

  const handleSubmit = async () => {
    showLoader();
    let d = new Date();
    let ref = d.getTime();
    let res;
    const localData = {
      "amount": postData.amount,
      "benefAccountNumber": postData.crAccount,
      "debitAccountNumber": postData.walletAccountNo,
      "paymentReference": ref,
      "tranCrncy": "NGN",
      "tranNarration": "internal transfer",
      "tranType": "LOCAL",
      "userId": profile.userId,
    }

    if (withdrawMethod === 'wayaBankAccount') {
      /*res = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/admin/sendmoney`,
        localData
        );*/

        res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
        {
          payload: JSON.stringify(localData),
          "requestId": `REQ${d.getTime()}`,
          "requestType": "WITHDRAW",
          customerName: postData?.acctName
        } )
    }else{
    /*res = await httpPost(
      `${customBaseUrl.withdrawalUrl}/admin/withdrawal/official/fund`,
      {...postData, transRef:`WAYA${ref}`, userId: profile.userId, saveBen:true}
    );*/
      let postDat ={...postData, transRef:`WAYA${ref}`, userId: profile.userId}
    res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
        {
          payload: JSON.stringify( postDat),
          "requestId": `REQ${d.getTime()}`,
          "requestType": "WITHDRAW",
          customerName: postData?.acctName
        } )
    }
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message);
      setCurrentView('default');
      setPostData({});
      setOtp('');
    } else {
      hideLoader();
      Swal.fire('Error', res.message || 'Something went wrong', 'error');
    }
    hideLoader();

  };

  
  const handleVerifyOtp = async () => {
   // return alert(JSON.stringify(postData))
    showLoader();
    // const sender = tab === 'mail' ? data.email : data.phoneNumber;
    const phone = profile.phoneNumber.slice(1);
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/otp/payment/verify`,
      {
        otp,
        phoneOrEmail: phone,
      }
    );

    if (res.status) {
      hideLoader();
     handleSubmit()
    } else {
      hideLoader();
      Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
    }
  };

    const handleSendOtp = async () => {

      if (!postData.walletAccountNo && postData.walletAccountNo !== '') {
        Swal.fire('Wrong', 'Input wallet', 'error');
        hideLoader();
        return;
      }
  
      
      if (!postData.amount ) {
        Swal.fire('Wrong', 'Amount cannot be empty', 'error');
        hideLoader();
        return;
      }
      if (!postData.crAccount ) {
        Swal.fire('Wrong', 'Account no cannot be empty', 'error');
        hideLoader();
        return;
      }
      if (!postData.crAccountName ) {
        Swal.fire('Wrong', 'Account name cant be empty', 'error');
        hideLoader();
        return; 
      }

      setCurrentView('summary');

      const phone = profile.phoneNumber.slice(1);
      // const sender = '2348057661075';
      const res = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/otp/generate/${phone}`
      );
  
      if (res.status) {
        hideLoader();
        Swal.fire('Done', `Otp sent successful`, 'success');
      } else {
        hideLoader();
        Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
      }
    };

    const handleSubmitFile = async (e) => {
      e.preventDefault();
      showLoader();
  
      const formdata = new FormData();
      formdata.append('file', postData.file);
      formdata.append('userId', profile.userId)

      const res = await httpPost(
        `${customBaseUrl.withdrawalUrl}/admin/withdrawal/multipleFund/excel`,
        formdata
      );
  
      
  /*      let d = new Date()
   let   res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
        {
          payload:  formdata,
          "requestId": `REQ${d.getTime()}`,
          "requestType": "WITHDRAWExcel",
          customerName: record?.firstName+' '+record?.lastName
        } )
*/
      if (res.status) {
        hideLoader();
        Swal.fire('Done', res.message, 'success');
        setPostData({});
        setCurrentView('default');

      } else {
        hideLoader();
        Swal.fire('Error', res.message, 'error');
      }
    };


  useEffect(() => {
    showLoader();
    if (withdrawMethod === 'savedBeneficiary') getBeneficiaryList();
    hideLoader();
  }, [withdrawMethod]); // eslint-disable-line react-hooks/exhaustive-deps

  const RecipientTypes = ['Single User', 'Excel Upload', 'web Forms' ];

  return (
    <Wrapper>
    {currentView==='default'? 
    
    <div className='contentBar'>
        <div className="input-content">
            <select value={selectedRecipientType} onChange={(e) =>{
              if(e.target.value === 'Excel Upload'){
                setCurrentView('excel')
              }else if(e.target.value === 'web Forms'){
                setShowBulkModal(true)
                setCurrentView('webForms')
              }
              else{
                setCurrentView('default')
              }
            }
              } className='border border-dark rounded'>
              {RecipientTypes.map((x) => (
                <option key={`recipient${x}`} value={x}>
                  {x}
                </option>
              ))}
            </select>
            </div>
        <select
          onChange={(e) => {
            setWithdrawMethod(e.target.value);
            setPostData({});
          }}
        >
          <option value=''>Select Beneficiary Option</option>
          <option value='savedBeneficiary' key='savedBeneficiary'>
            Withdraw to Saved Beneficiaries
          </option>
          <option value='savedBankAccount' key='savedBankAccount'>
            Withdraw to Saved Bank Account
          </option>
          <option value='newBankAccount' key='newBankAccount'>
            Withdraw to New Bank Account
          </option>
          
        </select>

        {withdrawMethod === 'savedBeneficiary' ? (
          <>
            <select
              onChange={(e) => {
                const [accountNumber, name, bankCode, bankName] =
                  e.target.value.split(',');
                setPostData({
                  ...postData,
                  crAccount: accountNumber,
                  crAccountName: name,
                  saveBen: true,
                  bankCode,
                  bankName,
                });
              }}
            >
              <option value=''>Select Beneficiaries</option>
              {beneficiaryList.length
                ? beneficiaryList.map((item) => (
                    <option
                      value={`${item.accountNumber},${item.name},${item.bankCode},${item.bankName}`}
                      key={item.accountNo}
                    >
                      {`${item.name}-${item.bankName}`}
                    </option>
                  ))
                : ''}
            </select>
            <input
              type='text'
              placeholder="Recipient's Account Number"
              value={postData.crAccount}
            />
            <input
              type='text'
              placeholder="Recipient's Account Name"
              value={postData.crAccountName}
            />
          </>
        ) : (
          ''
        )}

        {withdrawMethod === 'savedBankAccount' ? (
          <>
            <select
              value={`${postData.crAccount},${postData.crAccountName},${postData.bankCode},${postData.bankName}`}
              onChange={(e) => {
                const [accountNumber, accountName, bankCode, bankName] =
                  e.target.value.split(',');
                setPostData({
                  ...postData,
                  crAccount: accountNumber,
                  crAccountName: accountName,
                  bankCode,
                  bankName,
                });
              }}
            >
              <option value=''>Select Saved Bank Account</option>
              {bankAccountList.length
                ? bankAccountList.map((item) => (
                    <option
                      value={`${item.accountNumber},${item.accountName},${item.bankCode},${item.bankName}`}
                      key={item.accountNumber}
                    >
                      {`${item.accountName}-${item.bankName}`}
                    </option>
                  ))
                : ''}
            </select>
            <input
              type='text'
              placeholder="Recipient's Account Number"
              value={postData.crAccount}
            />
            <input
              type='text'
              placeholder="Recipient's Account Name"
              value={postData.crAccountName}
            />
          </>
        ) : (
          ''
        )}

        {withdrawMethod === 'newBankAccount' ? (
          <>
            <select
              onChange={(e) => {
               setPostData({
                 ...postData,
                 bankCode: JSON.parse(e.target.value).bankCode,
                 bankName: JSON.parse(e.target.value).bankName
               })
              }}
            >
              <option value='Select' key='select' selected disabled>
                Select Bank
              </option>
              {bankList?.length
                ? bankList.map((item,i) => (
                    <option value={JSON.stringify(item)} key={i}>
                      {item.bankName}
                    </option>
                  ))
                : ''}
            </select>
            <input
              type='text'
              placeholder="Recipient's Account Number"
              value={postData.crAccount}
              onChange={(e) => {
                if (e.target.value?.length > 9) {
                  resolveBank(e.target.value);
                }
                setPostData({...postData, crAccount: e.target.value})

              }}
            />
            <input
            disabled={true}
              type='text'
              placeholder="Recipient's Account Name"
              value={postData.crAccountName}
            />
          </>
        ) : (
          ''
        )}
        {
          withdrawMethod === 'wayaBankAccount' ? (
            <>
            <input
              type='text'
              placeholder="Recipient's Account Number"
              value={postData.crAccount}
              onChange={(e) => {
                if (e.target.value?.length > 9) {
                  resolveBank(e.target.value);
                }
                setPostData({...postData, crAccount: e.target.value})

              }}
            />
            <input
            disabled={true}
              type='text'
              placeholder="Recipient's Account Name"
              value={postData.crAccountName}
            />
            </>
          ):''
        }
        <input
          type='text'
          placeholder='Amount'
          value={postData.amount}
          onChange={(e) => setPostData({ ...postData, amount: e.target.value })}
        />
        <select     onChange={(e)=>setPostData({...postData, walletAccountNo:JSON.parse(e.target.value).accountNo, acctName: JSON.parse(e.target.value).acct_name})}>
          <option value=''>Select Wallet</option>
          {walletList.length
            ? walletList.map((item) => (
                <option
                 // value={`${item.accountNo},${item?.clr_bal_amt}`}
                 value={(JSON.stringify(item))}
                  key={item}
                >
                  {`${item?.acct_crncy_code}-${item?.accountNo}-${item?.acct_name}`}
                </option>
              ))
            : ''}
        </select>
        <input
          type='text'
          placeholder='Add a Note (Optional)'
          value={postData.narration}
          onChange={(e) => {
            setPostData({ ...postData, narration: e.target.value });
          }}
        />
        <input
          type='password'
          placeholder='PIN'
          value={postData.pin}
          onChange={(e) => setPostData({ ...postData, transactionPin: e.target.value })}
          />
        <button
                onClick={() => {
                  //return alert(postData.amount+ ' '+ (postData.accountNo?postData.accountNo:'')+ ' '+postData.bankName+ ' '+postData.narration)
                 handleSendOtp();
                 /* setDisplayData({
                    receiverName: postData.crAccountName,
                    amount: postData.amount,
                    senderName: postData.walletAccountNo?postData.walletAccountNo:'',
                    tranFees: '',
                    bank: postData.bankName,
                    description: postData.narration
                  });*/

                }}
        >Withdraw</button>
      </div>     
      :
      currentView === 'excel'?<div>
        <div className='contentBar'>

          <div className="input-content">
            <select value={selectedRecipientType} onChange={(e) =>{
              if(e.target.value === 'Excel Upload'){
                setCurrentView('excel')
              }else{
                setCurrentView('default')
              }
            }
              } className='border border-dark rounded'>
              {RecipientTypes.map((x) => (
                <option key={`recipient${x}`} value={x}>
                  {x}
                </option>
              ))}
            </select>
            </div>
        <form class='row g-3 needs-validation' novalidate>
        <div className='col-10 mt-5 mb-3 mx-auto'>
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setPostData({...postData, file:e.target.files[0]})}
            />            
            <div class='invalid-feedback'>File is required.</div>
          </div>
        </div>

        <div class='col-12 text-center mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmitFile}>
            Submit form
          </button>
        </div>
      </form>

          </div>
      </div>:
      currentView === 'webForms'?
      <div>
        <BulkWidrawalForm
        showModal={showBulkModal} 
        hideModal={setShowBulkModal}
         title={'Bulk Withdrawal'}
         userId={profile.userId}
         walletList={walletList}
        bankList={bankList}  
        setCurrentView={setCurrentView}
        />
      </div>
      :
      currentView === 'summary' ? (
        <div
          style={{
            width: '60%',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <Summary
            displayData={
              {receiverName: postData.crAccountName,
              amount: postData.amount,
              senderName: `${postData.acctName}`,
              tranFees: charges?.status=='CUSTOM'?charges?.customAmount:charges?.generalAmount,
              bank: postData.bankName,
              description: postData.narration
            }}
            otp={otp}
            setOtp={setOtp}
            handleClick={() => {
              handleVerifyOtp();
            }}
          />
        </div>
      ) : 
           (
          <div className='backButton'>
            
            <button
              onClick={() => {
                setCurrentView('default');
                setPostData({});
                setOtp('');
              }}
            >
              Go Back
            </button>
          </div>
        )    
      }
    </Wrapper>
  );
};
