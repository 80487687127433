import React, { useState, useEffect } from 'react';
import frontArrow from '../../assets/images/front_arrow.svg';
import backArrow from '../../assets/images/back_arrow.svg';
import upload from '../../assets/images/upload.svg';
import blocked from '../../assets/images/blocked.svg';
import pending from '../../assets/images/pending.svg';
import check from '../../assets/images/check.svg';
import StatImage from './StatImage';
import { StatusHolder, TierDetails, Wrapper } from '.';
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import EditIcon from '@mui/icons-material/Edit';


//<FontAwesomeIcon icon="fa-solid fa-pen" />

<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/>

export default function Tier5({
  handlePage,
  page,
  teir5Status,
  websiteUrl,
  facebookUrl,
  twitterUrl,
  appUrl,
  completed,
  instagramUrl,
  setWebsiteUrl,
  setFacebookUrl,
  setTwitterUrl,
  setInstagramUrl,
  setAppUrl,
  SubmitKycRequirement,
  handleApprove,
  handleReject,
  setViewData,
  setShowViewDocModal,
  handleUpdateStatus
}) {
  const allTier5Data = {
    WEBSITE_URL: websiteUrl,
    APP_URL: appUrl,
    FACEBOOK_URL: facebookUrl,
    INSTAGRAM_URL: instagramUrl,
    TWITTER_URL: twitterUrl,
  };
  const [editWebUrl, setEditWebUrl] = useState(false);
  const [editAppUrl, setEditAppUrl] = useState(false);
  const [editFbUrl, setEditFbUrl] = useState(false);
  const [editInstaUrl, setEditInstaUrl] = useState(false);
  const [editTwitterUrl, setEditTwitterUrl] = useState(false);

  const handleUrlEdit = (e) => {
    setEditWebUrl(true);
    console.log(editWebUrl)
  }
  const handleAppUrlEdit = (e) => {
    setEditAppUrl(true);
    console.log('tier5', Tier5)
  }
  const handleFbUrlEdit = (e) => {
    setEditFbUrl(true)
  }
  const handleInstaUrlEdit = (e) => {
    setEditInstaUrl(true)
  }
  const handleTwitterUrlEdit = () => {
    setEditTwitterUrl(true)
  }
  useEffect(() => {
    console.log('Count is more that 5', editWebUrl);
    console.log('tier5', Tier5)
  }, [editWebUrl, editAppUrl]);
  return (
    <div className='tier' id='tier4'>
      {/* <div className='head my-5'>Upgrade KYC Level</div> */}
      <div className='info my-3'>
        <p className='info-1'>Tier 5</p>
        <div className='info-2'>
          <p className='info-2-t'>status</p>
          <p
            className={`${completed.teir5 ? 'info-2-b' : 'info-2-b-false'
              }  px-3 py-1`}
          >
            {completed.teir5 
              ? 'Completed'
              : 'Not Completed'}
          </p>
        </div>
      </div>
      <div className='table my-2'>
        <table className='w-100'>
          <thead>
            <tr className=''>
              <th className='p-2'>Kyc Requirement</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr className=''>
              <td className='p-3'>Website URL</td>
              <td>
                {/* <StatusHolder status={teir5Status.website_url} /> */}
                <div //className='status mb-3'
                        className={ `d-flex
                          ${teir5Status.website_url
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      >
                        <img
                          
                          src={teir5Status.website_url ? check : pending}
                          alt=''
                          
                          className={` ${teir5Status.website_url
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>{teir5Status.website_url ? "APPROVED"  : 'Not Completed'}</span>
                      </div>
              </td>
              <td>
                <Wrapper>

                  {editWebUrl
                    ? <TierDetails
                      name={websiteUrl}
                      verified={false}
                      setChange={setWebsiteUrl}
                    />
                    : <TierDetails
                      name={websiteUrl}
                      verified={teir5Status.website_url}
                      setChange={setWebsiteUrl}
                    />
                  }

                  <div className='action-2 btn my-2'
                    onClick={() => {
                      setViewData({
                        url: websiteUrl,
                        title: 'WEBSITE URL',
                      });
                      setShowViewDocModal(true);
                    }}>
                    <img src={upload} alt='' className='me-2' />
                    <span>View</span>
                  </div>
                  <button className={`  btn btn my-2' ${teir5Status?.website_url ? 'btn disabled' : 'btn'
                    } my-2`}>
                    {/* <ModeEditOutlineTwoToneIcon onClick={handleUrlEdit} /> */}
                    <EditIcon fontSize='small' onClick={handleUrlEdit} />

                    {/* <ModeTwoToneIcon /> */}
                  </button>
                  <button
                    //className='btn btn-outline-success btn-sm ms-2'
                    className={` btn btn-outline-success btn-sm ms-2' ${teir5Status.website_url ? 'btn disabled' : 'btn'
                  } my-2`}
                    //disabled={teir5Status.website_url || false}
                    //onClick={() => handleApprove(teir5Status.website_url)}
                    onClick={() => handleUpdateStatus('APPROVED', teir5Status.website_url)}
                  >
                    <StatImage
                      srcImage={teir5Status.website_url ? check : pending}
                      className={`me-2 ${teir5Status.website_url
                        ? 'status-true'
                        : 'status-pending'
                        }`}

                    />
                    {teir5Status.website_url ? 'Approved' : 'Approve'}
                  </button>
                  <button
                    className='btn btn-outline-danger btn-sm ms-2'

                    //onClick={() => handleReject(teir5Status.website_url)}
                    onClick={() => handleUpdateStatus('REJECTED', teir5Status.website_url)}
                  >
                    <StatImage
                      srcImage={blocked}
                      className={`me-2 status-true`}
                    />
                    Reject
                  </button>
                </Wrapper>
              </td>
            </tr>

            <tr className=''>
              <td className='p-3'>App URL</td>
              <td>
                {/* <StatusHolder status={teir5Status.app_url} /> */}
                <div //className='status mb-3'
                        className={ `d-flex
                          ${teir5Status.app_url
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      >
                        <img
                          
                          src={teir5Status.app_url ? check : pending}
                          alt=''
                          
                          className={` ${teir5Status.app_url
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>{teir5Status.app_url ? "APPROVED"  : 'Not Completed'}</span>
                      </div>
              </td>
              <td>
                <Wrapper>
                  {editAppUrl
                    ? <TierDetails
                      name={appUrl}
                      verified={false}
                      setChange={setAppUrl}
                    />
                    : <TierDetails
                      name={appUrl}
                      verified={teir5Status.app_url}
                      setChange={setAppUrl}
                    />
                  }

                  <div className='action-2 btn my-2'
                    onClick={() => {
                      setViewData({
                        url: appUrl,
                        title: 'APP URL',
                      });
                      setShowViewDocModal(true);
                    }}>
                    <img src={upload} alt='' className='me-2' />
                    <span>View</span>
                  </div>
                  <button className={`  btn btn my-2' ${teir5Status?.app_url ? 'btn disabled' : 'btn'
                    } my-2`}>
                    <EditIcon fontSize='small' onClick={handleAppUrlEdit} />
                  </button>
                  <button
                    //className='btn btn-outline-success btn-sm ms-2'
                    className={` btn btn-outline-success btn-sm ms-2' ${teir5Status.app_url ? 'btn disabled' : 'btn'
                  } my-2`}
                    onClick={() => handleUpdateStatus('APPROVED', teir5Status.app_url)}
                  >
                    <StatImage
                      srcImage={teir5Status.app_Url ? check : pending}
                      className={`me-2 ${teir5Status.app_url
                        ? 'status-true'
                        : 'status-pending'
                        }`}

                    />
                   {teir5Status.app_url ? 'Approved' : 'Approve'}
                  </button>
                  <button
                    className='btn btn-outline-danger btn-sm ms-2'
                    //onClick={() => handleReject(teir5Status.website_url)}
                    onClick={() => handleUpdateStatus('REJECTED', teir5Status.app_url)}
                  >
                    <StatImage
                      srcImage={blocked}
                      className={`me-2 status-true`}
                    />
                    Reject
                  </button>
                </Wrapper>
              </td>
            </tr>

            <tr className=''>
              <td className='p-3'>Facebook URL</td>
              <td>
                {/* <StatusHolder status={teir5Status.facebook_url} /> */}
                <div //className='status mb-3'
                        className={ `d-flex
                          ${teir5Status.facebook_url
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      >
                        
                        <img
                          
                          src={teir5Status.facebook_url ? check : pending}
                          alt=''
                          
                          className={` ${teir5Status.facebook_url
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>{teir5Status.facebook_url ? "APPROVED"  : 'Not Completed'}</span>
                      </div>
              </td>
              <td>
                <Wrapper>
                  {editFbUrl
                    ? <TierDetails
                      name={facebookUrl}
                      verified={false}
                      setChange={setFacebookUrl}
                    />
                    : <TierDetails
                      name={facebookUrl}
                      verified={teir5Status.facebook_url}
                      setChange={setFacebookUrl}
                    />
                  }
                  
                  <div className='action-2 btn my-2'
                    onClick={() => {
                      setViewData({
                        url: facebookUrl.filename,
                        title: 'FACEBOOK URL',
                      });
                      setShowViewDocModal(true);
                    }}>
                    <img src={upload} alt='' className='me-2' />
                    <span>View</span>
                  </div>
                  <button className={`  btn btn my-2' ${teir5Status?.facebook_url ? 'btn disabled' : 'btn'
                    } my-2`}>
                    <EditIcon fontSize='small' onClick={handleFbUrlEdit} />
                  </button>
                  <button
                   // className='btn btn-outline-success btn-sm ms-2'
                   className={` btn btn-outline-success btn-sm ms-2' ${teir5Status.facebook_url ? 'btn disabled' : 'btn'
                  } my-2`}
                    onClick={() => handleUpdateStatus('APPROVED', teir5Status.facebook_url)}
                  >
                    <StatImage
                      srcImage={teir5Status.facebook_url ? check : pending}
                      className={`me-2 ${teir5Status.facebook_url
                        ? 'status-true'
                        : 'status-pending'
                        }`}

                    />
                   {teir5Status.facebook_url ? 'Approved' : 'Approve'}
                  </button>
                  <button
                    className='btn btn-outline-danger btn-sm ms-2'
                    onClick={() => handleUpdateStatus('REJECTED', teir5Status.facebook_url)}
                  >
                    <StatImage
                      srcImage={blocked}
                      className={`me-2 status-true`}
                    />
                    Reject
                  </button>
                </Wrapper>
              </td>
            </tr>

            <tr className=''>
              <td className='p-3'>Instagram URL</td>
              <td>
                {/* <StatusHolder status={teir5Status.instagram_url} /> */}
                <div //className='status mb-3'
                        className={ `d-flex
                          ${teir5Status.instagram_url
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      >
                        <img
                          
                          src={teir5Status.instagram_url ? check : pending}
                          alt=''
                          
                          className={` ${teir5Status.instagram_url
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>{teir5Status.instagram_url ? "APPROVED"  : 'Not Completed'}</span>
                      </div>
              </td>
              <td>
                <Wrapper>
                  {editInstaUrl
                    ? <TierDetails
                      name={instagramUrl}
                      verified={false}
                      setChange={setInstagramUrl}
                    />
                    : <TierDetails
                      name={instagramUrl}
                      verified={teir5Status.instagram_url}
                      setChange={setInstagramUrl}
                    />
                  }
                  {/* <TierDetails
                    name={instagramUrl}
                    verified={teir5Status.instagram_url}
                    setChange={setInstagramUrl}
                  /> */}
                  <div className='action-2 btn my-2'
                    onClick={() => {
                      setViewData({
                        url: instagramUrl,
                        title: 'INSTAGRAM URL',
                      });
                      setShowViewDocModal(true);
                    }}>
                    <img src={upload} alt='' className='me-2' />
                    <span>View</span>
                  </div>
                  <button className={`  btn btn my-2' ${teir5Status?.instagram_url ? 'btn disabled' : 'btn'
                    } my-2`}>
                    <EditIcon fontSize='small' onClick={handleInstaUrlEdit} />
                  </button>
                  <button
                    //className='btn btn-outline-success btn-sm ms-2'
                    className={` btn btn-outline-success btn-sm ms-2' ${teir5Status.instagram_url ? 'btn disabled' : 'btn'
                  } my-2`}
                    onClick={() => handleUpdateStatus('APPROVED', teir5Status.instagram_url)}
                  >
                    <StatImage
                      srcImage={teir5Status.instagram_url ? check : pending}
                      className={`me-2 ${teir5Status.instagram_url
                        ? 'status-true'
                        : 'status-pending'
                        }`}

                    />
                    {teir5Status.instagram_url ? 'Approved' : 'Approve'}
                  </button>
                  <button
                    className='btn btn-outline-danger btn-sm ms-2'
                    onClick={() => handleUpdateStatus('REJECTED', teir5Status.instagram_url)}
                  >
                    <StatImage
                      srcImage={blocked}
                      className={`me-2 status-true`}
                    />
                    Reject
                  </button>

                </Wrapper>
              </td>
            </tr>

            <tr className=''>
              <td className='p-3'>Twitter URL</td>
              <td>
                {/* <StatusHolder status={teir5Status.twitter_url} /> */}
                <div //className='status mb-3'
                        className={ `d-flex
                          ${teir5Status.twitter_url
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      >
                        <img
                          
                          src={teir5Status.twitter_url ? check : pending}
                          alt=''
                          
                          className={` ${teir5Status.twitter_url
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>{teir5Status.twitter_url ? "APPROVED"  : 'Not Completed'}</span>
                      </div>
              </td>
              <td>
                <Wrapper>
                  {editTwitterUrl
                    ? <TierDetails
                      name={twitterUrl}
                      verified={false}
                      setChange={setTwitterUrl}
                    />
                    : <TierDetails
                      name={twitterUrl}
                      verified={teir5Status.twitter_url}
                      setChange={setTwitterUrl}
                    />
                  }
                  {/* <TierDetails
                    name={twitterUrl}
                    verified={teir5Status.twitter_url}
                    setChange={setTwitterUrl}
                  /> */}
                  <div className='action-2 btn my-2'
                    onClick={() => {
                      setViewData({
                        url: twitterUrl,
                        title: 'TWITTER URL',
                      });
                      setShowViewDocModal(true);
                    }}>
                    <img src={upload} alt='' className='me-2' />
                    <span>View</span>
                  </div>
                  <button className={`  btn btn my-2' ${teir5Status?.twitter_url ? 'btn disabled' : 'btn'
                    } my-2`}>
                    <EditIcon fontSize='small' onClick={handleTwitterUrlEdit} />
                  </button>
                  <button
                    //className='btn btn-outline-success btn-sm ms-2'
                    className={` btn btn-outline-success btn-sm ms-2' ${teir5Status.twitter_url ? 'btn disabled' : 'btn'
                  } my-2`}
                    onClick={() => handleUpdateStatus('APPROVED', teir5Status.twitter_url)}
                  >
                    <StatImage
                      srcImage={teir5Status.twitter_url ? check : pending}
                      className={`me-2 ${teir5Status.twitter_url
                        ? 'status-true'
                        : 'status-pending'
                        }`}

                    />
                    {teir5Status.twitter_url ? 'Approved' : 'Approve'}
                  </button>
                  <button
                    className='btn btn-outline-danger btn-sm ms-2'
                    onClick={() => handleUpdateStatus('REJECTED', teir5Status.twitter_url)}
                  >
                    <StatImage
                      srcImage={blocked}
                      className={`me-2 status-true`}
                    />
                    Reject
                  </button>
                </Wrapper>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
          className='info my-3'
        >
          <div
            className='info-2'
            onClick={() => {
              if (!completed.teir5) {
                SubmitKycRequirement(
                  'allData',
                  JSON.stringify(allTier5Data),
                  5
                );
              }
            }}
          >
            <p
              style={{ opacity: completed.teir5 ? 0.5 : 1 }}
              className='info-2-b-false px-3 py-1'
            >
              Save
            </p>
          </div>
        </div>
      </div>
      <div className='tier-foot mb-2'>
        <div className='tier-foot-1'>
          NB: Supported files for upload includes, jpeg, png, pdf
        </div>
        <div className='tier-foot-2 me-0'>
          <div
            className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('previous');
            }}
          >
            <img className='me-2' src={backArrow} alt='' /> Previous
          </div>{' '}
          <div className='page mx-1'>{page}</div>{' '}
          <div
            className={`${page === 5 ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('next');
            }}
          >
            Next
            <img className='ms-2' src={frontArrow} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
