import { Button} from "antd";
import { useEffect, useState } from "react";
import { useMatStyles } from "./style";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import ExportData from "../WayaOfficial/modal/exportData";
import Swal from "sweetalert2";
import moment from "moment";
import { ExportOutlined } from "@ant-design/icons";
import CreateCardProduct from "./modals/createCardProduct";
import { ArrowBack } from "@mui/icons-material";
import { CSVLink } from "react-csv";
const Container = `styled.div`;
const CardProducts = ({setShowProducts}) => {
  const [metaData, setMetaData] = useState(0);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [products, setProducts] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [showCreateCardProduct, setShowCreateCardProduct] = useState(false);
  const styles = useMatStyles();


  const columns = [
  
    { field: 'productName',
      headerName: 'Product Name',
      width: 250 ,
      //    headerClassName: styles.tableHeader

    },
    { field: 'chargeFee',
      headerName: 'Charge Fee',
      width: 250 ,
      //    headerClassName: styles.tableHeader

    },
    { field: 'maintenanceFee',
      headerName: 'Maintenance Fee',
      width: 250 ,
      //    headerClassName: styles.tableHeader

    },
    
    { field: 'createdAt',
    headerName: "Date",
    width: 150 ,
    // headerClassName: styles.tableHeader,
    renderCell: ({row}) => `${row?.createdAt[2]}/${row?.createdAt[1]}/${row?.createdAt[0]}` 
    },
    ];  


  const handleChangePage = async (newPage) => {
    setPagination({ ...pagination, current: newPage });
    // await getProducts(newPage, newPage, pagination.pageSize);

  };
  const handleChangeRowsPerPage = async (e) => {
    console.log(e)
    setPagination({ ...pagination, current: 0, pageSize: e });
  //await getProducts(e,1, e);
  };

  const getProducts = async (e,page, size) => {
    e?.preventDefault();
    showLoader();
    const pages = page === 0 ? 0 : page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.cardUrl}/card/list/card-product`
    );
    if (request?.status) {
      console.log(request)
      hideLoader();
      setProducts(request?.data)
      setMetaData(request?.data?.lenght);
    }else{
      console.log(request)
      hideLoader();

    }
    
   
  };

  
  useEffect(() => {
  
  }, []);
  useEffect(()=>{
    getProducts()
  },[pagination?.pageSize, pagination.current]);

  return (
    <>

      <Container>

        <div className={styles.searchRow}>
          <div onClick={()=>setShowProducts(false)}>
            <ArrowBack/>
          </div>
          <div/>
          <div/>

         <div>
          <Button
                size="medium"
                style={{ background: "#27AE60", fontSize: "12px", color: "#fff" }}
              >
            <CSVLink
          data={products}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`cards product Type.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          Export
        </CSVLink>
          </Button>
        </div>
        <div className={styles.grow} />
        
          <Button     
          onClick={()=>setShowCreateCardProduct(true)}     
           className={styles.updateButton} size="medium">
            Create New Product
          </Button>
        </div>
                  
        <div style={{ height: 800, width: "100%" }}>
        {products?.length > 0 && <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={products}
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            // paginationMode="server"
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}       
        />}
  </div>
      {showCreateCardProduct ? (
        <CreateCardProduct
          showModal={showCreateCardProduct}
          hideModal={setShowCreateCardProduct}
          getProducts={getProducts}
        />
      ) : (
        ""
      )}
      </Container>
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={products} file={"KYC users tier "} />
      }
    </>
  );
};
export default CardProducts;
const gridStyle = {
  width: "23%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};
