import React, { useState, useEffect } from "react";
import moment from "moment";
import Layout from "../../components/Layout";
import useMatStyles from "./style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt, Stack } from "@mui/material";
import { Button, Input, Space, Modal } from "antd";
import { ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarColumnsButton } from "@mui/x-data-grid";
import CopyToClipboardComponent from "../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import { ExportOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { transformName } from "../../utils/helper";
import FilterTransactions from "./modals/filter";
import { useTheme } from "styled-components";
import SearchInput from "../../components/CustomInput/SearchInput";
import CreateAccount from "./modals/createAccount";
import FundWallets from "./modals/fundingWallets";
import MassTransaction from "./massTransaction"
import ExportTrans from "./modals/export";
import Transfer from "./modals/transfer";
import { CSVLink } from "react-csv";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}
const Accounts = () => {
  const [transactions, setTransactions] = useState([]);
  const theme = useTheme();

  const styles = useMatStyles();
  const matclasses = useMatStyles({ theme });

  const statistics = ["Total Accounts", "Active Accounts", "Inactive Accounts"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showFilter1, setShowFilter1] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] = useState(false);
  const [showFundingModal, setShowFundingModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalDebit, setTotalDebit] = useState(0)
  const [totalCreditDebit, setTotalCreditDebit] = useState(0)
  const [accounts, setAccounts] = useState([])
  const [funding, setFunding] = useState({})
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExport, setShowExport] = useState(false)
  const [activeAccounts,setActiveAccounts] = useState(0);
  const [inActiveAccounts, setInaactiveAccounts] = useState(0)
  const [showMass, setShowMass] = useState(false);
  const [filteredDate, setFilteredDate] = useState([])
  const [searchField, setSearchField] = useState(false)
  
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState(initial)
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
  };

  const ViewFunding = (row) => {
    setFunding(row);
    setShowFundingModal(!showFundingModal);
  };
  const ViewTransfer = (row) => {
    setFunding(row);
    setShowTransferModal(!showTransferModal);
  };

  const handleDeleteAccount = async (row) => {
    //return alert(JSON.stringify(row));
    showLoader();
    let deleteData =
    {
      "customerAccountNo": row?.accountNo
    }

    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/closure`,
      deleteData
    );
    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      await getAccounts();
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const DeleteAccount = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.value) {
        handleDeleteAccount(row);
      }
    });
  };

  const ActivateAccount = async (row,state) => {
    //return alert(JSON.stringify(row));
    showLoader();
    let ActivateData =
    {
      "block": state,
      "customerAccountNo": row?.accountNo
    }

    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/block`,
      ActivateData
    );
    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      await getAccounts();
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
 
  const dropDownOptions = ["Activate", "Fund",  "Transfer", "Delete"];
  const handleDropdownAction = (item, row) => {
    if (item === "Fund") ViewFunding(row);
    if (item === "Transfer") ViewTransfer(row);
    if (item === "Activate") ActivateAccount(row, false);
    if (item === "Delete") DeleteAccount(row);
    if (item === "Block") ActivateAccount(row, true);
    if (item === "Unblock") ActivateAccount(row, false);
    if (item === "Deactivate") ActivateAccount(row, true);
  


  };
  const columns = React.useMemo(() => [
    {
      field: "acct_opn_date",
      headerName: "Date Created",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "accountNo",
      headerName: "Account Number",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "nubanAccountNo",
      headerName: "Nuban Account No",
      width: 150,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "gl_code",
      headerName: "GL Code",
      width: 100,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "accountType",
      headerName: "Account Type",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "acct_crncy_code",
      headerName: "Currency",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "acct_name",
      headerName: "Account Holder",
      description: "Category Column",
      sortable: true,
      width: 250,
      headerClassName: styles.tableHeader,
    },
    {
      field: "clr_bal_amt",
      headerName: "Cleared Balance",
      width: 150,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "un_clr_bal_amt",
      headerName: "Uncleared Balance",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    
    
    {
      field: "lien_amt",
      headerName: "Lien Amount",
      width: 150,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "cum_cr_amt",
      headerName: "Cum. Credit Amount",
      width: 150,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "cum_dr_amt",
      headerName: "Cum. Debit Amount",
      width: 150,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "notify_email",
      headerName: "Notify Email",
      width: 100,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "last_tran_date",
      headerName: "Last Transanction Date",
      width: 100,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "del_flg",
      headerName: "Status",
      width: 100,
      editable: false,
      description: "Amount Column",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => !row?.del_flg && !row?.acct_cls_flg ?'Active': 'Inactive'
    },
    {
      field: "walletDefault",
      headerName: "DEFAULT ACCOUNT ",
      width: 100,
      editable: false,
      description: "Amount Column",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => row?.walletDefault ?'True': 'False'
    },
    {
      field: "hashed_no",
      headerName: "Hashed No",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "id",
      headerName: " Action",
      width: 100,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={ [ "Fund", !row?.del_flg && !row?.acct_cls_flg ?"Deactivate":"Activate", !row?.acct_cls_flg?'Block':'Unblock',  "Transfer", "Delete"]}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ]);
  const getAccounts = async (e) => {
    e?.preventDefault();
    //setAccounts([]);
    showLoader();

    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);
    /*const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    */
    const url1 = `${customBaseUrl.walletUrl}/api/v1/wallet/all/accounts?filter=${search.searchText}&fromdate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD') }&page=${pagination?.current}&size=${pagination?.pageSize}&todate=${moment(filteredDate?.todate?? (new Date()).setMonth(month + 1)).format('YYYY-MM-DD')}`;
    const url2 = `${customBaseUrl.walletUrl}/api/v1/wallet/all/accounts?fromdate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD') }&page=${pagination?.current}&size=${pagination?.pageSize}&todate=${moment(filteredDate?.todate?? (new Date()).setMonth(month + 1)).format('YYYY-MM-DD')}`;
    const response = await httpGet(search.searchText ? url1 : url2);
    if (response.status === true) {
      setAccounts(response?.data?.account);
      console.log(response?.data);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalItems);
      hideLoader();
    } else {
      hideLoader();
    }
  };

  
  const getAccountsStats = async (page, size) => {
    setAccounts([]);
    showLoader();

    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/total-inactive-count`
    );
    if (response.status === true) {
      setInaactiveAccounts(response.data);
      console.log('inactive',response.data);
      //setFiltered(response.data.content)
      hideLoader();
    } else {
      hideLoader();
    }

    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/total-active-count`
    );
    if (res.status) {
      setActiveAccounts(res.data);
      console.log('active',res.data);
      //setFiltered(response.data.content)
      hideLoader();
    } else {
      hideLoader();
    }



  };


  useEffect(() => {
    getAccounts();
    getAccountsStats();
  }, [pagination.current, pagination.pageSize]);

  const handleShowFilter = () => {
    setShowFilter1(true)
    setSearch({ ...search, searchText: '' })
  }

  const handleFilter = () => {
    console.log('searchData', searchData);
    //getTransactions();
    setFiltered(false)
    setShowFilter(false)
    setSearch({ ...search, searchText: '' })



  }
  const handleClearFilter = () => {
    //  return alert(JSON.stringify(transactions))
    setSearch({ ...search, searchText: '' })
    setSearchData(initial)
    setShowFilter(false)
    setFiltered(true)
  }

  const handleSearch = () => {
    if (!search.searchText) {
      return;
    }

    //alert(JSON.stringify(transactions.filter(req=>req?.paymentReference.toLowerCase().includes(search?.searchText.toLowerCase())||req?.tranCategory.toLowerCase().includes(search?.searchText.toLowerCase()))))


  }

 
  return (
    <Layout>

      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
        {statistics.map((item, index) => (
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              <span style={{ float: "left" }} className={styles.diTop}>
                {item}
              </span>
              {item === 'Total Accounts' ? <span className={styles.money}><br /> {activeAccounts+inActiveAccounts}</span> :
                item === 'Active Accounts' ? <span className={styles.money}><br/> {activeAccounts}</span> :
                  <span className={styles.money}><br /> {inActiveAccounts}</span>}
            </div>
          </Paper>
        ))}
      </MatGrid>
      <div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10, backgroundColor: "#ff6700"}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div>

      <div hidden={!searchField}  style={{background:'#ffffff', padding: 10}}>
        <form onSubmit={getAccounts}>
        <div>
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Account Number</span>        
          <input type="text" aria-label="First name" class="form-control"
            onChange={(e)=>setSearch({...search, searchText: e?.target?.value})}
          />
        </div>
        </div>
      
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Search</button>
      </div>
      </form>
      </div>

      <div style={tool} class="col-md-12">
        {/*<div className={styles.searchRow}>
          <Input
            className={styles.searchInput}
            placeholder="Search by Account Number"
            value={search.searchText}
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>
        {/*<div className={styles.searchRow}>
          <FilterList onClick={handleShowFilter} />
          Filter
          </div>*/}
          <div>

          </div>
          <div/>
        <div style={{ flexDirection: 'row', marginLeft: 50 }}>
        <MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <ExportOutlined/>
            <CSVLink
          data={search.searchText ? accounts.filter(req => req?.accountNo.toLowerCase()?.includes(search?.searchText.toLowerCase())) : accounts}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`accounts.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          EXPORT AS EXCEL
        </CSVLink>
          </MatButton>

          {/*<Button
          onClick={ ()=>setShowExport(true)}
            variant="contained"
            size="medium"
            style={{ background: '#27AE60', fontSize: '10px', width:100 }}
          >
            Export
          </Button>
          {/*<Button
            variant="contained"
            size="medium"
            style={{ background: '#27AE60', fontSize: '10px' }}
            onClick={() => setShowMass(true)}
          >

            Perform Mass Transaction
        </Button>*/}
          {showMass? (<Modal
            title="Mass Transaction"
            centered
            visible={showMass}
            //onOk={() => setVisible(false)}
            onCancel={() => setShowMass(false)}
            width={500}
            footer={[

            ]}
          >
            < MassTransaction  />
          </Modal>):''}
          <Button
            variant="contained"
            size="medium"
            style={{ background: '#FF4B01', fontSize: '10px' }}
            onClick={() => setShowFilter(true)}
          >
            Create Account
          </Button>
        </div>
      </div>
      {showFundingModal && 'hhhhhhhhhhh'}
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            className={styles.table}
            rows={search.searchText ? accounts.filter(req => req?.accountNo.toLowerCase()?.includes(search?.searchText.toLowerCase())) : accounts}
            density="comfortable"
            paginationMode="server"
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            components={{ Toolbar: GridToolbar }}

            pagination
            rowCount={metaData}
          />
        </div>
      </MatGrid>

      {
        showFundingModal && (
          <FundWallets
            accountNo={funding.accountNo}
            accountName = {funding?.acct_name}
            showModal={showFundingModal}
            hideModal={setShowFundingModal}
            getAccounts={getAccounts}
          />
        )
      }
       {
        showTransferModal && (
          <Transfer
            accountNo={funding.accountNo}
            accountName = {funding?.acct_name}
            showModal={showTransferModal}
            hideModal={setShowTransferModal}
            getAccounts={getAccounts}
          />
        )
      }

      {showFilter1 && (
        <FilterTransactions
          showModal={showFilter1}
          hideModal={setShowFilter1}
          clearFilter={handleClearFilter}
          searchData={searchData}
          setSearchData={setSearchData}
          handleFilters={handleFilter}
        />)
      }
      {
        showFilter && (
          <CreateAccount
            showModal={showFilter}
            hideModal={setShowFilter}

          />
        )
      }
       {showExport &&
        <ExportTrans showModal={showExport} hideModal={setShowExport} dataList={accounts} />
     }

    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Accounts;
