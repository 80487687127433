import React, { useState } from 'react';
import SearchDropdown from '../CustomDropdown/SearchDropdown';
import { searchUsers } from '../../store/httpCalls';

export default function PayToWayaId({ data, setData, setQuery, query }) {
  const [options, setOptions] = useState([]);

  const handleSearch = async () => {
    if (query !== '') {
      const result = await searchUsers(query);
      setOptions(result);
    }
  };

  const handleSelectOption = (selectedUser) => {
    setData({ ...data, ...selectedUser });
    setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);
    setOptions([]);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <input
          className='mb-2'
          type='text'
          placeholder='Receiver Id'
          value={data.userId}
          onChange={(e) => {
            setData({...data, userId: e.target.value})
           // setQuery(e.target.value);
            //handleSearch();
          }}
        />
        {//<SearchDropdown options={options} handleClick={handleSelectOption} />
        }
      </div>
    </>
  );
}
