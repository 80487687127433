import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import useMatStyles from "../style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt, TextField } from "@mui/material";
import { Button, Input, Space } from "antd";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { currencyFormat, transformName } from "../../../utils/helper";
import Exp from "../../../assets/images/export.svg";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { ProfileContext } from "../../../store/context/ProfileContext";
import { useContext } from "react";
import { CSVLink } from "react-csv";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}

const AllTransaction = () => {
  const styles = useMatStyles();
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
  
    fetchList()    
  }, []);
 
 
 
  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === 'undefined' || obj[propName] === '') {
        obj[propName]=null;
      }
    }
    return obj
  }

  const generateReport = async (e) => {
    e.preventDefault();

    const data = {
      filter: filteredData?.filter ?? null,
      fromDate: filteredDate?.fromdate ?? null,
      recipientEmail: filteredData?.email,
      reportType: filteredData?.tranCategory,
      toDate: filteredDate?.todate ?? null
    }

    // return console.log('data', clean(data));
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.statementUrl}/reports/fetch`,
      clean(data)
    );
    

    if (response.status === true) {

      hideLoader();
      Swal.fire('Done', response?.message)
      setFilteredData({
        ...filteredData,
        email: '',
        filter: '',
        tranCategory: ''
      });

    } else {
      NotificationManager.error(response?.message)
      Swal.fire('Error', response?.message)      
      hideLoader();
    }
  };

  const fetchList = async () => {
    const res = await httpGet(
      `${customBaseUrl.statementUrl}/reports/list`
    );
    
    if (res?.status) {
      //setFiltered(response.data.content)
      setList(res.data);
      hideLoader();
    } else {
      console.log(res)
      hideLoader();   
     }

     };
 
  return (
    <Layout>

        <>
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >       

    </MatGrid>
      {/*<div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div>*/}
      <div >
        <form onSubmit={generateReport}>
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Report Type</span>
        <select class="form-select" id="inputGroupSelect01" required
                value={filteredData?.tranCategory}

          onChange={(e)=>setFilteredData({...filteredData, tranCategory: e?.target?.value})}
        >
          <option selected>Choose...</option>
          {
            list?.map((e,i)=>
            <option key={i} value={e?.report}>{e?.report}</option>
          )
          }
        </select>
      </div>
      <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Filter</span>        
          <input type="text" class="form-control"  aria-label="tranId"
                    value={filteredData?.filter}

            onChange={(e)=>setFilteredData({...filteredData, filter: e?.target?.value})}

          />
        </div>
      </div>
      <div className="row">
        
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Email</span>        
          <input type="email" aria-label="Ref" class="form-control" required
          value={filteredData?.email}
            onChange={(e)=>setFilteredData({...filteredData, email: e?.target?.value})}

          />
        </div>
        {/*<div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Transaction Id</span>        
          <input type="text" class="form-control"  aria-label="tranId"
            onChange={(e)=>setFilteredData({...filteredData, tranId: e?.target?.value})}

          />
        </div>*/}
      </div>
      {/*<div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Account Number</span>        
          <input type="text" aria-label="First name" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Email</span>        
          <input type="email" class="form-control"  aria-label="Email"
             onChange={(e)=>setFilteredData({...filteredData, createdEmail: e?.target?.value})}
          />
        </div>
      </div>*/}
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
                    value={filteredDate?.fromdate}

            onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}            
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input  type="date" class="form-control" 
                              value={filteredDate?.todate}
          placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Generate</button>
      </div>
      </form>
      </div>
      
      {/*<div className="d-flex justify-content-between">
        <div style={tool} class="col-md-5">
        </div>
        <div >
          <MatButton
            variant="contained" 
            style={{marginRight:20}}
            onClick={()=>setShowStatement(true)}
          >
            <img src={Exp} alt="export" />
            <div>Generate Statement</div>
          </MatButton> 
         
        </div>
      </div>*/}

      <div >
        {/*<div class="col-md-12">
 <MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <img src={Exp} alt="export" />           
            <CSVLink
          data={transactions}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`Transactions-history.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </MatButton> 
          <MatButton
            //className="update-button-1"
            //onClick={() => generatePDF(transactions, filteredDate)}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>DOWNLOAD AS PDF</div>
          </MatButton>           
          
      </div>*/}
      </div>
    </>
    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default AllTransaction;
