import {
    Checkbox,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
  } from "@mui/material";
  import { Button } from "antd";
  import React, { useEffect, useState } from "react";
  import { Modal } from "react-bootstrap";
  import { getArraryString, transformName } from "../../../utils/helper";
  import useStyles from "../style";
import { customBaseUrl, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
  const NewUnit = ({
    showUnitModal,
    setShowUnitModal,
    getMenus,
  }) => {
    console.log(showUnitModal);
    const styles = useStyles();
  
  
    const [data, setData] = useState({name:'', description:'', isActive: true})
  
  
    const onHideModal = () => {
      const role = { ...showUnitModal };
      role.isVisible = false;
      Object.apply(role);
      setShowUnitModal(false);
    };

    const handleSave = async () => {
        if(!data.name || !data?.description) return
        showLoader();

        const res = await httpPost(`${customBaseUrl.roleUrl}/api/v1/menu/`,data)

        if(res.status){
            hideLoader();
            onHideModal();
            getMenus();
            Swal.fire('Done', res?.message);
        } else {
            hideLoader();
            console.log(res);
        }
    }
  
    return (
      <Modal
        className={styles.modal}
        show={showUnitModal}
        onHide={onHideModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          closeButton
        >
          <span
            style={{
              fontWeight: "600",
              fontsize: "14px",
              lineHeight: "24px",
              textAlign: "center",
              width: "100%",
              color: "#000",
            }}
          >
            {" "}
            Create Menu {}{" "}
          </span>
          <br></br>
        </Modal.Header>
  
        <Modal.Body>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
          }}
        >
            <label >
                Menu Name
            </label>
            <input 
            className="form-control"
            onChange={(e)=>setData({...data, name: e?.target?.value})}
            value={data?.name}
            />            
         </div>
         <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
          }}
        >
            <label >
              Menu  Descriptions
            </label>
            <input 
            className="form-control"
            onChange={(e)=>setData({...data, description: e?.target?.value})}
            value={data?.description}
            />            
         </div>
         <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
          }}
        >
            <label >
                Status
            </label>
            <select 
            className='form-select border'
            value={data?.isActive}
                onChange={(e)=>setData({...data, isActive: e?.target?.value})}
            >
                <option value={true}>TRUE</option>
                <option value={false}>FALSE</option>
            </select>                      
         </div>
          <div  style={{display: 'flex', margin:20, justifyContent: 'center', alignItems: 'center'}}>
          <button className="btn btn-primary" onClick={handleSave}>Save</button>

            </div>

        </Modal.Body>
      </Modal>
    );
  };
  
  export default NewUnit;
  