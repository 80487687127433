import styled from 'styled-components';

export const WithdrawalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .contentBar {
    width: 50%;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 43px;
    input,
    select {
      background: #f5f5f5;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      width: 100%;
      padding: 14px;
      font-size: 14px;
      line-height: 17px;
      color: #828282;
      margin-bottom: 19px;
    }
    button {
      background: #ff4b01;
      border-radius: 3px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      width: 100%;
      padding: 13px;
    }
  }
`;
