import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const UserTypeModal = ({ showModal, hideModal, title, setUserType, userType }) => {
  const navigate = useNavigate();
  const handleOnClose = (event, reason) =>{
 
    console.log(event);
    console.log(reason);
    if (reason && reason == "backdropClick"){
      return;
    }
    if (userType == "") {
      return;
    }
    hideModal(false);
    
  }
  const handleOnCancel = () => {
    hideModal(false);
    navigate('/users');
  }
  return (
    <Dialog
      open={showModal}
      disableEscapeKeyDown = {true}
      onClose={handleOnClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
       {title}
      </DialogTitle>
      <DialogContent>
        <select
          class="form-select"
          id="validationCustom04"
          required
          
          onChange={(e) =>
            setUserType(e.target.value)
          }
        >
          <option selected disabled value="">
            Choose...
          </option>
          {[
            { label: "Individual", value: "individual" },
            { label: "Corporate", value: "corporate" },
          ].map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnCancel()}>Cancel</Button>
        <Button onClick={() => handleOnClose(false)} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UserTypeModal;
