import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import '../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import {searchUsers} from '../../../store/httpCalls';
import { ProfileContext } from '../../../store/context/ProfileContext';

const TemporalPeriodModal = ({ showModal, hideModal, className, getTemporalReversalDays}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);

  
  const handleSubmit = async (e) => {

   
   /* 
     let res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/wallet/non-waya/payment/new`,
        transferData
      );*/
    showLoader();
    const formBody = Object.keys(data).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

      const res = await httpPostUnreloaded(
        `${customBaseUrl.walletUrl}/api/v1/config
        `,
        formBody
      )
    if (res.status) {
      getTemporalReversalDays()
      hideLoader();
      Swal.fire('Done',  'success');
     hideModal(false);
    } else {
      alert(JSON.stringify(res));

      Swal.fire('Error', res.message, 'error');
    }
  };


  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
        <div class='col-12 text-center font-weight-bold'>
        <p class="font-weight-bold text-lg"> Input No of Days For Reversal </p>
        </div>
       
       
        <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
          <label for='validationCustom05' class='form-label'>
            Enter no of days
          </label>
          <input
            type='Number'
            class='form-control'
            id='validationCustom05'
            value={data.days}
            required
            onChange={(e) => setData({ ...data, days: e.target.value })}
          />
        </div>
        
        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Save
          </button>
        </div>
    </Modal>
  );
};

export default TemporalPeriodModal;
