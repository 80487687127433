import { Input, Modal } from 'antd';
import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import cameraImg from '../../assets/images/camera-alt-01.svg';
import eventIcon from '../../assets/images/eventIcon.png';
import validateImage from '../../utils/validateImage';
import Button from '../../components/Button/Button';

const UploadModal = (props) => {
  const [imageFile, setImageFile] = useState({});
  const {
    showModal,
    hideModal,
    handleUploadDoc,
    name,
    setPostData,
    postData,
    loading,
    previewImage,
    setPreviewImage,
    setIdType,
  } = props;
  const [disable, setDisable] = useState(true);

  const handlePreview = (e) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setDisable(false);
      setImageFile(imageData);
      setPostData({ ...postData, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(imageData);
    } else {
      Swal.fire('Oops!', `${validFormat.message}`, 'error');
    }
  };
  useEffect(()=>{
    console.log('name', name);
  }, [])
  return (
    <Modal
      visible={showModal}
     /* onOk={async() => {
       await handleUploadDoc(name, imageFile,"");
        hideModal(false)}
      }
      */
      onCancel={() => {
        hideModal(false);
        setPostData({...postData, fileName: ''});
        setPreviewImage('');
      }
      }
      footer={null}
    >
      <h4 className='text-left'>Upload  </h4>
      <div>
        <div className='upload-kyc'>
          <img src={previewImage || eventIcon} alt='event' />
          <img src={cameraImg} className='upload-banner-input' alt='camera' />
          <input
            type='file'
            className='upload-banner-input2'
            onChange={(e) => handlePreview(e)}
          />
        </div>
        <div className='italic my-2 h6 text-center'>{postData?.fileName}</div>
        {
         name.includes('DriversLicense') ? (
            <div>
            <Input
                  placeholder="License"
                  value={postData?.License}
                  onChange={(e) =>
                    setPostData({ ...postData, License: e.target.value })
                  }
                />
              </div>
            ) :  name.includes('NationalId') ? (
              <div>
              <Input
                  placeholder="NIN"
                  value={postData?.virtualnin}
                  onChange={(e) =>
                    setPostData({ ...postData, virtualnin: e.target.value })
                  }
                />
                </div>
              ) :  name.includes('VotersCard') ? (
                <div>
                <Input
                  placeholder="Voter Id"
                  value={postData?.voterId}
                  onChange={(e) =>
                    setPostData({ ...postData, voterId: e.target.value })
                  }
                />
                </div>
                ) :  name.includes('InternationalPassport') ? (
                  <div>
                  <Input
                placeholder="International Passport Number"
                value={postData?.passportNumber}
                onChange={(e) =>
                  setPostData({ ...postData, passportNumber: e.target.value })
                }
              />
              </div>
                  ) : (
              ""
            )
        }
              {name === 'ID' && (
          <div className='my-3'>
            <select onChange={(e) => setIdType(e.target.value)}>
              <option value='' disabled selected>
                Select Identification Type
              </option>
              <option value='interPass'>International Passport</option>
              <option value='nationalId'>National Identification Card</option>
              <option value='votersCard'>Voters Card</option>
              <option value='driversLicence'>Drivers Licence</option>
            </select>
          </div>
        )}
         {/*name === 'Reference' && (
          <div className='my-3'>
            <select onChange={(e) => setIdType(e.target.value)}>
              <option value='' disabled selected>
                Select Reference Type
              </option>
              <option value='interPass'>Statement of account</option>
              <option value='nationalId'>National Identification Card</option>

            </select>
          </div>
         )*/}
         {
          name === 'reference' && (
            <Input 
            placeholder='Referece'
            value={postData?.reference}
            onChange={(e)=>{
              setPostData({...postData, reference: e?.target.value})
            }}
            />
          )
         }

        {
          name === 'DriversLicense' ? (
            <Input
                  placeholder="License"
                  value={postData?.License}
                  onChange={(e) =>
                    setPostData({ ...postData, License: e.target.value })
                  }
                />
            ) :  name === 'NationalId' ? (
              <Input
                  placeholder="NIN"
                  value={postData?.virtualnin}
                  onChange={(e) =>
                    setPostData({ ...postData, virtualnin: e.target.value })
                  }
                />
              ) :  name === 'VotersCard' ? (
                <Input
                  placeholder="Voter Id"
                  value={postData?.voterId}
                  onChange={(e) =>
                    setPostData({ ...postData, voterId: e.target.value })
                  }
                />
                ) :  name === 'InternationalPassport' ? (
                  <Input
                placeholder="International Passport Number"
                value={postData?.passportNumber}
                onChange={(e) =>
                  setPostData({ ...postData, passportNumber: e.target.value })
                }
              />
                  ) :  name === 'CAC' ? (
                    <Input
                  placeholder="CAC ID"
                  value={postData?.cacId}
                  onChange={(e) =>
                    setPostData({ ...postData, cacId: e.target.value })
                  }
                />
                    ) :  name === 'TIN' ? (
                      <Input
                    placeholder="TIN"
                    value={postData?.tinId}
                    onChange={(e) =>
                      setPostData({ ...postData, tinId: e.target.value })
                    }
                  />
                      ) : (
              ""
            )
        }

        {!disable && (
          <div className='w-100 my-5 text-center'>
            <Button
              style={{}}
              type='button'
              loading={loading}
              onClick={() => {
                
                handleUploadDoc(name, imageFile,"");
              }}
              content='Upload'
              className='bg-primary w-50 p-2 rounded-1 text-white border-0'
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadModal;
