import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import '../../../index.scss';
import { customBaseUrl, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const UploadExcel = ({ showModal, hideModal, title, className }) => {
  const [file, setFile] = useState('');
  const [amount, setAmount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();

    const formdata = new FormData();
    formdata.append('file', file);

    let d = new Date();
    let reqId= d.getTime();
    let customerName = 'Bulk Bonus'
    let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/BulkExcelBonus`, formdata)

    /*const res = await httpPost(
    `${customBaseUrl.billPaymentUrl}/api/v1/admin/bulk-user-excel`,
      formdata
    );
      */
    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      setFile('');
      hideModal(false);
    } else {
      hideLoader();
      Swal.fire('Error',res.message, 'error');
    }
  };

  return (
    <Modal
      className={className}
      title={title}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <form class='row g-3 needs-validation' novalidate>
      
        <div className='col-10 mt-5 mb-3 mx-auto'>
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label class='input-group-text' for='inputGroupFile02'>
              Browse
            </label>
            <div class='invalid-feedback'>File is required.</div>
          </div>
        </div>

        <div class='col-12 text-center mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UploadExcel;
