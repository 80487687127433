import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  TablePagination,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';

export default function Index3({
  headers,
  handleChangePage,
  handleChangeRowsPerPage,
  styles,
  children,
  count = 10,
  rowsPerPage = 5,
  page = 1,
  onRequestSort = null,
  order='asc', orderBy
}) {

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={styles?.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: "#fbe2dd", color: "#E7472C" }}>
            {headers?.map((item) => (
              <TableCell
              key={item.id}
              sortDirection={orderBy === item.id ? order : false}
                style={{
                  color: item.color,
                  fontWeight: item.weight,
                  fontSize: item.fontSize,
                }}
                align={item.align}
              >
                 <TableSortLabel
              active={orderBy === item.id}
              direction={orderBy === item.id ? order : 'asc'}
              onClick={createSortHandler(item.id)}
            >
             {item.name}
              {orderBy === item.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
               
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>{children}</TableBody>
      </Table>

      <TablePagination
        style={{ backgroundColor: "#fbe2dd", color: "#E7472C" }}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
          native: true,
        }}
      />
    </TableContainer>
  );
}
