import React, { useEffect, useState } from "react";
import { AutoComplete, Space } from 'antd';
import { customBaseUrl, httpGet, httpPost, httpPostFormData, httpPostUnreloaded } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";

const { Option } = AutoComplete;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
});


const AddRequirement = ({ selectedRecord, setOpen }) => {

    const [result, setResult] = useState({});
    const [requirement, setRequirement] = useState('')

   
    const handleAddRequest = async () => {
        showLoader();
         const data = {
          Item: requirement,
          tierType: selectedRecord.tiers.slice(-1),           
         }
         const formBody = Object.keys(data).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

         const formdata = new FormData();
         formdata.append('Item', requirement);
         formdata.append('tierType', selectedRecord.tiers.slice(-1));


         const res = await httpPostFormData(`${customBaseUrl.kycUrl}/kyc/add/tier/requirement`, 
         formdata
         );
        
         if (res.status) {
            hideLoader();
            setOpen(false)
            setRequirement('')
            Swal.fire({
                icon: 'success',
                title: 'Requirement Added',
                showConfirmButton: false,
            })
        }else {
            console.log(res)
            hideLoader();
            Swal.fire("Oops!", res.message, "error");
        }
    }

    return (
        <div sx={style}>
            <h4 className="d-flex justify-content-center py-3" >
                Add New Requirement for Tier {selectedRecord.tiers.slice(-1)}
            </h4>
            <div>

                <label >Enter Corporate Requirement</label>
                <input

                    className="d-flex justify-content-center"
                    value={requirement}
                    onChange={(e) => setRequirement(e.target.value)}
                    style={{
                        width: 400,
                    }}
                    required
                />
               

                <div className="d-flex justify-content-center py-5">
                    <button type="button" class="btn btn-danger" onClick={handleAddRequest }>Add Requirement</button>
                </div>
            </div>


        </div>
    )
}
export default AddRequirement;
