import { createUseStyles } from "react-jss";
import { grey } from "@mui/material/colors";
import { textAlign } from "@mui/system";
const useMatStyles = createUseStyles(() => ({
  otpWrapper: {
    fontFamily: "inherit",

    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    background: grey[50],
  },
  updateButton: {
    borderRadius: "4px !important",
    textAlign: "center",
    background: "#ff6700",
    alignSelf: "center",
    marginTop: "10px",
    border:'none',
    fontSize: "15px",
    width: "250px",
    color: "#fff",
    height: "40px",
    "&:hover": {
      background: "#FF4B01",
      color: "#fff",
    },
    "&:focus": {
      background: "#FF4B01",
      color: "#fff",
    },
  },
  otpComp: {
    color: "#000",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    display: "flex",
    justifyContent: "left",
  },
  link:{
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#ff6700",
    cursor:'pointer'
  },
  titleText: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "29px",
    color: "#3A3837",
    textAlign: "center",
    marginTop: "30px",
  },
  subTitleText: {
    margin: "30px",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#828282",
  },
  paperCard: {
    boxSizing: "border-box",
    position: "absolute",
    width: "633px",
    height: "467px",
    left: "404px",
    top: "80",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: "14px",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
    marginLeft: "20px",
    alignItems: "left",
    height: "97px",
    marginTop: "45px",
  },
  otpCont: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "33px",
  },

  otpInput: {
    border: "0",
    borderBottom: "1px solid #c4c4c4",
    borderRadius: "2px",
    width: "56.71px !important",
    outline: "0",
    fontSize: "30px",
  },
}));

export default useMatStyles;
