import React, { useState } from "react";
import Layout from '../../../components/Layout'
import { Button, Dropdown, Menu, Modal } from "antd"
import { useNavigate } from "react-router-dom";
import FundMassUsers from "./FundMassUsers";






const TechOverview = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);


    const menu = (
        <Menu

            items={
                [
                    {
                        key: '1',
                        label: (
                            <p target="_blank" rel="noopener noreferrer"  onClick={() => setVisible(true)} >
                                Single Fund Users
                            </p>
                        ),
                    },
                    {
                        key: '2',
                        label: (


                            <p type="primary" onClick={() => navigate('/simulated_users_fund')}>
                                Mass Fund Users
                            </p>

                        ),
                    },
                ]}
        />
    );

   



    return (
        <Layout>
            <div className="d-flex justify-content-end">
                <Dropdown overlay={menu} placement="bottomLeft">
                    <Button
                        style={{ background: "#FF4B01", fontSize: "15px", color: "#fff" }}>
                        Fund Simulated Users
                    </Button>
                </Dropdown>
            </div>
            <Modal

                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={50}
                footer={[]}
            >
                <FundMassUsers />
            </Modal>
        </Layout>
    )
}
export default TechOverview