import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import useMatStyles from "../style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt } from "@mui/material";
import { Button, Input, Space } from "antd";
import { ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import ViewTransaction from "./modals/ViewTransaction";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { currencyFormat, transformName } from "../../../utils/helper";
import FilterTransactions from "./modals/filter";
//import { Modal } from 'antd';
import ExportTrans from './modals/export'
import Exp from "../../../assets/images/export.svg";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}
const Disputes = () => {
  const [disputes, setDisputes] = useState([]);
  const styles = useMatStyles();
  const statistics = ["Total Dispute", "Total Rejected", "Total Resolved", "Total Under Review"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionReceiptModal, setShowDisputeReceiptModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [dispute, setDispute] = useState({});
  const [totalDispute, setTotalDispute] = useState(0)
  const [totalResolved, setTotalResolved] = useState(0)
  const [totalRejected, setTotalRejected] = useState(0)
  const [totalUnderReview, setTotalUnderReview] = useState(0)

  const [showExport, setShowExport] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState(initial)
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getDisputes(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getDisputes(0, value);
  };

  const viewTransactions = (row) => {
    setDispute(row);
    setShowDisputeReceiptModal(true);
  };

  const handleReverseTransaction = async (row) => {
    //return alert(JSON.stringify(row));
    showLoader();
    let reverseData =
    {
      tranCrncy: row.tranCrncyCode,
      tranDate: row.tranDate,
      tranId: row.tranId,
    }

    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/dispute/reverse`,
      reverseData
    );
    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      await getDisputes(pagination.current, pagination.pageSize);
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const reverseTransaction = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reverse this dispute?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.value) {
        handleReverseTransaction(row);
      }
    });
  };
  const dropDownOptions = ["View", "Reverse"];
  const handleDropdownAction = (item, row) => {
    if (item === "View") viewTransactions(row);
    if (item === "Reverse") reverseTransaction(row);
  };
  const columns = React.useMemo(() => [
    {
      field: "transactionId",
      headerName: "Reference ID",
      width: 150,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true,
      renderCell: ({ row }) => {
        const { transactionId = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {transactionId.length > 40
              ? transactionId.slice(0, -25) + "..."
              : transactionId}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.transactionId.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row.transactionId}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "transactionDate",
      headerName: "Created Date",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "id",
      headerName: "Dispute Id",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "transactionAmount",
      headerName: "Transaction Amount",
      sortable: true,
      width: 130,
      headerClassName: styles.tableHeader,
    },
    {
      field: "narrationOfDispute",
      headerName: "Dispute Narration",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
   
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      width: 100,
      editable: false,
      sortable: true,
      headerClassName: styles.tableHeader,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: false,
      sortable: true,
      headerClassName: styles.tableHeader,
    },

    {
      field: " ",
      headerName: "Action ",
      width: 80,
      align: "right",
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={dropDownOptions}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ]);
  const getDisputes = async (page, size) => {
    setDisputes([]);
    showLoader();

    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.wayapayDispute}/disputes?page=${pages}`
    );
    if (response?.status === true) {
      setDisputes(response?.data?.userDisputes);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalNumberOfDispute);
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const getTotalDispute = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-dispute`
    );

    if(res?.status === false){
      hideLoader()
    }else  {
      //setFiltered(response.data.content)
      setTotalDispute(res);
      hideLoader();
    }

  };

  const getTotalResolved = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-resolved`
    );
      if(res?.status === false){
        hideLoader()
      }else {
      //setFiltered(response.data.content)
      setTotalResolved(res);
      hideLoader();
    } 

  };

  const getTotalRejected = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-rejected`
    );

    if(res?.status === false){
      hideLoader()
    }else  {
      //setFiltered(response.data.content)
      setTotalRejected(res);
      hideLoader();
    } 

  };
  const getTotalUnderReview = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-under_review`
    );

    if(res?.status === false){
      hideLoader()
    }else {
      setTotalUnderReview(res);
      hideLoader();
    }

  };
  // const showModal = () => {
  //   setVisible(true);
  // };
  // const handleCancel = () => {
  //   setVisible(false);
  // };


  useEffect(() => {
    getDisputes();
    getTotalDispute();
    getTotalResolved();
    getTotalRejected();
    getTotalUnderReview();
  }, []);

  const handleShowFilter = () => {
    setShowFilter(true)
    setSearch({ ...search, searchText: '' })
  }

  const handleFilter = () => {
    console.log('searchData', searchData);
    //getDisputes();
    setFiltered(false)
    setShowFilter(false)
    setSearch({ ...search, searchText: '' })



  }
  const handleClearFilter = () => {
    //  return alert(JSON.stringify(disputes))
    setSearch({ ...search, searchText: '' })
    setSearchData(initial)
    setShowFilter(false)
    setFiltered(true)
  }

  const handleSearch = () => {
    if (!search.searchText) {
      return;
    }

    //alert(JSON.stringify(disputes.filter(req=>req?.paymentReference.toLowerCase().includes(search?.searchText.toLowerCase())||req?.tranCategory.toLowerCase().includes(search?.searchText.toLowerCase()))))


  }

  const SendUserOptions = [
    <div><Input placeholder="search" onChange={(e) => alert(e.value.target)} /></div>,
    "Send to Single User",
    "Send Using Webforms",
    "Upload Excel File to Send",
  ];

  let formatter = new Intl.NumberFormat('en-US',{
    style: 'currency',
    currency: 'NGN',
  })
  return (
    <Layout>

      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
        {statistics.map((item, index) => (
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              <span style={{ float: "left" }} className={styles.diTop}>
                {item}
              </span>
              {item === 'Total Dispute' ? <span className={styles.money}><br/>  {(totalDispute)}</span> :
                item === 'Total Resolved' ? <span className={styles.money}><br/> {(totalResolved)}</span> :
                item === 'Total Rejected' ? <span className={styles.money}><br/> {(totalRejected)}</span> :
        <span className={styles.money}><br/> {(totalUnderReview)}</span>}
            </div>
          </Paper>
        ))}
      </MatGrid>
      <div className="d-flex justify-content-between">
        <div style={tool} class="col-md-5">

          <div className={styles.searchRow}>
            <Input
              className={styles.searchInput}
              placeholder="Search Transactions"
              value={search.searchText}
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              prefix={<SearchOutlined className={styles.searchIcon} />}
            />
          </div>

          {/* <div className={styles.searchRow}>
          <Button onClick={handleSearch}>Search</Button>

        </div>
        <div className={styles.searchRow}>
          <FilterList onClick={handleShowFilter} />
          Filter
        </div>*/}
        </div>
        <div >
          {/* <MatButton
            onClick={showModal}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>Export Data</div>
          </MatButton> */}
          <MatButton
            //className="update-button-1"
            onClick={() => setShowExport(true)}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>Export Data</div>
          </MatButton>
        </div>
      </div>

      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            className={styles.table}
            rows={search.searchText ? disputes.filter(req => req?.transactionId.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.narrationOfDispute?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.status?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.transactionDate.includes(search?.searchText) || req?.transactionAmount === (search?.searchText)|| req?.trackingNumber.includes(search?.searchText)) :
           disputes 
              }
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
         /*   onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }*/
            onPageChange={(newPage) => handleChangePage(newPage)}
          //  rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          />
        </div>
      </MatGrid>
      {showTransactionReceiptModal && (
        <ViewTransaction
          showModal={showTransactionReceiptModal}
          setShowModal={setShowDisputeReceiptModal}
          dispute={dispute}
        />
      )}

      {showFilter && (
        <FilterTransactions
          showModal={showFilter}
          hideModal={setShowFilter}
          clearFilter={handleClearFilter}
          searchData={searchData}
          setSearchData={setSearchData}
          handleFilters={handleFilter}
        />)
      }


      {/* <Modal
        visible={visible}
        title="Title"
        //onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}>
        <ExportTrans />
      </Modal> */}


      {showExport ? (
        <ExportTrans showModal={showExport} hideModal={setShowExport} dataList={disputes} />
      ) : (
        ""
      )}



    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Disputes;
