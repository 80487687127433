import React from 'react';

const walletIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.52078 7.04285L24.271 2.84881L23.4898 1.20409C22.9788 0.135022 21.7323 -0.306455 20.706 0.225915L7.50146 7.04285H9.52078Z"
      fill="#FF4B01"
    />
    <path
      d="M28.8494 2.96484C28.6666 2.96484 28.4837 2.99081 28.3009 3.04275L24.8357 4.02958L14.2529 7.03769H26.265H31.5002L30.8521 4.56195C30.6028 3.59676 29.7635 2.96484 28.8494 2.96484Z"
      fill="#FF4B01"
    />
    <path
      d="M33.6651 8.5625H33.1914H32.5474H31.9034H26.9881H8.92637H6.55803H4.56364H4.19385H2.95567C2.29918 8.5625 1.71333 8.87846 1.33107 9.3762C1.15656 9.6056 1.0236 9.86962 0.948813 10.1596C0.903108 10.3414 0.874023 10.5318 0.874023 10.7266V10.9863V13.4534V34.8391C0.874023 36.0337 1.80474 37.0032 2.95151 37.0032H33.661C34.8078 37.0032 35.7384 36.0337 35.7384 34.8391V28.8012H23.4106C21.4619 28.8012 19.8789 27.1522 19.8789 25.1222V23.1399V22.469V21.7982V20.3093C19.8789 19.3138 20.2611 18.4092 20.8802 17.747C21.4287 17.1583 22.1641 16.7601 22.9868 16.6606C23.1239 16.6433 23.2652 16.6346 23.4065 16.6346H34.01H34.654H35.298H35.7384V10.7266C35.7426 9.53202 34.8119 8.5625 33.6651 8.5625Z"
      fill="#FF4B01"
    />
    <path
      d="M37.1967 18.6998C36.989 18.5007 36.7438 18.3492 36.4696 18.2496C36.2577 18.1761 36.0333 18.1328 35.7965 18.1328H35.7425H35.7009H35.0569H32.7343H23.4105C22.2637 18.1328 21.333 19.1023 21.333 20.2969V21.3746V22.0455V22.7163V25.1142C21.333 26.3088 22.2637 27.2783 23.4105 27.2783H35.7425H35.7965C36.0333 27.2783 36.2577 27.235 36.4696 27.1614C36.7438 27.0662 36.989 26.9104 37.1967 26.7113C37.6122 26.3174 37.874 25.7461 37.874 25.1142V20.2969C37.874 19.665 37.6122 19.0936 37.1967 18.6998ZM27.7483 23.1362C27.7483 23.7335 27.2829 24.2182 26.7095 24.2182H26.0198C25.4464 24.2182 24.9811 23.7335 24.9811 23.1362V22.4177C24.9811 22.0714 25.1348 21.7641 25.38 21.5693C25.5586 21.4265 25.7789 21.3356 26.0198 21.3356H26.1943H26.7095C27.2829 21.3356 27.7483 21.8204 27.7483 22.4177V23.1362Z"
      fill="#FF4B01"
    />
  </svg>
);
export default walletIcon;
