import {
  Edit,
  Refresh,
  Settings,
  Delete as DeleteFilled,
  ArrowBack,
} from "@mui/icons-material";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMatStyles from "./style";
import Layout from "../../../components/Layout";
import { customBaseUrl, httpDelete, httpGet, httpPost, httpPut } from "../../../store/http";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { hideLoader, showLoader } from "../../../utils/loader";
import EditAggregatorCommission from "./modals/editAggregatorCommission";
import ManageCommissions from "./modals/manageCommissions";
import CreateCommission from "./modals/createCommission";

const AggreegatorBill = () => {
  const [commissions, setCommissions] = useState([]);
  const [commissionData, setCommissionData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showAddCom, setShowAddCom] = useState(false);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 20 });
  const [metaData, setMetaData] = useState(0);
  const [allReferrals, setAllreferrals] = useState([]);
  const [comList, setComList] = useState({})
  const [amountType, setAmountType] = useState([]);
  const [tranType, setTranType] = useState([]);
  const [tranName, setTranName] = useState([]);
  const [debitSrc, setDebitSrc] = useState([]);


  const styles = useMatStyles();
  const navigation = useNavigate();

  
  const getReferralTypes = async () => {
    setAllreferrals([]);
    showLoader();
    let request = await httpPost(
      `${customBaseUrl.referralUrl}/api/v1/referral_type/view_report`,
      {
        pageSize: 100,
        pageNo: 0,
      }
    );
    if (request.status) {
      setAllreferrals(request.data.content);
    }
    hideLoader();
  };

  const handleOpenEditModal = (commission) => {
    setCommissionData(commission);
    setShowModal(true);
  };

  const editAggregatorCommission = async () => {
    showLoader();
    const url = `${customBaseUrl.commissionUrl}/api/v1/organisation`;
    const response = await httpPut(url, commissionData);
    if (response.status === true) {
      NotificationManager.success(response.message);
      getCommissions();
      setShowModal(false);
      setShowManageModal(false);
      setCommissionData({})
      hideLoader();
    } else {
      NotificationManager.error(response.message);
      hideLoader();
    }
  };

  const deleteAggregatorCommission = async () => {
    showLoader();
    const url = `${customBaseUrl.commissionUrl}/api/v1/organisation`;
    const response = await httpDelete(url, commissionData);
    if (response.status === true) {
      NotificationManager.success(response.message);
      getCommissions();
      hideLoader();
    } else {
      NotificationManager.error(response.message);
      hideLoader();
    }
  };

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getCommissions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getCommissions(newPage, pagination.pageSize);
  };



  const getCommissions = async (page,size) => {
    showLoader();
    const pages = page ?? pagination.current;
    const limit = size ? size : pagination.pageSize;

    const response = await httpPost(
      `${customBaseUrl.referralUrl}/api/v1/commission_setup/view_commission_setup`,
      {
        pageNo: pages,
        pageSize: limit,
      }
    );
    console.log('log', response)
    if (response.status === true) {

      setCommissions(response.data);
      setMetaData(response.data.totalElements);
      hideLoader();
    } else {
      NotificationManager.error(response.message, "Oops!", 3000);
      hideLoader();
    }
    // setCommissions(response.data);
  };

  const getComList = async (page,size) => {
    showLoader();
    const response1 = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/referral_type/get_transaction_name`
    );   
    const response = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/referral_type/get_amount_type`,
    );
    const response2 = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/referral_type/get_transaction_type`
    );
    const response3 = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/referral_type/get_charge_debit_source`
    );
    console.log('log', response)
    if (response.status === true) {

      setAmountType(response.data);
      hideLoader();
    }
    if (response1.status === true) {

      setTranName(response1.data);
      hideLoader();
    }
    if (response2.status === true) {

      setTranType(response2.data);
      hideLoader();
    }
    if (response3.status === true) {

      setDebitSrc(response3.data);
      hideLoader();
    }
  };

  const columns = [
    {
      field: "description",
      headerName: "NAME",
      width: 200,
      headerClassName: styles.tableHeader,
      valueGetter: ({ row }) => {
        return row.description.replace(/_/g," ");;
      },
    },
    {
      field: "transactionType",
      headerName: "Transaction type",
      width: 300,
      headerClassName: styles.tableHeader,
      valueGetter: ({ row }) => {
        return row.transactionType.replace(/_/g," ");
      },
    },
    {
      field: "commissionType",
      headerName: "Commission type",
      width: 200,
      headerClassName: styles.tableHeader,
      valueGetter: ({ row }) => {
        return row.commissionType.replace(/_/g," ");
      },
    },
    {
      field: "amountType",
      headerName: "Amount Type",
      headerClassName: styles.tableHeader,
      width: 200,
      valueGetter: ({ row, value }) => {
        return `${value}`;
      },
    },
    {
      field: "referralType",
      headerName: "Referral type",
      headerClassName: styles.tableHeader,
      width: 250,
    },
    {
      field: "recurrent",
      headerName: "Recurrent",
      headerClassName: styles.tableHeader,
      width: 250,
      renderCell: ({row}) => row?.recurrent ? 'TRUE' : 'FALSE'
    },
    {
      field: "cappedAmount",
      headerName: "CAPPED AMOUNT",
      headerClassName: styles.tableHeader,
      width: 200,
    },
    /*{
      field: "amount",
      headerName: "COMMISSION RATE",
      headerClassName: styles.tableHeader,
      width: 200,
      valueGetter: ({ row, value }) => {
        return `${value}`;
      },
    },*/
    {
      field: "amountForFix",
      headerName: "Amount Earned by Aggregator",
      headerClassName: styles.tableHeader,
      width: 300,
    },
    {
      field: "amountEarnedByWaya",
      headerName: "Amount Earned by Waya",
      headerClassName: styles.tableHeader,
      width: 350,
      renderCell: ({row}) => row?.amountEarnedByWaya ?? 0
    },
    {
      field: "percentageToEarn",
      headerName: "Percentage to Aggregator",
      headerClassName: styles.tableHeader,
      width: 300,
    },
    {
      field: "percentageByWaya",
      headerName: "Percentage Earned by Waya",
      headerClassName: styles.tableHeader,
      width: 350,
      renderCell: ({row}) => row?.percentageByWaya ?? 0
    },
    {
      field: "commissionSetup",
      headerName: "Commisiion Setup",
      headerClassName: styles.tableHeader,
      width: 250,
      renderCell: ({row}) => row?.commissionSetup ?? 0
    },

  {
      field: "debitSource",
      headerName: "Debit Source",
      headerClassName: styles.tableHeader,
      width: 200,
    },
    /* {
      field: "corporateUserEmail",
      headerName: "CORPORATE USER EMAIL",
      headerClassName: styles.tableHeader,
      width: 200,
    },*/
    /*{
      field: "commissionType",
      headerName: "COMMISSION TYPE",
      headerClassName: styles.tableHeader,
      width: 200,
    },  */
    {
      field: "actions",
      headerName: "ACTIONS",
      headerClassName: styles.tableHeader,
      type: "actions",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <div key={row.id}>
            <ButtonGroup variant="text">
              <IconButton
                onClick={() => handleOpenEditModal(row)}
                style={{ color: "#303030" }}
                aria-label="edit"
                component="span"
              >
                <Edit />
              </IconButton>
              {/*<IconButton
                style={{ color: "#FF2500" }}
                aria-label="refresh"
                component="span"
              >
                <Refresh />
              </IconButton>
              <IconButton
                style={{ color: "#FF2500" }}
                aria-label="delete"
                component="span"
              >
                <DeleteFilled />
              </IconButton>*/}
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getCommissions();
    getComList();
    getReferralTypes();
  }, []);
  return (
    <Layout>
      <div >
        <ArrowBack onClick={()=>{
          navigation('/transactions/commissions')
        }}/>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button variant="contained"
            onClick={() => setShowAddCom(true)}
            style={{
              // width:'100%',
              float: "right",
              fontFamily: "Inter",
              background: "#ff6700",
            }} startIcon={<Settings />}>
            Add New Commission
          </Button>


        </div>
        <div
          style={{ height: 800, width: "100%", marginTop: "25px" }}
          className="col-md-12"
        >
        

      <DataGrid
            className={styles.table}
            rows={commissions}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          />
        </div>
      </div>
      {showModal && (
        <EditAggregatorCommission
          showModal={showModal}
          setShowModal={setShowModal}
          commissionData={commissionData}
          handleSubmit={editAggregatorCommission}
          commissionsList={commissions}
          getCommissions={getCommissions}
          allReferrals={allReferrals}
          tranType={tranType}
          amountType={amountType}
          tranName={tranName}
          debitSrc={debitSrc}
        />
      )}
      {showManageModal && (
        <ManageCommissions
          showModal={showManageModal}
          setShowModal={setShowManageModal}
          commissionList={commissions}
          commissionData={commissionData}
          setCommissionData={setCommissionData}
          handleSubmit={editAggregatorCommission}
          comList={comList}
          getComList={getComList}

        />
      )}
      {showAddCom && (
        <CreateCommission
          showModal={showAddCom}
          setShowModal={setShowAddCom}
          getCommissions={getComList}
          allReferrals={allReferrals}
          tranType={tranType}
          amountType={amountType}
          tranName={tranName}
          debitSrc={debitSrc}
        />
      )}
    </Layout>
  );
};
export default AggreegatorBill;
