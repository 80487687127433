import React from "react";
import { InputNumber, Row, Col, AutoComplete, Button, DatePicker } from 'antd';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const { RangePicker } = DatePicker;


const DeleteUsers = ({ setDebitVisible }) => {


    return (
        <div>
            <div className="d-flex justify-content-center">
                <h5>Delete Simulated users</h5>
            </div>
            <div>
                
                <Row className="my-5">
                    <Col span={12}>
                        <p>Date Range</p>
                        <RangePicker
                            style={{
                                width: 320,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <p>Value Date</p>
                        <DatePicker
                            style={{
                                width: 320,
                            }}
                            format={dateFormatList}
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-end">
                    <Button
                        style={{ background: "#FF4B01", fontSize: "15px", color: "#fff" }}>
                        Delete Users
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default DeleteUsers