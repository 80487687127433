import React, { useState } from "react";
import useStyles from '../Otp/styles';
import { Button, Modal } from "antd";
import { hideLoader, showLoader } from "../../utils/loader";
import { customBaseUrl, httpPost } from "../../store/http";
import Swal from "sweetalert2";

const MassUpload = ({hideModal, showModal, type}) => {
const [postData, setPostData] = useState({})

const handleSubmitFile = async (e) =>{
    e.preventDefault();

    showLoader();

    let d = new Date();
    let reqId= d.getTime();
    let formData = new FormData();
    formData.append("file", postData?.file);
    formData.append("customerName", `Mass ${showModal === 'massCredit'? 'Credit': 'Debit'}`);
    formData.append("requestId", d.getTime());
    formData.append("requestType", showModal); 
    let customerName = `Mass ${showModal === 'massCredit'? 'Credit': 'Debit'}`
    let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/${showModal}`, formData)

    if (response?.status) {
      hideLoader();
      Swal.fire('Done', response.message, 'success');
      hideModal('');
    } else {
      hideLoader();
      Swal.fire('Error',response.message, 'error');
    }
}
    return (
        <Modal
        title={`Mass ${showModal === 'massCredit'? 'Credit': 'Debit'} Transaction`}
        centered
        visible={showModal}
        //onOk={() => setVisible(false)}
        onCancel={() => hideModal('')}
        width={500}
        footer={[

        ]}
      >
        <form class='row g-3 needs-validation' novalidate>
        <div className='col-10 mt-5 mb-3 mx-auto'>
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setPostData({...postData, file:e.target.files[0]})}
            />            
            <div class='invalid-feedback'>File is required.</div>
          </div>
        </div>

        <div class='col-12 text-center mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmitFile}>
            Submit form
          </button>
        </div>
        </form>
           
        </Modal>
    );

}
export default MassUpload