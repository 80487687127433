export const KycDocumentType = {
  LICENSE : 'LICENSE',
  INTERNATIONAL_PASSPORT : 'INTERNATIONAL_PASSPORT',
  NATIONAL_ID : 'NATIONAL_ID',
  PASSPORT_PHOTO : 'PASSPORT_PHOTO',
  VOTER_CARD : 'VOTERS_CARD',
  UTILITY_BILL : 'UTILITY_BILL',
  CAC : 'CAC',
  DRIVERS_LICENSE: 'LICENSE',
  PROOF_ADDRESS: 'PROOF_ADDRESS',
  TIN: 'TIN'
}
