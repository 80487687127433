import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import ColoredSwitcher from "../../../components/customSwitch/coloredSwitcher";
  import { customBaseUrl, httpGet, httpPut } from "../../../store/http";
  import { hideLoader, showLoader } from "../../../utils/loader";
  
  const CoreBanking = (props) => {
    const [services, setServices] = useState([]);
    const handleToggle = async (switches, switchState, swich) => {
        //return alert(swich.switchCode)
        const data = {
                        "newSwitchCode": swich.switchCode,
                        "onSwitch": true
                      }
      showLoader();
      const response = await httpPut(
        `${customBaseUrl.walletUrl}/api/v1/switch/wallet/toggle`,
        data
      );
      hideLoader();
      if (response.status === true) {
        // handleProviderChange(gatewayIndex, response?.data?.active);
        handleGetBankingServices();
      }
    };
    const handleGetBankingServices = async () => {
      showLoader();
      const response = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/switch/wallet`,
      );
      hideLoader();
      if (response.status === true) {
        setServices(response?.data);
      }
    };
    useEffect(() => {
      handleGetBankingServices();
    }, []);
    return (
      <>
        <Card
          key={props.keyValue}
          style={{
            transitionDuration: "0.3s",
            minHeight: "30vw",
            display: "block",
          }}
          sx={{ marginTop: "30px" }}
          raised={true}
        >
          <CardContent>
            <List dense={false}>
              
              {services.map((item, index) => (
                <Grid container spacing={10}>
                  <Grid key={item.id + item.switchCode} item sm={12} sx={{ width: 1 }}>
                    <ListItem
                      key={item.id + item.switchCode.trim()}
                      secondaryAction={
                        <IconButton key={item.id} edge="end" aria-label="delete">
                          <ColoredSwitcher
                            data={{...item, active:item.switched}}
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: "700" }}
                            label={''}
                            keyValue={item.id + item.switchCode.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                          
                        </IconButton>
                      }
                    >
                      <div
                        key={item.id}
                        style={{ fontFamily: "inherit", fontWeight: "700" }}
                      >
                        {item.switchCode==='Temporal'?'Waya Own Core Banking':'MIFOS Core Banking Wallet'}
                      </div>
  
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
            </List>
          </CardContent>
        </Card>
      </>
    );
  };
  export default CoreBanking;
  