import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/Layout";
import useMatStyles from "./style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt } from "@mui/material";
import { Button, Input, Row, Space } from "antd";
import { ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { ProfileContext } from '../../store/context/ProfileContext';
import CopyToClipboardComponent from "../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import ViewTransaction from "./modals/ViewTransaction";
import { ExportOutlined, PlusOutlined, SearchOutlined, SettingFilled } from "@ant-design/icons";
import FilterTransactions from "./modals/filter";
//import { Modal } from 'antd';
import ExportTrans from './modals/export'
import Exp from "../../assets/images/export.svg";
import SendSingleUserModal from "./modals/SendSingleUser";
import UploadExcel from "./modals/excelUpload";
import ReverseBillPayment from "./modals/reverse";
import { date } from "yup/lib/locale";
import BillPaymentWebForm from "./modals/webform";
import BillerForm from "./modals/biller";
import noDataLogo from "../../assets/images/no-data.png";
import { currencyFormat } from "../../utils/helper";
import { CSVLink } from "react-csv";


let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}
const BillsPayment = () => {
  const { profile } = useContext(ProfileContext);
  const [transactions, setTransactions] = useState([]);
  const styles = useMatStyles();
  const statistics = ["Total Transactions", "Successful Transactions", "Failed Transactions"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalDebit, setTotalDebit] = useState(0)
  const [totalCreditDebit, setTotalCreditDebit] = useState(0)
  const [showExport, setShowExport] = useState(false);
  const [showSendUser, setShowSendUser] = useState(false);
  const [categories, setCategories] = useState([])
  const [wallets, setWallets] = useState([])
  const [showTransferExcel,setShowTransferExcel]=useState(false)
  const [status, setStatus] = useState(true);
  const [showReverseModal, setShowReverseModal] = useState(false)
  const [reverseData, setReverseData] = useState({})
  const [showTransferForms, setShowTransferForms] =useState(false)
  const [quickTeller, setQuickTeller] = useState(false)
  const [aggr, setAgg] = useState(null);
  const [showBiller, setShowBiller] = useState(false);
  const [field, setField] = useState('all');
  const [fieldValue, setFieldValue] = useState('all');
  const [stat, setStat] = useState({});

  // const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState(initial)
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const viewTransactions = (row) => {
    setTransaction(row);
    setShowTransactionReceiptModal(true);
  };
  const viewReverse = (row) => {
    setTransaction(row);
    setShowReverseModal(true);
  };

  const handleReverseTransaction = async (row) => {
    //return alert(JSON.stringify(row));
    //return console.log(transaction);
   let d = new Date()
   /* let ref = d.getTime()
    let data ={ ...reverseData,
      "amount": transaction.amount,
  "billsPaymentTransactionId": transaction.transactionId,
  "customerCreditAccount": transaction?.userAccountNumber,
  "paymentReference": ref,
  "tranCrncy": "NGN",
  "tranNarration": "REFUND",
  "tranType": "LOCAL",
  "transactionCategory": "TRANSFER",
  "userId": profile.userId

    }
    
   // return alert(JSON.stringify(data))
    showLoader();
    setReverseData({})
    setShowReverseModal(false)

    const response = await httpPost(
      `${customBaseUrl.billPaymentUrl}/api/v1/admin/refund-failed-transaction`,
      data
    );
*/
    showLoader();
    let reverseData =
    {
      tranCrncy: "NGN",
      tranDate: row.transactionDateTime,
      tranId: row.transactionId,
    }

    /*const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/reverse`,
      reverseData
    );*/
    const response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(reverseData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "reversal",
      customerName: profile?.firstName +' '+ profile?.lastName
    } )



    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      // await getTransactions(pagination.current, pagination.pageSize);
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const reverseTransaction = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to reverse this transaction?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.value) {
        handleReverseTransaction(row);
      }
    });
  };
  const dropDownOptions = ["View", "Reverse"];
  const handleDropdownAction = (item, row) => {
    if (item === "View") viewTransactions(row);
    if (item === "Reverse") reverseTransaction(row);
  };

  const filterCategory = (e) => {
  
  const catName =  categories.filter((cat)=> cat.categoryId === e);

  return catName[0]?.categoryName??e

  }
  const columns = React.useMemo(() => [
    {
      field: "paymentReferenceNumber",
      headerName: "Reference",
      width: 200,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true,
      renderCell: ({ row }) => {
        const { paymentReferenceNumber = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {paymentReferenceNumber.length > 40
              ? paymentReferenceNumber.slice(0, -25) + "..."
              : paymentReferenceNumber}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.paymentReferenceNumber.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row.paymentReferenceNumber}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      expandable: true,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
      renderCell: ({row}) => {
       return filterCategory(row?.category)
      }
    },
    /* {
      field: "thirdPartyName",
      headerName: "Third Party",
      description: "Category Column",
      sortable: true,
      width: 100,
      headerClassName: styles.tableHeader,
    }, */
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "senderName",
      headerName: "Name",
      width: 200,
      editable: false,
      sortable: true,
      description: "Name Column",
      type: "text",
      headerClassName: styles.tableHeader,
    },
    {
      field: "senderEmail",
      headerName: "Email",
      width: 200,
      editable: false,
      sortable: true,
      description: "Email Column",
      type: "email",
      headerClassName: styles.tableHeader,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      editable: false,
      sortable: true,
      description: "Status Column",
      headerClassName: styles.tableHeader,
    },
    {
      field: "accountNumber",
      headerName: "Source Account",
      width: 150,
      editable: false,
      sortable: true,
      description: "Recipient Column",
      headerClassName: styles.tableHeader,
    },
    {
      field: "biller",
      headerName: "Biller Name",
      width: 150,
      editable: false,
      sortable: true,
      description: "Biller Name",
      headerClassName: styles.tableHeader,
      renderCell: ({row}) => row?.serviceProviderBiller?.name
    },

   /* {
      field: "id",
      headerName: "Action ",
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={dropDownOptions}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },*/
  ]);
  const exportData = []
  transactions.length > 0 &&
   transactions?.map(e=>
    exportData.push({
      ReferenceId: e?.paymentReferenceNumber,
      Date: e.createdAt,
      Category: e?.categoryName,
      Amount:e.amount,
      senderName: e?.senderName,
      senderEmail: e?.senderEmail,
      status: e?.status,
      sourceAccount: e?.accountNumber,
      BillerName: e?.serviceProviderBiller?.name

    })
    )
  const getTransactions = async (page, size) => {
    setTransactions([]);
    showLoader();

    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/admin/filter/transactions?field=${field}&pageNo=${pages + 1}&pageSize=${limit}&value=${field === 'all' ? 'all' : fieldValue}`
    );
    if (response.status === true) {
      setTransactions(response?.data?.content);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalElements);
      hideLoader();
    } else {
      hideLoader();
    }
  };


  const getStat = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/admin/analysis`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setStat(res?.data?.map);
      hideLoader();
    } else {
      hideLoader();
    }

  };

  const getTotalDebit = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-debit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalDebit(res.data.amount);
      hideLoader();
    } else {
      hideLoader();
    }

  };

  const getTotalDebitCredit = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-credit-debit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalCreditDebit(res.data);
      hideLoader();
    } else {
      hideLoader();
    }

  };
  // const showModal = () => {
  //   setVisible(true);
  // };
  // const handleCancel = () => {
  //   setVisible(false);
  // };

  const getCategories = async (userId) => {
    //  return alert(userId);
let res;
    const resp = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`)

    let aggregator = resp?.data;

    let quick = aggregator.filter(e=>e.aggregator==='QUICKTELLER')

    if(quick[0].active){
      setQuickTeller(true)
      /* res = await httpGet(
        `${customBaseUrl.billPaymentUrl}/api/v1/getCategories`
      );
        */
      res = await httpGet(
        `${customBaseUrl.billPaymentUrl}/api/v1/category`
      );
       }else{
        res = await httpGet(
          `${customBaseUrl.billPaymentUrl}/api/v1/category`
        );
       }
     // alert(JSON.stringify(res));
      if (res?.status) {
        setCategories(res.data);
      }
    };

    const getOfficial = async () => {
      //  return alert(userId);
        const res = await httpGet(
          `${customBaseUrl.billPaymentUrl}/api/v1/admin/get-waya-official-account`
        );
       // alert(JSON.stringify(res));
        if (res?.status) {
          console.log('wallets', res.data)
          setWallets(res?.data);
        }
      };

  useEffect(() => {
    // getOfficial();
    getTransactions();
    getStat();
    // getTotalCredit();
    // getTotalDebit();
    // getTotalDebitCredit();
    // getCategories();

  }, [status]);

  const handleShowFilter = () => {
    setShowFilter(true)
    setSearch({ ...search, searchText: '' })
  }

  const handleFilter = () => {
    console.log('searchData', searchData);
    //getTransactions();
    setFiltered(false)
    setShowFilter(false)
    setSearch({ ...search, searchText: '' })



  }
  const handleClearFilter = () => {
    //  return alert(JSON.stringify(transactions))
    setSearch({ ...search, searchText: '' })
    setSearchData(initial)
    setShowFilter(false)
    setFiltered(true)
  }

  const handleSearch = () => {
    if (!search.searchText) {
      return;
    }

    //alert(JSON.stringify(transactions.filter(req=>req?.paymentReference.toLowerCase().includes(search?.searchText.toLowerCase())||req?.tranCategory.toLowerCase().includes(search?.searchText.toLowerCase()))))


  }
  const handleSendUserDropdownAction = (item) => {
    if (item === "Send to Single User") setShowSendUser(true);
    if (item === "Upload Excel File to Send") setShowTransferExcel(true);
    if (item === "Send Using Webforms") setShowTransferForms(true);
  };

  const SendUserOptions = [
    <div><Input placeholder="search" onChange={(e) => console.log(e.value.target)} /></div>,
    "Send to Single User",
    "Send Using Webforms",
    "Upload Excel File to Send",
  ];

  const handleSettingsDropdownAction = (item) => {
    if (item === "Create Baxi Biller" ) {
      setAgg("baxi")
      setShowBiller(true);
    }
    if (item === "Create Baxi Category" ) {
      setAgg("baxi")
      setShowSendUser(true);
    }
    if (item === "Create Baxi Product"){
      setAgg("baxi")
      setShowSendUser(true);    }
      if (item === "Create Quick Teller Biller") {
        setAgg("quickteller")
        setShowSendUser(true);
      }
      if ( item === "Create Quick Teller Category") {
        setAgg("quickteller")
        setShowSendUser(true);
      }
      if ( item === "Create Quick Teller Product"){
        setAgg("quickteller")
        setShowSendUser(true);    }
  };

  const settingsOptions = [
    "Create Baxi Biller",
    "Create Baxi Category",
    "Create Baxi Product",
    "Create Quick Teller Biller",
    "Create Quick Teller Category",
    "Create Quick Teller Product",
  ];

  const filterOptions = [
    "Sucessful Transactions",
    "Failed Transactions",
  ];
  const handleFilterDropdown = (item) => {
    if (item === "Sucessful Transactions") setStatus(true);
    if (item === "Failed Transactions") setStatus(false);
    //if (item === "Send Using Webforms") setShowTransferForms(true);
  };

  const filterOption = {
    All: ['All'],
    status: ['FAILED', 'SUCCESSFUL', 'ERROR'],
    Category: ['airtime' , 'databundle' , 'cabletv' , 'epin' ,
      'betting' ,'electricity','education','vehicle',
      'insurance','donation','airline', 'transport',
      'tax','embassy','subscription','schoolboard',
      'shopping','event_ticket','online_shopping',
      'government_payments','insurance_and_investment',
      'international_airtime', 'lagos_state_cbs','credit_and_loan_repayment',
      'pay_tv_subscription','religious_institutions','nestle_distributors',
      'black_friday','apm_terminals','dealer_payments'],
    providerName: ['Baxi', 'QuickTeller']
  }

  const statLabel = {
    errorCount: 'Total Error',
    errorbaxiServiceProvider: ' Baxi Error',
    errorquickteller: 'QuickTeller Error',
    failCount: 'Total Failed',
    failbaxiServiceProvider: 'Baxi Failed',
    failquickteller: 'Quickteller Failed',
    successCount: 'Total Success',
    successbaxiServiceProvider: 'Baxi Successful',
    successquickteller: 'Quickteller Successful',
    totalErrorAmount: 'Total Error Amount',
    totalFailAmount: 'Total Failed Amount',
    totalSuccessAmount: 'Total Successful Amount'
}
  return (
    <Layout>
      {/*<div style={{alignContent: 'end'}}>
      <AntDropdown
        dropDownOptions={settingsOptions}
        handleDropdownAction={handleSettingsDropdownAction}
      >
        <Button
          size="medium"
          style={{ background: "#FF6700", fontSize: "10px", color: "#fff", textAlign: 'right' }}
        >
          <SettingFilled />
          Settings
        </Button>
      </AntDropdown>
      </div>*/}
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
        {Object.keys(statLabel).map((item, index) => (
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              
             
             <span style={{ float: "left" }} className={styles.diTop}>
             {statLabel[item]}
              </span>
              <span className={item?.toLowerCase()?.includes('amount') ? styles.money: styles.count}> 
                {item?.toLowerCase()?.includes('amount') ? currencyFormat(stat[item] ?? 0) : stat[item] ?? 0 }
              </span>
            </div>
          </Paper>
        ))}
      </MatGrid>
      <div className="d-flex justify-content-between">
        <div style={tool} class="col-md-5">

          <div className={styles.searchRow}>
            {/* <Input
              className={styles.searchInput}
              placeholder="Search Transactions"
              value={search.searchText}
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              prefix={<SearchOutlined className={styles.searchIcon} />}
            /> */}
          </div>

          {/* <div className={styles.searchRow}>
          <Button onClick={handleSearch}>Search</Button>
            }*
        </div>
        <div className={styles.searchRow}>
          <FilterList onClick={handleShowFilter} />
          Filter
        </div>*/}
        {/*<AntDropdown
            dropDownOptions={filterOptions}
            handleDropdownAction={handleFilterDropdown}
          >
            <FilterList/>
          </AntDropdown>*/}
          <select value={field} onChange={(e) => {
            setField(e.target.value)
            setFieldValue(filterOption[e.target.value][0]);
            }}>
            {Object.keys(filterOption).map(e=>
              <option value={e}>{e}</option>
              )}
          </select>
          <select value={fieldValue} onChange={(e) => setFieldValue(e.target.value)}>
          {filterOption[field]?.map(e=>
            <option value={e}>{e}</option>
            )}
           
          </select>
          <Button
              size="medium"
              style={{ background: "#27AE60", fontSize: "10px", color: "#fff" }}
              onClick={()=>getTransactions(0, pagination?.pageSize)}
            >
              Search
          </Button>
        </div>
        <div >
        
         
         <Button
              size="medium"
              style={{ background: "#27AE60", fontSize: "10px", color: "#fff" }}
              // onClick={() => setShowExport(true)}
            >
              <CSVLink
                data={exportData}
                style={{            
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  textDecoration:"none"
                }}
                filename={`Billspayment-history.csv`}
                onClick={(event, done) => {
                  //event.preventDefault();
                    // handleFilterOperation();
                  //done()           
                }}
              >
                DOWNLOAD AS EXCEL
              </CSVLink>
        </Button>

          {/*<AntDropdown
            dropDownOptions={SendUserOptions}
            handleDropdownAction={handleSendUserDropdownAction}
          >
            <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              Perform Transaction
            </Button>
          </AntDropdown> */}
        </div>
      </div>

      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          {transactions?.length > 0 ? <DataGrid
            className={styles.table}
            rows={search.searchText ? transactions.filter(req => req?.paymentReferenceNumber.toLowerCase()?.includes(search?.searchText.toLowerCase()) ) :
              transactions}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          /> : 
          <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Bills Yet</h4>
          <p className="mt-3 text-center" style={{ color: 'gray' }}>
            Bills payment will appear here
          </p>
        </>
          }
        </div>
      </MatGrid>
      {showTransactionReceiptModal && (
        <ViewTransaction
          showModal={showTransactionReceiptModal}
          setShowModal={setShowTransactionReceiptModal}
          transaction={transaction}
        />
      )}
      {showReverseModal && (
        <ReverseBillPayment
        showModal={showReverseModal}
        setShowModal={setShowReverseModal}
        transaction={transaction}
        setWallets = {setWallets}
        wallets={wallets}
        setReverseData= {setReverseData}
        reverseData = {reverseData}
        handleReverse={handleReverseTransaction}
        />
      )

      }
      {showFilter && (
        <FilterTransactions
          showModal={showFilter}
          hideModal={setShowFilter}
          clearFilter={handleClearFilter}
          searchData={searchData}
          setSearchData={setSearchData}
          handleFilters={handleFilter}
        />)
      }
      {showSendUser ? (
        <SendSingleUserModal
          showModal={showSendUser}
          hideModal={setShowSendUser}
          title="Send money to Single User"
          className={styles.modal}
          categories={categories}
          setCategories={setCategories}
          wallets={wallets}
          setWallets = {setWallets}
          quickTeller={quickTeller}
        />
      ):''}
      {showBiller ? (
        <BillerForm
          showModal={showBiller}
          hideModal={setShowBiller}
          aggr={aggr}
          title="Create Biller"
          className={styles.modal}
        />
      ):''}

      {/* <Modal
        visible={visible}
        title="Title"
        //onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}>
        <ExportTrans />
      </Modal> */}


      {showExport ? (
        <ExportTrans showModal={showExport} hideModal={setShowExport} dataList={transactions} />
      ) : (
        ""
      )}
      {showTransferExcel ? (
        <UploadExcel
          showModal={showTransferExcel}
          hideModal={setShowTransferExcel}
          title="Upload Excel Sheet"
          className={styles.modal}
        />
      ) : (
        ""
      )}
   {        showTransferForms? (
          <BillPaymentWebForm
          showModal={showTransferForms}
          hideModal={setShowTransferForms}
          categories={categories}
          setCategories={setCategories}
          title="Pay Bills to Users"
          className={styles.modal}
          wallets={[]}
          />
        ):("")
      }
       

    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default BillsPayment;
