import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

const VerifyUser =({ 
    // showModal,
    hideModal,
    title,
    description,
    buttonLabel,
    handleSubmit,
    loading,})=> {
  /*const {
    showModal,
    hideModal,
    title,
    description,
    buttonLabel,
    handleSubmit,
    loading,
  } = props;*/
  const [data, setData] = useState({});

  return (
    <Modal
      visible={true}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title={title}
      bodyStyle={{
        padding: '23px 73px 44px 73px',
        borderRadius: '12px',
      }}
    >
      <div className="modal-body-rs">
        <div className="header-sec-modal">
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/back.png"
            alt=""
          />
          <img className="header-sec-logo" src="./assets/appLogo.png" alt="" />
          <input
            type="image"
            onClick={() => hideModal(false)}
            className="header-img1"
            src="./assets/x.png"
            alt=""
          />
        </div>

        <div className="waya-modal-body">
          <h1 className="modal-header-data">{title}</h1>

          <p className="modal-header-sub-data text-secondary">{description}</p>

          <form>
            <div className="inputbox-with-one-input">
              <input
                placeholder="Email or Phone"
                type="text"
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
            </div>
          </form>

          <div className="w-100 mt-5 text-center">
            <Button
                style={{
                marginLeft: "auto",
                marginRight: "auto",
                background: "#FF4B01",
                color: "#fff",
                borderRadius: "3px",
                fontSize: "14px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                padding: "10px 56.76px",
              }}
              onClick={() => handleSubmit(data.email)}
            >Submit </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default VerifyUser
/*
VerifyUser.defaultProps = {
  title: '',
  description: '',
  buttonLabel: 'Send',
};

VerifyUser.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
*/
