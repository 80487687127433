import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "../request-log/export";
import { Filter, FilterList, ThreeDRotation } from "@mui/icons-material";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import moment from "moment";

const LoginTracker = () => {
  let roles= localStorage.getItem('roles')
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();

  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const styles = useMatStyles();
  const [logStat, setLogStat] = useState([])
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [totalRequest, setTotalRequest] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [status, setStatus] = useState('All')
  const getRequest = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : page === 0 ? 0 : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let request;
    request = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/history/all-history?page=${pages}&size=${limit}`
    );
      console.log(request.data);
    if (request.status) {
      hideLoader();
      setAllRequests(request?.data?.history);
      setTotalRequest(request?.data?.totalItems);
      console.log('hhh', request?.data?.history);

    }else{
      hideLoader();
      console.log(request);
    }
    hideLoader();
  };

  
  useEffect(() => {
    getRequest();
}, [status]);

  

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getRequest(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getRequest(newPage, pagination.pageSize);
  };


  var searchInput = useRef({});
  const columns = [    
    

    { field: 'name',
      headerName: "Name",
      width: 250,
      headerClassName: styles.tableHeader,

    },
    { field: 'role',
    headerName: "Role",
    headerClassName: styles.tableHeader,
    width: 200,
    renderCell: ({row})=> row?.role?.name ?? "Not found"
    },
    { field: 'phone',
    headerName: "Phone Number",
    headerClassName: styles.tableHeader,
    width: 150,
    renderCell: ({row})=> row?.phone ?? "Not found"

    },   
    { field: 'country',
    headerName: "Location",
    headerClassName: styles.tableHeader,
    width: 150,
    renderCell: ({row})=> row?.country ?? "Not found"

    },   
    { field: 'ip',
    headerName: "ip",
    width: 150,
    headerClassName: styles.tableHeader,
    renderCell: ({row})=> row?.ip ?? "Not found"

    },
    { field: 'device',
    headerName: "Login Device",
    width: 150,
    headerClassName: styles.tableHeader,
    renderCell: ({row})=> row?.device ?? "Not found"

    },
   
    { field: 'loginDate',
    headerName: "Login Duration",
    headerClassName: styles.tableHeader,
    width: 150,
    renderCell: ({row})=> moment(row?.loginDate).format('YYYY-MM-DD')
    }   
   
  ];

  
  return (
    <>

{/*<div style={tool}>
 
<div  >
<Row  justify="start" >     

<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
          </Row>
        </div>       
      </div>*/}




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Total Login Tracker</p>
          <h2 className="text-left">{totalRequest}</h2>
        </Card.Grid>        
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allRequests.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allRequests}
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalRequest}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests}  />
      }
    
    </>
  );
};

const gridStyle = {
  width: "18%",
  textAlign: "center",
  backgroundColor: "#fff",
  fontWeight: "bold",
  marginLeft: "10px",
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '30px',
  justifyContent:'space-between',
  flexDirection: 'column'
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default LoginTracker;
