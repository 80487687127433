import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

const SingleInputModal = ({
  isOpen,
  setIsOpen,
  title = "Enter a value",
  handleClick,
  inputType,
  inputLabel,
  inputDescription =""
}) => {
    const [inputValue, setInputValue] = useState("");
  const handleSubmit = () => {
    setIsOpen(false);
    handleClick(inputValue);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {inputDescription}
          </DialogContentText>
          <TextField
            autoFocus
            margin="comfortable"
            id={inputType}
            label={inputLabel}
            type={inputType}
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SingleInputModal;
