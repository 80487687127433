import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import OtpInput from 'react-otp-input';
import PinModal from '../../components/Modal/Pin/PinModal';

import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPostFormData,
  httpPut,
} from "../../store/http";
import frontArrow from "../../assets/images/front_arrow.svg";
import backArrow from "../../assets/images/back_arrow.svg";
import check from "../../assets/images/check.svg";
import pending from "../../assets/images/pending.svg";
import upload from "../../assets/images/upload.svg";
import "./index.scss";
import ViewDocModal from "../../components/Modal/ViewDoc";
import Tier3 from "./tier3";
import Tier1 from "./tier1";
import blocked from '../../assets/images/blocked.svg';
import StatImage from './StatImage';

import UploadModal from "./UploadModal";
import Tier5 from "./tier5";
import { hideLoader, showLoader } from "../../utils/loader";
// import { hideLoader, showLoader } from '../../../utils/loader';
import VerifyEmailAndPhone from "./VerifyEmailAndPhone";
import { getSentenceCase } from "../../utils/helper";
import { KycDocumentType } from "../../utils/enum";
import { Button, Input, notification } from "antd";
import UpdateKYC from "./updateKYC";
import Tier4 from "./tier4";
import BVNModal from "./bvnModal";

const Index = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [kycStatus, setKycStatus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [postData, setPostData] = useState({});
  const [bvnStat, setBvnStat] = useState();
  const [bvn, setBVN] = useState([]);
  const [bvnVal, setBvnVal] = useState('');
  const [loading, setLoading] = useState(false);
  const [idType, setIdType] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [name, setName] = useState("");
  const [utilityBillStat, setUtilityBillStat] = useState([]);
  const [trnxlevels, settrnxLevels] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState("");
  const [page, setPage] = useState(1);
  const [viewData, setViewData] = useState({});
  const [showViewDocModal, setShowViewDocModal] = useState(false);
  const [showViewDocModal1, setShowViewDocModal1] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [tier1Data, setTier1Data] = useState({});
  const [Bdescription, setBdescription] = useState(null);
  const [Btype, setBtype] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState(null);
  const [appUrl, setAppUrl] = useState(null);
  const [facebookUrl, setFacebookUrl] = useState(null);
  const [instagramUrl, setInstagramUrl] = useState(null);
  const [twitterUrl, setTwitterUrl] = useState(null);
  const [otp, setOTP] = useState('');
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [showBvnUpdate, setShowBvnUpdate] = useState(false);
  const [bvnData, setBvnData] = useState({});
  const [completed, setCompleted] = useState({
    teir1: false,
    teir5: false,
  });
  const [docs, setDocs] = useState([]);
  const [nibssUrl, setNibssUrl] = useState('');
  const [businessLogo, setBusinessLogo] = useState({});
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [teir1Status, setTeir1Status] = useState({
    business_name: null,
    business_name_reqId: null,
    business_description: null,
    business_description_reqId: null,
    business_logo: null,
    business_logo_reqId: null,
    business_type: null,
    business_type_reqId: null,

  });
  const [teir5Status, setTeir5Status] = useState({
    website_url: true,
    app_url: false,
    facebook_url: false,
    instagram_url: false,
    twitter_url: false,
  });
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [passport, setPassport] = useState([]);
  const [id, setId] = useState([]);
  const [utility, setUtility] = useState([]);
  const [cac, setCac] = useState([]);
  const [tin, setTin] = useState([]);
  const [reference, setReference] = useState([]);
  const [showComment, setShowComment] =useState(false);


  const handleGetBusiness = async () => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=40`
    );
    
    setBusinessTypes(res.businessTypeList);
 
  };
  const handlePage = (type) => {
    if (type.includes('previous')) {
      setPage(page - 1);
      console.log(page - 1);
    }
    if (type.includes('next')) {
      setPage(page + 1);
      console.log(page + 1);
    }
  };

  const getKycStatus = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/fetch-status/${userId}`
    );
    if (res && res.status) {
      hideLoader();
      console.log('kycStatus',res);
      setKycStatus(res.data);
    }else{
      hideLoader();
    }

    return [];
  };

    const getBussinessLogo = async () => {
      const res = await httpGet(
        `${customBaseUrl.kycUrl}/business/logo/user/${userId}`
      );
      setBusinessLogo(res);
      console.log('logo',res)
   
    return [];
  };

  const getCustomerKycDetails = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl
      }/kyc/manage-requirement/list-by-customerId/${Number(userId)}`

    );

    if(res.status){
      hideLoader();
      console.log('res tier1data', res.data)
      const data = await res.data;
      setTier1Data(data);
      getInfo(data);
    }else{
      hideLoader();
      console.log('err', res)
    }
  

  };

  const getInfo = async (data) => {
    data.map((el) => {
      if (el.reqItem === "BUSINESS_NAME") {
        setBusinessName(el.reqValue);
        setTeir1Status((prev) => ({
          ...prev,
          business_name: el.reqValue,
          business_status: el.status,
          business_name_reqId: el.id,
        }));
      } else if (el.reqItem === "BUSINESS_DESCRIPTION") {
        setBdescription(el.reqValue);
        setTeir1Status((prev) => ({
          ...prev,
          business_description: el.reqValue,
          description_status: el.status,
          business_description_reqId: el.id,
        }));
      }else if (el.reqItem === "BUSINESS_LOGO") {
        setTeir1Status((prev) => ({
          ...prev,
          business_logo: el.reqValue,
          logo_status: el.status,
          business_logo_reqId: el.id,
        }));
      }else if (el.reqItem === "BUSINESS_TYPE") {
        setBtype(el.reqValue);
        setTeir1Status((prev) => ({
          ...prev,
          business_type: el.reqValue,
          type_status: el.status,
          business_type_reqId: el.id,
        }));
      } else if (el.reqItem === "allData") {
        const Teirdat = JSON.parse(el.reqValue);
        if (Teirdat.APP_URL) {
          setTeir5Status((prev) => ({ ...prev, app_url: true }));
          setAppUrl(Teirdat.APP_URL);
        }

        if (Teirdat.WEBSITE_URL) {
          setTeir5Status((prev) => ({ ...prev, website_url: true }));
          setWebsiteUrl(Teirdat.WEBSITE_URL);
        }
        if (Teirdat.FACEBOOK_URL) {
          setTeir5Status((prev) => ({ ...prev, facebook_url: true }));
          setFacebookUrl(Teirdat.FACEBOOK_URL);
        }
        if (Teirdat.INSTAGRAM_URL) {
          setTeir5Status((prev) => ({ ...prev, instagram_url: true }));
          setInstagramUrl(Teirdat.INSTAGRAM_URL);
        }
        if (Teirdat.TWITTER_URL) {
          setTeir5Status((prev) => ({ ...prev, twitter_url: true }));
          setTwitterUrl(Teirdat.TWITTER_URL);
        }
        setCompleted((prev) => ({ ...prev, teir5: true }));
      }
      return el;
    });
  };

  const SubmitKycRequirement = async (Lname, value, number) => {
    showLoader();
    const data = {
      customerId: userDetails.userId,
      reqItem: Lname,
      reqValue: value,
      status: "UNDER REVIEW",
      tierName: `tier${number}`,
    };
    /* const res = await httpPost(
      `${customBaseUrl.kycUrl}/kyc/manage-requirement/customer/add`,
      data
    );
    */
    let d= new Date();
    let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
         {
           payload: JSON.stringify(data),
           "requestId": `REQ${d.getTime()}`,
           "requestType": "addKycRequirement",
           customerName: userDetails?.firstName+' '+userDetails?.lastName
         } )

    if (res.status) {
      hideLoader();
      Swal.fire("Done", ` ${Lname} submitted`, "success").then(
        () => {
          getCustomerKycDetails();
        }
      );
      return { response: true };
    }else{
      hideLoader();
      Swal.fire("Error", `${res.message}`, "error");
    }
  };

  const handleUploadDoc = async (type = '', image, fileName = '') => {
    setLoading(true);
    showLoader();
    const newForm = new FormData();
    // const fileName  = getSentenceCase(type)?.trimStart().replace(" ", "_").toLocaleUpperCase();
    newForm.append("fileName", fileName);
    newForm.append("file", image);
    newForm.append("userId", userDetails?.userId);
    newForm.append("file", image);
    console.log("type",type);
    // 
    if (type==='bvn'){
      const bvnForm = new FormData();   
      if(!bvnVal){
        hideLoader();
        return notification.error({message:"bvn can't be empty"})
      }
      //cacForm.append("fileType", "CAC");
      //cacForm.append("file", image);
      bvnForm.append('reference', bvnVal);
      bvnForm.append("isBvn", true);
      bvnForm.append("status", "APPROVED");

      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${bvn?.id}/${userId}`,
        bvnForm
      );

      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('CAC')) {
      const cacForm = new FormData();   

      cacForm.append("fileType", "CAC");
      cacForm.append("file", image);
      cacForm.append('reference', postData?.cacId);
      cacForm.append("isBvn", false);
      cacForm.append("status", "UNDER_REVIEW");

      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${cac?.id}/${userId}`,
        cacForm
      );

      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          // getKycStatus();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('reference')) {
      const referenceForm = new FormData();   

      referenceForm.append("fileType", "REFERENCE");
      referenceForm.append("file", image);
      referenceForm.append('reference', postData?.refence);
      referenceForm.append("isBvn", false);
      referenceForm.append("status", "UNDER_REVIEW");

      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${reference?.id}/${userId}`,
        referenceForm
      );

      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          // getKycStatus();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }

    if (type.includes('TIN')) {
      const tinForm = new FormData();
      tinForm.append("fileType", "TIN");
      tinForm.append("file", image);
      tinForm.append('reference', postData?.tinId);
      tinForm.append("isBvn", false);
      tinForm.append("status", "UNDER_REVIEW");

      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${tin?.id}/${userId}`,
        tinForm
      );

      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('UTILITY_BILL')) {
      const utiForm = new FormData();
      utiForm.append("fileName", "Utility_bill");     

      utiForm.append("fileType", "UTILITY");
      utiForm.append("file", image);
      utiForm.append('reference', null);
      utiForm.append("isBvn", false);
      utiForm.append("status", "UNDER_REVIEW");
      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${utility?.id}/${userId}`,
        utiForm
      );
      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('PASSPORT_PHOTO')) {
      const passForm = new FormData();
      passForm.append("fileType", "PHOTO");
      passForm.append("isBvn", false);
      passForm.append("reference", null);
      passForm.append("status", "UNDER_REVIEW");
      passForm.append("file", image);
      passForm.append("userId", userDetails?.userId);
      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${passport?.id}/${userId}`,
        passForm
      );
      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
          getKYCDoc();
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('InternationalPassport')) {
      const iPassForm = new FormData();
      iPassForm.append("fileType", "ID");
      iPassForm.append("file", image);
      iPassForm.append('reference', postData?.passportNumber);
      iPassForm.append("isBvn", false);
      iPassForm.append("status", "UNDER_REVIEW");

      let userId = userDetails.userId;
      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${id?.id}/${userId}`,
        iPassForm,
      );
      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('NationalId')) {
      const natIdForm = new FormData();
      natIdForm.append("fileType", "ID");
      natIdForm.append("file", image);
      natIdForm.append('reference', postData?.virtualnin);
      natIdForm.append("isBvn", false);
      natIdForm.append("status", "UNDER_REVIEW");

      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${id?.id}/${userId}`,
        natIdForm,
      );
      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }

    if (type.includes('proof-address')) {
      const res = await httpPut(
        `${customBaseUrl.kycUrl}/kyc/pos/upload/${userDetails?.userId}?fileName=${KycDocumentType.PROOF_ADDRESS}`,
        newForm,

      );
      if (res.status) {
        Swal.fire("Done", res.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          getKYCDoc();
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res.message, "error");
        setLoading(false);
      }
    }

    if (type.includes('VotersCard')) {
      const voterForm = new FormData();
      voterForm.append("fileType", "ID");
      voterForm.append("file", image);
      voterForm.append('reference', postData?.voterId);
      voterForm.append("isBvn", false);
      voterForm.append("status", "UNDER_REVIEW");
      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${id?.id}/${userId}`,
        voterForm,

      );
      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('DriversLicense')) {
      const licenseForm = new FormData();
      
      licenseForm.append("fileType", "ID");
      licenseForm.append("file", image);
      licenseForm.append('reference', postData?.License);
      licenseForm.append("isBvn", false);
      licenseForm.append("status", "UNDER_REVIEW");
      const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/updateCustomerTierData/${id?.id}/${userId}`,
        licenseForm
      );
      if (res?.status) {
        Swal.fire("Done", res?.message, "success").then(() => {
          hideLoader();
          setShowModal(false);
          setLoading(false);
          getKYCDoc();
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        Swal.fire("Oops!", res?.message, "error");
        setLoading(false);
      }
    }
    if (type.includes('logo')) {
        let newForm = new FormData();
        newForm.append("fileName", 'BUSINESSLOGO');
        newForm.append("file", image);
        newForm.append("userId", userDetails?.userId);
        const res = await httpPost(
          `${customBaseUrl.kycUrl}/business/logo`,
          newForm
        );

/*        let d= new Date();
    let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
         {
           payload: JSON.stringify(data),
           "requestId": `REQ${d.getTime()}`,
           "requestType": "kycLogoUpload",
           customerName: userDetails?.firstName+' '+userDetails?.lastName
         } )
*/
        if (res.status) {
          console.log('busi log',res)
          if(!businessLogo?.adminStatus ){
            SubmitKycRequirement('BUSINESS_LOGO', '',1);
          }else{
            handleUpdatRequirement('BUSINESS_LOGO', '',1)
          }

          Swal.fire("Done", res.message, "success")
            hideLoader();
            setShowModal(false);
            setLoading(false);
            setPostData({});
            getKYCDoc();
            getBussinessLogo();
            setPreviewImage(null);
        } else {
          hideLoader();
          Swal.fire("Oops!", res.message, "error");
          setLoading(false);
        }
    }
    if (type.includes('tin')) {
      const resp = await SubmitKycRequirement("TIN", "TIN", 4);
      if (resp.response) {
        let newForm = new FormData();
        newForm.append("tin", "TIN");
        newForm.append("token", `${localStorage.token}`);
        const res = await httpPostFormData(
          `${customBaseUrl.kycUrl}/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
  
        );
        if (res?.body?.status) {
          Swal.fire("Done", res?.body?.message, "success").then(() => {
            hideLoader();
            setShowModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          Swal.fire("Oops!", res?.body?.message, "error");
          setLoading(false);
        }
      }
    }
    if (type.includes('memart')) {
     // const resp = await SubmitKycRequirement("MEMART", "MEMART", 4);
     // if (res.response) {
        newForm.append("memart", image, image?.name);
        newForm.append("token", `${localStorage.token}`);
        let dFoerm = new FormData();
        dFoerm.append("fileName", "MEMART");
        dFoerm.append("file", image);
        const res = await httpPost(
          `${customBaseUrl.kycUrl}/kyc/pos/upload/${userDetails?.userId}/mermart`,
          dFoerm
        );
        if (res.status) {
          Swal.fire("Done", res.message, "success").then(() => {
            hideLoader();
            setShowModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          Swal.fire("Oops!", res.message, "error");
          setLoading(false);
        }
     // }
    }
    if (type.includes('certificate')) {
      const resp = await SubmitKycRequirement("CAC_FILE", "CAC_FILE", 4);
      if (resp.response) {
        newForm.append("cacDocument", image, image?.name);
        newForm.append("token", `${localStorage.token}`);
        const res = await httpPost(
          `${customBaseUrl.kycUrl}/kyc/pos/upload/${userDetails?.userId}/cac-certificate`,
          newForm
        );
        if (res.status) {
          Swal.fire("Done", res.message, "success").then(() => {
            hideLoader();
            setShowModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
          });
        } else {
          hideLoader();
          Swal.fire("Oops!", res.message, "error");
          setLoading(false);
        }
      }
    }
    setLoading(false);
    hideLoader();
  };

  const getBvnStatus = async () => {
   
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/isBvnLinked/${userId}`
    );
    if (res.status) {
      setBvnStat(res.data);
      setBVN(res?.data?.bvn)
    }
  };

  const getUserDetails = async () => {
    const res = await httpGet(`${customBaseUrl.authUrl}/api/v1/user/${userId}`);
    const response = res;
    if (response.status) {
      if (response.data) {
        const data = response.data;
        setUserDetails(data);

      }

    }

  };

  const getUtilityBillStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/getUserKycData/utilityBill/${userId}`
    );
    if (res.status) {
      setUtilityBillStat(res.data);

    }
    return [];
  };

  const getTransactionLevels = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/fetchAllTransactionLevels`
    );
    if (res.status) {
      settrnxLevels(res.data);

    }
    return [];
  };

  const handleUpdateStatus = async (status, fileName) => {
//    return alert(fileName);
    showLoader();
    const res = await httpPut(`${customBaseUrl.kycUrl}/kyc/status/change`, {
      status,
      fileName: fileName,//==='LICENSE'?fileName:fileName==='BUSINESSLOGO'?fileName:fileName.substring(1),
      userId,
    });
    if (res.status) {
      getKycStatus();
      getBussinessLogo();
      hideLoader();
      Swal.fire(
        "Done",
        `Successfully ${status === "APPROVED" ? "approved" : "rejected"}`,
        "success"
      );
    } else {
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }
  };

  const handleApprove = async (reqId) => {
   // return alert(reqId);
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/manage-requirement/approve-req/${reqId}`

    );
    console.log('res', res)
    if (res.status) {
      hideLoader();
      Swal.fire("Done", "Successfully approved", "success");
      getCustomerKycDetails();

    } else {
      hideLoader();
      Swal.fire("Error", res.message, "error");


    }
  };

  const handleReject = async (reqId) => {
    //  setShowComment();
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/manage-requirement/reject-req/${reqId}`
    );

    if (res.status) {
      hideLoader();
      Swal.fire("Done", "Successfully rejected", "success");
      getCustomerKycDetails();
    } else {
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }
  };

  const handleUpdatRequirement = async (item, val, tier) => {
    showLoader();
    let data = {
        "customerId": userDetails.userId,
        "reqItem": item,
        "reqValue": val,
        "status": "UNDER REVIEW",
        "tierName": `tier${tier}`
      }
    
 /*   const res = await httpPost(
      `${customBaseUrl.kycUrl}/kyc/manage-requirement/admin/update`,
      data
    );
*/
    let d= new Date();
    let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
         {
           payload: JSON.stringify(data),
           "requestId": `REQ${d.getTime()}`,
           "requestType": "updatingKycRequirement",
           customerName: userDetails?.firstName+' '+userDetails?.lastName
         })

    if (res.status) {
      hideLoader();
      Swal.fire("Done", "submitted successfully", "success");
      getCustomerKycDetails();
    } else {
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }
  };

  const handleConfirmOTP = async () => {
   // return alert(userId)
    if (!otp) return Swal.fire("Oops!", "Please enter OTP", "error");
      showLoader();
    //  const res = await httpPost(`${customBaseUrl.bvnUrl}/bvn/validateBvn/${userId}/${otp}`)
    let d = new Date();
    let  res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify( {userId, otp}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "bvnKYC",
      customerName: userDetails?.firstName+' '+ userDetails?.lastName
    } )
      if (res.status) {
        hideLoader();
        setShowOTPModal(false);
        Swal.fire("Done", res?.message, "success");
        //getBvnStatus();
      } else {
        hideLoader();
        Swal.fire("Error", res.message, "error");
      }
  }


  const handleLinkBvn = async () => {
    if(!bvnData.bvn)  return Swal.fire("Oops", "Please enter BVN", "error");

     const data = {...bvnData, userId:userDetails.userId}
    showLoader();
    const res = await httpPost(`${customBaseUrl.kycUrl}/kyc/validateBvn/${userDetails?.userId}/${bvnData?.bvn}`, 
    data
    );
    if (res.status) {
      hideLoader();
      setShowBvnUpdate(false)
      Swal.fire("Done", res?.message, "success");
      //setShowOTPModal(true);

    } else {
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }
  }

  const handleBVNOTPResend = async () => {
    
    showLoader();
    const res = await httpPost(`${customBaseUrl.bvnUrl}/bvn/resendOTP/${userDetails.userId}`)

    if (res.status) {
      hideLoader();
      setShowOTPModal(true);
      Swal.fire("Done", "OTP sent", "success");
      setShowBvnUpdate(false)
      setOTP('');
    } else {
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }

  }

  const getNibss = async () => {
    const res = await httpGet(
      `${customBaseUrl.nipBvnUrl}/api/v1/bvn-service/redirect`
    );
    console.log('nibsss', res);
    setNibssUrl(res);
  };
  const getKYCDoc = async () => {
    const res = await httpPost(
      `${customBaseUrl.kycUrl}/api/v1/admin/kyc/requirementsData/${userId}`,
      {},
    );
    if (res?.status) {
      // console.log('bvn', res);
      if (res?.data) {
        setDocs(res?.data);
        /*const email1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'EMAIL'
        )[0];
        const phone1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'PHONE_NUMBER'
        )[0];*/
        const bvn1 = res?.data?.tier2?.filter(
          (e) => e?.requirementType === 'BVN'
        )[0];
        const passport1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'PHOTO'
        )[0];
        const id1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'ID'
        )[0];
        const utility1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'UTILITY'
        )[0];
        const cac1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'CAC'
        )[0];
        const tin1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'TIN'
        )[0];
        const reference1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'REFERENCE'
        )[0];
        
        //setEmail(email1);
        //setPhone(phone1);
        setBVN(bvn1);
        setPassport(passport1);
        setId(id1);
        setUtility(utility1);
        setCac(cac1);
        setTin(tin1);
        setReference(reference1);
      } else {
        console.log(res, docs);
      }
    }
  };

  useEffect(async () => {
    //  getNibss();
    getKYCDoc();
    await getUserDetails();
    // await getCustomerKycDetails();
    // await getKycStatus();
    //  await getBvnStatus();
    //await getTransactionLevels();
    //await getUtilityBillStatus();
    // await getCustomerKycDetails();
    // await getBussinessLogo();
    // await handleGetBusiness()


  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Layout>
      <div className="d-flex my-5">
        <div
          className=""
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/users")}
          >
          <span className="me-1">
            <ArrowLeftOutlined />
          </span>
          back
        </div>
        <div className="text-center h5" style={{ width: "90%" }}>
          Upgrade KYC Level
        </div>

        <div>
          <Button type="primary" onClick={() => setShowViewDocModal1(true)}>
            Update KYC Level
          </Button>
        </div>
      </div>

      {page === 1 ? (
        <Tier1
          handlePage={handlePage}
          page={page}
          userDetails={userDetails}
          setShowAlertType={setShowAlertType}
          setShowAlert={setShowAlert}
          setBusinessName={setBusinessName}
          businessName={businessName}
          tier1Data={tier1Data}
          teir1Status={teir1Status}
          Btype={Btype}
          setBtype={setBtype}
          Bdescription={Bdescription}
          setBdescription={setBdescription}
          setShowViewDocModal={setShowViewDocModal}
          setViewData={setViewData}
          handleUpdateStatus={handleUpdateStatus}
          handleApprove={handleApprove}
          handleReject={handleReject}
          setName={setName}
          user={userDetails}
          setShowModal={setShowModal}
          kycStatus={docs}
          businessLogo={businessLogo}
          handleRequirement={SubmitKycRequirement}
          getKycStatus = {getKycStatus}
          handleUpdatRequirement={handleUpdatRequirement}
          businessTypes={businessTypes}
        />
      ) : page === 2 ? (
        <div className="tier" id="tier2">
          <div className="info my-3">
            <p className="info-1">Tier 2</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${bvn?.status==='APPROVED' ? "info-2-b-true" : "info-2-b-false"
                  } px-3 py-1`}
              >
                {bvn?.status==='APPROVED' ? "Completed" : "Not Completed"}
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="">
                  <th className="p-2 ">Kyc Requirement</th>
                  <th className="p-2 ">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">BVN Validation</td>
                  <td>
                    <div
                      className={
                        bvn?.status==='APPROVED' ? "status-true" : "status-pending"
                      }
                    >
                      <span>
                        {bvn?.status==='APPROVED' ? " Verified" : "Pending"}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        <input placeholder="input bvn" disabled={bvn?.status==='APPROVED'} value={ bvn?.status==='APPROVED'?bvn?.verificationReference: bvnVal} onChange={(e)=>setBvnVal(e.target.value)}/>
                      </span>
                    </div>
                    </td>
                    <td>
                    <div onClick={()=>handleUploadDoc('bvn')}
                        className={`action-2 ${bvn?.status==='APPROVED'? 'btn disabled': 'btn'
                          } my-2`}
                      >
                      <img src={frontArrow} alt='' className='me-2' />
                        
                        <span>
                      {bvn?.status==='APPROVED' ? 'Verified': 'verify'}
                        </span>
                      </div>
                  </td>
                  

                </tr>
              </tbody>
            </table>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? "btn disabled " : "btn"}`}
                onClick={() => {
                  handlePage("previous");
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{" "}
              <div className="page mx-1">{page}</div>{" "}
              <div
                className={`${page === 4 || bvnStat?.validated  ? "btn disabled " : "btn"}`}
                onClick={() => {
                  handlePage("next");
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
      ) : page === 3 ? (
        <Tier3
          kycStatus={docs}
          setViewData={setViewData}
          hideModal={setShowModal}
          setName={setName}
          setShowViewDocModal={setShowViewDocModal}
          handlePage={handlePage}
          page={page}
          handleUpdateStatus={handleUpdateStatus}
          handleAprove={handleApprove}
          handleReject={handleReject}
          userId={userId}
        />
      ) : page === 4 ? 
      <Tier4
      
          handlePage={handlePage}
          page={page}
          userDetails={userDetails}
          setShowAlertType={setShowAlertType}
          setShowAlert={setShowAlert}
          setBusinessName={setBusinessName}
          businessName={businessName}
          tier1Data={tier1Data}
          teir1Status={teir1Status}
          Btype={Btype}
          setBtype={setBtype}
          Bdescription={Bdescription}
          setBdescription={setBdescription}
          setShowViewDocModal={setShowViewDocModal}
          setViewData={setViewData}
          handleUpdateStatus={handleUpdateStatus}
          handleApprove={handleApprove}
          handleReject={handleReject}
          setName={setName}
          user={userDetails}
          setShowModal={setShowModal}
          kycStatus={docs}
          businessLogo={businessLogo}
          handleRequirement={SubmitKycRequirement}
          getKycStatus = {getKycStatus}
          handleUpdatRequirement={handleUpdatRequirement}
        />
      /*(
        /*}
        <div className="tier" id="tier4">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 4</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${KycDocumentType.PROOF_ADDRESS === true ? "info-2-b-true" : "info-2-b-false"
                  } px-3 py-1`}
              >
                {KycDocumentType.PROOF_ADDRESS === true ? "Completed" : "Not Completed"}
              </p>
            </div>
          </div>
          <div className="table ">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className=" w-50">Kyc Requirement</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">
                    Address verification - photo of the street, house number,
                    left and right
                  </td>
                  <td>
                    <div //className='status mb-3'
                      className={`d-flex
                          ${KycDocumentType.PROOF_ADDRESS
                          ? 'status-true'
                          : 'status-pending'
                        }`}
                    >
                      <img

                        src={KycDocumentType.PROOF_ADDRESS ? check : pending}
                        alt=''

                        className={` ${KycDocumentType.PROOF_ADDRESS
                          ? 'status-true'
                          : 'status-pending'
                          }`}
                      />
                      <span>{KycDocumentType.PROOF_ADDRESS ? "APPROVED" : 'Not Completed'}</span>
                    </div>
                  </td>
                  <td>
                    <div className='action-2 btn my-1 action-d'>
                      {KycDocumentType.PROOF_ADDRESS || "Not Uploaded"}
                    </div>
                  </td>
                  <td>

                    <div
                      //className='action-2 btn my-2'
                      className={`action-2  btn my-2' ${KycDocumentType.PROOF_ADDRESS ? 'btn disabled' : 'btn'
                        } my-2`}
                      onClick={() => {
                        setShowModal(true);
                        setName('proof-address');
                      }}


                    >
                      <img src={upload} alt="" className="me-1" />
                      <span> {KycDocumentType.PROOF_ADDRESS ? '  Uploaded' : 'Upload'}</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className='action-2 btn my-2'
                      onClick={() => {
                        setViewData({
                          url: KycDocumentType.PROOF_ADDRESS,
                          title: 'ADDRESS VERIFICATION',
                        });
                        setShowViewDocModal(true);
                      }}
                    >
                      <img src={upload} alt='' className='me-2' />
                      <span>View</span>
                    </div>
                  </td>

                  <td>
                    <div className="status">
                      <button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2 btn btn-outline-success my-2' ${KycDocumentType.PROOF_ADDRESS ? 'btn disabled' : 'btn'
                          } my-2`}


                        onClick={() => handleUpdateStatus('APPROVED', KycDocumentType.PROOF_ADDRESS)}
                      >
                        <StatImage
                          srcImage={KycDocumentType.PROOF_ADDRESS ? check : pending}
                          className={`me-2 ${KycDocumentType.PROOF_ADDRESS
                            ? 'status-true'
                            : 'status-pending'
                            }`}

                        />
                        {KycDocumentType.PROOF_ADDRESS ? 'Approved' : 'Approve'}
                      </button>
                    </div>
                  </td>
                  <td>
                    <button
                      className='action-2 btn btn-outline-danger  my-2'
                      //onClick={() => handleReject(teir5Status.website_url)}
                      onClick={() => handleUpdateStatus('REJECTED', KycDocumentType.PROOF_ADDRESS)}
                    >
                      <StatImage
                        srcImage={blocked}
                        className={`me-2 status-true`}
                      />
                      Reject
                    </button>
                  </td>
                </tr>

                {/* <tr className="">
                  <td className="p-3">{ } </td>
                  <td>
                    <div className="action btn my-2">
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                  </td>
                  <td>
                    <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>Approved</span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">{ }</td>
                  <td>
                    <div className="action btn my-2">
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload 3</span>
                    </div>
                  </td>
                  <td>
                    <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>Approved</span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">{ }</td>
                  <td>
                    <div className="action btn my-2">
                      <img src={upload} alt="" className="me-2" />
                      <span>Add</span>
                    </div>
                  </td>
                  <td>
                    <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>Approved</span>
                    </div>
                  </td>  
                </tr>*//*}
              </tbody>
            </table>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? "btn disabled " : "btn"}`}
                onClick={() => {
                  handlePage("previous");
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{" "}
              <div className="page mx-1">{page}</div>{" "}
              <div


                className={`${page === 5 || KycDocumentType.PROOF_ADDRESS 
                  ? 'btn disabled ' : 'btn '}`}

                onClick={() => {
                  handlePage('next');
                }}

              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
      )*/ : page === 5 ? (
        <Tier5
          handlePage={handlePage}
          page={page}
          teir5Status={teir5Status}
          websiteUrl={websiteUrl}
          twitterUrl={twitterUrl}
          instagramUrl={instagramUrl}
          appUrl={appUrl}
          completed={completed}
          setAppUrl={setAppUrl}
          facebookUrl={facebookUrl}
          setFacebookUrl={setFacebookUrl}
          setWebsiteUrl={setWebsiteUrl}
          setTwitterUrl={setTwitterUrl}
          SubmitKycRequirement={SubmitKycRequirement}
          setViewData={setViewData}
          setShowViewDocModal={setShowViewDocModal}
          handleUpdateStatus={handleUpdateStatus}
        />
      ) : (
        ""
      )}

      {showModal ? (
        <UploadModal
          hideModal={setShowModal}
          showModal={showModal}
          handleUploadDoc={handleUploadDoc}
          name={name}
          postData={postData}
          setPostData={setPostData}
          loading={loading}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          setIdType={setIdType}
        />
      ) : (
        ""
      )}
       {showBvnUpdate ? (
        <BVNModal
          hideModal={setShowBvnUpdate}
          showModal={showBvnUpdate}
          handleLinkBvn={handleLinkBvn}
          handleBVNOTPResend={handleBVNOTPResend}
          name={name}
          postData={postData}
          setPostData={setPostData}
          loading={loading}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          setIdType={setIdType}
          profile={userDetails}
          bvnData={bvnData}
          setBvnData={setBvnData}
          bvnStat={bvnStat}
          nibssUrl={nibssUrl}
        />
      ) : (
        ""
      )}
      {showAlert ? (
        <VerifyEmailAndPhone
          showAlert={showAlert}
          type={showAlertType}
          setShowAlert={setShowAlert}
          profile={userDetails}
        />
      ) : (
        ""
      )}
      {showViewDocModal ? (
        <ViewDocModal
          showModal={showViewDocModal}
          hideModal={setShowViewDocModal}
          data={viewData}
        />
      ) : (
        ""
      )}
      {showViewDocModal1?
      (<UpdateKYC showUpdateModal={showViewDocModal1} getKycStatus={getKycStatus} setShowUpdateModal={setShowViewDocModal1} userId={userId} />)
      :''}
    {showOTPModal?
     <PinModal
     center
     showModal={showOTPModal}
     hideModal={setShowOTPModal}
     otp={otp}
     setOtp={setOTP}
     numInputs={6}
     separator=''
     buttonLabel='Verify'
     title='Verify BVN'
     description='Please input the OTP sent to your Phone'
     handleSubmit={handleConfirmOTP}
   />
  
    :''}
    </Layout>
  );
};

export const TierDetails = ({ verified, name, setChange }) => {
  return (
    <div className="action-3 btn my-2 action-d ms-0">
      {verified ? (
        <span>{name}</span>
      ) : (
        <input disabled={verified}
          type="text"
          className="editInput"
          value={name}
          onChange={(e) => setChange(e.target.value)}
        />
      )}
    </div>
  );
};

export const Wrapper = ({ children }) => {
  return (
    <div
      style={{
        width: "calc(100% - 20px)",
        // display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        // marginLeft: 10,
      }}
    >
      {children}
    </div>
  );
};

export const StatusHolder = ({ status }) => {
  return (
    <div className={status === "APPROVED" ? "status-true" : "status-pending"}>
      <img
        src={status === "APPROVED" ? check : pending}
        alt=""
        className={`me-2 ${status === "APPROVED" ? "status-true" : "status-pending"
          }`}
      />
      <span>{status === null ? "Pending" : status}</span>
    </div>
  );
};


export default Index;
