import './dispute.css';
import { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box, IconButton } from '@mui/material';
import { customBaseUrl, httpGet } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import { ArrowBack, DeleteOutline } from '@mui/icons-material';
import { Card, Col, Row } from 'antd';
import ExportTrans from './modals/export';
import Action from './modals/action';
import FilterDisputes from './modals/filter';
import { PlusOutlined } from '@ant-design/icons';
import CreateDispute from './modals/addDispute';

function Dispute() {
  const [display, setDisplay] = useState(true);
  const [selectedRow, setSelectedRow] = useState([])
  const [charges, setCharges] = useState('')
  const [admins, setAdmins] = useState([])

  return (
    <Layout>
      {display?<DisputePage setDisplay={setDisplay} setSelectedRow={setSelectedRow} setAdmins={setAdmins} setCharges={setCharges}/>:
      <DetailsPage setDisplay={setDisplay} selectedRow={selectedRow} admins={admins} charges={charges}/>}
    </Layout>
  );
}



const DetailsPage = ({setDisplay, selectedRow, charges, admins}) => {
  const [showAction, setShowAction] = useState(false)

  return(
    <>
    <div>
    <ArrowBack   onClick={() => setDisplay(true)} />
    back
    </div>
    <div style={{marginLeft:20}}>
    Tickect Id: {selectedRow?.trackingNumber}
    </div>


    <Row gutter={6}>
        <Col className="gutter-row" span={16}>
        <h5 className='text' style={{color: '#FF4B01'}}>
        Dispute Details
        </h5>

          <Card > 
         <Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Status
        </Col>
        <Col className="gutter-row" span={8}>
          {selectedRow.status}
         </Col>
        </Row>
        <hr/>

        <Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Transaction Id
        </Col>
        <Col className="gutter-row" span={8}>
          {selectedRow?.transactionId}
         </Col>
        </Row>
        <hr/>


        <Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Category
        </Col>
        <Col className="gutter-row" span={8}>
          {selectedRow?.ticketCategory?.category}
         </Col>
        </Row>
        <hr/>

        <Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Dispute Amount
        </Col>
        <Col className="gutter-row" span={8}>
          {selectedRow.transactionAmount}
         </Col>
        </Row>
        <hr/>
       {(selectedRow.status==='OPENED' || selectedRow.status==='CLOSED')&& <><Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Transaction Charges
        </Col>
        <Col className="gutter-row" span={8}>
          {//charges?.filter(e=>e?.eventId==="DISPCHARGE")[0].tranAmt
          }
         </Col>
        </Row>
        <hr/></>}

        <Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Transaction Date
        </Col>
        <Col className="gutter-row" span={8}>
          {selectedRow.transactionDate}
         </Col>
        </Row>
        <hr/>


        <Row gutter={6} style={{padding:10}}>
        <Col className="gutter-row" span={16}>
          Dispute Description
        </Col>
        <Col className="gutter-row" span={8}>
          {selectedRow.narrationOfDispute}
         </Col>
        </Row>


      

          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
        <h5 className='text' style={{color: '#FF4B01'}}>
          Attached Files
          </h5>
          <Card > 
            <div style={{width: '80%',  borderRadius:5, margin:4, height:200, backgroundColor:'gray'}}>
              <img src={selectedRow?.file} style={{ width: '100%', height: 200, maxHeight: '100%', maxWidth:'100%' }}   />
            </div>
         

          </Card>
        </Col>
    </Row>

    <Card>
      ACTION

      <div>
        <button 
         className='btn btn-danger mr-2'
         style={{marginRight:10, background: '#FF4B01',color: '#fff'}}
         onClick={()=>setShowAction(true)}
        >
          Resolve Dispute
        </button>
        <button 
         className='btn btn-primary mr-2'
        >
                <DeleteOutline />
              Delete Dispute
      </button>
      </div>
    </Card>
    {
      showAction && <Action admins={admins} showModal={showAction} hideModal={setShowAction} status={selectedRow?.status} disputeId={selectedRow?.id} trackingNo={selectedRow?.trackingNumber}/>
    }
      
    </>
  )
}

const DisputePage = ({setDisplay, setSelectedRow, setCharges, setAdmins}) => {
  const [state, setState] = useState([]);
  const [disputes, setDisputes] = useState([]);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [dispute, setDispute] = useState({});
  const [totalDispute, setTotalDispute] = useState(0)
  const [totalResolved, setTotalResolved] = useState(0)
  const [totalRejected, setTotalRejected] = useState(0)
  const [totalUnderReview, setTotalUnderReview] = useState(0)
  const [filterStatus, setStatus] = useState('')
  const [showExport, setShowExport] = useState(false);
  const [showLogDispute, setShowLogdispute]= useState(false)
  const [disputeTypes, setDisputeTypes] = useState('')
  // const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState('')
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getDisputes(newPage, pagination.pageSize);
  };

  const renderDeleteButton = (params) => {
    return (
      <strong>
        <Button
                // key={params?.row?.action?.id}
                variant="contained"
                color="#ff6700"
                size="small"
                style={{ marginLeft: 16 }}
                // onClick={() => setQrData(params?.row?.action?.qrCode)}
            >
                <FaBeer />
            </Button>
      </strong>
    );
  };
  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
                // key={params?.row?.action?.id}
                variant="contained"
                color="#fff"
                size="small"
                style={{ marginLeft: 16 }}
                 onClick={() => setDisplay(true)}
            >
                View More
            </Button>
      </strong>
    );
  };
  const columns = [
    {
      field: 'trackingNumber',
      headerName: 'TICKET ID',
      headerClassName: 'super-app-theme--header',
      width: 100,
    },
  
    {
      field: 'category',
      headerName: 'CATEGORY',
      headerClassName: 'super-app-theme--header',
      width: 150,
      renderCell: ({row})=> row?.ticketCategory?.category
    },
    {
      field: 'transactionDate',
      headerName: 'DURATION',
      headerClassName: 'super-app-theme--header',
      width: 200,

    },
    {
      field: 'transactionAmount',
      headerName: 'AMOUNT',
      headerClassName: 'super-app-theme--header',
      width: 200,

    },
    {
      field: 'status',
      headerName: 'STATUS',
      headerClassName: 'super-app-theme--header',
      width: 250,
    },
    {
      field: 'action',
      headerName: 'ACTION',
      headerClassName: 'super-app-theme--header',
      width: 200,
      renderCell:({row})=> <><button
      onClick={() => {setDisplay(false); setSelectedRow(row)}}
      variant="contained"
      color="#fff"
      size="small"
      style={{ marginLeft: 16, padding:10 }}
  >
      View More
  </button><DeleteOutline /></>,
    }
  ];


  

  // const rows = state?.userDisputes.map((row, index) => ({
  //   id: index,
  //   ticketID: row.trackingNumber,
  //   type: row.type,
  //   category: row.category,
  //   duration: row.transactionAmount,
  //   status: row.status,
  //   action: row,
  //   delete: row,
  // }));

  const tableData = []
  const rows = tableData.map((row, index) => ({
    id: index,
    ticketID: row.trackingNumber,
    type: row.type,
    category: row.category,
    duration: row.transactionAmount,
    status: row.status,
    action: row,
    delete: row,
  }));




  const getDisputes = async (page, size) => {
    setDisputes([]);
    showLoader();

    const pages = page == 0 ? page : page?? pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.wayapayDispute}/disputes-ex?page=${pages}&size=${limit}`
    );
    if (response?.status) {
      console.log('disput', response)
      setDisputes(response?.data?.disputes);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalItems);
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination,  pageSize: value });
    await getDisputes(0, value);
  };

  const getTotalDispute = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-dispute`
    );
    if(res?.status === false || res.status== 401){
      hideLoader()
    }else {
      //setFiltered(response.data.content)
      setTotalDispute(res);
      hideLoader();
    } 

  };

  const getTotalResolved = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-resolved`
    );
    
    if(res?.status === false|| res.status== 401){
      hideLoader()
    }else {
      //setFiltered(response.data.content)
      setTotalResolved(res);
      hideLoader();
    } 

  };

  const getTotalRejected = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-rejected`
    );

    if(res?.status === false || res.status== 401){
      hideLoader()
    }else {
      //setFiltered(response.data.content)
      setTotalRejected(res);
      hideLoader();
    } 

  };
  const getTotalUnderReview = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-under_review`
    );

    if(res?.status === false || res.status== 401){
      hideLoader()
    }else {      setTotalUnderReview(res);
      hideLoader();
    } 

  };

  const getCharges = async () => {
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/bank/event/charges`
    );
    if (response.status) {
      setCharges(response.data);
      console.log('charges',response.data)
    } else{
      console.log(response)
    }
  };
  const getAdmins = async () => {
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/users`
    );
    setAdmins(response?._embedded?.userResponse)    
  };

  const getDisputeTypes = async () => {
    const response = await httpGet(
      `${customBaseUrl.wayapayDispute}/ticket-category`
    );
    console.log('disputeTypes',response)

    setDisputeTypes([...response?.data?.ticketCategoryResponses]);


  };  


  useEffect(() => {
    getDisputes();
    getDisputeTypes()
    getAdmins();
    getTotalDispute();
    getTotalResolved();
    getTotalRejected();
    getTotalUnderReview();
    getCharges();
  }, []);

  return (
    <>
      {/* top session  */}
      <section className="dispute-top-session">
       
        <div className="dispute-search-wrapper">
          <div className="dispute-filter-container" onClick={()=>setShowFilter(true)}>
            <FaFilter className="filter-icon" />
            <h4>Filter</h4>
          </div>
          <div className="search-container">
            <div className="search-icon">
              <FaSistrix />
            </div>
            <input type="text" placeholder="Search"   
            onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              />
          
          </div>
          
           <div className='container' style={{backgroundColor:''}} >
           <Button
           onClick={()=>setShowLogdispute(true)}
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              Log a complain on behalf a User
            </Button>             
            
          </div>
          <div className="container" onClick={()=>setShowExport(true)}>
            <div className="mechanics-icon">
              <div className="icon">
                <FaExternalLinkAlt />
              </div>
              <div className="export-data">Export Data</div>
            </div>
          </div>         
        </div>
      </section>

      {/* card session */}
      <section>
        <div className="cards-container dispute-card-container">
          <div className="cards dispute-card">
            <p>All Dispute Logs</p>
            <h2>{totalDispute}</h2>
          </div>
          <div className="cards dispute-card green">
            <p>Total Closed Disputes</p>
            <h2>{totalResolved}</h2>
          </div>
          <div className="cards dispute-card yellow">
            <p>Total Under Review</p>
            <h2>{totalUnderReview}</h2>
          </div>
          <div className="cards dispute-card red">
            <p>Total Rejected Disputes</p>
            <h2>{totalRejected}</h2>
          </div>
        </div>
      </section>

      {/* mini cards session *//*}
      <section>
        <div className="mini-cards-container">
          <div className="single-card">
            <p>Payment Dispute</p>
            <p>200</p>
          </div>
          <div className="single-card">
            <p>Social Dispute</p>
            <p>203</p>
          </div>
          <div className="single-card">
            <p>Auth & Notification</p>
            <p>203</p>
          </div>
          <div className="single-card">
            <p>Other Disputes</p>
            <p>203</p>
          </div>
        </div>
      </section>

      {/* table session */}
      <section>
        <Box
          sx={{
            width: 1,
            '& .super-app-theme--header': {
              background: '#ff6700',
              color: '#ffffff',
            },
          }}
        >
          <div
            style={{
              height: 650,
              width: '100%',
              background: '#fff',
              borderRadius: '8px',
            }}
          >
            {disputes?.length>0&&<DataGrid
            rows={search.searchText ? disputes.filter(req => req?.transactionId.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.narrationOfDispute?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.status?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.transactionDate.includes(search?.searchText) || req?.transactionAmount === (search?.searchText)|| req?.trackingNumber.includes(search?.searchText)) :
           filterStatus? disputes.filter(req => req?.status ===filterStatus):
              disputes 
              }
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalDispute}
          />}
          </div>
        </Box>
      </section>
      {showExport ? (
        <ExportTrans showModal={showExport} hideModal={setShowExport} dataList={disputes} />
      ) : (
        ""
      )}
      {
        showFilter && <FilterDisputes hideModal={setShowFilter} showModal={showFilter} setStatus={setStatus}/>
      }
      { 
      showLogDispute && <CreateDispute getDispute={getDisputes} hideModal={setShowLogdispute} disputeTypes={disputeTypes} showModal = {showLogDispute}/>
      }
    </>
  );
};

export default Dispute;
