export const checkAcess = (value) => {
  const regexFormat = /^[[0-9+-]+$/;
  console.log(regexFormat.test(value));
  return regexFormat.test(value);
};

export const currencyFormat = (num) => {
  return `₦${num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};

export const emailValidator = (email) => {
  // eslint-disable-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(String(email).toLowerCase());
};

export const descendingComparator= (a, b, orderBy)=> {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const  getComparator = (order, orderBy) =>{
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) =>{
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getArraryString = (array = []) => {
  let string = array.map((item) => item).join(", ");
  return string
    .replaceAll("_", " ")
    .toLocaleLowerCase();
};
export const getSentenceCase = (string ="") => {
  return string.replace(/([A-Z])/g," $1", (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  })
 
}
export const transformName = (name = "") => {
  return name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};
