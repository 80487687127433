import styled from 'styled-components';
import color from '../../theme/color';
import device from '../../utils/device';

export const LoginWrapper = styled.div`
  input:focus {
    outline: none !important;
    border: 1.5px solid #e7472c !important;
    border-radius: 2px;
  }
  @media ${device.maxtablet} {
    .div-left {
      display: none !important;
    }
    .div-right {
      width: 100% !important;
    }
  }
`;

export const ForgotPasswordWrapper = styled.div`
  input:focus {
    outline: none !important;
    border: 1.5px solid #e7472c !important;
  }
  button {
    background: ${color.primary};
    text: color.white;
    height: '50px';
    border-color: ${color.primary};
    margin-top: 25px;
  }
  @media ${device.maxtablet} {
    .wrapper {
      width: 100% !important;
      padding: 40px;
    }
  }
`;

const style = {
  login: {
    container: {
      height: '100vh',
      display: 'flex',
    },
    left: {
      background: 'rgba(231, 71, 44, 0.31)',
      width: '50%',
      height: 'inherit',
      image: {
        height: '400px',
      },
      logo: {
        height: '35px',
        position: 'absolute',
        top: '30px',
        left: '40px',
      },
    },
    right: {
      width: '50%',
      height: 'inherit',
    },
    formContainer: {
      width: '70%',
    },
    input: {
      borderRadius: '7px',
      boxSizing: 'border-box',
    },
    button: {
      background: color.primary,
      text: color.white,
      height: '50px',
      borderColor: color.primary,
    },
  },
};

export default style;
