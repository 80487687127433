import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import {Wrapper} from '../style';
import { customBaseUrl, httpPost, httpPostFormData } from '../../../store/http';
import Swal from 'sweetalert2';
import { hideLoader, showLoader } from '../../../utils/loader';
import { TextField } from '@mui/material';
import { ProfileContext } from '../../../store/context/ProfileContext';

const EmailInvite = ({ showModal, hideModal, type }) => {
const [data, setData] = useState({})
const { profile } = useContext(ProfileContext);

const [category, setCategory] = useState('single')
const [value, setValue] = useState(0)
  const submit =async () => {
    let Data ={
      "msg": data.msg,
  "usersList": [
    {
      "email": data.email,
      "firstName": data.firstName,
      "phoneNumber": data.phoneNumber,
      "senderUserId": profile.userId,
      "surname": data.surname
    }
  ]
    }
    showLoader()
    let res;
    if(type==='email'){
    res =await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/email`, Data)
    }else{
      res =await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/sms`, Data)
 
    }
    if(res.status===true){
      hideLoader()
      hideModal(false)
      Swal.fire('Done', "Invitation send successfully")
    }else{
      hideLoader();
      Swal.fire("error", res.message)
    }

  }
  const handleChange = (e) => {
    console.log(e.target.files);
    setValue(e.target.files[0]);
  };

  const inviteUsers = async () => {
    showLoader();
    if (value === 0) {
      return Swal.fire('Error','no file to upload!, please attach one');
    }
    const formdata = new FormData();
    formdata.append('file', value);
    formdata.append('inviteMsg', data.inviteMsg);
    console.log(formdata);
    let res = await httpPostFormData(`${customBaseUrl.contactUrl}/contact/account/service/send/bulk/excelFile/invite?inviteMsg=${data.inviteMsg}`, formdata)

    if(res.status===true){
      hideLoader();
      hideModal(false)
      Swal.fire('Done', res.message)
    }else{
      hideLoader();
      Swal.fire('Error', res.message)
    }

  }

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title='Invite user'
      width='40%'
    >

      <Wrapper>
        <div className="cw-content">
          <div>
            <label>Invitation type</label>
            <select className='input border' style={{marginLeft:20, width: 100, marginBottom:20}} onChange={(e)=>setCategory(e.target.value)}>
              <option value='single'>Single</option>
              <option value='bulk'>Bulk</option>
            </select>
          </div>
          {category ==='bulk'?<div >            
          <div style={{ flexDirection:'column', marginBottom:'20px',}}>
                <label>
                    Invite Message 
                </label>
                <div>
                    <input  onChange={(e)=>{setData({...data, inviteMsg:e.target.value})}}  style={{borderRadius:5, height:40, width:'100%', marginRight:'auto', marginRight:'auto'}}/>
                </div>
            </div>
            <div>
              <TextField
              
                id='outlined-select-currency-native'
                type='file'
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant='outlined'
              />
            </div>
            <div style={{width: '30%', marginLeft:'auto', marginRight:'auto', marginTop:20}}>
            <button  className='btn border' style={{marginBottom:10,background: "#FF4B01", fontSize: "14px", color: "#fff" }} onClick={inviteUsers}>Send Invite</button>

            </div> 
          </div>:<>
            <div style={{display:'flex', justifyContent:"space-around", flexDirection:'row'}}>
            <input type='email' className='input' onChange={(e)=>{setData({...data, email:e.target.value})}} placeholder="Email Address" style={{borderRadius:5, height:40, marginRight:20}}/>
            <input className='input' onChange={(e)=>{setData({...data, phoneNumber:e.target.value})}} placeholder="Phone" style={{borderRadius:5, height:40}}/>

            </div>
            <div style={{display:'flex', justifyContent:"space-around", flexDirection:'row', marginTop:'10px', marginBottom:10}}>
                <div>
                    <input placeholder="first Name" onChange={(e)=>{setData({...data, firstName:e.target.value})}} style={{borderRadius:5, height:40, marginRight:20}}/>
                </div>
                <div>
                    <input placeholder='Last Name' onChange={(e)=>{setData({...data, surname:e.target.value})}} style={{borderRadius:5, height:40}}/>
                </div>
            </div>
            <div style={{ flexDirection:'column', marginLeft:'20px',}}>
                <label>
                    Invite Message 
                </label>
                <div>
                    <input  onChange={(e)=>{setData({...data, msg:e.target.value})}}  style={{borderRadius:5, height:40, width:'100%', marginRight:'auto', marginRight:'auto'}}/>
                </div>
            </div>

            <div style={{width: '30%', marginLeft:'auto', marginRight:'auto', marginTop:20}}>
            <button  className='btn border' style={{marginBottom:10,background: "#FF4B01", fontSize: "14px", color: "#fff" }} onClick={submit}>Send Invite</button>

            </div> 
            </> }      
        </div>
      </Wrapper>
    </Modal>
  );
};

export default EmailInvite;
