/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Box, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import useMatStyles from "./style";
import NewAdminUser from "./modals/NewAdminUser";
import deleteIcon from "../../assets/icons/delete.svg";
import ChangeRole from "./modals/ChangeRole";
import { hideLoader, showLoader } from "../../utils/loader";
import CustomTable from "../../components/CustomTable/index3";
import Layout from "../../components/Layout";
import ShowMoreText from "react-show-more-text";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";

import { Button, Input } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  SettingFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getArraryString, getComparator, stableSort } from "../../utils/helper";
import { NotificationManager } from "react-notifications";
import UpdateKYC from "./modals/udateKYC";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import ExportData from "./export";
const TierLevel = styled.div``;

export default function Index() {
  const styles = useMatStyles();
  const navigate = useNavigate();
  const [showAdmin, setShowAdmin] = useState(false);
  const [showChangeRole, setShowChangeRole] = useState({ isVisible: false });
  const [showUpdateKYC, setShowUpdateKYC] = useState({ isVisible: false });
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState(0);
  const [links, setLinks] = useState({});
  const [selectedUser, setSelectedUser] = useState('');
  const [units, setUnits] = useState([]);
  const [orderData, setOrderData] = useState({
    order: "asc",
    orderBy: "firstName",
  });
  const [search, setSearch] = useState({
    searchBy: "firstName",
    searchText: "",
  });
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });

  const [headers] = useState([
    {
      id: "firstName",
      name: "FULL NAME",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "email",
      name: "EMAIL",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "roles",
      name: "ROLES",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "accessType",
      name: "ACCESS TYPE",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "unit",
      name: "UNIT",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "menu",
      name: "MENU",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "status",
      name: "STATUS",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    // {
    //   name: 'ACCESS',
    //   color: '#ed462f',
    //   weight: 600,
    //   fontSize: '12px',
    //   align: 'left',
    // },
    {
      id: "createdAt",
      name: "DATE CREATED",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
    {
      id: "action",
      name: "",
      color: "#ed462f",
      weight: 600,
      fontSize: "12px",
      align: "left",
    },
  ]);

  const dropDownOptions = ["Change Role", "Activity Log"];
  const handleDropdownAction = (item, row) => {
    if (item === "Change Role") {
      handleShowChangeRole(row);
    } else if (item === "Activity Log") { 
      navigate(`/user/activity_log/${row.userId}`)
      
      
    }
   
  };

  const handleChangePage = async (event, newPage) => {
    setPagination({ ...pagination, current: newPage });
    await getAdminUsers(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (e) => {
    const { value } = e.target;
    console.log(value)
    if (value < 0) {
      setPagination({ ...pagination, current: 0, pageSize: metaData });
      await getAdminUsers(0, metaData);
    }
    else{
      setPagination({ ...pagination, current: 0, pageSize: value });
      await getAdminUsers(0, value);
    }
  
  };
  const handleRequestSort = (event, property) => {
    const { order, orderBy } = orderData;
    const isAsc = orderBy === property && order === "asc";
    setOrderData(
      isAsc
        ? { order: "desc", orderBy: property }
        : { order: "asc", orderBy: property }
    );
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pagination.current > 0
      ? Math.max(
          0,
          (1 + pagination.current) * pagination.pageSize - users.length
        )
      : 0;

  const getAdminUsers = async (page, size) => {
    showLoader();
    const pages =  page? page: page===0?0: pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(`${customBaseUrl.authUrl}/api/v1/admin/users-admin-users?page=${pages}&size=${limit}`,
    );
   if(response?._embedded?.userResponse){
    hideLoader();
    const data = response;
    setUsers(data?._embedded?.userResponse);
    setMetaData(data?.page?.totalElements);
    setLinks(data?._links);
   }else if(response?.status=== false){
    hideLoader();
   }else{
    hideLoader();

   }  
    
  
   
  };

  const getRoles = async () => {
    const response = await httpGet(`${customBaseUrl.authUrl}/api/v1/admin/manage-user/roles`);
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    // const data = response;
    setRoles(response);
  };

  const getUnits = async () => {
    const response = await httpGet(`${customBaseUrl.roleUrl}/api/v1/menu/fetchAll`);
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    // const data = response;
    setUnits(response?.data);
  };

  const handleChangeRole = async (userId,roleId , add) => {
   // return alert(roleId)
    showLoader();
    let success = false;
    const response = await httpPost(`${customBaseUrl.authUrl}/api/v1/admin/manage-user/${userId}/roles/${add}/${roleId}`,
      data);
    hideLoader();
    if (!response || response.error !== undefined || response.status == false) {
      NotificationManager.error("error",response.message);
      success = false;
    }
    else{
      Swal.fire({
        title: "Success",
        text: "Role has been changed",
        icon: "success",
        confirmButtonText: "Ok",
      });
      success = true
    }
    return success
  }

    const handleUpdateKYCLimit = async (TransLimit) => {
    //  return alert (`${selectedUser} ${TransLimit}`);
      showLoader();
      const data ={
        transactionLimit: TransLimit,
        userId: selectedUser
      }
      const response = await httpPost(`/kyc-service/kyc/admin/upgradeTransLimit/${selectedUser}?transactionLimit=${TransLimit}`,
        customBaseUrl.authUrl
      );
      if(response.status){
        hideLoader();
        console.log(response)
        Swal.fire('Success', 'Transaction Limit has been updated', 'success');
        setShowUpdateKYC(false);
      } 
      else{
        hideLoader();
        Swal.fire('Error', response.message, 'error');
      }
 
   
  };


  const handleCreateAdmin = async (data) => {
    let domain = data.email.split('@')
    var numberRegex = /^\d+$/
   const first3 = data?.phoneNumber.substring(0, 3);
      if(first3 != '234') return NotificationManager.error('Not a valid phone number')
      if(!numberRegex.test(data?.phoneNumber)) return NotificationManager.error('Not a valid waya phone number')
                  
    if(data?.phoneNumber.length !=13) return NotificationManager.error('Not a valid waya email address')
    if(domain[1]!=='wayapaychat.com') return NotificationManager.error('Not a valid waya email address')
    showLoader();
    let d = new Date();  
   let reqData =  {
      payload: JSON.stringify({...data, admin:true, ussd: true, wayaBankPayPosGram:'', url:''}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "createUser",
      customerName: data?.firstName+' '+ data?.surname
    } 
    console.log(data)
   let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,reqData);

   /* data.admin = true;
    const res = await httpPost(`${customBaseUrl.authUrl}/api/v1/admin/users/create-private`,
      { ...data, admin: true });
    hideLoader();
    if (!res || res.error !== undefined) {
      return;
    }*/
    // const data = res;
    if (res.status) { 
      hideLoader();
      setShowAdmin(false)
      Swal.fire("Done", res.message, "success");
    } else {
      hideLoader();
      Swal.fire("Wrong", res.message, "error");
    }

  };

  const deactivateAdmin = async (user) => {
    showLoader();
   /* if (
      user.active === true ||
      user.emailVerified === true ||
      user.phoneVerified === true
    ) {
      return Swal.fire(`${user.firstName} is still an active user`);
    }*/
    const response = await httpDelete(`${customBaseUrl.authUrl}/api/v1/user/delete/${user.userId}`);

    if (response.status ) {
      hideLoader();
       getAdminUsers();
      Swal.fire("Deleted!", response.message, "success");
      }else{
      hideLoader();
      Swal.fire('Error', response.message)
    }
    // success(message);
  };

  useEffect(() => {
    setUsers([]);
    getAdminUsers();
    getRoles();
    getUnits();
  }, []);

  const handleShowChangeRole = (userObject) => {
    let newUserObject = { ...userObject };
    newUserObject.isVisible = !showChangeRole.isVisible;
    Object.apply(newUserObject);
    console.log(newUserObject);
    setShowChangeRole(newUserObject);
  };
  const handleUpdateKYC = (userObject) => {
    let newUserObject = { ...userObject };
    newUserObject.isVisible = !showChangeRole.isVisible;
    Object.apply(newUserObject);
    console.log(newUserObject);
    setShowUpdateKYC(newUserObject);
  };

  return (
    <Layout>
      <TierLevel>
        <Box sx={{ flexGrow: 1 }}>
          <div className={styles.searchRow}>
            <select
              className={styles.selectType}
              defaultValue="disabled"
              onChange={(event) => {
                setSearch({ ...search, searchBy: event.target.value });
              }}
            >
              <option value="disabled" disabled>
                Search By...
              </option>
              {headers?.map((header) => (
                <option value={header.id}>{header.name}</option>
              ))}
            </select>
            <div className={styles.searchRow}>
              <Input
                className={styles.searchInput}
                placeholder="Search Users by Name, Email or Date"
                onChange={(event) => {
                  setSearch({ ...search, searchText: event.target.value });
                }}
                prefix={<SearchOutlined className={styles.searchIcon} />}
              />
            </div>
            <div className={styles.grow} />
            <Button
              className={styles.manageAdminButton}
              onClick={() => navigate("/admin/manage_roles")}
              icon={<SettingFilled className={styles.manageButtonIcon} />}
              size="middle"
            >
              Manage Roles and Access
            </Button>
            <Button
          onClick={ ()=>setShowExport(true)}
            variant="contained"
            size="middle"
            style={{ background: '#27AE60', fontSize: '14px', width:'70px' }}
          >
            Export
          </Button>
            <Button
              onClick={() => setShowAdmin(true)}
              className={styles.updateButton}
              icon={<PlusOutlined  />}
              size="middle"
            >
              Create New Admin User
            </Button>
          </div>

          <div className={styles.dashboardItem}>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>All Users</div>
              <div className={styles.dibot}>{metaData}</div>
            </div>  
            <div className={styles.dashItem}>
              <div className={styles.diTop}>All Units</div>
              <div className={styles.dibot}>{0}</div>
            </div>  
            <div className={styles.dashItem}>
              <div className={styles.diTop}>Active Units</div>
              <div className={styles.dibot}>{0}</div>
            </div>  
            <div className={styles.dashItem}>
              <div className={styles.diTop}>Inactive Units</div>
              <div className={styles.dibot}>{0}</div>
            </div>            
          </div>
          {/* <div className={styles.tableFilter}>
            <select
              className={styles.tfItem}
              defaultValue="disabled"
              onChange={() => {}}
            >
              <option value="disabled" disabled>
                Role
              </option>
            </select>
            <select
              className={styles.tfItem}
              defaultValue="disabled"
              onChange={() => {}}
            >
              <option value="disabled" disabled>
                Date
              </option>
            </select>
          </div> */}

          <CustomTable
            headers={headers}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            setShowAccessArea={setShowChangeRole}
            styles={styles}
            count={metaData}
            paginationMode="server"
            onRequestSort={handleRequestSort}
            rowsPerPage={pagination.pageSize}
            page={pagination.current}
          >
            {(pagination.pageSize > 0
              ? stableSort(
                  search.searchBy === "roles"
                    ? users?.filter((user) =>
                        getArraryString(user.roles).includes(
                          search.searchText.toLowerCase()
                        )
                      )
                    : users?.filter((user) =>
                        user[search.searchBy]
                          .toLowerCase()
                          .includes(search.searchText.toLowerCase())
                      ),
                  getComparator(orderData.order, orderData.orderBy)
                )
              : search.searchBy === "roles"
              ? users?.filter((user) =>
                  getArraryString(user.roles).includes(
                    search.searchText.toLowerCase()
                  )
                )
              : users?.filter((user) =>
                  user[search.searchBy]
                    .toLowerCase()
                    .includes(search.searchText.toLowerCase())
                )
            )?.map((item) => (
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  {
                    <Box
                      className={styles.longTextStyle}
                    >{`${item.firstName} ${item.lastName}`}</Box>
                  }
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  {<Box className={styles.shortTextStyle}>{item.email}</Box>}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more="show all"
                    less="show less"
                    expanded={false}
                    width={150}
                    truncatedEndingComponent={"... "}
                  >
                    {getArraryString(item.roles)}
                  </ShowMoreText>
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more="show all"
                    less="show less"
                    expanded={false}
                    width={150}
                    truncatedEndingComponent={"... "}
                  >
                    {(item?.accessType)}
                  </ShowMoreText>
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more="show all"
                    less="show less"
                    expanded={false}
                    width={150}
                    truncatedEndingComponent={"... "}
                  >
                    {(item?.unit)}
                  </ShowMoreText>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more="show all"
                    less="show less"
                    expanded={false}
                    width={150}
                    truncatedEndingComponent={"... "}
                  >
                    {(item?.menu)}
                  </ShowMoreText>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <ShowMoreText
                    /* Default options */
                    lines={1}
                    more="show all"
                    less="show less"
                    expanded={false}
                    width={150}
                    truncatedEndingComponent={"... "}
                  >
                    {(item?.status)}
                  </ShowMoreText>
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.94px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <Box className={styles.shortTextStyle}>
                    {moment(item.createdAt).format("MMM, Do YYYY")}
                  </Box>
                </TableCell>

                <TableCell
                  style={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "14.52px",
                    color: "#000000",
                  }}
                  align="left"
                >
                  <div className="d-flex align-items-center justify-content-between">
                  <AntDropdown
                        dropDownOptions={dropDownOptions}
                        handleDropdownAction={(row) => handleDropdownAction(row, item)}
                        isArrow
                      />
                   
                    <div className="d-flex align-items-center justify-content-center">
                      {//!item.accountDeleted && item.active && (
                        (<img
                          src={deleteIcon}
                          className="mx-1"
                          alt=""
                          onClick={() => {
                            Swal.fire({
                              title: "Remove Admin User?",
                              text: `By doing this, ${item.email} will no longer be able to access this business anymore. \nDo you want to continue?`,
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, Remove",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deactivateAdmin(item);
                              }
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 5 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </CustomTable>
        </Box>

        {showChangeRole.isVisible ? (
          <ChangeRole
            showModal={showChangeRole}
            setShowModal={setShowChangeRole}
            roles={roles}
            handleChangeRole={handleChangeRole}
            getAdminUsers={getAdminUsers}

          />
        ) : (
          ""
        )}
         {showUpdateKYC.isVisible ? (
          <UpdateKYC
            showModal={showUpdateKYC}
            setShowModal={setShowUpdateKYC}
            roles={roles}
            handleUpdateKYCLimit={handleUpdateKYCLimit}
          />
        ) : (
          ""
        )}

        {showAdmin ? (
          <NewAdminUser
            showModal={setShowAdmin}
            setShowModal={setShowAdmin}
            data={data}
            setData={setData}
            handleSubmit={handleCreateAdmin}
            roles={roles}
            units={units}

          />
        ) : null}
        {
          showExport?
          <ExportData showModal={showExport} hideModal={setShowExport} dataList={users} />: null
        }
        {/* {showAdmin ? (
        <CreateAdminUser
          handleOpenAdmin={() => setShowAdmin(true)}
          handleCloseAdmin={() => setShowAdmin(false)}
        />
      ) : null} */}
      </TierLevel>
    </Layout>
  );
}
