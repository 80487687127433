import React, { useState } from "react";
import { Modal, Button, DatePicker, Switch } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import moment from "moment";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";

const UpdateConfig = ({ showModal, hideModal, selectedRow, getConfig}) => {
  const [data, setData] = useState(selectedRow);
  const [config, setConfig] = useState([]);



    const updateConfig = async(e) => {
        e?.preventDefault();
      showLoader();
      let res = await httpPost(`${customBaseUrl.smWalletUrl}/api/v1/wallet/simulation/config/update/{id}?id=${data?.id}`,
      data
      )
      if(res?.status) {
        hideLoader();
        getConfig();
        hideModal(false);
      }else {
        hideLoader();
        console.log(res);
      }
    }

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      width={'500px'}
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
        width: "500px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <form onSubmit={updateConfig} >
        <div className="title">UPDATE {(data?.day).toUpperCase()} CONFIGURATION ({data?.userType === 'corporate' ? 'Business' : 'Individual'})</div>
        <div className="cw-content">
        <div className="input-content">
                <label>Day</label>
            <input 
                value={data?.day} 
                disabled
                className='form-control'
                //onChange={(e)=> setData({...data, numberOfUsers: e?.target?.value})}
             />
            </div>
            <div className="input-content">
                <label>Number Of Users</label>
            <input 
                className='form-control'
                value={data?.numberOfUsers} 
                onChange={(e)=> setData({...data, numberOfUsers: e?.target?.value})} />
            </div>
            <div className="input-content">
                <label>Credit Amount</label>
            <input 
                className='form-control'
                value={data?.creditAmount} 
                onChange={(e)=> setData({...data, creditAmount: e?.target?.value})} />
            </div>
            <div className="input-content">
                <label>Debit AMount</label>
            <input 
                className='form-control'
                value={data?.debitAmount} 
                onChange={(e)=> setData({...data, debitAmount: e?.target?.value})} />
            </div>
            <div className="input-content">
                <label>Active</label>
            <select 
                value={data?.active} 
                className='form-control'
                onChange={(e)=> setData({...data, active: e?.target?.value})} >
                    <option value={true}>Active</option>
                    <option value={false}>InActive</option>
            </select>
            </div>
        </div>
        <div className="text-center">
            <button className="btn"
                style={{
                    marginTop: '50px',
                    marginLeft: "auto",
                    marginRight: "auto",
                    background: "#FF4B01",
                    color: "#fff",
                    borderRadius: "3px",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 25px",
                  }}
            >
                Update Config
            </button>
        </div>
        </form>
      </Wrapper>
    </Modal>
  );
};

export default UpdateConfig;
