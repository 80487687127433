import Layout from "../../../components/Layout";
import React, { useEffect, useState } from "react";
// import {Tabs, Box,Tab} from '@mui/material';
import { Button, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import { SettingFilled } from "@ant-design/icons";
import Wrapper from "./style";
import CoreBankingServices from "./coreBanking";
import FundingWallet from "./fundingWallet";
import Withdraw from "./withdraw";
import BvnValidation from "./bvnValidation"
import SmsGateway from "./smsGateway";
import KycVendor from "./kycVendor";
import CoreBanking from "./coreBankingService";
import BillPaymentAggregators from "./billPaymentAggregators";
import ThirdPartyAccount from "./3rdPartyAccount";
import ExternalAccounts from "./ExternalAccounts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: "5px 0 25px 0",
  },
  textHeader: {
    fontStyle: "normal",
    fontSeight: 500,
    fontSize: 9,
    lineHeight: "18px",
    color: "rgba(0, 0, 0, 0.4)",
    textAlign: "center",
  },
}));
const Index = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("one");
  const [showAddNewBiller, setShowAddNewBiller] = useState(false);
  const [showAddNewCategory, setShowAddNewCategory] = useState(false);

  const moreOptions = [
    "Add New Biller",
    "Add New Category",
    "Billing Information",
  ];
  const handleCreateUserDropdownAction = (item) => {
    if (item === "Add New Biller") showAddNewBiller(true);
    if (item === "Add New Category") showAddNewCategory(true);
    // if (item === "Billing Information") ()=>{
    //   //TODO
    // }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const { TabPane } = Tabs;

  return (
    <Layout>
      <Wrapper>
        {/*<Row className="text-font" type="flex" justify="end" align="middle">
          <AntDropdown
            dropDownOptions={moreOptions}
            handleDropdownAction={handleCreateUserDropdownAction}
          >
            <Button
              size="medium"
              type="dashed"
              style={{
                background: "#FF4B01",
                fontSize: "13px",
                color: "#fff",
                marginBottom: "30px",
              }}
            >
              <SettingFilled />
              More Options
            </Button>
          </AntDropdown>
            </Row>*/}

        <Tabs
          defaultActiveKey="1"
          size="small"
          tabBarStyle={{ paddingLeft: 20 }}
          onChange={handleChange}
          animated
          color="#ff6700"
        >
          <TabPane
            tab={<span>&nbsp;&nbsp; SMS Gateway</span>}
            key="1"
          >
            <SmsGateway keyValue={1} />
          </TabPane>
          <TabPane
            tab={<span>&nbsp;&nbsp; Bills Payments Aggregators</span>}
            key="2"
          >
            <BillPaymentAggregators keyValue={2} />
          </TabPane>
          <TabPane
            tab={<span>&nbsp;&nbsp; Core Banking Services</span>}
            key="3"
          >
            <CoreBanking keyValue ={3}/>
          </TabPane>

          <TabPane
            tab={<span>&nbsp;&nbsp; Funding 3rd Parties</span>}
            key="4"
          >
            <FundingWallet keyValue={4} />
          </TabPane>
          <TabPane tab={<span>&nbsp;&nbsp; Withdraw</span>} key="5">
            <Withdraw keyValue={5} />
          </TabPane>
          <TabPane
            tab={<span>&nbsp;&nbsp; KYC Providers</span>}
            key="6"
          >
            <KycVendor keyValue={6} />
          </TabPane>
          {/*<TabPane
            tab={<span>&nbsp;&nbsp; BVN Validation</span>}
            key="6"
          >
            <BvnValidation keyValue={6} />
          </TabPane>
          <TabPane tab={<span>&nbsp;&nbsp; 3rd Party Account</span>} key="7">
            <ThirdPartyAccount  keyValue={7} />
          </TabPane>
          <TabPane tab={<span>&nbsp;&nbsp; KYC Vendor</span>} key="8">
            <KycVendor  keyValue={8} />
          </TabPane>
          <TabPane tab={<span>&nbsp;&nbsp; External Accounts</span>} key="9">
            <ExternalAccounts  keyValue={9} />
          </TabPane>*/}
        </Tabs>
      </Wrapper>
    </Layout>
  );
};

export default Index;
