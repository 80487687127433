import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ColoredSwitcher from "../../../components/customSwitch/coloredSwitcher";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";

const Withdraw = (props) => {
  const [providers, setProviders] = useState([]);
  const handleToggle = async (providerIndex, providerState, provider) => {
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.withdrawalUrl}/provider/toggle/${provider.id}`,
      {}
    );
    hideLoader();
    if (response.status === true) {
      // handleProviderChange(providerIndex, response?.data?.active);
      handleGetProviders();
    }
  };
  const handleGetProviders = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.withdrawalUrl}/provider/list`
    );
    hideLoader();
    if (response.status === true) {
      setProviders(response?.data);
    }
    hideLoader();
  };
  const handleProviderChange = (providerIndex, providerState) => {
    console.log({providerIndex, providerState});
    const providersCopy = [...providers];
    providersCopy[providerIndex].active = providerState;
    setProviders(providersCopy);
  };
  useEffect(() => {
    handleGetProviders();
  }, []);
  return (
    <>
      <Card
        key={props.keyValue}
        style={{
          transitionDuration: "0.3s",
          minHeight: "30vw",
          display: "block",
        }}
        sx={{ marginTop: "30px" }}
        raised={true}
      >
        <CardContent>
          <List dense={false}>
            <div className="row" style={{
               fontWeight: "bold",
               marginBottom: "30px",

            }}>
              <div className="col-sm-2"></div>
            </div>
            {providers.map((item, index) => (
              <Grid container spacing={10}>
                 <Grid key={item.id + item.name} item sm={11} sx={{ width: 3, height: 150 }}>
                  <ListItem
                    key={item.id + item.name.trim()}
                    secondaryAction={
                      <IconButton key={item.id} edge="end" aria-label="delete">
                        <ColoredSwitcher
                          data={item}
                          type="biller"
                          dataIndex={item.id}
                          id={item.id.toString()}
                          style={{ fontWeight: "700" }}
                          label={item.name}
                          keyValue={item.id + item.name.trim()}
                          color="#ff6700"
                          onChange={handleToggle}
                        />
                      </IconButton>
                    }
                  >
                    <div
                      key={item.id}
                      style={{ fontFamily: "inherit", fontWeight: "700" }}
                    >
                      {item.name}
                    </div>
                  </ListItem>
                </Grid>
              </Grid>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};
export default Withdraw;
