import React, { useState } from 'react';
import { Table } from "antd";
import Summary from './summary/summary';
import { customBaseUrl, httpDelete, httpGet, httpPost, httpPostUnreloaded } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import Swal from 'sweetalert2';
import AntDropdown from '../../../components/CustomDropdown/AntDropdown';

const  SentRequests = ({request,  getSentRequests}) => {
  let [summary, setSummary] = useState(false);
  let [displayData, setDisplayData] = useState({});
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [referenceCode, setReferenceCode] = useState('');


       const columns = [ 
        { 
        key: "reason", 
        title: "Activity", 
        dataIndex: "reason", 
        }, 
        { 
        key: "sender", 
        title: "From", 
        dataIndex: "senderName", 
        render: (text, record) =>  text
        }, 
        { 
        key: "amount", 
        title: "Amount", 
        dataIndex: "amount", 
        }, 
        { 
        key: "status", 
        title: "Status", 
        dataIndex: "status", 
        } ,
        {
         key: "refrence", 
        title: " ", 
        dataIndex: "reference", 
        render: (reference, record) => (
            <AntDropdown
            dropDownOptions={dropDownOptions}
            handleDropdownAction={(action) => handleDropdownAction(reference, record,action)}
            isArrow
            />
        )
        }
      
        ] 
        
        const dropDownOptions = [ "Delete"];
        const handleDropdownAction = (reference, row, action) => {
          if (action === "Delete" && row.status !=='PAID'){
                 deleteRequest(reference);
          } else {
            Swal.fire('Warning', 'You can only delete a request that is not paid', 'warning');
          }

            };

        const deleteRequest = (item) => {
            Swal.fire({
              title: "Are you sure?",
              text: `You want to delete this request with refrence code ${item}`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete it!",
            }).then((result) => {
              if (result.value) {
                handleDeleteRequest(item);
              }
            });
          };



        const handleDeleteRequest = async (refrence) => {
          showLoader();
          
    
         // const formBody = Object.keys(postData).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(postData[key])).join('&');

          const res = await httpDelete(
            `${customBaseUrl.paymentRequestUrl}/delete-payment-request/${refrence}`
          );

          if (res?.status) {
            setSummary(false);
          getSentRequests();
          
            hideLoader();
            Swal.fire('Done', res?.message, 'success');
            console.log(res)
          }
          else{
            Swal.fire('Error', res?.message, 'failed');

          hideLoader();
        }
        }
      

        const handleReject = () => {
          setSummary(false);
        }
 return(

    <div>
        <div className="table"> 
        <Table dataSource={request} columns={columns}  
         onRow={(record, rowIndex) => {
            return {
              onClick: event => {setDisplayData((record)); setSummary(true); setReferenceCode(record.reference)}, // click row
            };
          }}
        //showHeader={false}
        /> 
        </div> 
    </div>
 )   


}

export default SentRequests;