import React, { useState,useEffect } from "react";
import { Button, Divider, Row } from "antd";
import Swal from "sweetalert2";
import WalletCard from "../CustomCard/walletCard";
import checkIcon from "../../assets/images/check.svg";
import blockIcon from "../../assets/images/blocked.svg";
import fmdBad from "../../assets/images/fmd_bad.svg";
import { currencyFormat } from "../../utils/helper";
import { httpPost, customBaseUrl } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import themeColor from "../../theme/color";
import { grey } from "@mui/material/colors";
import { PlusOutlined } from "@ant-design/icons";
import CopyToClipboardComponent from "../CopyToClipBoard";
import AddWalletModal from "./modal/AddWalletModal";
import style from "../../views/Auth/style";
import ListChooserModal from "../Modal/ListChooserModal/ListChooserModal";
import SingleInputModal from "../Modal/singleInputModal/SingleInputModal";
import { useNavigate } from "react-router-dom";
import WithdrawCommission from "./modal/withdrawComToWallet";
import UpdateWalletModal from "./modal/updateWallet";
import SettleLien from "../Modal/singleInputModal/settleLien";

const ManageWallet = ({ userId, walletList, virtualAccount, getWallets,record}) => {
  // const [showAddWalletModal, setShowAddWalletModal] = useState(false);
  const navigate = useNavigate();

  const [showSelectItemModal, setShowSelectItemModal] = useState(false);
  const [accountNo, setAccountNo] = useState("");
  const [showAmountInputModal, setShowAmountInputModal] = useState(false);
  const [showLien, setShowLien] = useState(false);
  const [commissionAccount, setCommissionAccount] = useState("");
  const [withdrawModal, setWidrawModal] = useState(false)
  const [showAddWallet, setShowAddWallet] = useState(false)
  const [edit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showBlockAmount, setShowBlockAmount] = useState(false);
  const pauseFreezeOptions = [
    { label: "DEBIT", value: "D" },
    { label: "CREDIT", value: "C" },
    { label: "TOTAL FREEZE", value: "T" },
  ];
  const handleAddWallet = async () => {
    setShowAddWallet(true)
   /* showLoader();
    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/create-wallet`,
      { userId }
    );

    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: "Wallet created successfully",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: themeColor.primary,
      });
      getWallets();
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: themeColor.primary,
      });
    }*/
  };
  const dropDownItem = (item) => [
    item.gl_code !== "21105"&&{ id: "setPrimaryWallet", label: "Set as Primary Wallet" },
    { id: "blockWallet", label: "Block Wallet" },
    { id: "blockAmount", label: "Add Block Amount" },
    { id: "edit", label: "Update Description" },
    !item?.frez_code ? { id: "pause", label: "Pause", items: [{ id: "pause", label: "Pause" }] }
    : { id: "unfreez", label: "Unfreez"},
    { id: "lien", label: "Lien"},
    { id: "deleteWallet", label: "Delete Wallet", style: "text-danger" },
    item.gl_code === "21105"&&{ id: 'withdraw', label: "Withdraw to Wallet " }
  ];

  const handleDefaultAccount = async (acctNo) => {
    showLoader();
    const defaultWallet = {
      newDefaultAcctNo: acctNo,
      userId,
    };
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/user/account/toggle`,
      defaultWallet
    );
    hideLoader();
    if (res.status) {
      Swal.fire(`Accouunt Number ${acctNo} is set to default`);
    } else {
      Swal.fire(res.message);
    }
  };

  const handleDeleteAccount = async (acctNo) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/closure`,
      {
        customerAccountNo: acctNo,
      }
    );
    hideLoader();
    if (res.status) {
      Swal.fire("Deleted!", res.message, "success");
      getWallets();
    } else {
      Swal.fire(res.message);
    }
  };

  // this is passed into the pause freeze modal to handle the pause freeze
  const handlePauseAccount = async (pauseType, pauseTitle) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/pause`,
      {
        customerAccountNo: accountNo,
        freezCode: pauseType,
        freezReason: "admin request",
      }
    );
    hideLoader();
    if (res.status) {
      Swal.fire(`Paused for ${pauseTitle}!`, res.message, "success");
      getWallets();
    } else {
      Swal.fire(res.message);
    }
  };
  const handleUnFreezAccount = async (accountNo) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/pause`,
      {
        customerAccountNo: accountNo,
        freezCode: 'U',
        freezReason: "admin request",
      }
    );
    hideLoader();
    if (res.status) {
      Swal.fire(`Unfreezing for ${accountNo}!`, res.message, "success");
      getWallets();
    } else {
      Swal.fire(res.message);
    }
  };
  const handleBlockAccount = async (blockAmount = 0) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/block/transaction`,
      {
        customerAccountNo: accountNo,
        lienAmount: blockAmount,
        lienReason: "admin request",
      }
    );
    hideLoader();
    if (res.status) {
      Swal.fire(`Blocked!`, res.message, "success");
      getWallets();
    } else {
      Swal.fire(res.message);
    }
  };

  const handleSettleLien = async (blockAmount = 0, lien) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/lien/transaction`,
      {
        customerAccountNo: accountNo,
        lienAmount: blockAmount,
        lien,
        lienReason: "admin request",
      }
    );
    hideLoader();
    if (res.status) {
      Swal.fire(`Done`, res.message, "success");
      getWallets();
    } else {
      Swal.fire(res.message);
    }
  };
  const handleBlockAmount = async (blockAmount = 0) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/update/debit-limit/${accountNo}?limit=${blockAmount}`,
      {}
    );
    hideLoader();
    if (res.status) {
      Swal.fire(`Done`, res.message, "success");
      getWallets();
    } else {
      Swal.fire(res.message);
    }
  };
  const handleDropDown = (id, item) => {
    if (id === "setPrimaryWallet") {
      handleDefaultAccount(item.accountNo);
    }
    //Block Wallet Requires an Amount to be entered
    if (id === "blockWallet") {
      setShowAmountInputModal(true);
      setAccountNo(item.accountNo);
    }
    if (id === "lien") {
      setShowLien(true);
      setAccountNo(item?.accountNo);
    }
    if (id === "blockAmount") {
      setShowBlockAmount(true);
      setAccountNo(item?.accountNo);
    }
    if(id==='withdraw'){
      setWidrawModal(true)
      setCommissionAccount(item.accountNo)
    }
    if(id==='edit'){
      setEdit(true)
      setSelectedItem(item)
    }
    // Pause takes a property "Freeze can either be D=DEBIT FREEZE,C=CREDIT FREEZE and T= TOTAL FREEZE",
    //"FreezCode must not Null or Blank"

    if (id === "pause") {
      setShowSelectItemModal(true);
      setAccountNo(item.accountNo);
    }
    if (id === "unfreez") {
      handleUnFreezAccount(item.accountNo)
    }

    if (id === "deleteWallet") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: themeColor.primary,
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteAccount(item.accountNo);
        }
      });
    }
  };

 
  const { accountNumber, bankName } = virtualAccount || {};
  return (
    <div>
      <Row
        style={{
          marginLeft: "18px",
        }}
        gutter={16}
      >
        {/*<div className="col-md-4">
          <div>Virtual Account Number</div>
          <div>
            <CopyToClipboardComponent text={accountNumber} key={1} />
          </div>
        </div>
        <div className="col-md-4">
          <div>Bank Name:</div>
          <div
            style={{
              fontSize: "18px",
              fontFamily: "inherit",
              fontWeight: "500",
            }}
          >
            {bankName}
          </div>
        </div>*/}
        <div className="col-md-4">
          <div
            style={{
              fontSize: "18px",
              fontFamily: "inherit",
              fontWeight: "500",
            }}
          >
            <Button type="primary" onClick={()=>navigate(`/transactions_history/${userId}`)} >View Transactions</Button>
          </div>
        </div>
      </Row>
      <Row gutter={16}>
        {walletList.length
          ? walletList.map((item) => (
              <div className="col-md-4">
                <WalletCard
                  title={item.gl_code === "21105" ? "Commision Wallet" : ""}
                  icon={
                    item.gl_code === "21105"
                      ? ""
                      : item.acct_cls_flg
                      ? blockIcon
                      : item.frez_code !== null
                      ? fmdBad
                      : checkIcon
                  }
                  amount={currencyFormat(item.clr_bal_amt)}
                  blockAmount={currencyFormat(item.blockAmount ?? 0)}
                  lienAmount={item?.lien_amt}
                  accName={item.acct_name}
                  description = {item?.description}
                  accNumber={item.accountNo}
                  dropDownItem={dropDownItem(item)}
                  handleDropDown={handleDropDown}
                  data={item}
                  blocked={item.acct_cls_flg}
                />
              </div>
            ))
          : ""}
        <div
          style={{
            height: "120px",
            width: "28.33333%",
            margin: "21px",
            cursor: "pointer",
            color: `${grey[700]}`,
          }}
          className="add-div"
          onClick={handleAddWallet}
        >
          <div
            style={{
              marginBottom: "6px",
            }}
          >
            {" "}
            <PlusOutlined />
          </div>

          <div>ADD WALLET</div>
        </div>
      </Row>
      {showSelectItemModal && (
        <ListChooserModal
          isOpen={showSelectItemModal}
          setIsOpen={setShowSelectItemModal}
          options={pauseFreezeOptions}
          handleClick={handlePauseAccount}
          title="Select Freeze Type"
        />
      )}
      {showAmountInputModal && (
        <SingleInputModal
          isOpen={showAmountInputModal}
          setIsOpen={setShowAmountInputModal}
          title="Enter Block Amount"
          handleClick={handleBlockAccount}
          inputLabel="Enter Block Amount"
          inputDescription="in order to block the wallet, you must enter a block amount"
          inputType={"number"}
        />
      )}
      {showLien && (
        <SettleLien
          isOpen={showLien}
          setIsOpen={setShowLien}
          title="Lien"
          handleClick={handleSettleLien}
          inputLabel="Enter Amount"
          inputDescription=""
          inputType={"number"}
        />
      )}
      {
        withdrawModal &&(
          <WithdrawCommission
          hideModal={setWidrawModal}
          wallets= {walletList}
          showModal ={withdrawModal}
          getWallets={getWallets}
          userId={userId}
          debitWallet = {commissionAccount}
          />
        )
      }
      {showBlockAmount &&(
        <SingleInputModal
          title="Add Block Amount"
          handleClick={handleBlockAmount}
          inputLabel="Block Amount"
          isOpen={showBlockAmount}
          setIsOpen={setShowBlockAmount}
        />
      )}

      {showAddWallet&&(
        <AddWalletModal
        showModal ={showAddWallet}
        hideModal = {setShowAddWallet}
        userId={userId}
        record={record}
        />
      )}

      {edit &&(
        <UpdateWalletModal
          showModal={edit}
          hideModal={setEdit}
          selectedItem={selectedItem}
          getWallets={getWallets}
          />
      )

      }
    </div>
  );
};

export default ManageWallet;
