import React, { useState } from "react";
import useStyles from '../Otp/styles';
import { Button } from "antd";
import { InputNumber, DatePicker } from 'antd';
import moment from 'moment';
import MassUpload from "./fileUpload";



function MassTransactions() {
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const styles = useStyles();
    const [currentPage, setCurrentPage] = useState('default');
    const [showMass, setShowMass] = useState('')

    return (
        <div>
            <div>
                <h5 className="d-flex justify-content-center">Perform mass transaction to users</h5>
            </div>
            <div className="d-flex justify-content-center">
                {currentPage === 'default' && (

                    <div className={styles.btnCont}>
                        <div
                            className={styles.bcItem}

                        >
                            <h4>Mass Debit</h4>
                            <p>Debit Mass Users</p>
                            <div>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ background: '#FF4B01', fontSize: '10px' }}
                                    onClick={() => setShowMass('massDebit')}
                                >
                                    Proceed
                                </Button>
                            </div>

                        </div>
                        <div
                            className={styles.bcItem}

                        >
                            <h4>Mass Credit</h4>
                            <p>Fund Mass Users</p>

                            <div>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ background: '#FF4B01', fontSize: '10px' }}
                                    onClick={() => setShowMass('massCredit')}
                                >

                                    Proceed
                                </Button>
                            </div>

                        </div>
                        {/*<div
                            className={styles.bcItem}

                        >
                            <h4>Mass Debit Non Waya</h4>
                            <p>Debit Mass Users</p>
                            <div>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ background: '#FF4B01', fontSize: '10px' }}
                                    onClick={() => setCurrentPage('sendOtp4PinViaEmail')}
                                >

                                    Proceed
                                </Button>
                            </div>

                        </div>
                        <div
                            className={styles.bcItem}

                        >
                            <h4>Mass Credit</h4>
                            <p>Fund Mass Users</p>
                            <div>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ background: '#FF4B01', fontSize: '10px' }}
                                    onClick={() => setCurrentPage('sendOtp4PinViaPhone')}
                                >

                                    Proceed
                                </Button>
                            </div>

                        </div>*/}
                    </div>

                )}
                {currentPage === 'massDebit' && (
                    <div className={styles.soWrapper}>
                        <div className={styles.soLabel}>Select Funding Type</div>
                        <select className='form-control'
                        >
                            {/* {users.map((option) => (
                        <option value={option.email}>{option.firstName} {option.lastName}</option>
                    ))} */}
                            <option selected>Select</option>
                        </select>
                        <div className={styles.soLabel}>Amount to be debited from each user</div>
                        <div>
                            <InputNumber className="form-control"
                                //min={1} max={10} defaultValue={3} onChange={onChange} 
                                type='number' />
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            {/* <button type="button" className={styles.soButton} onClick={handleSendOtpPasswordByEmail}>
                        Send OTP
                    </button> */}
                        </div>
                    </div>
                )}

                {currentPage === 'sendOtp4PassViaPhone' && (
                    <div className={styles.soWrapper}>
                        <div>Select funding period</div>
                        <select className='form-control'
                        >
                            {/* {users.map((option) => (
                        <option value={option.phoneNumber}>{option.firstName} {option.lastName}</option>
                    ))} */}
                            <option selected>Select</option>
                        </select>
                        <div className="">
                            <div>Start Date</div>
                            <div>
                                <DatePicker
                                    defaultValue={moment('01/01/2015', dateFormatList[0])}
                                    format={dateFormatList}
                                    className='form-control' />
                            </div>
                        </div>
                        <div>Stop Date</div>
                        <div>
                            <DatePicker
                                defaultValue={moment('01/01/2015', dateFormatList[0])}
                                format={dateFormatList}
                                className='form-control' />
                        </div>
                        <div><label>Amount to be debited from each user</label></div>
                        <div>
                            <InputNumber className="form-control"
                                //min={1} max={10} defaultValue={3} onChange={onChange} 
                                type='number' />
                        </div>
                        <div className="w-100 d-flex justify-content-center">
                            {/* <button type="button" className={styles.soButton} onClick={handleSendOtpPasswordByPhone}>
                        Send OTP
                    </button> */}
                        </div>
                    </div>
                )}
                {
                    showMass ? <MassUpload showModal={showMass} hideModal={setShowMass}/>: ''
                }
                {currentPage === 'sendOtp4PinViaEmail' && (
                    <div className={styles.soWrapper}>
                        <div className={styles.soLabel}>Select / Enter Receiver</div>
                        <select className={styles.soInput}
                        >
                            {/* {users.map((option) => (
                        <option value={option.email}>{option.name}</option>
                    ))} */}
                            <option selected>Select</option>
                        </select>
                        <div className="w-100 d-flex justify-content-center">
                            {/* <button type="button" className={styles.soButton} onClick={handleSendOtpByEmail}>
                        Send OTP
                    </button> */}
                        </div>
                    </div>
                )}
                {currentPage === 'sendOtp4PinViaPhone' && (
                    <div className={styles.soWrapper}>
                        <div className={styles.soLabel}>Select / Enter Receiver</div>
                        <select className={styles.soInput}
                        >
                            {/* {users.map((option) => (
                        <option value={option.phone}>{option.name}</option>
                    ))} */}
                            <option selected>Select</option>
                        </select>
                        <div className="w-100 d-flex justify-content-center">
                            {/* <button type="button" className={styles.soButton} onClick={handleSendOtpByPhone}>
                        Send OTP
                    </button> */}
                        </div>
                    </div>
                )}


            </div>
        </div>
    );

}
export default MassTransactions