import { ArrowBack } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { GetSvgIcon } from '../../../components/svg';
import { customBaseUrl, httpPost, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const PricingCards = ({ item, data, setData, handleGetPrices,setCurrentPage }) => {
  const [showCard, setShowCard] = useState(false);
  const [ generalData, setGeneralData] = useState()
  const [ customData, setCustomData] = useState({})
  const [priceType, setPriceType] = useState("PERCENTAGE")


  console.log('data', data);

  // {
  //   "capAmount": 0,
  //   "fixedAmount": 0,
  //   "percentDiscount": 0,
  //   "percentPrice": 0,
  //   "userType": "string"
  // }

  const handleEdit = async (product, userId) => {
    showLoader();
    
      const res =  await httpPost(
          `${customBaseUrl.walletUrl}/api/v1/pricing/apply-general-price?capAmount=${generalData.capAmount}&generalPrice=${generalData.amount}&priceType=${priceType}&productType=${product}`);
                 if (res.status) {
            Swal.fire('Done', res.message, 'success');
          } else {
            Swal.fire('Wrong', res.message, 'error');
          }
      if(res.status===true){
        hideLoader();
        Swal.fire('Done', res.message)
      }else{
        hideLoader()
        Swal.fire('Error', res.message)
      }
  };

  useEffect(()=>{
    setGeneralData({generalData, amount:data.generalAmount, capAmount: data.capPrice, discountAmount:data.discount})
  },[])

  return (
    <div className='pricingFormCont'>
      {//<ArrowBack style={{marginBottom:20}} onClick ={()=>setCurrentPage('default')}/>
      }
      <div
        className='pfHead d-flex justify-content-between align-items-center'
        onClick={() => setShowCard(!showCard)}
        style={{
          background: showCard ? '#ff4b01' : '#898989',
        }}
      >
        <div>{data?.name  }</div>
        <div className='fs-2'>{showCard ? '-' : '+'}</div>
      </div>
      {showCard ? (
        <div className='pricingForm'>
          <div className='pfTitle'>
          </div>
          <div className='pfContent'>
            <div className='pfcTitle'>Select user type</div>
            <div className='d-flex w-100 justify-content-center'>
              <div className='checkCont'>
                <input
                  type='checkbox'
                  name=''
                  id=''
                  defaultChecked={item?.corporate || false}
                  checked={data?.userType}
                  onChange={() =>
                    setData({
                      ...data,
                      userType: 'CORPORATE',
                    })
                  }
                />
                <div>corporate</div>
              </div>
              <div className='checkCont'>
                <input
                  type='checkbox'
                  name=''
                  id=''
                  defaultChecked={item?.personal || false}
                  checked={item?.userType}
                  onChange={() =>
                    setData({
                      ...data,
                      userType: 'PERSONAL',
                    })
                  }
                />
                <div>personal</div>
              </div>
              <div className='checkCont'>
                <input
                  type='checkbox'
                  name=''
                  id=''
                  defaultChecked={item?.aggregator || false}
                  checked={item?.userType}
                  onChange={() =>
                    setData({
                      ...data,
                      userType: 'AGGREGATOR',
                    })
                  }
                />
                <div>aggregator</div>
              </div>
            </div>
            <div className='checkCont'>
              <input
                type='checkbox'
                name=''
                id=''
                defaultChecked={item?.userType === 'ALL' || false}
                checked={item?.userType}
                onChange={() =>
                  setData({
                    ...data,
                    userType: 'ALL',
                  })
                }
              />
              <div>Select all corporate users</div>
            </div>
            {/*item?.userType !== 'all' && (
              <div className='d-flex flex-column text-start'>
                <div
                  style={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#000000',
                  }}
                >
                  Choose a set of Merchants
                </div>
                <div className='inputCont'>
                  <input type='text' />
                  <GetSvgIcon type='search-user' />
                </div>
              </div>
                )*/}
            <div className='hr-dotted' />
            <div className='d-flex flex-column text-start'>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000',
                }}
              >

                Pricing Type
              </div>
              <div className='inputCont2 pl-2'>
                <select
                  onChange={(e) => {
                    setPriceType(e.target.value)
                  }}
                >
                  <option value='PERCENTAGE'>Percentage</option>
                  <option value='FIXED'>Fixed</option>
                </select>
              </div>
            </div>
            <div className='d-flex flex-column text-start'>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000',
                }}
              >
                Rate
              </div>
              <div className='inputCont2 pl-2'>
                <input
                  type='text'
                  value={generalData.amount}
                  onChange={(e) =>
                    setGeneralData({ ...generalData, amount: e.target.value })
                  }
                />
                <div
                  style={{
                    background: '#4F4F4F',
                    border: '1px solid #E0E0E0',
                    boxSizing: 'border-box',
                    borderRadius: '5px',
                    color: '#fff',
                    width: '57.4px',
                    height: '57.4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  %
                </div>
              </div>
            </div>
            <div className='d-flex flex-column text-start'>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000',
                }}
              >
                Discount
              </div>
              <div className='inputCont2 pl-2'>
                <input
                  type='text'
                  defaultValue={item?.percentDiscount}
                  value={generalData.discountAmount}
                  onChange={(e) =>
                    setGeneralData({ ...generalData, discountAmount: e.target.value })
                  }
                />
                <div
                  style={{
                    background: '#4F4F4F',
                    border: '1px solid #E0E0E0',
                    boxSizing: 'border-box',
                    borderRadius: '5px',
                    color: '#fff',
                    width: '57.4px',
                    height: '57.4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  %
                </div>
              </div>
            </div>
            <div className='d-flex flex-column text-start'>
              <div
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000',
                }}
              >
                Processing Fees Capped at
              </div>
              <div className='inputCont2'>
                <div
                  style={{
                    background: '#E0E0E0',
                    border: '1px solid #E0E0E0',
                    boxSizing: 'border-box',
                    borderRadius: '5px',
                    color: '#000',
                    width: '92.26px',
                    height: '54px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  NGN
                </div>
                <input
                  type='text'
                  defaultValue={item?.capAmount}
                  value={generalData.capAmount}
                  onChange={(e) =>
                    setGeneralData({ ...generalData, capAmount: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <div className='pfButton' onClick={() => handleEdit(data?.code, data?.userId)}>
              Save Changes
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PricingCards;
