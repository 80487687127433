import { ArrowLeftOutlined, PlusCircleOutlined, SettingFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import _ from "lodash";
import {
  List,
  Typography,
  Divider,
  Card,
  Row,
  Col,
  Menu,
  Space,
  Button,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { hideLoader, showLoader } from "../../utils/loader";
import { ListItemText } from "@mui/material";
import useStyles from "./style";
import { getArraryString, transformName } from "../../utils/helper";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import CreateCustomRole from "./modals/CreateCustomRole";
import Swal from "sweetalert2";
import { ProfileContext } from "../../store/context/ProfileContext";
import UpdateRole from "./modals/updateRole";
import NewUnit from "./modals/NewUnit";
import UpdateMenu from "./modals/updateMenu";
import { Edit } from "@mui/icons-material";
import ManageMenu from "./manageMenu";
const ManageAdminRoles = () => {
  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);

  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [roleUsers, setRoleUsers] = useState([]);
  const [rolePrivileges, setRolePrivileges] = useState([]);
  const [roleCannotList, setRoleCannotList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [totalPrivillages, setTotalPrivillages] = useState(0)
  const [showUpdateRole, setShowUpdateRole] = useState(false)
  const [showUnitModal, setShowUnitModal] = useState(false)
  const [showMenu, setShowMenu] = useState(false);
  const [showUpdateMenu, setShowUpdateMenu] =useState(false)
  const [menus, setMenus] = useState([])
  const [permMenu, setPermMenu] = useState([]);
  const [roleData, setRoleData] = useState({
    privileges: [],
    name: "",
    description: "",
    userId: profile.userId
  }); //privileges: [], name: ""
  const [menuData, setMenuData] = useState([])
  const [menuId, setMenuId] = useState(null);
  const [roleUpdateData, setRoleUpdateData] = useState({
    privileges: [],
    roleId: selectedRole.id
  }); 
  const [pagination, setPagination] = useState({ current: 0, pageSize: 20 });

  // integrate the endpoint to create a custom role
  const handleSubmitCreateRole = async () => {
    if (roleData.name === "") {
      Swal.fire({
        title: "Oops...",
        text: "Please enter a role name",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    if (roleData.privileges.length === 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please select at least one privilege",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    if (roleData.description === "") {
      Swal.fire({
        title: "Oops...",
        text: "Please enter a role description",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/manage-user/create-custom-role`,
      roleData
    );
    if (response.status == true) {
      hideLoader()
      Swal.fire({
        title: "Success!",
        text: "Role created successfully",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        hideLoader()
        setShowUpdateRole(false)
      });
    } else {
      Swal.fire({
        title: "Oops...",
        text: response.message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      hideLoader()

    }
    hideLoader()

  };


  const handleUpdateUnit = async () => {
    console.log('menu', menuData)
    if (!menuId) {
      Swal.fire({
        title: "Oops...",
        text: "Please select menu",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    if (menuData.length === 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please select at least one privilege",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
    /*if (roleData.description === "") {
      Swal.fire({
        title: "Oops...",
        text: "Please enter a role description",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }*/
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.roleUrl}/api/v1/menu/${menuId}/permission/${menuData?.id}/create`
    );
    if (response.status == true) {
      hideLoader()
      Swal.fire({
        title: "Success!",
        text: response?.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        hideLoader()
        setShowUpdateMenu(false)
      });
    } else {
      Swal.fire({
        title: "Oops...",
        text: response.message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      hideLoader()

    }
    hideLoader()

  };

  const updateRole = async () => {
    if (roleUpdateData.privileges.length === 0) {
      Swal.fire({
        title: "Oops...",
        text: "Please select at least one privilege",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      return;
    }
   
    showLoader();
    const response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/roles-privileges/assign-role-privilege?roleId=${selectedRole.id}`,
      roleUpdateData.privileges
    );
    if (response.statusCode == 'OK') {
      Swal.fire({
        title: "Success!",
        text: response.message,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        setShowUpdateRole(false)
      });
      getRoles()
    } else {
      hideLoader()
      Swal.fire({
        title: "Oops...",
        text: response.message,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    }
    hideLoader()
  };

  
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    let pages = Math.floor(totalPrivillages/pagination.pageSize)
    if(newPage==='next' && pagination.current<pages){
      setPagination({ ...pagination, current: pagination.current+1 });
      await getPreviledges(pagination.current+1, pagination.pageSize);


    }else if(newPage==='back' && pagination.current>0) {
      setPagination({ ...pagination, current: pagination.current-1 });
      await getPreviledges(pagination.current-1, pagination.pageSize);

    }

  };

  const getPreviledges = async (page, size) => {
    showLoader();
    const pages =  page? page: page===0?0: pagination.current;
    const limit = size ? size : pagination.pageSize;
    let response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/privileges/all-privileges?page=${pages}&size=${limit}`
    );
    let body = response.body.data;
    setPrivileges(body.privileges);
    setTotalPrivillages(body?.totalItems)
      hideLoader();
  };
  const getMenus = async () => {
    // showLoader();
    
    let response = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/menu/fetchAll`
    );
    if(response?.status){
      setMenus(response?.data)
    }else{
      console.log(response)
    }    
      hideLoader();
  };

  const getMenusPerm = async () => {
    //showLoader();
    
    let response = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/menu/fetchAllActivePermissionMenus`
    );
    if(response?.status){
      setPermMenu(response?.data)
    }else{
      console.log(response)
    }    
      hideLoader();
  };

  useEffect(() => {
    getPreviledges(0,20);
    getMenus();
    getMenusPerm();
  }, []);

  const getRoleCannot = (role) => {
    let allPrivileges = roles.map((x) => x.privileges);
    let allPrivilegesFlat = _.flatten(allPrivileges);
    allPrivileges = mergeDistinctArrayObjects(allPrivilegesFlat);
    setRoleCannotList(
      allPrivileges.filter(
        (x) =>
          !role.privileges.find(
            (item) => item.id === x.id && item.name === x.name
          )
      )
    );
  };
  const handleSetSelectedRolePrivileges = (role) => {
    setSelectedRole(role);
    setRolePrivileges(role.privileges);
    getRoleCannot(role);
  };

  useEffect(() => {
    console.log(roleCannotList);
  }, [roleCannotList]);

  const mergeDistinctArrayObjects = (array) => {
    var merged = _.merge(_.keyBy(array, "id"), _.keyBy(array, "id"));
    return _.values(merged);
  };

  const getRoles = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/roles/roles-with-privileges`
    );
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setRoles(response);
    handleSetSelectedRolePrivileges(response[0]);
  };

  const getUsersByRole = async (roleId) => {
    showLoader();
    const response = await httpGet(`/api/v1/admin/users/role/${roleId}`);
    hideLoader();
    if (!response || response.error !== undefined) {
      return;
    }
    setRoleUsers(response);
  };
  useEffect(() => {
    getRoles();
    return () => {
      setRoles([]);
    };
  }, []);

  return (
    <Layout>
      {!showMenu ? (<div >
        <div style={{display:'flex', flexDirection: 'row'}}>
        <div
          className=""
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/users/admin")}
        >
          <span className="me-1">
            <ArrowLeftOutlined />
          </span>
          back
        </div>
        <div style={{float:'right', marginRight: 0, marginLeft: 'auto'}}>
        <button className="btn btn-danger" style={{marginRight: 5}} onClick={()=>setShowMenu(true)}>
          <SettingFilled className={classes.buttonIcon} />

            Manage menu
          </button>
          {/*<button className="btn btn-danger" style={{marginRight: 5}} onClick={()=>setShowUnitModal(true)}>
          <PlusCircleOutlined className={classes.buttonIcon} />

            Add new unit
          </button>
          <button className="btn btn-primary" onClick={() => setShowUpdateMenu(true)}>
          <PlusCircleOutlined className={classes.buttonIcon} />
            Add Permission to Menu
          </button>
      */}
          </div>
        </div>
        <br />
        <div style={{ marginTop: "10px" }}>
          <Row>
            <Col flex={"200px"}>
              <Divider orientation="left">Default Roles</Divider>
              <Menu
                theme="light"
                mode="vertical"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
              >
                {roles.filter(role=> role.default ===true).map((role) => {
                  return (
                    <Menu.Item
                      key={role.id}
                      onClick={() => handleSetSelectedRolePrivileges(role)}
                    >
                      {transformName(getArraryString([role.name]))}
                    </Menu.Item>
                  );
                })}
              </Menu>
              <Divider orientation="left">Custom Roles</Divider>
              <Menu
                theme="light"
                mode="vertical"
                style={{ border: "0" }}
                
              >
                {
                  roles.filter(role=> role.default !==true).map(item =>{
                    return (
                      <Menu.Item
                      key={item.id}
                      onClick={() => handleSetSelectedRolePrivileges(item)}
                      > {transformName(getArraryString([item.description]))}</Menu.Item>
                    );
                  })
                }
                <Menu.Item>
                <Button
                className={classes.addCustomRoleButton}
                size="middle"
                style={{ color: "#828282", width: "100%" }}
                onClick={() => setShowModal(true)}
                icon={<PlusCircleOutlined className={classes.buttonIcon} />}
                ghost
              >
                Add Custom Role
              </Button>
                </Menu.Item>
               
              </Menu>
              {/* <Divider dashed={true} /> */}
            
            </Col>
            <Col flex={"70%"}>
              <Divider orientation="left">
                <ListItemText
                  primaryTypographyProps={{ align: "left", variant: "h5" }}
                  primary={transformName(
                    getArraryString([selectedRole.description])
                  )}
                  secondary="This role grants users the privileges listed below."
                ></ListItemText>
              </Divider>

              <List
                style={{ marginTop: "10px" }}
                header={
                  <div style={{ color: "#828282" }}>
                    {/* <b>10 Members with this role</b>: Adeyanju Akorede, Philip
                    Templar, Adeyanju Gabriel, Clever Eziogor, Samuel Aniefiok,
                    John Mary, Adeyanju Akorede, Philip Templar, Adeyanju
                    Gabriel, Clever Eziogor, John Mary */}
                  </div>
                }
                bordered
                dataSource={[{}]}
                renderItem={(itemGr) => (
                  <Row>
                    <Col span={10}>
                      <List
                        bordered
                        style={{ marginRight: "10px" }}
                        dataSource={rolePrivileges}
                        header={
                          <div style={{ color: "#52936F", fontWeight: "500" }}>
                            {transformName(
                              getArraryString([selectedRole.description])
                            )}{" "}
                            Can
                          </div>
                        }
                        renderItem={(item) => (
                          <>
                            <List.Item> {transformName(getArraryString([item?.description]))}</List.Item>
                          </>
                        )}
                      ></List>
                      <PlusCircleOutlined onClick={()=>setShowUpdateRole(true)}/>

                    </Col>
                    <Col span={10}>
                      <List
                        bordered
                        style={{ marginLeft: "10px" }}
                        dataSource={roleCannotList}
                        header={
                          <div style={{ color: "#C67777", fontWeight: "500" }}>
                            {transformName(
                              getArraryString([selectedRole.description])
                            )}{" "}
                            Cannot
                          </div>
                        }
                        renderItem={(item) => (
                          <List.Item> {transformName(getArraryString([item?.description]))}</List.Item>
                        )}
                      >
                      </List>
                    </Col>
                  </Row>
                )}
              />
            </Col>
          </Row>
        </div>
      </div>) :(
        <ManageMenu setShowMenu={setShowMenu} permMenu= {permMenu} defMenu={menus} getMenus={getMenus} getMenusPerm={getMenusPerm}/>
      )}
      {showModal ? (
        <CreateCustomRole
          showModal={showModal}
          setShowModal={setShowModal}
          privileges={privileges}
          roleData={roleData}
          setRoleData={setRoleData}
          handleSubmit={handleSubmitCreateRole}
          handleLoadMore={handleChangePage} totalPrivillages={totalPrivillages} pagination={pagination}
        />
      ) : null}
      {setShowUpdateRole ? (
        <UpdateRole
          showModal={showUpdateRole}
          setShowModal={setShowUpdateRole}
          privileges={roleCannotList}
          roleData={roleUpdateData}
          setRoleData={setRoleUpdateData}
          selectedRole={selectedRole}
          handleSubmit={updateRole}
          handleLoadMore={handleChangePage} totalPrivillages={totalPrivillages} pagination={pagination}
        />
      ) : null}
      {
        showUnitModal ? (
          <NewUnit
          setShowUnitModal={setShowUnitModal}
          showUnitModal={showUnitModal}
          />
        ) : null
      }
      {showUpdateMenu ? (
        <UpdateMenu
          showModal={showUpdateMenu}
          setShowModal={setShowUpdateMenu}
          privileges={privileges}
          roleData={roleData}
          setRoleData={setRoleData}
          handleSubmit={handleUpdateUnit}
          handleLoadMore={handleChangePage} totalPrivillages={totalPrivillages} pagination={pagination}
          menus={menus}
          menuData={menuData}
          setMenuData = {setMenuData}
          setMenuId = {setMenuId}
        />
      ) : null}
    </Layout>
  );
};

export default ManageAdminRoles;
