import styled from "styled-components";
import { createUseStyles } from 'react-jss';

const Wrapper = styled.div`
  .banner-div {
    background: #fff7f4;
    border: 1px solid #ff4b01;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 18px 33px 14px 18px;
    margin-bottom: 46px;

    .img-div {
      margin-right: 10.17px;
    }
    .banner-texts {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      margin-top: 8px;
      .bt-top {
        display: flex;
        justify-content: space-between;
        .bt-item {
          display: flex;
          flex-direction: column;
          .top {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
          }
          .bottom {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
      .bt-bottom {
        background: #141414;
        border-radius: 2px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
        padding: 5px 16px;
        align-self: flex-end;
        margin-top: 30px;
        position:relative;
        .bt-dropDown{
          position: absolute;
          display:flex;
          flex-direction:column;
          background: #FFFFFF;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          padding: 15px 20px 0px 23px;
          width:max-content;
            justify-content:flex-start;
            top:26px;
            left:0;

          .btd-item{
            display: flex;
            align-items:center;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #000000;
            margin-bottom:20px;
            img{
              margin-right:5px;
            }
          }
        }
      }
    }
  }
  .preCard-div {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1px;
      color: #000000;
      margin-right: 8px;
    }
    .switch-box {
      background: #fffaf9;
      border: 1px solid #ffe3d8;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 6px 4.21px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      color: #000000;
      .ant-switch {
        .ant-switch-small {
          .ant-switch-checked {
            background: #ff4b01;
          }
        }
      }
      .text {
        margin-right: 10.79px;
      }
    }
    .grow {
      flex-grow: 1;
    }
    .pd-btn {
      background: #ff4b01;
      border-radius: 3px;
      padding: 5px 9px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .info-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .id-item {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border-radius: 4px;
      padding: 18px 10px;
      width: 32%;
      flex-direction: column;

      .id-left {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #263238;
      }
      .id-right {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
      }
    }
  }
  .woCard-div {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    .arrow-left{
      position:absolute;
      transform: rotate(180deg);
      left: 0;
      top: 35%;
    }
    .arrow-right{ 
      position:absolute;
      right: 0;
      top: 35%;
    }
    .wocard-item {
      margin-right:10px;
  
    }
  }
  .table-div {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1px;

      color: #000000;
      margin-bottom: 20px;
    }
    .search-row {
      display: flex;
      height: 46px;
      align-items: center;
      margin-bottom: 12px;
      .select-type {
        margin-right: 10px;
        border-radius: 6px !important;
        height: 100%;
        width: 171px;
        padding: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #ff4b01;
        background: #ffeae1;
        border: 1px solid #ff4b01 !important;
        box-sizing: border-box;
      }
      .search-input {
        height: 100%;
        width: 279px;
        border-radius: 6px;
        background: #f5f5f5 !important;
        border: 1px solid #ff4b01;
        box-sizing: border-box;
        color: #978c8a;
      }
      .search-icon {
        background: #f5f5f5;
        color: #ff4b01;
      }
      .grow {
        flex-grow: 1;
      }
      .update-button {
        height: 100%;
        border-radius: 3px !important;
        text-align: center;
        background: #ff4b01;
        color: #fff;
        border-radius: 3px;
      }
      .update-button-1 {
        height: 100%;
        border-radius: 3px !important;
        display: flex;
        align-items: center;
        background: #ff4b01;
        color: #fff;
        background: #27ae60;
        border-radius: 3px;
        margin-right: 5px;

        img {
          margin-right: 7.63px;
        }
      }
      .update-button-SMU {
        height: 100%;
        border-radius: 3px !important;
        display: flex;
        align-items: center;
        background: #ff4b01;
        color: #fff;
        background: #000;
        border-radius: 3px;
        margin-right: 5px;

        img {
          margin-right: 7.63px;
        }
      }
    }
    .tableFilter {
      display: flex;
      margin-bottom: 20px;
      .tfItem {
          background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 3px !important;
    border: 0 !important;
    margin-right: 10px;
    color: #9E9E9E;
    font-weight: 500,
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    width: 203px;
      }
    }
  }
`;
export const useMatStyles = createUseStyles(() => ({
  searchRow: {
    display: 'flex',
    height: '46px',
    alignItems: 'center',
    marginBottom: '12px',
    width: '20%',
    marginLeft: '10px',
  },
  modal:{
    marginTop: 50, 
    minWidth: "600px" 
  },
  modalBody: {
    marginTop: '60px',
    marginLeft: '50px',
    marginRight: '50px'
  },
  selectType: {
    marginRight: '-70px',
    marginTop: '6px',
    borderRadius: '6px !important',
    // border: '0 !important',
    height: '100%',
    width: '171px',
    padding: '10px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FF4B01',
    background: '#FFEAE1',
    border: '1px solid #FF4B01 !important',
    boxSizing: 'border-box',
  },
  grow: {
    flexGrow: 1,
  },
  searchInput: {
    height: '100%',
    width: '279px',
    borderRadius: '6px',
    background: '#F5F5F5 !important',
    border: '1px solid #FF4B01',
    boxSizing: 'border-box',
    color: '#978C8A',
  },

  searchIcon: {
    background: '#F5F5F5',
    color: '#FF4B01',
  },
  buttonIcon:{
    marginBottom: '10px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  manageButtonIcon: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '3px',
    color: '#828282',
  },
  manageAdminButton:{
    height: '80%',
    borderRadius: '6px !important',
    textAlign: 'center',
    background: '#F2F2F2',
    color: '#333333',
    border: '1px solid #E5E5E5',
    marginRight: '18px',
    boxShadow: '0px 0px 0px 0px #E5E5E5',
    fontSize: '9px',
    fontWeight: '700',
  
    "&:hover":{
      background: '#F2F2F2',
      color: '#333333',
    },
    "&:focus":{
      background: '#F2F2F2',
      color: '#333333',
    }
  },

  addCustomRoleButton:{
    borderRadius: '5px !important',
    textAlign: 'center',
    background: '#FF4B01',
    color: '#fff',
    "&:hover":{
      background: '#FF4B01',
      color: '#fff',
    },
    "&:focus":{
      background: '#FF4B01',
      color: '#fff',
    }
  },
  updateButton: {
    height: '80%',
    borderRadius: '5px !important',
    textAlign: 'center',
    background: '#FF4B01',
    color: '#fff',
    "&:hover":{
      background: '#FF4B01',
      color: '#fff',
    },
    "&:focus":{
      background: '#FF4B01',
      color: '#fff',
    }
  },
  dashboardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    alignItems: 'center',
  },

  tierList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  searchInput: {
    height: '100%',
    width: '100%',
    marginTop: '10px',
    borderRadius: '6px',
    background: '#f5f5f5 !important',
    border: '1px solid #ff4b01',
    boxSizing: 'border-box',
    outline: 'none',
    color: '#978c8a',
    ':focus':{
      outline: 'none',
      outlineWidth: '0px',
    },
    ':hover':{
      outline: 'none',
      outlineWidth: '0px',
    }
  },
  searchIcon :{
    background: '#f5f5f5',
    color: '#ff4b01'
  },
  grow: {
    flexGrow: 1
  },
  dashItem: {
    background: '#FBFBFB',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    width: '33%',
    display: 'flex',
    height: '140px',
    padding: '20px',
    marginRight: '5px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  diTop: {
    marginBottom: '36px',
    fontWeight: '600',
    fontSize: '10px',
    fontFamily: 'Inter',
    lineHeight: '19px',
    color: '#1C1C1C',
  },
  dibot: {
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '33px',
    color: '#1C1C1C',
  },
  tierItem: {
    background: '#FFFFFF',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    width: '24%',
    alignItems: 'center',
  },
  lti: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: '#263238',
  },
  rti: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
  },
  tableFilter: {
    display: 'flex',
    marginBottom: '20px',
  },
  tfItem: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '3px !important',
    border: '0 !important',
    marginRight: '10px',
    color: '#9E9E9E',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    padding: '8px',
    width: '203px',
  },
  modalTitle: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    marginBottom: '22px',
  },
  inputWrap: {},
  inputCont: {
    dispaly: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  inputCont2: {
    dispaly: 'flex',
    width: '48%',
    flexDirection: 'column',
  },
  inputLabel: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#000000',
    marginBottom: '4.5px',
  },
  inputInput: {
    background: '#FFFFFF',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5px !important',
    width: '100%',
    padding: '8px !important',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '12px',
  },
  inputContFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  shortTextStyle: {
    wordWrap: 'break-word',
    maxWidth: 150,
    fontWeight: 'normal',
  },
  longTextStyle: {
    wordWrap: 'break-word',
    maxWidth: 200,
    fontWeight: 'normal',
  },
  modal:{
    borderRadius: '50px !important',
    fontFamily: 'Inter',
    
  },
  tableHeader: {
    background: "#ff6700",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    // lineHeight: "15px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#ffffff",
  },
  table: {
    background: "#FFFFFF",
    borderRadius: "10px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#666666",
  }
}));
export default Wrapper;
