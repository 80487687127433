import React from 'react';
import { Table } from 'antd';

const AntTable = ({ loading, users, pagination, handleGetNext, columns , ref}) => {
  console.log('>?<>???', users);
  const handleTableChange = (pagination, filters, sorter) => {
    handleGetNext({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  return (
    <Table
      ref={ref}
      columns={columns}
      rowKey={Math.random()}
      dataSource={users}
      pagination={{...pagination, showSizeChanger: true, pageSizeOptions: ['10', '20', '30','100', '500','1000']}}
      loading={loading}
      scroll={{ x: 1500 }}
      onChange={handleTableChange}
    />
  );
};

export default AntTable;
