import wayabankLogo from "../../assets/images/logo.svg";
import React from "react";
import { CommingSoonWrapper } from "./style";
import Layout from "../../components/Layout";
const CommingSoon = () => {
  return (
    <Layout>
      <ComingSoonComponent percentage={35}/>
    </Layout>
  );
};
export default CommingSoon;

export const ComingSoonComponent = ({percentage}) => {
  return (
    <CommingSoonWrapper>
      <div className="body">
        <div id="main">
          <img src={wayabankLogo} alt="Logo Image" />
          <h1>WAYABANK</h1>
          <h2>Comming Soon</h2>
          <div id="progress">
            <div id="fill">
              <div id="barpercent">
                <h4>{percentage}%</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommingSoonWrapper>
  );
};
