import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { BugOutlined, CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import { searchUsers } from "../../../store/httpCalls";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

const SingleUserBonus = ({ showModal, hideModal, title, selectedData, wallets ,className}) => {
    let d = new Date();
    let ref = d.getTime();
  const initialState = {
    "tranType": "TRANSFER",
    "userId": selectedData?.userId,
    tranCrncy: "NGN",
    amount:"",
    customerCreditAccount:"",
    officeDebitAccount: "",
    paymentReference: `WAYA${ref}`,
    tranNarration: "",
  };
  const [data, setData] = useState(initialState);
  const [transactions, setTransactions] = useState([{ ...initialState }]);
  const [selectedWallet, setSelectedWallet] = useState({});
  const [options, setOptions] = useState([]);
  const [walletList, setWalletList] = useState([]);
  const [officialWalletList, setOfficialWalletList] = useState([])
  const [query, setQuery] = useState('');
  
  const [page, setPage] = useState(1);
  const clearState = () => {
    setData({ ...initialState });
    setWalletList([]);
  };
  useEffect(() => {
    getOfficialWallets();
    console.log(selectedWallet);
  }, [selectedWallet]);

    
 

  const handleTransaction = async () => {
    const isEmpty = Object.keys(data).map(x =>{console.log(x,data[x]); if(data[x] ==''){ return true}else{return false}} );
  //  if (isEmpty.includes(true)) return  Swal.fire("Warning", "Please fill all the fields", "warning");
    
    showLoader();
  

        //let newTran = delete data.walletOp;
      //  newTran.forEach(function(v){ delete v.walletOp });
    let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
        {
          payload: JSON.stringify(data),
          "requestId": `${d.getTime()}`,
          "requestType": "singleRefferalBonus",
          customerName: selectedData?.referralUser
        } )
      
        
    if (response.status === true) {
      hideLoader();
      Swal.fire('Done', response.message, 'success');
      hideModal(false)
    } else {
      hideLoader();
      Swal.fire('Error', response.message, 'error');
    }
  };

  useEffect(()=>{
    getWallets()
  },[data?.userId])


 
  const getWallets = async (e) => {
   //  return alert(fullName);
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/account/count/${selectedData?.userId}`
      );
     // alert(JSON.stringify(res));
      if (res?.status) {
        setWalletList(res.data);
       //setData({...data, walletOp: res.data});
      }
    };

    const getOfficialWallets = async (e) => {
        //  return alert(fullName);
           const res = await httpGet(
             `${customBaseUrl.walletUrl}/api/v1/wallet/waya/official/account`
           );
          // alert(JSON.stringify(res));

           if (res?.status) {
             setOfficialWalletList(res.data);
           }
         };
  


  return (
    <Modal
    className={className}
    centered
    visible={showModal}
    onOk={() => hideModal(false)}
    onCancel={() => hideModal(false)}
    width={500}
    footer={0}
    closable={false}
    title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >


        
      <Wrapper class='row g-3 needs-validation border mb-2 p-3'>
        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom01' class='form-label'>
            Receiver's Id
          </label>
          <input
          
            type='text'
            class='form-control'
            id='validationCustom01'
            required
            value={selectedData?.userId}
          />
                    {//}  <Button onClick={()=>{setQuery('');setData({...data, fullName:''})}}>x</Button>
             }
        </div>
       <div class='col-md-12'>
            <div className="label"> Amount</div>
            <div className="input-content">
              <input
                class='form-control'
                value={data.amount}
                onChange={(e) =>{
                  setData({ ...data, amount: e.target.value })
                }
              }
              />
            </div>
          </div>
          
          <div class='col-md-12'>
          <label for='validationCustom06' class='form-label'>
            Select Official wallet to perform this transaction
          </label>

            <select

            onChange={(e) =>{ setData({ ...data, officeDebitAccount: JSON.parse(e.target.value).accountNo })}}
             className='border border-dark rounded form-select'
             value={data.officeDebitAccount?JSON.stringify(officialWalletList?.filter((item) =>item.accountNo === data.officeDebitAccount)[0]):""}
            >
              <option value='' disabled>select wallet</option>
              {officialWalletList.map((option) => (
              <option key={option.accountNo} value={JSON.stringify(option)}>
                {option.acct_name +' - '+option.accountNo}
              </option>
            ))
              }             


            </select>

          <div class='invalid-feedback'>Wallet account is required.</div>
        </div>

         
         <div class='col-md-12'>
          <label for='validationCustom06' class='form-label'>
            Select sender's wallet to perform this transaction
          </label>

            <select

            onChange={(e) =>{ setData({ ...data, customerCreditAccount: JSON.parse(e.target.value).accountNo })}}
             className='border border-dark rounded form-select'
             value={walletList?JSON.stringify(data.walletOp?.filter((item) =>item.accountNo === data.customerCreditAccount)[0]):""}
            >
              <option value='' disabled>select wallet</option>
              {walletList?.map((option) => (
              <option key={option.accountNo} value={JSON.stringify(option)}>
                {option.acct_name +' - '+option.accountNo + ' - ' + option?.description}
              </option>
            ))
              }             


            </select>

          <div class='invalid-feedback'>Wallet account is required.</div>
        </div>
        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom01' class='form-label'>
            Description
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            required
            onChange={(e)=>setData({...data, tranNarration: e.target.value})}
            value={data.tranNarration}
          />
                    {//}  <Button onClick={()=>{setQuery('');setData({...data, fullName:''})}}>x</Button>
             }
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleTransaction}
        >
          Proceed
        </Button> */}
      </div>
      <div style={{ marginTop: "10px" }} className="row">     
        <div className="col-md-6">
          <Button
            className="iconUI"
            type="ghost"
            onClick={handleTransaction}
            style={{ color: "#fff", background: "#FF4B01", float: "right" }}
          >
            Proceed
          </Button>
        </div>
      </div>

    
    </Modal>
  );
};

export default SingleUserBonus;
