import * as React from 'react';
import { ArrowLeftOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { Box, Chip, Stack, TableCell, TableRow } from "@mui/material";
import { Button, Input, Tag, Modal, Table } from "antd";
import { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import useStyles from "./style.js";
import CustomTable from "../../../components/CustomTable/index3";
import { getComparator, stableSort } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { customBaseUrl, httpPost, httpGet } from "../../../store/http";
import EditCooperateRequirement from './corporateRequirement';
import EditIcon from '@mui/icons-material/Edit';
import Edit from '@mui/icons-material/Edit';
import { hideLoader, showLoader } from '../../../utils/loader';
import TierRequirement from './TierRequirements';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};





const KycSettings = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const [records, setRecords] = useState([]);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});
  const [tranData, setTranData] = useState({})


  function getStyles(name, kycReqRequest, theme) {
    return {
      fontWeight:
        kycReqRequest.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const [kycReqRequest, setAddKycReqRequest] = useState({})
  

  const [orderData, setOrderData] = useState({
    order: "asc",
    orderBy: "tier",
  });

  const [search, setSearch] = useState({
    searchBy: "tier",
    searchText: "",
  });
  const [metaData, setMetaData] = useState(0);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 5 });
  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, current: newPage });
  };
  const handleChangeRowsPerPage = (e) => {
    const { value } = e.target;
    setPagination({ ...pagination, current: 0, pageSize: value });
  };
  const handleRequestSort = (event, property) => {
    const { order, orderBy } = orderData;
    const isAsc = orderBy === property && order === "asc";
    setOrderData(
      isAsc
        ? { order: "desc", orderBy: property }
        : { order: "asc", orderBy: property }
    );
  };




  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };






  const showModal = (record) => {
    setItem(record)
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getCooperateRequirement = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/manage-tier/list/all`

    );

    console.log('data1', res.data)
    // setRecords(data);
    if(res.status){
      hideLoader();
      setRecords(res.data);
      console.log('data', records)
      
    }else{
      hideLoader();
      console.log('error', res.message)
    }
    
  }


  useEffect(() => {
    getCooperateRequirement();
  }, [])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddKycReqRequest(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pagination.current > 0
      ? Math.max(
        0,
        (1 + pagination.current) * pagination.pageSize - records.length
      )
      : 0;



      const columns = [ 
        { 
          key: "tiers", 
          title: "Tiers", 
          dataIndex: "tiers", 
          headerClassName: styles.tableHeader,

          },
        { 
        key: "maximum", 
        title: "Maximum Daily Limit", 
        dataIndex: "maximumLimit", 
        }, 
        { 
        key: "singleLimit", 
        title: "Single Transfer Limit", 
        dataIndex: "singleTransferLimit", 
        render: (text, record) =>  text
        }, 
        { 
        key: "tranAllowed", 
        title: "Maximum Number of Transaction Allowed", 
        width: 200,
        dataIndex: "maxNumberOfTxnAllowed", 
        },
        /*{ 
          key: "requirement", 
          title: "Corporate Requirement", 
          width: 200,
          dataIndex: "tiers", 
          render: (text,row)=> <TierRequirement id={ text[text.length-1]}/>,
        },*/
        { 
          key: "action", 
          title: "Action", 
          dataIndex: "id", 
          render: (text, record) =>  <Edit onClick ={()=>{
            setSelectedRecord(record)
            setTranData(record)
            setVisible(true)
          }
          }/>
          }
       
      
        ] 
  return (
    <Layout>
      <div
        className=""
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/kyc/user_tier_levels")}
      >
        <span className="me-1">
          <ArrowLeftOutlined />
        </span>
        back
      </div>
      <div className={styles.searchRow}>
        <select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.dataIndex !== "corporateRequirements")
            .map((header) => (
              <option value={header.id}>{header.title}</option>
            ))}
        </select>
        <div className={styles.searchRow}>
          <Input
            className={styles.searchInput}
            placeholder="Search data by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>
        <div className={styles.grow} />
      </div>
      <div style={{ marginTop: '95px' }}>
        
      <Table dataSource={records} columns={columns} pagination={false} 
        
        /> 

        <Modal
          visible={visible}

          centered
          onCancel={() => setVisible(false)}
          width={700}
          footer={[]}
        >
          <EditCooperateRequirement
            data={item}
            setData={setRecords}
            selectedRecord = {selectedRecord}
            //item={item}
            getCooperateRequirement={getCooperateRequirement}
            setVisible={setVisible}
            tranData={tranData}
            setTranData={setTranData}
          />
        </Modal>
      </div >

    </Layout >
  );
};

export default KycSettings;
