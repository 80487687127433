import { Modal } from 'antd'
import React,{useState, useEffect, useContext} from 'react'
import Swal from 'sweetalert2'
import { ProfileContext } from '../../../store/context/ProfileContext'
import { customBaseUrl, httpPost } from '../../../store/http'
import { hideLoader, showLoader } from '../../../utils/loader'



const WithdrawCommission = ({hideModal, showModal, wallets,getWallets,  debitWallet}) =>{
const [amount, setAmount] = useState('');
const [selectedWallet, setSelectedWallet] = useState('');
const {profile} = useContext(ProfileContext)

    const handleSubmit = async () =>{
        showLoader();
        let d = new Date();
        let Data ={
            "amount": amount,
            "benefAccountNumber": selectedWallet,
            "debitAccountNumber": debitWallet,
            "paymentReference": d.getTime(),
            "tranCrncy": "NGN",
            "tranNarration": "Transfer from commission wallet",
            "tranType": "TRANSFER",
            "userId": profile.userId
        }
        let res = await httpPost(`${customBaseUrl.walletUrl}/api/v1/wallet/admin/commission/transfer`, Data)

        if(res?.status){
            hideLoader();
            hideModal(false);
            Swal.fire('Done', res?.message)
            getWallets();
        }else{
            hideLoader();
            Swal.fire('Error', res?.message)
        }
    }

    return(

        <Modal
        visible={showModal}
        onCancel ={()=>hideModal(false)}
        footer={false}        
        >
            <div>Transfer to Wallet</div>

            <div>
                <select 
                class='form-select border'
                onChange={(e)=>setSelectedWallet(e.target.value)}
                >
                    <option>Select wallet</option>
                    {
                        wallets.map((item,i)=>
                            <option value={item.accountNo}>{item.accountNo} {item.walletDefault?'Default':''} </option>
                        )
                    }
                </select>
            </div>
            <div class='contentBar-items m-2'>
             <label class='form-label'>
            Amount
              </label>
                <input className='form-control' placeholder='Amount' onChange={(e)=>setAmount(e.target.value)}/>
            </div>
                    
          <div class='col-12 text-end mt-5 m-2'>
      
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Proceed
          </button>
        </div>
            
        </Modal>
    )
}

export default WithdrawCommission;