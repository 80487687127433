import React, { useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { customBaseUrl, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";

const CreateWallet = ({ showModal, hideModal, getWallets }) => {
  const [data, setData] = useState({});

  const handleCreate = async () => {
    if (!data?.tranNarration || !data?.codeDesc || !data?.codeName|| !data?.codeSymbol || !data?.codeValue || !data?.crncyCode || !data?.chargeCustomer || !data?.eventId || !data?.chargeWaya ) return NotificationManager.error('All field must be filled!')
    showLoader();
    /*const postData = {
      ...data,
      crncyCode: "NGN",
      productCode: "OABAS",
      productGL: "11104",
      "accountType": "Official"    
    };
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/official/waya/account`,
      postData
    );
    */
    let d = new Date();
      let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({admin:false, ...data}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "createOfficialAccount",
        customerName: 'Official Wallet'
      } )

    if (res.status === true) {
      hideModal(false)
      getWallets();
      Swal.fire("success", res.message, "success");
      hideLoader();
    } else {
      Swal.fire("error", res.message, "error");
      hideLoader();
    }
  };
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="title">Create Account</div>
        <div className="cw-content">
          {/* <div className="input-content">
            <div className="label">Account Type</div>
            <select name="" id="">
              <option value="">Select Account Type</option>
            </select>
          </div> */}
          <div className="input-content">
            <div className="label">Account Name</div>

            <input
              value={data?.tranNarration}
              onChange={(e) =>
                setData({ ...data, tranNarration: e.target.value })
              }
            />
          </div>
         
          <div className="input-content">
            <div className="label">Code Description</div>

            <input
              value={data?.codeDesc}
              onChange={(e) =>
                setData({ ...data, codeDesc: e.target.value })
              }
            />
          </div>  <div className="input-content">
            <div className="label">Code Name</div>

            <input
              value={data?.codeName}
              onChange={(e) =>
                setData({ ...data, codeName: e.target.value })
              }
            />
          </div>  <div className="input-content">
            <div className="label">Code Symbol</div>

            <input
              value={data?.codeSymbol}
              onChange={(e) =>
                setData({ ...data, codeSymbol: e.target.value })
              }
            />
        
          </div> 
           <div className="input-content">
            <div className="label">Code Value</div>

            <input
              value={data?.codeValue}
              onChange={(e) =>
                setData({ ...data, codeValue: e.target.value })
              }
            />
          </div>
          <div className="input-content">
            <div className="label">Currency Code</div>

            <input
              value={data?.crncyCode}
              onChange={(e) =>
                setData({ ...data, crncyCode: e.target.value })
              }
            />
          </div>
          <div className="input-content">
            <div className="label"> Event IDName</div>

            <input
              value={data?.eventId}
              onChange={(e) =>
                setData({ ...data, eventId: e.target.value })
              }
            />
          </div>
          <div className="input-content">
            <div className="label">  Charge Customer</div>
                <select 
                className="border"
                value={data?.chargeCustomer}

                 onChange={(e) =>
                  setData({ ...data, chargeCustomer: e.target.value })
                }
                >
                  <option value=''>select option</option>
                  <option value={true}>True</option>
                  <option value={false}> False</option>
                </select>           
             <div className="input-content">
            <div className="label">Charge Waya</div>

            <select 
                 className="border"
                 value={data?.chargeWaya}
                 onChange={(e) =>
                  setData({ ...data, chargeWaya: e.target.value })
                }
                >
                  <option value=''>select option</option>
                  <option value={true}>True</option>
                  <option value={false}> False</option>
                </select> 
          </div>
          </div>
          {/* <div className="input-content">
            <div className="label">Account Number</div>
            <input value="" />
          </div>
          <div className="input-content">
            <div className="label">Wallet Type</div>
            <select name="" id="">
              <option value="">Select Wallet Type</option>
            </select>
          </div> */}
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleCreate}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default CreateWallet;
