import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import '../index.scss';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import {searchUsers} from '../../../store/httpCalls';
import SearchDropdown from '../../CustomDropdown/SearchDropdown';
import { ProfileContext } from '../../../store/context/ProfileContext';

const SendSingleUserModal = ({ showModal, hideModal, title, className}) => {
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [walletList, setWalletList] = useState([]);
  const [query, setQuery] = useState('');
  const { profile } = useContext(ProfileContext);

  const handleSelectOption = (selectedUser) => {
    getWallets((selectedUser.userId));
    setData({ ...data, actRefId: selectedUser.userId, fullName: `${selectedUser.firstName}  ${selectedUser.surname}`});
    setQuery(`${selectedUser.firstName} ${selectedUser.surname}`);
   /* setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);*/
    setOptions([]);
  };


  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }
  const handleSubmit = async (e) => {

    e.preventDefault();
    const d = new Date();
    let time = d.getTime();

    let transferData = {
      "amount": data.amount,
      "customerDebitAccountNo": data.accountNo,
      "emailOrPhoneNo": data.email||data.phoneNumber,
      "fullName": data.fullName,
      "paymentReference": `${data.actRefId}|${profile.userId}|${time}`,
      "tranCrncy": "NGN",
      "tranNarration": data.narration,
      "userId": data.actRefId
    }

    showLoader();
   /* 
     let res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/wallet/non-waya/payment/new`,
        transferData
      );*/
    
     /* const res = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new
        `,
        transferData
      );

      */
     let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify(transferData),
        "requestId": `${d.getTime()}`,
        "requestType": "TRANSFERToNonWaya",
        customerName: data?.fullName
      } )
    

    if (res.status) {
      hideLoader();
      Swal.fire('Done', res?.message, 'success');
      hideModal(false);
    } else {

      Swal.fire('Error', res.message, 'error');
    }
  };


  const getWallets = async (userId) => {
  //  return alert(userId);
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}`
    );
   // alert(JSON.stringify(res));
    if (res?.status) {
      setWalletList(res.data);
    }
  };
/*
  const getVirtualAccounts = async () => {
    const res = await httpGet(
      `${customBaseUrl.accountCreationUrl}/account/getAccounts/${userId}`
    );
    if (res?.status) {
      setVirtualAccount(res.data);
    }
  };

  const getBankAccountList = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/admin/list/userBankAcct/${userId}`
    );
    if (res?.status) {
      setBankAccountList(res.data);
    }
  };
*/
  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={800}
      footer={0}
    >
      <form class='row g-3 needs-validation' novalidate>
        <div class='col-12 text-center font-weight-bold'>
        <p class="font-weight-bold text-lg"> {title} </p>
        </div>
        <div style={{ position: 'relative' }} class="col-md-6">
          <label for='validationCustom01' class='form-label'>
            Sender's Name
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            value={query}
            required
            onChange={handleSearch}
          />
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>
      
      <div class='col-md-6'>
          <label for='validationCustom02' class='form-label'>
            Recipient's Phone
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom02'
            value={data.phoneNumber}
            required
            onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
          />
          <div class='-feedback'>Phone number is required.</div>
       </div>     
          
        <div class='col-md-6'>
          <label for='validationCustom03' class='form-label'>
            Recipient's Email
          </label>
          <input
            type='email'
            class='form-control'
            id='validationCustom03'
            value={data.email}
            required
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <div class='invalid-feedback'>Email is required.</div>
        </div>
        <div class='col-md-6'>
          <label for='validationCustom04' class='form-label'>
            Narration
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom04'
            value={data.narration}
            required
            onChange={(e) => setData({ ...data, narration: e.target.value })}
          />
          <div class='invalid-feedback'>Email is required.</div>
        </div>
        
        <div class='col-md-6'>
          <label for='validationCustom05' class='form-label'>
            Amount
          </label>
          <input
            type='number'
            class='form-control'
            id='validationCustom05'
            value={data.surname}
            required
            onChange={(e) => setData({ ...data, amount: e.target.value })}
          />
        </div>
        <div class='col-md-6'>
          <label for='validationCustom06' class='form-label'>
            Select sender's wallet to perform this transaction
          </label>
          <select
            class='form-select'
            id='validationCustom06'
            required
            value={data.accountNo}
            onChange={(e) => setData({ ...data, accountNo: e.target.value })}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { walletList.length>0 &&
            walletList.map((option) => (
              <option key={option.accountNo} value={option.accountNo}>
                {option.acct_name +' - '+option.accountNo}
              </option>
            ))}
          </select>
          <div class='invalid-feedback'>Wallet account is required.</div>
        </div>
        
        <div class='col-12 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Proceed
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SendSingleUserModal;
