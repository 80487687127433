import React, { useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { hideLoader, showLoader } from "../../../../utils/loader";
import { customBaseUrl, httpGet } from "../../../../store/http";
import moment from "moment";

const ExportTrans = ({ showModal, hideModal, dataList, accountNo }) => {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [transactions, setTransactions] = useState([]);


  const handleFilterOperation = async () => {
   // return alert(JSON.stringify(data));
    if (data.startDate && data.endDate) {
      console.log(data);
      const filteredData =await  dataList.filter(({transactionDateTime}) => {
        var date = new Date(transactionDateTime);
        return date > new Date(data.startDate) && date < new Date(data.endDate);
      });
      console.log('sentList',dataList);
      console.log('filtered',filteredData);
      setFilteredData(filteredData);

    } else {
      NotificationManager.error("Please select date range");
    }
  };
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const getHistory = async () => {
  showLoader();
  const response = await httpGet(
    `${customBaseUrl.walletUrl}/api/v1/wallet/transaction-list/${accountNo}?fromdate=${moment(data.startDate).format('YYYY-MM-DD')}&todate=${moment(data.endDate).format('YYYY-MM-DD')}`
  );
  if (response.status === true) {
    setTransactions(response.data.transaction);
    NotificationManager.success(response?.message)
    //setFiltered(response.data.content)   
    hideLoader();
  } else {
    NotificationManager.error(response?.message)
    setTransactions([]);

    hideLoader();
  }
};
useEffect(()=>{
  if(data.endDate && data.startDate){
    getHistory();
    console.log('end', data.endDate, 'stat', data.startDate )
  }
},[data.startDate, data.endDate])
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="title">Export Data</div>
        <div className="cw-content">
          <div className="input-content border-dark">
            <div className="label">Filters</div>
            <select name="" id="" className="border border-dark">
              <option value="">Transaction Date</option>
            </select>
          </div>
            <div className="label">Start Date</div>
           
         <DatePicker   onChange={(e)=>{setData({ ...data, startDate: formatDate(e) })       }  } />

            <div className="label" style={{marginTop:20}}>End Date</div>
            
          <DatePicker   onChange={(e)=>{setData({ ...data, endDate: formatDate(e) })       }  } />

        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 50
        }}
      >
        <CSVLink
          data={transactions}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
            textDecoration:"none"
          }}
          filename={"Transaction-History.csv"}
          onClick={async (event, done) => {
            // event.preventDefault();
              await handleFilterOperation().then(res=>{
                done();
              })
          }}
        >
          Export Data
        </CSVLink>
      </div>
    </Modal>
  );
};


export default ExportTrans

