import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
  } from "@mui/material";
  import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import ColoredSwitcher from '../../../components/customSwitch/coloredSwitcher';
import { Modal } from 'antd';
import CreateNewCount from './updateDays';
import NewBonusAmount from './newBonusAmount';

const ReferralSettings = ({ showModal, hideModal, className, getTemporalReversalDays}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [counts, setCounts] = useState([]);
  const [amounts, setAmounts] = useState([])
  const [showCreateNew, setShowCreateNew] = useState(false)
  const [showCreateNewAmount, SetShowCreateNewAmount] = useState(false)
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')


  const [newDayCount,setNewDayCount] =useState(false)

    const handleToggle = async (vendorIndex, countstate, vendor) => {
      showLoader();
      const response = await httpPut(
        `${customBaseUrl.referralUrl}/api/v1/admin/config/transaction-count/${vendor.id}/toggle`
      );
      hideLoader();
      if (response.status === true) {
        Swal.fire(response.message)
        // handleProviderChange(vendorIndex, response?.data?.active);
        handleGetReferalCount();
      }
    };

    const handleToggleAmount = async (vendorIndex, countstate, amount) => {
      showLoader();
      const response = await httpPut(
        `${customBaseUrl.referralUrl}/api/v1/admin/config/amount/${amount.id}/toggle`
      );
      hideLoader();
      if (response.status === true) {
        Swal.fire(response.message)
        // handleProviderChange(vendorIndex, response?.data?.active);
        handleGetReferalAmount();
      }
    };

    const handleGetReferalCount = async () => {
        const response = await httpGet(
          `${customBaseUrl.referralUrl}/api/v1/admin/config/transaction-count/list`,
        );
        hideLoader();
        if (response?.status === true) {
            hideLoader();
            console.log(response)
          setCounts(response?.data);
        }else{
            console.log(response)
            hideLoader()
        }
        hideLoader()

      };

      const handleGetReferalAmount = async () => {
        const response = await httpGet(
          `${customBaseUrl.referralUrl}/api/v1/admin/config/amount`,
        );
        hideLoader();
        if (response?.status === true) {
            hideLoader();

            console.log(response)
          setAmounts(response?.data);
        }else{
            console.log(response)
            hideLoader()
        }
        hideLoader();

      };

  
  const handleSubmit = async (e) => {   
  
    showLoader();
    const formBody = Object.keys(data).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');

      const res = await httpPostUnreloaded(
        `${customBaseUrl.walletUrl}/api/v1/config
        `,
        formBody
      )
    if (res.status) {
      getTemporalReversalDays()
      hideLoader();
      Swal.fire('Done',  'success');
     hideModal(false);
    } else {
      alert(JSON.stringify(res));

      Swal.fire('Error', res.message, 'error');
    }
  };

  const handleCreateNew = async() =>{
        showLoader();

        let res= await httpPost(`${customBaseUrl.referralUrl}/api/v1/admin/config/transaction-count`,
        {
            numberOfTransaction: newDayCount
        }  )

        if(res.status){
            setShowCreateNew(false)
            hideLoader()
            console.log(res)
            handleGetReferalCount();
            setNewDayCount('')
            Swal.fire('Success', res.message,'Done')
        }else{
            console.log('err',res)
            hideLoader();
            Swal.fire('failed', res.message,'Error')

        }
    

  }

  const handleCreateNewAmount = async() =>{
    showLoader();
    let data= {
      "amount": amount,
      "description": description,
      "id": 1,
      "userType": "ROLE_USER"
    }
    let res= await httpPost(`${customBaseUrl.referralUrl}/api/v1/admin/config/amount`,
    data  )

    if(res.status){
        SetShowCreateNewAmount(false)
        hideLoader()
        console.log(res)
        handleGetReferalAmount();
        setAmount('')
        Swal.fire('Success', res.message,'Done')
    }else{
        console.log('err',res)
        hideLoader();
        Swal.fire('failed', res.message,'Error')

    }


}

  useEffect(() => {
    handleGetReferalCount();
    handleGetReferalAmount();
  }, []);

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}
    
    >
      {/*<div class="row">
        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={()=>setShowCreateNew(true)}>
            Create New Bonus Count
          </button>
        </div>
        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={()=>SetShowCreateNewAmount(true)}>
            Create Bonus Amount
          </button>
        </div>
        </div>
        {/*<Card
          style={{
            transitionDuration: "0.3s",
            minHeight: "30vw",
            display: "block",
          }}
          sx={{ marginTop: "30px" }}
          raised={true}
        >

        <CardContent>
            <List dense={false}>
              <div style={{display:'flex', flexDirection:'row', alignContent:'space-between'}}>
                <div style={{marginLeft:10, marginRight:10}}>
                  No. of Transactions
              {counts?.map((item, index) => (
                <Grid container spacing={20}>
                  <Grid key={item.id + item.numberOfTransaction} item sm={4} sx={{ width: 50 }}>
                    <ListItem
                      key={item.id }
                      secondaryAction={
                        <div>
                        <IconButton key={item.id} edge="end" aria-label="delete">
                          <ColoredSwitcher
                            data={item}
                            type="sms-vendor"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: "700", width: 50 }}
                            label={''}
                            keyValue={item.id}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                        
                        </div>
                      }
                    >
                      <div
                        key={item.id}
                        style={{ fontFamily: "inherit", marginLeft:50, marginRight:10, fontWeight: "700" }}
                      >
                        {item.numberOfTransaction}
                      </div>
  
                       {//<ListItemText   secondary={item.numberOfTransaction} /> 
                      }
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
              </div><div>
                Amount 
               {amounts?.map((item, index) => (
                <Grid container spacing={10}>
                  <Grid key={item.id + item.amount} item sm={4} sx={{ width: 1 }}>
                    <ListItem
                      key={item.id }
                      secondaryAction={
                        <IconButton key={item.id} edge="end" aria-label="delete">
                          <ColoredSwitcher
                            data={item}
                            type="sms-vendor"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: "700" }}
                            label={''}
                            keyValue={item.id}
                            color="#ff6700"
                            onChange={handleToggleAmount}
                          />
                        </IconButton>
                      }
                    >
                      <div
                        key={item.id}
                        style={{ fontFamily: "inherit", marginLeft:50, marginRight:10, fontWeight: "700" }}
                      >
                        {item.amount}
                      </div>
  
                      {// <ListItemText   secondary={item.name} /> 
                      }
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
              </div>
              </div>
            </List>
          </CardContent>
          </Card>*/}
          <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
          <label for='validationCustom05' class='form-label'>
            Select Business Type
          </label>
          <select
            class='form-select border'
            id='validationCustom05'
            value={newDayCount}
            required
            // onChange={(e) => setNewDayCount(e.target.value)}
          >
            <option>--Select business type</option>
          </select>
        </div>
          <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
          <label for='validationCustom05' class='form-label'>
            Number of Transfer Time
          </label>
          <input
            type='Number'
            class='form-control'
            id='validationCustom05'
            value={newDayCount}
            required
            // onChange={(e) => setNewDayCount(e.target.value)}
          />
        </div>
        <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
          <label for='validationCustom05' class='form-label'>
            Amount to Disburse
          </label>
          <input
            type='Number'
            class='form-control'
            id='validationCustom05'
            value={newDayCount}
            required
            // onChange={(e) => setNewDayCount(e.target.value)}
          />
        </div>
        <div class='col-12 text-end mt-5'>
          <button class='btn btn-primary' type='submit' >
            Save Changes
          </button>
        </div>
                      {
                        showCreateNew &&
                            <CreateNewCount
                            showModal={showCreateNew}
                            hideModal= {setShowCreateNew}
                            newDayCount={newDayCount}
                            setNewDayCount = {setNewDayCount}
                            handleCreateNew={handleCreateNew}
                            />
                        
                      }
                       {
                        showCreateNewAmount &&
                            <NewBonusAmount
                            showModal={showCreateNewAmount}
                            hideModal= {SetShowCreateNewAmount}
                            amount={amount}
                            setAmount = {setAmount}
                            description={description}
                            setDescription ={setDescription}
                            handleCreateNew={handleCreateNewAmount}
                            />
                        
                      }
        
    </Modal>
  );
};

export default ReferralSettings;
