import React from 'react';

export const StatCards = ({ lists, state, classes, title }) => {
  return (
    <div style={{textAlign:'center', background:"#ffffff", marginBottom:100, borderRadius:10}} >
    <p ><h5>{title}</h5></p>
    <div className={classes.mainContainer}>
      {lists.map((l) => (
        <div className={classes.cardsContainer} key={l.id}>
          {l.icon}
          <div className={classes.valueContainer}>
            <p className={classes.text}>{l.title}</p>
            <p className={classes.textAmt}>{state ? l.value?.value : l.value?.value?'*******':''}</p>
            <p className={classes.textAmt}>{state ? l.value?.count : '*******'}</p>

          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export const extra = () => {};
