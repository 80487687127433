import { Button} from "antd";
import { useEffect, useState } from "react";
import { useMatStyles } from "./style";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import ExportData from "../WayaOfficial/modal/exportData";
import Swal from "sweetalert2";
import moment from "moment";
import { ExportOutlined } from "@ant-design/icons";
import CreateCardType from "./modals/createCardType";
import { ArrowBack } from "@mui/icons-material";
import { CSVLink } from "react-csv";
const Container = `styled.div`;
const CardType = ({setShowCardType}) => {
  const [metaData, setMetaData] = useState(0);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [cardTypes, setCardTypes] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [showCreateCardType, setShowCreateCardType] = useState(false);
  const styles = useMatStyles();


  const columns = [
  
    { field: 'type',
      headerName: 'Type',
      width: 250 ,
      //    headerClassName: styles.tableHeader

    },
    
    { field: 'createdAt',
    headerName: "Date",
    width: 150 ,
    // headerClassName: styles.tableHeader,
    renderCell: ({row}) => `${row?.createdAt[2]}/${row?.createdAt[1]}/${row?.createdAt[0]}` 
    },
    ];  


  const handleChangePage = async (newPage) => {
    setPagination({ ...pagination, current: newPage });
    // await getCardTypes(newPage, newPage, pagination.pageSize);

  };
  const handleChangeRowsPerPage = async (e) => {
    console.log(e)
    setPagination({ ...pagination, current: 0, pageSize: e });
  //await getCardTypes(e,1, e);
  };

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const getCardTypes = async (e,page, size) => {
    e?.preventDefault();
    showLoader();
    const pages = page === 0 ? 0 : page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.cardUrl}/card/list/card-type`
    );
    if (request?.status) {
      console.log(request)
      hideLoader();
      setCardTypes(request?.data)
      setMetaData(request?.data?.lenght);
    }else{
      console.log(request)
      hideLoader();

    }
    
   
  };

  
  useEffect(() => {
  
  }, []);
  useEffect(()=>{
    getCardTypes()
  },[pagination?.pageSize, pagination.current]);

  return (
    <>

      <Container>

        <div className={styles.searchRow}>
          <div onClick={()=>setShowCardType(false)}>
            <ArrowBack/>
          </div>
         <div>
         <Button
                size="medium"
                style={{ background: "#27AE60", fontSize: "12px", color: "#fff" }}
              >
            <CSVLink
          data={cardTypes}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`card Types.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          Export
        </CSVLink>
          </Button>
        </div>
        <div className={styles.grow} />
        
          <Button     
          onClick={()=>setShowCreateCardType(true)}     
           className={styles.updateButton} size="medium">
            Create New Card Type
          </Button>
        </div>
                  
        <div style={{ height: 800, width: "100%" }}>
        {cardTypes?.length > 0 && <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={cardTypes}
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            // paginationMode="server"
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}       
        />}
  </div>
      {showCreateCardType ? (
        <CreateCardType
          showModal={showCreateCardType}
          hideModal={setShowCreateCardType}
          getCardTypes={getCardTypes}
        />
      ) : (
        ""
      )}
      </Container>
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={cardTypes} file={"KYC users tier "} />
      }
    </>
  );
};
export default CardType;
const gridStyle = {
  width: "23%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};
