import React, { useEffect } from "react";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useMatStyles from "../style";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Divider } from "antd";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../../store/http";
import { NotificationManager } from "react-notifications";
import { httpPostFormData } from "../../../../services/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import Swal from "sweetalert2";



const ManageCommissions = ({
  showModal,
  setShowModal,
  commissionList,
  commissionData,
  setCommissionData,
  handleSubmit,
  getComList,
  comList
}) => {
  const styles = useMatStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [commissionUsers, setCommissionUsers] = React.useState([]);
  const [usersCommission, setUsersCommission] = React.useState(0);
  const [comLists, setComList] = React.useState({})
  const [genComList, setGenComList] = React.useState('')
  const [agentCom, setAgentCom] = React.useState(0)
  const [aggregatorCom, setAggregatoCom] = React.useState(0)


  useEffect(() => {
    setCommissionData(commissionList?.[0]);
    setComList(comList?.[0])
    setGenComList(comList?.[0]?.generalCommission)
    console.log('comm',comList)
  }, []);

 

  const handleSubmitCommission = async () => {
    showLoader()
    let data = {
      "agentAggregatorCommission": agentCom,
      "agentCommission": aggregatorCom,
      "generalCommission": genComList
    }
   
    let res = await httpPut(`${customBaseUrl.commissionUrl}/api/v1/admin/commissions/${comLists?.id}`, data)

    if(res.status){
      hideLoader();
      setShowModal(false)
      getComList();
      Swal.fire('Done', res.message)
    }else{
      hideLoader();
      Swal.fire('error', res.message)
    }
  };

  const handleClose = () => {
    setCommissionData({});
    setShowModal(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={showModal}
        onClose={() => handleClose()}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Manage Commissions
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-3">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Biller</div>
                <select
                  value={commissionData?.biller}
                  className={`{styles.inputInput} border input-content`}
                >
                  <option>ALL BILLERS</option>
                  {/*commissionList.map((commission) => (
                    <option key={commission?.id} value={commission.billerAggregatorName}>
                      {commission?.billerAggregatorName?.toUpperCase()}
                    </option>
                  ))*/}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className={styles.inputCont}>
                <div className={styles.inputLabel}>
                  General Commission to Corp. Acct.
                </div>
                <input
                  value={genComList}
                  type="number"
                  className={styles.inputInput}
                  onChange={(e) =>
                    setGenComList(e.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className={styles.inputCont}>
                <div className={styles.inputLabel}>                  Commission to Agent Aggregator
</div>
                <input
                  value={aggregatorCom}
                  type="number"
                  className={styles.inputInput}
                  onChange={(e) =>
                    setAggregatoCom(e.target.value)
                  }
                />
              </div>
            </div>
            
            <div className="col-md-3">
              <div className={styles.inputCont}>
              <div className={styles.inputLabel}>
                  COMMISION TYPE                </div>
               <select class='input-content border'>
                <option>FIXED</option>
                <option>PERCENTAGE</option>

               </select>
              </div>
            </div>
          </div>
          <div className="row">
           {/*} <div className="col-md-12">
              <Root>
                <div {...getRootProps()}>
                  <Label {...getInputLabelProps()}>
                    Set Specific Users to Commissions
                  </Label>
                  <InputWrapper
                    style={{ width: "100%" }}
                    ref={setAnchorEl}
                    className={focused ? "focused" : ""}
                  >
                    {value.map((option, index) => (
                      <StyledTag
                        label={option.merchantName}
                        {...getTagProps({ index })}
                      />
                    ))}

                    <input {...getInputProps()} />
                  </InputWrapper>
                </div>
                {groupedOptions.length > 0 ? (
                  <Listbox style={{ width: "100%" }} {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                      <li {...getOptionProps({ option, index })}>
                        <span>{option.merchantName}</span>
                        <CheckIcon fontSize="small" />
                      </li>
                    ))}
                  </Listbox>
                ) : null}
              </Root>
            </div>*/}
          </div>
          <Divider />
          {/*<div className="row">
            <div className="col-md-12">
              <div style={{ width: "100%" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>
                  Set Commissions For the Selected Users
                </div>
                <input
                  value={usersCommission}
                  type="number"
                  className={styles.inputInput}
                  onChange={(e) => setUsersCommission(e.target.value)}
                />
              </div>
              <Button
                style={{
                  marginTop: "-15px",
                }}
                className={styles.viewUsersButton}
              >
                view all users with specific commissions
              </Button>
            </div>
          </div>*/}
          <div className="row">
            <div className="col-md-12">
              <Button onClick={handleSubmitCommission} className={styles.filledButton}>
                Save Changes
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ManageCommissions;
