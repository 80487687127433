import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import '../index.scss';
import { customBaseUrl, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const CreateBulkUser = ({ showModal, hideModal, title, className }) => {
  const [file, setFile] = useState('');
  const [isCorporate, setIsCorporate] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();

    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('isCorporate', isCorporate);   

    /*const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/users/bulk-user-excel/${isCorporate}`,
      formdata
    );*/
    let d = new Date();
    let reqId= d.getTime();
    let customerName = ' Bulk Users'
    let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/BulkExcelUsers`, formdata)

    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      setFile('');
      setIsCorporate(false);
      hideModal(false);
    } else {
      Swal.fire('Error', res.message, 'error');
    }
  };

  return (
    <Modal
      className={className}
      title={title}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <form class='row g-3 needs-validation' novalidate>
        <div class='col-10 mx-auto'>
          <label for='validationCustom04' class='form-label'>
            Select Account type
          </label>
          <select
            class='form-select'
            id='validationCustom04'
            required
            onChange={(e) => setIsCorporate(e.target.value)}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            {[
              { label: 'Individual', value: false },
              { label: 'Corporate', value: true },
            ].map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div class='invalid-feedback'>Account type is required.</div>
        </div>
        <div className='col-10 mt-5 mb-3 mx-auto'>
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label class='input-group-text' for='inputGroupFile02'>
              Browse
            </label>
            <div class='invalid-feedback'>File is required.</div>
          </div>
        </div>

        <div class='col-12 text-center mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateBulkUser;
