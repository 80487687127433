import React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { customBaseUrl, httpPost } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import Swal from 'sweetalert2';
import QRCode from 'react-qr-code';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  height: 280,
  bgcolor: 'background.paper',
  'border-radius': '9px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function GenerateQr( {modalState, modalCloseAction} ) {
  const [open, setOpen] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userType, setUserType] = useState(true);
  const [accountNumber, setAccountNumber] = useState("");
  const [pin, setPin] = useState("");
  const [supportCode, setSupportCode] = useState("");
 const [qrv, setQrV] = useState('')
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    modalCloseAction();
  };

  let handleSubmit = async (e) => {
    showLoader();
    e.preventDefault();
    let data1 = JSON.stringify({
      firstName,
      email,
      phoneNumber,
      userType,
      accountNumber,
      pin,
    });
    let d = new Date();
    let data = {
      "email": email,
      "firstName": firstName,
      "phoneNumber": phoneNumber,
      "password": pin,
      "supportCode": supportCode,
      "surname": lastName
    }
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(data),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "createFieldMarketer",
      customerName: `${firstName} ${lastName}`
    } )
    if (res?.status) {
      hideLoader();
      handleClose()
      Swal.fire(res?.message);
    } else {
      hideLoader();
      Swal.fire(res?.message);
    }
  };

  return (
    <>
      <Modal
        open={modalState}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 800, height: 550, padding: '20px' }}>
          <CloseIcon
            onClick={modalCloseAction}
            style={{
              top: '50%',
              'margin-left': '750px',
              color: '#BCBCBC',
              'border-radius': '20px',
              cursor: 'pointer',
            }}
          />
          <div style={{ 'align-item': 'center' }}>
              <form onSubmit={handleSubmit}>
            <div style={{ 'align-item': 'center' }}>
              <h2
                id="child-modal-title"
                style={{
                  'font-style': 'normal',
                  'font-weight': '600',
                  'font-size': '20px',
                  'line-height': '140%',
                  'text-align': 'center',
                  color: '#000000',
                }}
              >
                Single User             

              </h2>
              <div
                style={{
                  padding: '20px 30px',
                  display: 'flex',
                  'flex-wrap': 'wrap',
                  width: '100%',
                  height: '300px',
                }}
              >
                <div style={{ width: '33%', position: 'relative', margin: 0, }}>
                    
                  <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333', }} >
                    First Name
                  </label>
                  <input 
                  type="text"
                  style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 40px', 'margin-top': '10px', height: 42,
                      'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '1px solid #828282' }}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div style={{ width: '33%', position: 'relative', margin: '0 0 0 130px', }}>
                    
                    <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333', }} >
                      Last Name
                    </label>
                    <input 
                    type="text"
                    style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 40px', 'margin-top': '10px', height: 42,
                        'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '1px solid #828282' }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                  </div>

                <div style={{ width: '33%', position: 'relative', 'margin': '0', }}>
                  <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333' }}>
                    Phone Number
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 40px', 'margin-top': '10px', height: 42,
                      'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '1px solid #828282' }}
                      />
                </div>

                <div style={{ width: '33%', position: 'relative', margin: '0 0 0 130px',
                }}>
                  <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333' }}>
                    Email Address
                  </label>
                  <input style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 40px', 'margin-top': '10px', height: 42,
                      'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '1px solid #828282' }}
                      type="text" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      />
                </div>
                <div style={{ width: '33%', position: 'relative', 'margin': '0', }}>
                  <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333' }}>
                    Password
                  </label>
                  <input style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 40px', 'margin-top': '10px', height: 42,
                      'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '1px solid #828282' }}
                    type="text"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      /> 
                </div>
                {/*<div style={{ width: '33%', position: 'relative', 'margin': '0', }}>
                  <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333' }} >
                    User Type
                  </label>
                  <select
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                    style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 0', 'margin-top': '10px', height: 42,
                      'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '2px solid #828282' }}
                      >
                        <option value=""></option>
                        <option value="true">Cooporate User</option>
                        <option value="false">Individual User</option>
                  </select>
                </div>*/}

                <div style={{ width: '33%', position: 'relative', margin: '0 0 0 130px',
                }}>
                  <label style={{ 'font-weight': '400', 'font-size': '12px', 'line-height': '12px', color: '#333' }}>
                    Support Code
                  </label>
                  <input style={{ width: 300, color: '#000', 'border-radius': '3px', padding: '10px 40px', 'margin-top': '10px', height: 42,
                      'font-style': 'normal', 'font-weight': '600', 'font-size': '10px', border: '1px solid #828282' }}
                    type="text"
                      value={userId}
                      onChange={(e) => setSupportCode(e.target.value)}
                      />
                </div>

                
              </div>
            <Button
              type="submit"
              style={{
                background: '#FF4B01',
                width: 200,
                padding: '12px 10px',
                color: '#fff',
                'font-weight': 500,
                'font-size': '14px',
                'margin-left': '550px',
                'margin-buttom': '20px',
                'margin-top': '20px',
                'font-style': 'normal',
                'line-height': '140%',
                'border-radius': '3px'
              }}
            >
              Create User
            </Button>
            </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default GenerateQr;
