import styled from "styled-components";

const Wrapper = styled.div`
  @media print {
  @page {
     size: landscape;
  }
  }
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 48px;
  }
  .cw-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1px;

      .label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 8px;
        line-height: 12px;
        color: #000000;
        margin-bottom: 3px;
      }
      .label2 {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #000000;
        margin-bottom: 3px;
      }
      input,
      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 8px;
        outline: 0px;
      }
    }
  }
`;

export default Wrapper;

export const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tab-bar {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #a8a8a8;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 43px;
    .tb-item {
      padding: 10px 0px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #a8a8a8;
      cursor: pointer;
    }
    .tb-item-active {
      padding: 10px 0px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ff4b01;
      border-bottom: 2px solid #ff4b01;
    }
  }
  .create-pin {
    width: 100%;
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-bottom: 43px;
    }
    .cp-flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .input-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        .label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 12px;
          color: #000000;
          margin-bottom: 9px;
        }
        input,
        select {
          width: 100%;
          background: #ffffff;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 8px;
          outline: 0px;
        }
      }
    }
    .cp-content {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      .cp-t-button {
        border-radius: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        padding: 10px;
        width: 48%;
        text-align: center;
      }
      .input-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 40px;

        .label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 12px;
          color: #000000;
          margin-bottom: 9px;
        }
        input,
        select {
          width: 100%;
          background: #ffffff;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 8px;
          outline: 0px;
        }
      }
    }
  }
  .linkedCard {
    width: 100%;
    .card-div {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 35px;
    }
    .add-div {
      background: #ffffff;
      border: 1px dashed #ff6700;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ff6700;
      display: flex;
      flex-direction: column;
      padding: 23px;
      width: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`;
