import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Grid, Paper } from "@mui/material";
import { Button, Divider } from "antd";
import MatButton from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../../../../components/Layout";
import slugs from "../../../../resources/slugs";
import { customBaseUrl, httpDelete, httpGet } from "../../../../store/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import useMatStyles from "../style"; // eslint-disable-line
import ChangeChargesModal from "./changeCharges";
import CreateNewCharge from "./createNewCharge";
const ChangeTransactionCharges = () => {
  const styles = useMatStyles();

  const [charges, setCharges] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState({});
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [CreateData, setCreateData] = useState({});
  const navigate = useNavigate();
  const getCharges = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/bank/event/charges`
    );
    if (response.status === true) {
      hideLoader();
      setCharges(response.data);
      console.log(response)
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
  };
  useEffect(() => {
    getCharges();
  }, []);
  useEffect(() => {
    console.log(charges);
  }, [charges]);

  const handleChangeModal = (row) => {
    setSelectedCharge(row);
    setShowChangeModal(true);
  };

  const handleDelete = async (row) => { 
    Swal.fire({
      title: `Are you sure want to delete ${row.tranNarration} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDeleteCharges(row);
      }
    });
  }

  const handleDeleteCharges = async (row) => {

    showLoader();
    const response = await httpDelete(
      `${customBaseUrl.walletUrl}/api/v1/bank/update/event/${row.id}/delete`
    );
    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Deleted!",
        text: "Charges has been deleted.",
        icon: "success",
      });
      getCharges();
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
  }


  return (
    <Layout>
      <div>
        <Button
          type="ghost"
          onClick={() => navigate(slugs.transaction_charge)}
          icon={
            <ArrowLeftOutlined
              style={{
                fontSize: "15px",
              }}
            />
          }
        >
          Back
        </Button>

        {/* <Button
          className={styles.updateButton}
          icon={<PlusOutlined />}
          size="middle"
          onClick={() => setShowCreateModal(true)}
        >
          Create New Charge
        </Button> */}
      </div>
      <Divider />
      <section id="chargeSection">
        <Card className={styles.cardContainer} style={{ width: "100%" }}>
          <Card.Title style={{ textAlign: "center", marginTop: "40px" }}>
            <h5>Transaction Charges</h5>
          </Card.Title>
          <Card.Subtitle
            className={styles.subtitleText}
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            Make changes to charges
          </Card.Subtitle>
          <Card.Body style={{ marginTop: "40px" }}>
            <div className="row">
              {charges.map((item, index) => (
                <div key={index} className="col-md-4">
                  <Paper elevation={1} className={styles.transactionCard}>
                    <div className={styles.transactionCardTitle}>
                      {item.tranNarration}
                    </div>
                    <div
                      className="row"
                      style={{
                        marginTop: "30px",
                        marginRight: "30px",
                        marginBottom: "40px",
                      }}
                    >
                      <div className="col-md-6">
                        <div className={styles.transactionAmount}>
                          {item.currencyCode === "NGN"
                            ? "₦"
                            : item.currencyCode}
                          {item.tranAmt}
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end">
                        <ButtonGroup
                          variant="text"
                          aria-label="text button group"
                        >
                          <MatButton
                            size="small"
                            onClick={() => {
                              handleChangeModal(item);
                            }}
                            style={{ color: "#27AE60" }}
                          >
                            Change
                          </MatButton>
                          {/* <MatButton size="small" style={{ color: "#FF4B01" }}>

                          <MatButton size="small" style={{ color: "#FF4B01" }}
                            onClick={() => {
                              handleDelete(item);
                            } }
                          >

                            Delete
                          </MatButton> */}
                        </ButtonGroup>
                      </div>
                    </div>
                  </Paper>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </section>
      {showChangeModal && (
        <ChangeChargesModal
          showModal={showChangeModal}
          hideModal={setShowChangeModal}
          charge={selectedCharge}
          getAll={getCharges}
        />
      )}
      {/* {showCreateModal && (
        <CreateNewCharge 
          showModal={showCreateModal}
          hideModal={setShowCreateModal} 
          getAll={getCharges}
         
        />)} */}
    </Layout>
  );
};
export default ChangeTransactionCharges;
