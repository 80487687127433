import React from 'react';
import Switch from '@mui/material/Switch';

const SwitchToggler = ({ handleToggler, state }) => {
  return (
    <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
      <p
        style={{
          fontSize: '15px',
          marginTop: '10px',
          color: '#000000',
          fontWeight: 600,
        }}
      >
        {state === true ? 'hide dashboard values' : 'show dashboard values'}
      </p>{' '}
      <span>
        <Switch
          checked={state}
          onChange={handleToggler}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </span>
    </div>
  );
};

export default SwitchToggler;
