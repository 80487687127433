import React, {useRef} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {useReactToPrint} from 'react-to-print';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FaDownload } from "react-icons/fa";
import QRCode from "react-qr-code";
import { useState, useEffect } from 'react';
import { customBaseUrl, httpGet } from '../../store/http';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  height: 280,
  bgcolor: 'background.paper',
  'border-radius': '9px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function GeneratedQr({modalState, modalCloseAction, qrValue, selectedRow}) {
  const componentRef = useRef()
  const [qrImage, setQrImage] = useState(null);
  // const [qrImageUrl, setQrImageUrl] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await httpGet(
        `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/user-qrcode`
      );
      console.log(response);
      setQrImage(response.data.qrImage);
    }
    fetchData();
  }, []);

  const [open, setOpen] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePrint = useReactToPrint({
    content: ()=> componentRef.current,
  })
  const downloadPdfDocument = (rootElementId) => {
    const input = document.getElementById(rootElementId);
    html2canvas(input)
      .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 0, 0);
          pdf.save("QRcode.pdf");
      })
  }
  return (
    <>
      {/* <Button onClick={handleOpen}>Open Child Modal</Button> */}
      <Modal
        open={modalState}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500, height: 600, padding: '20px' }} >
          <CloseIcon
            onClick={modalCloseAction}
            style={{ top: '50%', 'margin-left': '450px', color: '#BCBCBC', 'border-radius': '20px', cursor: 'pointer' }}
          />
          <div ref={componentRef} id="divToDownload">
          <div style={{ 'align-item': 'center' }}>
            <h2
              id="child-modal-title" style={{ 'font-style': 'normal', 'font-weight': '600', 'font-size': '20px', 'line-height': '140%',
                'text-align': 'center', color: '#000000', }}
            >
              Generated QR Code
            </h2>
          </div>
          <div style={{ 
            height: 250,
            width: 250,
            'margin': '50px 100px 0 100px',
          }}>
        <img width={200} height={200} src={`data:image/jpeg;base64,${qrValue}`} />

         {//}   <QRCode value={selectedRow} size={250} />
          }
          </div>
          <div>
            <p style={{
              'font-weight': 500, margin: '40px 70px', 'font-size': '14px', 'line-height': '140%',
            }}>
            </p>
          </div>
          </div>
            <div style={{ padding: '5px 0 5px 60px', height: 35, display: 'flex' }}>
              {/*<Button
              style={{ display: 'block', background: '#D9FBE7', width: 150, padding: '5px 0 5px 15px', color: '#27AE60', 'align-item': 'center',
                'border-radius': '2px', 'margin-buttom': '20px', 'margin-left': '20px', 'font-style': 'normal',
                'font-weight': 500, 'font-size': '10px', border: '1px solid #27AE60'
              }}
              // button should be able to download the image
              onClick={() => {
                const link = document.createElement('a');
                link.href = qrImage;
                link.download = 'qrCode.jpg';
                link.click();
              }}
            >
            Download in JPEG
            </Button>
            <FaDownload style={{
                color: '#27AE60', position: 'absolute', margin: '5px 0', top: 542, left: '95', width: 40, }}/>
                */}
            <Button
              style={{
                position: 'relative', display: 'block', width: 150, padding: '5px 0 5px 15px',
                color: '#0086E8', 'border-radius': '2px', 'margin-buttom': '20px', 'margin-left': '20px',
                'font-style': 'normal', 'font-weight': '500', 'font-size': '11px', 'line-height': '140%',
                background: '#F1F9FF', border: '1px solid #0086E8',
              }}
              // button should be able to download the image
              /*onClick={() => {
                const link = document.createElement('a');
                link.href = qrImage;
                link.download = 'qrCode.pdf';
                link.click();
              }}*/
              onClick={()=>downloadPdfDocument("divToDownload")}
            >
              Download in PDF
            </Button>
            {/*<FaDownload style={{
                color: '#0086E8', position: 'absolute', margin: '5px 0', top: 542, left: '265', width: 40,
              }}/>*/}
            </div>
        </Box>
      </Modal>
    </>
  );
}

export default GeneratedQr;
