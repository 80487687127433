import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { Input, Row, Button, Typography } from 'antd';
import style, { ForgotPasswordWrapper } from './style';
import loginLogo from '../../assets/images/logo.svg';
import { customBaseUrl, httpGet } from '../../store/http';
import { checkAcess } from '../../utils/helper';
const { Paragraph, Title } = Typography;

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let res;
    if (checkAcess(email)) {
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byPhone?phoneNumber=${email}`,
        true
      );
    } else {
      if (validator.isEmpty(email)) {
        Swal.fire('Oops!', 'Email cannot be empty', 'error');
        setLoading(false);
        return;
      }
      const url = undefined;
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byEmail?email=${email}`,
        true
      );
    }

    if (res.status) {
      setLoading(false);
      Swal.fire({ title: 'Done', text: res.message, icon: 'success' }).then(
        (result) => {
          if (result.isConfirmed) {
            localStorage.setItem('email', email);
           navigate('/reset_password');
          }
        }
      );
    } else {
      setLoading(false);
      Swal.fire('Oops!', res.message, 'error');
    }
  };

  return (
    <ForgotPasswordWrapper>
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh' }}
      >
        <div className='wrapper' style={{ width: '39%' }}>
          <img src={loginLogo} alt='reset-logo' style={style.login.left.logo} />
          <Title level={2} align='middle'>
            Forgot your password
          </Title>
          <Paragraph align='middle'>
            Fill in the text to request for a password reset. A link will be
            sent to your mail for a password reset.
          </Paragraph>

          <Input size='large' placeholder='abc@gmail.com' onChange={(e) => setEmail(e.target.value)} />

          <Button
            type='primary'
            htmlType='submit'
            size='large'
            block
            loading={loading}
            style={{ ...style.login.button, marginTop: '45px' }}
            onClick={handleSubmit}
          >
            Request for a password reset
          </Button>
        </div>
      </Row>
    </ForgotPasswordWrapper>
  );
}
