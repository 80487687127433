  import { Button, Input, Row, Space } from "antd";
  import { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import Layout from "../../components/Layout";
  import { useMatStyles } from "./style";
  import CustomTable from "../../components/CustomTable/index3";
  import styled from "styled-components";
  import {
    Box,
    TableCell,
    TableRow,
    Grid as MatGrid,
    Paper,
  } from "@mui/material";
  import { getComparator, stableSort } from "../../utils/helper";
  import { customBaseUrl, httpGet, httpPost, httpPut } from "../../store/http";
  import { hideLoader, showLoader } from "../../utils/loader";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import AntDropdown from "../../components/CustomDropdown/AntDropdown";
  import ExportData from "../WayaOfficial/modal/exportData";
  import ViewDocModal from "../../components/Modal/ViewDoc";
  import Swal from "sweetalert2";
import moment from "moment";
import { ExportOutlined } from "@ant-design/icons";
import CreateBranch from "./modals/createBranch";
import BranchList from "./branchList";
import CardType from "./cardType";
import CardProducts from "./products";
import { CSVLink } from "react-csv";
import Delivery from "./delivery";
  
  const Container = `styled.div`;
  
  
  
  const Tier =({id})=>{
    const [tier, setTier] = useState()
  
    useEffect(async()=>{
      const res = await httpGet(
        `${customBaseUrl.kycUrl}/kyc/fetch-status/${id}`
      );
        if  (res.status){
          hideLoader();
         // alert(res?.data?.status[0]?.tiers?.tiers)
           setTier(res?.data?.status[0]?.tiers?.tiers);
        }else{
          hideLoader();
          console.log(res.message);
        }
    },[])
  
    return(
      <h6>{tier}</h6>
    )
  }
  
  const CardsMainPage = () => {
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState({
      order: "asc",
      orderBy: "businessName",
    });
    const [search, setSearch] = useState({
      searchBy: "businessName",
      searchText: "",
    });
    const [metaData, setMetaData] = useState(0);
    const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
    const [pendingData, setPendingData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [stat, setStat] = useState([])
    const [tierStat, setTierStat] = useState([]);
    const [showExport, setShowExport] = useState(false);
    const [showCreateBranch, setShowCreateBranch] = useState(false);
    const [showBranchList, setShowBranchList] = useState(false);
    const [showCardType, setShowCardType] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const [showDelivery, setShowDelivery] = useState(false);
    const [searchField, setSearchField] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDate, setFilteredDate] = useState([]);
    const [showViewDocModal, setShowViewDocModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [field, setField] = useState(null);
    const [value, setValue] = useState(null);
    const styles = useMatStyles();
  
  
  
    const dropDownOptions = ["Update KYC", "View Doc"];
    const handleDropdownAction = (item, row) => {      
      if(item === "APPROVE") approveReject(row?.id, "APPROVED");
      if(item === "REJECT") approveReject(row?.id, "REJECTED");
      if(item === "BLOCK") handleBlock(row?.id);
      if(item === "UNBLOCK") handleUnblock(row?.id);
      if(item === "CANCEL") handleCancel(row?.userId);
  
    };

    const handleSettingsDropdown = (item) => {      
      if(item === "Create Branch") setShowCreateBranch(true);
      if(item === "REJECT") approveReject("REJECTED");
    };
  
    const approveReject = async (id, status) => {
      const res = await httpPost(
        `${customBaseUrl.cardUrl}/admin/card/approveOrReject/${id}?status=${status}`
      );
        if  (res.status === true){
          hideLoader();
          Swal.fire('Done', res?.message, 'success')
          // getCardStat();
          getCards();
        }else{
          hideLoader();
          Swal.fire('error', res?.message)
          console.log(res.message);
        }
    }
    const handleBlock = async (id) => {
      const res = await httpPut(
        `${customBaseUrl.cardUrl}/admin/admin-block-Card/${id}?reason=blocked_by_admin`
      );
        if  (res.status === true){
          hideLoader();
          Swal.fire('Done', res?.message, 'success')
          // getCardStat();
          getCards();
        }else{
          hideLoader();
          Swal.fire('error', res?.message)
          console.log(res.message);
        }
    }
    const handleUnblock = async (id) => {
      const res = await httpPut(
        `${customBaseUrl.cardUrl}/admin/admin-unblock-card/${id}`
      );    
        if  (res.status === true){
          hideLoader();
          Swal.fire('Done', res?.message, 'success')
          // getCardStat();
          getCards();
        }else{
          hideLoader();
          Swal.fire('error', res?.message)
          console.log(res.message);
        }
    }
    const handleCancel = async (id) => {
      const res = await httpPut(
        `${customBaseUrl.cardUrl}/admin/cancel-card`,
          [id],
      );    
        if  (res.status === true){
          hideLoader();
          Swal.fire('Done', res?.message, 'success')
          // getCardStat();
          getCards();
        }else{
          hideLoader();
          Swal.fire('error', res?.message)
          console.log(res.message);
        }
    }
    const columns = [
    
      { field: 'customerName',
        headerName: 'Name',
        width: 250 ,
        headerClassName: styles.tableHeader
  
      },
      { field: 'customerEmail',
        headerName: 'Email',
        width: 250 ,
        headerClassName: styles.tableHeader
  
      },
  
      { field: 'mobile',
        headerName: "Phone",
        width: 250 ,
        headerClassName: styles.tableHeader,
  
      },
      { field: 'state',
        headerName: "State",
        width: 250 ,
        headerClassName: styles.tableHeader,
  
      },
      { field: 'lga',
        headerName: "LGA",
        width: 250 ,
        headerClassName: styles.tableHeader,
  
      },
      { field: 'address',
        headerName: "Address",
        width: 250 ,
        headerClassName: styles.tableHeader,
  
      },
      { field: 'cardType',
      headerName: "Card Type",
      width: 200 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row}) => row?.cardType?? 'NA'
  
      },
      { field: 'accountNumber',
      headerName: "Account Numbr",
      width: 150 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.kycTier?.name.replace('_',' ')
      },

      /*{ field: 'dataType',
      headerName: "Data Type",
      width: 150 ,
      headerClassName: styles.tableHeader,
      // renderCell: ({row})=> row?.kycStatus?.processed==='true'?'Completed':row?.kycStatus?.processed==='false'?'Under Review':'Not Completed'
  
         
      },*/
      { field: 'dateCreated',
      headerName: "Date",
      width: 150 ,
      headerClassName: styles.tableHeader,
      // renderCell: ({row})=> moment(row?.dateCreated).format('DD-MM-YY')
      renderCell: ({row}) => `${row?.dateCreated[2]}/${row?.dateCreated[1]}/${row?.dateCreated[0]}` 

         
      },
      { field: 'status',
      headerName: "Status",
      width: 150 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.status.replace('_',' ')
         
      },
      
    
      {
        field: " ",
        headerName: "Action ",
        width: 80,
        align: "right",
        headerClassName: styles.tableHeader,
        renderCell: ({ row }) => (
          <AntDropdown
            dropDownOptions={[
              row?.status !=="APPROVED" && "APPROVE",
              row?.status !=="REJECTED" &&"REJECT",
              row?.cardActive && "BLOCK",
              !row?.cardActive && row?.status ==="APPROVED" && "UNBLOCK",
              row?.status !=="REJECTED" && row?.status !=="APPROVED" && "CANCEL",            
            ]}
            handleDropdownAction={(item) => handleDropdownAction(item, row)}
            userId={row}
            isArrow
          />
        ),
  
      },
       ];  
  
  
    const handleChangePage = async (newPage) => {
      setPagination({ ...pagination, current: newPage });
      // await getCards(newPage, newPage, pagination.pageSize);
  
    };
    const handleChangeRowsPerPage = async (e) => {
      console.log(e)
      setPagination({ ...pagination, current: 0, pageSize: e });
    //await getCards(e,1, e);
    };
    const handleOpenTierLevels = (Id) => {
      navigate(`/users_kyc/${Id}`);
    };
    const handleRequestSort = (event, property) => {
      const { order, orderBy } = orderData;
      const isAsc = orderBy === property && order === "asc";
      setOrderData(
        isAsc
          ? { order: "desc", orderBy: property }
          : { order: "asc", orderBy: property }
      );
    };
  
    const getPendingKYC = async () => {
      showLoader();
      const res = await httpGet(
        `${customBaseUrl.kycUrl}/kyc/count/totalPendingStatus`
      );
        if  (res.status){
          hideLoader();
          setPendingData(res.data);
        }else{
          hideLoader();
          console.log(res.message);
        }
     
  
      return [];
    };
  
    const getCardStat = async() =>  {
      let res = await httpGet(`${customBaseUrl.kycUrl}/api/v1/admin/kyc/analysis`)
      if(res.status){
        setStat(res.data)
      }
    }
    const getTierStat = async() =>  {
      let res = await httpGet(`${customBaseUrl.kycUrl}/kyc/tier/count-users-by-tier`)
      if(res?.status){
        setTierStat(res.data)
      }
    }
  
    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj
    }
  
    const getCards = async (e,page, size) => {
      e?.preventDefault();
      showLoader();
      const pages = page === 0 ? 0 : page ? page : pagination.current;
      const limit = size ? size : pagination.pageSize;
      
      let request = await httpPost(
        `${customBaseUrl.cardUrl}/card/request/all?page=${pages}&size=${limit}`
      );
      if (request?.status) {
        console.log(request)
        hideLoader();
        setUsersData(request?.data?.cardList)
        setMetaData(request?.data?.cardList?.totalElements);
      }else{
        console.log(request)
        hideLoader();
  
      }
      
     
    };
  
    
    useEffect(() => {
    
    }, []);
    useEffect(()=>{
      getCards()
    },[pagination?.pageSize, pagination.current]);
  
    // Avoid a layout jump when reaching the last page with empty rows.
    
  const category = ['customerId','status','name','email','phoneNumber']
  const sCategory = ['INITIATED', 'UNDER_REVIEW', 'PENDING', 'APPROVED', 'REJECTED']
  
    return (
      <Layout>
  
        { showBranchList ?
            <BranchList setShowBranchList={setShowBranchList}/> :
        showCardType ? <CardType setShowCardType={setShowCardType}/> :
        showProducts ? <CardProducts setShowProducts={setShowProducts}/> :
        showDelivery ? <Delivery setShowDelivery={setShowDelivery}/> :
          <Container>
  
          <div className={styles.searchRow}>
            {/*<select
              className={styles.selectType}
              defaultValue="disabled"
              onChange={(event) => {
                setSearch({ ...search, searchBy: event.target.value });
              }}
            >
              <option value="disabled" disabled>
                Search By...
              </option>
            
            </select>
            <div className={styles.searchRow}>
              <Input
                className={styles.searchInput}
                placeholder="Search data by Name, Email or Date"
                onChange={(event) => {
                  setSearch({ ...search, searchText: event.target.value });
                }}
                prefix={<SearchOutlined className={styles.searchIcon} />}
              />
            </div>*/}
          <div>
            <Button
                size="medium"
                className={styles.BranchList}
                onClick={() => setShowBranchList(true)}
              >
                Branch List
        </Button>
          </div>
          
          <div>
            <Button
                size="medium"
                className={styles.updateButton}
                onClick={() => setShowProducts(true)}
              >
                Product Type
            </Button>
          </div>
          <div>
            <Button
                size="medium"
                style={{ background: "#2760FF", fontSize: "10px", color: "#fff" }}
                onClick={() => setShowCardType(true)}
              >
                Card Type
        </Button>
          </div>
          <div>
            <Button
                size="medium"
                className={styles.updateButton}
                onClick={() => setShowDelivery(true)}
              >
                Delivery Fee
            </Button>
          </div>
          <Button
                size="medium"
                style={{ background: "#27AE60", fontSize: "12px", color: "#fff" }}
              >
            <CSVLink
          data={usersData ?? []}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`cards.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          Export
        </CSVLink>
          </Button>

          
          <div className={styles.grow} />
          <AntDropdown
            dropDownOptions={["Bulk Request","Single Request"]}
            handleDropdownAction={(item) => handleSettingsDropdown(item)}
            >
            <Button 
             className={styles.updateButton} size="medium">
              Card Request
            </Button>
          </AntDropdown>
          </div>
          <div className={styles.dashboardItem}>
            {/*<div className={styles.dashItem}>
              <div className={styles.diTop}>Total Number of Users</div>
              <div className={styles.dibot}>{stat?.totalUsers}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>Tier Levels</div>
              <div className={styles.dibot}>{stat?.totalTierLevels}</div>
            </div>
            {/*<div className={styles.dashItem}>
              <div className={styles.diTop}>Cards Pending for Approval</div>
              <div className={styles.dibot}>{stat?.pending}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>APPROVED</div>
              <div className={styles.dibot}>{stat?.approved}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>REJECTED</div>
              <div className={styles.dibot}>{stat?.rejected}</div>
            </div> */}
          </div>
          
          {/*<div>
          <Button 
          variant="contained" 
          style={{width: '100%', marginBottom: 10}}
          onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</Button>
        </div>*/}
        <div hidden={!searchField}>
          <form onSubmit={getCards}>
        <div class="row">
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Status</span>
          <select class="form-select" id="inputGroupSelect01" required
            onChange={(e)=>{ 
              setField(e.target.value);      
              setFilteredData({...filteredData, status: e?.target?.value})
              setPagination({...pagination, current: 0})
            
            }
            }
          >
            <option selected>Choose...</option>
            {
              category?.map((e,i)=>
              <option key={i} value={e}>{e.replace('_',' ').toUpperCase()}</option>
            )
            }
          </select>
        </div>
        {field === 'status'?<div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Value</span>
          <select class="form-select" id="inputGroupSelect01" 
            onChange={(e)=>{
              setValue(e.target.value)      
              setFilteredData({...filteredData, status: e?.target?.value})
              setPagination({...pagination, current: 0})
            
            }
            }
          >
            <option selected>Choose...</option>
            {
              sCategory?.map((e,i)=>
              <option key={i} value={e}>{e.replace('_',' ')}</option>
            )
            }
          </select>
        </div>:
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Value</span>
          <input  
          onChange={(e)=>setValue(e.target.value)}
          class="form-select" id="inputGroupSelect01" />
        </div>
          }
        {/*<div className="input-group mb-3 col">
            <span className="input-group-text">Transaction Type</span>
            <select class="form-select" id="inputGroupSelect02"
                onChange={(e)=>setFilteredData({...filteredData, partTranType: e?.target?.value})}
            >
            <option selected>Choose...</option>
            <option value={''}>ALL</option>
            <option value="D" >DEBIT</option>
            <option value="C">CREDIT</option>         
          </select>        
        </div>
         
          {/*<div class="col">
            <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
          </div>*/}
        </div>
        {/*<div className="row">
          
          <div class="input-group mb-3 col">
           <span class="input-group-text" id="basic-addon3">Reference Number</span>        
            <input type="text" aria-label="Ref" class="form-control"
              onChange={(e)=>setFilteredData({...filteredData, paymentReference: e?.target?.value})}
  
            />
          </div>
          <div class="input-group mb-3 col">
            <span class="input-group-text" id="basic-addon3">Transaction Id</span>        
            <input type="text" class="form-control"  aria-label="tranId"
              onChange={(e)=>setFilteredData({...filteredData, tranId: e?.target?.value})}
  
            />
          </div>
        </div>
        <div className="row">
        
          <div class="input-group mb-3 col">
           <span class="input-group-text" id="basic-addon3">Account Number</span>        
            <input type="text" aria-label="First name" class="form-control"
              onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
            />
          </div>
          <div class="input-group mb-3 col">
            <span class="input-group-text" id="basic-addon3">Email</span>        
            <input type="email" class="form-control"  aria-label="Email"
               onChange={(e)=>setFilteredData({...filteredData, createdEmail: e?.target?.value})}
            />
          </div>
        </div>
        <div className="row">
        
          <div class="input-group mb-3 col">
           <span class="input-group-text" id="basic-addon3">From</span>        
            <input type="date" aria-label="Date" class="form-control"
              onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
              required
            />
          </div>
          <div class="input-group mb-3 col">
            <span class="input-group-text" id="basic-addon3">To</span>        
            <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
               onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
            />
          </div>
        </div>*/}
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary"
          >Search</button>
        </div>
        </form>
        </div>
  
           
            
          <div style={{ height: 800, width: "100%" }}>
          {usersData?.length > 0 && <DataGrid 
           className={styles.table}
           density="comfortable"
          rows={usersData}
              columns={columns}
              page={pagination.current}
              pageSize={pagination.pageSize}
              onPageSizeChange={(newPageSize) =>
                handleChangeRowsPerPage(newPageSize)
              }
              paginationMode="server"
              onPageChange={(newPage) => handleChangePage(newPage)}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              pagination
              components={{ Toolbar: GridToolbar }}
              rowCount={metaData}       
          />}
    </div>
       
    {showViewDocModal ? (
          <ViewDocModal
            showModal={showViewDocModal}
            hideModal={setShowViewDocModal}
            data={viewData}
          />
        ) : (
          ""
        )}
        {showCreateBranch ? (
          <CreateBranch
            showModal={showCreateBranch}
            hideModal={setShowCreateBranch}
          />
        ) : (
          ""
        )}
        </Container>}
        {showExport &&
           <ExportData showModal={showExport} hideModal={setShowExport} dataList={usersData} file={"KYC users tier "} />
        }
      </Layout>
    );
  };
  export default CardsMainPage;
  const gridStyle = {
    width: "23%",
    textAlign: "left",
    backgroundColor: "#fff",
    padding: "24px 10px 24px 12px",
    fontWeight: "bold",
    marginLeft: "10px",
  };
  
  const tool = {
    display: "flex",
    borderRadius: "250px",
    justifyContent: "space-between",
    marginBottom: "30px",
  };
  