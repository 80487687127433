import React, { useState } from 'react';
import { Tabs, Button } from 'antd';

const Index = ({ children, totalTabs }) => {
  const [currentTab, setCurrentTab] = useState('1');

  // const dropDownItem2 = [
  //   { id: 'deleteAccounr', label: 'Delete Account', style: 'text-danger' },
  //   { id: 'makeDefault', label: 'Make Default' },
  // ];

  return (
    <>
       <div>
         {/* TODO ADD USER SVG HERE */}
       </div>
      <Tabs
      centered
      size='large'
        tabBarExtraContent={{
          // left: (
          //   <Button
          //     className='me-2'
          //     onClick={() => {
          //       if (currentTab === '1') return;
          //       setCurrentTab(`${Number(currentTab) - 1}`);
          //     }}
          //   >
          //     Left
          //   </Button>
          // ),
          // right: (
          //   <Button
          //     onClick={() => {
          //       if (currentTab === totalTabs) return;
          //       setCurrentTab(`${Number(currentTab) + 1}`);
          //     }}
          //   >
          //     Right
          //   </Button>
          // ),
        }}
        activeKey={currentTab}
        defaultActiveKey={currentTab}
        onChange={(e) => setCurrentTab(e)}
      >
        {children }
      </Tabs>
    </>
  );
};

export default Index;
