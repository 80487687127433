import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../store/http";
import { styled } from '@mui/material';
import qs from "qs";
import Swal from "sweetalert2";
import { Modal } from 'antd';
import { hideLoader, showLoader } from '../../utils/loader';


export default function EditTerm({ showModal, setShowModal,getTerms, content, id }) {
  const styles = useStyles();
  const [newTerm, setNewTerm] = useState('');



  const handleSubmit = async () => {
    showLoader();
    let data={
      content: newTerm
    }
  
 let response = await httpPut(`${customBaseUrl.contentManagementUrl}/terms-and-condition/${id}`, data)
    if (response.status === true) {
      setShowModal(false)
      getTerms();
      hideLoader();
      console.log('data', response.data)
      Swal.fire('success',response.message, 'done')

    } else {
      hideLoader();
      Swal.fire('Error', response.message, 'failed')

    }
  }


  useEffect(()=>{
    setNewTerm(content)
},[])


  return (
    <>
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          keyboard={false}
          size="md"
          centered
          footer={false}
          width={400}
        >
         
         <div className={styles.soWrapper}>

            <div className={styles.mTitle}>UPDATE TERM AND CONDITION </div>           
            <div className="label" style={{marginLeft:20}}>Terms and Conditions </div>
               <textarea style={{width:'90%', marginLeft:20}}
               onChange={(e)=>setNewTerm(e.target.value)}
                 value={newTerm}
                 />
                  <div style={{width:60, marginLeft:'30%', marginTop:20}}>
                <button className={styles.soButton} onClick={handleSubmit}>Save</button>
                </div>
          </div>


           
        </Modal>
    </>
  );
}
