import React,{useState, useEffect, useContext} from 'react';
import { Modal, Button } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import { hideLoader, showLoader } from '../../../utils/loader';
import Swal from 'sweetalert2';
import { customBaseUrl, httpPost, httpPostFormData, httpPostUnreloaded } from '../../../store/http';
import { useGridRegisterPipeProcessor } from '@mui/x-data-grid/hooks/core/pipeProcessing';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { emailValidator } from '../../../utils/helper';

const Transfer = ({ showModal, hideModal,  accountNo, getAccounts, accountName }) => {
 const { profile } = useContext(ProfileContext);

const [transferType, setTransferType] = useState('email');
const [fundingWallet, setFundingWallet] = useState('');
const [data, setData] = useState(
                                  {
                                     tranCrncy: "NGN",
                                     eventId: "COMPAYM",
                                   });

  let transferOption = [
    
    {
      title: "Transfer to Phone Number",
      value: "phone"
    },
   
    {
      title: "Transfer To Waya ID",
      value: "id"
    },
    {
        title: "Transfer To Email",
        value: "email"
      }
  ]


  let handleTransaction = async () => {
    let d= new Date();
    if (transferType==='email' && !emailValidator(data.email)) {
        Swal.fire('Wrong', 'Invalid email format', 'error');
        hideLoader();
        return;
      }

    showLoader();

    if (!data.amount ) {
      Swal.fire('Wrong', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }
    
    const emailData = {
        description: data.reason,
        amount: data.amount,
        senderId: profile.userId,
        senderAccountNumber: accountNo,
        beneficiaryEmail: data.email

      };
      const phoneData = {
        amount: data.amount,
        description: data.reason,
        senderId: profile.userId,
        senderAccountNumber: accountNo,
        beneficiaryPhoneNo: data.phoneNumber

      };
      const postData = {
        amount: data.amount,
        description: data.reason,
        senderId: profile.userId,
        senderAccountNumber: accountNo,
        beneficiaryId: data?.id

      };
  
      
      let response;

      if(transferType==='email'){
        /* response = await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/money/to/email/${data.email}`,
            emailData
          );*/
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
          {
            payload: JSON.stringify(emailData),
            "requestId": `REQ${d.getTime()}`,
            "requestType": "TRANSFERToEmail",
            customerName: accountName
      
          } )
      }else if(transferType === 'phone'){
        /*response = await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/money/to/phoneNumber/${data.phoneNumber}`,
            phoneData
          );*/
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
          {
            payload: JSON.stringify(phoneData),
            "requestId": `REQ${d.getTime()}`,
            "requestType": "TRANSFERToPhone",
            customerName: accountName
      
          } )
      }else if(transferType === 'id'){
        /* response = await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/money/to/userId/${data.id}`,
            postData
          );
         */
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
          {
            payload: JSON.stringify(postData),
            "requestId": `REQ${d.getTime()}`,
            "requestType": "TRANSFERToUserId",
            customerName: accountName
      
          } )
      }
  
      
   
    
    if (response.status) {
      hideLoader();
      hideModal(false);
      getAccounts();
      response?.data?.authorization_url?
        window.location.href = response?.data?.authorization_url:
      (Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      }));

    } else {
      hideLoader();
      console.log(response);
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };


  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: '23px 73px 44px 73px',
        borderRadius: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '27px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>


      </div>
      <Wrapper>
        <div className="title">Transfer From Account </div>
        <div className="cw-content">
          <div className="input-content">
            <div className="label">Transfer Method</div>
            <select style={{height: 40}} value={transferType} onChange={(e)=> setTransferType(e.target.value)} className='border border-dark rounded' >
              <option value = "" >select Transfer method</option>
              {Object.keys(transferOption).map((key, index) => 
                  <option key={index} value={transferOption[key].value}>
                    {transferOption[key].title}
                  </option>
              )}
            </select>
          </div>
  
        {transferType === "email" &&    
          <div className="input-content">
            <div className="label">Email</div>
            <input 
            type="text"
            value={data.email}  
            onChange={(e)=>setData({...data, email:e.target.value})}/>
          </div>
              }
               {transferType === "id" &&    
          <div className="input-content">
            <div className="label">Waya Id</div>
            <input 
            type="text"
            value={data.id}  
            onChange={(e)=>setData({...data, id:e.target.value})}/>
          </div>
              }
               {transferType === "phone" &&    
          <div className="input-content">
            <div className="label">Phone Number</div>
            <input 
            type="text"
            value={data.phoneNumber}  
            onChange={(e)=>setData({...data, phoneNumber:e.target.value})}/>
          </div>
              }
          <div className="input-content">
            <div className="label">Amount</div>
            <input 
            type="number"
            value={data.amount}  
            onChange={(e)=>setData({...data, amount:e.target.value})}/>
          </div>
          <div className="input-content">
            <div className="label">Description</div>
              <input 
              type="text"
              value={data.reason} 
              onChange={(e)=>setData({...data, reason: e.target.value})} />
          </div>
        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            background: '#FF4B01',
            color: '#fff',
            borderRadius: '3px',
            fontSize: '14px',
            lineHeight: '17px',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 25px',
          }}
          onClick={()=>handleTransaction()}
        >
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default Transfer;
