import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import { Button, Divider } from "antd";
import { Modal } from "react-bootstrap";
import useMatStyles from "../style";
import { getArraryString, transformName } from "../../../utils/helper";
const UpdateMenu = ({
  showModal,
  setShowModal,
  roles,
  privileges,
  setRoleData,
  roleData,
  handleSubmit,
  menus,
  totalPrivillages, pagination, handleLoadMore,
  setMenuData,
  menuData,
  setMenuId,
  selectedMenu
}) => {
  const styles = useMatStyles();

  const handleToggle = (value) => () => {
    console.log('value', value)
    const currentIndex = roleData.privileges.findIndex(
      (item) => item.id === value.id
    );
    const newChecked = [roleData.privileges];

    if (currentIndex === -1) {
      setMenuData(value)
      newChecked.push(value);
    } else {
      setMenuData([])
      newChecked.splice(currentIndex, 1);
    }

    setRoleData({ ...roleData, privileges: newChecked });
  };

  

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={true}
        size="md"
        centered
      >
        <Modal.Header
          title={"Create Custom Role"}
          style={{
            borderBottom: "none",
            padding: "2rem",
          }}
          closeButton
        />

        <Modal.Body
          style={{
            padding: "2rem",
          }}
        >
          <div className={styles.modalTitle}>Update {selectedMenu?.name}</div>
          <Divider />
          <div className="row">
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <div className='row'>
                  
                  {privileges.map((value, index) => {
                    return (
                      <div className="col-md-6">
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(value)}
                          dense
                        >
                          <ListItemIcon>
                            <Radio
                              edge="start"
                              name="radio-buttons"
                              checked={
                                roleData.privileges.findIndex(
                                  (item) => item.id === value.id
                                ) > -1
                              }
                              tabIndex={-1}
                              // disableRipple
                              inputProps={{
                                "aria-labelledby": value.description,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={value.id}
                            primary={transformName(
                              getArraryString([value.description])
                            )}
                          />
                        </ListItemButton>
                      </ListItem>
                      </div>
                    );
                  })}
                  </div>
                
                </List>
              </div>
              <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
    {pagination.current>0 &&<Button onClick={()=>handleLoadMore('back')}>Prev</Button>}
    {pagination.current<Math.floor(totalPrivillages/pagination.pageSize) &&  <Button onClick={()=>handleLoadMore('next')}>Next</Button>}
    </div>

    {/*<div className={styles.inputCont}>
                <div className={styles.inputLabel}>Select Unit</div>
                <select 
                    className='form-select border'
                    // value={data?.isActive}
                    //onChange={(e)=>setData({...data, isActive: e?.target?.value})} 
                    onChange={(e)=>setMenuId(e.target.value)}
                >
                <option>.....</option>
                {menus?.map(e=>
                <option value={e?.id}>{e?.name}</option>
                )}
                </select>
              </div>
              {/*<div className={styles.inputCont}>
                <div className={styles.inputLabel}>Select Access Type</div>
                <select 
                    className='form-select border'
                    // value={data?.isActive}
                    // </div>onChange={(e)=>setData({...data, isActive: e?.target?.value})}
                >
                <option>.....</option>
            </select>
              </div>*/}
              <div className={styles.inputCont}>
                <Button
                  onClick={handleSubmit}
                  style={{
                      width: "100%",
                  }}
                  className={styles.updateButton}
                  size="middle"
                >
                  Update
                </Button>
              </div>
              <div className={styles.inputCont}>
              <Button
                  onClick={() => setShowModal(false)}
                  style={{
                      width: "100%",
                  }}
                  className={styles.manageAdminButton}
                  size="middle"
                >
                 Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UpdateMenu;
