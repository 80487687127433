import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "./export";
import { Filter, FilterList, ThreeDRotation } from "@mui/icons-material";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import FilterLogs from "./filterLogs";
import moment from "moment";

const RequestLogs = () => {
  let roles= localStorage.getItem('roles')
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();

  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const styles = useMatStyles();
  const [logStat, setLogStat] = useState([])
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [totalRequest, setTotalRequest] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [filteredReq, setFilteredReq] = useState('');
  const [status, setStatus] = useState('All')
  const getRequest = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : page === 0 ? 0 : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let request;
    if(status==='All'){
    request = await httpGet(
      `${customBaseUrl.logUrl}/api/v1/requestlog/list?page=${pages}&size=${limit}`
    );}else{
       request = await httpGet(`${customBaseUrl.logUrl}/api/v1/requestlog/action/${status}`)   
      
    }
      console.log(request.data);
    if (request.status) {
      hideLoader()
      setAllRequests(request?.data?.requestLog)
      setTotalRequest(request?.data?.totalItems)
    }else{
      hideLoader();
      console.log(request)
    }
    hideLoader();
  };

  const getStat = async () => {
    let res = await httpGet(`${customBaseUrl.logUrl}/api/v1/requestlog/total-request`)

    if(res.status){
      setLogStat(res?.data)
    }
  }
  const handleFilters = async () => {
    if(!searchData.searchText || !searchData.searchDate[0]) return NotificationManager.error("Input field not completed")
    showLoader();
    
    let res = await httpGet(`${customBaseUrl.logUrl}/api/v1/requestlog/fromDate/${moment(searchData.searchDate[0]).format('YYYY-MM-DD')}/toDate/${moment(searchData.searchDate[1]).format('YYYY-MM-DD')}`)
    let row;
    if(res.status){
      row = res?.data?.filter(e=>e?.createdBy.toLowerCase().includes(searchData?.searchText?.toLowerCase()))
      console.log("log search", row);
      setFilteredReq(row);
      setShowFilter(false);
      hideLoader();
    }else{
      console.log(res);
      hideLoader();
    }
  }
  
  const handleClearFilter = () => {
    setFilteredReq('');
    setSearchData({
      searchText: ""
    });
  }
  
  useEffect(() => {
    getStat();
    getRequest();
}, [status]);

  

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getRequest(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getRequest(newPage, pagination.pageSize);
  };

  const actionOptions = ["Approve", "Reject", "Delete"]
const handleAction = async(e, id)=>{
 // if(roles.indexOf('ROLE_ADMIN_OWNER') !==-1  ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1 ||roles.indexOf('ROLE_ADMIN_APPROVAL') !==-1) {
  let postData ={
    action: e==='Approve'?'APPROVED': e==='Reject'?'Reject':'Delete' ,
    requestId: id
  }
  showLoader()
 
 let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/process-request`, postData)
  
  if(res.status){
    hideLoader();
    Swal.fire('Done', res.message)
    getRequest();
  }else{
    hideLoader()
    console.log(res)
    Swal.fire('Error', res?.message);
  }
/*}else{
  return NotificationManager.error('not authourize')
}*/
}

  var searchInput = useRef({});
  const columns = [
    
    { field: 'requestId',
      headerName: 'Request Id',
      editable: false,
      expandable: true,
      pinnable: true,
      width: 200,
      renderCell: ({ row }) => {
        const { requestId = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {requestId?.length > 20
              ? requestId.slice(0, -19) + "..."
              : requestId}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.requestId?.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row?.requestId}
              />
            </span>
          </div>
        );
      },
    },

    { field: 'createdBy',
      headerName: "Request By",
      width: 150 
    },
    { field: 'initiatorRole',
    headerName: "Role",
    width: 200 
    },
    { field: 'customerName',
    headerName: "Request For",
    width: 150 
    },
    { field: 'requestType',
    headerName: "Action Type",
    width: 250,
    renderCell: ({row}) => row.requestType.includes('TRANSFER')?"TRANSFER":row.requestType?.toLowerCase()?.includes('paymentrequest')?"PAYMENT REQUEST":
    row.requestType.includes('UPDATE')?"UPDATE PROFILE":row.requestType.includes('ACTIVATION')?"ACCOUNT ACTIVATION":
    row.requestType.includes('ADDWALLET')?"ADD WALLET":row.requestType.includes('ADDACCOUNT')?"CREATE ACCOUNT":
    row.requestType.includes('ADDBANKACCOUNT')?"ADD BANK ACCOUNT":row.requestType.includes('DELETEBANKACCOUNT')?"DELETE BANK ACCOUNT":
    row.requestType.includes('ADDCARD')?"ADD BANK CARD":row.requestType.includes('DELETECARD')?"DELETE BANK CARD":
    row.requestType.includes('addKycRequirement')?"Add KYC Requirement":
    row.requestType.includes('updatingKycRequirement')?"Update KYC Requirement":
    row.requestType.includes('BILLSPAYMENT')?"BILLS PAYMENT"
    :row.requestType === 'reversal' ? <div><Typography>Reverse-{row?.customerName}</Typography> 
                                          <Typography>{JSON.parse(row?.payload)?.tranId}</Typography>
                                          <Typography>NGN{JSON.parse(row?.payload)?.tranAmount}</Typography></div>:
    row.requestType
    },
   
    { field: 'dateCreated',
    headerName: "DATE",
    width: 150 
    },
    { field: 'status',
    headerName: "Status",
    width: 150 
    },
    { field: 'action ',
    headerName: "Action",
    width: 150,
    renderCell: ({row})=>   <AntDropdown
    dropDownOptions={actionOptions}
    handleDropdownAction={(e)=>handleAction(e,row?.requestId)}
    isArrow
   />
    }
  ];

  const filetrOp = ['APPROVED', 'REJECTED','Initiated', 'All']

  const handleFilter = (item)=>{
    setStatus(item)
  }
  
  return (
    <Layout>

<div style={tool}>
<Row type="flex" justify="start" align="middle">     
  {//<AntDropdown dropDownOptions={filetrOp} handleDropdownAction={handleFilter}>
  }
            <FilterList className="filter-icon" onClick={()=>setShowFilter(true)} />
  {//</div></AntDropdown>
  }
  </Row>
  <div>
<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        </div>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>
        <Row type="flex" justify="end" align="middle">     
        
        
            <Button
              style={{ fontSize: "10px", width:100, background: "#27AE60", color: "#fff" }}
              onClick={() => setShowExport(true)}

            >
              {" "}
              Export
            </Button>
           
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Total Request</p>
          <h2 className="text-left">{logStat?.totalRequest}</h2>
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle} className="sm-1">
          <p>Total Approved Request</p>
          <h2 className="text-left">{logStat?.totalApproved}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="sm-1">
          <p>Total Pending Request</p>
          <h2 className="text-left">{logStat?.totalInitiated}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="sm-1">
          <p>Total Rejected Request</p>
          <h2 className="align-bottom">{logStat?.totalRejected}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="sm-1">
          <p>Total Failed Request</p>
          <h2 className="align-middle">{logStat?.totalFailed}</h2>
        </Card.Grid>
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allRequests?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={filteredReq? filteredReq?.filter((request) =>
          request[search?.searchBy]
            ?.toLowerCase()
            ?.includes(search?.searchText?.toLowerCase())
        ):          
          allRequests?.filter((request) =>
          request[search?.searchBy]
            ?.toLowerCase()
            ?.includes(search?.searchText?.toLowerCase())
        )}
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalRequest}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests}  />
      }
    {
      showFilter &&
      <FilterLogs showModal={showFilter} hideModal={setShowFilter} clearFilter={handleClearFilter}  searchData={searchData} handleFilters={handleFilters} setSearchData={setSearchData}/>
    }
    </Layout>
  );
};

const gridStyle = {
  width: "18%",
  textAlign: "center",
  backgroundColor: "#fff",
  fontWeight: "bold",
  marginLeft: "10px",
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '30px',
  justifyContent:'space-between',
  flexDirection: 'column'
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default RequestLogs;
