import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge, Switch } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "../request-log/export";
import { Filter, FilterList, ThreeDRotation } from "@mui/icons-material";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import moment from "moment";

const FraudEvent = () => {
  let roles= localStorage.getItem('roles')
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();

  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const styles = useMatStyles();
  const [logStat, setLogStat] = useState([])
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [totalRequest, setTotalRequest] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [status, setStatus] = useState('All')
  const [ total, setTotal] = useState(0);
  const [resolved, setResolved] = useState(0);
  const [unResolved, setUresolved] = useState(0);
  const getRequest = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let request;
    request = await httpGet(
      `${customBaseUrl.fraudUrl}/api/v1/fraud-event`
    );
      console.log(request.data);
    if (request.status) {
      hideLoader()
      setAllRequests(request.data)
      setTotalRequest(request.data.length);
      getStat();
    }else{
      hideLoader();
      console.log(request)
    }
    hideLoader();
  };

  
  const getStat = async () => {
    if (allRequests?.length > 0) {
      setTotal(allRequests.length)
    const resolved = await allRequests?.filter(e=>!e.active)
    const unResolved = await  allRequests?.filter(e=>e.active)
    setResolved(resolved.length);
    setUresolved(unResolved.length);

    }
  }
  
  useEffect(() => {
    getRequest();
}, []);
useEffect(()=>{
  getStat();
},[allRequests])
  

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    //  await getRequest(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    // await getRequest(newPage, pagination.pageSize);
  };
  const handleToggle = async (row) => {
    showLoader();
    const data = {
      "eventRuleName": row?.eventRuleName,
      "userId": row?.userId
    }
   let res = await httpPost(`${customBaseUrl.fraudUrl}/api/v1/fraud-event/undo-action`,
   data
   );
   if (res?.status) {
    hideLoader();
    Swal.fire('Done', res?.message )
    getRequest();
   }else {
    Swal.fire('Error', res?.message )
    console.log(res);
    hideLoader();
   }
   hideLoader();

  }

  const columns = [    
    

    { field: 'name',
      headerName: "Name",
      width: 250,
      headerClassName: styles.tableHeader,

    },
    { field: 'emailAddress',
    headerName: "User Email",
    headerClassName: styles.tableHeader,
    width: 250 
    },
    { field: 'phoneNumber',
    headerName: "Phone Number",
    headerClassName: styles.tableHeader,
    width: 200 
    },
    { field: 'eventRuleName',
    headerName: "Rule Violated",
    width: 250,
    headerClassName: styles.tableHeader
    },
    { field: 'active',
    headerName: "Status",
    width: 250,
    headerClassName: styles.tableHeader,
    renderCell: ({row}) => row?.active ? "Unresolved" : "Resolved"
    },
   
    { field: 'createdAt',
    headerName: "DATE",
    headerClassName: styles.tableHeader,
    width: 200,
    renderCell: ({row}) =>  moment(row?.createdAt).format('DD-MM-YY HH:MM:SS')
    },
    
    { field: 'tog',
    headerName: "Action",
    headerClassName: styles.tableHeader,
    width: 300,
    renderCell: ({row}) => <Switch style={{ float: "right" }} disabled ={!row.active}  checked={row?.active} onClick={()=>handleToggle(row)} />
    },
    
   
  ];

  return (
    <>
      <Card style={{ background: "transparent" }}>
      <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>All Fraud Events</p>
          <h2 className="text-left">{allRequests.length}</h2>
        </Card.Grid>  
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Resolved Fraud Events</p>
          <h2 className="text-left">{resolved}</h2>
        </Card.Grid>  
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Unresolved Fraud Events</p>
          <h2 className="text-left">{unResolved}</h2>
        </Card.Grid>        
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allRequests.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid
         getRowId={row=>row.eventId}
         className={styles.table}
         density="comfortable"
        rows={allRequests}
            columns={columns}
            page={pagination.current}
          //  paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalRequest}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests}  />
      }
    
    </>
  );
};

const gridStyle = {
  width: "18%",
  textAlign: "center",
  backgroundColor: "#fff",
  fontWeight: "bold",
  marginLeft: "10px",
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '30px',
  justifyContent:'space-between',
  flexDirection: 'column'
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default FraudEvent;
