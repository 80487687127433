import JsFileDownloader from "js-file-downloader";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "./loader";

export const downloadFile = (url) => {
    console.log("url", url);
    showLoader();
    const httpLink = url;
      new JsFileDownloader({ 
        url: httpLink,
        headers: [
          { name: 'Authorization', value: localStorage.getItem("token") },

        ],
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        contentTypeDetermination: 'full'
      })
      .then(function () {
       
        hideLoader();
      })
      .catch(function (error) {
        hideLoader();
        Swal.fire("Couldnt Download", error, "error");
      });
      
}