import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';

const NewBonusAmount = ({ showModal, hideModal,setAmount, setDescription,amount, description, className, handleCreateNew}) => {

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
        <div class='col-12 text-center font-weight-bold'>
        </div>
       
       
        <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
          <label for='validationCustom05' class='form-label'>
            Amount to receive when referal requirement is met
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom05'
            value={amount}
            required
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'left', alignItems:'left', alignContent:'left'}}>
          <label for='validationCustom05' class='form-label'>
            Description
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom05'
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        
        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleCreateNew}>
            Save
          </button>
        </div>
    </Modal>
  );
};

export default NewBonusAmount;
