import React, { useState } from 'react';
import frontArrow from '../../assets/images/front_arrow.svg';
import backArrow from '../../assets/images/back_arrow.svg';
import check from '../../assets/images/check.svg';
import upload from '../../assets/images/upload.svg';
import pending from '../../assets/images/pending.svg';
import blocked from '../../assets/images/blocked.svg';
import { useEffect } from "react";
import { getSentenceCase } from '../../utils/helper';
import StatImage from './StatImage';
// import { NotificationManager } from 'react-notifications';
export default function Tier3({
  handlePage,
  page,
  setShowViewDocModal,
  setName,
  hideModal,
  kycStatus,
  setViewData,
  handleUpdateStatus,
  handleAprove,
  handleReject,
}) {

  const [id, setId] = useState(null);
  const [passport, setPassport] = useState(null);
  const [ utility, setUtility] = useState(null);
  const [idType, setIdType] = React.useState('userInternationalPassport');
  let uncompleted;
 
  useEffect(() => {
    console.log('kycStatus1', kycStatus);
    const passport1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'PHOTO'
    )[0];
    const id1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'ID'
    )[0];
    const utility1 = kycStatus?.tier3?.filter(
      (e) => e?.requirementType === 'UTILITY'
    )[0];
        setPassport(passport1);
        setId(id1);
        setUtility(utility1);
        console.log('fff',id1);

  }, [])
  //delete kycStatus1.userCAC;
  let {userCAC, userInternationalPassport, userPassportPhotograph, userDriversLicense, userNationalId,userVotersCard, usersUtilityBill} = kycStatus || {};
  let selectedId=0;// = kycStatus[idType];
 // setIdType(kycStatus?.userInternationalPassport?.adminStatus==='APPROVED'?'userInternationalPassport':kycStatus?.userDriversLicense?.adminStatus?'userDriversLicense':kycStatus?.userNationalId?.adminStatus?'userNationalId':kycStatus?.userVotersCard?.adminStatus?'userVotersCard':'')
 //Object.keys(kycStatus).find(key => kycStatus[key].adminStatus === 'APPROVED'); 
 return (
    <div className='tier' id='tier3'>
      {/* <div className='head my-5'>Upgrade KYC Level</div> */}
      <div className='info my-3'>
        <p className='info-1'>Tier 3 </p>
        <div className='info-2'>
          <p className='info-2-t'>status</p>
          <p
            className={
              passport?.status === 'APPROVED' && 
             id?.status === 'APPROVED' &&
              utility?.status === 'APPROVED'             
                ? 'info-2-b px-3 py-1'
                : 'info-2-b-false px-3 py-1'
            }
          >
            { passport?.status === 'APPROVED' && 
             id?.status === 'APPROVED' &&
              utility?.status === 'APPROVED' ? 'Completed'
              : 'Not Completed'}
          </p>
        </div>
      </div>
      <div className='table my-2'>
        <table className='w-100'>
          <thead>
            <tr className=''>
              <th className='p-2'>Kyc Requirement</th>
              <th className='p-2 w-20'>Status</th>
              <th className='p-2 w-20'>Action </th>
            </tr>
          </thead>
          <tbody>
          <tr className=''>
                    <td className='p-3'>Passport  </td>
                    <td>
                      <div
                        className={
                          passport?.status === 'APPROVED' ? 'status-true':
                          passport?.status === 'REJECTED'? 'status-false':
                          'status-pending'
                        }
                      >
                        <img
                          src={
                            passport?.status === 'APPROVED'? check:
                            passport?.status === 'REJECTED'? blocked:
                            pending
                          }
                          alt=''
                          className={`me-2 ${passport?.status === 'APPROVED'
                            ? 'status-true':
                            passport?.status === 'REJECTED'?'status-false'
                            : 'status-pending'
                            }`}
                        />
                        <span>
                          {passport?.status}
                        </span> 
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {passport?.attachment ? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${passport?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          hideModal(true);
                          setName('PASSPORT_PHOTO');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {passport?.status === 'APPROVED' ? '  Uploaded' : passport?.status === 'REJECTED'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: passport?.attachment,
                            title: 'Passport',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      {/*<button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${passport?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleUpdateStatus('APPROVED', 'PASSPORT_PHOTOGRAPH')}
                        
                      >
                     
                        {/* Approve *//*}
                        {passport?.status === 'APPROVED' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleUpdateStatus('REJECTED', 'PASSPORT_PHOTOGRAPH') }
                      >
                        {passport?.status === 'REJECTED' ? 'Rejected' : 'Reject'}
                        </button>*/}
                    </td>
                  </tr>


                  <tr className=''>
                    <td className='p-3'>
                      <select value={idType} onChange={(e)=>setIdType(e.target.value)}>
                        <option value='userDriversLicense'>Drivers License</option>
                        <option value='userNationalId'>National Id</option>
                        <option value='userVotersCard'>Voters Card</option>
                        <option value='userInternationalPassport'>International Passport</option>
                      </select>
                    </td>
                    <td>
                      <div
                        className={
                          id?.status === 'APPROVED'? 'status-true':
                          id?.status === 'REJECTED'? 'status-false':
                          'status-pending'
                        }
                      >
                        <img
                          src={
                            id?.status === 'APPROVED' ? check:
                            id?.status === 'REJECTED'? blocked:
                            pending
                          }
                          alt=''
                          className={`me-2 ${id?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>
                          {id?.status}
                        </span> 
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {id?.attachment ? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${id?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          hideModal(true);
					            	setName(idType.replace("user", " "));    
                                          }}
                       >
                        <img src={upload} alt='' className='me-2' />
                        <span> {id?.status === 'APPROVED' ? '  Uploaded' : id?.status === 'REJECTED'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: id?.attachment,
                            title: getSentenceCase(idType.replace("users", "").replace("user", "").trim()).toLocaleUpperCase(),
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      {/*<button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${id?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleUpdateStatus('APPROVED', selectedId.fileName)}
                        
                      >
                     
                        {/* Approve *//*}
                        {id?.status === 'APPROVED' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleUpdateStatus('REJECTED', selectedId.fileName) }
                      >
                        {id?.status === 'REJECTED' ? 'Rejected' : 'Reject'}
                        </button>*/}
                    </td>
                  </tr>

                  <tr className=''>
                    <td className='p-3'>Utility Bill on the name </td>
                    <td>
                      <div
                        className={
                          utility?.status === 'APPROVED'? 'status-true':
                          utility?.status === 'REJECTED'? 'status-false':
                          'status-pending'
                        }
                      >
                        <img
                          src={
                            utility?.status === 'APPROVED'? check:
                            utility?.status === 'REJECTED'? blocked:
                            pending
                          }
                          alt=''
                          className={`me-2 ${utility?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>
                          {utility?.status}
                        </span> 
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {utility?.attachment? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${utility?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          hideModal(true);
                          setName('UTILITY_BILL');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {utility?.status === 'APPROVED' ? '  Uploaded' : utility?.status === 'REJECTED'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: utility?.attachment,
                            title: 'UTILITY_BILL',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      {/*<button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${utility?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleUpdateStatus('APPROVED', 'UTILITY_BILL')}
                        
                      >
                     
                        {/* Approve *//*}
                        {utility?.status === 'APPROVED' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleUpdateStatus('REJECTED', 'UTILITY_BILL') }
                      >
                        {utility?.status === 'REJECTED' ? 'Rejected' : 'Reject'}
                      </button>*/}
                    </td>
                  </tr>
          </tbody>
        </table>
      </div>
      <div className='tier-foot mb-2'>
        <div className='tier-foot-2 me-0'>
          <div
            className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('previous');
            }}
            
          >
            <img className='me-2' src={backArrow} alt='' /> Previous
          </div>{' '}
          <div className='page mx-1'>{page}</div>{' '}
          <div
            className={`${    passport?.status !== 'APPROVED' && 
            utility?.status !== 'APPROVED' &&
            id?.status !== 'APPROVED'  || page === 4  
            ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('next');
            }}
          >
            Next 
            <img className='ms-2' src={frontArrow} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
