import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const success = (title, message = '') =>
  Toast.fire({
    icon: 'success',
    title,
    text: message,
  });

const error = (title, message = '') =>
  Toast.fire({
    icon: 'error',
    title,
    text: message,
  });

const info = (title, message = '') =>
  Toast.fire({
    icon: 'info',
    title,
    text: message,
  });

const warning = (title, message = '') =>
  Toast.fire({
    icon: 'warning',
    title,
    text: message,
  });

const swalService = { success, error, info, warning };
export default swalService;
export { success, error, info, warning };
