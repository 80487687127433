import React, { useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { customBaseUrl, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";

const NotificationEmail = ({ showModal, hideModal, getWallets, account }) => {
  const [data, setData] = useState({});

  const handleCreate = async () => {
    showLoader();
    
    let d = new Date();
      let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({...data, accountNumber: account}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "updateNotificationEmail",
        customerName: 'Official Wallet'
      } )

    if (res.status === true) {
      hideModal(false)
      getWallets();
      Swal.fire("success", res.message, "success");
      hideLoader();
    } else {
      Swal.fire("error", res.message, "error");
      hideLoader();
    }
  };
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="cw-content">
          <div className="input-content">
            <div className="label">Email</div>

            <input
              value={data?.tranNarration}
              onChange={(e) =>
                setData({ ...data, email: e.target.value })
              }
            />
          </div>        
         
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleCreate}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationEmail;
