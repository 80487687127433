import styled from 'styled-components';

export const TransferWrapper = styled.div`
  .wrapper {
    margin-top: 60px;
    .button-cont {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 5px;
      width: 45%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 28px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 45px;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #1c1c1c;
    }
  }

  .tabBar {
    background: #faf3f3;
    border-radius: 30px;
    width: 60%;
    height: 46px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    .tabBar-item {
      border-radius: 30px;
      height: 100%;
      width: calc(100% / 3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      cursor: pointer;
    }
    .tB-active {
      background: #ff4b01;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }

  .contentBar {
    width: 50%;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 43px;
    input,
    select {
      background: #f5f5f5;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      width: 100%;
      padding: 14px;
      font-size: 14px;
      line-height: 17px;
      color: #828282;
    }
    button {
      background: #ff4b01;
      border-radius: 3px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      width: 100%;
      padding: 13px;
    }
    .input-detail {
      font-size: 10px;
      color: #b6b3b3;
    }
  }

  .backButton {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      border: 1px solid #303030;
      box-sizing: border-box;
      border-radius: 3px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 56px;
      width: 167px;
      height: 51px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #303030;
      text-align: center;
    }
  }
`;
