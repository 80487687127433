import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Button, notification } from "antd";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { customBaseUrl, httpPost, httpPut } from "../../../../store/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import useMatStyles from "../style";
const CreateCommission = ({
  showModal,
  setShowModal,
  getCommissions,
  allReferrals,
  tranType,
  tranName,
  amountType,
  debitSrc,
}) => {
  const styles = useMatStyles();

  const [customAmount, setCustomAmount] = useState('')

  const [postData, setPostData] = useState({
  amountEarnedByWaya: 0,
  amountForFixed: 0,
  amountType: "FIXED",
  cappedAmount: 0,
  description: "",
  percentageCustomerToEarn: 0,
  percentageWayaToEarn: 0,
  recurrent: true,
  referralTypeID: [],
  transactionName: "",
  transactionType: "",
  chargeDebitSource: ""
  });
  const [isCorp, setIsCorp] = useState(false);

  useEffect(()=>{
    // setPostData(commissionData);
  },[])
 
  const handleUpdate =async () =>{
    if(!postData?.transactionName) return notification.error('Name cannot be blank');
    showLoader();
    const url = `${customBaseUrl.referralUrl}/api/v1/commission_setup/add`;
    const response = await httpPost(url, postData);
    if (response.status === true) {
      setShowModal(false);
      Swal.fire('Done', response.message)
      getCommissions();
      hideLoader();
    } else {
      setShowModal(false);
      Swal.fire('Error', response.message)
      hideLoader();
    }
  }

  return (
    <>
      <Dialog  open={showModal} onClose={() => {setShowModal(false); setPostData({})}}>
        <DialogTitle>Create new Commission</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-12">
            <div style={{ marginTop: "10px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Description</div>
                <input 
                  value={postData.description}
                  className={`${styles.inputInput} border`}
                  onChange={(e)=>{setPostData({...postData, description: e?.target.value})}}
                />        
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Transaction Name</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    transactionName: e.target.value,
                  })}
                  value={postData.transactionName}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>
                  {tranName?.map(e=> 
                  <option value={e} key={e}>{e}</option>
                )}
                </select>
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Debit Source</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    chargeDebitSource: e.target.value,
                  })}
                  value={postData.chargeDebitSource}
                  className={`${styles.inputInput} border`}
                >
                   <option  selected>...</option>
                  {debitSrc?.map(e=> 
                  <option value={e} key={e}>{e}</option>
                )}             
                </select>
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Transaction Type</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    transactionType: e.target.value,
                  })}
                  value={postData.transactionType}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>
                  {tranType?.map(e=> 
                  <option value={e} key={e}>{e}</option>
                )}
                </select>
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Amount Type</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    amountType: e.target.value,
                    amountForFixed: 0,
                    percentageCustomerToEarn: 0,
                    amountEarnedByWaya: 0,
                    percentageWayaToEarn: 0
                  })}
                  value={postData.amountType}
                  className={`${styles.inputInput} border`}
                >
                   <option  selected>...</option>
                  {amountType?.map(e=> 
                  <option value={e} key={e}>{e}</option>
                )}             
                </select>
              </div>

              {postData?.amountType === 'FIXED' ? <>
              <div style={{ marginTop: "15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Amount for Fixed</div>
                <input
                  type="number"
                  value={postData.amountForFixed}
                  onChange={(e)=>{setPostData({...postData, amountForFixed: Number(e?.target.value)})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Amount Earned by Waya</div>
                <input
                  type="number"
                  value={postData.amountEarnedByWaya}
                  onChange={(e)=>{setPostData({...postData, amountEarnedByWaya: Number(e?.target.value)})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              </> : <>
              <div style={{ marginTop: "15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Percentage Earned by Customer</div>
                <input
                  value={postData.percentageCustomerToEarn}
                  type="number"
                  onChange={(e)=>{setPostData({...postData, percentageCustomerToEarn: Number(e?.target.value)})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Percentage Earned by Waya</div>
                <input
                  value={postData.percentageWayaToEarn}
                  type="number"
                  onChange={(e)=>{setPostData({...postData, percentageWayaToEarn: Number(e?.target.value)})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              </>}
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Capped Amount</div>
                <input
                  type="number"
                  value={postData.cappedAmount}
                  onChange={(e)=>{setPostData({...postData, cappedAmount: Number(e?.target.value)})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Referral Type</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    referralTypeID: [Number(e.target.value)]
                  })}
                  // value={postData?.referralTypeId ?? null}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>  
                  {allReferrals?.map(e=>
                    <option value={e?.referralTypeId} key={e?.referralTypeID}>
                      {e?.referralDescription}
                    </option>
                  )}              
                </select>
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Recurrent</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    recurrent: e.target.value? true : false,
                  })}
                  value={postData.recurrent}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>  
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              <div style={{
                  alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
              }} className={styles.inputCont}>
                <Button onClick={handleUpdate} className={styles.filledButton}>
                  Create
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CreateCommission;
