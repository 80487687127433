import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../components/Layout";
import noDataLogo from "../../assets/images/no-data.png";
import color from "../../theme/color";
import { customBaseUrl, httpDelete, httpGet } from "../../store/http";
import { styled } from "@mui/material/styles";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import  useMatStyles  from "./styles";
import { transformName } from "../../utils/helper";
import { hideLoader, showLoader } from "../../utils/loader";
import { useTheme } from "styled-components";
import TemporalPeriodModal from "../../components/Modal/TransferToNonWayaUser/TemporalPeriod";
import SendOtp from "./sendOtp";

const Otp = () => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [metadata, setMetadata] = useState(0);
  const [allOtp, setAllOtp] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [showSendOtp,setShowSendOtp] = useState(false);

  const getAllOtp = async (page, size) => {
    setAllOtp([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/get-all-otp`
    );
      console.log(request.data);
    if (request.status) {
      setAllOtp(request?.data);
      setMetadata(request?.data?.length);
    }
    hideLoader();
  };


  useEffect(() => {
    getAllOtp();
}, []);



  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
   // await getAllOtp(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
 //   await getAllOtp(newPage, pagination.pageSize);
  };



  var searchInput = useRef({});
  const columns = [
    
    { field: 'email',
      headerName: 'RECEIVER',
      width: 150,
      renderCell: ({row})=>row?.phoneNumber?row?.phoneNumber:row?.email
    },

    { field: 'code',
      headerName: "OTP RECEIVED",
      width: 250 
    },
    { field: 'requestType',
    headerName: "TYPE",
    width: 250,
    renderCell: ({row})=> row?.requestType.replace(/_/g," ")
    },
    { field: 'valid',
    headerName: "STATUS",
    width: 150 
    },
   
    { field: 'createdAt',
    headerName: "EXPIRY DATE",
    width: 150 ,
    renderCell: ({row})=> `${row?.expiryDate?.dayOfMonth}-${row?.expiryDate?.monthValue}-${row?.expiryDate?.year} ${row?.expiryDate?.hour}:${row?.expiryDate?.minute}:${row?.expiryDate?.second}`
    }

  ];

  
  return (
    <Layout>

<div style={tool}>
{/*<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>*/}

        <Row type="flex" justify="end" align="middle">
             
          <Button
          onClick={()=>setShowSendOtp(true)}
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined/>
              Send OTP
            </Button>    
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>Total OTPs Sent</p>
          <h2 className="text-left">{metadata}</h2>
        </Card.Grid>
       
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allOtp?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allOtp}
            columns={columns}
            page={pagination.current}
            //paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metadata}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      {showSendOtp&& <SendOtp
                      setShowModal={setShowSendOtp}
                      showModal={showSendOtp}
                      className={styles.modal}
                      />  
    }
    </Layout>
  );
};

const gridStyle = {
  width: "23%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Otp;
