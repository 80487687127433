import React, { useEffect, useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NotificationManager } from "react-notifications";
import { grey } from "@mui/material/colors";
const CopyToClipboardComponent = ({ text, style = null, showText= true }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied === true) {
      NotificationManager.info(`${text} Copied to Clipboard`, "Copied", 3000);
      setCopied(false);
    }
  }, [copied]);
  return (
    <>
      <div style={  style === null ?{
          fontFamily:'inherit',
          fontWeight:'700',
          fontSize:'18px'
      }: style}>
        {" "}
        { showText? text: '' }
        <span>
          <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
            <ContentCopyOutlinedIcon style={{
                marginLeft:'12px',
                fontSize:'15px',
                color:    `${grey[600]}`,
                marginTop:'-3px',
                cursor:'pointer'
            }} />
          </CopyToClipboard>
        </span>
      </div>
    </>
  );
};
export default CopyToClipboardComponent;
