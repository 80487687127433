import { Dialog, DialogTitle, List, ListItem, ListItemText } from "@mui/material";




const ListChooserModal = ({
    isOpen,
    setIsOpen,
    options,
    setSelectedItem,
    handleClick,
    title = "Make a Selection",
}) => {
    const handleListItemClick = (item) => {
        setIsOpen(false);
        handleClick(item.value, item.label);
    }
    return (
        <>
        <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {options.map((item) => (
          <ListItem button onClick={() => handleListItemClick(item)} key={item.value}>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  
        </>
    )
};
export default ListChooserModal;