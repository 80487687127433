import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge, Statistic } from "antd";
import qs from "qs";
import Swal from "sweetalert2";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined, SettingFilled } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost, httpPut } from "../../../store/http";

import { styled } from "@mui/material/styles";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import DeactivateUsers from "../../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { currencyFormat, transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import CreateBulkForm from "../../../components/Modal/User/CreateBulkForm";
import SendSingleUserModal from "../../../components/Modal/TransferToNonWayaUser/SendSingleUser";
import BulkTransfer from "../../../components/Modal/TransferToNonWayaUser/BulkTransfer";
import NonWayaWebForm from "../../../components/Modal/TransferToNonWayaUser/WebForm";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "../../WayaOfficial/modal/exportData";
import TemporalPeriodModal from "../../../components/Modal/TransferToNonWayaUser/TemporalPeriod";
import CommissionExcelUpload from "./excelUpload";
import CHangePayout from "./changePayOut";
import moment from "moment";

const Commission = () => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showSendUser, setShowSendUser] = useState(false);
  const [showTransferExcel, setShowTransferExcel] = useState(false);
  const [showTransferForms, setShowTransferForms] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [showPayoutDate, setShowPayoutDate] = useState(false)
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [selectedRow, setSelectedRow] = useState([]);
  const [showManual, setShowManual] = useState(false)
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [allTransfers, setAllTransfers] = useState(0);
  const [retrievedTransfers, setRetrievedTransfers] = useState(0);
  const [nonRetrievedTransfers, setNonRetrievedTransfers] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [reversalDays, setReveversalDays] = useState([]);
  const [stat, setStat] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState('')
  const [comOption, setComOpt] = useState('history');
  const [overall, setOverall] = useState([]);
  const history = useNavigate()

  const getTransactions = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpPost(
      `${customBaseUrl.referralUrl}/api/v1/commission_report/fetchAllTransactionCommissions`,
      {
        pageNo: pages,
        pageSize: limit,
      }
    );
    
    if (request?.status) {
      setAllRequests(request?.data?.trans);
      setAllTransfers(request?.data?.totalItems);
    }
    hideLoader();
  };

  const getStatistic = async () => {
    setAllRequests([]);
    showLoader();
   
    
    let request = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/commission_report/analysis`
    );
    
      console.log('lll',request?.data?.content);
    if (request?.status) {
      setStat(request?.data);
    }
    hideLoader();
  };
  
  const fetchOverview = async () => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/overall-based-analysis`
    );
    
    if (res?.status) {
      //setFiltered(response.data.content)
      setOverall(res?.data);
      hideLoader();
    } else {
      console.log(res)
      hideLoader();   
     }

     };

  useEffect(() => {
    getStatistic();
    getTransactions();
    // fetchOverview();
}, []);

  const SendUserOptions = [
   ,
    "Upload Excel File to Send",
  ];

  const handleSendUserDropdownAction = (item) => {
    if (item === "Upload Excel File to Send") setShowTransferExcel(true);
  };
 
  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };

const handleChangePayout=async(userType)=>{
  setShowPayoutDate(true)
  setSelectedUserType(userType)

}

const handleManualSettlement = async (row) => {
  showLoader();
  const res = await httpPut(`${customBaseUrl.referralUrl}/api/v1/commission_report/makeManualPayment?transactionId=${row?.id}`)
  if(res?.status){
    hideLoader();
    Swal.fire('done', res?.message);
  }else{
    hideLoader();
    Swal.fire('error', res?.message);
  }
}

const dropDownOptions = [ "Settle Manually"];
  const handleDropdownAction = (item, row) => {
    if (item === "Settle Manually") {
      handleManualSettlement(row);
      //  setShowManual(true);
    }
  };

  var searchInput = useRef({});
  const columns = React.useMemo(()=>[

    { field: 'customerName',
      headerName: 'Customer Name',
      width: 250,
      renderCell: ({row}) => row?.customerName ?? 'N/A'
    },
    { field: 'customerEmail',
      headerName: 'Customer Email',
      width: 250,
      renderCell: ({row}) => row?.customerEmail ?? 'N/A'
    }, 
    { field: 'referralName',
      headerName: 'Refferal Name',
      width: 200,
      renderCell: ({row}) => row?.referralName ?? 'N/A'

    },
    { field: 'commissionType',
      headerName: 'Commission Type',
      width: 200,
      renderCell: ({row}) => row?.commissionType ?? 'N/A'

    },
     { field: 'commission',
      headerName: 'Customer Commission Amount',
      width: 250,
    },
     { field: 'cappedAmount',
      headerName: 'Capped Amount',
      width: 250,
    },  
     
     { field: 'chargeAmount',
      headerName: 'Charge Amount',
      width: 150,
      renderCell: ({row}) => row?.chargeAmount ?? 'N/A'

    },  
     { field: 'chargeDebitSource',
      headerName: 'Debit Source',
      width: 150,
      renderCell: ({row}) => row?.chargeDebitSource ?? 'N/A'

    },
    { field: 'transactionAmount',
      headerName: 'Transaction Amount',
      width: 150,
      renderCell: ({row}) => row?.transactionAmount ?? 'N/A'

    },
    
     { field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: ({row}) => row?.status

    },    
    {// field: 'aggregatorPhone',
     // headerName: 'Aggregator Phone',
     // width: 250 
    },
    { field: 'createdAt',
      headerName: 'Date',
      width: 150,
      renderCell: ({row}) => moment(row?.createdAt).format('DD/MM/YYYY')

    },
    {
      field: "id",
      headerName: "Action ",
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={dropDownOptions}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    }
    /*{ field: 'disburse',
      headerName: 'Commission Disbursed Date',
      width: 250 
    },

    { field: 'commision',
      headerName: "Commission to Waya",
      width: 250 
    },
  
    { field: 'commissionValue',
    headerName: "Commision to Agent",
    width: 200 
    },
    { field: 'tokenId',
    headerName: "Commission to Aggregator",
    width: 250 ,
    renderCell: ({row})=> (JSON.parse(row?.jsonRequest))?.customerAccountNumber
    },
   
    /*{ field: 'action',
    headerName: "Action",
    width: 150,
    renderCell: ({row})=> <div onClick={()=>handleChangePayout(row.userType)}>Change payout date</div>
    }*/

  ]);

  const handleChangeButton = (e)=>{
    setComOpt(e.target.value)
    if(e.target.value==='PayoutDates') handleChangePayout()
  }
  const totalTranCom = stat?.totalTransactionCommission;
  const totalSuccessTran = stat?.totalSuccessTransactionCommission;
  const totalFailedTran = stat?.totalFailedTransactionCommission;
  const totalReferalCount = stat?.totalReferralCount;
  const totalRefferedUser = stat?.totalReferredUserCount;
  const totalSignUp = stat?.totalSignUpCommissionCount;
  const totalSignUpSucc = stat?.totalSuccessSignUpCommissionCount;
  const totalSignUpFailed = stat?.totalFailedSignUpCommissionCount;
  const totalBillsAggCom = stat?.totalBillsPaymentAggregatorCommission;
  const totalWithdrawal = stat?.totalWithdrawalCommission;
  const totalWithdrawalAgg = stat?.totalWithdrawalAggregatorCommission;
  const totalWayaRevenue = stat?.totalWayaRevenue;
  const totalSuccessDisbursed = stat?.totalSuccessDisbursed;
  const totalFailedDisbursed = stat?.totalFailedDisbursed;
  const billCom = stat?.totalBillsPaymentAggregatorCommission;
  const totalReferredUserCount = stat?.totalReferredUserCount;
  const totalReferralDisbursed = stat?.totalReferralDisbursed;
  // const totalSuccessDisbursed = stat?.totalSuccessDisbursed;


let statData = [
  {
    title: 'Total Waya Revenue',
    value: <h5>{currencyFormat(totalWayaRevenue ?? 0)}</h5>,
  },
  {
    title: 'Total Commission Successfully Disbursed',
    value:  <h5>{currencyFormat(totalSuccessDisbursed ?? 0)}</h5>,
  },
  {
    title: 'Total Commission Failed Disbursed',
    value:  <h5>{currencyFormat(totalFailedDisbursed ?? 0)}</h5>,
  },
  {
    title: 'Total Bill Commission Disbursed',
    value:  <h5>{currencyFormat(billCom ?? 0)}</h5>,
  },
  {
    title: 'Total Withdrawal Commission Disbursed',
    value:  <h5>{currencyFormat(totalWithdrawalAgg ?? 0)}</h5>,
  },
  {
    title: 'Total Referral Bonus Disbursed',
    value:  <h5>{currencyFormat(totalReferralDisbursed ?? 0)}</h5>,
  },
  {
    title: 'Total Deposit Bonus Disbursed',
    value:  <h5>{currencyFormat(totalSuccessDisbursed ?? 0)}</h5>,
  },
  {
    title: 'Number of Aggregators',
    value:  totalReferalCount ?? 0,
  },
  {
    title: 'Users Onboarded',
    value:  totalReferredUserCount ?? 0,
  },
  /*{
    title: 'Total Successful Transaction Commission',
    value: totalSuccessTran
  },
  {
    title: 'Total Failed Transaction Commission',
    value: totalFailedTran
  },
  {
    title: 'Total Refferals',
    value: totalReferalCount
  },
  {
    title: 'Total RefferedUsers',
    value: totalRefferedUser
  },
  {
    title: 'Total Sign Up',
    value: totalSignUp
  },
  {
    title: 'Total Successful Sign up',
    value: totalSignUpSucc
  },
  {
    title: 'Total Failed Signup',
    value: totalSignUpFailed
  },
  {
    title: 'Total Aggregator Bills Commission',
    value: totalBillsAggCom
  },
  {
    title: 'Total Aggregator Withdrawal',
    value: totalWithdrawalAgg
  },*/
 ]

  return (
    <Layout>

<div style={tool}>
<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>

        <Row type="flex" justify="end" align="middle">
      
        
        
            <Button
              style={{ fontSize: "10px", width:100, background: "#27AE60", color: "#fff" }}
              onClick={() => setShowExport(true)}

            >
              {" "}
              Export
            </Button>
            {/*<AntDropdown
            dropDownOptions={SendUserOptions}
            handleDropdownAction={handleSendUserDropdownAction}
          >
            <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              New Transfer
            </Button>
          </AntDropdown> */}
          <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
              onClick={()=>navigate('/tech/aggregator_bill')}
            >
              <SettingFilled />
              Manage Commission
            </Button>
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        
        {
          statData?.map(e=>
            <Card.Grid style={gridStyle} className="sm-1">
          <p>{e?.title}</p>
          <h2 className="text-center col-auto mr-auto">{e?.value}</h2>
        </Card.Grid> 
          )
        }    
      </Card>

      {/*<Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <div class='input-content ms-3'  onChange={handleChangeButton} style={{justifyContent: 'space-around'}}>
        <input type="radio" value="history" checked={comOption==='history'} name="commission" /> Commission History
        <input className="input-content ms-2" type="radio" value="PayoutDates" checked={comOption==='PayoutDates'} name="commission" /> Payout Dates
      </div>
      </Grid>*/}
      
      {allRequests?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allRequests}
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={allTransfers}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No record yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Data will appear here when you
          </p>
        </>
      )}

      
      {showSendUser ? (
        <SendSingleUserModal
          showModal={showSendUser}
          hideModal={setShowSendUser}
          title="Send money to Single User"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showTransferExcel ? (
        <CommissionExcelUpload
          showModal={showTransferExcel}
          hideModal={setShowTransferExcel}
          title="Upload Excel Sheet to Transfer from Waya Official Commission Account To User's Commission Account"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {
        showTransferForms? (
          <NonWayaWebForm
          showModal={showTransferForms}
          hideModal={setShowTransferForms}
          title="Send money to Non Waya Users with Web Form"
          className={styles.modal}
          wallets={[]}
          />
        ):("")
      }
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests} file={"commission"} />
      }
      {showPayoutDate&&
      <CHangePayout setComOpt={setComOpt} selectedUserType={selectedUserType} showModal={showPayoutDate} hideModal={setShowPayoutDate} title="Set Payout Date" className={styles.modal} />

      }
   
    </Layout>
  );
};

const gridStyle = {
  width: "15%",
  textAlign: "centre",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Commission;
