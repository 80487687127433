import {
    Checkbox,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
  } from "@mui/material";
  import { Button } from "antd";
  import React, { useEffect, useState } from "react";
  import { Modal } from "react-bootstrap";
  import { getArraryString, transformName } from "../../../utils/helper";
  import useStyles from "../style";
  const UpdateKYC = ({
    showModal,
    setShowModal,
    roles,
    handleUpdateKYCLimit,
  }) => {
    console.log(showModal);
    const styles = useStyles();
  
    const [rolesChecked, setRolesChecked] = useState(roles);
  
    const [userRoles, setUserRoles] = useState(showModal.roles);
    const [transLimit, setTransLimit] = useState(0);
  
    useEffect(() => {
      console.info(rolesChecked);
      console.info(userRoles);
    }, []);
      
    const onHideModal = () => {
      const role = { ...showModal };
      role.isVisible = false;
      Object.apply(role);
      setShowModal(role);
    };
  
    return (
      <Modal
        className={styles.modal}
        show={showModal.isVisible}
        onHide={onHideModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          closeButton
        >
          <span
            style={{
              fontWeight: "600",
              fontsize: "14px",
              lineHeight: "24px",
              textAlign: "center",
              width: "100%",
              color: "#000",
            }}
          >
            {" "}
            Update Transaction Limit {}{" "}
          </span>
          <br></br>
        </Modal.Header>
  
        <Modal.Body>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
        <input 
        className="form-control"
        type='number'
         placeholder="Set transaction limit" 
         onChange={(e)=>setTransLimit(e.target.value)}
        value={transLimit}
        />
        
          </div>
          <div  style={{display: 'flex', margin:20, justifyContent: 'center', alignItems: 'center'}}>
          <button className="btn btn-primary" onClick={()=>handleUpdateKYCLimit(transLimit)}>Update</button>

            </div>

        </Modal.Body>
      </Modal>
    );
  };
  
  export default UpdateKYC;
  