import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ColoredSwitcher from "../../../components/customSwitch/coloredSwitcher";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";

const SmsGateway = (props) => {
  const [gateways, setGateways] = useState([]);
  const handleToggle = async (gatewayIndex, gatewayState, gateway) => {
    const response = await httpPost(
      `${customBaseUrl.notificationAggUrl}/provider/enable/${gateway.id}`,
      {}
    );
    if (response.status === true) {
      hideLoader();
      // handleProviderChange(gatewayIndex, response?.data?.active);
      Swal.fire('Done', 'Done')
      handleGetGateways();
    }else{

    }
  };
  const handleGetGateways = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.notificationAggUrl}/provider/list`,
    );
    if (response.status === true) {
      hideLoader();
      console.log('gate', response?.data)
      setGateways(response?.data);
    }else{
      hideLoader();

    }
  };
  const handleProviderChange = (gatewayIndex, gatewayState) => {
    const gatewaysCopy = [...gateways];
    gatewaysCopy[gatewayIndex].active = gatewayState;
    setGateways(gatewaysCopy);
  };
  useEffect(() => {
    handleGetGateways();
  }, []);
  const gatewayItems = [{name:'Account Creation'}, {name:'Alerts'}, {name:'Notifications'}, {name:'Bulk Sms'}];

  

  return (
    <>
      <Card
        key={props.keyValue}
        style={{
          transitionDuration: "0.3s",
          minHeight: "30vw",
          display: "block",
        }}
        sx={{ marginTop: "30px" }}
        raised={true}
      >
        <CardContent>
          <List dense={false}>
          
          <div className="row" style={{
             fontWeight: "bold",
             marginBottom: "30px",
          }}>
            <div className="col-sm-2"></div>
            {/*gateways.map((item, index) => ( 
            <div className="col-sm-2">
              <div>{item?.vendor}</div>
            </div>           
            ))*/}
          </div>
          {gateways.map((item, index) => (
              <Grid container spacing={10}>
                <Grid key={ item?.vendor} item sm={5.5}>
                  <ListItem
                    key={item.id + item?.vendor.trim()}
                    secondaryAction={
                      <div style={{ width: "100%" }} className="container">
                        <div className="col-xl-6" style={{width: '75%'}}>
                      <IconButton key={item?.vendor} edge="end" aria-label="delete">
                        <ColoredSwitcher
                          //data={gateways[0]}
                          data={{...item, active:item.active}}

                          type="sms-gateway"
                          style={{ fontWeight: "700" }}
                          label={''}
                          keyValue={ item?.vendor.trim()}
                          color="#ff6700"
                          onChange={handleToggle}
                          />
                      </IconButton>
                          </div>
                          
                    </div>
                    }
                  >
                    <div
                      style={{ fontFamily: "inherit", fontWeight: "700" }}
                    >
                      {item?.vendor}
                    </div>
                  </ListItem>
                </Grid>
              </Grid>
            ))}               
         </List>
        </CardContent>
      </Card>
    </>
  );
};
export default SmsGateway;