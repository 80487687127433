import React, { createContext, useState, useEffect } from "react";
import { customBaseUrl, httpGet } from "../http";

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const { children } = props;
  const [profile, setProfile] = useState({});
  const [reload, setReload] = useState(false);

  const getUserState = async (phoneNumber) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/phone/${phoneNumber}`
    );
    if (res?.status) {
      setProfile(res?.data);
    }
  };

  useEffect(() => {
    const phoneNumber = localStorage.getItem("phoneNumber");
    if (phoneNumber) getUserState(phoneNumber);
  }, [reload]);

  return (
    <ProfileContext.Provider
      value={{
        setProfile,
        profile,
        reload,
        setReload,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
