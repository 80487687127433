import React from "react";
import styled from "styled-components";
import { Input, Button, Switch } from "antd";
import useStyles from "./styles";
import UserIcon from "../../../assets/icons/userOrange.svg";
import Check from "../../../assets/icons/check.svg";
import NotCheck from "../../../assets/icons/notCheck.svg";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import styles from "./styles";
import { hideLoader, showLoader } from "../../../utils/loader";
import { customBaseUrl, httpGet, httpPut, httpPost } from "../../../store/http";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Swal from "sweetalert2";
import UpdateAdmninPass from "./UpdatePassword";
const EditWRapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EditSuperProfile = () => {
  const { id: id } = useParams();
  const navigate = useNavigate();
  const styles = useStyles();
  const [user, setUser] = useState({});
  const [storedUser, setStoredUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [userType, setUserType] = useState(false);
  const [email, setEmail] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [gender, setGender] = useState("");
  const [smsAlertConfig, setSmsAlertConfig] = useState(false);
  const [userId, setUserId] = useState("");
  const [virtualAccountNumber, setVirtualAccountNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [status, setStatus] = useState("");
  const [userModel, setUserModel] = useState({});
  const [viewUpdatePass, setViewUpdatePass] = useState(false);

  useEffect(() => {
     getUser();
  }, []);

  const getUser = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/profile/${id}`
    );
    hideLoader();
    const storedUser = JSON.parse(localStorage.getItem("user_profile"));


    if (response.status !== true) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });

    }
    else {
      const user = response.data;
      console.log(user);
     /* setUser(user);
      setStoredUser(storedUser);
      setFirstName(user.firstName);
      setLastName(user.surname);
      setDateOfBirth(new Date(user.dateOfBirth));
      setUserType(user.corporate);
      setEmail(user.email);
      setReferenceCode(user.referenceCode);
      setGender(user.gender);
      setPhoneNumber(user.phoneNumber);
      setSmsAlertConfig(user.smsAlertConfig);
      setUserId(user.userId);
      setVirtualAccountNumber("32342424242");
      setStatus(storedUser.active);
        */
      setUserModel({
        firstName: user.firstName,
        surname: user.surname,
        dateOfBirth: user.dateOfBirth,
        email: user.email,
        gender: user.gender,
        phoneNumber: user.phoneNumber,
        smsAlertConfig: user.smsAlertConfig,
      });


    }

  };
  const handleSmsAlertConfigChange = async (event) => {
    //setUserModel({ ...userModel, smsAlertConfig: event })
    let response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/users/subscribe-sms-alert`,
      {
        phoneNumber: user.phoneNumber
      }
    );
    if (response.status === true) {
      setSmsAlertConfig(event);
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };
  const handleUpdateProfile = async () => {
    showLoader();
    let isUserCorporate = userType === "Corporate" ? true : false;
    if (isUserCorporate) {
      let response = await httpPut(
        `${customBaseUrl.authUrl}/api/v1/profile/update-corporate-profile/${id}`,
        userModel
      );
      if (response.status === true) {
        hideLoader();
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        hideLoader();
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    } else {
      let response = await httpPut(
        `${customBaseUrl.authUrl}/api/v1/profile/update-personal-profile/${id}`,
        userModel
      );
      if (response.status === true) {
        hideLoader();
        Swal.fire({
          title: "Success",
          text: "Profile updated successfully",
          icon: "success",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        hideLoader();
        Swal.fire({
          title: "Error",
          text: "Profile not updated",
          icon: "error",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
showLoader();
    let res;
   
      const url = undefined;
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byEmail?email=${userModel?.email}&redirectUrl=${url}`,
        true
      );
    

    if (res.status) {
        hideLoader();
        Swal.fire(`OTP sent to ${userModel?.email}`);
       setViewUpdatePass(true)
     
    } else {
        hideLoader();
      Swal.fire('Oops!', res.message, 'error');
    }
  };
  return (
    <Layout>
     
      <EditWRapper>
        <div className={styles.topDiv} style={{textAlign:'center'}}>
          <div className={styles.title}>Personal Information</div>
          <div className={styles.titleDet}>
            <div style={{margin:'auto'}}>
              <img src={UserIcon} alt="user" className={styles.tDlIcon}  />
            </div>
          </div>
        </div>
        <div className={styles.bottomDiv}>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>First Name</div>
              <Input
                value={userModel.firstName}
                onChange={(evt) =>{
                  setUserModel({ ...userModel, firstName: evt.target.value });
                  alert(JSON.stringify(userModel))
                 } }
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Last Name</div>
              <Input
                value={userModel.surname}
                onChange={(evt) =>
                  setUserModel({ ...userModel, surname: evt.target.value })
                }
                className={styles.inputInput}
              />
            </div>
          
          </div>
          <div className={styles.inputWrap}>
          <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Email Address</div>
              <Input
                value={userModel.email}
                disabled
                onChange={(evt) =>
                  setUserModel({ ...userModel, email: evt.target.value })
                }
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Phone Number</div>
              <Input
                value={userModel.phoneNumber}
                onChange={(evt) =>
                  setUserModel({ ...userModel, phoneNumber: evt.target.value })
                }
                className={styles.inputInput}
              />
            </div>
                      
          </div>        
         <div style={{  margin: 'auto',  width: '50%',  padding: 10}}>
          <Button onClick={handleUpdateProfile} style={{ background: "#ff6700",
            marginTop: "-18px", padding:2,    fontSize: "20px",    width: "100%",      color: "#fff"}} >
            Update User
          </Button>
          <div onClick={handleSubmit} style={{textAlign:'center'}} >
          change password
          </div>
          </div>
        </div>
      </EditWRapper>
      {
        viewUpdatePass && <UpdateAdmninPass showModal={viewUpdatePass} hideModal={setViewUpdatePass} email={userModel?.email}/>
      }
    </Layout>
  );
};

export default EditSuperProfile;
