import Layout from "../../components/Layout";
import React, { useEffect, useState } from "react";
// import {Tabs, Box,Tab} from '@mui/material';
import { Button, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";   
import FeedbackComponent from "./Feedback";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: "5px 0 25px 0",
  },
  textHeader: {
    fontStyle: "normal",
    fontSeight: 500,
    fontSize: 9,
    lineHeight: "18px",
    color: "rgba(0, 0, 0, 0.4)",
    textAlign: "center",
  },
}));

const FeedBack = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("one");
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const { TabPane } = Tabs;

  return (
    <Layout>
        

        <Tabs
          defaultActiveKey="1"
          size="small"
          tabBarStyle={{ paddingLeft: 20 }}
          onChange={handleChange}
          animated
          color="#ff6700"
        >
          <TabPane
            tab={<span>&nbsp;&nbsp; Posts</span>}
            key="1"
          >
            <FeedbackComponent keyValue={1}/>
          </TabPane>
          <TabPane
            tab={<span>&nbsp;&nbsp; Top Posts</span>}
            key="2"
          >
            <div></div>
          </TabPane>
        </Tabs>
    </Layout>
  );
};

export default FeedBack;
