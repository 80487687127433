import React, { useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { CloseCircleFilled } from "@ant-design/icons";
import { customBaseUrl, httpPost, httpPostFormData } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";

const PerformTransactionExcel = ({ showModal, hideModal, title, wallets }) => {
  const [data, setData] = useState({tranCrncy:"NGN" });
  const [file, setFile] = useState('');
  const [pin, setPin] = useState('');
  const [tranType, setTranType] = useState('transfer');
  const [recipientType, setRecipientType] = useState('waya')
  // {
  //   amount: 0,
  //   customerAccountNumber: "string",
  //   eventId: "string",
  //   paymentReference: "string",
  //   tranCrncy: "string",
  //   tranNarration: "string",
  //   transactionCategory: "string",
  //   userId: 0,
  // }
  const handleTransaction = async (e) => {
    showLoader();
    e.preventDefault();
    showLoader();
    let d = new Date();

    const reqId = d.getTime();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('pin', pin);
    let response;
      if (tranType === 'transfer') {

  //    response = await httpPostFormData(`${customBaseUrl.walletUrl}/api/v1/wallet/transfer/bulk-transaction-excel`, formdata);
     let customerName = ' Bulk Transfer'
     response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/bulkOfficialTransfer`, formData)

     
      }else if(tranType === 'withdrawal'){
       /* response = await httpPost(
          `${customBaseUrl.withdrawalUrl}/admin/withdrawal/official/multipleFund/excel`,
          formdata
          )
          */
          let customerName = ' Bulk Withdrwal'
     response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/bulkOfficialWithdrawal`, formData)

      }else if(tranType === 'nonwaya'){
       /* response = await httpPostFormData(
          `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new-multiple-official-excel-upload`,
          formdata
          )*/
          let customerName = ' Bulk transfer to non waya'
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/bulkOfficialTransferNonWaya`, formData)
     
      }else if(tranType === 'paymentrequest' && recipientType==='nonwaya'){
        /*response = await httpPost(
          `${customBaseUrl.paymentRequestUrl}/api/v1/admin/non-waya/official-excel-upload`,
          formdata
          )*/
          let customerName = ' Bulk Payment Request'
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/bulkOfficialReqNonWaya`, formData)
     
      }else if(tranType === 'paymentrequest' && recipientType==='waya'){
        /*response = await httpPost(
          `${customBaseUrl.paymentRequestUrl}/api/v1/admin/waya/official-excel-upload`,
          formdata
          )*/
          let customerName = ' Bulk payment req'
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/bulkOfficialReq`, formData)
     
      }
      else if(tranType === 'commission'){
        /*response = await httpPost(
          `${customBaseUrl.commissionUrl}/api/v1/transfer/bulk-user-commission-excel`,
          formdata
          )*/
          let customerName = ' Bulk commission'
          response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/BulkCommission`, formData)
     

      }

    /*  if(tranType === 'nonwaya'){
        if (response.body.Response[0].status === true) {
          hideLoader();
          hideModal(false);
          Swal.fire({
            title: "Success",
            text:response.body.Response[0].message,
            icon: "success",
            confirmButtonText: "OK",
            showCancelButton: false,
          })
        }
        else {
          hideLoader();
          Swal.fire({
            title: "Error",
            text: response.body.Response[0].message,
            icon: "error",
            confirmButtonText: "OK",
            showCancelButton: false,
          });
        }

      
      }else if(tranType==='paymentrequest'){
        console.log(response.body)
          if(response?.body?.status|| response?.body?.Response.length>0){
            hideLoader();
            Swal.fire('Done', response?.body?.message || 'Done', 'Done')
            hideModal(false)
          }else{
            hideLoader();
            Swal.fire('Error', response.message, 'Error')
          }

      }    
      else{*/

        
         
    if (response.status === true) {
      hideLoader();
      hideModal(false);
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      })
    }
    else {
      //alert(JSON.stringify(response));
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
 // }
  };

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <Wrapper>
        <div className="cw-content">
          <div className="input-content">
            <div className="label">Transaction Type </div>
            <select name="" id="" onChange={(e)=>setTranType(e.target.value)} className='border border-dark rounded'>
              <option value="transfer">Wallet Transfer</option>
              <option value="withdrawal"> Withdrawal</option>
              <option value="nonwaya">Transfer Non Waya</option>
              <option value="paymentrequest">Payment Request</option>
              <option value="commission">Commission</option>



            </select>
          </div>
          {tranType === 'paymentrequest' && <div>
          <div className="input-content">

          <div className="label">Recipient Type </div>
            <select name="" value={recipientType} id="" className='border border-dark rounded' onChange={(e)=>setRecipientType(e.target.value)} >
              <option value="waya">Waya Users</option>
              <option value="nonwaya">Non Waya Users</option>

            </select>
          </div>
          </div>
          }
          <div className="input-content">
            <div className="label">Select Excel File to Upload </div>
            <div className="input-content">
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setFile(e.target.files[0])}
            />
            </div>
          </div>
          <div className="input-content">
            <div className="label">PIN </div>
            <input
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </div>
          
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleTransaction}
        >
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default PerformTransactionExcel;
