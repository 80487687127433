import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import { clear } from "@testing-library/user-event/dist/clear";
import { ref } from "yup";
import { ProfileContext } from "../../../store/context/ProfileContext";





const BillsPayment  =({wallets,categories, hideModal, quickTeller})=>{
    const [data, setData] = useState({});
    const [options, setOptions] = useState([]);
    const [walletList, setWalletList] = useState([]);
    const { profile } = useContext(ProfileContext);
    const [query, setQuery] = useState('');
    const [category, setCategory] = useState('')
    const [view, setView] = useState('default');
    const [billerList,setBillerList] = useState([])
    const [billerInfo,setBillerInfo] =useState([])
    const [billerItems, setBillerItems] = useState([]);
    const [plan, setPlan] = useState('')
    const [amount, setAmount] = useState('');
    const [accountNo, setAccountNo] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedWallet, setSelectedWallet] = useState('');
  const [title, setTitle] = useState('');
  const [walletType, setWalletType] = useState('');
  const [userWallets, setUserWallets] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [customerId, setCustomerId] = useState('');

  const getBillersByCategories = async () => {
    showLoader();
  
  let res;
  /*if(quickTeller){
   res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/${view}`)
  }else{*/
     res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${view=='default'?1:view}`)
 
 // }
    if (res?.status) {
      hideLoader();
      setBillerList(res?.data);
      console.log(res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
    hideLoader();

  };

  const getBillerItems = async () => {
    showLoader();   
    
     let res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${billerInfo.categoryId}/biller/${billerInfo.billerId}`)
     if (res?.status) {
      hideLoader();
      setBillerItems(res?.data?.items);
      console.log('biller materials',res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const d = new Date();
    let time = d.getTime();

   const data1 = {
    "amount": amount,
    "billerId": billerInfo.billerId,
    "categoryId": billerInfo.categoryId,
    "data": [
      {
        "name": "phone",
        "value": phone
      },
      {
        "name": "amount",
        "value": amount
      },
      {
        "name": "total_amount",
        "value": amount
      },
      {
        "name": "account_number",
        "value": accountNo
      },
      {
        "name": "smartcard_number",
        "value": accountNo
      },
      {
        "name": "plan",
        "value": plan
      },
      {
        "name": "pinValue",
        "value": plan
      },
      {
        "name": "bundles",
        "value": plan
      },
      {
        "name": "account_number",
        "value": accountNo
      },
      {
        "name": "numberOfPins",
        "value": accountNo
      }
    ],
    "sourceWalletAccountNumber": selectedWallet
  }

  let quickData = {
    "amount": amount,
    "billerId": billerInfo.billerId,
    "categoryId": billerInfo.categoryId,
      "data": [
        {
          "name": "customerMobile",
          "value": phone
        },
        {
          "name": "amount",
          "value": amount
        },
        {
          "name": "customerEmail",
          "value": 'wayaadmin@wayapaychat.com'
        },
        {
          "name": "customerId",
          "value": customerId
        },
        {
          "name": "paymentCode",
          "value": plan
        }
      
      ],
      "sourceWalletAccountNumber": selectedWallet,
      userId: 1
    }
  
//return alert(JSON.stringify(data))
    showLoader();
   /* 
     let res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/wallet/non-waya/payment/new`,
        transferData
      );*/
    /*
      const res = await httpPost(
        `${customBaseUrl.billPaymentUrl}/api/v1/admin/make-payment-for-user?userId=${walletType==='userwallet'?data?.actRefId:profile.userId}
        `,
        quickTeller?quickData:data1
      );*/
      let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
     {
       payload: JSON.stringify(quickTeller?quickData:data1),
       "requestId": `REQ${d.getTime()}`,
       "requestType": "OfficialBillspayment",
       customerName: selectedWallet
     } )

    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      console.log(res)
      hideModal(false);
    } else {
      hideLoader();
      console.log(res)

      Swal.fire('Error', res.message, 'error');
    }
  };

  useEffect(()=>{
    getBillersByCategories();
    getBillerItems();
    setBillerItems([]);
    setAmount('');
    setAccountNo('');
  },[view, billerInfo])
  
  const  accountType = { 
    'betting': 'betting',
    'electricity': 'Meter no',
    'cabletv': 'Smartcard no',
    'airtime': 'Phone no',
    vehiclepaper: 'Vehicle paper',
    insurance: "General Insurance"
  
                        }
  
  
return(
    <>
    <div className="cw-content">
    <form class='row g-3 needs-validation' novalidate>
        <div class='col-12 text-center font-weight-bold'>

        <p class="font-weight-bold text-lg"><h5> {view==='default'?'Select Bills Payment Type': title}</h5> </p>
        {view==='default'?
          <select
          class='form-select border'
          id='validationCustom06'
         onChange={(e)=>{ setView(JSON.parse(e.target.value).categoryId); setTitle(JSON.parse(e.target.value).categoryName); setBillerItems(); setBillerInfo() }}>
           <option value="">Select</option>
           {categories.map((item, index) => (
           <option value={JSON.stringify(item)}>{item.categoryName}</option>
           ))}
         </select>
        :""}

        </div>
        {view !== 'default' ?<div >
        
        <div class='contentBar-items m-2'>
          <select 
            class='form-select border'
            id='validationCustom06'
            required
          value={JSON.stringify(billerInfo)} onChange={(e)=>{setBillerInfo(JSON.parse(e.target.value))}}>
          <option value="">Service Provider</option>
          {billerList.map((biller, index) => (
            <option key={index} value={JSON.stringify(biller)}>
              {biller?.billerName}
            </option>
          ))}
        </select>
        </div>
        <div className="contentBar-items m-2">
          {billerItems?.map((item, index) => (
            <div key={index} className="contentBar-item">
              {item.subItems.length>0 && 
                (<div key={index} className="contentBar-item-subItem">
                  <div className="contentBar-item-subItem-name">{item.paramName}</div>
                  <select
                   class='form-select border'
                   id='validationCustom06'
                  onChange={(e)=>{setPlan(e.target.value)}}>
                    <option value="">Select</option>
                    {item.subItems.map((subItem, index) => (
                    <option value={subItem.id}>{`${subItem.name}  - ${subItem?.amount?'N':''}${subItem?.amount?subItem?.amount:''}`}</option>
                    ))}
                  </select>
                  </div>)} 
              </div>))}
        </div>
      
      
        
        <div class='contentBar-items m-2'>
        <label for='validationCustom07' class='form-label'>
            Phone
          </label>
        <input 
         class='form-control'
        type="text"  value={phone}  onChange={(e)=>setPhone(e.target.value)}/>
        </div>
        {quickTeller?<div>
             <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
          Customer Id 
          </label>
        <input
        class='form-control'
        type="text"  value={customerId}  onChange={(e)=>setCustomerId(e.target.value)}/>
       </div>

          </div>:
          <div>
        {view!=='airtime' && view !== 'databundle' && 
        
        <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
        {accountType[view]}
        </label>
        <input
        class='form-control'
        type="text"  value={accountNo}  onChange={(e)=>setAccountNo(e.target.value)}/>
       </div>}

       </div>}
       <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
          Amount
          </label>
        <input
        class='form-control'
        type="text"  value={amount}  onChange={(e)=>setAmount(e.target.value)}/>
       </div>
       
        <div class='contentBar-items m-2'>
          <label for='validationCustom07' class='form-label'>
            Select Wallet for transaction
          </label>
          <select
            class='form-select border'
            id='validationCustom07'
            required
            onChange={(e)=>setSelectedWallet(e.target.value)}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { wallets.length>0 &&
            wallets?.map((option) => (
              <option key={option.accountNo} value={option?.accountNo}>
                {option?.acct_name +' - '+option?.accountNo}
              </option>
            ))}
          </select>
        </div>
       

        <div class='col-12 text-end mt-5 m-2'>
        <button class='btn border m-2'  onClick={()=>{setView('default')}}>
            Back  
        </button>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit} >
            Proceed
          </button>
        </div>
        </div>: ''}
      </form>
         
       </div>
    </>
)
}

export default BillsPayment;