import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';

const FilterDisputes = ({ showModal, hideModal, setStatus, searchData, clearFilter, setSearchData, handleFilters }) => {


  const handleSelect=(e)=>{
    setStatus(e.target.value)
    hideModal(false)
  }
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title='Filter Disputes'
      width='15%'
    >
      <Wrapper>
        <div className="cw-content">
        <button value='OPENED' className='btn border' style={{marginBottom:10}} onClick={handleSelect}>Opened</button>
        <button value='CLOSED' className='btn border' style={{marginBottom:10}} onClick={handleSelect}>Closed</button>
        <button value='PROCESSING' className='btn border' style={{marginBottom:10}} onClick={handleSelect}>Processing</button>
        <button value='WAYAPAY_REVIEWING' className='btn border' style={{marginBottom:10}} onClick={handleSelect}>Review</button>
        <button value='PENDING_RESPONSE' className='btn border' style={{marginBottom:10}} onClick={handleSelect}>Pending</button>

           
        </div>
      </Wrapper>
      <button value='' onClick={handleSelect}>Clear</button>


    </Modal>
  );
};

export default FilterDisputes;
