import {
  CaretDownOutlined,
  CaretUpOutlined,
  SettingFilled,
} from "@ant-design/icons";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
} from "@mui/material";
import { Button } from "antd";
import react, { useEffect, useState, Fragment } from "react";
import ColoredSwitcher from "../../../components/customSwitch/coloredSwitcher";
import { httpGet,customBaseUrl, httpPut, httpPatch } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import Swal from "sweetalert2";

const BillPaymentAggregators = (props) => {
  const [isBillerSelected, setIsBillerSelected] = useState({ active: true });
  const [isAggregatorSelected, setIsAggregatorSelected] = useState({
    active: false,
  });

  const [billers, setBillers] = useState([]);
  const [aggregators, setAggregators] = useState([]);

  const handleToggleAggregator = async (aggregatorIndex, aggregatorState, aggregator) => {
    showLoader();
    const response = await httpPatch(`${customBaseUrl.billPaymentUrl2}/api/v1/admin/activateServiceProvider/${aggregator.id}?isActive=${!aggregator?.isActive}`,{});
    hideLoader();
    if (response?.status === true) {
      // handleAggregatorChange(aggregatorIndex,  response?.data?.active);
      handleGetAggregators();
    }
  }
  const handleGetAggregators = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/admin/fetchAllServiceProvider`
    );
    let billerss = []
    if (res.status === true) {
      hideLoader();
      setAggregators(res?.data);
      res?.data?.map((e, i)=> (
        Object.keys(e).map(k => {
          if(k?.includes('process')){
            billerss.push({aggregatorName: e?.name,  name: k , active: e[k]})
          }
          // console.log(k)
      })
      ))
      setBillers(billerss);
      console.log('billll', billerss);
    } else {
      hideLoader();
    }
  };
  const handleGetBillers = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/config/biller`
    );
    hideLoader();
    if (res.status === true) {
      // setBillers(res?.data);
    }
  };

  useEffect(() => {
    handleGetAggregators();
    handleGetBillers();
  }, []);

  const handleAggregatorChange = (aggregatorIndex, aggregatorState) => {
    const updatedAggregator = aggregators.find(item => item.id === aggregatorIndex); // from the state 'aggregator' array, get the correct object for updatedAggregator
    updatedAggregator.active = aggregatorState; // update the boolean of the aggregator to indicate going/true || not/false
    handleBillerSelection(updatedAggregator); // call the function to handle the billers selection and update the state
    const newAggregators = [...aggregators]; // make a copy of previous state of attendees
    let index = newAggregators.findIndex((aggregator) => aggregator.id === aggregatorIndex); // find the index of the attendee to update
    newAggregators[index] = updatedAggregator; // insert/overwrite array object of the attendee in question with the new version
    setAggregators(newAggregators); // update state
  };

  const handleBillerSelection = (item) => {
    setIsBillerSelected({
      aggregator: item.name,
      active: !isBillerSelected.active,
    });
    setIsAggregatorSelected({
      aggregator: item.name,
      active: item.isActive,
    });
  };
  return (
    <Card
      key={props.keyValue}
      style={{
        transitionDuration: "0.3s",
        minHeight: "30vw",
        display: "block",
      }}
      sx={{ marginTop: "30px" }}
      raised={true}
    >
      <CardContent>
        <Box sx={{ mx: 3, p: 4 }}>
          <Grid container spacing={10}>
            {aggregators.length > 0
              ? aggregators.map((item, index) => (
                  <Grid key={item.id+item.name} item sm={4} sx={{ width: 1 }}>
                    <ColoredSwitcher
                      data={item}
                      dataIndex={item.id}
                      style={{ fontWeight: "700" }}
                      type="name"
                      label={item.name}
                      keyValue={index.toString()}
                      color="#ff6700"
                      onChange={handleToggleAggregator}
                    />

                    <Button
                      id={index + "_" + item.name}
                      style={{
                        width: "65%",
                        marginTop: "5px",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                      danger
                      size="default"
                      icon={
                        isBillerSelected.active &&
                        item.isActive &&
                        isBillerSelected.aggregator == item.name ? (
                          <CaretUpOutlined
                            style={{ fontSize: "20px", marginRight: "30px" }}
                          />
                        ) : (
                          <CaretDownOutlined
                            style={{ fontSize: "20px", marginRight: "30px" }}
                          />
                        )
                      }
                      disabled={
                        item.isActive === true
                          ? false
                          : item.isActive === false
                          ? true
                          : isBillerSelected.aggregator != item.name &&
                            item.isActive == isBillerSelected.active
                      }
                      onClick={() => handleBillerSelection(item)}
                    >
                      Billers
                    </Button>
                  </Grid>
                ))
              : null}
          </Grid>
        </Box>

        {isAggregatorSelected.active &&
        !isBillerSelected.active &&
        billers.length > 0 ? (
          <div>
            <BillPaymentAggregatorsList
              aggregator={isAggregatorSelected.aggregator}
              billersList={billers}
              setBillersState = {setBillers}
              aggregators={aggregators}
              handleGetAggregators={handleGetAggregators}
            />
          </div>
        ) : null}
        {/* Contailer for Billers */}
      </CardContent>
    </Card>
  );
};

const BillPaymentAggregatorsList = (props) => {
  var { billersList, aggregator, setBillersState, aggregators, handleGetAggregators  } = props;
 

 

  const handleToggleBiller = async (billerIndex, billerState, biller) => {
    //  console.log('bilIndex', billerIndex);
    showLoader();
    const response = await httpPatch(`${customBaseUrl.billPaymentUrl}/api/v1/config/biller/toggle/${biller.id}`);
    hideLoader();
    if (response.status === true) {
      console.log("response", response);
     handleBillerChange(billerIndex, response?.data?.active);
    }
  }
  const billers = billersList.filter(
    (biller) => biller.aggregatorName === aggregator
  );
  const handleBillerChange = (billerIndex, billerState) => {
    const updatedbiller = billersList.find(item => item.id === billerIndex); // from the state 'biller' array, get the correct object for updatedbiller
    updatedbiller.active = billerState; // update the boolean of the biller to indicate going/true || not/false
    const newbillersList = [...billersList]; // make a copy of previous state of billers
    let index =newbillersList.findIndex(item => item.id === billerIndex); // insert/overwrite array object of the attendee in question with the new version
    newbillersList[index] = updatedbiller; // insert/overwrite array object of the attendee in question with the new version
    setBillersState(newbillersList); // update state
    billersList = newbillersList;
  };

  const handleBillers = async (name) => {
   
    const billss = aggregators?.filter(e => e?.name === aggregator)[0];
     const data =  Object.keys(billss).
        filter((key) => key.includes('process')).
        reduce((cur, key) => { return Object.assign(cur, { [key]: billss[key] })}, {});
    data[name] = !data[name]
    data.precedence = 1
    showLoader();
    const response = await httpPut(`${customBaseUrl.billPaymentUrl2}/api/v1/admin/updateServiceProvider/${billss?.id}`, data);
    hideLoader();
    if (response.code === 200) {
      hideLoader();
      Swal.fire('DOne', response?.message);
      console.log("response", response);
      handleGetAggregators();
    } else {
      hideLoader();
    }
  }

  return (
    <Box sx={{ mx: 2, p: 4 }}>
      <Grid container spacing={10}>
        <Grid item sm={7} sx={{ width: 1 }}>
          <List dense={false}>
            {billers.map((item, index) => (
              <ListItem
                key={item.id+item.name.trim()}
                secondaryAction={
                  <IconButton key={item.id} edge="end" aria-label="delete"
                    onChange={()=>handleBillers(item?.name)}
                  >
                    <ColoredSwitcher
                      data={item}
                      type="biller"
                      dataIndex={item?.id}
                      id = {item?.id}
                      style={{ fontWeight: "700" }}
                      label={item.name}
                      keyValue={item.id+item.name.trim()}
                      color="#ff6700"
                      //  onChange={handleToggleBiller}
                      
                    />
                  </IconButton>
                }
              >
                <div
                  key={item.id}
                  style={{ fontFamily: "inherit", fontWeight: "700" }}
                >
                  {item.name}
                </div>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillPaymentAggregators;
