import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ColoredSwitcher from '../../../components/customSwitch/coloredSwitcher';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FundingWallet = (props) => {
  const [providers, setProviders] = useState([]);
  const handleToggle = async (providerIndex, providerState, provider) => {
    try {
      showLoader();

      const response = await httpPost(
        `${customBaseUrl.cardUrl}/provider/enable/${provider.id}`,
        {}
      );

      if (response.status === true) {
        handleGetProviders(provider.name);
        handleProviderChange(providerIndex, response?.data?.active);
      }

      hideLoader();
    } catch (error) {
      hideLoader();
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const showMessage = (message) => {
    toast.success(`${message}`);
  };

  const handleGetProviders = async (name) => {
    try {
      showLoader();
      const response = await httpGet(`${customBaseUrl.cardUrl}/provider/list`);

      if (response.status === true) {
        setProviders(response?.data);

        if (name !== undefined) {
          showMessage(`${name} is now activated`);
        }

        hideLoader();
      }
    } catch (error) {
      hideLoader();
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  const handleProviderChange = (providerIndex, providerState) => {
    const providersCopy = [...providers];
    providersCopy[providerIndex].active = providerState;
    setProviders(providersCopy);
  };

  useEffect(() => {
    handleGetProviders();
  }, []);

  return (
    <>
      <ToastContainer />
      <Card
        key={props.keyValue}
        style={{
          transitionDuration: '0.3s',
          minHeight: '30vw',
          display: 'block',
        }}
        sx={{ marginTop: '30px' }}
        raised={true}
      >
        <CardContent>
          <List dense={false}>
            <div
              className="row"
              style={{
                fontWeight: 'bold',
                marginBottom: '30px',
              }}
            >
              <div className="col-sm-2"></div>
              <div className="col-sm-2">
                <div>Collections</div>
              </div>
              <div className="col-sm-2">
                <div>Virtual Bank Transfer</div>
              </div>
              <div className="col-sm-2">
                <div>Bank Card</div>
              </div>
              <div className="col-sm-2">
                <div>Bank Account</div>
              </div>
              <div className="col-sm-2">
                <div>USSD</div>
              </div>
            </div>
            {providers.map((item, index) => (
              <Grid container spacing={10} key={index}>
                <Grid key={item.id + item.name} item sm={6}>
                  <ListItem
                    key={item.id + item.name.trim()}
                    alignItems="center"
                    secondaryAction={
                      <div style={{ marginLeft: '100px', width: '250%' }}>
                        <IconButton
                          key={item.id}
                          edge="end"
                          aria-label="delete"
                          className="col-sm-2"
                        >
                          <ColoredSwitcher
                            data={item}
                            type="biller"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: '700' }}
                            label={item.name}
                            keyValue={item.id + item.name.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                        <IconButton
                          key={item.id}
                          edge="end"
                          aria-label="delete"
                          className="col-sm-2"
                        >
                          <ColoredSwitcher
                            data={item}
                            type="biller"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: '700' }}
                            label={item.name}
                            keyValue={item.id + item.name.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                        <IconButton
                          key={item.id}
                          edge="end"
                          aria-label="delete"
                          className="col-sm-2"
                        >
                          <ColoredSwitcher
                            data={item}
                            type="biller"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: '700' }}
                            label={item.name}
                            keyValue={item.id + item.name.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                        <IconButton
                          key={item.id}
                          edge="end"
                          aria-label="delete"
                          className="col-sm-2"
                        >
                          <ColoredSwitcher
                            data={item}
                            type="biller"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: '700' }}
                            label={item.name}
                            keyValue={item.id + item.name.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                        <IconButton
                          key={item.id}
                          edge="end"
                          aria-label="delete"
                          className="col-sm-2"
                        >
                          <ColoredSwitcher
                            data={item}
                            type="biller"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: '700' }}
                            label={item.name}
                            keyValue={item.id + item.name.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                      </div>
                    }
                  >
                    <div
                      key={item.id}
                      style={{
                        fontFamily: 'inherit',
                        fontWeight: '700',
                        height: 50,
                      }}
                    >
                      {item.name}
                    </div>
                  </ListItem>
                </Grid>
              </Grid>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  );
};
export default FundingWallet;
