/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tabs } from 'antd';
import LoadingComponent from '../../components/loading/LoadingComponent';
import OverviewComponent from './overview';
import SwitchToggler from './SwitchToggler';
import SmWalletIcon from '../../assets/icons/sm-wallet-icon';
import SmTerminalIcon from '../../assets/icons/sm-terminal-icon';
import SmCardIcon from '../../assets/icons/sm-card-icon';
import Layout from '../../components/Layout';
import { customBaseUrl, httpGet } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
// import RecentUsersComponent from './RecentUsersComponent';
// import TransactionSummaryComponent from './TransactionSummaryComponent';
// import LogSummaryComponent from './LogSummaryComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '5px 0 25px 0',
  },
  textHeader: {
    fontStyle: 'normal',
    fontSeight: 500,
    fontSize: 15,
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.4)',
    textAlign: 'center',
  },
}));

const Index = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [state, setState] = React.useState(false);
  const [to, setTo] = React.useState(null);
  const [from, setFrom] = React.useState(null);
  const [analysis, setAnalysis] = useState([])

  const [title, setTitle] = React.useState(
    'ACCOUNT OVERVIEW',
  );
  const [loading] = React.useState(false);
  const { TabPane } = Tabs;
  const handleChange = (val) => {
    setValue(val);
    setState(false);
    if (val == '1') setTitle('WALLET PRODUCT PERFORMANCE METRICS');
    if (val == '2') setTitle('WEB POS PRODUCT PERFORMANCE METRICS');
    if (val == '3') setTitle('TERMINAL POS PRODUCT PERFORMANCE METRICS');
  };

  const handleToggler = (event) => {
    if (state) {
      setState(false);
    } else {
      setState(true);
    }
  };

  const getStat = async(from, to) =>  {
    showLoader()
    let res = await httpGet(`${customBaseUrl.walletUrl}/api/v1/wallet/transaction/analysis?fromdate=${from}&todate=${to}`)
    if(res.status){
      hideLoader();
      setAnalysis(res.data)
    }else{
      hideLoader();
    }
  }

  useEffect(()=>{
    if(to && from){
      console.log('from',from, 'to', to)
      getStat(from,to);
    }
  },[from, to])
  
  return (
    <Layout>
    <div className={classes.root}>
      <h3 className={classes.textHeader}>{title}</h3>
      <div className="cards ">
        <div className="input-container d-flex">
          <span className="text-muted fs-12 mx-1">From</span>
          <input className="input fs-12" type="date" onChange={(e)=>setFrom(e?.target.value)} />
          <span className="text-muted fs-12 me-1">To</span>
          <input className="input fs-12" type="date" onChange={(e)=>setTo(e?.target.value)}/>
        </div>
      </div>
      <Tabs
        defaultActiveKey="1"
        size="large"
        tabBarStyle={{ paddingLeft: 20 }}
        onChange={handleChange}
        animated
        tabBarExtraContent={
          <SwitchToggler handleToggler={handleToggler} state={state} />
        }
      >
        <TabPane
          tab={
            <></>
          }
          key="1"
        >
          <OverviewComponent state={state} analysis={analysis}/>
        </TabPane>
         
      </Tabs>
    </div>
    </Layout>
  );
};

export default Index;
