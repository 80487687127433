import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import { SettingsWrapper as Wrapper } from "./style";
import { httpPost, customBaseUrl, httpGet } from "../../../store/http";
import { ProfileContext } from "../../../store/context/ProfileContext";
import { hideLoader, showLoader } from "../../../utils/loader";
import Swal from "sweetalert2";
import { error } from "../../../services/swal-mixin";

const AddAccount = ({ showModal, hideModal, getAccounts, userId }) => {
  const [values, setValues] = useState({});
  const { profile } = useContext(ProfileContext);
  const [banks, setBanks] = useState([]);
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');

  const getBanks = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/BK
      `
    );

    if (res?.status) {
      setBanks(res?.data);
    }
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = async () => {
      let postData ={
          accountName,
          accountNumber,
          bankCode,
          bankName,
          userId
      }
    showLoader();
/*
    const res = await httpPost(
      `${customBaseUrl.cardUrl}/bankAccount/add/${userId}`,
      postData
    );

    */

    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "ADDBANKACCOUNT",
      customerName: accountName
    } )

    if (res?.status) {
      Swal.fire(res?.message);
      hideLoader();
      getAccounts();
      hideModal(false);
    } else {
      Swal.fire(res?.message);
      hideLoader();
    }
  };
  useEffect(() => {
    getBanks();
  }, []);

  


  const resolveBank = async (value) => {
    showLoader();
    if (value === '') {
      Swal.fire('Account Number is required');
      return;
    }
    const newPostData = {
      accountNumber: value,
      bankCode,
      // bankName: postData.bankName,
    };
    const res = await httpPost(
      `${customBaseUrl.cardUrl}/bankAccount/resolveAccountNumber/WD`,
      newPostData
    );


    if (res.data!=='Invalid_Destination_Bank_Code801') {
      hideLoader();
      setAccountName(res.data);
      setAccountNumber(value)
      
    } else {
      Swal.fire('error','Account details not correct');
    }
    hideLoader();
  };



  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <Wrapper>
        <div className="create-pin w-100">
          <div className="title">Add Bank Account</div>
          <div className="cp-content w-100">
            <div className="input-content">
              <div className="label">Select Bank</div>
              <select
                id=""
                value={bankCode?JSON.stringify(banks?.filter((item)=>item.bankCode===bankCode)[0]):""}
                name="bankName"
                onChange={(e) => {setBankName(JSON.parse(e.target.value).bankName);setBankCode(JSON.parse(e.target.value).bankCode)}}
              >
                <option value="">Select Bank</option>

                {banks?.map((option) => (
                  <option key={option?.bankName} value={JSON.stringify(option)}>
                    {option?.bankName}
                  </option>
                ))}
              </select>

              
        

            </div>
            <div className="input-content">
              <div className="label">Account Number</div>
              <input
                name="accountNumber"
                id=""
                type="text"
                value={accountNumber}
                onChange={(e) => {
                  if(e.target.value.length>=10){
                    resolveBank(e.target.value);
                  }
                  setAccountNumber(e.target.value);
                  setAccountName('');
                }}
              />
            </div>
            <div className="input-content">
              <div className="label">Account Name</div>
              <input
                name=""
                id=""
                value={accountName}
                disabled={true}
              />
            </div>
            <div className="input-content">
              <div className="label">BVN</div>
              <input
                name=""
                id=""
                value={values.bvn}
                onChange={(e) => setValues({ ...values, bvn: e.target.value })}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                background: "#FF4B01",
                color: "#fff",
                borderRadius: "3px",
                fontSize: "14px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                padding: "10px 56.76px",
              }}
              onClick={() => handleSubmit()}
            >
              Add Account
            </Button>
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default AddAccount;
