import React, { useState,useEffect } from 'react';
import Swal from 'sweetalert2';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { BillerWrapper as Wrapper } from './style';

export const BillsPayment = ({userId, walletList,quickTeller, record}) => {
  const [tab, setTab] = useState('betting');
  const [categoryId, setCategoryId] = useState('betting');
  const [billerList, setBillerList] = useState([]);
  const [billerInfo, setBillerInfo] = useState([]);
  const [billerItems, setBillerItems] = useState([]);
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [plan,  setPlan] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedWallet, setSelectedWallet] =useState('')
  const [customerId, setCustomerId] = useState('')

  const getBillersByCategories = async () => {
    showLoader();
    let res;
    if (tab === 'betting') {
      res =  await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${quickTeller?14:'betting'}`)
      //await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/14`)
    }else if(tab === 'data'){
      res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${quickTeller?9:'databundle'}`)
     // await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/9`)

    }else if(tab === 'utility'){
      res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${quickTeller?1:'electricity'}`)
     // await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/1`)

    }else if(tab === 'airtime'){
      res =await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${quickTeller?4:'airtime'}`)
     // await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/4`)

    }else if(tab === 'cable'){
      res =await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${quickTeller?2:'cabletv'}`)
      //await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/2`)

    }

    if (res?.status) {
      hideLoader();
      setBillerList(res?.data);
      console.log(res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };

  const getBillerItems = async () => {
    showLoader();
   
     let res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${billerInfo.categoryId}/biller/${billerInfo.billerId}`)
    

    if (res?.status) {
      hideLoader();
      setBillerItems(res?.data?.items);
      console.log(res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };

useEffect(()=>{
  setBillerItems([]);
  getBillersByCategories();
  setAmount('');
  setAccountNo('');
},[tab]);

useEffect(()=>{
  getBillerItems();
},[billerInfo]);

const handleSubmit = async (e) => {
  e.preventDefault();
const data = {
  "amount": amount,
  "billerId": billerInfo.billerId,
  "categoryId": billerInfo.categoryId,
  "data": [
    {
      "name": "phone",
      "value": phone
    },
    {
      "name": "amount",
      "value": amount
    },
    {
      "name": "total_amount",
      "value": amount
    },
    {
      "name": "account_number",
      "value": accountNo
    },
    {
      "name": "smartcard_number",
      "value": accountNo
    },
    {
      "name": "plan",
      "value": plan
    },   
    {
      "name": "bundles",
      "value": plan
    },
    {
      "name": "account_number",
      "value": accountNo
    },
    {
      "name": "numberOfPins",
      "value": accountNo
    }
  ],
  "sourceWalletAccountNumber": selectedWallet,
  userId
}

let quickData = {
  "amount": amount,
  "billerId": billerInfo.billerId,
  "categoryId": billerInfo.categoryId,
    "data": [
      {
        "name": "customerMobile",
        "value": phone
      },
      {
        "name": "amount",
        "value": amount
      },
      {
        "name": "customerEmail",
        "value": record?.email
      },
      {
        "name": "customerId",
        "value": customerId
      },
      {
        "name": "paymentCode",
        "value": plan
      }
    
    ],
    "sourceWalletAccountNumber": selectedWallet,
    userId
  }

//return alert(JSON.stringify(data))
  showLoader();
 /*
  let res = await httpPost(`${customBaseUrl.billPaymentUrl}/api/v1/admin/make-payment-for-user?userId=${userId}`,
    data)
  */
 let d = new Date();
  let  res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(quickTeller?quickData:data),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "BILLSPAYMENT",
      customerName: record?.firstName + ' ' + record.lastName
    } )
  if (res?.status) {
    hideLoader();
    Swal.fire('Success', 'Transaction Successful', 'success');
    setBillerItems([]);
    setAmount('');
    setAccountNo('');
    console.log('succ',res)
  }else{
    Swal.fire('Error', res.message, 'error');
    console.log('err',res)
    hideLoader();
  }

}

const  accountType = { 
  'betting': 'betting',
  'utility': 'Meter no',
  'cable': 'Smartcard no',
  'airtime': 'Phone no',
  data: 'phone no'
                      }

  return (
    <Wrapper>
      <div className="tabBar">
        {/*<div
          onClick={() => setTab('betting')}
          className={`tabBar-item ${tab === 'betting' ? 'tB-active' : ''}`}
        >
          Betting 
        </div>*/}
        <div
          onClick={() => setTab('utility')}
          className={`tabBar-item ${tab === 'utility' ? 'tB-active' : ''}`}
        >
          Utility Bills
        </div>
        <div
          onClick={() => setTab('cable')}
          className={`tabBar-item ${tab === 'cable' ? 'tB-active' : ''}`}
        >
          Cable
        </div>
        <div
          onClick={() => setTab('airtime')}
          className={`tabBar-item ${tab === 'airtime' ? 'tB-active' : ''}`}
        >
          Airtime
        </div>
        <div
          onClick={() => setTab('data')}
          className={`tabBar-item ${tab === 'data' ? 'tB-active' : ''}`}
        >
          Data
        </div>
        <div
          onClick={() => setTab('betting')}
          className={`tabBar-item ${tab === 'betting' ? 'tB-active' : ''}`}
        >
          {quickTeller?'Others': 'Betting'}
        </div>
      </div>
      <div className="contentBar">
        <select value={JSON.stringify(billerInfo)} onChange={(e)=>{setBillerInfo(JSON.parse(e.target.value))}}>
          <option value="">Service Provider</option>
          {billerList.map((biller, index) => (
            <option key={index} value={JSON.stringify(biller)}>
              {biller.billerName}
            </option>
          ))}
        </select>


        <div className="contentBar-items">
          {billerItems.map((item, index) => (
            <div key={index} className="contentBar-item">
              {item.subItems.length>0 && 
                (<div key={index} className="contentBar-item-subItem">
                  <div className="contentBar-item-subItem-name">{item.paramName}</div>
                  <select onChange={(e)=>setPlan(e.target.value)}>
                    <option value="">Select</option>
                    {item.subItems.map((subItem, index) => (
                    <option value={subItem.id}>{`${subItem.name}  - ${subItem?.amount?'N':''}${subItem?.amount?subItem?.amount:''}`}</option>
                    ))}
                  </select>
                  </div>)} 
              </div>))}
        </div>

        <input type="text" placeholder={'Phone no.'} value={phone}  onChange={(e)=>setPhone(e.target.value)}/>
                    
        {quickTeller?<div>
      
        <input
        placeholder='Customer Id'
        type="text"  value={customerId}  onChange={(e)=>setCustomerId(e.target.value)}/>
         </div>:
          <div>

        {tab!=='airtime' && tab !== 'data' &&  <input type="text" placeholder={accountType[tab]} value={accountNo}  onChange={(e)=>setAccountNo(e.target.value)}/>}
        </div>}
        
        
        <input type="text" placeholder="Amount" value={amount}  onChange={(e)=>setAmount(e.target.value)}/>
               
        <div className="contentBar-items">
        <div class='contentBar-items m-2'>
          <label for='validationCustom07' class='form-label'>
            Select Wallet for Transaction
          </label>
          <select
            class='form-select border'
            id='validationCustom07'
            required
            onChange={(e)=>setSelectedWallet(e.target.value)}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { walletList.length>0 &&
            walletList?.map((option) => (
              <option key={option.accountNo} value={option?.accountNo}>
                {option?.acct_name +' - '+option?.accountNo}
              </option>
            ))}
          </select>
        </div>
        </div>

        
        <button onClick={handleSubmit}>Next</button>
      </div>
    </Wrapper>
  );
};
