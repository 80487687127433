import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpPost, httpPut } from '../../../store/http';
import { NotificationManager } from 'react-notifications';

const Action = ({ showModal, hideModal, trackingNo,disputeId, status, admins}) => {

    const [view, setView] = useState('default')
    const [refundAmount, setRefundAmount] = useState('')
    const [accept, setAccept] = useState(true)
    const [action, setAction] = useState('OPENED')
    const [admin, setAdmin] = useState('');

  const { RangePicker } = DatePicker;


  const Submit = async () => {
showLoader();
let data = {
    "accept": accept,
    "refundAmount": refundAmount
  }
let res =await httpPut(`${customBaseUrl.wayapayDispute}/accept-dispute/?trackingNumber=${trackingNo}`, data)

if(res.status){
    hideLoader();
    hideModal(false);
    Swal.fire('Done', res.message)
}else{
    hideLoader();
    Swal.fire('Error', res.message)
}

  }

  const handleAdmin = async () => {
    if(!admin) return NotificationManager.error('Select an admin to assign dispute to')
    showLoader();
    const {email, firstName, lastName, userId } = JSON.parse(admin)
    let name = firstName + ' '+ lastName;
    let res =await httpPost(`${customBaseUrl.wayapayDispute}/assign-dispute/${userId}?disputeId=${disputeId}&email=${email}&name=${name}`)
    
    if(res.status){
        hideLoader();
        hideModal(false);
        Swal.fire('Done', 'Dispute assigned')
    }else{
        hideLoader();
        Swal.fire('Error', res.message)
    }
    
      }

  const handleAction = async (action) => {
    showLoader();
    let data = {
        "status": action,
        "trackingNumber": trackingNo
      }
    let res =await httpPut(`${customBaseUrl.wayapayDispute}/dispute-action`, data)
    
    if(res.status){
        setView(action==='OPENED'?'accept':'reject')
        hideModal(false)
        hideLoader();
        Swal.fire('Done', res.message)
    }else{
        hideLoader();
        Swal.fire('Error', res.message)
    }
    
      }

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      title="Resolve Dispute"
      onCancel={() => {
        hideModal(false);
      }}
      width='30%'
    >
      <Wrapper>
        <div className="cw-content">
        {(view === 'default' && status !== 'WAYAPAY_REVIEWING')?<div className="cw-content">
        <button className='btn' style={{background: '#FF4B01',color: '#fff', marginBottom:20}} onClick={()=>setView('accept')}>
            Accept Dispute
        </button>
        <button className='btn btn-primary' onClick={()=>handleAction('CLOSED')}>
            Reject Dispute
        </button>
        </div>:
        (view === 'accept' && status !== 'WAYAPAY_REVIEWING')?<div className="cw-content ">
        <button className='btn border' style={{background: '#fff',color: '#000', marginBottom:20}} onClick={()=>setView('acceptByMe')}>
            Resolve By Me
        </button>
        <button className='btn border' style={{background: '#fff',color: '#000'}} onClick={()=>setView('assign')}>
            Assign to Others to Resolve
        </button>
        </div>:
            status === 'WAYAPAY_REVIEWING'?<div>
                <input className='input-content' placeholder={trackingNo}/>
                <label>Action</label>
                <select className='border input-content' style={{marginTop:10}} onChange={(e)=>setAction(e.target.value)}>
                    <option value={'OPENED'}>Open</option>
                    <option value={'PROCESSING'}>Processing</option>
                    <option value={'CLOSED'}>Close</option>                </select>

                <div className='center' style={{marginTop:10, textAlign:'center'}}>
                <button className='btn btn-primary' onClick={()=>handleAction(action)}>
            Send
        </button>
        </div>
            </div>:
            view === 'acceptByMe'?<div>
            <input className='input-content' placeholder={trackingNo} disabled/>
            <select className='border input-content' onChange={(e)=>setAccept(e.target.value)}>
                <option value={true}>Accept</option>
                <option value={false}>Reject</option>
            </select>
            <input className='input-content' placeholder='Refund Amount' onChange={(e)=>setRefundAmount(e.target.value)} />
        <div className='center' style={{marginTop:10, textAlign:'center'}}>
            <button className='btn btn-danger' onClick={Submit}>
              Send
              </button>
        </div>
        </div>:
        <div>
        <input className='input-content' placeholder={trackingNo} disabled/>
        <select className='border input-content' onChange={(e)=>setAdmin(e.target.value)}>
            <option value=''>Select Admin</option>
            {
              admins?.map(e=>
                <option value={JSON.stringify(e)}> {e?.firstName+' '+e?.lastName}</option>
              )
            }
        </select>
    <div className='center' style={{marginTop:10, textAlign:'center'}}>
        <button className='btn btn-danger' onClick={handleAdmin}>
          Send
          </button>
    </div>
    </div>
        }
        </div>

      </Wrapper>

     
    </Modal>
  );
};

export default Action;
