import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import {Wrapper} from '../style';
import { customBaseUrl, httpPost } from '../../../store/http';
import Swal from 'sweetalert2';
import { hideLoader, showLoader } from '../../../utils/loader';
import {
    FacebookShareButton,
    InstapaperShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,  
    WhatsappIcon,
    TwitterIcon,
    InstapaperIcon,
  } from "react-share";

const SocialInvites = ({ showModal, hideModal, type }) => {
const [data, setData] = useState({})

  const submit =async () => {
    let Data ={
      "msg": data.msg,
  "usersList": [
    {
      "email": data.email,
      "firstName": data.firstName,
      "phoneNumber": data.phoneNumber,
      "senderUserId": 510,
      "surname": data.surname
    }
  ]
    }
    showLoader()
    let res;
    if(type==='email'){
    res =await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/email`, Data)
    }else{
      res =await httpPost(`${customBaseUrl.contactUrl}/contact/account/service/send/sms`, Data)
 
    }
    if(res.status===true){
      hideLoader()
      hideModal(false)
      Swal.fire('Done', "Invitation send successfully")
    }else{
      hideLoader();
      Swal.fire("error", res.message)
    }

  }
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title='Send Via Social Media'
      width='40%'
    >

      <Wrapper>
        <div className="cw-content">
            <div style={{display:'flex', justifyContent:"space-around", flexDirection:'row'}}>
           <WhatsappShareButton title="Click on this link to signup Waya bank"
           url={"https://wayabank.ng/signup"}
        >
            <WhatsappIcon/>
            </WhatsappShareButton>
          
            <TwitterShareButton  url={"https://wayabank.ng/signup"} title="Click on this link to signup Waya bank">
                <TwitterIcon/>
            </TwitterShareButton>
           
            <FacebookShareButton  quote="Click on this link to signup Waya bank"      url={"https://wayabank.ng/signup"}>
            <FacebookIcon/>
            </FacebookShareButton>
            </div>
           
            <div style={{width: '30%', marginLeft:'auto', marginRight:'auto', marginTop:20}}>

            </div>
        
        </div>
      </Wrapper>
    </Modal>
  );
};

export default SocialInvites;
