import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "antd";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { customBaseUrl, httpPut } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import useMatStyles from "./style";
const CustomEdit = ({
  showModal,
  setShowModal,
  selectedRow,
  handleSubmit,
  handleGetPrices
}) => {
  const styles = useMatStyles();
const [capAmount, setCapAmount] = useState(selectedRow.capPrice);
const [discountAmount, setDiscountAmount] = useState(selectedRow.discount);
 const [customAmount, setCustomAmount] = useState(selectedRow.customAmount)
 const [priceType, setPriceType] = useState('')
  const handleUpdate =async () =>{
    const formdata = new FormData();
    formdata.append('capAmount', capAmount);
    formdata.append('discountAmount', discountAmount);
    formdata.append('customAmount', customAmount);
    formdata.append('product', selectedRow.code);
    formdata.append('userId', selectedRow.userId);
    formdata.append('priceType', priceType);

       
    showLoader();
    const response = await httpPut(`${customBaseUrl.walletUrl}/api/v1/pricing/custom-pricing`, formdata);
    if (response.status === true) {
      setShowModal(false);
      Swal.fire('Done', response.message)
      handleGetPrices();
      hideLoader();
    } else {
      Swal.fire('Error', response.message)
      hideLoader();
    }
  }
  return (
    <>
      <Dialog  open={showModal} onClose={() => {setShowModal(false)}} >
        <DialogContent>
            <h4 style={{textAlign:'center'}}>Custom Pricing</h4>
          <div className="row">
         

            <div className="col-md-12">

            <div className={styles.inputCont}>
                <div className={styles.inputLabel}>
                  Type
                </div>
                <select className="bordered" value={priceType} onChange={(e)=>setPriceType(e.target.value)}>
                  <option value=''>Select Type</option>
                  <option value='FIXED'>FIXED</option>
                  <option value='PERCENTAGE'>PERCENTAGE</option>
                </select>              
              </div>

              <div className={styles.inputCont}>
                <div className={styles.inputLabel}>
                  Rate
                </div>
                <input
                  type="number"
                  value={customAmount}
                  className={styles.inputInput}
                  onChange={(e) =>
                    setCustomAmount(e.target.value)
                  }
                />
              </div>



              <div className={styles.inputCont}>
                <div className={styles.inputLabel}>
                  Discount
                </div>
                <input
                  type="number"
                  value={discountAmount}
                  className={styles.inputInput}
                  onChange={(e) =>
                    setDiscountAmount(e.target.value)
                  }
                />
              </div>

              <div className={styles.inputCont}>
                <div className={styles.inputLabel}>
                  Processing Fees Capped at
                </div>
                <div style={{flexDirection:'row'}}>                  
                    <div>
                <input
                  type="number"
                  value={capAmount}
                  className={styles.inputInput}
                  onChange={(e) =>
                    setCapAmount(e.target.value)
                  }
                />
              </div>
              </div>
              </div>
              
              
           </div>
           </div>
        </DialogContent>
        <DialogContent>
        <Button onClick={()=>setShowModal(false)} className={styles.filledButton}>
                  Cancel
                </Button>  <Button onClick={handleUpdate} className={styles.filledButton}>
                  Okay
                </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CustomEdit;
