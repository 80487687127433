import React, { useState } from 'react';
import { WalletCardWrapper as Wrapper } from './style';
import More from '../../assets/icons/more.svg';
import Close from '../../assets/icons/close.svg';
import { Radio } from 'antd';
import { currencyFormat } from '../../utils/helper';


function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}


const WalletCard = ({
  icon,
  title,
  amount,
  accName,
  accNumber,
  setWalletId,
  walletId,
  totalTrans,
  walletAcct,
  setChecked,
  updateCurrentSlide,
  checked,
  dropDownItem,
  handleDropDown,
  blocked,
  data,
  lienAmount,
  blockAmount
}) => {
  const [dropDown, setDropDown] = useState(false);
  const [walletNo, setWalletNo] = useState(0);



  return (
    <Wrapper>
      <div className="wc-icons">
        <div>{icon ? <img src={icon} alt="icon" /> : ''}</div>
        <h6 style={{fontSize: 10, color: 'gray', fontStyle: 'italic'}}>Lien amount: {currencyFormat(lienAmount)}</h6>
        {totalTrans ? (
            <Radio
            className="align-baseline"
            checked={checked}
            onChange={() =>{
              setWalletId(walletAcct);
              updateCurrentSlide()
            }}
          />
          ) : (
            ''
          )}
        <div className="wc-icon-text">{title}</div>
        <div className="wc-more">
          <img
            src={dropDown === true ? Close : More}
            alt="more"
            onClick={() => setDropDown(!dropDown)}
            onKeyPress={() => setDropDown(!dropDown)}
            role="presentation"
            tabIndex="-1"
          />
          {dropDown ? (
            <div className="wc-dropdown">
              {dropDownItem?.length
                ? dropDownItem.map((item) => (
                  <div
                    className={`wc-drop-item ${item.style}`}
                    key={item.id}
                    onClick={() => handleDropDown(item?.id, data)}
                  >
                    {item.label}
                  </div>
                ))
                : ''}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={`wc-value ${blocked ? 'text-black-50' : ''}`}>
        {amount}
      </div>
      <div className="wcb-title">
          Block Amount: {blockAmount}
        </div>
      <div className="wc-bottom">
        <div className="wcb-item">
          <div className="wcb-title">Account Name</div>
          <div className={`wcb-detail ${blocked ? 'text-black-50' : ''}`}>
            {titleCase(accName)}
          </div>
        </div>
        <div className="wcb-item">
          <div className="wcb-title">Account Number</div>
          <div className={`wcb-detail ${blocked ? 'text-black-50' : ''}`}>
            {accNumber}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default WalletCard;
