import { CloseCircleOutlined } from "@ant-design/icons";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Button } from "antd";
import React from "react";
import Swal from "sweetalert2";
import useMatStyles from "../style";
import { customBaseUrl, httpPut } from "../../../../store/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import { NotificationManager } from "react-notifications";
const ChangeChargesModal = ({ showModal, hideModal, charge, getAll }) => {
  const styles = useMatStyles();
  const [newAmount, setNewAmount] = React.useState(0);

  const handleChangeChargeAmount = async () => {
    showLoader();
    if (newAmount === 0 || newAmount === ""|| newAmount === null) {
      hideLoader();
      NotificationManager.warning("Please enter a valid amount");
      return ;    
    }

    let data = {
      "chargeCustomer": true,
      "chargeWaya": true,
      "crncyCode": "NGN",
      "taxAmt": 0,
      "taxable": charge.taxable,
      "tranAmt": newAmount
    }

    const response = await httpPut(
      `${customBaseUrl.walletUrl}/api/v1/bank/update/event/${charge.id}`,
      data
    );
    if (response.status === true) {
      hideLoader();
      hideModal(false);
      getAll();
      Swal.fire({
        title: "Success",
        text: "Transaction charge updated successfully",
        icon: "success",
      });
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    
  };
  return (
    <Dialog open={showModal} maxWidth="md" fullWidth>
      <DialogTitle>
        {hideModal ? (
          <IconButton
            aria-label="close"
            onClick={() => hideModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseCircleOutlined />
          </IconButton>
        ) : null}
        <br />
        <div className={styles.title}> change {charge.eventId}</div>
      </DialogTitle>

      <DialogContent className={styles.cardContent}>
        <div class="col-md-12">
          <label for="validationCustom02" class="form-label">
            Current  {charge.chargeName}
          </label>
          <input
            style={{ width: "100%" }}
            type="email"
            class="form-control"
            id="validationCustom02"
            value={charge.tranAmt}
            required
            disabled
          />
        </div>
        <div class="col-md-12">
          <label for="validationCustom01" class="form-label">
            New {charge.chargeName}
          </label>
          <input
            type="text"
            style={{ width: "100%" }}
            class="form-control"
            id="validationCustom01"
            value={newAmount}
            required
            onChange={(e) => setNewAmount(e.target.value)}
          />
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleChangeChargeAmount()}
            className={styles.updateButton}
          >
            Update Fee
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ChangeChargesModal;
