import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {useMatStyles, Wrapper} from "./style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt } from "@mui/material";
import { Button, Input, Space } from "antd";
import { Delete, ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import CopyToClipboardComponent from "../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import { DeleteFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import AntTable from "../../components/CustomTable/AntTable";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: "",
  searchDate: [0,0]

}
const FeedbackComponent = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const styles = useMatStyles();
  const statistics = ["Total Debit", "Total Credit", "Total Transaction"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] =
    useState(false);
    const [filtered, setFiltered] = useState(true)
    const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalFeedBack, setTotalFeed] = useState(0)
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState(initial) 
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getFeedbacks(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getFeedbacks(0, value);
  };




  const columns = [
    {
      title: "Feedback",
      dataIndex: "answer",
      width: '5%'
    },
    {
      title: "Are you presently using wayabank",
      dataIndex: "active",
      render: (text) => <a>yes</a>,
      width: '3%'

    },
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: true,
      width: '3%'

    },
    {
      title: "  ",
      dataIndex: "active",
      render: (text) => <DeleteFilled/>,
      width: '10%'

    }
  ]
  const getFeedbacks = async (page, size) => {
    setFeedbacks([]);
    showLoader();
    setLoading(true);
    const response = await httpGet(
      `${customBaseUrl.contentManagementUrl}/api/v1/admin/feedback/user-feedback-list`      
    );

    if (response.status === true) {
      console.log(response.data)
      hideLoader();
      setFeedbacks(response.data);
      setTotalFeed(response.data.length);
      setLoading(false);
      //setFiltered(response.data.content)
    } else {
      hideLoader();
      setLoading(false);
    }
  };
  useEffect(() => {
    getFeedbacks();
  }, []);

  const handleShowFilter =() =>{
    setShowFilter(true)
    setSearch({...search, searchText:''})
  }
  const handleFilter = () => {
  //  return alert(JSON.stringify(feedbacks))
    setFiltered(false)
    setShowFilter(false)
    setSearch({...search, searchText:''})
    
    
  }
  const handleClearFilter = () => {
          //  return alert(JSON.stringify(feedbacks))
      setSearch({...search, searchText:''})
      setSearchData(initial)
      setShowFilter(false)
      setFiltered(true)
    }

    const handleSearch =() => {
        if(!search.searchText){
          return ;
        }

     //alert(JSON.stringify(feedbacks.filter(req=>req?.paymentReference.toLowerCase().includes(search?.searchText.toLowerCase())||req?.tranCategory.toLowerCase().includes(search?.searchText.toLowerCase()))))
        

    }


  return (
       <>
     <Wrapper>
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              <span style={{ float: "left" }} className={styles.diTop}>
                Total No. of Feedback 
              </span>
            </div>
            <br/>
            <div style={{justifyContent:'left', marginTop:50}}>
            <span className={styles.money}> {totalFeedBack}</span>

            </div>
          </Paper>
        
      </MatGrid>

      <div style={tool} class="col-md-5">
        
        <div className={styles.searchRow}>
          <Input
            className={styles.searchInput}
            placeholder="Search Feedbacks"
            value={search.searchText}
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div> 
        
        <div className={styles.searchRow}>
        <FilterList onClick={handleShowFilter }/>
            Filter
          </div>       
      </div>


      <MatGrid
        container
        direction="row"
        alignItems="center"
        className={styles.matGrid}
      >


        <div style={{ height: 800 }}>
           <AntTable
              pagination={null}
              users={search.searchText? feedbacks.filter(req=> req?.answer.toLowerCase()?.includes(search?.searchText.toLowerCase())):feedbacks}
              loading={loading}
              // handleGetNext={getWayaTransaction}
              columns={columns}
            />
          </div>
      </MatGrid>
      </Wrapper>
     </>
     
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default FeedbackComponent;
