import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import ColoredSwitcher from "../../../components/customSwitch/coloredSwitcher";
  import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
  import { hideLoader, showLoader } from "../../../utils/loader";
import ManageKycProviders from "./modal/updateKycProviders";
import AddKycProvider from "./modal/addProvider";
  
  const KycVendor = (props) => {
    const [vendors, setVendors] = useState([]);
    const [showManageProvider, setShowManageProvider] = useState(false);
    const [showCreateProvider, setShowCreateProvider] = useState(false);
    const [selectedProvide, setSelectedProvider] = useState({});
    const handleToggle = async (vendorIndex, vendorState, vendor) => {
      showLoader();
      const response = await httpPost(
        `${customBaseUrl.kycUrl}/provider/enable/${vendor.id}`,
        {}
      );
      hideLoader();
      if (response.status === true) {
        // handleProviderChange(vendorIndex, response?.data?.active);
        handleGetVendors();
      }
    };
    const handleGetVendors = async () => {
      showLoader();
      const response = await httpGet(
        `${customBaseUrl.kycUrl}/api/v1/provider/all?pageNo=1&pageSize=20`,
      );
      hideLoader();
      if (response.status === true) {
        setVendors(response?.data?.content);
      }
    };
    const handleProviderChange = (vendorIndex, vendorState) => {
      const vendorsCopy = [...vendors];
      vendorsCopy[vendorIndex].active = vendorState;
      setVendors(vendorsCopy);
    };
    useEffect(() => {
      handleGetVendors();
    }, []);
    return (
      <>
        <Card
          key={props.keyValue}
          style={{
            transitionDuration: "0.3s",
            minHeight: "30vw",
            display: "block",
          }}
          sx={{ marginTop: "30px" }}
          raised={true}
        >
          <CardContent>
            {/*<List dense={false}>
              
              {vendors.map((item, index) => (
                <Grid container spacing={10}>
                  <Grid key={item.id + item.name} item sm={4} sx={{ width: 1 }}>
                    <ListItem
                      key={item.id + item.name.trim()}
                      secondaryAction={
                        <IconButton key={item.id} edge="end" aria-label="delete">
                          <ColoredSwitcher
                            data={item}
                            type="sms-vendor"
                            dataIndex={item.id}
                            id={item.id.toString()}
                            style={{ fontWeight: "700" }}
                            label={''}
                            keyValue={item.id + item.name.trim()}
                            color="#ff6700"
                            onChange={handleToggle}
                          />
                        </IconButton>
                      }
                    >
                      <div
                        key={item.id}
                        style={{ fontFamily: "inherit", fontWeight: "700" }}
                      >
                        {item.name}
                      </div>
                      
  
                      {/* <ListItemText   secondary={item.name} /> *//*}
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
            </List> */}
            <button 
              onClick={() => {
                setShowCreateProvider(true)
              }}
            className="btn-primary">Add Provider</button>
            <table className="table">
              <thead>
                <tr>
                  <th>Provider Name</th><th>Process Type</th><th>Identity Type</th><th>Status</th><th>Action</th>
                </tr>
              </thead>
              <tbody>
                {vendors?.map(e=>
                  <tr><td>{e?.name ?? 'N/A'}</td><td>{e?.processType ?? 'N/A'}</td><td>{e?.identityType ?? 'N/A'}</td><td>{e?.isActive ? 'Active' : 'False'}</td>
                  <td><button onClick={()=>{
                    setSelectedProvider(e);
                   setShowManageProvider(true);
                  }}>Update</button></td></tr>
                  )}
              </tbody>
            </table>
          </CardContent>
          {
            showManageProvider ? 
            <ManageKycProviders
              showModal={showManageProvider}
              setShowModal={setShowManageProvider}
              providerData={selectedProvide}
              getProviders={handleGetVendors}
              /> : null
          }
           {
            showCreateProvider ? 
            <AddKycProvider
              showModal={showCreateProvider}
              setShowModal={setShowCreateProvider}
              getProviders={handleGetVendors}
              /> : null
          }
        </Card>
      </>
    );
  };
  export default KycVendor;
  