import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import Swal from 'sweetalert2';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';

export default function ResetPin() {
  const { profile } = useContext(ProfileContext);
  // const [postData, setPostData] = useState({});
  const [values, setValues] = useState({});

  const getOtpPhone = async () => {
    showLoader();
    const phone = profile.phoneNumber.slice(1);
    const response = await httpGet(
      `/api/v1/pin/forgot-pin/byPhone?phoneNumber=${phone}`,
      customBaseUrl.authUrl
    );
    hideLoader();
    const { message, data, status } = response;
    if (status === false) {
      return Swal.fire('error', message, 'error');
    }
    Swal.fire('Done', data, 'success');
  };

  const getOtpEmail = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/forgot-pin/byEmail?email=${profile.email}`
    );
    hideLoader();
    const { message, data, status } = response.data;
    if (status === false) {
      return Swal.fire('error', message, 'error');
    }
    Swal.fire('Done', data, 'success');
  };

  const handleSubmit = async () => {
    showLoader();

    if (values.pin !== values.confirmPin) {
      return Swal.fire('wrong', 'Pins do not match', 'error');
    }

    const response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/pin/forgot-pin`,
      values
    );
    hideLoader();
    const { message, status } = response.data;
    if (status === false || status === 404) {
      return Swal.fire('wrong', 'invalid token', 'error');
    } else if (status === false || status === 404) {
      return Swal.fire('error', message, 'error');
    }
    setValues({});
    Swal.fire('Done', `${message} successfully`, 'success');
  };

  return (
    <div className='create-pin'>
      <div className='title'>Reset Waya Official Pin</div>

      <div className='cp-content'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '35px',
          }}
        >
          <div
            className='cp-t-button'
            style={{
              background: '#27AE60',
            }}
            onClick={() => getOtpPhone()}
          >
            OTP Via Phone
          </div>
          <div
            className='cp-t-button'
            style={{
              background: '#0086E8',
            }}
            onClick={() => getOtpEmail()}
          >
            OTP Via Email
          </div>
        </div>
        <div className='input-content'>
          <div className='label'>OTP</div>
          <input
            name=''
            id=''
            value={values.otp}
            onChange={(e) => setValues({ ...values, otp: e.target.value })}
          />
        </div>
        <div className='input-content'>
          <div className='label'>Phone or Email</div>
          <input
            name=''
            id=''
            value={values.phoneOrEmail}
            onChange={(e) =>
              setValues({ ...values, phoneOrEmail: e.target.value })
            }
          />
        </div>
        <div className='input-content'>
          <div className='label'>New Pin</div>
          <input
            name=''
            id=''
            value={values.pin}
            onChange={(e) => setValues({ ...values, pin: e.target.value })}
          />
        </div>
        <div className='input-content'>
          <div className='label'>Confirm Pin</div>
          <input
            name=''
            id=''
            value={values.confirmPin}
            onChange={(e) =>
              setValues({ ...values, confirmPin: e.target.value })
            }
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            background: '#FF4B01',
            color: '#fff',
            borderRadius: '3px',
            fontSize: '14px',
            lineHeight: '17px',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 56.76px',
          }}
          onClick={() => handleSubmit()}
        >
          Send
        </Button>
      </div>
    </div>
  );
}
