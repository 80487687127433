import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { customBaseUrl, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import useMatStyles from "../../../views/admin/style";

const UpdateWalletModal = ({ showModal, hideModal, selectedItem,getWallets, title, style, userId, record }) => {
  return (
    <Dialog style={style} open={showModal} onClose={() => hideModal(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <UpdateWallet userId={userId} selectedItem={selectedItem} getWallets={getWallets} hideModal={hideModal} record={record} />
      </DialogContent>
    </Dialog>
  );
};
export default UpdateWalletModal;

const UpdateWallet = ({ userId, hideModal, record,getWallets, selectedItem }) => {
  const [data, setData] = useState({});
  const [desc, setDesc] = useState(selectedItem?.description)
  const styles = useMatStyles();


const handleSubmmit = async() => {
showLoader()
 let postData = {
    "accountType": "Savings",
    "description": data?.description,
    "simulated": data?.simulated,
    userId
     }

  //let res = await httpPost(`${customBaseUrl.walletUrl}/api/v1/wallet/create-wallet`,postData)
  let d= new Date();
 let res = await httpPost(`${customBaseUrl.walletUrl}/api/v1/wallet/update/account-description/${selectedItem?.accountNo}?description=${desc}`,
   )
  if(res?.body?.status){
    hideLoader()
    hideModal(false)
    Swal.fire('Done', res?.body?.message)
    getWallets();
  }else{
    hideLoader()
    console.log(res)
    Swal.fire('Error', res?.body?.message)
  }
  hideLoader()

}

  return (
    <div className="row">
      <div className="col-md-12">
      <div className={styles.inputCont}>
          <div className={styles.inputLabel}>Account Number</div>
          <input
            disabled
            value={selectedItem?.accountNo}
            className={styles.inputInput}
          />
        </div>        
        <div className={styles.inputCont}>
          <div className={styles.inputLabel}>Account Description</div>
          <input
            value={desc}
            className={styles.inputInput}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>        
      </div>
      <button className="btn btn-danger" style={{width: 150, marginTop:20, marginLeft:'auto', marginRight:'auto'}} onClick={handleSubmmit}>Update Wallet</button>
    </div>
  );
};
