import styled from "@emotion/styled";
import { createUseStyles } from "react-jss";
import { grey } from "@mui/material/colors";
const Wrapper = styled.div`
   .text-font {
    font-family: "Inter";
  }
  .ant-tabs-tab    {
    color: #B7B7B7 !important; 
    font-weight: 500;
 }
 .ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #ff6700 !important; 
  font-weight: 500;
 }
 .ant-tabs-ink-bar {
  position: absolute;
  background: #ff6700;
  pointer-events: none;
}
`;
export const useMatStyles = createUseStyles(() => ({
  viewUsersButton: {
    height: "25px",
    background: grey[800],
    float: "left",
    // marginLeft: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    fontFamily: "'Lato'",
    fontStyle: "normal",

    fontSize: "12px",

    color: "#FFFFFF",
    "&:hover": {
      background: grey[800],
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#FFFFFF",
    },
    "&:focus": {
      background: grey[800],
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#FFFFFF",
    },
  },
  filledButton: {
    height: "35px",
    background: "#ff6700",
    float: "right",
    // marginLeft: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.3px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#ff6700",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#FFFFFF",
    },
    "&:focus": {
      background: "#ff6700",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#FFFFFF",
    },
  },
  matGrid: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  modalSize: {
    width: "1000px",
  },
  tableHeader: {
    background: "#ff6700",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    inlineSize: "150px",
    overflowWrap: "break-word",
    // lineHeight: "15px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#ffffff",
  },
  table: {
    background: "#FFFFFF",
    borderRadius: "10px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#666666",
  },
  inputCont: {
    dispaly: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: "20px",
  },

  inputLabel: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "12px",
    color: "#000000",
    marginBottom: "5px",
  },
  inputInput: {
    background: "#FFFFFF",
    border: "1px solid #000000",
    boxSizing: "border-box",
    borderRadius: "5px !important",
    width: "100%",
    padding: "8px !important",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "12px",
  },
}));
export default Wrapper;