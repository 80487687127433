import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, DatePicker, Modal } from "antd";
import "../index.scss";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import { Divider, Pagination, Typography } from "@mui/material";
import { Stack } from "react-bootstrap";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

const initialState = {
  amount: "",
  crAccount: "",
  crAccountName: "",
  bankCode: "",
  bankName: "",
  narration: "",
  walletAccountNo: "",
  saveBen: "true",
  transactionPin:"",
  tranCrncy:"NGN",
  transRef: ""
  
};


const BulkWidrawalForm = ({ showModal, hideModal, title, className, userId, walletList, bankList, setCurrentView }) => {
  const [data, setData] = useState([{ ...initialState }]);
  const [user, setUser] = useState(initialState);
  const [page, setPage] = useState(1);
  const [showUserType, setShowUserType] = useState(false);
  const [userType, setUserType] = useState("");
  const [businessTypes,setBusinessTypes] = useState([]);
  const [trRef, setTrRef] = useState([]);

  const handleSubmit = async (e) => {
    let res;

    if(data.length>0){
    showLoader();
    /*
      res = await httpPost(
        `${customBaseUrl.withdrawalUrl}/admin/withdrawal/multipleFund`,
        data);
      }
    */
   let d= new Date()
   
      res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify(data),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "WithdrawMultiple",
        customerName: data?.acctName
      } )
    }
    if (res.status) {
      hideLoader();
      Swal.fire("Done", res.message, "success");
      hideModal(false);
      setCurrentView('default')

    } else {
      console.log(res);
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }
  };

  const clearState = () => {
    setUser({ ...initialState });
  };
  const handleChange = (event, value) => {
    const isEmpty = checkProperties(user);
    if (isEmpty) {
      Swal.fire("Warning", "Please fill all the fields", "warning");
    } else {
      console.log(user);
      event.preventDefault();
      setData((prevState) => [user, ...prevState]);
      setPage(data.length + 1);
      clearState();
    }
  };

  const handlePaginateChange = (event, pageV) => {
    const currentIndex = pageV - 1;
    setPage(pageV);
    setUser(data.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(data);
  };
  useEffect(() => {
    setTimeout(() => {
      setShowUserType(true);
    }, 300);
  }, []);

  useEffect(() => {
    console.log(data);
    let dataRec = data;
    dataRec[page - 1] = user;
    setData(dataRec);
  }, [user]);
  
 

  const handleRemoveUser = async () => {
    let current = page === 1 ? 1 : page - 1;
    await setData((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...initialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };

  const handleShowUserType = (value) => {
    if (value === false && userType === "") {
      hideModal(false);
    }
    setShowUserType(value);
  };


  const resolveBank = async (value) => {
    
    showLoader();
    if (value === '') {
      Swal.fire('Account Number is required');
      return;
    }
    const newPostData = {
      accountNumber: value,
      bankCode: user.bankCode,
      // bankName: postData.bankName,
    };
    const res = await httpPost(
      `${customBaseUrl.cardUrl}/bankAccount/resolveAccountNumber/WD`,
      newPostData
    );
    if (res.status) {
      hideLoader();
      setUser({
        ...user,
        crAccountName: res.data,
        crAccount: value,
      });
    } else {
      Swal.fire(res.message);
    }
    hideLoader();
  };

  const getRef= async ()=>{
    let d = new Date();
    let ref =  d.getTime();
    return JSON.stringify(ref);
  }

  useEffect(()=>{
    let d = new Date();
    let ref =  d.getTime();    
      //setTrRef(ref)
  },[])

  return (
    <>
      <Modal
        className={className}
        title={title}
        centered
        visible={showModal}
        onOk={() =>{  
                setCurrentView('default')
                hideModal(false)
                   }
            }   
        onCancel={() =>{
          setCurrentView('default')
           hideModal(false)}
          }
        width={1000}
        footer={0}
      >
        {/* <Divider>
        <div style={{ marginBottom: "20px" }}>
          <Typography>Page: {page}</Typography>
        </div>
      </Divider> */}
        <form class="row g-3 needs-validation" >
          <div class="col-md-6">
          <select
            class=" "
              onChange={(e) => {
                let d = new Date();
                let ref =  d.getTime();    
               setUser({
                 ...user,
                 bankCode: JSON.parse(e.target.value).bankCode,
                 bankName: JSON.parse(e.target.value).bankName,
                 userId: userId,
                 transRef: ref
               })
              }}
              value={user.bankCode?JSON.stringify(bankList?.filter((item) =>item.bankCode === user.bankCode)[0]):""}
            >
              <option value="" key='select' selected disabled>
                Select Bank
              </option>
              {bankList.length
                ? bankList.map((item,i) => (
                    <option value={JSON.stringify(item)} key={i}>
                      {item.bankName}
                    </option>
                  ))
                : ''}
            </select>
          </div>
          <div class="col-md-6">
          <input
              class="form-control "
              type='text'
              placeholder="Recipient's Account Number"
              value={user.crAccount}
              onChange={(e) => {
                if (e.target.value?.length > 9) {
                  resolveBank(e.target.value);
                }
                setUser({...user, crAccount: e.target.value})

              }}
            />
            </div>
            <div class="col-md-6">

            <input
            class="form-control "
            disabled={true}
              type='text'
              placeholder="Recipient's Account Name"
              value={user.crAccountName}
            />
            </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Narration
            </label>
            <input
              type="text"
              class="form-control"
              id="validationCustom02"
              value={user.narration}
              required
              onChange={(e) => setUser({ ...user, narration: e.target.value })}
            />
            <div class="invalid-feedback">Please put narration</div>
          </div>
          <div class="col-md-6">
          <select    
          className=""
            value ={user.walletAccountNo?JSON.stringify(walletList?.filter((item) =>item.accountNo === user.walletAccountNo)[0]):""}
           onChange={(e)=>setUser({...user, walletAccountNo:JSON.parse(e.target.value).accountNo, acctName: JSON.parse(e.target.value).acct_name})}>
          <option value="">Select Wallet</option>
          {walletList.length
            ? walletList.map((item) => (
                <option
                 // value={`${item.accountNo},${item?.clr_bal_amt}`}
                 value={(JSON.stringify(item))}
                  key={item}
                >
                  {`${item?.acct_crncy_code}-${item?.accountNo}-${item?.acct_name}`}
                </option>
              ))
            : ''}
        </select>
        
            <div class="invalid-feedback">Wallet is required.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Amount
            </label>
            <input
              type="number"
              class="form-control"
              id="validationCustom02"
              value={user.amount}
              required
              onChange={(e) =>
                setUser({ ...user, amount: e.target.value })
              }
            />
            <div class="invalid-feedback">Transaction Pin</div>
          </div>         
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Pin
            </label>
            <input
              type="pin"
              class="form-control"
              id="validationCustom02"
              value={user.transactionPin}
              required
              onChange={(e) =>
                setUser({ ...user, transactionPin: e.target.value })
              }
            />
            <div class="invalid-feedback">Amount is required.</div>
          </div>         
          
          <div style={{ marginTop: "10px" }} className="row">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "200px",
                gap: "10px",
              }}
              className="col-md-3"
            >
              <Button
                className="iconUI"
                style={{ color: "#C4C4C4", border: "1px solid #C4C4C4" }}
                onClick={handleChange}
                icon={<PlusCircleOutlined />}
              >
                Add More User
              </Button>
            </div>
            <div className="col-md-3">
              <Button
                className="iconUI"
                onClick={handleRemoveUser}
                style={{ color: "#FF4B01", border: "1px solid #FF4B01" }}
                icon={<CloseOutlined />}
              >
                Remove User
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="iconUI"
                type="ghost"
                onClick={handleSubmit}
                style={{ color: "#fff", background: "#FF4B01", float: "right" }}
              >
                Withdraw
              </Button>
            </div>
          </div>

          <div className="row" style={{ marginTop: "7px" }}>
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <Stack spacing={2}>
                <Pagination
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    alignSelf: "center",
                  }}
                  count={data.length}
                  page={page}
                  onChange={handlePaginateChange}
                />
              </Stack>
            </div>
            <div className="col-md-3"></div>
          </div>

          {/* <div class="col-12 text-center mt-5">
          <button class="btn btn-primary" type="submit" onClick={handleSubmit}>
            Submit form
          </button>
        </div> */}
        </form>
      </Modal>
    </>
  );
};
export default BulkWidrawalForm;
