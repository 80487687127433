import styled from 'styled-components';

export const RequestWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .tabBar {
    background: #faf3f3;
    border-radius: 30px;
    width: 60%;
    height: 46px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    .tabBar-item {
      border-radius: 30px;
      height: 100%;
      width: calc(100% / 3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      cursor: pointer;
    }
    .tB-active {
      background: #ff4b01;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }
  .contentBar {
    width: 90%;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 43px;
    input,
    select {
      background: #f5f5f5;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3px;
      width: 100%;
      padding: 14px;
      font-size: 14px;
      line-height: 17px;
      color: #828282;
      margin-bottom: 19px;
    }
    button {
      background: #ff4b01;
      border-radius: 3px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      width: 100%;
      padding: 13px;
    }
  }
`;
