import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import { useMatStyles } from "./style";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import { FilterList, GroupAddOutlined, Settings } from "@mui/icons-material";
import Invite from "./invite";
import ChangeRole from "./changeRole";
import Swal from "sweetalert2";

const ManageTeam = () => {
  const theme = useTheme();
  const { id: id } = useParams();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showSendUser, setShowSendUser] = useState(false);
  const [showTransferExcel, setShowTransferExcel] = useState(false);
  const [showTransferForms, setShowTransferForms] = useState(false);
  const [showInvite, setShowInvite ] = useState(false)
  const [showChangeRole, setShowChangeRole] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "referralUser",
    searchText: "",
  });
  const [metaData, setMetaData] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [filterValue, setFilterValue] = useState(false);
  const [fileteredMeta, setFilteredMeta] = useState(0);
  const [roles, setRoles] = useState([])


  const getAllTeamMembers = async (page, size) => {
    setTeamMembers([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.roleUrl}/api/v1/user-access/members/${id}/?page=${pages}&size=${limit}`
    );
      console.log(request.data);
      console.log('dtaa',request);
      if(request.status){
      setTeamMembers(request?.data?.members);
      setMetaData(request?.data?.totalItems);
    hideLoader();
      }else{
        hideLoader()
        Swal.fire('Error', request.message)
      }
  };

 
  useEffect(() => {
    getAllTeamMembers();
    getRoles();
}, []);

const getRoles = async () => {

  let req = await httpGet(`${customBaseUrl.roleUrl}/api/v1/role/all`)
  console.log('roles',req)
  setRoles(req)

  
}

 

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getAllTeamMembers(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getAllTeamMembers(newPage, pagination.pageSize);
  };

const handleRemove =(item)=>{

  Swal.fire({
    title: "Are you sure?",
    text: "You want to remove this user?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, remove it!",
  }).then((result) => {
    if (result.value) {
      handleRemoveUser(item);
    }
  });

}

const handleRemoveUser=async (item) => {
  showLoader();
  let res = await httpDelete(`${customBaseUrl.roleUrl}/api/v1/user-access/invite/corporate/waya/${item?.id}`)
  console.log('res', res)
  if (res.status=== false){
    hideLoader();
    Swal.fire('Error', res.message)
    getAllTeamMembers()
  }else{
    hideLoader();
    Swal.fire('Done')
    getAllTeamMembers()
  }

}

  var searchInput = useRef({});
  const columns =  React.useMemo(() =>  [
    
    { field: 'inviteeName',
      headerName: 'Name',
      width: 200 ,
      headerClassName: styles.tableHeader,

    },
   
    { field: 'email',
      headerName: "Email",
      width: 200,
      headerClassName: styles.tableHeader,

    },
    { field: 'role',
    headerName: "Role",
    width: 250,
    headerClassName: styles.tableHeader,
    renderCell: ({row})=> (row.role.name)?.replace(/_/g,' ')


    },
   
   

    { field: 'idd',
    headerName: "Actions ",
    width: 250,
    headerClassName: styles.tableHeader,
    renderCell: ({row})=><div>
      <Button size='small' onClick={()=>{setSelectedRow(row); setShowChangeRole(true) }} >Change Role</Button>
      <Button size='small' onClick={()=>handleRemove(row)}>Remove</Button>
    </div>

    },

  ]);


  
  return (
    <Layout>
    <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
      <div style={tool}>
       
      <div className={styles.searchRow}>
        Team Members - {metaData}
          
        </div>
        <Row type="flex" justify="end" align="middle">  
         
        <div style={{alignContent:'end', marginLeft: 'auto',marginRight: 0}}>   
       
          <Button
              onClick={() => navigate("/admin/manage_roles")}
              size="medium"
              style={{  fontSize: "14px",  }}
            >
              <Row>
              <Settings/>
              Manage Roles
              </Row>  
            </Button> 
            <Button
            onClick={()=>setShowInvite(true)}
              size="medium"
              style={{ background: "#FF4B01", fontSize: "14px", color: "#fff" }}
            >
              <Row>
              <GroupAddOutlined/>
              Invite Someone
              </Row>
            </Button>  
            </div>  
        </Row>
       
      </div>




      


      <Grid
      getRowId ={row=>row.normalUserId}
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {teamMembers.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
      getRowId ={row=>row.id}
      className={styles.table}
         density="comfortable"
        rows={teamMembers }
    
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
          //  components={{ Toolbar: GridToolbar }}
            rowCount={filterValue?fileteredMeta: metaData}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      {
        showInvite && <Invite getAllTeamMembers={getAllTeamMembers} hideModal={setShowInvite} corporateId={id} showModal={showInvite} roles={roles}/>
      }
       {
        showChangeRole && <ChangeRole getAllTeamMembers={getAllTeamMembers} hideModal={setShowChangeRole} id={id} selectedRow={selectedRow} setSelectedRow={setSelectedRow} showModal={showChangeRole} roles={roles}/>
      }
    
      </Card.Grid>
        </Card>
    </Layout>
  );
};

const gridStyle = {
  width: "100%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "50px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default ManageTeam;
