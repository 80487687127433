import React, { useState, useEffect } from 'react';
import { Modal, Tabs } from "antd";
import { RequestWrapper as Wrapper } from './style';
import CustomTabs from "../../../components/CustomTabs";
import ReceivedRequests from './received';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpDelete, httpGet, httpPost, httpPut } from "../../../store/http";
import Swal from 'sweetalert2';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../../store/httpCalls';
import SentRequests from './sent';
import { useNavigate } from 'react-router-dom';
import RejectPaymentRequest from '../../Transaction/PaymentRequest/modals/reject';

const { TabPane } = Tabs;

export const Requests = ({ setCurrentView, record, charges }) => {
  const navigate = useNavigate();

  const [tab, setTab] = useState('send');
  const [receiveReq, setReceivedReq] = useState([]);
  const [sentReq, setSentReq] = useState([]);
const [meta, setMetaData] = useState(0)
  const [data, setData] = useState([]);
  const [userType, setUserType] = useState('waya');
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [walletList, setWalletList] = useState([]);

  const getReceivedRequests = async (page, size) => {
    showLoader();
    const pages = page ? page : 0;
    const limit = size ? size : 10;
     const res = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request-received/${record.userId}?page=${pages}&size=${limit}`
    );
    if (res?.status) {
      hideLoader();
      console.log(res.data)
      setReceivedReq(res.data.request);     
      setMetaData(res?.data?.totalItems)
    }else{
      hideLoader();
    }
    hideLoader();
  }
  const getSentRequests = async () => {
    showLoader();
    
     const res = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request-sent/${record.userId}`
    );
    if (res?.status) {
      hideLoader();
      console.log(res.data)
      setSentReq(res.data.request);     
    }else{
      hideLoader();
    }
    hideLoader();
  }

  useEffect(() => {
    getReceivedRequests();
    getSentRequests();
    console.log('charges', charges)

  }, []);

  const handleSubmit = async () => {
    let d = new Date();
   let postData = {
      amount: data.amount,
      createdAt: d,
      reason: data.reason,
      receiverEmail: data.receiverEmail,
      receiverId: userType==='nonWaya'?"non waya":data.receiverId,
      receiverName: data.receiverName,
      receiverPhoneNumber: data.receiverPhoneNumber,
      senderEmail: record.email,
      senderId: record.userId,
      senderName: `${record.firstName} ${record.lastName}`,
    }

   // return alert(JSON.stringify(postData));
    showLoader();

    //const formBody = Object.keys(postData).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(postData[key])).join('&');
    let res;
    if (userType === 'nonWaya'){
   /* res = await httpPost(
      `${customBaseUrl.paymentRequestUrl}/api/v1/admin/non-waya/single-payment-request`,
      postData
    );*/
    res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "paymentRequest",
      customerName: `${record.firstName} ${record.lastName}`
    } )
    }else if (userType === 'waya'){
    /*  res = await httpPost(
        `${customBaseUrl.paymentRequestUrl}/payment-request-single-waya`,
        postData
      );
      */
      res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      requestId: `REQ${d.getTime()}`,
      requestType: "paymentRequest",
      customerName: `${record.firstName} ${record.lastName}`
    } )
    }
    console.log(res)
    if (res?.status) {
      hideLoader();
      setData({...data, amount: '', reason: '', receiverEmail: '', receiverName: '', receiverPhoneNumber: ''});
      setQuery('');
      Swal.fire('Done', res?.message, 'success');
      
    }else{
      hideLoader();
      Swal.fire('Error', res?.message || res?.receiverPhoneNumber|| res?.amount || res?.reason, 'Failed');
    }
  }
  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }
  const handleSelectOption = (selectedUser) => {
    console.log(selectedUser);
    getWallets((selectedUser.userId));
    setData({ ...data, receiverId: selectedUser.userId, receiverName: `${selectedUser.firstName} ${selectedUser.surname}`, receiverEmail: selectedUser.email, receiverPhoneNumber: selectedUser.phoneNumber });
    setQuery(`${selectedUser.firstName} ${selectedUser.surname}`);
   /* setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);*/
    setOptions([]);
  };

  const getWallets = async (userId) => {
    //  return alert(userId);
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}`
      );
     // alert(JSON.stringify(res));
      if (res?.status) {
        setWalletList(res.data);
      }
    };
    
  return (
    <Wrapper>
      <div className='tabBar'>
        <div
          onClick={() => setTab('send')}
          className={`tabBar-item ${tab === 'send' ? 'tB-active' : ''}`}
        >
          Send Requests
        </div>
        <div
          onClick={() => setTab('recieve')}
          className={`tabBar-item ${tab === 'recieve' ? 'tB-active' : ''}`}
        >
          Recieve Requests
        </div>
        <div
          onClick={() => setTab('all')}
          className={`tabBar-item ${tab === 'all' ? 'tB-active' : ''}`}
        >
          All Requests
        </div>
      </div>

           
      {tab === 'send'?<div className='contentBar'>
        <select value={userType}  onChange={(e)=>setUserType(e.target.value)}>
          <option value='waya'>Waya Users</option>
          <option value='nonWaya'>Non Waya Users</option>
        </select>
      
        {userType === 'waya' &&<div style={{ position: 'relative' }} >
          <label for='validationCustom01' class='form-label'>
            
          </label>
          <input
           placeholder='Search Receiver Name'
            type='text'
            class='form-control'
            id='validationCustom01'
            value={query}
            required
            onChange={handleSearch}
          />
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>}

        <input type='number' value={data.amount} onChange={(e)=>setData({...data, amount:e.target.value})} placeholder='AMOUNT' />
        {userType === 'nonWaya' &&<input type='text' value={data.receiverName} onChange={(e)=>setData({...data, receiverName:e.target.value})} placeholder='RECEIVER NAME' />}
        <input type='email' value={data.receiverEmail} onChange={(e)=>setData({...data, receiverEmail:e.target.value})} placeholder='RECEIVER EMAIL' />        
        <input type='text' value={data.receiverPhoneNumber} onChange={(e)=>setData({...data, receiverPhoneNumber:e.target.value})} placeholder='RECEIVER PHONE'  />
        <input type='text' value={data.reason} onChange={(e)=>setData({...data, reason:e.target.value})} placeholder='REASON'  />
        
        
        <button onClick={handleSubmit}>
          Request Payment
        </button>
      </div>:
      tab === 'recieve'?<div className='contentBar'>
          <ReceivedRequests meta={meta} getReceivedRequests={getReceivedRequests} record={record} request={receiveReq} />
        </div>:
      tab === 'all'?<div>
        <CustomTabs totalTabs="2">
            <TabPane tab="Sent Requests" key="1">
              <div className='contentBar'>
                {sentReq.length>0 ? <SentRequests request={sentReq} getSentRequests={getSentRequests}/>:
                <div>
                You have not made any requests
                </div>}
              
              </div>
            </TabPane>
            <TabPane tab="Received Requests" key="2">
              <div className='contentBar' style={{ marginTop: "20px" }}>
               <ReceivedRequests meta={meta} charges={charges}  request={receiveReq} record={record}  getReceivedRequests={getReceivedRequests}/>
              </div>
            </TabPane>
            </CustomTabs>
       
          
      </div>:''
        }
          

    </Wrapper>
  );
};
