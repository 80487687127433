import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import '../../../index.scss';
import { customBaseUrl, httpGet, httpPatch, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { Button, Modal } from 'antd';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../../store/httpCalls';

const ChangeRole = ({ showModal, hideModal, className,getAllTeamMembers,id, selectedRow, roles}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [roleId, setRoleId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('')
  
 

  const handleAssign = async() => {
    showLoader();
  let res;
  let data = {
    email: selectedRow.email.trim(),
    roleId: roleId
  }
    res = await httpPut(`${customBaseUrl.roleUrl}/api/v1/user-access/invite/corporate/waya/${selectedRow?.id}`,data)


      if(res.status === false){
        hideLoader();
        Swal.fire("Error", res.message)
      hideModal(false)
      }else{
        getAllTeamMembers();
        hideModal(false);
        hideLoader()
        console.log(res)
        Swal.fire('Done', 'Role Changed Successfully')
      }

  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}

    >
        <div style={{textAlign:'center '}}>
          <h5>Change Role</h5>
          <div>
            This action will change the role of this user. The user will no longer have access to the previous role.
          </div>
        </div>

        <div style={{ position: 'relative' }} class="col-md-12 mt-4">
          
          <select value={roleId} onChange={(e)=>setRoleId(e.target.value)}   class='form-control '
            id='validationCustom03'>
            <option value="">Select New Role For This User</option>
            {roles?.map((e,i)=>
                <option value={e?.id}>{e?.name}</option>
            )}
          </select>
       
        </div>

        <div class='col-12  mt-5'>
          <Button   type='submit' onClick={handleAssign}
                  style={{ background: "#FF4B01", fontSize: "14px", color: "#fff", width:'100%', marginBottom:10 }}
          >
            Change
          </Button>
          <Button   type='submit' onClick={()=>hideModal(false)}
           style={{ background: "#FF4B01", fontSize: "14px", color: "#fff", width:'100%' }}

          >
            Cancel
          </Button>
        </div>

                   
        
    </Modal>
  );
};

export default ChangeRole;
