import React, { useState, useContext, useEffect } from "react";
import { Modal, Button } from "antd";
import { SettingsWrapper as Wrapper } from "./style";
import { showLoader, hideLoader } from "../../../utils/loader";
import Swal from "sweetalert2";
import { httpPost, customBaseUrl, httpGet } from "../../../store/http";
import { ProfileContext } from "../../../store/context/ProfileContext";

const AddCard = ({ showModal, hideModal, user, walletList, getCards, userId, admin }) => {
  const [values, setValues] = useState({});
  const { profile } = useContext(ProfileContext);

 // const [walletList, setWalletList] = useState([]);
  useEffect(() => {
   // getWalletList();
  } , [])
 /* const getWalletList = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/admin/get-waya-official-account`
    );
    if (res?.status) {
      setWalletList(res.data);
    }
  };*/
  const handleSubmit = async () => {
    showLoader();
   /* const res = await httpPost(`${customBaseUrl.cardUrl}/card/add`, {
      ...values,
      userId,
      surname:admin?profile.lastName: user?.lastName ,
      email: admin?profile.email:user?.email,
      accountNo: values.accountNo,
      last4digit: values.cardNumber.substr(values.cardNumber.length - 4),
    });
    */
   let cardData={
    ...values,
      userId,
      surname:admin?profile.lastName: user?.lastName ,
      email: admin?profile.email:user?.email,
      accountNo: values.accountNo,
      last4digit: values.cardNumber.substr(values.cardNumber.length - 4)
   }
   let d= new Date();
   let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
        {
          payload: JSON.stringify(cardData),
          "requestId": `REQ${d.getTime()}`,
          "requestType": "ADDCARD",
          customerName: admin?profile.lastName: user?.lastName +' '+user?.lastName
        } )
    hideLoader();
    if (res?.status) {
      getCards();
      console.log(res)
      hideModal(false);
      Swal.fire({
        title: "Success",
        text: 'Operation Successful',
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      hideLoader();
      console.log(res)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res?.message,
      });
    }
  };
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      
      maskClosable={false}

      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
       <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '27px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="create-pin w-100">
          <div className="title">Add Card</div>
          <div className="cp-content w-100">
            <div className="input-content">
              <div className="label">Card Number</div>
              <input
                name=""
                id=""
                value={values.cardNumber}
                onChange={(e) =>
                  setValues({ ...values, cardNumber: e.target.value })
                }
              />
            </div>
            <div className="input-content">
              <div className="label">Expiry Month</div>
              <input
                name=""
                id=""
                value={values.expiryMonth}
                onChange={(e) =>
                  setValues({ ...values, expiryMonth: e.target.value })
                }
              />
            </div>
            <div className="input-content">
              <div className="label">Expiry Year</div>
              <input
                name=""
                id=""
                value={values.expiryYear}
                onChange={(e) =>
                  setValues({ ...values, expiryYear: e.target.value })
                }
              />
            </div>
            <div className="input-content">
              <div className="label">CVV Code</div>
              <input
                name=""
                id=""
                value={values.cvv}
                onChange={(e) => setValues({ ...values, cvv: e.target.value })}
              />
            </div>
            <div className="input-content">
              <div className="label">Card Pin</div>
              <input
                name=""
                id=""
                value={values.pin}
                onChange={(e) => setValues({ ...values, pin: e.target.value })}
              />
            </div>
            <div className="input-content">
              <div className="label">Select Wallet</div>
              <select
                name=""
                id=""
                value={values.accountNo?JSON.stringify(walletList?.filter((item) =>item.accountNo === values.walletAccountNo)[0]):""} 

                onChange={(e) => setValues({ ...values, accountNo: JSON.parse(e.target.value).accountNo, name:JSON.parse(e.target.value).acct_name })}

              >
         <option  value='' >select wallet</option>
         {walletList.length
                  ?walletList.map((item, index) => (
                    <option key={index} value={(JSON.stringify(item))}>{item.acct_name}</option>
                  ))
                :''}
              </select>              
        
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                background: "#FF4B01",
                color: "#fff",
                borderRadius: "3px",
                fontSize: "14px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                padding: "10px 56.76px",
              }}
              onClick={() => handleSubmit()}
            >
              Add Card
            </Button>
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default AddCard;
