import React, { useState } from 'react';
import Swal from 'sweetalert2';
import validator from 'validator';
import { Input, Row, Button, Typography } from 'antd';
import { useNavigate } from 'react-router';
import style, { ForgotPasswordWrapper } from './style';
import loginLogo from '../../assets/images/logo.svg';
import { customBaseUrl, httpPost } from '../../store/http';
const { Paragraph, Title } = Typography;

export default function ForgotPassword() {
  const { navigate } = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pin, setPin] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validator.isEmpty(password)) {
      Swal.fire('Oops!', 'Password cannot be empty', 'error');
      setLoading(false);
      return;
    }
    if (!validator.equals(password, confirmPassword)) {
      Swal.fire('Oops!', 'Password do not match', 'error');
      setLoading(false);
      return;
    }
    if (validator.isEmpty(pin)) {
      Swal.fire('Oops!', 'Pin cannot be empty', 'error');
    }
    const postData = {
      phoneOrEmail: localStorage.getItem('email'),
      newPassword: password,
      otp: Number(pin),
    };
    const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/password/forgot-password`,
      postData,
      true
    );
    if (res.status) {
      setLoading(false);
      Swal.fire('Done', res.message, 'success').then(() => {
        localStorage.removeItem('email');
        window.location.href = "/";
      });
    } else {
      setLoading(false);
      Swal.fire('Oops!', res.message, 'error');
    }
  };

  return (
    <ForgotPasswordWrapper>
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh' }}
      >
        <div className='wrapper' style={{ width: '39%' }}>
          <img src={loginLogo} alt='reset-logo' style={style.login.left.logo} />
          <Title level={2} align='middle'>
            Reset your password
          </Title>
          <Paragraph align='middle'>
            Fill in the text to request for a password reset. A link will be
            sent to your mail for a password reset.
          </Paragraph>

          <Input.Password
            placeholder='New Password'
            size='large'
            style={style.login.input}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <br />

          <Input.Password
            placeholder='Confirm Password'
            size='large'
            style={style.login.input}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <br />
          <br />

          <Input
            size='large'
            placeholder='otp'
            onChange={(e) => setPin(e.target.value)}
          />

          <Button
            type='primary'
            htmlType='submit'
            size='large'
            block
            loading={loading}
            style={{ ...style.login.button, marginTop: '45px' }}
            onClick={handleSubmit}
          >
            Save Password
          </Button>
        </div>
      </Row>
    </ForgotPasswordWrapper>
  );
}
