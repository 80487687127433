import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import Swal from 'sweetalert2';
import { SettingsWrapper as Wrapper } from './style';
import BankAccount from '../../../components/CustomCard/bankAccount';
import CreatePin from './CreatePin';
import ResetPin from './ResetPin';
import CardList from '../../../components/ManageBankCards';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { customBaseUrl, httpDelete, httpGet, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import AddBankAccount from './AddBankAccount';
import AdvanceNotifications from './notifications';
import validator from 'validator';

const WayaOfficialSettings = ({ showModal, hideModal, walletList }) => {
  const { profile } = useContext(ProfileContext);
  const [activeTab, setActiveTab] = useState('createPin');
  const [bankAccounts, setBankAccounts] = useState([]);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [email, setEmail] = useState(profile.email);
  const [phoneNumber, setPhoneNumber] = useState(profile.phoneNumber);
  const [address, setAddress] = useState(profile.address);
  const [firstName, setFirstName] = useState(profile.firstName);
  const [lastName, setLastName] = useState(profile.lastName);
  const [passData, setPassData] = useState([]);
  const dropDownItem2 = [
    { id: 'deleteAccounr', label: 'Delete Account', style: 'text-danger' },
    { id: 'makeDefault', label: 'Make Default' },
  ];

  const handleDropDown = (id, item) => {
    if (id === 'deleteAccounr') {
      deleteAccount(item?.accountNumber, profile?.userId, item?.userId);
    }
  };

  const getAccount = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/admin/list/userBankAcct/${profile?.userId}`
    );

    if (res?.status) {
      setBankAccounts(res?.data);
    }
    // setMetaData(data.metaInfo);
  };

  const deleteAccount = async (accountNumber, adminId, userid) => {
    showLoader();
    const res = await httpDelete(
      `${customBaseUrl.cardUrl}/admin/deleteBank/${userid}/${accountNumber}/${adminId}`
    );
    if (res?.status) {
      getAccount();
      hideLoader();
      Swal(res?.message);
    } else {
      hideLoader();
      Swal(res?.message);
    }
  };
  const getOtpPhone = async () => {
    showLoader();
    const phone = profile.phoneNumber.slice(1);
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byPhone?phoneNumber=${phone}`,
    );
    hideLoader();
    const { message, data, status } = response;
    if (status === false) {
      return Swal.fire('error', message, 'error');
    }
    Swal.fire('Done', data, 'success');
  };

  const getOtpEmail = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byEmail?email=${profile.email}`
    );
    hideLoader();
    const { message, data, status } = response.data;
    if (status === false) {
      return Swal.fire('error', message, 'error');
    }
    Swal.fire('Done', data, 'success');
  };

  const handleSubmit = async (e) => {
    showLoader();
    if (validator.isEmpty(passData.pass1)) {
      Swal.fire('Oops!', 'Password cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (!validator.equals(passData.pass1, passData.pass2)) {
      Swal.fire('Oops!', 'Password do not match', 'error');
      hideLoader(false);
      return;
    }
    if (validator.isEmpty(passData.otp)) {
      Swal.fire('Oops!', 'OTP cannot be empty', 'error');
    }
    const postData = {
      phoneOrEmail: localStorage.getItem('email'),
      newPassword: passData.pass1,
      otp: Number(passData.otp),
    };
    const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/password/forgot-password`,
      postData,
      true
    );
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
    } else {
      hideLoader(false);
      Swal.fire('Oops!', res.message, 'error');
    }
  };
  useEffect(() => {
    getAccount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=''
      bodyStyle={{
        padding: '40px',
        borderRadius: '9px',
      }}
      width={1028}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '27px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className='tab-bar'>
          <div
            className={activeTab === 'createPin' ? 'tb-item-active' : 'tb-item'}
            onClick={() => setActiveTab('createPin')}
          >
            CREATE PIN
          </div>
          <div
            className={
              activeTab === 'linkedCard' ? 'tb-item-active' : 'tb-item'
            }
            onClick={() => setActiveTab('linkedCard')}
          >
            LINKED CARDS
          </div>
          <div
            className={
              activeTab === 'bankAccount' ? 'tb-item-active' : 'tb-item'
            }
            onClick={() => setActiveTab('bankAccount')}
          >
            BANK ACCOUNTS
          </div>
          <div
            className={
              activeTab === 'notifications' ? 'tb-item-active' : 'tb-item'
            }
            onClick={() => setActiveTab('notifications')}
          >
            NOTIFICATIONS
          </div>
          <div
            className={
              activeTab === 'resetPassword' ? 'tb-item-active' : 'tb-item'
            }
            onClick={() => setActiveTab('resetPassword')}
          >
            RESET WAYA PASSWORD
          </div>
          <div
            className={activeTab === 'resetPin' ? 'tb-item-active' : 'tb-item'}
            onClick={() => setActiveTab('resetPin')}
          >
            RESET PIN
          </div>
          <div
            className={
              activeTab === 'updateDetails' ? 'tb-item-active' : 'tb-item'
            }
            onClick={() => setActiveTab('updateDetails')}
          >
            UPDATE WAYA DETAILS
          </div>
        </div>
        {activeTab === 'updateDetails' ? (
          <div className='create-pin'>
            <div className='title'>Update Waya Official Details</div>

            <div className='cp-flex'>
              <div
                className='input-content'
                style={{
                  width: '32%',
                }}
              >
                <div className='label'>First Name</div>
                <input value={firstName} onChange={(evt)=> setFirstName(evt.target.value)}  name='firstName' />
              </div>
              <div
                className='input-content'
                style={{
                  width: '32%',
                }}
              >
                <div className='label'>Last Name</div>
                <input name='lastName' value={lastName} onChange={(evt)=> setLastName(evt.target.value)} />
              </div>
              <div
                className='input-content'
                style={{
                  width: '32%',
                }}
              >
                <div className='label'>Email Address</div>
                <input name='email' value={email} onChange={(evt)=> setEmail(evt.target.value)}/>
              </div>
            </div>
            <div className='cp-flex'>
              <div
                className='input-content'
                style={{
                  width: '32%',
                }}
              >
                <div className='label'>Phone Number</div>
                <input name='phoneNumber' value={phoneNumber} onChange={(evt)=> setPhoneNumber(evt.target.value)} />
              </div>
              <div
                className='input-content'
                style={{
                  width: '66%',
                }}
              >
                <div className='label'>Address</div>
                <input name='address' value={address} onChange={(evt) => setAddress(evt.target.value)} />
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  background: '#FF4B01',
                  color: '#fff',
                  borderRadius: '3px',
                  fontSize: '14px',
                  lineHeight: '17px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 56.76px',
                }}
                onClick={() => {}}
              >
                Send
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
        {activeTab === 'createPin' ? <CreatePin /> : ''}
        {activeTab === 'resetPin' ? <ResetPin /> : ''}
        {activeTab === 'notifications' ? <AdvanceNotifications walletList={walletList} /> : ''}

        {activeTab === 'linkedCard' ? (
          <CardList userId={profile?.userId} walletList={walletList} admin={true} />
        ) : (
          ''
        )}
        {activeTab === 'bankAccount' ? (
          <div className='linkedCard'>
            <div className='card-div'>
              {bankAccounts?.map((item) => (
                <div
                  style={{
                    width: '32%',
                    marginRight: '41px',
                  }}
                >
                  <BankAccount
                    accName={item?.accountName}
                    accNumber={item?.accountNumber}
                    bankName={item?.bankName}
                    dropDownItem={dropDownItem2}
                    handleDropDown={handleDropDown}
                    data={item}
                  />
                </div>
              ))}
            </div>
            <div className='add-div' onClick={() => setShowAddAccount(true)}>
              <div>+</div>
              <div>ADD Account</div>
            </div>
          </div>
        ) : (
          ''
        )}
        {activeTab === 'resetPassword' ? (
          <div className='create-pin'>
            <div className='title'>Reset Waya Official Password</div>

            <div className='cp-content'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '35px',
                }}
              >
                <div
                  className='cp-t-button'
                  style={{
                    background: '#27AE60',
                  }}
                  onClick={getOtpPhone}
                >
                  OTP Via Phone
                </div>
                <div
                  className='cp-t-button'
                  style={{
                    background: '#0086E8',
                  }}
                  onClick={getOtpEmail}
                >
                  OTP Via Email
                </div>
              </div>
              <div className='input-content'>
                <div className='label'>OTP</div>
                <input name='' id='' value={passData.otp} onChange={(e) => setPassData({ ...passData, otp: e.target.value})} />
              </div>
              <div className='input-content'>
                <div className='label'>New Password</div>
                <input name='' id='' value={passData.pass1} onChange={(e) => setPassData({ ...passData, pass1: e.target.value})} />
              </div>
              <div className='input-content'>
                <div className='label'>Confirm Password</div>
                <input name='' id='' value={passData.pass2} onChange={(e) => setPassData({ ...passData, pass2: e.target.value})}/>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  background: '#FF4B01',
                  color: '#fff',
                  borderRadius: '3px',
                  fontSize: '14px',
                  lineHeight: '17px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 56.76px',
                }}
                onClick={handleSubmit}
              >
                Send
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </Wrapper>
      <AddBankAccount
        showModal={showAddAccount}
        hideModal={setShowAddAccount}
        getAccount={getAccount}
      />
    </Modal>
  );
};

export default WayaOfficialSettings;
