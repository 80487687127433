import React, { useState, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Col, Row, Card, Tag } from 'antd';
import WalletIcon from '../../assets/icons/wallet-icon';
import SmartPhoneIcon from '../../assets/icons/smart-phone-icon';
import TempUserIcon from '../../assets/icons/temp-user-icon';
import CoinIcon from '../../assets/icons/coin-icon';
import image from '../../assets/images/user-image.png';
import { StatCards } from './StatCards';
import { error } from '../../services/swal-mixin';
import { hideLoader, showLoader } from '../../utils/loader';
import { customBaseUrl, httpGet } from '../../store/http';
import { currencyFormat } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles(() => ({
  mainContainer: {
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 172,
    paddingTop: 25,
    borderRadius: 7

  },
  cardsContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4
  },
  valueContainer: {
    marginTop: 18,
  },
  text: {
    margin: 0,
    marginBottom: 3,
    textAlign: 'center',
    fontSize: 10,
    color: '#000000',
    fontWeight: '500',
  },
  textAmt: {
    margin: 0,
    textAlign: 'center',
    fontSize: 25,
    color: '#000000',
    fontWeight: 'normal',
    // lineHeight: 30.26
  },
  recentCard: {
    marginTop: 5,
  },
  recentList: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 38,
  },
  title: {
    Weight: '500',
    fontStyle: 'normal',
    fontSize: 12,
  },
  btn: {
    width: 87,
    height: 24,
    backgroundColor: '#121212',
    borderRadius: 2,
    color: '#ffffff',
    fontSize: 10,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 22,
  },
  userColItem: {
    display: 'flex',
  },
  userCol: {
    flexBasis: '50%',
  },
  name: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: '15px',
    color: '#000000',
  },
  phone: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: '15px',
    color: '#BDBDBD',
  },
  btnCol: {
    width: 99,
    height: 29,
    background: '#F5F5F5',
    border: '1px solid #D5D5D5',
    boxSizing: 'border-box',
    borderRadius: 3,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineGeight: '15px',
    color: '#000000',
  },
  recentImg: {
    width: 38,
    height: 38,
    borderRadius: 38,
    marginRight: 8,
  },
  listHeaderName: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#858585',
    flexBasis: '15%',
  },
  listHeaderDesc: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#858585',
    flexBasis: '50%',
  },
  listHeaderStatus: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#858585',
    flexBasis: '10%',
  },
  listHeaderDate: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#858585',
    flexBasis: '15%',
  },
  listName: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#000000',
    flexBasis: '15%',
  },
  listDesc: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#000000',
    flexBasis: '50%',
  },
  listStatus: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '15px',
    color: '#16B079',
    flexBasis: '10%',
  },
  listDate: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#000000',
    flexBasis: '15%',
  },
}));

function DashboardOverView({ state, analysis }) {
  const theme = useTheme();
  const navigate = useNavigate()
  const classes = useStyles({ theme });
  // const [data, setData] = useState([]);
  const [overview, setOverview] = useState({
    totalAvailableWalletBalance: 0,
    totalClearedBalance: 0,
    totalCommission: 0,
  });
  const [totalTransaction, setTotalTransaction] = useState(0)
  const [totalRegUsers, setTotalRegisteredUsers] = useState(0)
  const [tempUser, setTempUser] = useState(0);
  const [totalCorporateUsers,setTotalCorporateUsers] = useState(0)
  const [totalIndividualUsers,setTotalIndividualUsers] = useState(0)
  const [activeUsers, setTotalActiveUsers] = useState(0);
  const [inactiveUsers, setTotalInactiveUsers] = useState(0);
  const [blockUsers, setTotalBlockUsers] = useState(0);
  const [overallAnalysis, setOverall] = useState([]);
  const [categoryAnalysis, setCategoryAnalysis] = useState([]);
  const [users, setUsers] = useState([]);
  const [kycStat, setKycStat] = useState([]);
  const [tierStat, setTierStat] = useState([]);


  // const getWallet = async () => {
  //   const response = await httpGet(
  //     '/api/v1/wallet/find/all',
  //     customBaseUrl.walletUrl
  //   );
  // };

 

  const fetchOverview = async () => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/overall-based-analysis`
    );
    
    if (res?.status) {
      //setFiltered(response.data.content)
      setOverall(res.data);
      hideLoader();
    } else {
      console.log(res)
      hideLoader();   
     }

     };

     const fetchCategory = async () => {
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/category-based-analysis`
      );
      
      if (res?.status) {
        //setFiltered(response.data.content)
        setCategoryAnalysis(res.data);
        hideLoader();
      } else {
        console.log(res)
        hideLoader();   
       }
  
       };
  
     const getStat = async() =>{
      let res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/dashboard/users-analysis`
      );
      if (res?.status){
        setTotalCorporateUsers(res?.data?.coorporateUsers);
        setTotalIndividualUsers(res?.data?.personalUsers);
        setTotalRegisteredUsers(res?.data?.registeredUsers);
        setTotalActiveUsers(res?.data?.activeUsers);
        setTotalInactiveUsers(res?.data?.inactiveUsers);
        setTotalBlockUsers(res?.data?.blockedUsers);



      }

      /*if (totalCorporateUsersResponse !== null) {
        setTotalCorporateUsers(totalCorporateUsersResponse);
      }
      let totalIndividualUsersResponse = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/dashboard/total-registered-individual-users`
      );
      if (totalIndividualUsersResponse !== null) {
        setTotalIndividualUsers(totalIndividualUsersResponse);
      }
      let totalRegisteredUsersResponse = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/dashboard/total-registered-users`
      );
      if (totalRegisteredUsersResponse !== null) {
        setTotalRegisteredUsers(totalRegisteredUsersResponse);
      }    


      let totalActiveUsersResponse = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/dashboard/active-users`
      );
      if (totalActiveUsersResponse !== null) {
        setTotalActiveUsers(totalActiveUsersResponse);
      }
      let totalInactiveUsersResponse = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/dashboard/in-active-users`
      );
      if (totalInactiveUsersResponse !== null) {
        setTotalInactiveUsers(totalInactiveUsersResponse);
      }
     */
     }



     const handleGetUsers = async (params = {}) => {
      const res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/admin/users?page=0&size=10`
      );
      setUsers(res?._embedded?.userResponse);

      console.log(res);
      
    };
  
    const getKycStat = async() =>  {
      let res = await httpGet(`${customBaseUrl.kycUrl}/api/v1/admin/kyc/analysis`)
      if(res.status){
        setKycStat(res.data)        
      }
    }
    const getTierStat = async() =>  {
      let res = await httpGet(`${customBaseUrl.kycUrl}/kyc/tier/count-users-by-tier`)
      if(res.status){
        setTierStat(res.data)
      }
    }


  useEffect(() => {
    handleGetUsers();
    fetchOverview();
    getKycStat()
    getTierStat();
    getStat();
    //fetchCategory();
  }, []);
  useEffect(()=>{
    console.log('analyss', analysis);
    setOverall(analysis);
  },[analysis])

  // const CalculateAmount = () => {
  //   let sum = 0;
  //   for (let val of data) {
  //     sum += parseFloat(val.summary.availableBalance);
  //   }
  //   return sum;
  // };

  // const calculate = CalculateAmount();
  // const val = calculate.toString();

  console.log(overview, tempUser);

 {/* const lists = [
    {
      icon: <CoinIcon />,
      title: 'Total Transaction Amount',
      value: currencyFormat(totalTransaction),
      id: 1,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Registered Users',
      value: totalRegUsers,
      id: 3,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Business Users',
      value: totalCorporateUsers,
      id: 3,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Personal Users',
      value: totalIndividualUsers,
      id: 3,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Business Users',
      value: totalCorporateUsers,
      id: 3,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Personal Users',
      value: totalIndividualUsers,
      id: 3,
    },
   
  ];*/}

  const overall = [
    {
      icon: <WalletIcon />,
      title: 'Total Balance',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalBalance)}</h6>, count: overallAnalysis?.overallAnalysis?.countResponse?.totalBalance},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Deposit',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalDeposit)}</h6>, count: overallAnalysis?.overallAnalysis?.countResponse?.countDeposit},
      id: 2,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Withdrawal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalWithdrawal)}</h6>, count: overallAnalysis?.overallAnalysis?.countResponse?.countWithdrawal || overallAnalysis?.overallAnalysis?.countResponse?.countWithdrawalcountWithdrawal},
      id: 3,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Revenue',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.overallAnalysis?.sumResponse?.totalRevenue)}</h6>, count: overallAnalysis?.overallAnalysis?. countResponse?.totalRevenue},
      id: 4,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Commission to Merchant',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 5,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Commission to Aggregator',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 6,
    },   
  ];
  const business = [
    {
      icon: <WalletIcon />,
      title: 'Total Transaction Amount - Business',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Income Amount - Business',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 2,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Outgoing Amount - Business',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 3,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Waya Revenue - Business',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 4,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Commission - Business',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 5,
    },
  ];

  const personal = [
    {
      icon: <WalletIcon />,
      title: 'Total Transaction Amount - Personal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Income Amount - Personal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 3,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Outgoing Amount - Personal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 3,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Waya Revenue - Personal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 3,
    },
    {
      icon: <CoinIcon />,
      title: 'Total Commission - Personal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 3,
    },
  ]
  const category = [
    {
      icon: <WalletIcon />,
      title: 'Total Bills Payment- BAXI',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.billsPaymentTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.billsCount || overallAnalysis?.categoryAnalysis?.countResponse?.baxiCount},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Bills Payment- Quickteller',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.quicketllerPayment)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.quicktellerCount|| overallAnalysis?.categoryAnalysis?.countResponse?.quickTellerCount},
      id: 1,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Outbound External Transfer',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.outboundExternalTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.outboundExternalCount},
      id: 2,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Outbound Internal Transfer',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.outboundInternalTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.outboundInternalCount},
      id: 3,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Inbound Transfer - NIP',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.nipInbountTrans?? 0)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.nipCount},
      id: 4,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Inbound Transfer - WayaQuick',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.totalWayaQuickTrans?? 0)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.wayaquickCount??'-'},
      id: 5,
    },
    {
      icon: <WalletIcon />,
      title: 'Total Inbound Transfer - Paystack',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.categoryAnalysis?.sumResponse?.totalPaystackTrans)}</h6>, count: overallAnalysis?.categoryAnalysis?.countResponse?.paystackCount},
      id: 6,
    },
   
  ];
  const userAnalysis = [
    {
      icon: <TempUserIcon />,
      title: 'Total Registered Users',
      value: {value: '', count: totalRegUsers},
      id: 1,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Business Users',
      value: {value: '', count: totalCorporateUsers},
      id: 2,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Personal Users',
      value: {value: '', count: totalIndividualUsers},
      id: 3,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Active Users',
      value: {value: '', count: activeUsers},
      id: 4,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Inactive Users',
      value: {value: '', count: inactiveUsers},
      id: 5,
    },
    {
      icon: <TempUserIcon />,
      title: 'Total Blocked Users',
      value: {value: '', count:  blockUsers},
      id: 6,
    },
   
  ];

  const kycAnalysis = [
    {
      icon: <VerifiedUserIcon style={{color:'red'}} />,
      title: 'Total KYC Pending Approval',
      value: {value: '', count: kycStat?.pending},
      id: 1,
    },
    {
      icon: <VerifiedUserIcon style={{color:'red'}} />,
      title: 'Total Rejected KYC',
      value: {value: '', count: kycStat?.rejected??0},
      id: 2,
    },
    {
      icon: <VerifiedUserIcon style={{color:'red'}} />,
      title: 'Total KYC Approved T1',
      value: {value: '', count: kycStat?.tier1},
      id: 3,
    },
    {
      icon: <VerifiedUserIcon style={{color:'red'}} />,
      title: 'Total KYC Approved T2',
      value: {value: '', count: kycStat?.tier2},
      id: 4,
    },
    {
      icon: <VerifiedUserIcon style={{color:'red'}} />,
      title: 'Total KYC Approved T3',
      value: {value: '', count: kycStat?.tier3},
      id: 5,
    },
    {
      icon: <VerifiedUserIcon style={{color:'red'}} />,
      title: 'Total KYC Approved T4',
      value: {value: '', count: kycStat?.tier4},
      id: 6,
    },
   
  ];
  return (
    <>
      <div style={{marginBottom:10, borderRadius:10}}>
      <StatCards lists={overall} state={state} classes={classes} title='Overall Transactions Analysis' />
      </div>
      {/*<div style={{marginBottom:10, borderRadius:10}}>
      <StatCards lists={business} state={state} classes={classes} title='Business Transactions Analysis' />
      </div>
      <div style={{marginBottom:10, borderRadius:10}}>
      <StatCards lists={personal} state={state} classes={classes} title='Personal Transactions Analysis'/>
      </div>*/}
      <div style={{marginBottom:10, borderRadius:10}}>
      <StatCards lists={category} state={state} classes={classes} title='Category Based Transaction Analysis'/>
      </div>
      <div style={{marginBottom:10, borderRadius:10}}>
      <StatCards lists={userAnalysis} state={state} classes={classes} title='Users Analysis'/>
      </div>
      <div style={{marginBottom:10, borderRadius:10}}>
      <StatCards lists={kycAnalysis} state={state} classes={classes} title='KYC Analysis'/>
      </div>
      {//<StatCards lists={lists} state={state} classes={classes} />
      }
      <Row gutter={6}>
        <Col className="gutter-row" span={24}>
          <Card className={classes.recentCard}>
            <div className={classes.recentList}>
              <h4 className={classes.title}>RECENT USERS</h4>
              <button type="button" className={classes.btn}>
                View All Users
              </button>
            </div>
            {users?.map((item,i) => (
              <div className={classes.listItem} key={i}>
                <div className={classes.userCol}>
                  <div className={classes.userColItem}>
                    <img
                      src={image}
                      alt="recent users"
                      className={classes.recentImg}
                    />
                    <span>
                      <h4 className={classes.name}>{item?.firstName} {item?.lastName}</h4>
                      <p className={classes.phone}>{item?.phoneNumber}</p>
                    </span>
                  </div>
                </div>
                <button type="button" className={classes.btnCol} onClick={()=>navigate(`/users/profile/${item?.userId}`)
}>
                  Manage User
                </button>
              </div>
            ))}
          </Card>
        </Col>
        
      </Row>
    </>
  );
}

export default DashboardOverView;
