import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #828282;
    margin-bottom: 16px;
    text-align: center;
  }
  .content {
    background: #f5f5f5;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 28px 31px;
    margin-bottom: 29px;
    .ci-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .c-item {
        display: flex;
        flex-direction: column;
        text-align: left;
        .c-title {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #828282;
          margin-bottom: 9px;
        }
        .c-det {
          font-family: Lato;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height */

          color: #064a72;
        }
      }
    }
  }
  .otp-info {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
  }
  .otp-cont {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
  }
  .otp-input {
    border: 0;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 2px;
    width: 56.71px !important;
    outline: 0;
  }
  .sum-bot {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      background: rgb(255, 102, 52);
      border-radius: 4px;
      padding: 10px 120px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      text-align: center;
    }
  }
`;

export default Wrapper;
