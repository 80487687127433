import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { BugOutlined, CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import { searchUsers } from "../../../store/httpCalls";
import SearchDropdown from "../../../components/CustomDropdown/SearchDropdown";


const BillerForm = ({ showModal, hideModal, title, userId,className}) => {

  const [data, setData] = useState([]);

  return (
    <Modal
    className={className}
    centered
    visible={showModal}
    onOk={() => hideModal(false)}
    onCancel={() => hideModal(false)}
    width={800}
    footer={0}
    closable={false}
    title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >


        
      <Wrapper class='row g-3 needs-validation border mb-2 p-3'>
        <div class='col-md-12 m-2'>
          <label for='validationCustom07' class='form-label'>
            Service  Provider ID
            </label>
          <input
          class='form-control'
          type="text"  value={data.amount}  onChange={(e)=>setData({...data, amount:e.target.value})}/>
        </div>      
      </Wrapper>

    
      <div style={{ marginTop: "10px", textAlign: 'center', alignContent: 'center' }} className="row">
        <div
          style={{
            
           
          }}
          className="col-md-2"
        >
          <Button
            className="iconUI"
            style={{ color: "#ffffff", border: "1px solid #C4C4C4",
            margin: 0,
            position: 'absolute',
            left: '45%',
            background: '#ff6700',
            borderRadius: 5
          }}

          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BillerForm;
