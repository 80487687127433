import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Avatar, Button, Drawer, List, Radio, Skeleton, Space } from 'antd';
import "./index.css";
import { DeleteOutline } from '@mui/icons-material';
import { ReadOutlined } from '@ant-design/icons';
import AntDropdown from '../components/CustomDropdown/AntDropdown';
import { hideLoader, showLoader } from '../utils/loader';
import { customBaseUrl, httpPatch, httpPut } from '../store/http';
import Swal from 'sweetalert2';


const Notification = ({open,getNotification, setOpen, notifications,totalNotifications,handleDelete, pagination, handleLoadMore}) => {
  const [placement, setPlacement] = useState('right');
  
  const onClose = () => {
    setOpen(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const loadMore =
  (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
    {pagination.current>0 &&<Button onClick={()=>handleLoadMore('back')}>Prev</Button>}
    {pagination.current<Math.floor(totalNotifications/pagination.pageSize) &&  <Button onClick={()=>handleLoadMore('next')}>Next</Button>}
    </div>
  ) ;

      const handleRead = async(id) =>{
        let data = {
            "read": true
          }
        showLoader()
        let res =await httpPut(`${customBaseUrl.notificationUrl}/in-app-notifications/${localStorage.getItem('userId')}/${id}`, data)
        if(res.status){
          hideLoader()
          Swal.fire('Done', res.message)
          await getNotification(pagination.current, pagination.pageSize);
        }else{
          hideLoader()
          Swal.fire('Error', res.message)
        }
      }
      const handleUnRead = async (id) =>{
        let data = {
            "read": false
          }
        showLoader()
        let res =await httpPut(`${customBaseUrl.notificationUrl}/in-app-notifications/${localStorage.getItem('userId')}/${id}`, data)
        if(res.status){
          hideLoader()
          Swal.fire('Done', res.message)
          await getNotification(pagination.current, pagination.pageSize);
        }else{
          hideLoader()
          Swal.fire('Error', res.message)
        }
      }

 const notOptions = ['Mark as read','Delete']
  const notAction =(item, id)=>{
    if (item === 'Mark as read') handleRead(id)
    if (item === 'Mark as unread') handleUnRead(id)
    if (item === 'Delete') handleDelete(id)
  }
  return (
    <div>
     
      <Drawer
        title="Notifications"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={open}
      >
        <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item
          actions={[ <AntDropdown dropDownOptions={[item?.read?'Mark as unread':'Mark as read','Delete']} handleDropdownAction={(e)=>notAction(e, item.notificationId)}/>]}
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <div style={{flexDirection:'column'}}>
            <div style={{color: item?.read?'grey':'#000'}}>
                {item.content}             
            </div>
           
             </div>
          </Skeleton>
        </List.Item>
      )}
    />
      </Drawer>
    </div>
  );
};

export default Notification;