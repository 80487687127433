import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import wayabankLogo from "../../../../assets/images/logo.svg";
import React, { useEffect } from "react";
import { Button } from "antd";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import { Print, Save } from "@mui/icons-material";

function setPageSize(cssPageSize) {
  const style = document.createElement('style');
  style.innerHTML = `@page {size: ${cssPageSize}; margin: 0}`;
  style.id = 'page-orientation';
  document.head.appendChild(style);
}


const ViewTransaction = ({ transaction, setShowModal, showModal,orientation = 'landscape' }) => {
  transaction = Object.fromEntries(
    Object.entries(transaction).filter(
      ([_, v]) => v != null && v != undefined && v != ""
    )
  );

  useEffect(() => {
    setPageSize(orientation);
    return () => {
        const child = document.getElementById('page-orientation');
        child.parentNode.removeChild(child);
    };
}, [orientation]);
  return (
    <>
      <Dialog fullWidth maxWidth="md" open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle id="alert-dialog-title">
          <div className="row">
            <div className="col-md-4">
              <img src={wayabankLogo} alt="Wayabank" />
            </div>
            <div className="col-md-6">{"Transaction Receipt"}</div>
            <div className="col-md-2">
                <Button icon={<CloseOutlined />} onClick={() => setShowModal(false)}></Button>
            </div>
          </div>

          <div id="logo" style={{ textAlign: "center" }}></div>
        </DialogTitle>
        <DialogContent>
          <div className="row" >
            <div className="col-md-6">
              <div style={{ float: "right" }}>
                <b>
                  <div>Transaction Amount</div>
                  <div>Transaction Date</div>
                  <div>Reference Number </div>
                  <div>Transaction Type</div>
                  <div>Transaction Category</div>
                  <div>Sender Name </div>
                  <div>Sender Account</div>
                  <div>Receiver Name </div>
                  <div>Receiver Account</div>
                  <div>Receiver bank</div>
                  <div>Narration</div>
                </b>
              </div>
            </div>
            <div className="col-md-6">
              
              <div>
                {transaction.tranAmount}
              </div>
              <div>
                {transaction.createdAt}
              </div>
              <div>
                {transaction.paymentReference}
              </div>
              <div>
              {transaction.partTranType === "C" ? "CREDIT" : "DEBIT"}
              </div>
              <div>                
              {transaction.tranCategory}
              </div>
              <div>
                {transaction.senderName||'-'}
              </div>
              <div>
              {(transaction.acctNum)||'-'}
              </div>
              <div>
                {transaction.receiverName|| '-'}
              </div>
              <div>
                {transaction.receiverAccount|| '-'}
              </div>
              <div>
                {transaction.receiverBank || '-'}
              </div>
              <div>
                {transaction.tranNarrate||'-'}
                </div>
            </div>
          </div>
        </DialogContent>
        <div style={{flexDirection:'row', marginLeft: '40%'}}>
         <Button  icon={<Print/>} onClick={() => window.print()}>Print</Button>
        <Button  icon={<Save/>} onClick={()=>  window.print() }>Save</Button>
        <div>
        <b>NOTE:</b>
        <p>You'll be able to dispute this after 24 hours of transaction time</p>
        </div>
        </div>

      </Dialog>
    </>
  );
};
export default ViewTransaction;
