import React, { useState } from "react";
import Layout from '../../../components/Layout'
import { Card, Col, Row, Button, Modal } from 'antd';
import GenerateUsers from "./GenerateUsers";
import DebitUsers from "./massDebit";
import DeleteUsers from "./DeleteMassUser";


const SimulatedUsersFund = () => {
    const [visible, setVisible] = useState(false);
    const [fundVisible, setFundVisible] = useState(false);
    const [debitVisible, setDebitVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);

    return (
        <Layout>
            <div><h5>Overview</h5></div>
            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col span={8}>
                        <Card bordered={false}>
                            <h4>Generate Users</h4>
                            <p>Generate users for simulation</p>
                            <Button type="primary" onClick={() => setVisible(true)}>
                                Proceed
                            </Button>

                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            <h4>Fund Users</h4>
                            <p>Fund simulated users </p>
                            <Button type="primary" onClick={() => setFundVisible(true)}>
                                Proceed
                            </Button>

                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            <h4>Debit Users</h4>
                            <p>Debit wallet balance of simulated users </p>
                            <Button type="primary" onClick={() => setDebitVisible(true)}>
                                Proceed
                            </Button>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} className='my-3'>
                    <Col span={8}>
                        <Card bordered={false}>
                            <h4>Delete Users</h4>
                            <p>Delete simulated users </p>
                            <Button type="primary" onClick={() => setDeleteVisible(true)}>
                                Proceed
                            </Button>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            <h4>Transact</h4>
                            <p>Simulate transactions between private users and marchants</p>
                            <Button>Proceed</Button>
                        </Card>
                    </Col>
                    {/* <Col span={8}>
                        <Card title="Card title" bordered={false}>
                            Card content
                        </Card>
                    </Col> */}
                </Row>
            </div>
            <Modal

                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={400}
                footer={[]}
            >
                <GenerateUsers
                    setVisible
                />
            </Modal>
            <Modal

                centered
                visible={fundVisible}
                onOk={() => setFundVisible(false)}
                onCancel={() => setFundVisible(false)}
                width={800}
                footer={[]}
            >

            </Modal>
            <Modal

                centered
                visible={debitVisible}
                onOk={() => setDebitVisible(false)}
                onCancel={() => setDebitVisible(false)}
                width={900}
                footer={[]}
            >
                <DebitUsers
                    setDebitVisible
                />
            </Modal>
            <Modal

                centered
                visible={deleteVisible}
                onOk={() => setDeleteVisible(false)}
                onCancel={() => setDeleteVisible(false)}
                width={700}
                footer={[]}
            >
                <DeleteUsers
                    setVisible
                />
            </Modal>
        </Layout>
    )
}
export default SimulatedUsersFund