import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import LinkBvn from '../../components/Modal/Bvn';
import PinModal from '../../components/Modal/Pin/PinModal';
import Button from '../../components/Button/Button';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';

export default function VerifyEmailAndPhone({ showAlert, type, setShowAlert, profile }) {
  const [showBvn, setShowBvn] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendVerifyEmailOtp, setResendVerifyEmailOtp] = useState(false);

  useEffect(() => {
    if (resendVerifyEmailOtp) console.log('resend otp to email');
    return () => {
      setResendVerifyEmailOtp(false);
    };
  }, [resendVerifyEmailOtp]);

  const handleVerifyEmail = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp-mail/${profile.email}`
    );
    if (res.status) {
      Swal.fire('Done', res.message, 'success');
    } else {
      Swal.fire('Opps!', res.message, 'error');
    }
  };

  const handleVerifyEmailOtp = async () => {
    const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/auth/verify-email`,
      {
        phoneOrEmail: profile.email,
        otp,
      }
    );
    if (res.status) {
      Swal.fire('Done', 'Email verified successfully.', 'success');
      setShowPin(false);
      setOtp('');
    } else {
      Swal.fire('Opps!', res.message, 'error');
    }
  };

  const handleResendOtpPhone = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/${profile.phoneNumber}`
    );
    if (res.status) {
      Swal.fire('Done', res.message, 'success');
    } else {
      Swal.fire('Opps!', res.message, 'error');
    }
  };

  const handleVerifyPhoneOtp = async () => {
    const res = await httpPost(
      `/api/v1/auth/verify-phone`,
      {
        phoneOrEmail: profile.phoneNumber,
        otp,
      },
      customBaseUrl.authUrl
    );
    if (res.status) {
      Swal.fire('Done', 'Phone number verified successfully.', 'success');
      setShowPin(false);
      setOtp('');
    } else {
      Swal.fire('Opps!', res.message, 'error');
    }
  };

  const handleSendOtpTo = () => {
    if (type === 'verifyEmail') {
      handleVerifyEmailOtp();
    } else {
      handleVerifyPhoneOtp();
    }
  };

  return (
    // <div
    //   className={!showAlert ? 'd-none' : 'text-center notification-div mx-auto'}
    // >
    <Modal
      id='verification'
      visible={showAlert}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        setShowAlert(false);
      }}
    >
      <div>
        {type === 'bvn' && (
          <div className=''>
            <p className='text-center'>Click on Verify to Add BVN</p>
            <div className='w-100 mt-5 text-center'>
              <Button
                type='button'
                className='btn btn-primary text-white btn-lg w-50'
                loading={false}
                disabled={false}
                onClick={() => {
                  setShowBvn(true);
                }}
                content='verify'
              />
            </div>
          </div>
        )}

        {type === 'verifyEmail' && (
          <div className=''>
            <p className='text-center'>
              Click on Verify to recieve OTP to your registered Email address
              for Verififcation
            </p>
            <div className='w-100 mt-5 text-center'>
              <Button
                type='button'
                className='btn btn-primary text-white btn-lg w-50'
                loading={false}
                disabled={false}
                onClick={() => {
                  handleVerifyEmail();
                  setShowPin(true);
                }}
                content='verify'
              />
            </div>
          </div>
        )}

        {type === 'verifyPhone' && (
          <div className=''>
            <p className='text-center'>
              Click on Verify to recieve OTP to your registered Phone Number for
              Verififcation
            </p>
            <div className='w-100 mt-5 text-center'>
              <Button
                type='button'
                className='btn btn-primary text-white btn-lg w-50'
                loading={false}
                disabled={false}
                onClick={() => {
                  handleResendOtpPhone();
                  setShowPin(true);
                }}
                content='verify'
              />
            </div>
          </div>
        )}

        {showBvn ? (
          <LinkBvn
            showModal={showBvn}
            hideModal={setShowBvn}
            className='bvn-wrapper'
            profile={profile}
          />
        ) : (
          ''
        )}

        {showPin ? (
          <PinModal
            center
            showModal={showPin}
            hideModal={setShowPin}
            otp={otp}
            setOtp={setOtp}
            numInputs={6}
            separator=''
            buttonLabel='Verify'
            title='Verify Your Account'
            description={
              type === 'verifyEmail'
                ? 'Please input the OTP sent to your Email Address'
                : 'Please input the OTP sent to your Phone'
            }
            isResendOtp
            resendOtp={setResendVerifyEmailOtp}
            handleSubmit={handleSendOtpTo}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
}
