import React from 'react';
import { DropDownWrapper } from './style';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';

export default function SearchDropdown(props) {
  const { options, handleClick } = props;

  return (
    <DropDownWrapper style={!options.length ? { display: 'none' } : {}}>
      {options.length
        ? options.map((item) => (
            <div
              className='d-flex'
              key={Math.random()}
              style={{
                borderBottom: '1px solid #EDEAEA',
                padding: '10px 20px',
              }}
              onClick={() => handleClick(item)}
            >
              <div className='profile-div'>
                <div className='image-div'>
                  <img
                    src={item?.avatar || imgPlaceholder}
                    alt='placeholder'
                    style={{ borderRadius: '50%' }}
                  />
                </div>
                <div className='text-div'>
                  <span>{`${item.surname} ${item.firstName} ${item.email}`}</span>
                </div>
              </div>
            </div>
          ))
        : ''}
    </DropDownWrapper>
  );
}
