import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { styled } from "@mui/material/styles";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import  useMatStyles  from "./styles";
import { useTheme } from "styled-components";
import { customBaseUrl, httpGet } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import Layout  from "../../../components/Layout"
import { transformName } from "../../../utils/helper";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import CreateBulkForm from "./bulkuploads";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";

const SimulatedUsers = () => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "firstName",
    searchText: "",
  });
const [viewAddBulk, setViewAddBulk] = useState(false);
  const [allSimulatedUsers, setAllSimulatedUsers] = useState(0);
  const [showSendOtp,setShowSendOtp] = useState(false);
  const [userType, setUserType] = useState('')
  const [metaData, setMetaData] = useState(0)
  const getSimulatedUser = async (page, size) => {

    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.authUrl}api/v1/sUser/users?page=${pages}&size=${limit}`
    );

    if (request?._embedded?.userResponse) {
      hideLoader();
      setAllSimulatedUsers(request?._embedded?.userResponse)
      setMetaData(request?.page?.totalElements);

      
    }

    hideLoader();

 
  };


  useEffect(() => {
    getSimulatedUser();
}, []);



  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getSimulatedUser(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getSimulatedUser(newPage, pagination.pageSize);
  };



  var searchInput = useRef({});
  const columns = [
    
    { field: 'firstName',
      headerName: 'Name',
      width: 250 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.firstName +' '+ row?.lastName

    },

    { field: 'phoneNumber',
      headerName: " PHONE",
      width: 250 ,
      headerClassName: styles.tableHeader,
    },
    { field: 'email',
    headerName: "Email Address",
    width: 250 ,
    headerClassName: styles.tableHeader,

    },
    { field: 'corporate',
    headerName: "User Type",
    width: 150 ,
    renderCell: ({row})=> row.corporate?'CORPORATE':'PERSONAL',
    headerClassName: styles.tableHeader,
    },
   
    { field: 'createdAt',
    headerName: "DATE",
    width: 150,
    headerClassName: styles.tableHeader,
 
    }

  ];

  const dropDownOptions = ["Single", "Bulk Personal", "Bulk Corporate"];
  const handleDropdownAction = (item, row) => {
 
    if (item === "Bulk Personal"){
      setUserType('individual')
       setViewAddBulk(true);

    }else if (item === "Bulk Corporate"){
      setUserType('corporate')
       setViewAddBulk(true);
    }
  };

  return (
    <Layout>

<div style={tool}>
<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>

        <Row type="flex" justify="end" align="middle">

          <AntDropdown
            handleDropdownAction={handleDropdownAction}
            dropDownOptions ={dropDownOptions}
          >
          <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined/>
              Add Simulated User
            </Button>    
            </AntDropdown>
             
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>Total Number of Simulated Users</p>
          <h2 className="text-left">{metaData}</h2>
        </Card.Grid>
       
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allSimulatedUsers.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
        getRowId={(row)=>row.userId}
         className={styles.table}
         density="comfortable"
        // rows = {allSimulatedUsers}
        rows={allSimulatedUsers.filter((request) =>
            request[search?.searchBy]
              .toLowerCase()
              .includes(search?.searchText.toLowerCase())
          )}
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}


      {viewAddBulk &&
      <CreateBulkForm
        showModal={viewAddBulk}
        hideModal = {setViewAddBulk}
        title = "Add Simulated Users"
        userType={userType}
      />
      }
      
     
    </Layout>
  );
};

const gridStyle = {
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default SimulatedUsers;
