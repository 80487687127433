import './pricing.css';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box, IconButton } from '@mui/material';
import { customBaseUrl, httpGet } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ArrowBack, DeleteOutline } from '@mui/icons-material';
import { Card, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Pricings from './Pricings';





const MainPricing = ({setDisplay, setSelectedRow, setCharges, setAdmins}) => {
  const [state, setState] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
 
  const [totalPricing, setTotalDispute] = useState(0)
  const [filterStatus, setStatus] = useState('')
  const [showExport, setShowExport] = useState(false);
  const [addPricing, setAddPricing]= useState(false)
  const [showPricing, setShowPricing]= useState(false)
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState('')
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getDisputes(newPage, pagination.pageSize);
  };

 
  
  const columns = [
    {
      field: 'trackingNumber',
      headerName: 'PRICING ID',
      headerClassName: 'super-app-theme--header',
      width: 100,
    },
  
    {
      field: 'category',
      headerName: 'PRICING CATEGORY',
      headerClassName: 'super-app-theme--header',
      width: 250,
      renderCell: ({row})=> row?.ticketCategory?.category
    },
    {
      field: 'totalUsers',
      headerName: 'TOTAL USERS',
      headerClassName: 'super-app-theme--header',
      width: 200,

    },
    {
      field: 'status',
      headerName: 'STATUS',
      headerClassName: 'super-app-theme--header',
      width: 200,

    },
    {
      field: 'date',
      headerName: 'DATE CREATED',
      headerClassName: 'super-app-theme--header',
      width: 250,
    },
    {
      field: 'action',
      headerName: 'ACTION',
      headerClassName: 'super-app-theme--header',
      width: 200,
      
    }
  ];


  

  const tableData = []
  const rows = tableData.map((row, index) => ({
    id: index,
    ticketID: row.trackingNumber,
    type: row.type,
    category: row.category,
    duration: row.transactionAmount,
    status: row.status,
    action: row,
    delete: row,
  }));




  const getDisputes = async (page, size) => {
    setPricing([]);
    showLoader();

    const pages = page == 0 ? page : page?? pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/pricing-ex?page=${pages}&size=${limit}`
    );
    if (response?.status) {
      console.log('disput', response)
      setPricing(response?.data?.pricing);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalItems);
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination,  pageSize: value });
  };

 
  useEffect(() => {    getDisputes();
 
  }, []);

  return (
    <Layout>
    {!showPricing?(<>
      {/* top session  */}
      <section className="dispute-top-session">
       
        <div className="dispute-search-wrapper">
          {/*<div className="dispute-filter-container" onClick={()=>setShowFilter(true)}>
            <FaFilter className="filter-icon" />
            <h4>Filter</h4>
          </div>
          <div className="search-container">
            <div className="search-icon">
              <FaSistrix />
            </div>
            <input type="text" placeholder="Search"   
            onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              />
          
          </div>
          
           <div className='container' style={{backgroundColor:''}} >
           <Button
           onClick={()=>setShowLogdispute(true)}
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              but
            </Button>             
            
          </div>*/}
          {/*<div className="container" onClick={()=>setShowExport(true)}>
            <div className="mechanics-icon">
              <div className="icon">
                <FaExternalLinkAlt />
              </div>
              <div className="export-data">Export Data</div>
            </div>
          </div> */}
          <div className="mechanics-icon">

          <Button
           onClick={()=>setAddPricing(true)}
              size="medium"
              style={{  fontSize: "10px", color: "#fff", }}
            >
              <PlusOutlined />
              Add pricing category
            </Button>   
            </div>
          </div>    
      </section>

      {/* card session */}
      <section>
        <div className="cards-container dispute-card-container">
          <div className="cards dispute-card" >
            <h1 style={{textAlign:'center', paddingTop:10}}>500</h1>
            <p style={{textAlign:'center'}}>TOTAL USERS ON DEFAULT PRICING</p>
            <h6 style={{textAlign:'center',color:"red",}} 
            onClick={()=>setShowPricing(true)}
            >View users</h6>
          </div>
          <div className="cards dispute-card" >
            <h1 style={{textAlign:'center', paddingTop:10}}>500</h1>
            <p style={{textAlign:'center'}}>TOTAL USERS ON STARTER PRICING</p>
            <h6 style={{textAlign:'center',color:"red",}}>View users</h6>
          </div>
          <div className="cards dispute-card" >
            <h1 style={{textAlign:'center', paddingTop:10}}>500</h1>
            <p style={{textAlign:'center'}}>TOTAL USERS ON BETA PRICING</p>
            <h6 style={{textAlign:'center',color:"red",}}>View users</h6>
          </div>
          <div className="cards dispute-card" >
            <h1 style={{textAlign:'center', paddingTop:10}}>500</h1>
            <p style={{textAlign:'center'}}>TOTAL USERS ON OTHER PRICING</p>
            <h6 style={{textAlign:'center',color:"red",}}>View users</h6>
          </div>
        </div>
         
         
      </section>

    
      <section>
        <Box
          sx={{
            width: 1,
            '& .super-app-theme--header': {
              background: '#ff6700',
              color: '#ffffff',
            },
          }}
        >
          <div
            style={{
              height: 650,
              width: '100%',
              background: '#fff',
              borderRadius: '8px',
            }}
          >
            {pricing?.length>0&&<DataGrid
            rows={[]}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalPricing}
          />}
          </div>
        </Box>
      </section>  
    </>)  : 
    (<>
      <ArrowBack onClick={()=>setShowPricing(false)}/>
      <Pricings/>
    </>)
          }
        
    
    </Layout>
  );
};

export default MainPricing;
