import { createUseStyles } from "react-jss";
import { grey } from "@mui/material/colors";
import styled from "@emotion/styled";

const useMatStyles = createUseStyles(() => ({
  searchRow: {
    display: "flex",
    height: "46px",
    alignItems: "center",
    marginBottom: "12px",
  },
  tableHeader: {
    background: "#ff6700",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    // lineHeight: "15px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#ffffff",
  },
  dibot: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "19px",
    margin: "10px",
    color: "#3C345399",
  },
  money: {
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "41px",
    color: "#333333",
    width: "80%",
  },
  diTop: {
    marginBottom: "10px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "50px",
    float: "right",
    color: "#333333",
  },
  card: {
    padding: "12px",
    width: "48%",
    background: "#FFFFFF",
    boxShadow: "0px 21px 45px rgba(30, 66, 138, 0.1)",
    borderRadius: "10px",
  },

  transactionCard:{
    background: "#FFFFFF",
    border: "1px solid #B7B7B7",
    borderRadius: "5px",
    width: "100%",
    height:"140px",
    marginBottom:'40px'
  },
  transactionCardTitle:{
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#333333",
    marginTop:'30px',
    textAlign:'center',
  },
  transactionAmount:{
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "36px",
    color: "#333333",
    marginLeft:'30px',	
  },

  table: {
    background: "#FFFFFF",
    borderRadius: "10px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#666666",
  },
  selectType: {
    marginRight: "10px",
    borderRadius: "6px !important",
    // border: '0 !important',
    height: "100%",
    width: "171px",
    padding: "10px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#FF4B01",
    background: "#FFEAE1",
    border: "1px solid #FF4B01 !important",
    boxSizing: "border-box",
  },

  buttonIcon: {
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  manageButtonIcon: {
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "3px",
    color: "#828282",
  },
  manageAdminButton: {
    height: "80%",
    borderRadius: "6px !important",
    textAlign: "center",
    background: "#F2F2F2",
    color: "#333333",
    border: "1px solid #E5E5E5",
    marginRight: "18px",
    boxShadow: "0px 0px 0px 0px #E5E5E5",
    fontSize: "9px",
    fontWeight: "700",
    "&:hover": {
      background: "#F2F2F2",
      color: "#333333",
    },
    "&:focus": {
      background: "#F2F2F2",
      color: "#333333",
    },
  },
  searchInput: {
    height: "100%",
    width: "100%",
    marginTop: "10px",
    borderRadius: "6px",
    background: "#f5f5f5 !important",
    border: "1px solid #ff4b01",
    boxSizing: "border-box",
    outline: "none",
    color: "#978c8a",
    ":focus": {
      outline: "none",
      outlineWidth: "0px",
    },
    ":hover": {
      outline: "none",
      outlineWidth: "0px",
    },
  },
  searchIcon: {
    background: "#f5f5f5",
    color: "#ff4b01",
  },
  grow: {
    flexGrow: 1,
  },

  addCustomRoleButton: {
    borderRadius: "5px !important",
    textAlign: "center",
    background: "#FF4B01",
    color: "#fff",
    "&:hover": {
      background: "#FF4B01",
      color: "#fff",
    },
    "&:focus": {
      background: "#FF4B01",
      color: "#fff",
    },
  },
  updateButton: {
    height: "80%",
    width:"20%",
    borderRadius: "4px !important",
    textAlign: "center",
    background: "#FF4B01",
    color: "#fff",
    float: "right",
    "&:hover": {
      background: "#FF4B01",
      color: "#fff",
    },
    "&:focus": {
      background: "#FF4B01",
      color: "#fff",
    },
  },
  cardContent: {
    marginLeft: '50px',
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '50px',
  },
  title:{
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "36px",
    color: "#333333",
    textAlign: 'center',
  },
  subtitleText: {
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#C4C4C4",
  },
  filledButton: {
    height: "35px",
    background: "#ff6700",
    float: "right",
    // marginLeft: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.3px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#ff6700",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#FFFFFF",
    },
    "&:focus": {
      background: "#ff6700",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#FFFFFF",
    },
  },
  matGrid: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  modalSize: {
    width: "1000px",
  },
  tableHeader: {
    background: "#ff6700",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    inlineSize: "150px",
    overflowWrap: "break-word",
    // lineHeight: "15px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#E7472C",
  },
  table: {
    background: "#FFFFFF",
    borderRadius: "10px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#666666",
  },

  inputWrap: {},
  inputCont: {
    dispaly: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: "20px",
  },
  inputCont2: {
    dispaly: "flex",
    width: "48%",
    flexDirection: "column",
  },
  inputLabel: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "12px",
    color: "#000000",
    marginBottom: "4.5px",
  },
  inputInput: {
    background: "#FFFFFF",
    border: "1px solid #000000",
    boxSizing: "border-box",
    borderRadius: "5px !important",
    width: "100%",
    padding: "8px !important",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "12px",
  },
  inputContFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  shortTextStyle: {
    wordWrap: "break-word",
    maxWidth: 150,
    fontWeight: "normal",
  },
  longTextStyle: {
    wordWrap: "break-word",
    maxWidth: 200,
    fontWeight: "normal",
  },
}));

const Wrapper = styled.div`
   .text-font {
    font-family: "Inter";
  }
  .ant-tabs-tab    {
    color: #B7B7B7 !important; 
    font-weight: 500;
 }
 .ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #ff6700 !important; 
  font-weight: 500;
 }
 .ant-tabs-ink-bar {
  position: absolute;
  background: #ff6700;
  pointer-events: none;
}
`;
export { Wrapper, useMatStyles };