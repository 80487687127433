import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { customBaseUrl, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import useMatStyles from "../../../views/admin/style";

const AddWalletModal = ({ showModal, hideModal, title, style, userId, record }) => {
  return (
    <Dialog style={style} open={showModal} onClose={() => hideModal(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <AddWalletForm userId={userId} hideModal={hideModal} record={record} />
      </DialogContent>
    </Dialog>
  );
};
export default AddWalletModal;

const AddWalletForm = ({ userId, hideModal, record }) => {
  const [data, setData] = useState({});
  const styles = useMatStyles();


const handleSubmmit = async() => {
showLoader()
 let postData = {
    "accountType": "Savings",
    "description": data?.description,
    "simulated": data?.simulated,
    userId
     }

  //let res = await httpPost(`${customBaseUrl.walletUrl}/api/v1/wallet/create-wallet`,postData)
  let d= new Date();
 let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
  {
    payload: JSON.stringify(postData),
    "requestId": `REQ${d.getTime()}`,
    "requestType": "ADDWALLET",
    customerName: record?.firstName+' '+record?.lastName
  } )
  if(res.status){
    hideLoader()
    hideModal(false)
    Swal.fire('Done', res.message)
  }else{
    hideLoader()
    console.log(res)
    Swal.fire('Error', res.message)
  }
  hideLoader()

}

  return (
    <div className="row">
      <div className="col-md-12">
        <div className={styles.inputCont}>
          <div className={styles.inputLabel}>Account Description</div>
          <input
            value={data.description}
            className={styles.inputInput}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
        </div>
        <div className={styles.inputCont} >
              <div  className={styles.inputLabel}>Select User Type </div>
              <select value={data.stimulated} onChange={(e) => { setData({ ...data, simulated: e.target.value }) }} className='label2 border border-dark '>
              <option value=''>Select User Type</option>
                <option value='true'>Stimulated User</option>
                <option value='false'>Non Stimulated User</option>
              </select>
            </div>
      </div>
      <button className="btn btn-danger" style={{width: 150, marginTop:20, marginLeft:'auto', marginRight:'auto'}} onClick={handleSubmmit}>Create Wallet</button>
    </div>
  );
};
