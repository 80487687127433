import React,{useState, useEffect, useContext} from 'react';
import { Modal, Button } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import { hideLoader, showLoader } from '../../../utils/loader';
import Swal from 'sweetalert2';
import { customBaseUrl, httpPost, httpPostFormData, httpPostUnreloaded } from '../../../store/http';
import { useGridRegisterPipeProcessor } from '@mui/x-data-grid/hooks/core/pipeProcessing';
import { ProfileContext } from '../../../store/context/ProfileContext';

const FundWallets = ({ showModal, hideModal,  accountNo, getAccounts, accountName }) => {
 const { profile } = useContext(ProfileContext);

const [fundingType, setFundingType] = useState('fundWithCard');
const [fundingWallet, setFundingWallet] = useState('');
const [data, setData] = useState(
                                  {
                                     tranCrncy: "NGN",
                                     eventId: "COMPAYM",
                                   });

const [fundingCard, setFundingCard] = useState('');


  let fundingOption = [
    
    {
      title: "Fund with Card",
      value: "fundWithCard"
    },
   
    {
      title: "Fund with Bank Account",
      value: "fundWithBank"
    }
  ]


  let handleTransaction = async () => {
    showLoader();

    if (!data.amount ) {
      Swal.fire('Wrong', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }
    
    let d = new Date();
    let ref = d.getTime();
    const postData = {
      amount: data.amount,
      tranCrncy: data.tranCrncy?data.tranCrncy:'NGN',
      userId: profile.userId,
      Ref:ref,
      cardNumber: data.cardNumber,
      adminId: profile.userId,
      walletAccountNo: accountNo

    }

const formBody = Object.keys(postData).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(postData[key])).join('&');
      const bankData = {
                  "amount": data.amount,
                  "app_ref": ref,
                  "email": profile?.email,
                  "walletAcctNo": accountNo
                }

    let response;
     if(fundingType === 'transferFromWayaOfficialAccount'){
   //   return alert('lll')
        response = await httpPost(
          `${customBaseUrl.walletUrl}/api/v1/wallet/official/transfer`,
            postData      
    )}else if(fundingType === 'fundWithCard'){

      response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify(postData),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "fundingWithCard",
        customerName: accountName
      } )

      /*response = await httpPostUnreloaded(
        `${customBaseUrl.cardUrl}/admin/charge`,
         formBody
      )*/
    }else{
      response = await httpPost(
        `${customBaseUrl.cardUrl}/admin/payWithCheckOut/${profile.userId}/${profile.userId}`,
          bankData
      )
    }

    if (response.status === true) {
      hideLoader();
      hideModal(false);
      getAccounts();
      response?.data?.authorization_url?
        window.location.href = response?.data?.authorization_url:
      (Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      }));

    } else {
      hideLoader();
      console.log(response);
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };


  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: '23px 73px 44px 73px',
        borderRadius: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: '27px',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>


      </div>
      <Wrapper>
        <div className="title">Fund Wallet </div>
        <div className="cw-content">
          <div className="input-content">
            <div className="label">Funding Method</div>
            <select style={{height: 40}} value={fundingType} onChange={(e)=> setFundingType(e.target.value)} className='border border-dark rounded' >
              <option value = "" >select funding method</option>
              {Object.keys(fundingOption).map((key, index) => 
                  <option key={index} value={fundingOption[key].value}>
                    {fundingOption[key].title}
                  </option>
              )}
            </select>
          </div>
    {/*fundingType === "transferFromWayaOfficialAccount" &&      <div className="input-content">
            <div className="label">Funding Wallet</div>
            <select style={{height: 40}} value={fundingWallet} onChange={(e)=>setFundingWallet(e.target.value)} className='border border-dark rounded' >
             <option value="">...</option>
              {walletList?.map(( wallet) => 

                  <option key={wallet.id} value={wallet?.accountNo}>
                    {wallet.acct_name}
                  </option>
              )}
            </select>
              </div>*/}
        {fundingType === "fundWithCard" &&    
          <div className="input-content">
            <div className="label">Card Number</div>
            <input 
            type="text"
            value={data.cardNumber}  
            onChange={(e)=>setData({...data, cardNumber:e.target.value})}/>
          </div>
              }
          <div className="input-content">
            <div className="label">Amount</div>
            <input 
            type="number"
            value={data.amount}  
            onChange={(e)=>setData({...data, amount:e.target.value})}/>
          </div>
          <div className="input-content">
            <div className="label">Enter PIN</div>
              <input 
              type="password"
              value={data.pin} 
              onChange={(e)=>setData({...data, pin: e.target.value})} />
          </div>
        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            background: '#FF4B01',
            color: '#fff',
            borderRadius: '3px',
            fontSize: '14px',
            lineHeight: '17px',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 25px',
          }}
          onClick={()=>handleTransaction()}
        >
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default FundWallets;
