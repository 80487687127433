import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeactivateBulkUser from "./DeactivateBulkUser";
import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Button as MuiButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, textAlign } from "@mui/system";
import { Button, Layout } from "antd";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    fontStyle: "normal",
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontStyle: "normal",
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Inter"'].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    fontFamily: "inherit",
    fontSize: "20px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    fontFamily: "Inter",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      style={{ textAlign: "center", fontFamily: "inherit" }}
      sx={{ m: 0, p: 4 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeactivateUsers = ({
  showModal,
  hideModal,
  title,
  className,
  users,
  handleDactivateUser,
}) => {
  const [showDeactivateBulkUsers, setShowDeactivateBulkUsers] = useState(false);
  const [userId, setUserId] = useState("");
  const handleOnClick = (e) => {
    setShowDeactivateBulkUsers(true);
    //hideModal(false);
  };
  return (
    <Layout>
      <Box sx={12}>
        <BootstrapDialog
          open={showModal}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={"sm"}
          style={{ zIndex: "100" }}
          fullWidth={true}
          onClose={() => hideModal(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => hideModal(false)}
          >
            {title}
          </BootstrapDialogTitle>

          <DialogContent style={{ minHeight: "250px" }}>
            <DialogContentText>
              <Grid container>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
                <Grid fullWidth item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <label>Select User</label>
                  <FormControl fullWidth style={{ minWidth: "400px" }}>
                    <Select
                     
                      input={<BootstrapInput />}
                      onChange={(e) => setUserId(e.target.value)}
                      inputProps={{ "aria-label": "Bussiness Type" }}
                    >
                      {users.map((user) => (
                        <MenuItem key={`${user.firstName}${user.userId}`.trim()}  value={user.userId}>
                         {`${user.firstName} ${user.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Button
                      style={{ color: "black", border: 0 }}
                      onClick={handleOnClick}
                      size="medium"
                    >
                      Deactivate Multiple Users
                    </Button>
                  </div>
                  <div style={{ textAlign: "center", marginTop: "40px" }}>
                    <MuiButton
                      variant="contained"
                      color="warning"
                      size="medium"
                      onClick={() => handleDactivateUser(userId)}
                    >
                      Deactivate User
                    </MuiButton>
                  </div>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}></Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </BootstrapDialog>
      </Box>
      {showDeactivateBulkUsers ? (
        <DeactivateBulkUser
          showModal={showDeactivateBulkUsers}
          hideModal={setShowDeactivateBulkUsers}
          title="Upload Excel Sheet to Deactivate Users"
        />
      ) : null}
    </Layout>
  );
};

export default DeactivateUsers;
