import React, { useEffect, useState } from 'react';
import { Pagination, Table } from "antd";
import { Edit } from '@mui/icons-material';
import NotificationEmail from './updateNotificationEmail';

const  AdvanceNotifications = ({request, meta,walletList,record,   getReceivedRequests}) => {
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [viewEdit, setViewEdit] = useState(false);
  const [account, setAccount] = useState('')

  const handleChangePage = async (newPage) => {
   //alert(newPage)
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
     getReceivedRequests(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (page, size) => {
    //alert(size)
    setPagination({ ...pagination, current: 0, pageSize: size });
     getReceivedRequests(0, size);
  };

       const columns = [ 
        { 
        key: "accountNo", 
        title: "Account Number", 
        dataIndex: "accountNo", 
        }, 
        { 
        key: "description", 
        title: "Description", 
        dataIndex: "description", 
        render: (text, record) =>  text?text:record.acct_name
        }, 
        { 
        key: "clr_bal_amt", 
        title: "Balance", 
        dataIndex: "clr_bal_amt", 
        }, 
        { 
        key: "email", 
        title: "Email", 
        dataIndex: "notify_email", 
        },
        { 
        key: "action", 
        title: "Action", 
        dataIndex: "action",
        render: (text, record) => <Edit onClick={()=>{
            setAccount(record.accountNo)
            setViewEdit(true)
        }}/>
        } 
      
        ] 
        
       
 return(

    <div>
        <div className="table"> 
        <p style={{textAlign:"center"}}>
        Advance Email Alert Notification Setting
        </p>
        <Table dataSource={walletList} columns={columns} pagination={false} 
         
        showHeader={true}
        /> 
         {//<Pagination  onShowSizeChange={handleChangeRowsPerPage} pageSize={pagination.pageSize} defaultCurrent={0} onChange={handleChangePage} total={meta}  />
        }   
        </div> 
        {
            viewEdit && <NotificationEmail showModal={viewEdit} hideModal={setViewEdit} account={account}/>
        }
    </div>
 )   


}

export default AdvanceNotifications;