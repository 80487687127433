import React, { useState, useEffect, useContext } from 'react';
// import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon, { BellOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar, Badge } from 'antd';
import './dropdown.css';
import { LogOut } from '../svg/sidebar';
import Notification from '../../Notification';
import { customBaseUrl, httpDelete, httpGet } from '../../store/http';
import { ProfileContext } from '../../store/context/ProfileContext';
import { hideLoader, showLoader } from '../../utils/loader';
import Swal from 'sweetalert2';

const TopNavDropDown = ({setLoad}) => {
  const { profile } = useContext(ProfileContext);
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [notifications, setNotification] = useState([]);
  const profileName = localStorage.getItem('fullName');
  
  // const data = JSON.parse(localStorage.getItem('data'));
  const logout = (e) => {
    localStorage.clear();
    window.location.href = '/';
  };

  const addCount = () => {
    setCount(1);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    let pages = Math.floor(totalNotifications/pagination.pageSize)
    if(newPage==='next' && pagination.current<pages){
      setPagination({ ...pagination, current: pagination.current+1 });

    }else if(newPage==='back' && pagination.current>0) {
      setPagination({ ...pagination, current: pagination.current-1 });

    }
    await getNotification(pagination.current, pagination.pageSize);
  };
 const  handleDelete = async (id) =>{
  showLoader()
  let res =await httpDelete(`${customBaseUrl.notificationUrl}/delete-notifications/${id}`)
  if(res.status){
    hideLoader()
    Swal.fire('Done', res.message)
    await getNotification(pagination.current, pagination.pageSize);
  }else{
    hideLoader()
    Swal.fire('Error', res.message)
  }
 }

 const getNotification = async (page, size) =>{
  const pages = page ? page : pagination.current;
  const limit = size ? size : pagination.pageSize;
  let res =await httpGet(`${customBaseUrl.notificationUrl}/in-app-notifications/${localStorage.getItem('userId')}?page=${pages}`)

  if(res?.status){
    setNotification(res?.data?.notifications);
    setTotalNotifications(res?.data?.totalNumberOfInAppNotifications)

  }
}

  useEffect(()=>{
    //alert(localStorage.getItem('userId'))
    getNotification(pagination?.current, pagination?.pageSize)

    setInterval(()=>{
    //  getNotification(pagination?.current, pagination?.pageSize)
    },10000)
  },[])
  const menu = (
    <Menu selectable={false} style={{}}>
      <Menu.Item>
        <Link to={`/admin/profile/${localStorage.getItem('userId')}`}>
          <div
            style={{
              display: 'flex',

              alignItems: 'center',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#000000',
              marginLeft: '20px',
              marginRight: '20px',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <UserOutlined
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                color: '#000000',
                marginRight: '11.25px',
              }}
            />
            <div> Edit profile</div>
          </div>
        </Link>
      </Menu.Item>

      <Menu.Item>
        <a href='/#' onClick={logout}>
          <div
            style={{
              display: 'flex',

              alignItems: 'center',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FF0505',
              marginLeft: '20px',
              marginRight: '20px',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <Icon
              component={LogOut}
              style={{
                fontSize: '14px',
                lineHeight: '17px',
                color: '#000000',
                marginRight: '11.25px',
              }}
            />
            <div> Log Out</div>
          </div>
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <>    
 <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 20
        }}
      >
        <div onClick={()=>{setOpen(true); setLoad(true)}}>
       <Badge count={totalNotifications} >
          <Avatar shape='circle' size='large' icon={<BellOutlined />} />
        </Badge>
        </div>
    </div>
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        className='h-dropdown'
      >
        <Badge >
          <Avatar shape='circle' size='large' icon={<UserOutlined />} />
        </Badge>
        <div
          style={{
            marginLeft: '10px',
          }}
        >
          {profileName}
        </div>
      </div>
    </Dropdown>
    {
       open && <Notification setOpen={setOpen} getNotification={getNotification} handleDelete={handleDelete} handleLoadMore={handleChangePage} totalNotifications={totalNotifications} pagination={pagination}  open={open} notifications={notifications}/>
    }
    </>

  );
};

export default TopNavDropDown;
