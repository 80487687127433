import React, { useEffect, useState } from "react";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {useMatStyles} from "../style";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/AutocompleteUnstyled";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Divider } from "antd";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../../store/http";
import { NotificationManager } from "react-notifications";
import { httpPostFormData } from "../../../../services/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import Swal from "sweetalert2";



const ManageKycProviders = ({
  showModal,
  setShowModal,

  getProviders,
  providerData
}) => {
  const styles = useMatStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [comLists, setComList] = React.useState({})
  const [genComList, setGenComList] = React.useState('')
  const [agentCom, setAgentCom] = React.useState(0)
  const [aggregatorCom, setAggregatoCom] = React.useState(0)
  const [data, setData] = useState(providerData)


  useEffect(() => {
   
  }, []);

 

  const handleSubmitUpdate = async () => {
    showLoader()
   
    let res = await httpPut(`${customBaseUrl.kycUrl}/api/v1/provider/update/${data?.id}/${data?.isActive}?identityType=${data?.identityType}&processType=${data?.processType}`)

    if(res.status){
      hideLoader();
      setShowModal(false)
      getProviders();
      Swal.fire('Done', res?.message)
    }else{
      hideLoader();
      Swal.fire('error', res?.message)
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const idType=['ALL','ACCOUNT_STATEMENT', 'BVN', 'CAC', 'DRIVER_LICENSE', 'EMAIL', 'ID', 'INTERNATIONAL_PASSPORT', 'NIN', 'PHONE_NUMBER', 'PHOTO', 'REFERENCE', 'TIN', 'UTILITY', 'VOTERS_CARD']
  const processType=['ADVANCE', 'BASIC', 'GLOBAL']
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        open={showModal}
        onClose={() => handleClose()}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Manage KYC Provider
        </DialogTitle>
        <DialogContent>
          <div className="col">
          <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Provider Name</div>
                <input
                disabled
                  value={data?.name}
                  className={`{styles.inputInput} border input-content`}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Process Type</div>
                <select
                  value={data?.processType}
                  onChange={(e)=>setData({...data, processType: e?.target?.value})}
                  className={`{styles.inputInput} border input-content`}
                >
                 <option>select Process Type</option>                  
                    {processType?.map(e=> 
                    <option value={e}>
                      {e}
                    </option>
                    )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Identity Type</div>
                <select
                  value={data?.identityType}
                  onChange={(e)=>setData({...data, identityType: e?.target?.value})}
                   className={`{styles.inputInput} border input-content`}
                >
                  <option>select Identity Type</option>                  
                    {idType?.map(e=> 
                    <option value={e}>
                      {e}
                    </option>
                    )}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className={styles.inputCont}>
                <div className={`${styles.inputLabel}`}>Status</div>
                <select
                   value={data?.isActive}
                   onChange={(e)=>setData({...data, isActive: e?.target?.value})}
                  className={`{styles.inputInput} border input-content`}
                >
                    <option value={true}>
                      Active
                    </option>
                    <option value={false}>
                      InActive
                    </option>
                </select>
              </div>
            </div>
          </div>
          <Divider />
          <div className="row">
          <div className="col-md-6">
              <Button onClick={()=>setShowModal(false)} className='btn-danger'>
                Close
              </Button>
            </div>
            <div className="col-md-6">
              <Button onClick={handleSubmitUpdate} className={styles.filledButton}>
                Save Changes
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ManageKycProviders;
