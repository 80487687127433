import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';
import { customBaseUrl, httpPost } from '../../../../store/http';
import { hideLoader, showLoader } from '../../../../utils/loader';

const SendPaymentRequest = ({ showModal, hideModal,
   
    getTransactions }) => {
const [data, setData] = useState({})
const [recipientType, setRecipientType] = useState('waya')


const handleSubmit = async()=>{
    let d = new Date();

    let NonWayaRequestData = {
        "amount": data.amount,
        "createdAt": d,
        "reason": data.reason,
        "receiverEmail": data.receiverEmail,
        "receiverName": data.receiverName,
        "receiverPhoneNumber": data.receiverPhoneNumber,
        "senderEmail": data.senderEmail,
        "senderId": data.senderId,
        "senderName": data.senderName, 
      }
      let wayaRequestData = {
        "amount": data.amount,
        "createdAt": d,
        "reason": data.reason,
        "receiverId": data.receiverId,
        "receiverEmail": data.receiverEmail,
        "receiverName": data.receiverName,
        "receiverPhoneNumber": data.receiverPhoneNumber,
        "receiverPhoneNumber": data.receiverPhoneNumber,
        "senderEmail": data.senderEmail,
        "senderId": data.senderId,
        "senderName": data.senderName,
      }
      showLoader();
        let response;
      if(recipientType === 'nonWaya'){
        response = await httpPost(
          `${customBaseUrl.paymentRequestUrl}/payment-request-non-waya`,
          NonWayaRequestData
        )}else if(recipientType === 'waya'){
          response = await httpPost(
            `${customBaseUrl.paymentRequestUrl}/payment-request-single-waya`,
            wayaRequestData
          )}

          if(response.status){
            hideLoader();
            getTransactions();
            hideModal(false);
            Swal.fire('Done', response.message)
          }else{
            hideLoader()
            Swal.fire('Error', response.message)
          }
  
}


  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      width='30%'
    >
      <Wrapper>
        <div className="cw-content">

          <div >


              <h3 >Send Payment Request</h3>
            
           
          
              <div className="cw-content">
         <div className="input-content ">
          
           <div className="label">Recipient Type </div>
           <select onChange={(e)=>{setRecipientType(e.target.value)}} name="" id="" className='border border-dark rounded' >
             <option value="waya">Waya User</option>
             <option value="nonWaya">Non Waya User</option>

           </select>         
        
         </div>
        
         <div className="input-content">
           <div className="label">Amount </div>
           <input
             value={data.amount}
             onChange={(e) => setData({ ...data, amount: e.target.value })}
           />
         </div>

         <div className="input-content">
           <div className="label">Sender Email </div>
           <input
             type= "text"
             value={data.senderEmail}
             onChange={(e) => setData({ ...data, senderEmail: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">sender Id </div>
           <input
             type= "text"
             value={data.senderId}
             onChange={(e) => setData({ ...data, senderId: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">sender Name </div>
           <input
             type= "text"
             value={data.senderName}
             onChange={(e) => setData({ ...data, senderName: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Receiver Name </div>
           <input
             value={data.receiverName}
             onChange={(e) => setData({ ...data, receiverName: e.target.value })}
           />
         </div>
         {
            recipientType === 'waya'&&
            <div className="input-content">
           <div className="label">Receiver ID </div>
           <input
             type= "text"
             value={data.receiverId}
             onChange={(e) => setData({ ...data, receiverId: e.target.value })}
           />
         </div>
         }
         <div className="input-content">
           <div className="label">Receiver Email </div>
           <input
             type= "text"
             value={data.receiverEmail}
             onChange={(e) => setData({ ...data, receiverEmail: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Receiver Phone </div>
           <input
             type= "text"
             value={data.receiverPhoneNumber}
             onChange={(e) => setData({ ...data, receiverPhoneNumber: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Reason </div>
           <input
             type= "text"
             value={data.reason}
             onChange={(e) => setData({ ...data, reason: e.target.value })}
           />
         </div>
         
       </div>
          
          </div>

        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'stretch',
          justifyContent: 'stretch',
        }}
      >
      
        <Button
          style={{
            background: '#FF4B01',
            color: '#fff',
            fontSize: '14px',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            marginTop: 20,
            marginLeft: '20%'
          }}
          onClick={handleSubmit}
        >
          Send Payment Request
        </Button>
      </div>
    </Modal>
  );
};

export default SendPaymentRequest;
