import React, { useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../utils/loader";

function GenQR ({ showModal, hideModal, handleGenerate,// setCount, count, dataList 
}){
  const [count, setCount] = useState('');
  const [filteredData, setFilteredData] = useState([]);
///api/v1/qrcode/generateQRCode/count/1
const profileName = localStorage.getItem('fullName');

const handleGenerate1 = async() => {

  showLoader();
  if(!count ) return Swal.fire('error','Invalid input');
  const postData = {
    count
  }
  let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "generateQRCode",
      customerName: profileName
    } )

    if (res?.status) {
      Swal.fire(res?.message);
      hideLoader();
      hideModal(false);
    } else {
      Swal.fire(res?.message);
      hideLoader();
    }

 /* if(!count) return Swal.fire('Oops', 'Count field is empty')
  let response = await httpGet(`${customBaseUrl.qrCodeUrl}/api/v1/qrcode/generateQRCode/count/${count}`)
 showLoader();
  if (response.status){    
    hideLoader();
    Swal.fire('Success', response?.message);
    //setQRStatus( response?.message);
    hideModal(false);
  }else{
    hideLoader();
    console.log(response?.message)
  }*/
}
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <div className="title">Generate QR Code</div>
        <div className="cw-content">
        <div className="input-content border-dark">
            <div className="label">Enter Number of QR codes to be generated</div>
            <input onChange={(e) => setCount(e.target.value)} value={count} name="" id="" className="border border-dark"/>
          </div>         
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 50
        }}
      >
        
        <button style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick = {handleGenerate1}
          >
          Generate QR Code
          </button>
      </div>
    </Modal>
  );
};

export default GenQR;
