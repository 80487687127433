import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";

const SettleLien = ({
  isOpen,
  setIsOpen,
  title = "Enter a value",
  handleClick,
  inputType,
  inputLabel,
  inputDescription =""
}) => {
    const [inputValue, setInputValue] = useState("");
    const [lien, setLien] = useState(false);
  const handleSubmit = () => {
    setIsOpen(false);
    handleClick(inputValue, lien);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {inputDescription}
          </DialogContentText>
          <TextField
            autoFocus
            margin="comfortable"
            id={inputType}
            label={inputLabel}
            type={inputType}
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="standard"
          />
          <div className="input-group mt-3 mb-3 col">
          <span className="input-group-text">Lien</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e) => setLien(e.target.value === "true" ? true : false)}
              value={lien}
          >
          <option value={true} >True</option>
          <option value={false}>False</option>         
        </select>        
      </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SettleLien;
