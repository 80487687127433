import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

//import { format } from "date-fns";

const generatePDF = (transactions, filteredDate) => {
  const doc = new jsPDF('landscape');

  // define the columns we want and their titles
  const tableColumn = ["User Id","First Name", "Last Name", "Phone Number","Email","User Type","Status","Account Locked", "Account Delete", "Account Expired", "Date Created"];
  // define an empty array of rows
  const tableRows = [];

  // for each transaction pass all its data into an array
  transactions.forEach(transaction => {
    const transactionData = [
      transaction.id,
      transaction.firstName,
      transaction.surname,
      transaction.phoneNumber,
      transaction.email,
      transaction.corporate ? "Business" : "Personal",
      transaction.active? "Active" : "Inactive",
      transaction.accountNonLocked? "True" : "False",
      transaction.deleted? "True" : "False",
      transaction.accountNonExpired? "True" : "False",
      transaction.createdAt,

 //     format(new Date(transaction.updated_at), "yyyy-MM-dd")
    ];
    // push each tickcet's info into a row
    tableRows.push(transactionData);
  });

  const date2 = new Date();
  const month = date2.getMonth();
  date2.setMonth(month - 1);
  // startY is basically margin-top
  doc.text(`Registered users from ${moment(filteredDate?.fromdate ?? date2).format('YYYY-MM-DD') } to ${moment(filteredDate?.todate ?? new Date()).format('YYYY-MM-DD')}`, 14, 15);
  doc.autoTable(tableColumn, tableRows, {
    startY: 35,
    headStyles: { fillColor: '#ff6700' },
  });
  doc.autoTable(tableColumn, tableRows, { startY: 20 });
  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  // transaction title. and margin-top + margin-left
  //doc.text("Transaction report from", 14, 15);
  // we define the name of our PDF file.
  doc.save(`users_${dateStr}.pdf`);
};

export default generatePDF;