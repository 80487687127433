import React from 'react';

export default function PayToPhone({
  data,
  setData,
  setRequestType,
  requestType,
}) {
  return (
    <>
      <select
        className='mb-2'
        value={requestType}
        onChange={(e) => setRequestType(e.target.value)}
      >
        <option value='Select' key='select' selected disabled>
          Select User Type
        </option>
        <option value='wayaUser'>Waya User</option>
        <option value='nonWayaUser'>Non Waya User</option>
      </select>
      <input
        className='mb-2'
        type='text'
        placeholder='Recipient Phone Number'
        value={data.phoneNumber}
        onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
      />
      {requestType === 'nonWayaUser' && (
        <input
          className='mb-2'
          type='text'
          placeholder='Recipients Name'
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        />
      )}
    </>
  );
}
