import React, { useState, useEffect } from 'react';
import { GetSvgIcon } from '../../../components/svg';
import { PricingWrapper as Wrapper } from './style';
import AntTable from '../../../components/CustomTable/AntTable';
import Layout from '../../../components/Layout';
import { customBaseUrl, httpGet } from '../../../store/http';
import PricingCards from './PricingCards';
import UpdatePricing from './updatingPricing';
import { Pagination } from 'antd';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMatStyles from "./style";
import CustomEdit from './customEdit';
import { EditFilled } from '@ant-design/icons';
import { ArrowBack } from '@mui/icons-material';
import ExportPricing from './export';
import { Button } from '@mui/material';

const Pricings = () => {
  const styles = useMatStyles();

  const [currentPage, setCurrentPage] = useState('default');
  // const [tab, setTab] = useState("wallet");
  const [subProducts, setSubProducts] = useState([]);
  const [userPricingList, setUserPricingList] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [showPricingUpdate, setShowPricingUpdate] = useState(true)
  const [metaData, setMetaData] = useState(0)
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
const [showCustom, setShowCustom] = useState(false)
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({})
  const [searchText, setSearchText] = useState('')
  const [showExport,setShowExport]= useState(false)
  const [searchData, setSearchData]= useState([])
  const [priceData, setPriceData]= useState([])
  const [products, setProducts] = useState([]);
  const columns = [
    {
      title: 'USER ID',
      dataIndex: 'userId',
    },
   {
      title: 'USER',
      dataIndex: 'fullName',
    },
     {
       title: 'PRODUCT',
       dataIndex: 'product',
       width: 300
     },
  /*  {
      title: 'SUB PRODUCT',
      dataIndex: 'products',
    }*/
    {
      title: 'GENERAL RATE',
      dataIndex: 'generalAmount',
    },
    {
      title: 'CUSTOM RATE',
      dataIndex: 'customAmount',
    },
    {
      title: 'CAP (#)',
      dataIndex: 'capPrice',
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discount',
    },
   
    {
      title: 'STATUS',
      dataIndex: 'status',
    },
    {
      title: "PRICING TYPE",
      dataIndex: 'priceType'
    },
   /* {
      title: 'PERSONAL',
      dataIndex: 'personal',
      render: (item) => (item ? <GetSvgIcon type='markUp' /> : ''),
    },
    {
      title: 'AGGREGATOR',
      dataIndex: 'aggregator',
      render: (item) => (item ? <GetSvgIcon type='markUp' /> : ''),
    },*/
    {
      title: 'ACTION',
      render: (item) => (
        <>
          <EditFilled onClick={()=>{setSelectedRow(item);setShowCustom(true)}}/>
        </>
      ),
    },
  ];

  const searchUser= async (searchTxt) =>{
    if(searchTxt.length>0){
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/pricing/products/search/${searchTxt}`
    );
    setSearchData(res)
    setPriceData(res)
    }else{
      setSearchData([])
    }

  }

  const getProducts= async () =>{
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/pricing/products`
    );
    if(res.status){
      setProducts(res.data)
   
    }

  }
  const handleGetPrices = async (page,size) => {
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/pricing/user-products?page=${pages}&size=${limit}`
    );
    if (res?.status) {
      console.log('pricing List', res)
      setUserPricingList(res?.data?.userPricingList);
    }
    setLoading(false);
    setMetaData(res?.data?.totalItems)
  };
  const handleChangePage = async (newPage, s) => {
     console.log(newPage);
     setPagination({ ...pagination, current: newPage, pageSize:s });
     handleGetPrices(newPage, s);
   };
   const handleChangeRowsPerPage = async (page, size) => {
     //alert(size)
     handleGetPrices(0, size);

     setPagination({ ...pagination,  current:0, pageSize:size });
   };
 
  const handleGetSubProduct = async () => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.settlementUrl}/api/v1/product/sub-product`
    );
    if (res.status) {
      console.log('subopro', res.data)
      setSubProducts(res.data);
    }
    setLoading(false);
    setPagination(pagination);
  };

  useEffect(() => {
    handleGetPrices();
    handleGetSubProduct();
    getProducts()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const showTotal = (total) => `Total ${total} items`;

  return (
    <>
      <Wrapper>
        {currentPage === 'default' && (
          <>
            {' '}
            <div className='pricingHeader'>
              <div className='firstSide'>
                {/*<div className='filter'>
                  <GetSvgIcon type='filter' />
                  <div>Filter</div>
                </div>*/}
                <div className='searchCont'>
                  <GetSvgIcon type='search' className='icon' />
                  <input
                    onChange={
                      (e)=>{
                        setSearchText(e.target.value)
                        searchUser(e.target.value);
                      }}
                    type='text'
                    value={searchText}
                    placeholder='Search Users by product'
                  />
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row'}}>
              <div className='btnCont' onClick={() => setCurrentPage('edit')}>
                <GetSvgIcon type='edit-alt' />
                <div>Edit Pricing  </div>
              </div>
              <div>
              <Button
            //className="update-button-1"
            onClick={() => setShowExport(true)}
            variant="contained" 
            color="success"
          >
            <div>Export Data</div>
          </Button>
              </div>
              </div>
            </div>
            <div className='pricingTable'>
              <AntTable
                pagination={false}
               // users={searchText?userPricingList?.filter((e)=>(e.product.toLowerCase()?.includes(searchText.toLocaleLowerCase())||e.userId==(searchText))): userPricingList}
               users={searchText.length>0?searchData:userPricingList} 
               loading={loading}
                handleGetNext={handleGetPrices}
                columns={columns}
              />
            </div>
            <Pagination  onShowSizeChange={handleChangeRowsPerPage} total={searchData.length>0?searchData.length:metaData} onChange={handleChangePage} showTotal={showTotal} />
       </>
        )}
        {currentPage === 'edit' && (
          <div className='pricingEdit'>
          <ArrowBack style={{marginBottom:20}} onClick ={()=>setCurrentPage('default')}/>
      
            {/* <div className="tabBar">
              <div
                onClick={() => setTab("wallet")}
                className={`tabBar-item ${tab === "wallet" ? "tB-active" : ""}`}
              >
                Wallet
              </div>
              <div
                onClick={() => setTab("webPos")}
                className={`tabBar-item ${tab === "webPos" ? "tB-active" : ""}`}
              >
                Web POS
              </div>
              <div
                onClick={() => setTab("terminalPos")}
                className={`tabBar-item ${
                  tab === "terminalPos" ? "tB-active" : ""
                }`}
              >
                Terminal POS
              </div>
            </div> */}

              {products.map((e)=><PricingCards
                data={e}
                setData={setCurrentProduct}
                setCurrentPage={setCurrentPage}
                handleGetPrices={handleGetPrices}
              />)}
          </div>
        )}

        {showCustom && <CustomEdit
            showModal={showCustom}
            setShowModal = {setShowCustom}
            selectedRow={selectedRow}
            handleGetPrices={handleGetPrices}
            />
        }
        {showExport ? (
        <ExportPricing showModal={showExport} hideModal={setShowExport} dataList={userPricingList} />
      ) : (
        ""
      )}

      </Wrapper>
           {//} <UpdatePricing showModal={showPricingUpdate} setShowModal={setShowPricingUpdate}/>
            }
    </>
  );
};

export default Pricings;
