import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import { clear } from "@testing-library/user-event/dist/clear";
import { ref } from "yup";





const PaymentRequestForm  =({nonWayaReqData, setNonWayaReqData, tranType,
    setTranType,
    wallets,
    NonWayaReqInitialState,
    setTransactions,
    recipientType,
    setRecipientType

})=>{


return(
    <>
    <div className="cw-content">
         <div className="input-content ">
           <div className="label">Transaction Type </div>
           <select name="" id="" value={tranType} className='border border-dark rounded' onChange={(e)=>{setTranType(e.target.value); setTransactions([{...NonWayaReqInitialState}]) }} >
             <option value="wallet">Wallet Transfer</option>
             <option value="bank">Bank Transfer</option>
             <option value="nonwaya">Transfer to Non Waya users</option>
             <option value="paymentrequest">Payment Request</option>

           </select>
           <div className="label">Recipient Type </div>
           <select onChange={(e)=>{setRecipientType(e.target.value);setTransactions([{...NonWayaReqInitialState}])}} name="" id="" className='border border-dark rounded' >
             <option value="waya">Waya User</option>
             <option value="nonwaya">Non Waya User</option>

           </select>

           <div className="input-content">
           <div className="label">Select Waya Official Wallet</div>
           <select
             className='border border-dark rounded'
             onChange={(e) => {
              let d = new Date()
              let ref = d.getTime();
              setNonWayaReqData({...nonWayaReqData, senderId: JSON.parse(e.target.value).accountNo, createdAt:d})
             
            }}
             value ={nonWayaReqData.senderId?JSON.stringify(wallets?.filter((item) =>item.accountNo === nonWayaReqData.senderId)[0]):""}

           >
            <option value="" key='select' selected disabled>
               Select wallet
             </option>
             {wallets.length
               ? wallets.map((item,i) => (
                   <option value={JSON.stringify(item)} key={i}>
                     {item.acct_name}
                   </option>
                 ))
               : ''}
           </select>

         
         </div>
        
         </div>

        
         <div className="input-content">
           <div className="label">Amount </div>
           <input
             value={nonWayaReqData.amount}
             onChange={(e) => setNonWayaReqData({ ...nonWayaReqData, amount: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Receiver Name </div>
           <input
             value={nonWayaReqData.receiverName}
             onChange={(e) => setNonWayaReqData({ ...nonWayaReqData, receiverName: e.target.value })}
           />
         </div>
         {
            recipientType === 'waya'&&
            <div className="input-content">
           <div className="label">Receiver ID </div>
           <input
             type= "text"
             value={nonWayaReqData.receiverId}
             onChange={(e) => setNonWayaReqData({ ...nonWayaReqData, receiverId: e.target.value })}
           />
         </div>
         }
         <div className="input-content">
           <div className="label">Receiver Email </div>
           <input
             type= "text"
             value={nonWayaReqData.receiverEmail}
             onChange={(e) => setNonWayaReqData({ ...nonWayaReqData, receiverEmail: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Receiver Phone </div>
           <input
             type= "text"
             value={nonWayaReqData.receiverPhoneNumber}
             onChange={(e) => setNonWayaReqData({ ...nonWayaReqData, receiverPhoneNumber: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Reason </div>
           <input
             type= "text"
             value={nonWayaReqData.reason}
             onChange={(e) => setNonWayaReqData({ ...nonWayaReqData, reason: e.target.value })}
           />
         </div>
         
       </div>
    </>
)
}

export default PaymentRequestForm;