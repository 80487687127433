import styled from 'styled-components';

export const BusinessTypeWrapper = styled.div`
  .pricingHeader {
    width: 100%;
    background: #ffffff;
    height: 65px;
    display: flex;
    padding: 9px 41px 10px 35.5px;
    justify-content: space-between;
    align-items: center;
    .firstSide {
      display: flex;
      align-items: center;
      .filter {
        display: flex;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: rgba(0, 0, 0, 0.5);
        margin-right: 15px;
        align-items: center;
        svg {
          margin-right: 25px;
        }
      }
      .searchCont {
        background: #f5f5f5;
        border: 1px solid #ff4b01;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 12px;
        line-height: 15px;
        padding: 14px 15px;
        color: #978c8a;
        display: flex;
        input {
          background: #f5f5f5;
          color: #978c8a;
          border: 0px;
        }
        svg {
          margin-right: 15px;
        }
      }
    }
    .btnCont {
      background: #ff4b01;
      border-radius: 3px;
      display: flex;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      padding: 11px 22px;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  .pricingTable {
    margin-top: 41px;
  }
  .pricingEdit {
    .tabBar {
      background: #fff;
      border-radius: 30px;
      width: 100%;
      height: 47px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      display: flex;
      .tabBar-item {
        border-radius: 30px;
        height: 100%;
        width: 123px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        cursor: pointer;
      }
      .tB-active {
        background: #ff4b01;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
    }
    .pricingFormCont {
      margin-top: 21px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .pfHead {
        width: 100%;
        background: #ff4b01;
        padding: 18px 36px;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */
        color: #ffffff;
        margin-bottom: 25px;
        cursor: pointer;
      }
      .pricingForm {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 36px;
        margin-bottom: 25px;
        .pfTitle {
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #ff4b01;
          text-align: center;
          margin-bottom: 16px;
          width: 100%;
        }
        .pfContent {
          text-align: center;

          .pfcTitle {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 24px;
          }
          .checkCont {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-right: 33px;
            margin-bottom: 25px;

            input {
              margin-right: 5px;
              background: #c4c4c4;
              border-radius: 4px;
              color: #fff;
            }
          }
          .inputCont {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid rgba(130, 130, 130, 0.5);
            box-sizing: border-box;
            border-radius: 4px;
            padding: 12px;
            margin-top: 12px;
            input {
              background: #fff;
              color: #000;
              border: 0px;
              flex: 1;
            }
          }
          .inputCont2 {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid rgba(130, 130, 130, 0.5);
            box-sizing: border-box;
            border-radius: 4px;
            margin-top: 12px;
            margin-bottom: 26px;
            padding: 0px;

            input {
              background: #fff;
              color: #000;
              border: 0px;
              flex: 1;
            }
          }
          .hr-dotted {
            border: 1px dashed rgba(0, 0, 0, 0.2);
            width: 100%;
            margin-bottom: 24px;
            margin-top: 31px;
            height: 0px;
          }
        }
        .pfButton {
          background: #ff4b01;
          border-radius: 5px;
          width: 269px;
          height: 72px;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;
