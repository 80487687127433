import React, { useEffect, useState } from "react";
import { customBaseUrl, httpGet, httpDelete } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import Layout from "../../../components/Layout";
import { formatDistance } from "date-fns";
import { Button } from "@mui/material";
import { useMatStyles } from "../style";
import qs from "qs";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';



const UserActivityLog = () => {
  const styles = useMatStyles();
  const currentUser = JSON.parse(localStorage.getItem("selectedUser"));
  const { id: id } = useParams();
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [metaData, setMetaData] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [deleteData, setDeleteData] = useState([])




  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize,
    page: params.pagination.current,
  });
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
  };
  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
  };
  const handleDelete = () => {



  }

  const fireAlert = async (id) => {
    await Swal.fire({
      title: 'Are you sure you want to delete.',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      icon: 'warning',
      preConfirm: async (id1) => {
        const response = await httpDelete(`${customBaseUrl.logUrl}/api/v1/log/delete/${id}`);
        if (!response.status) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${response.message}`,
            //footer: '<a href="">Why do I have this issue?</a>'
          })

        }
        return response.status;
      },
      allowOutsideClick: () => !Swal.isLoading()
    }
    ).then((result) => {
      if (result.isConfirmed) {
        // showLoader();
        handleChangePage();
        Swal.fire('Deleted', '', 'success');
      } else
        Swal.fire(' Cancelled', '', 'error')

    })
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      flex: 1,
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.id}
            {/* {currentUser.firstName} {currentUser.lastName} Transferred{" "}
            {row.tranAmount} to <b>{row.fullName.toUpperCase()}| NARR:</b> ({row.message}) */}
          </div>
        );
      },
    },
    {
      field: "requestDate",
      headerName: "DATE RECEIVED",
      editable: false,
      width: 200,
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return formatDistance(new Date(row.requestDate), new Date(), {
          addSuffix: true,
        });
      },
    },
    {
      field: "message",
      headerName: "ACTIVITY",
      editable: false,
      flex: 1,
      headerClassName: styles.tableHeader,
    
    },
    {
      field: "location",
      headerName: "LOCATION",
      editable: false,
      flex: 1,
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.location}
            {/* {currentUser.firstName} {currentUser.lastName} Transferred{" "}
            {row.tranAmount} to <b>{row.fullName.toUpperCase()}| NARR:</b> ({row.message}) */}
          </div>
        );
      },
    },
    {
      field: "delected",
      headerName: "ACTIONS",
      editable: false,
      width: 200,
      align: "end",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div >

            <Button
              variant="text"
              size="small"
              style={{ color: "#FF4B01" }}
              onClick={() => {
                //console.log("i work");

                //navigate(`/manage-user/user-activity/activity-log/${row.id}`);

                fireAlert(row.id);
              }}
            >
              <DeleteForeverIcon />
            </Button>

          </div>
        );
      },
    },
  ];
 /* const getLogs = async (params) => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.logUrl
      }/api/v1/log/${id}?${qs.stringify(
        getRandomuserParams(params)
      )}`

    );*/
  const getLogs = async (params) => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.logUrl}/api/v1/log/${id}?limit=2`
    );


    if (response?.status === true) {
      hideLoader();
      setLogs(response?.data);
      setMetaData(response?.data?.length)
     

    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });

    }
  };

  useEffect(() => getLogs(), []);
  return (
    <Layout>
      <section>
        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            className={styles.table}
            rows={logs}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}

            rowCount={metaData}
          />
        </div>
      </section>
    </Layout>
  );
};
export default UserActivityLog;
