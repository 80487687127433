import React, { useState } from 'react';
import { Modal } from 'antd';
import Swal from 'sweetalert2';
import { customBaseUrl, httpPost } from '../../../store/http';

const CreateBusinessType = ({ showModal, hideModal, className }) => {
  const [data, setData] = useState({});

  const handleSubmit = async () => {
    const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/business/type/create`,
      data
    );
    const { message, status } = res;
    if (status) {
      Swal.fire('Done', `${data.businessType} created successfully`, 'success');
      return;
    }
    Swal.fire('Error', message, 'error');
    setData({});
  };

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <h5 className='text-center mb-5 mt-5'>Create New Business Type</h5>
      <div class='col-md-8 mx-auto mt-5'>
        <label for='validationCustom02' class='form-label'>
          Business Type
        </label>
        <input
          type='text'
          class='form-control'
          id='validationCustom02'
          value={data.businessType}
          required
          onChange={(e) => setData({ ...data, businessType: e.target.value })}
        />
        <div class='invalid-feedback'>Business type is required.</div>

        <div class='col-12 text-center mt-5'>
          <button
            class='btn btn-primary text-white'
            type='submit'
            onClick={handleSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBusinessType;
