import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge, Switch } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { EditFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPut } from "../../../store/http";
import { styled } from "@mui/material/styles";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
  IconButton,
} from "@mui/material";
import  useMatStyles  from "./styles";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import NewTerm from "./newPrivacy";
import { DeleteOutline } from "@mui/icons-material";
import EditTerm from "./editPrivacy";
import EditPrivacy from "./editPrivacy";
import NewPrivacy from "./newPrivacy";
import Swal from "sweetalert2";

const Privacy = () => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [totalPrivacy, setTotalPrivacy] = useState(0);
  const [allPrivacy, setAllPrivacy] = useState([]);
  const [showPrivacy,setShowPrivacy] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState({})
  const getPrivacy = async (page, size) => {
    setAllPrivacy([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.contentManagementUrl}/privacy?page=${pages}`
    );
      console.log(request.data);
    if (request.status) {
      hideLoader();
      setAllPrivacy(request.data.privacyPolicies);
      setTotalPrivacy(request.data.totalNumberOfPrivacyPolicy);
    }else{
      console.log(request)
    hideLoader();
    }
    hideLoader();
  };


  useEffect(() => {
    getPrivacy();
}, []);



  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getPrivacy(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getPrivacy(newPage, pagination.pageSize);
  };


  const handleAct = async(id, activate) => {
    showLoader();
    let res = await httpPut(`${customBaseUrl.contentManagementUrl}/privacy/${id}/activate`,{activate})

    if(res?.status){
      hideLoader()
      Swal.fire('Done', activate?"Activated": "Deactivated")
      getPrivacy();
    }else{
      hideLoader()
      Swal.fire('Error', res?.message)
      getPrivacy();
    }

  }

  var searchInput = useRef({});
  const columns = [
    
    { field: 'content',
      headerName: 'Privacy Policies',
      width: 800,
      headerClassName: styles.tableHeader
    },
    
    { field: 'id',
    headerName: "Action ",
    width: 150 ,
    headerClassName: styles.tableHeader,
    renderCell:({row})=> <><IconButton onClick={()=>{setSelectedRow(row); setShowEdit(true)}}><EditFilled/></IconButton><Switch defaultChecked={row?.active} onClick={()=>handleAct(row?.id, !row?.active )}/> </>,
    }

  ];

  
  return (
    <Layout>

<div style={tool}>
{/*<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        {/*<div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
          </div>*/}
        <div></div>
        <Row type="flex" justify="end" align="middle">
             
          <Button
          onClick={()=>setShowPrivacy(true)}
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined/>
              New Privcy Policy
            </Button>    
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>All Terms and Conditions</p>
          <h2 className="text-left">{totalPrivacy}</h2>
        </Card.Grid>
       
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      <Card style={{ background: "transparent" }}>
        <Card.Grid style={tableGridStyle} className="mr-2">
      {allPrivacy.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allPrivacy}
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalPrivacy}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}
  </Card.Grid>
       
       </Card>
 
      
      {showPrivacy&& <NewPrivacy
                      setShowModal={setShowPrivacy}
                      showModal={showPrivacy}
                      getPrivacy={getPrivacy}
                      className={styles.modal}
                      />  
    }
    {showEdit&& <EditPrivacy
                      setShowModal={setShowEdit}
                      showModal={showEdit}
                      getPrivacy={getPrivacy}
                      content ={selectedRow.content}
                      id={selectedRow.id}
                      className={styles.modal}
                      />  
    }
    </Layout>
  );
};

const gridStyle = {
  width: "23%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};
const tableGridStyle = {
  width: "100%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Privacy;
