import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import DeactivateUsers from "../../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import CreateBulkForm from "../../../components/Modal/User/CreateBulkForm";
import SendSingleUserModal from "../../../components/Modal/TransferToNonWayaUser/SendSingleUser";
import BulkTransfer from "../../../components/Modal/TransferToNonWayaUser/BulkTransfer";
import NonWayaWebForm from "../../../components/Modal/TransferToNonWayaUser/WebForm";
import { useTheme } from "styled-components";
import ExportData from "../../WayaOfficial/modal/exportData";
import TemporalPeriodModal from "../../../components/Modal/TransferToNonWayaUser/TemporalPeriod";
import { Settings } from "@mui/icons-material";
import ReferralSettings from "./settings";
import AssignCode from "./assignCode";

const TransactionsDone = () => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showSendUser, setShowSendUser] = useState(false);
  const [showTransferExcel, setShowTransferExcel] = useState(false);
  const [showTransferForms, setShowTransferForms] = useState(false);
  const [showAssignCode, setShowAssignCode ] = useState(false)
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [allTransfers, setAllTransfers] = useState(0);
  const [retrievedTransfers, setRetrievedTransfers] = useState(0);
  const [nonRetrievedTransfers, setNonRetrievedTransfers] = useState(0);
  const [allTransactions, setAllTransactions] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [reversalDays, setReveversalDays] = useState([]);
  const getTransactions = async (page, size) => {
    setAllTransactions([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-user-transaction-count`
    );
      console.log(request?.data);
      console.log('dtaa',request);
      setAllTransactions(request);
      setAllTransfers(request?.length);
    hideLoader();
  };

  

  useEffect(() => {
    getTransactions();
}, []);

  const SendUserOptions = [
    "Send to Single User",
    "Send Using Webforms",
    "Upload Excel File to Send",
  ];

  const handleSendUserDropdownAction = (item) => {
    if (item === "Send to Single User") setShowSendUser(true);
    if (item === "Upload Excel File to Send") setShowTransferExcel(true);
    if (item === "Send Using Webforms") setShowTransferForms(true);
  };
 
  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };



  var searchInput = useRef({});
  const columns = [
    
    { field: 'userId',
      headerName: 'Full Name',
      width: 250 ,
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return <div className="rowitem">{row?.user?.cust_name}</div>;
      },
  

    },
   
    { field: ' ',
    headerName: "Email",    
    width: 250,
    headerClassName: styles.tableHeader,
    renderCell: ({ row }) => {
      return <div className="rowitem">{row?.user?.emailAddress}</div>;
    },

    },
    { field: 'referralEmail',
    headerName: "Phone",
    width: 150,
    headerClassName: styles.tableHeader,
    renderCell: ({ row }) => {
      return <div className="rowitem">{row?.user?.mobileNo}</div>;
    },

    },
    { field: 'totalCount',
    headerName: "No. of Transactions",
    width: 150 ,
    headerClassName: styles.tableHeader,

    },
    { field: ' ',
    headerName: "Date created",
    width: 150 ,
    headerClassName: styles.tableHeader,
    renderCell: ({ row }) => {
      return <div className="rowitem">{row?.user?.cust_opn_date}</div>;
    },
    }

  ];

  
  return (
    <Layout>
<div
          className=""
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/users/referral")}
        >
          <span className="me-1">
            <ArrowLeftOutlined />
          </span>
          back
        </div>
<div style={tool}>

{/*<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.field !== ("totalCount"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
          </div>*/}
      </div>     


      <Grid
      getRowId ={row=>row.userId}
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allTransactions?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
        getRowId={row=>row.userId}
         className={styles.table}
         density="comfortable"
        rows={allTransactions }
        
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={allTransfers}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
    
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allTransactions}  />
      }
  
      {
        showAssignCode && 
        <AssignCode showModal={showAssignCode} hideModal={setShowAssignCode} title="Assign Refferral Code" className={styles.modal} />
      }
    </Layout>
  );
};

const gridStyle = {
  width: "40%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "50px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-around",
  marginBottom: "30px",
};

export default TransactionsDone;
