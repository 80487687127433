import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import { clear } from "@testing-library/user-event/dist/clear";
import { ref } from "yup";
import PaymentRequestForm from "./paymentRequest";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] == "") return true;
  }
  return false;
}

const PerformTransactionForms = ({ showModal, hideModal, banks, title, wallets , userId}) => {
  const tranInitialState = {
    amount: '',
    phoneNumber: '',
    email: '',
    userId,
    "tranCrncy": "NGN",
    "tranNarration": "Transfer From Waya Official",
    "customerCreditAccount": " ",
    "officeDebitAccount": "",
    "paymentReference": "",
    "tranCrncy": "NGN",
    "tranType": "TRANSFER"

  };
  const NonWayaInitialState = {
    amount: '',
    emailOrPhoneNo: '',
    fullName: '',
    officialAccountNumber:'',
    paymentReference: " ",
    userId,
    "tranCrncy": "NGN",
    "tranNarration": "Transfer From Waya Official" 

  };
  const NonWayaReqInitialState = {
    amount: '',
    receiverEmail: '',
    receiverName: '',
    receiverPhoneNumber: '',
    senderEmail: "admin@wayapaychat.com",
    senderId: "",
    "senderName": "WAYA OFFICIAL",
    "wayaOfficial": true,
    createdAt: '',
    reason:'',
    receiverId: ''
  }
  const bankInitialState = {
    amount: '',
    paymentReference: " ",
    userId,
    walletAccountNo:'',
    crAccount: '',
    "tranCrncy": "NGN",
    "tranNarration": "Transfer From Waya Official",
    crAccountName: '',
    transactionPin: ''


  };
  const [data, setData] = useState(tranInitialState);
  const [nonData, setNonData] = useState(NonWayaInitialState);
  const [bankData, setBankData] = useState(bankInitialState);
  const [nonWayaReqData,setNonWayaReqData] = useState(NonWayaReqInitialState)
  const [transactions, setTransactions] = useState([{ ...tranInitialState }]);
  const [bankTransactions, setBankTransactions] = useState([{ ...bankInitialState }]);
  const [nonWayaTransactions, setNonWayaTransactions] = useState([{ ...NonWayaInitialState }]);
  const [nonWayaReqTransactions, setNonWayaReqTransactions] = useState([{...NonWayaReqInitialState}])
  const [selectedWallet, setSelectedWallet] = useState({});
  const [page, setPage] = useState(1);
  const [tranType, setTranType] = useState('wallet');
  const [recipientType,setRecipientType] = useState('waya')
  const clearState = () => {
    setData({ ...tranInitialState });
    setNonData({...NonWayaInitialState});
    setBankData({...bankInitialState});
    setNonWayaReqData({...NonWayaReqInitialState})
  };
  useEffect(() => {
    console.log(selectedWallet);
  }, 
   [selectedWallet]
  );

  const handleChange = (event, value) => {
    let payReqData = nonWayaReqData
    if(recipientType === 'nonwaya') delete payReqData.receiverId;
    const isEmpty = checkProperties(tranType==='wallet'?data:tranType==='bank'?bankData:tranType==='paymentrequest'?payReqData:nonData);
    if (isEmpty) {
     return Swal.fire("Warning", "Please fill all the fields", "warning");
    } 
    if(tranType === 'wallet')
     {
      console.log(data);
      event.preventDefault();
      setTransactions((prevState) => [data, ...prevState]);
      setPage(transactions.length + 1);
      clearState();
    }else if(tranType === 'bank')    
     {
     // alert(JSON.stringify(bankData))
      console.log(bankData);
      event.preventDefault();
      setBankTransactions((prevState) => [bankData, ...prevState]);
      setPage(bankTransactions.length + 1);
      clearState();
    }else if(tranType=== 'paymentrequest'){
      event.preventDefault();
      setNonWayaReqTransactions((prevState)=>[nonWayaReqData, ...prevState]);
      setPage(nonWayaReqTransactions.length + 1)
      clearState();
    }
    
    else{
      console.log(nonData);
      event.preventDefault();
      setNonWayaTransactions((prevState) => [nonData, ...prevState]);
      setPage(nonWayaTransactions.length + 1);
      clearState();
    }
  };


  const handlePaginateChange = (event, pageV) => {
    const currentIndex = pageV - 1;
    setPage(pageV);
    if (tranType === 'wallet') {
      setData(transactions.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(transactions);
    }else if (tranType === 'bank') {
      setBankData(bankTransactions.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(bankTransactions);
    }else if(tranType === 'paymentrequest') {
    setNonWayaReqData(nonWayaReqTransactions.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(nonWayaReqTransactions);
    }
    else{
     setNonData(nonWayaTransactions.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(nonWayaTransactions);
    }
  };
  useEffect(() => {
    console.log(transactions);
    let dataRec = tranType==='wallet'?transactions:tranType==='bank'?bankTransactions:tranType==='paymentrequest'?nonWayaReqTransactions: nonWayaTransactions;
    dataRec[page - 1] = tranType==='wallet'? data:tranType === 'bank'?bankData: tranType==='paymentrequest'?nonWayaReqData: nonData;
    if(tranType==='wallet'){
      setTransactions(dataRec);
    }else if(tranType==='bank'){
      setBankTransactions(dataRec);
    }else if(tranType === 'paymentrequest'){
      setNonWayaReqTransactions(dataRec)
    }
    else{
      setNonWayaTransactions(dataRec);
    }

  }, [data, nonData, bankData, nonWayaReqData]);

  const handleRemoveUser = async () => {
    let current = page === 1 ? 1 : page - 1;
    if (tranType === 'wallet') {
     
    await setTransactions((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...tranInitialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
    }else if (tranType === 'bank') {
     
      await setBankTransactions((prevState) =>
        page !== 1
          ? prevState.filter((_, i) => i !== current)
          : [{ ...bankInitialState }]
      );
      await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
      }else if( tranType === 'paymentrequest'){
        await setNonWayaReqTransactions((prevState)=>
        page !== 1
          ? prevState.filter((_, i) => i !== current)
          : [{ ...NonWayaReqInitialState }]
      );
      await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
      }
    else{
      await setNonWayaTransactions((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...NonWayaInitialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
    }
  };

  const handleTransaction = async () => {
   // return alert(JSON.stringify(transactions))
    let d = new Date()
    let ref = d.getTime();
    let tranData = {
      officeAccountNo: selectedWallet,
      paymentReference: ref,
      tranCrncy: "NGN",
      tranNarration: "Transfer from waya official",
      tranType: "TRANSFER",
      userId: userId,
      usersList: transactions
    
    }

    showLoader();
    let response;
    if(tranType === 'wallet'){
      let d= new Date();
      response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
     {
       payload: JSON.stringify(transactions),
       "requestId": `REQ${d.getTime()}`,
       "requestType": "MulitiOfficialToWallet",
       customerName: 'Multiple Transfer'
     } )
      /*/
     response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/official/user/transfer-multiple`,
      transactions
    );*/
    
  }else if(tranType === 'bank'){
   /*response = await httpPost(
     `${customBaseUrl.withdrawalUrl}/admin/withdrawal/official/multipleFund`,
     bankTransactions
   );
  /*/ let d= new Date();
   response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
  {
    payload: JSON.stringify(bankTransactions),
    "requestId": `REQ${d.getTime()}`,
    "requestType": "MulitiOfficialToBank",
    customerName: 'Multiple Bank Transfer'
  } )
 }else if(tranType === 'paymentrequest' && recipientType==='nonwaya'){
  /*response = await httpPost(
    `${customBaseUrl.paymentRequestUrl}/api/v1/admin/payment-request-non-waya-multiple-form`,
    nonWayaReqTransactions
  );
  /*/
  let d= new Date();
  response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
 {
   payload: JSON.stringify(nonWayaReqTransactions),
   "requestId": `REQ${d.getTime()}`,
   "requestType": "MulitiOfficialReqToNonwaya",
   customerName: 'Multiple Non-Waya Transfer'
 } )
 }else if(tranType === 'paymentrequest' && recipientType==='waya'){
  
  /*response = await httpPost(
    `${customBaseUrl.paymentRequestUrl}/api/v1/admin/waya/official-multiple-form`,
    nonWayaReqTransactions
  );
  /*/
  let d= new Date();
  response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
 {
   payload: JSON.stringify(nonWayaReqTransactions),
   "requestId": `REQ${d.getTime()}`,
   "requestType": "MulitiOfficialReqTowaya",
   customerName: 'Multiple Waya Transfer'
 } )
  
 }  
  else{
   /* response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new-multiple-waya-official`,
      nonWayaTransactions
    );
*/
let d= new Date();
  response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
 {
   payload: JSON.stringify(nonWayaTransactions),
   "requestId": `REQ${d.getTime()}`,
   "requestType": "MulitiOfficialToNonwaya",
   customerName: 'Multiple Waya Transfer'
 } )

    }
    hideLoader();
    /*if (tranType==='wallet'?response.status === true:tranType==='bank'?response.status === true:tranType === 'paymentrequest'?response.status:response[0].status === true) {
      hideModal(false);
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response?.message||response[0].message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } */
    
    if (response.status) {
      hideModal(false);
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response?.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      console.log(response);
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response?.message||response[0].message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  const resolveBank = async (value) => {
    showLoader();
    if (value === '') {
      Swal.fire('Account Number is required');
      return;
    }
    const newPostData = {
      accountNumber: value,
      bankCode: bankData.bankCode,
      // bankName: postData.bankName,
    };
    const res = await httpPost(
      `${customBaseUrl.cardUrl}/bankAccount/resolveAccountNumber/WD`,
      newPostData
    );


    if (res.status) {
      hideLoader();
      setBankData({
        ...bankData,
        crAccountName: res.data,
        crAccount: value,
      });
    } else {
      Swal.fire(res.message);
    }
    hideLoader();
  };


  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Wallet Transfer With Waya Official
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <Wrapper>
      {tranType==='wallet'? <div className="cw-content">
          <div className="input-content ">
            <div className="label">Transaction Type </div>
            <select name="" id="" className='border border-dark rounded' value={tranType} onChange={(e)=>{setTranType(e.target.value); setTransactions([{...tranInitialState}])}} >
              <option value="wallet">Wallet Transfer</option>
              <option value="bank">Bank Transfer</option>
              <option value="nonwaya">Transfer to Non Waya users</option>
              <option value="paymentrequest">Payment Request</option>


            </select>

            <div className="input-content">
            <div className="label">Select Waya Official Wallet</div>
            <select
              className='border border-dark rounded'
              onChange={(e) => {
                let d = new Date();
                let ref = d.getTime()
               setSelectedWallet(JSON.parse(e.target.value).accountNo)  
               setData({...data, officeDebitAccount:JSON.parse(e.target.value).accountNo, paymentReference:ref})                 
              }}

              value ={data.officeDebitAccount?JSON.stringify(wallets?.filter((item) =>item.accountNo === data.officeDebitAccount)[0]):""}

            >
              <option value="" key='select' selected disabled>
                Select wallet
              </option>
              {wallets.length
                ? wallets.map((item,i) => (
                    <option value={JSON.stringify(item)} key={i}>
                      {item.acct_name}
                    </option>
                  ))
                : ''}
            </select>
          </div>
          <div className="label">Recipient Type </div>
            <select name="" id="" className='border border-dark rounded' >
              <option value="">Web Forms</option>
            </select>
          </div>

         <div className="input-content">
            <div className="label"> Account Number</div>
            <div className="input-content">
              <input
                value={data.customerCreditAccount}
                onChange={(e) =>{
                  setData({ ...data, customerCreditAccount: (e.target.value).replace(/\s/g, '') })
                }
              }
              />
            </div>
          </div>

       

          <div className="input-content">
            <div className="label">Amount </div>
            <input
              value={data.amount}
              onChange={(e) => setData({ ...data, amount: e.target.value })}
            />
          </div>
          <div className="input-content">
            <div className="label">Email </div>
            <input
              type= "email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
          <div className="input-content">
            <div className="label">Phone No </div>
            <input
              value={data.phoneNumber}
              onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
            />
          </div>
        </div>:
        tranType==='bank'?
        <div>
        
          <div className="cw-content">
         <div className="input-content ">
           <div className="label">Transaction Type </div>
           <select name="" value={tranType} id="" className='border border-dark rounded' onChange={(e)=>{setTranType(e.target.value); setBankTransactions([{...bankInitialState}]) }} >
             <option value="wallet">Wallet Transfer</option>
             <option value="bank">Bank Transfer</option>
             <option value="nonwaya">Transfer to Non Waya users</option>
             <option value="paymentrequest">Payment Request</option>

           </select>

           <div className="input-content">
           <div className="label">Select Waya Official Wallet</div>
           <select
             className='border border-dark rounded'
             onChange={(e) => {
              let d = new Date()
              let ref = d.getTime();
              setBankData({...bankData, walletAccountNo: JSON.parse(e.target.value).accountNo, paymentReference:ref})
             
            }}
             value ={bankData.walletAccountNo?JSON.stringify(wallets?.filter((item) =>item.accountNo === bankData.walletAccountNo)[0]):""}
           >
            <option value="" key='select' selected disabled>
               Select wallet
             </option>
             {wallets.length
               ? wallets.map((item,i) => (
                   <option value={JSON.stringify(item)} key={i}>
                     {item.acct_name}
                   </option>
                 ))
               : ''}
           </select>         
         </div>
         <div className="label">Recipient Type </div>
           <select name="" id="" className='border border-dark rounded' >
             <option value="">Web Forms</option>
           </select>
         </div>

         <div className="input-content">
            <div className="label">Select  Bank</div>
          <select onChange={(e) =>{ 
            let d = new Date()
           let transRef = d.getTime();
            setBankData({...bankData, transRef, bankCode: JSON.parse(e.target.value).bankCode, bankName: JSON.parse(e.target.value).bankName})
          }} 
            value={bankData.bankCode?JSON.stringify(banks?.filter((item) =>item.bankCode === bankData.bankCode)[0]):""}
          className='border border-dark rounded'>
             <option value=''>...</option>
              {banks? banks.map((x) => (
                <option key={`banks${x.id}`} value={JSON.stringify(x)}>
                  {x.bankName}
                </option>
              )):''}
            </select>
          </div>     
          <div className="input-content">
            <div className="label">Account Number </div>
            <input
              value={bankData.crAccount}
              onChange={(e) => {
                  if (e.target.value?.length > 9) {
                    resolveBank(e.target.value);
              }
              setBankData({...bankData, crAccount: e.target.value})
            }}
            />   
            </div>
          <div className="input-content">
            <div className="label">Account Name </div>
            <input
              value={bankData.crAccountName}
            />
          </div>

         <div className="input-content">
           <div className="label">Amount </div>
           <input
             value={bankData.amount}
             onChange={(e) => setBankData({ ...bankData, amount: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">PIN </div>
           <input
              type='password'
             value={bankData.transactionPin}
             onChange={(e) => setBankData({ ...bankData, transactionPin: e.target.value })}
           />
         </div>
       
         
       </div>
          
        </div>:
        tranType === 'nonwaya'?
         <div className="cw-content">
         <div className="input-content ">
           <div className="label">Transaction Type </div>
           <select name="" id="" value={tranType} className='border border-dark rounded' onChange={(e)=>{setTranType(e.target.value); setTransactions([{...NonWayaInitialState}]) }} >
             <option value="wallet">Wallet Transfer</option>
             <option value="bank">Bank Transfer</option>
             <option value="nonwaya">Transfer to Non Waya users</option>
             <option value="paymentrequest">Payment Request</option>

           </select>

           <div className="input-content">
           <div className="label">Select Waya Official Wallet</div>
           <select
             className='border border-dark rounded'
             onChange={(e) => {
              let d = new Date()
              let ref = d.getTime();
              setNonData({...nonData, officialAccountNumber: JSON.parse(e.target.value).accountNo, paymentReference:ref})
             
            }}
             value ={nonData.officialAccountNumber?JSON.stringify(wallets?.filter((item) =>item.accountNo === nonData.officialAccountNumber)[0]):""}

           >
            <option value="" key='select' selected disabled>
               Select wallet
             </option>
             {wallets.length
               ? wallets.map((item,i) => (
                   <option value={JSON.stringify(item)} key={i}>
                     {item.acct_name}
                   </option>
                 ))
               : ''}
           </select>

         
         </div>
         <div className="label">Recipient Type </div>
           <select name="" id="" className='border border-dark rounded' >
             <option value="">Web Forms</option>
           </select>
         </div>

        
         <div className="input-content">
           <div className="label">Amount </div>
           <input
             value={nonData.amount}
             onChange={(e) => setNonData({ ...nonData, amount: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Full Name </div>
           <input
             value={nonData.fullName}
             onChange={(e) => setNonData({ ...nonData, fullName: e.target.value })}
           />
         </div>
         <div className="input-content">
           <div className="label">Email or Phone Number </div>
           <input
             type= "text"
             value={nonData.emailOrPhoneNo}
             onChange={(e) => setNonData({ ...nonData, emailOrPhoneNo: e.target.value })}
           />
         </div>
         
       </div>:<PaymentRequestForm
        nonWayaReqData={nonWayaReqData} 
        setNonWayaReqData={setNonWayaReqData}
        tranType= {tranType}
        setTranType={setTranType}
        wallets={wallets}
        NonWayaReqInitialState={NonWayaReqInitialState}
        setTransactions={setBankTransactions}
        setRecipientType={setRecipientType}
        recipientType={recipientType}
        />
       }
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleTransaction}
        >
          Proceed
        </Button> */}
      </div>
      <div style={{ marginTop: "10px" }} className="row">
        <div
          style={{
            
           
          }}
          className="col-md-2"
        >
          <Button
            className="iconUI"
            style={{ color: "#C4C4C4", border: "1px solid #C4C4C4" }}
            onClick={handleChange}
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <Button
            className="iconUI"
            onClick={handleRemoveUser}
            style={{ color: "#FF4B01", border: "1px solid #FF4B01" }}
            icon={<CloseOutlined />}
          >
            Remove 
          </Button>
        </div>
        <div className="col-md-6">
          <Button
            className="iconUI"
            type="ghost"
            onClick={handleTransaction}
            style={{ color: "#fff", background: "#FF4B01", float: "right" }}
          >
            Proceed
          </Button>
        </div>
      </div>

      <div className="row" style={{ marginTop: "7px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Stack spacing={2}>
            <Pagination
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                alignSelf: "center",
              }}
              count={tranType==='wallet'?transactions.length:tranType==='bank'?bankTransactions.length: tranType==='paymentrequest'?nonWayaReqTransactions.length: nonWayaTransactions.length}
              page={page}
              onChange={handlePaginateChange}
            />
          </Stack>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Modal>
  );
};

export default PerformTransactionForms;
