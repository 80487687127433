import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';

const RejectPaymentRequest = ({ showModal, hideModal, reason, setReason, handleReject }) => {

  const { RangePicker } = DatePicker;




  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
        setReason('')
      }}
      width='40%'
    >
      <Wrapper>
        <div className="cw-content">

          <div >


              <h3 >Reject Transaction</h3>
            
           
          
            <div className="input-content label">
              <div className="label">Reason</div>
              <input value={reason} onChange={(e) => { setReason(e.target.value)}} className="input-content label" />
            </div>
          
          </div>

        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'stretch',
          justifyContent: 'stretch',
        }}
      >
      
        <Button
          style={{
            background: '#FF4B01',
            color: '#fff',
            fontSize: '8px',
            alignItems: 'center',
            width: 70
          }}
          onClick={handleReject}
        >
          Reject
        </Button>
      </div>
    </Modal>
  );
};

export default RejectPaymentRequest;
