import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import '../index.scss';
import { customBaseUrl, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const BulkTransfer = ({ showModal, hideModal, title, className }) => {
  const [file, setFile] = useState('');
  const [amount, setAmount] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();

    let d = new Date();
    let reqId= d.getTime();
      let formData = new FormData();
    formData.append("file", file);
    formData.append("customerName", 'Bulk Users');
    formData.append("requestId", d.getTime());
    formData.append("requestType", 'BulkTransferToNonWaya'); 
/*
    const formdata = new FormData();
    formdata.append('file', file);
    const res = await httpPost(
    `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new-multiple-excel-upload`,
      formdata
    );
      */
      let customerName = ' Bulk Transfer'
    let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create/bulk/${reqId}/${customerName}/BulkTransferToNonWaya`, formData)

    if (response?.status) {
      hideLoader();
      Swal.fire('Done', response.message, 'success');
      setFile('');
      hideModal(false);
    } else {
      hideLoader();
      Swal.fire('Error',response.message, 'error');
    }
  };

  return (
    <Modal
      className={className}
      title={title}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <form class='row g-3 needs-validation' novalidate>
      
        <div className='col-10 mt-5 mb-3 mx-auto'>
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setFile(e.target.files[0])}
            />
            <label class='input-group-text' for='inputGroupFile02'>
              Browse
            </label>
            <div class='invalid-feedback'>File is required.</div>
          </div>
        </div>

        <div class='col-12 text-center mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default BulkTransfer;
