import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Switch, Input, Button, Modal, Checkbox, Radio } from "antd";
import { Button as MatButton } from "@mui/material";

import { PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import qs from "qs";
import AntTable from "../../components/CustomTable/AntTable";
import { MdRadioButtonUnchecked, MdOutlineContentCopy } from 'react-icons/md';
import { BsCheckCircle } from 'react-icons/bs';
import Wrapper, { useMatStyles } from "./style";
import banner from "../../assets/images/banner.png";
import WalletCard from "../../components/CustomCard/walletCard";
import Check from "../../assets/images/check.svg";
import Blocked from "../../assets/images/blocked.svg";
import Exp from "../../assets/images/export.svg";
import { LeftArrow, RightArrow } from "./arrows";
import del from "../../assets/images/delete.svg";
import settings from "../../assets/images/settings.svg";
import CreateWallet from "./modal/createWallet";
import PerformTransaction from "./modal/performTransaction";
import ExportData from "./modal/exportData";
import FundWallet from "./modal/fundWallet";
import WayaOfficialSettings from "./modal/wayaOfficialSettings";
import { hideLoader, showLoader } from "../../utils/loader";
import { ProfileContext } from "../../store/context/ProfileContext";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import Layout from "../../components/Layout";
import { currencyFormat, transformName } from "../../utils/helper";
import themeColor from "../../theme/color";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import PerformTransactionExcel from "./modal/PerformTransactionExcel";
import PerformTransactionForms from "./modal/PerformTransactionForms";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import PinModal from "../../components/Modal/Pin/PinModal";
import WalletForms from "./modal/createWalletForms";
import { Filter, Filter1Outlined, FilterList } from "@mui/icons-material";
import AccountStatement from "./modal/statement";
import moment from "moment";
import generatePDF from "../Transaction/AllTransaction/generatePDF";
import { CSVLink } from "react-csv";
import GenerateStatement from "../Transaction/AllTransaction/generateStatement";

const TransactionHistory = () => {
  const roles= localStorage.getItem('roles')

  let navigate = useNavigate();
  const { profile } = useContext(ProfileContext);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showPerform, setShowPerform] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showFundWallet, setShowFundWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [wayaTransactions, setWayaTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState({});
  const [totalTransaction, setTotalTransaction] = useState(0)
  const [isAdmin, setIsadmin] =useState(false)
  const [showCreateMultiWallet, setShowCreateMultiWallet] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [validateOTP, setValidateOTP] = useState('');
  const [checked, setChecked] = useState(true);
  const [searchField, setSearchField] = useState(false)
  const [showBulkExcelTransaction, setShowBulkExcelTransaction] =
    useState(false);
  const [showFormTransaction, setShowFormTransaction] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "id",
    searchText: "",
  });
  const [filter, setFilter] = useState('')
  const [otp, setOtp]= useState('')
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [totalTrans, setTotalTrans] = useState(0)
  const [totalDebitTrans, setTotalDebitTran] = useState(0)
  const [totalCreditTrans, setTotalCredTrans] = useState(0)
  const [walletId, setWalletId] = useState('');
  const [walletNo, setWalletNo] = useState(0);
  const [selectedAccountName, setSelectedAccountName] = useState('')
  const [showStatement, setShowStatement] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState([]);

  const styles = useMatStyles();
  const [cardList, setCardList] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const handleDefaultAccount = async (acctNo) => {
    showLoader();
    const defaultWallet = {
      // newDefaultAcctNo: acctNo,
      newDefaultAcctNo: walletId,
      userId: profile?.userId,
    };
    if (walletId){
      setWalletId(walletId);
    }else{
      setWalletId(acctNo);
    }
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/user/account/toggle`,
      defaultWallet
    );

    if (res.status) {
      getWallets();
      Swal.fire(`Accouunt Number ${acctNo} is set to default`);
      hideLoader();
    } else {
      Swal.fire(res.message);
      hideLoader();
    }
  };

  const updateCurrentSlide = (index) => {
    setWalletNo(index);
  };

  const handleDeleteWallet = async (acctNo) => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/account/closure`,
      {
        customerAccountNo: acctNo,
      }
    );
    if (res.status) {
      getWallets();
      hideLoader();
      Swal.fire("Deleted!", res.message, "success");
    } else {
      hideLoader();
      Swal.fire(res.message);
    }
  };

  const CheckBox = ({ status }) => {
    return (
      <div style={{ color: '#828282' }} className="last_one">
        {' '}
        <span style={{ marginRight: 30, marginLeft: 10 }}>
          {status ? (
            <BsCheckCircle color="#ff6700" size={15} />
          ) : (
            <MdRadioButtonUnchecked size={15} />
          )}
        </span>
      </div>
    );
  };

  const handleDropDown = (type, item) => {
    // console.log(type);
    if (type === "fund") {
      setShowFundWallet(true);
      setSelectedWallet(item.accountNo);
      setSelectedAccountName(item?.acct_name);
    }
    if (type === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: themeColor.primary,
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteWallet(item.accountNo);
        }
      });
    }

    if (type === "primary") {
      handleDefaultAccount(item.accountNo);
    }
    if (type === "block") {
      // handleOpenBlock(item.id);
    }
  };

  const handleDeleteUser = async () => {
    showLoader();
    const response = await httpDelete(
      `${customBaseUrl.authUrl}/api/v1/user/delete/${profile?.userId}`
    );

    hideLoader();
    const { status, message } = response.data;
    if (status === false) {
      return Swal.fire("error", message, "error");
    }
    Swal.fire(message);
  };

  // console.log(profile);

  const getWallets = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/admin/get-waya-official-account`
    );
    const Bankres = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/BK`
    );
    const Cardres = await httpGet(
      `${customBaseUrl.cardUrl}/card/list/${profile.userId}`
    );

    if (res?.status) {
      setWalletList(res.data);
    }
    if (res?.status) {
      setBankList(Bankres.data);
    }
    if (Cardres?.status) {
      setCardList(Cardres.data);
    }
  };

  const handleChangeRowsPerPage = async (value) => {
    // console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getWayaTransaction(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getWayaTransaction(newPage, pagination.pageSize);
  };

  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const getWayaTransaction = async (page, size) => {
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    setLoading(true);
    /*const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/official/transaction?filter=${filter}&page=${pages}&size=${limit}`
      );*/
    const transacRes = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/transactions/${walletId}?page=${pages}&size=${limit}`
    );
    if (transacRes?.status) {
      console.log('transRes', transacRes);
      hideLoader();
      setLoading(false);
      console.log('transaction', transacRes.data)
      setTotalTransaction(transacRes.data.totalElements)
      setWayaTransactions(transacRes.data.content);
      console.log('transRes1', wayaTransactions);
    }
    setLoading(false);
    hideLoader();
  };

  const handleValidate = async () => {
    showLoader();
    const res = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/authenticate-waya/otp-verify/${Number(otp)}`
   );
    if (res.status) {
      NotificationManager.success("Sucessful");
      setIsadmin(true)
      setIsModalVisible(false)
      hideLoader();

    } else {
      hideLoader();
      // console.log('otp err', res)
      navigate("../dashboard");
      NotificationManager.error(res.error);
    }
  }

  const sendOTP = async () => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/authenticate-waya/otp-send`
    );
    if (res.status = true) {
      NotificationManager.success("OTP sent");
    }
  }
  const handleOtp = () => {
    if (!isAdmin){
      setIsModalVisible(true);
          sendOTP()

    }
  }
  
  const getStat= async()=>{
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-official-credit-count`
    );
    if (res.status) {
      setTotalTrans(res.data)
    }
    const res1 = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-official-debit-transaction-count`
    );
    if (res1.status) {
      setTotalDebitTran(res1.data)
    }
    const res2 = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/get-official-debit-credit-count`
    );
    if (res2.status) {
      setTotalCredTrans(res2.data)
    }
  }
  
  useEffect(() => {    
    //  handleOtp()
   }, []);
 
 
  useEffect(() => {
   // getStat();
    //getWayaTransaction();

    /*if(walletList > 0){
      setWalletNo();
      updateCurrentSlide()
    }*/

    if (profile?.userId) {
      getWallets();

    }
  }, [profile, filter, walletId]); // eslint-disable-line react-hooks/exhaustive-deps

  
  const handleReverse = async (row) => {
    showLoader();
    // return  alert(JSON.stringify(row))
    let data = {
      tranId: row.tranId,
      tranDate: row.tranDate,
      tranCrncy: "NGN",
    }
    // `${customBaseUrl.withdrawalUrl}/admin/withdrawal/reverseWithdrawalTransaction`,

    let response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/reverse`,
      data
    )

    if (response.status) {
      hideLoader();
      Swal.fire('Success', response.message);

    } else {
      Swal.fire('Error', response.message);
      hideLoader();

    }
  };

  const handleChecked = () =>{
    if (walletNo){
      setChecked(true)
    }
  }

  const dropDownOptions = ["Reverse"];
  const handleDropdownAction = (item, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleReverse(row);
      }
    });


    if (item === "View") viewTransactions(item);
  };

  const WalletDropDownOptions = ["Block Account"];
  const handleWalletDropdownAction = (item, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be block this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, block it!",
    }).then((result) => {
      if (result.isConfirmed) {
       // handleReverse(row);
      }
    });


    if (item === "View") viewTransactions(item);
  };
  const viewTransactions = () => {
    return ''
  }


  const columns = [
    {
      headerName: "Id",
      field: "id",
      sorter: true,
      width: 150,
      headerClassName: styles.tableHeader,

    },
    {
      headerName: "SENDER",
      field: "senderName",
      sorter: true,
      width: 150,
      headerClassName: styles.tableHeader,

    },
    {
      field: "partTranType",
      headerName: "Type",
      width: 150,
      editable: false,
      sortable: true,
      description: "Status Column",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div>
            <span
              style={{
                color: `${row.partTranType === "C" ? "#27AE60" : "#EB5757"}`,
              }}
            >
              {row.partTranType === "C" ? "Credit" : "Debit"}
            </span>
          </div>
        );
      },
    },
    {
      headerName: "CATEGORY",
      field: "tranCategory",
      width: 100,
      headerClassName: styles.tableHeader,
      

    },
    {
      headerName: "NARRATION",
      field: "tranNarrate",
      headerClassName: styles.tableHeader,
      width: 300
      

    },
    {
      headerName: "RECIPIENT",
      field: "receiverName",
      width: 200,
      headerClassName: styles.tableHeader,
      

    },
    {
      headerName: "AMOUNT",
      field: "tranAmount",
      sorter: true,
      width: 100,
      headerClassName: styles.tableHeader,
      
    },

    {
      headerName: "DATE CREATED",
      field: "createdAt",
      width: 200,
      headerClassName: styles.tableHeader,

    },
    {
      field: "",
      headerName: "ACTION ",
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (

        row.tranCategory !== 'REVERSAL' ? <Button onClick={(item) => handleDropdownAction(item, row)}>reverse</Button> : ''
      ),
    },
  ];

  const performTransactionOptions = [
    "Single User",
    "Excel Upload",
    "Web Forms",
  ];
  const handlePerformTransactions = (item) => {
   // if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){
    if (item === "Single User") setShowPerform(true);
    if (item === "Excel Upload") setShowBulkExcelTransaction(true);
    if (item === "Web Forms") setShowFormTransaction(true);
    /*}else{
      NotificationManager.error('Not authorized');
    }*/
  };

  const walletOptions = [
    "Single Wallet",
    "Multiple Wallet"
  ];
  const handleWalletAction = (item) => {
    if (item === "Single Wallet") setShowCreateWallet(true);
    if (item === "Multiple Wallet") setShowCreateMultiWallet(true);

  };
  const transactionOptions = [
    "Debit",
    "Credit"
  ];
  const handleTransactionOption = (item) => {
    if (item === "Debit") setFilter("D");
    if (item === "Credit") setFilter("C");

  };

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const getTransaction = async (e) => {
    e.preventDefault();
    console.log('fffff', filteredData)
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction-list/${filteredData?.acctNum}?fromdate=${moment(filteredDate?.fromdate).format('YYYY-MM-DD')}&todate=${moment(filteredDate?.todate).format('YYYY-MM-DD')}`
    );
    
    const filter = clean(filteredData);

    if (response.status === true) {
      const filtered = response?.data?.transaction?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered);
      setTotalTransaction(filtered?.length)
      setWayaTransactions(filtered);
      //setTransactions(filtered);
      //setFiltered(response.data.content)
      //setMetaData(filtered?.length);
      //  console.log('filter', fil)
      NotificationManager.success(response?.message)

      hideLoader();
    } else {
      NotificationManager.error(response?.message)
      setWayaTransactions([]);
  
      hideLoader();
    }
  };
  let category = ['COMMISSION', 'TRANSFER', 'REVERSAL', 'WITHDRAW', 'FUNDING', 'AIRTIME_TOPUP', 'DATA_TOPUP', 'CABLE']

  return (
      <Wrapper>
        {!showStatement?
        <>
           <div className="preCard-div">
          <div className="title">Select Account Number to Display History</div>
          <div className="switch-box">
            {//<div className="text">Hide Balances </div>
            }
          </div>
          <div className="grow" />
          <div className="pd-btn">
            <AntDropdown
             dropDownOptions={walletOptions}
             handleDropdownAction={handleWalletAction}
            ><span>
              <PlusCircleOutlined/> 
              Create New Account Number
             </span>
            </AntDropdown>
          </div>
        </div>
        
        <div className="" style={{flexDirection:'row', paddingTop: 10}}>
            <div>
          {/*<select onChange={(e)=>setFilteredData({...filteredData, accountNo: e.target.value})}
            className="search-input">
            <option>
                Select Account
            </option>
            {
                walletList?.map((e,i)=>
                    <option value={e.accountNo}>
                       {e.description} - {e.accountNo} - {e.acct_name} - {currencyFormat(e.clr_bal_amt)}
                    </option>
                )
            }
          </select>*/}
          {walletId?
          <AntDropdown          
          dropDownOptions={WalletDropDownOptions}
          handleDropdownAction={(item) => handleWalletDropdownAction(item, walletId)}
          isArrow
        />
        :''}
        </div>
        </div>
        
        <div className="table-div">
        <div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10, backgroundColor:'#ff6700'}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div>           
       <div hidden={!searchField}>
      <form onSubmit={getTransaction}>
        <div>
        <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Select Account</span>
            <select class="form-select" id="inputGroupSelect01" required
          onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
        >
          <option selected>Choose...</option>
          {
                walletList?.map((e,i)=>
                    <option value={e.accountNo}>
                       {e.description} - {e.accountNo} - {e.acct_name} - {currencyFormat(e.clr_bal_amt)}
                    </option>
                )
            }
        </select>
        </div>
        </div>
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Category</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, tranCategory: e?.target?.value})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>

          {
            category?.map((e,i)=>
            <option key={i} value={e}>{e.replace('_',' ')}</option>
          )
          }
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Transaction Type</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, partTranType: e?.target?.value})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value="D" >DEBIT</option>
          <option value="C">CREDIT</option>         
        </select>        
      </div>
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      <div className="row">
        
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Reference Number</span>        
          <input type="text" aria-label="Ref" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, paymentReference: e?.target?.value})}

          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Transaction Id</span>        
          <input type="text" class="form-control"  aria-label="tranId"
            onChange={(e)=>setFilteredData({...filteredData, tranId: e?.target?.value})}

          />
        </div>
      </div>
      <div className="row">
      
        {/*<div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Account Number</span>        
          <input type="text" aria-label="First name" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
          />
        </div>*/}
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Email</span>        
          <input type="email" class="form-control"  aria-label="Email"
             onChange={(e)=>setFilteredData({...filteredData, createdEmail: e?.target?.value})}
          />
        </div>
      </div>
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Search</button>
      </div>
      </form>
            </div>
            <div >
        <div class="col-md-12">
 <MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <img src={Exp} alt="export" />           
            <CSVLink
          data={wayaTransactions}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`Transactions-history.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </MatButton> 
          <MatButton
            //className="update-button-1"
            onClick={() => generatePDF(wayaTransactions, filteredDate)}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>DOWNLOAD AS PDF</div>
          </MatButton>     
          
      </div>
      </div>

          <div className="search-row">
            {/*<select
              className="search-input"
              onChange={(event) => {
                setSearch({ ...search, searchBy: event.target.value });
              }}
            >
              <option value="disabled" disabled>
                Search By...
              </option>
              {columns
                .filter((x) => x.field !== ("tranAmount"||"id"))
                .map((header) => (
                  <option value={header.field}>
                    {transformName(header.headerName)}
                  </option>
                ))}
            </select>
            <Input
              className="search-input"
              //   size="large"
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              placeholder="Search Users by Name, Email or Date"
              prefix={<SearchOutlined className="search-icon" />}
            />
            {/*<div style={{marginLeft:10}} >
                          <AntDropdown
             dropDownOptions={transactionOptions}
             handleDropdownAction={handleTransactionOption}
            ><span>
              <FilterList/> 
              
             </span>
            </AntDropdown>
            </div>*/}

            <div className="grow" />
            {/*<Button
              className="update-button-1"
              onClick={() => setShowExport(true)}
            >
              <img src={Exp} alt="export" />
              <div>Export Data</div>
            </Button>*/}
            <Button
            className="update-button"
            variant="contained" 
            style={{marginRight:20}}
            onClick={()=>setShowStatement(true)}
          >
            <div>Generate Statement</div>
          </Button> 
            {/*<AntDropdown
              size="medium"
              dropDownOptions={performTransactionOptions}
              handleDropdownAction={handlePerformTransactions}
            >
              <Button className="update-button">Perform Transaction</Button>
          </AntDropdown>*/}
          </div>
          {/*} <div className="tableFilter">
            <select
              className="tfItem"
              defaultValue="disabled"
              onChange={() => {}}
            >
              <option value="disabled" disabled>
                File Type
              </option>
            </select>
            <select
              className="tfItem"
              defaultValue="disabled"
              onChange={() => {}}
            >
              <option value="disabled" disabled>
                Document Type
              </option>
            </select>
            </div>*/}
          <div className="mt-3">
            {wayaTransactions.length ? (
              <div style={{ height: 800, width: "100%" }}>
                <DataGrid
                  className={styles.table}
                  density="comfortable"
                  rows={wayaTransactions}
                //  rows={wayaTransactions?.fil} TypetranAmount
                  /*rows={search.searchText ? wayaTransactions.filter(req => req?.id==(search?.searchText) || req?.senderName?.toLowerCase()?.includes(search?.searchText.toLowerCase())|| req?.receiverName?.toLowerCase()?.includes(search?.searchText.toLowerCase())|| req?.tranNarrate?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.tranCategory?.toLowerCase()?.includes(search?.searchText.toLowerCase())|| req?.partTranType?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.createdAt.includes(search?.searchText) || req?.tranAmount === (search?.searchText.toLowerCase())) :
                  wayaTransactions}*/
                /*  rows ={wayaTransactions?.filter((request) =>
                    request[search?.searchBy]
                      ?.toLowerCase()
                      .includes(search?.searchText?.toLowerCase())
                  )}*/
                  columns={columns}
                  page={pagination.current}
                  paginationMode="server"
                  pageSize={pagination.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    handleChangeRowsPerPage(newPageSize)
                  }
                  onPageChange={(newPage) => handleChangePage(newPage)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                  components={{ Toolbar: GridToolbar }}
                  rowCount={totalTransaction}

                />
              </div>
            ) : ""}

            {/*}  <AntTable
              pagination={null}
              users={wayaTransactions.filter((user) =>
                user[search?.searchBy] && user[search?.searchBy].toLowerCase().includes(search?.searchText.toLowerCase())
              )}
              loading={loading}
              handleGetNext={getWayaTransaction}
              columns={columns}
              />*/}
          </div>
        </div>
        </>
        :
        <GenerateStatement setShowStatement={setShowStatement} wallets={walletList}/>}

        {showCreateWallet ? (
          <CreateWallet
            showModal={showCreateWallet}
            hideModal={setShowCreateWallet}
            getWallets={getWallets}
          />
        ) : (
          ""
        )}
        {showPerform ? (
          <PerformTransaction
            showModal={showPerform}
            hideModal={setShowPerform}
            wallets={walletList}
            banks={bankList}
            title="Wallet Transfer with Waya Official"
            userId={profile?.userId}
          />
        ) : (
          ""
        )}
        {showExport ? (
          <ExportData showModal={showExport} hideModal={setShowExport} dataList={wayaTransactions} accountNo={walletId} />
        ) : (
          ""
        )}
        {showFundWallet ? (
          <FundWallet
            selectedWallet={selectedWallet}
            selectedAccountName={selectedAccountName}
            setSelectedWallet={setSelectedWallet}
            walletList={walletList}
            showModal={showFundWallet}
            hideModal={setShowFundWallet}
            userId={profile?.userId}
            cardList={cardList}
            email={profile.email}
          />
        ) : (
          ""
        )}
        {showSettings ? (
          <WayaOfficialSettings
            walletList={walletList}
            showModal={showSettings}
            hideModal={setShowSettings}
          />
        ) : (
          ""
        )}
        {showBulkExcelTransaction ? (
          <PerformTransactionExcel
            showModal={showBulkExcelTransaction}
            hideModal={setShowBulkExcelTransaction}
            wallets={walletList}
            title="Bulk Wallet Transfer with Waya Official"
            userId={profile?.userId}
          />
        ) : (
          ""
        )}
        {showFormTransaction ? (
          <PerformTransactionForms
            showModal={showFormTransaction}
            hideModal={setShowFormTransaction}
            wallets={walletList}
            banks={bankList}
            title="Web Forms Transfer with Waya Official"
            userId={profile?.userId}
          />
        ) : (
          ""
        )}
        {showCreateMultiWallet&&
        <WalletForms
        showModal={showCreateMultiWallet}
        hideModal = {setShowCreateMultiWallet}
        />
        }

            
             {isModalVisible&& <PinModal
                
                showModal={isModalVisible}
                hideModal={setIsModalVisible}
                otp={otp}
                setOtp={setOtp}
                numInputs={6}
                separator=''
                buttonLabel='Verify'
                title='Verify OTP'
                description='Please input the OTP sent to your Phone'
                handleSubmit={handleValidate}
                pinMode={true}

              />}
        {/*showStatement && (
        <AccountStatement
        wallets={walletList}
          showModal={showStatement}
          hideModal={setShowStatement}          
        />)
        */}



      </Wrapper>
  );
};
const onWheel = (apiObj, ev) => {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    // console.log(apiObj, ev);
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    // console.log(apiObj, ev);
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    // console.log(apiObj, ev);
    apiObj.scrollPrev();
  }
};
export default TransactionHistory;
