import {
    ExportOutlined,
    PlusOutlined,
    SearchOutlined,
    SettingOutlined,
  } from "@ant-design/icons";
  import { Button, Input, Row, Space } from "antd";
  import { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import Layout from "../../../components/Layout";
  import { useMatStyles } from "./style";
  import CustomTable from "../../../components/CustomTable/index3";
  import styled from "styled-components";
  import {
    Box,
    TableCell,
    TableRow,
    Grid as MatGrid,
    Paper,
  } from "@mui/material";
  import { getComparator, stableSort } from "../../../utils/helper";
  import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../store/http";
  import { hideLoader, showLoader } from "../../../utils/loader";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
  import ExportData from "../../WayaOfficial/modal/exportData";
  import ViewDocModal from "../../../components/Modal/ViewDoc";
  import Swal from "sweetalert2";
import Reason from "./reason";
  
  const Container = `styled.div`;
  
  
  
  
  const KycCacPage = () => {
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState({
      order: "asc",
      orderBy: "businessName",
    });
    const [search, setSearch] = useState({
      searchBy: "businessName",
      searchText: "",
    });
    const [metaData, setMetaData] = useState(0);
    const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
    const [selectedfield, setSelectedField] = useState('All CAC Documents');
    const [pendingData, setPendingData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [rejectedData, setRejectedData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [stat, setStat] = useState([])
    const [tierStat, setTierStat] = useState([]);
    const [showExport, setShowExport] = useState(false);
    const [searchField, setSearchField] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDate, setFilteredDate] = useState([]);
    const [showViewDocModal, setShowViewDocModal] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [viewData, setViewData] = useState({});
    const [field, setField] = useState(null);
    const [value, setValue] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});
    const styles = useMatStyles();
  
  
  
    const dropDownOptions = ["Update KYC", "View Doc"];
    const handleDropdownAction = (item, row) => {
      if (item === "Update KYC") navigate(`/users_kyc/${row.userId}`);
      if(item === "VIEW DOCUMENT"){
          setViewData({
            url: row?.docUrl,
            title: row?.dataType,
          });
          setShowViewDocModal(true);
      }
      if(item === "APPROVE") approveReject(row, "APPROVED");
      if (item === "REJECT") {
        setShowReasonModal(true);
        setSelectedRow(row);
        //  approveReject(row, "REJECTED");
      }
  
    };
  
    const approveReject = async (row, status) => {
      showLoader();
      let res;
      let res2;
      
      if (selectedfield === 'Directors') {
      res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/approveDirector/${row?.id}?status=${status}`
      );
      }else if (selectedfield === 'Shareholders') {
        res = await httpPut(
          `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/approveShareholder/${row?.id}?status=${status}`
        );
        } else {
        approveDoc(row, status);
        return;
      }


        if  (res?.status){
          hideLoader();
          setShowReasonModal(false);
          setReason('');
          Swal.fire('Done', res?.message, 'success')
          getKycStat();
          getUsers();
        }else{
          hideLoader();
          setShowReasonModal(false);
          setReason('');
          Swal.fire('error', res?.message)
          console.log(res.message);
        }
    }

    const approveDoc = async (row, status) => {

      const res2 = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/approveOtherDocs/${row?.id}/${row?.businessRequirementId}?reason=${reason}&status=${status}`
      );
      if  (res2.status){
        hideLoader();
        setShowReasonModal(false);
        approvalNoti(row, status, reason);
        setReason('');
        approveDirector(row, status);
        Swal.fire('Done', res2?.message, 'success')
        getKycStat();
        getUsers();
      }else{
        hideLoader();
        setShowReasonModal(false);
        setReason('');
        Swal.fire('error', res2?.message)
        console.log(res2.message);
      }
     };
    const approveDirector = async (row, status) => {
      if(!row?.director) return;
     const res = await httpPut(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/approveDirector/${row?.director?.id}?status=${status}`
      );
      if  (res.status){
        Swal.fire('Done', res?.message, 'success');
      }
    };

    const approvalNoti = async (item, status, reason) => {
      showLoader();
      const data = {
        attachment: 'string',
        data: {
          message: `Your ${item?.docType} document has been ${status}`,
          names: [
            {
              email: item?.createdBy,
              fullName: item?.businessName,
            },
          ],
        },
        eventCategory: 'WELCOME',
        eventType: 'EMAIL',
        htmlCode: `<p>${reason ? `Reason: ${reason}` : ''}</p>`,
        initiator: 'Admin',
        productType: 'WAYABANK',
        subject: `Document approval status`,
      };
      const res = await httpPost(`${customBaseUrl.notificationUrl}/email-notification`,
        data
      );
      if (res.status === true) {
        hideLoader();
      } else {
        hideLoader();
      }
    };

    const allColumns = [
    
      { field: 'id',
        headerName: 'Customer Id',
        width: 250 ,
        headerClassName: styles.tableHeader
  
      },
      { field: 'businessName',
        headerName: 'Business Name',
        width: 250 ,
        headerClassName: styles.tableHeader
  
      },
        
      /*{ field: 'customerPhoneNumber',
      headerName: "Phone Number",
      width: 200 ,
      headerClassName: styles.tableHeader,
  
      },
      { field: 'kycTier',
      headerName: "Tier Level",
      width: 150 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.kycTier?.name.replace('_',' ')
  
         
      },*/
      { field: 'docType',
      headerName: "Data Type",
      width: 250 ,
      headerClassName: styles.tableHeader,
      // renderCell: ({row})=> row?.kycStatus?.processed==='true'?'Completed':row?.kycStatus?.processed==='false'?'Under Review':'Not Completed'
  
         
      },
      { field: 'docUrl',
        headerName: "TIN",
        width: 150 ,
        headerClassName: styles.tableHeader,
        renderCell: ({row})=> row?.docType === 'TAX_DOCUMENT' ? row?.docUrl : 'N/A'
      },

      { field: 'status',
      headerName: "Status",
      width: 150 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row})=> row?.status.replace('_',' ')
         
      },
      { field: 'createdAt',
        headerName: "Created At",
        width: 250 ,
        headerClassName: styles.tableHeader,
        // renderCell: ({row})=> row?.stat
           
        },
        { field: 'modifiedAt',
          headerName: "Updated At",
          width: 250 ,
          headerClassName: styles.tableHeader,
             
          },
      
    
      {
        field: "ids",
        headerName: "Action ",
        width: 80,
        align: "right",
        headerClassName: styles.tableHeader,
        renderCell: ({ row }) => (
          <AntDropdown
            dropDownOptions={[ "VIEW DOCUMENT", "APPROVE", "REJECT"]}
            handleDropdownAction={(item) => handleDropdownAction(item, row)}
            userId={row}
            isArrow
          />
        ),
  
      },
       ];  
  
       const columns = [
    
        { field: 'id',
          headerName: 'Customer Id',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'businessName',
          headerName: 'Business Name',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
          { field: 'firstName',
          headerName: 'First Name',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'lastName',
          headerName: 'Last Name',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'phone',
          headerName: 'Phone Number',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'email',
          headerName: 'Email',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'dob',
          headerName: 'Date of Birth',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'ownershipCut',
          headerName: 'Ownership Cut',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        
        { field: 'lga',
          headerName: 'LGA',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
       
        { field: 'state',
          headerName: 'State',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'nationality',
        headerName: 'Nationality',
        width: 250 ,
        headerClassName: styles.tableHeader
  
        },
        
        { field: 'address',
          headerName: 'Address',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'nextOfKinFirstName',
          headerName: 'Next of Kin First Name',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'nextOfKinLastName',
          headerName: 'Next of Kin Last Name',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'nextOfKinPhone',
          headerName: 'Next of Kin Phone',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'nextOfKinEmail',
          headerName: 'next of Kin Email',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },
        { field: 'nextOfKinAddress',
          headerName: 'Next ofKin Address',
          width: 250 ,
          headerClassName: styles.tableHeader
    
        },       
        
        
        /*{ field: 'customerPhoneNumber',
        headerName: "Phone Number",
        width: 200 ,
        headerClassName: styles.tableHeader,
    
        },
        { field: 'kycTier',
        headerName: "Tier Level",
        width: 150 ,
        headerClassName: styles.tableHeader,
        renderCell: ({row})=> row?.kycTier?.name.replace('_',' ')
    
           
        },*/
       
        { field: 'status',
        headerName: "Status",
        width: 150 ,
        headerClassName: styles.tableHeader,
        renderCell: ({row})=> row?.status.replace('_',' ')
           
        },
        
      
        {
          field: "ids",
          headerName: "Action ",
          width: 80,
          align: "right",
          headerClassName: styles.tableHeader,
          renderCell: ({ row }) => (
            <AntDropdown
              dropDownOptions={["APPROVE", "REJECT"]}
              handleDropdownAction={(item) => handleDropdownAction(item, row)}
              userId={row}
              isArrow
            />
          ),
    
        },
         ];  
  
    const handleChangePage = async (newPage) => {
      setPagination({ ...pagination, current: newPage });
      // await getUsers(newPage, newPage, pagination.pageSize);
  
    };
    const handleChangeRowsPerPage = async (e) => {
      console.log(e)
      setPagination({ ...pagination, current: 1, pageSize: e });
    //await getUsers(e,1, e);
    };
    const handleOpenTierLevels = (Id) => {
      navigate(`/users_kyc/${Id}`);
    };
    const handleRequestSort = (event, property) => {
      const { order, orderBy } = orderData;
      const isAsc = orderBy === property && order === "asc";
      setOrderData(
        isAsc
          ? { order: "desc", orderBy: property }
          : { order: "asc", orderBy: property }
      );
    };
  
    const getPendingKYC = async () => {
      showLoader();
      const res = await httpGet(
        `${customBaseUrl.kycUrl}/kyc/count/totalPendingStatus`
      );
        if  (res.status){
          hideLoader();
          setPendingData(res.data);
        }else{
          hideLoader();
          console.log(res.message);
        }
     
  
      return [];
    };
  
    const getKycStat = async() =>  {
      let res = await httpGet(`${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/analysis`)
      if(res.status){
        setStat(res.data)
      }
    }
    const getTierStat = async() =>  {
      let res = await httpGet(`${customBaseUrl.kycUrl}/kyc/tier/count-users-by-tier`)
      if(res.status){
        setTierStat(res.data)
      }
    }
  
    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj
    }
  
    const getUsers = async (e,page, size) => {
      e?.preventDefault();
      console.log('page', page)
      console.log('size', size)
  
      showLoader();
      const pages = page === 0 ? 0 : page ? page : pagination.current;
      const limit = size ? size : pagination.pageSize;
      let response;
      if(selectedfield === 'Directors') {
        response = await httpGet(
          `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/fetchAllCacDirectors?field=${field??'all'}&pageNo=${pages + 1}&pageSize=${limit}&value=${value??'all'}`
        );
      } else if (selectedfield === 'Shareholders'){
        response = await httpGet(
          `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/fetchAllCacShareHolders?field=${field??'all'}&pageNo=${pages + 1}&pageSize=${limit}&value=${value??'all'}`
        );
      } else {
      response = await httpGet(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/cac/fetchAllCacDocuments?field=${field??'all'}&pageNo=${pages + 1}&pageSize=${limit}&value=${value??'all'}`
      );
      }
      if (response?.code === 200) {
        //console.log(request)
        hideLoader();
        setUsersData(response?.data?.content)
        setMetaData(response?.data?.totalElements);
  
        
      }else{
        console.log(response)
        hideLoader();
  
      }
      //const filter = clean(filteredData);
      /*if (response.status === true) {
        console.log('filter', filteredData);
        const filtered = response?.data?.content?.filter(e=>  {
          //  console.log(filteredData)
          return Object.keys(filter).every(key => {
            return filter[key] === e[key];
          });
            //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
          
        });
        setTransactions(filtered);
        //setFiltered(response.data.content)
        setMetaData(filtered?.length);
        //  console.log('filter', fil)
        
        hideLoader();
      } else {
        NotificationManager.error(response?.message)
        setTransactions([]);
    
        hideLoader();
      }
      */
  
    };
  
    /*const getKyc = async (e) => {
      e.preventDefault();
      showLoader();
      const response = await httpGet(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/customerKycData/all?field=status&pageNo=1&pageSize=5&value=APPROVED`
      );
      
      const filter = clean(filteredData);
  
      if (response.status === true) {
        console.log('filter', filteredData);
        const filtered = response?.data?.transaction?.filter(e=>  {
          //  console.log(filteredData)
          return Object.keys(filter).every(key => {
            return filter[key] === e[key];
          });
            //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
          
        });
        setTransactions(filtered);
        //setFiltered(response.data.content)
        setMetaData(filtered?.length);
        //  console.log('filter', fil)
        
        hideLoader();
      } else {
        NotificationManager.error(response?.message)
        setTransactions([]);
    
        hideLoader();
      }
    };*/
  
    useEffect(() => {
      getKycStat()
      getPendingKYC();
      getTierStat();
    }, []);
    useEffect(()=>{
      getUsers()
    },[pagination?.pageSize, pagination.current, selectedfield]);
  
    // Avoid a layout jump when reaching the last page with empty rows.
    
  const category = ['userId','status','name','email','phoneNumber']
  const sCategory = ['INITIATED', 'UNDER_REVIEW', 'PENDING', 'APPROVED', 'REJECTED']
  const fields = ['All CAC Documents','Shareholders','Directors']

  
    return (
      <>
  
        <Container>
  
          <div className={styles.searchRow}>
            {/*<select
              className={styles.selectType}
              defaultValue="disabled"
              onChange={(event) => {
                setSearch({ ...search, searchBy: event.target.value });
              }}
            >
              <option value="disabled" disabled>
                Search By...
              </option>
            
            </select>
            <div className={styles.searchRow}>
              <Input
                className={styles.searchInput}
                placeholder="Search data by Name, Email or Date"
                onChange={(event) => {
                  setSearch({ ...search, searchText: event.target.value });
                }}
                prefix={<SearchOutlined className={styles.searchIcon} />}
              />
            </div>*/}
           {/*} <div>
            <Button
                size="medium"
                style={{ background: "#27AE60", fontSize: "10px", color: "#fff" }}
                onClick={() => setShowExport(true)}
              >
                <ExportOutlined />
                Export Data
        </Button>
          </div>*/}
            {/*<div className={styles.grow} />
  
            <Button
            onClick={() => navigate("/kyc/kyc_settings")}
             className={styles.updateButton} size="middle">
              Update User Tier Level
            </Button>*/}
          </div>
          <div className={styles.dashboardItem}>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>Total Number of CAC KYC DATA</div>
              <div className={styles.dibot}>{stat?.totalKycDataCount}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>Total Number of CAC Documents</div>
              <div className={styles.dibot}>{stat?.totalDocumentsCount}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>KYC Pending for Approval</div>
              <div className={styles.dibot}>{stat?.totalUnderReviewKycDataCount}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>APPROVED DATA</div>
              <div className={styles.dibot}>{stat?.totalApprovedKycDataCount}</div>
            </div>
            <div className={styles.dashItem}>
              <div className={styles.diTop}>REJECTED DATA</div>
              <div className={styles.dibot}>{stat?.totalRejectKycDataCount}</div>
            </div>
          </div>
          <MatGrid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Paper elevation={4} style={{ padding: "12px", width:'17%' }}>
             
            <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 1 Business</span><span style={{float: 'right'}}><br/> {stat?.totalTier1BusinessCount}</span></div>
            </Paper>
            <Paper elevation={4} style={{ padding: "12px", width:'17%' }}>
            <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 2 Business</span><span style={{float: 'right'}}><br/> {stat?.totalTier2BusinessCount}</span></div>
            </Paper>
            <Paper elevation={4} style={{ padding: "12px", width:'17%' }}>
            <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 3 Business</span><span style={{float: 'right'}}><br/> {stat?.totalTier3BusinessCount}</span></div>
            </Paper>
            <Paper elevation={4} style={{ padding: "12px" , width:'17%'}}>
            <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 4 Business</span><span style={{float: 'right'}}> <br/>{stat?.totalTier4BusinessCount}</span></div>
            </Paper>
          
          </MatGrid>
  
          <div>
          <Button 
          variant="contained" 
          style={{width: '100%', marginBottom: 10}}
          onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</Button>
        </div>
        <div hidden={!searchField}>
          <form onSubmit={getUsers}>
          <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Field</span>
          <select class="form-select" id="inputGroupSelect01" required
          value={selectedfield}
            onChange={(e)=>{ 
              setSelectedField(e.target.value);
              setPagination({...pagination, current: 0})
            
            }
            }
          >
            <option selected>Choose...</option>
            {
              fields?.map((e,i)=>
              <option key={i} value={e}>{e.replace('_',' ').toUpperCase()}</option>
            )
            }
          </select>
        </div>
        <div class="row">
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Status</span>
          <select class="form-select" id="inputGroupSelect01" required
            onChange={(e)=>{ 
              setField(e.target.value);      
              setFilteredData({...filteredData, status: e?.target?.value})
              setPagination({...pagination, current: 0})
            
            }
            }
          >
            <option selected>Choose...</option>
            {
              category?.map((e,i)=>
              <option key={i} value={e}>{e.replace('_',' ').toUpperCase()}</option>
            )
            }
          </select>
        </div>
        {field === 'status'?<div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Value</span>
          <select class="form-select" id="inputGroupSelect01" 
            onChange={(e)=>{
              setValue(e.target.value)      
              setFilteredData({...filteredData, status: e?.target?.value})
              setPagination({...pagination, current: 0})
            
            }
            }
          >
            <option selected>Choose...</option>
            {
              sCategory?.map((e,i)=>
              <option key={i} value={e}>{e.replace('_',' ')}</option>
            )
            }
          </select>
        </div>:
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Value</span>
          <input  
          onChange={(e)=>setValue(e.target.value)}
          class="form-select" id="inputGroupSelect01" />
        </div>
          }
        {/*<div className="input-group mb-3 col">
            <span className="input-group-text">Transaction Type</span>
            <select class="form-select" id="inputGroupSelect02"
                onChange={(e)=>setFilteredData({...filteredData, partTranType: e?.target?.value})}
            >
            <option selected>Choose...</option>
            <option value={''}>ALL</option>
            <option value="D" >DEBIT</option>
            <option value="C">CREDIT</option>         
          </select>        
        </div>
         
          {/*<div class="col">
            <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
          </div>*/}
        </div>
        {/*<div className="row">
          
          <div class="input-group mb-3 col">
           <span class="input-group-text" id="basic-addon3">Reference Number</span>        
            <input type="text" aria-label="Ref" class="form-control"
              onChange={(e)=>setFilteredData({...filteredData, paymentReference: e?.target?.value})}
  
            />
          </div>
          <div class="input-group mb-3 col">
            <span class="input-group-text" id="basic-addon3">Transaction Id</span>        
            <input type="text" class="form-control"  aria-label="tranId"
              onChange={(e)=>setFilteredData({...filteredData, tranId: e?.target?.value})}
  
            />
          </div>
        </div>
        <div className="row">
        
          <div class="input-group mb-3 col">
           <span class="input-group-text" id="basic-addon3">Account Number</span>        
            <input type="text" aria-label="First name" class="form-control"
              onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
            />
          </div>
          <div class="input-group mb-3 col">
            <span class="input-group-text" id="basic-addon3">Email</span>        
            <input type="email" class="form-control"  aria-label="Email"
               onChange={(e)=>setFilteredData({...filteredData, createdEmail: e?.target?.value})}
            />
          </div>
        </div>
        <div className="row">
        
          <div class="input-group mb-3 col">
           <span class="input-group-text" id="basic-addon3">From</span>        
            <input type="date" aria-label="Date" class="form-control"
              onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
              required
            />
          </div>
          <div class="input-group mb-3 col">
            <span class="input-group-text" id="basic-addon3">To</span>        
            <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
               onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
            />
          </div>
        </div>*/}
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary"
          >Search</button>
        </div>
        </form>
        </div>
  
           
            
          <div style={{ height: 800, width: "100%" }}>
          {usersData?.length > 0 && <DataGrid 
           className={styles.table}
           density="comfortable"
          rows={usersData}
              columns={selectedfield !== 'All CAC Documents' ? columns : allColumns}
              page={pagination.current}
              pageSize={pagination.pageSize}
              onPageSizeChange={(newPageSize) =>
                handleChangeRowsPerPage(newPageSize)
              }
              paginationMode="server"
              onPageChange={(newPage) => handleChangePage(newPage)}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              pagination
              components={{ Toolbar: GridToolbar }}
              rowCount={metaData}       
          />}
    </div>
       
    {showViewDocModal ? (
          <ViewDocModal
            showModal={showViewDocModal}
            hideModal={setShowViewDocModal}
            data={viewData}
          />
        ) : (
          ""
        )}
        </Container>
        {showExport &&
           <ExportData showModal={showExport} hideModal={setShowExport} dataList={usersData} file={"KYC users tier "} />
        }
        {showReasonModal &&
          <Reason
            showModal={showReasonModal}
            hideModal={setShowReasonModal}
            handleSubmit={approveReject}
            selectedRow={selectedRow}
            reason={reason}
            setReason={setReason}
          />
        }
      </>
    );
  };
  export default KycCacPage;
  const gridStyle = {
    width: "23%",
    textAlign: "left",
    backgroundColor: "#fff",
    padding: "24px 10px 24px 12px",
    fontWeight: "bold",
    marginLeft: "10px",
  };
  
  const tool = {
    display: "flex",
    borderRadius: "250px",
    justifyContent: "space-between",
    marginBottom: "30px",
  };
  