import React from 'react';
import OtpInput from 'react-otp-input';
import Wrapper from './style';

const Summary = ({ displayData, handleClick, otp, setOtp }) => {
  const { receiverName, amount, senderName, tranFees, bank, description } =
    displayData;

  //  alert(tranFees)
  return (
    <Wrapper>
      <div className='title'>Summary</div>
      <div className='content'>
        <div className='ci-cont'>
          <div className='c-item'>
            <div className='c-title'>Sending to</div>
            <div className='c-det'>{receiverName || ''}</div>
          </div>
          <div className='c-item'>
            <div className='c-title'>Amount</div>
            <div className='c-det'>NGN {amount || 0}.00</div>
          </div>
        </div>
        <div className='ci-cont'>
          <div className='c-item'>
            <div className='c-title'>From</div>
            <div className='c-det'>{senderName || ''}</div>
          </div>
          <div className='c-item'>
            <div className='c-title'>Transaction Fees</div>
            <div className='c-det'>NGN {tranFees}.00</div>
          </div>
        </div>
        <div
          className='ci-cont'
          style={{
            marginBottom: '0px',
          }}
        >
          <div className='c-item'>
            <div className='c-title'>Beneficiary Bank</div>
            <div className='c-det'>{bank || 'WAYA Bank'}</div>
          </div>
          <div className='c-item'>
            <div className='c-title'>Description</div>
            <div className='c-det'>{description || ''}</div>
          </div>
        </div>
      </div>
      <div className='otp-info'>
        Please input your 6 digit pin to complete transaction
      </div>

      <OtpInput
        containerStyle='otp-cont'
        inputStyle='otp-input'
        value={otp}
        onChange={(value) => setOtp(value)}
        numInputs={6}
        // separator={<span> {`  -  `} </span>}
      />

      <div className='sum-bot'>
        <button onClick={() => handleClick()}>Confirm</button>
      </div>
    </Wrapper>
  );
};

export default Summary;
