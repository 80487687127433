import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import '../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import { ProfileContext } from '../../store/context/ProfileContext';
import ColoredSwitcher from '../../components/customSwitch/coloredSwitcher';
import { Modal } from 'antd';
import SearchDropdown from '../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../store/httpCalls';

const SelectUser = ({ showModal, hideModal,currentPage,setShowModal, className}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [counts, setCounts] = useState([]);
  const [showCreateNew, setShowCreateNew] = useState(false)
  const [newDayCount,setNewDayCount] =useState(false)
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('')
  const [selectedUser, setSelectedUser] = useState({})
  
  const handleSelectOption = (selectedUser) => {
    setSelectedUser(selectedUser);
    setSelectedUserId(selectedUser.userId);
    setQuery(selectedUser.firstName+' '+ selectedUser.surname)
    setOptions([]);
  };

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }

  const handleSendOtp = async() => {
        let response;
        showLoader();
      if(currentPage==='passEmail'){
        response = await httpGet(`${customBaseUrl.authUrl}/api/v1/password/change-password/byEmail?email=${selectedUser.email}`)
      }else if( currentPage === 'passPhone'  ){
        response = await httpGet(`${customBaseUrl.authUrl}/api/v1/password/change-password/byPhone?phoneNumber=${selectedUser.phoneNumber}`)
      }else if (currentPage === 'pinPhone'){

        response = await httpGet(`${customBaseUrl.authUrl}/api/v1/pin/change-pin/byPhone?phoneNumber=${selectedUser.phoneNumber}`)
      }else if (currentPage === 'pinEmail'){

        response = await httpGet(`${customBaseUrl.authUrl}/api/v1/pin/change-pin/byEmail?email=${selectedUser.email}`)
      }


      if (response.status){
        hideModal(false);
        setShowModal(false);
        hideLoader();
        Swal.fire('Done',response.message,'Done')
      }else{
        hideLoader();
        console.log(response);
        Swal.fire('Error', response.message, 'Failed')
      }   

  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}

    >
        <div>
          <h5>Send OTP</h5>
        </div>

        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom09' class='form-label'>
            Search/Select Receiver
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            value={query}
            required
            onChange={handleSearch}
          />
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>

        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleSendOtp}>
            Send Otp
          </button>
        </div>

                   
        
    </Modal>
  );
};

export default SelectUser;
