import React, { useState } from "react";
import Swal from "sweetalert2";
import { Input, Row, Form, Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import style, { LoginWrapper } from "./style";
import loginImage from "../../assets/images/login-1.png";
import loginLogo from  '../../assets/images/newlog.svg';
import color from "../../theme/color";
import { customBaseUrl, httpPost } from "../../store/http";
import { hideLoader, showLoader } from "../../utils/loader";
import OtpInput from "react-otp-input";
import PinModal from "../../components/Modal/Pin/PinModal";
const { Paragraph } = Typography;

export default function ApproverLogin() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState('');

///api/v1/auth/generate-login-otp/
const handleContinue = async() => {
  let idx = data?.emailOrPhoneNumber.indexOf('@wayapaychat')

  showLoader();
  const res = await httpPost(`${customBaseUrl.loanUrl}/api/v1/approver/approver-verify`, {
    emailAddress: data.emailOrPhoneNumber,
    password: data.password,
  });

  if (res.status) {
    hideLoader();
    // if (res.data?.roles[0] === "ROLE_USER" && res.data?.roles.length <= 2) {
    //   return Swal.fire("access denied");
    // }
    // if(!res.data?.user.isAdmin || idx<0){
    //   hideLoader();
    //   return Swal.fire("access denied");
    // }
    hideLoader();
    setLoading(false);
    // localStorage.setItem("token", res?.data?.token);
    // localStorage.setItem("userId", res?.data?.user.id);
    // localStorage.setItem("phoneNumber", res?.data?.user.phoneNumber);
    localStorage.setItem("email", data.emailOrPhoneNumber);
    localStorage.setItem("approverRole", res.data.role);
    // localStorage.setItem("roles", res?.data?.roles);
    //  localStorage.setItem("fullName", res?.data?.user?.firstName +' '+ res?.data?.user?.lastName);

    // Swal.fire('Done', res.message, 'success');
    window.location.href = "/loan";
  } else {
    hideLoader();
    setLoading(false);
    Swal.fire("Error", res.message, "error");
    // Check if user account is verified or not ----- this is the start of the admin verification process -------------------
    if (res.message === "Account not Verified") {
       setTimeout(() => {
        window.location.href = `/activate_account?emailOrPhone=${data.emailOrPhoneNumber}`;
        sessionStorage.setItem("activation_request_trials","6B86B273FF34FCE19D6B804EFF5A3F5747ADA4EAA22F1D49C01E52DDB7875B4B");
        
       }, 3000);
     
    }

   
  }

}

  const onFinish = async () => {
    setLoading(true);
    showLoader();
  
    const res = await httpPost(`${customBaseUrl.authUrl}/api/v1/auth/generate-login-otp?email=${
      data.emailOrPhoneNumber
    }`);

    if (res.status) {
      setShowOtp(true);
        hideLoader();      
      
      
    } else {
      hideLoader();
      setLoading(false);
      if(res?.message == " 2FA not active for this user"){
        handleContinue();
      }else{
      Swal.fire("Error", res.message, "error");
      }
    }

     
    };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <LoginWrapper className="h-100">
      <div style={style.login.container}>
        <div className="div-left" style={style.login.left}>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ minHeight: "100vh" }}
          >
            <img
              src={loginLogo}
              alt="login-logo"
              style={style.login.left.logo}
            />
            <img src={loginImage} alt="login" style={style.login.left.image} />
          </Row>
        </div>
        <div className="div-right" style={style.login.right}>
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ minHeight: "100vh" }}
          >
            <div style={style.login.formContainer}>
              <h1
                style={{
                  textAlign: "center",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "53px",
                }}
              >
                Sign in
              </h1>

              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={handleContinue}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item label="Email address" name="username">
                  <Input
                    size="large"
                    style={style.login.input}
                    className="input-focus"
                    onChange={(e) =>
                      setData({ ...data, emailOrPhoneNumber: e.target.value })
                    }
                  />
                </Form.Item>

                <Form.Item label="Password" name="password">
                  <Input.Password
                    size="large"
                    style={style.login.input}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                </Form.Item>

                  {/* <a href = "/forgot_password" style={{color: color.primary}}>Forgot password?</a> */}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    loading={loading}
                    style={style.login.button}
                  >
                    Sign in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
        </div>
      </div>
      {
        showOtp && (<PinModal
          showModal={showOtp}
          hideModal = {setShowOtp}
          otp={otp}
          setOtp={setOtp}
          title="2FA"
          description="Please enter your OTP to continue"
          buttonLabel="Unlock"
          handleSubmit={handleContinue}
          isIdle={false}
          numInputs={6}
          pinMode={true}
          />)
      }
    </LoginWrapper>
  );
}
