import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import './index.scss';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import {searchUsers} from '../../../store/httpCalls';
import { ProfileContext } from '../../../store/context/ProfileContext';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';

const SendSingleUserModal = ({ showModal, hideModal, className, categories,quickTeller, wallets}) => {
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [walletList, setWalletList] = useState([]);
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('')
  const { profile } = useContext(ProfileContext);
  const [view, setView] = useState('default');
  const [billerList,setBillerList] = useState([])
  const [billerInfo,setBillerInfo] =useState([])
  const [billerItems, setBillerItems] = useState([]);
  const [plan, setPlan] = useState('')
  const [amount, setAmount] = useState('');
  const [accountNo, setAccountNo] = useState('');
const [phone, setPhone] = useState('');
const [selectedWallet, setSelectedWallet] = useState('');
const [title, setTitle] = useState('');
const [walletType, setWalletType] = useState('');
const [userWallets, setUserWallets] = useState([]);
const [selectedUserId, setSelectedUserId] = useState('');
const [customerId, setCustomerId] = useState('')

  const handleSelectOption = (selectedUser) => {
    getUserWallets((selectedUser.userId));
    setSelectedUserId(selectedUser.userId);
    setData({ ...data, actRefId: selectedUser.userId,email:selectedUser?.email, fullName: `${selectedUser.firstName} ${selectedUser.surname}`});
    setQuery(`${selectedUser.firstName} ${selectedUser.surname}`);
    
   /* setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);*/
    setOptions([]);
  };


  
  const getUserWallets = async (userId) => {
    //  return alert(userId);
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}`
      );
     // alert(JSON.stringify(res));
      if (res?.status) {
        setUserWallets(res.data);
      }
    };
  
  const getBillersByCategories = async () => {
    showLoader();
    let res;
    /*if(quickTeller){
      res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/getBillersByCategories/${view}`)
    }else{*/
    res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${view=='default'?8:view}`)
   // }
    if (res?.status) {
      hideLoader();
      setBillerList(res?.data);
      console.log(res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };

  const getBillerItems = async () => {
    showLoader();   
     let res = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/category/${billerInfo.categoryId}/biller/${billerInfo.billerId}`)
     if (res?.status) {
      hideLoader();
      setBillerItems(res?.data?.items);
      console.log('biller materials',res?.data)
    }else{
      console.log(res)
      hideLoader();
    }
  };


  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const d = new Date();
    let time = d.getTime();

   const data1 = {
    "amount": amount,
    "billerId": billerInfo.billerId,
    "categoryId": billerInfo.categoryId,
    "data": [
      {
        "name": "phone",
        "value": phone
      },
      {
        "name": "amount",
        "value": amount
      },
      {
        "name": "total_amount",
        "value": amount
      },
      {
        "name": "account_number",
        "value": accountNo
      },
      {
        "name": "smartcard_number",
        "value": accountNo
      },
      {
        "name": "plan",
        "value": plan
      },
      {
        "name": "pinValue",
        "value": plan
      },
      {
        "name": "bundles",
        "value": plan
      },
      {
        "name": "account_number",
        "value": accountNo
      },
      {
        "name": "numberOfPins",
        "value": accountNo
      }
    ],
    "sourceWalletAccountNumber": selectedWallet
  }

  let quickData = {
    "amount": amount,
    "billerId": billerInfo.billerId,
    "categoryId": billerInfo.categoryId,
      "data": [
        {
          "name": "customerMobile",
          "value": phone
        },
        {
          "name": "amount",
          "value": amount
        },
        {
          "name": "customerEmail",
          "value": data?.email
        },
        {
          "name": "customerId",
          "value": customerId
        },
        {
          "name": "paymentCode",
          "value": plan
        }
      
      ],
      "sourceWalletAccountNumber": selectedWallet,
      userId: selectedUserId
    }

//return alert(JSON.stringify(data))
    showLoader();
   /* 
     let res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/wallet/non-waya/payment/new`,
        transferData
      );*/
    
      let res;

      /*if(quickTeller){
        res = await httpPost(
          `${customBaseUrl.billPaymentUrl}/api/v1/admin/make-payment-for-user?userId=${data?.actRefId}
          `,
          quickData
        );
     
      }else{

            
      res = await httpPost(
        `${customBaseUrl.billPaymentUrl}/api/v1/admin/make-payment-for-user?userId=${data?.actRefId}
        `,
        data1
      );
      }*/
       res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify(quickTeller?quickData:data1),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "BILLSPAYMENT",
        customerName: query
      } )
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      console.log(res)
      setData({})
      hideModal(false);
    } else {
      hideLoader();
      console.log(res)

      Swal.fire('Error', res.message, 'error');
    }
  };

  


  useEffect(()=>{
    getBillersByCategories();
    getBillerItems();
    setBillerItems([]);
    setAmount('');
    setAccountNo('');
  },[view, billerInfo])
  
/*
  const getVirtualAccounts = async () => {
    const res = await httpGet(
      `${customBaseUrl.accountCreationUrl}/account/getAccounts/${userId}`
    );
    if (res?.status) {
      setVirtualAccount(res.data);
    }
  };

  const getBankAccountList = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/admin/list/userBankAcct/${userId}`
    );
    if (res?.status) {
      setBankAccountList(res.data);
    }
  };
*/

const  accountType = { 
  'betting': 'betting',
  'electricity': 'Meter no',
  'cabletv': 'Smartcard no',
  'airtime': 'Phone no',
  vehiclepaper: 'Vehicle paper',
  insurance: "General Insurance"

                      }


  const  getHandle = async (e)=>{
    return alert(e)
  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}
      maskClosable={true}
    >
      
      <form class='row g-3 needs-validation' novalidate>
        <div class='col-12 text-center font-weight-bold'>

        <p class="font-weight-bold text-lg"><h5> {view==='default'?'Select Bills Payment Type': title}</h5> </p>
        {view==='default'?
        <div>
            {categories?.map((item,index)=>
            <div className='row center' >
               <button  class='btn border col-12 text-center font-weight-bold m-2 center '  type='submit' onClick={(e)=>{ setView(item.categoryId); setTitle(item.categoryName); setBillerItems(); setBillerInfo() }}>
               {item.categoryName}
             </button>
             </div>
            )}
          </div>:""}

        </div>
        {view !== 'default' ?<div >
        
        <div class='contentBar-items m-2'>
          <select 
            class='form-select border'
            id='validationCustom06'
            required
          value={JSON.stringify(billerInfo)} onChange={(e)=>{setBillerInfo(JSON.parse(e.target.value))}}>
          <option value="">Service Provider</option>
          {billerList.map((biller, index) => (
            <option key={index} value={JSON.stringify(biller)}>
              {biller?.billerName}
            </option>
          ))}
        </select>
        </div>
        <div className="contentBar-items m-2">
          {billerItems?.map((item, index) => (
            <div key={index} className="contentBar-item">
              {item.subItems.length>0 && 
                (<div key={index} className="contentBar-item-subItem">
                  <div className="contentBar-item-subItem-name">{item.paramName}</div>
                  <select
                   class='form-select border'
                   id='validationCustom06'
                  onChange={(e)=>{setPlan(e.target.value)}}>
                    <option value="">Select</option>
                    {item.subItems.map((subItem, index) => (
                    <option value={subItem.id}>{`${subItem.name}  - ${subItem?.amount?'N':''}${subItem?.amount?subItem?.amount:''}`}</option>
                    ))}
                  </select>
                  </div>)} 
              </div>))}
        </div>
      
      
        
        <div class='contentBar-items m-2'>
        <label for='validationCustom07' class='form-label'>
            Phone
          </label>
        <input 
         class='form-control'
        type="text"  value={phone}  onChange={(e)=>setPhone(e.target.value)}/>
        </div>
        {
          quickTeller?<div>
             <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
          Customer Id 
          </label>
        <input
        class='form-control'
        type="text"  value={customerId}  onChange={(e)=>setCustomerId(e.target.value)}/>
       </div>

          </div>:
        <div>
        {view!=='airtime' && view !== 'databundle' && 
        
        <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
        {accountType[view]}
        </label>
        <input
        class='form-control'
        type="text"  value={accountNo}  onChange={(e)=>setAccountNo(e.target.value)}/>
       </div>}
       </div>}
       <div class='col-md-12 m-2'>
        <label for='validationCustom07' class='form-label'>
          Amount
          </label>
        <input
        class='form-control'
        type="text"  value={amount}  onChange={(e)=>setAmount(e.target.value)}/>
       </div>
        {/* <div class='contentBar-items m-2'>
          <label for='validationCustom07' class='form-label'>
            Select Wallet Type
          </label>
          <select
            class='form-select border'
            id='validationCustom07'
            required
            onChange={(e)=>{setWalletType(e.target.value);setSelectedWallet('')}}
            value={walletType}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            <option selected  value='userwallet'>
              User wallet
            </option>
            <option selected  value='officialwallet'>
              Official Wallet
            </option>
          </select>
            </div>*/}
       
         <div class='contentBar-items m-2'>
        <label for='validationCustom09' class='form-label'>
            Select Wallet for transaction
          </label>
          <div style={{ position: 'relative' }} class="col-md-6">
          <label for='validationCustom09' class='form-label'>
            Sender's Name
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            value={query}
            required
            onChange={handleSearch}
          />
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>
        
          <label for='validationCustom07' class='form-label'>
            Select Wallet for transaction
          </label>
          <select
            class='form-select border'
            id='validationCustom07'
            required
            onChange={(e)=>setSelectedWallet(e.target.value)}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { userWallets.length>0 &&
            userWallets?.map((option) => (
              <option key={option.accountNo} value={option?.accountNo}>
                {option?.acct_name +' - '+option?.accountNo}
              </option>
            ))}
          </select>
        </div>

       {/*walletType==='officialwallet'&& <div class='contentBar-items m-2'>
          <label for='validationCustom07' class='form-label'>
            Select Wallet for transaction
          </label>
          <select
            class='form-select border'
            id='validationCustom07'
            required
            onChange={(e)=>setSelectedWallet(e.target.value)}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { wallets.length>0 &&
            wallets?.map((option) => (
              <option key={option.accountNo} value={option?.accountNo}>
                {option?.acct_name +' - '+option?.accountNo}
              </option>
            ))}
          </select>
        </div>*/}
       

        <div class='col-12 text-end mt-5 m-2'>
        <button class='btn border m-2'  onClick={()=>{setView('default')}}>
            Back  
        </button>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Proceed
          </button>
        </div>
        </div>: ''}
      </form>
    </Modal>
  );
};

export default SendSingleUserModal;
