import React from "react";
import styled from "styled-components";
import { Input, Button, Switch } from "antd";
import useStyles from "./styles";
import UserIcon from "../../../assets/icons/userOrange.svg";
import Check from "../../../assets/icons/check.svg";
import NotCheck from "../../../assets/icons/notCheck.svg";
import Layout from "../../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import styles from "./styles";
import { hideLoader, showLoader } from "../../../utils/loader";
import { customBaseUrl, httpGet, httpPut, httpPost } from "../../../store/http";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Swal from "sweetalert2";
import moment from "moment";
const EditWRapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Profile = () => {
  const { id: id } = useParams();
  const navigate = useNavigate();
  const styles = useStyles();
  const [user, setUser] = useState({});
  const [storedUser, setStoredUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [userType, setUserType] = useState(false);
  const [email, setEmail] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [gender, setGender] = useState("");
  const [smsAlertConfig, setSmsAlertConfig] = useState(false);
  const [userId, setUserId] = useState("");
  const [virtualAccountNumber, setVirtualAccountNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [status, setStatus] = useState("");
  const [userModel, setUserModel] = useState({});
  const [bvnData, setBvnData] = useState([]);
  const [limitData, setLimitData] = useState([]);
  const [count, setCount] = useState(0);
  const [volume, setVolume] = useState(0);
  const [kyc, setKyc] = useState([])
  const [tranStatus, setTranStatus] = useState([])
  const [ userData, setUserData] = useState({});
  const [businessType, setBusinessTypes] = useState([]);
  useEffect(() => {
    getBvn();
    getTransStatus();
    getUser();
    getLimit();
    getKYC();
    getBusinessTypes();
  }, []);
  const getBusinessTypes = async () =>{
    let response = await httpGet(`${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=50`);
    // console.log(response);
    setBusinessTypes(response.businessTypeList);
  }
  const getUser = async () => {
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/profile/${id}`
    );
    hideLoader();
    // const storedUser = JSON.parse(localStorage.getItem("user_profile"));


    if (response.status !== true) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });

    }
    else {
      const user = response.data;
      console.log('user',user);
      setUserData({
        ...userData,
        address: response?.data?.address,
  businessType: response?.data?.otherDetails?.businessType,
  city: response?.data?.city,
  dateOfBirth: response?.data?.dateOfBirth,
  email: response?.data?.email,
  firstName: response?.data?.firstName,
  gender: response?.data?.gender,
  middleName: response?.data?.middleName,
  officeAddress: response?.data?.otherDetails?.organizationAddress,
  organisationEmail: response?.data?.otherDetails?.organisationEmail,
  organisationName: response?.data?.otherDetails?.organisationName,
  organisationPhone: response?.data?.otherDetails?.organisationPhone,
  organisationType: response?.data?.otherDetails?.organisationType,
  organizationCity: response?.data?.otherDetails?.organizationCity,
  organizationState: response?.data?.otherDetails?.organizationState,
  phoneNumber: response?.data?.phoneNumber,
  profileId: response?.data?.id,
  state: response?.data?.otherDetails?.organizationState,
  surname: response?.data?.surname
      })
      setUser(user);
      setStoredUser(storedUser);
      setFirstName(user.firstName);
      setLastName(user.surname);
      setDateOfBirth(new Date(user.dateOfBirth));
      setUserType(user.corporate);
      setEmail(user.email);
      setReferenceCode(user.referenceCode);
      setGender(user.gender);
      setPhoneNumber(user.phoneNumber);
      setSmsAlertConfig(user.smsAlertConfig);
      setUserId(user.userId);
      setVirtualAccountNumber("32342424242");
      setStatus(storedUser.active);

      setUserModel({
        firstName: user.firstName,
        surname: user.surname,
        dateOfBirth: user.dateOfBirth,
        email: user.email,
        gender: user.gender,
        phoneNumber: user.phoneNumber,
        smsAlertConfig: user.smsAlertConfig,
      });


    }

  };

  const getBvn = async () => {
    //showLoader();
      const response = await httpPost(
        `${customBaseUrl.kycUrl}/api/v1/admin/kyc/requirementsData/${id}`
      );
    hideLoader();

    if (response.status) {
    setBvnData(response?.data)
    console.log('bvnData', response?.data?.tier2[0]);
    }
    else {
     console.log(response)
    }
  };
  const getLimit = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/user/setup?id=${id}`
    );
    hideLoader();

    if (response.status === true) {
    setVolume(response?.data?.transactionLimit)
    }
    else {
     console.log(response)
    }
  };

  const getKYC = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/fetchCurrentLevel/${id}`
    );
    hideLoader();

    if (response.status === true) {
    setKyc(response?.data)
    }
    else {
     console.log(response)
    }
  };
  const getTransStatus = async () => {
    //showLoader();
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/trans-type/status/${id}`
    );
    hideLoader();

    if (response.status === true) {
    setTranStatus(response?.data)
    }
    else {
     console.log(response)
    }
  };
  const handleSmsAlertConfigChange = async (event) => {
    //setUserModel({ ...userModel, smsAlertConfig: event })
    let response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/users/subscribe-sms-alert`,
      {
        phoneNumber: user.phoneNumber
      }
    );
    if (response.status === true) {
      setSmsAlertConfig(event);
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };
  const configTran = async (event) => {
    //setUserModel({ ...userModel, smsAlertConfig: event })
    let response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/toggle/${id}?type=${event}`,
      {
        phoneNumber: user.phoneNumber
      }
    );
    if (response.status === true) {
      hideLoader();      
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
      getTransStatus();
    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };
  const handleSubmit =()=>{
    updateLimit();
    handleUpdateProfile();
  }
  const updateLimit = async () => {
  let res =  await httpPost(`${customBaseUrl.kycUrl}/kyc/admin/upgradeTransLimit/${id}?transactionLimit=${volume}`,{});
    if (res?.status) {
      console.log('success', res)
    }else{
      console.log('failed', res)
    }
  }
  const handleUpdateProfile = async () => {
    //return console.log('users', userData);
   showLoader();
    let isUserCorporate = userType === "Corporate" ? true : false;
    if (userType ) {
   /*   let response = await httpPut(
        `${customBaseUrl.authUrl}/api/v1/profile/update-corporate-profile/${id}`,
        userModel
      );*/
    let d= new Date()
    //let res =  await httpPost(`${customBaseUrl.kycUrl}/kyc/admin/upgradeTransLimit/${id}?transactionLimit=${volume}`,{});

    let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({...userData, userId:id}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "UPDATECORPORATEUSER",
        customerName: userData.firstName +' '+ userData.surname
      } )
      if (response.status === true) {
        hideLoader();
        updateLimit();
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        hideLoader();
        Swal.fire({
          title: "Error",
          text: response.message,
          icon: "error",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    } else {
     /* let response = await httpPut(
        `${customBaseUrl.authUrl}/api/v1/profile/update-personal-profile/${id}`,
        userModel
      );*/
      let d= new Date()
      let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({...userData, userId:id}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "UPDATEPERSONALUSER",
        customerName: userData.firstName +' '+ userData.surname
      } )
      if (response.status === true) {
        hideLoader();
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        hideLoader();
        Swal.fire({
          title: "Error",
          text: "Profile not updated",
          icon: "error",
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      }
    }
  };
  return (
    <Layout>
      <div style={{ cursor: "pointer" }} onClick={() => navigate("/users")}>
        <ArrowLeftOutlined
          style={{
            fontSize: "20px",
            fontWeight: "600",
            marginRight: "4px",
            marginTop: "10px",
          }}
        />
        <span className="me-1">back</span>
      </div>
      <EditWRapper>
        <div className={styles.topDiv}>
          <div className={styles.title}>User Profile</div>
          <div className={styles.titleDet}>
            <div className={styles.tDL}>
              <img src={user?.profileImage??UserIcon} alt="user" className='img-fluid rounded-circle' width={90} height={50}/>
              <div style={{margin: 10}}>
                <div className={styles.tDLTextTop}>
                  {userData?.surname?.toUpperCase()} {userData?.firstName?.toUpperCase()}
                </div>
               {//} <div className={styles.tDLTextBot}>Super Admin</div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomDiv}>
          <div className={styles.inputWrap}>
            <div >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div >
                  <DesktopDatePicker
                    label="Date of Birth"
                    inputFormat="MM/dd/yyyy"
                    value={userData.dateOfBirth}
                    onChange={(value) =>{
                      console.log('DOB', moment(value).format('YYYY-MM-DD'));
                      setUserData({ ...userData, dateOfBirth: moment(value).format('YYYY-MM-DD') })
                    }}
                    renderInput={(params) => (
                      <TextField className={styles.datePicker} {...params} />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Usertype</div>
              <Input
                disabled
                value={userType == true ? "Corporate" : "Individual"}
                onChange={(event) => setUserType(event.target.value)}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Status</div>
              <Input value={status == true ? "Active" : "Inactive"} disabled className={styles.inputInput} />
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>First Name</div>
              <Input
                value={userData.firstName}
                onChange={(e) => setUserData({...userData, firstName: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Last Name</div>
              <Input
                value={userData.surname}
                onChange={(e) => setUserData({...userData, surname: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Email Address</div>
              <Input
                value={userData.email}
                onChange={(e) => setUserData({...userData, email: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Phone Number</div>
              <Input
                value={userData.phoneNumber}
                onChange={(e) => setUserData({...userData, phoneNumber: e?.target?.value})}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Reference Code</div>
              <Input
                value={referenceCode}
                disabled
                onChange={(evt) => setReferenceCode(evt.target.value)}
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              {/* <div className={styles.inputLabel}>Following</div> */}
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> SMS Alert</span>
                <Switch
                  style={{ float: "right" }}
                  checked={smsAlertConfig}
                  onChange={handleSmsAlertConfigChange}
                />
              </div>

              {/* <Input value="233" className={styles.inputInput} /> */}
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Gender</div>
              <Input
                disabled
                value={userData.gender}
                onChange={(evt) =>
                  setUser({ ...userData, gender: evt.target.value })
                }
                className={styles.inputInput}
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>User ID</div>
              <Input value={userId} disabled className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Current Tier Level</div>
              <Input value={kyc?.kycTier?.orderLevel??'Not set'} disabled className={styles.inputInput} />
            </div>
            </div>
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Transaction Processing Status</div>
              <Input  disabled className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Account Block Status</div>
              <Input  disabled className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Maximum Daily Transaction Limit</div>
              <Input value={volume} onChange={(e)=>{
                setVolume(e.target.value)}}  className={styles.inputInput} />
            </div>
            </div>
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Maximum Daily Transaction Count</div>
              <Input value={count} onChange={(e)=>{
                setCount(e.target.value)}}  className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>BVN</div>
              <Input value={/*`${/* bvnData?.tier2[0] ? bvnData?.tier2[0]?.verificationReference : ''}
                - ${bvnData?.tier2[0] ? bvnData?.tier2[0]?.status : ''}`*/''} disabled className={styles.inputInput} />
                
            </div>
            {/* <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Virtual Account Number</div>
              <Input
              -${bvnData?.teir2[0]?.status}
                value={virtualAccountNumber}
                disabled
                className={styles.inputInput}
              />
            </div> */}
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Maximum Transfer Limit</div>
              <Input disabled  value={kyc?.kycTier?.dailyTransactionLimit??''} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>No. Transaction Allowed</div>
              <Input disabled value={kyc?.kycTier?.dailyTransactionLimitCount??''} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Single Traansfer Limit</div>
              <Input disabled value={kyc?.kycTier?.singleTransactionLimit??''} className={styles.inputInput} />
            </div>
            </div>


            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> Bills Payment</span>
                <Switch
                  style={{ float: "right" }}
                  checked={tranStatus?.billsPayment}
                  onChange={()=>configTran('Bills')}
                />
              </div>
            </div>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> Withdrawal</span>
                <Switch
                  style={{ float: "right" }}
                  checked={tranStatus?.withdrawal}
                  onChange={()=>configTran('Withdrawal')}
                />
              </div>
            </div>
            <div className={styles.inputCont}>
              <div
                className={styles.inputInput}
                style={{ height: "42px", marginTop: "22px" }}
              >
                <span> Deposit</span>
                <Switch
                  style={{ float: "right" }}
                  checked={tranStatus?.deposit}
                  onChange={()=>configTran('Deposit')}
                />
              </div>
            </div>
          </div>
             {user?.otherDetails && <>
              <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Name</div>
              <Input 
              onChange={(e) => setUserData({...userData, organisationName: e?.target?.value})}
              value={userData?.organisationName} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Email</div>
              <Input  
              onChange={(e) => setUserData({...userData, organisationEmail: e?.target?.value})}
              value={userData?.organisationEmail} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Phone</div>
              <Input 
              onChange={(e) => setUserData({...userData, organisationPhone: e?.target?.value})}
              value={userData?.organisationPhone}   className={styles.inputInput} />
            </div>
            </div>
            
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization City</div>
              <Input
                onChange={(e) => setUserData({...userData, organizationCity: e?.target?.value})}
                value={userData?.organizationCity} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization Address</div>
              <Input  
                onChange={(e) => setUserData({...userData, officeAddress: e?.target?.value})}
                value={userData?.officeAddress} className={styles.inputInput} />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Organization State</div>
              <Input 
                onChange={(e) => setUserData({...userData, organizationState: e?.target?.value})}
                value={userData?.organizationState}   className={styles.inputInput} />
            </div>
            </div>
            <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Business Type</div>
              <select 
                className={styles.inputInput}
                value={userData?.organisationType} onChange={(e) => setUserData({...userData, organisationType: e?.target?.value})}>
                {
                 businessType?.length > 0 && businessType?.map(e=>
                    <option value={e?.businessType}>{e?.businessType}</option>
                    )
                }
              </select>
            </div>
            
            </div>
            </>}
            
          {/* <div className={styles.inputWrap}>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Bank Name</div>
              <Input value={bankName} disabled className={styles.inputInput} />
            </div>
          </div> */}
          <Button onClick={handleSubmit} className={styles.updateButton}>
            Update User
          </Button>
        </div>
      </EditWRapper>
    </Layout>
  );
};

export default Profile;
