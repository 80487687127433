import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import ColoredSwitcher from '../../../components/customSwitch/coloredSwitcher';
import { Modal } from 'antd';
import CreateNewCount from './updateDays';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../../store/httpCalls';

const AssignCode = ({ showModal, hideModal, className,  getTemporalReversalDays}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [counts, setCounts] = useState([]);
  const [showCreateNew, setShowCreateNew] = useState(false)
  const [newDayCount,setNewDayCount] =useState(false)
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('')
 
  
  const handleSelectOption = (selectedUser) => {
    setSelectedUserId(selectedUser.userId);
    setQuery(selectedUser.firstName+' '+ selectedUser.surname)
    setOptions([]);
  };

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }

  const handleAssign = async() => {
    if (!selectedUserId) return Swal.fire('Error', 'Select User', 'User Not Selected')
    showLoader();
    let res = await httpPost(`${customBaseUrl.referralUrl}/api/v1/admin/users/assign-or-replace-referral-code`,
    {
      userId: selectedUserId
    })


      if(res.status){
        hideLoader();
        Swal.fire("Done", res.message, "Done")
      hideModal(false)
      }else{
        hideLoader()
        console.log(res)
        Swal.fire('Failed', res.message, 'Error')
      }

  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}

    >
        <div>
          <h5>Assign Referral Code</h5>
        </div>

        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom09' class='form-label'>
            User
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            value={query}
            required
            onChange={handleSearch}
          />
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>

        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleAssign}>
            Save changes
          </button>
        </div>

                   
        
    </Modal>
  );
};

export default AssignCode;
