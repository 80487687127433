import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import '../../../index.scss';
import { customBaseUrl, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { Modal } from 'antd';


const RefTypeModal = ({ showModal, hideModal, className, getReferralTypes}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);


  const handleCreateNew = async() =>{
        showLoader();

        let res= await httpPost(`${customBaseUrl.referralUrl}/api/v1/referral_type/create`,
        data
      )

        if(res.status){
            hideModal(false)
            hideLoader()
            getReferralTypes()
            Swal.fire('Success', res.message,'Done')
        }else{
            console.log('err',res)
            hideLoader();
            Swal.fire('failed', res.message,'Error')

        }
    

  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}
    
    >
          <div class='col-md-12 mt-4' style={{display:'flex',flexDirection:'column', justifyContent:'start', alignItems:'start', alignContent:'start'}}>
          <label for='validationCustom05' class='form-label'>
            Select Business Type
          </label>
          <select
            class='form-select border'
            id='validationCustom05'
            value={data?.accountTypeEnum}
            required
            onChange={(e) => setData({ ...data, accountTypeEnum: e.target.value })}
          >
            <option>--Select account type</option>
            <option value="PERSONAL">Personal</option>
            <option value="CORPORATE">Corporate</option>

          </select>
        </div>
        <div class='col-md-12 mt-4' style={{display:'flex',flexDirection:'column', justifyContent:'start', alignItems:'start', alignContent:'start'}}>
          <label for='validationCustom05' class='form-label'>
            Is Default
          </label>
          <select
            class='form-select border'
            id='validationCustom05'
            value={data?.defaultType}
            required
            onChange={(e) => setData({ ...data, defaultType: e.target.value ==='true' ? true : false })}
          >
            <option>--Select</option>
            <option value='true'>TRUE</option>
            <option value='false'>FALSE</option>

          </select>
        </div>
        <div class='col-md-12 mt-4' style={{display:'flex',flexDirection:'column', justifyContent:'start', alignItems:'start', alignContent:'start'}}>
          <label for='validationCustom05' class='form-label'>
            Transaction Count Required?
          </label>
          <select
            class='form-select border'
            id='validationCustom05'
            value={data?.numberOfTransactionRequired}
            required
            onChange={(e) => setData({ ...data, numberOfTransactionRequired: e.target.value ==='true' ? true : false, numberCount: 0 })}
          >
            <option>--Select</option>
            <option value='true'>TRUE</option>
            <option value='false'>FALSE</option>

          </select>
        </div>
          <div class='col-md-12 mt-4' style={{display:'flex',flexDirection:'column', justifyContent:'start', alignItems:'start', alignContent:'start'}}>
          <label for='validationCustom05' class='form-label'>
            Number of Transfer Count
          </label>
          <input
            type='Number'
            class='form-control'
            id='validationCustom05'
            disabled={!data?.numberOfTransactionRequired}
            value={data?.numberCount}
            
            onChange={(e) => setData({...data, numberCount: e.target.value})}
          />
        </div>
        <div class='col-md-12 mt-4' style={{display:'flex',flexDirection:'column', justifyContent:'start', alignItems:'start', alignContent:'start'}}>
          <label for='validationCustom05' class='form-label'>
            Referral Discription
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom05'
            value={data?.referralDescription}
            required
            onChange={(e) => setData({...data, referralDescription:e.target.value})}
          />
        </div>
        <div class='col-12 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleCreateNew} >
            Create
          </button>
        </div>
    </Modal>
  );
};

export default RefTypeModal;
