import React, { useEffect } from "react"
import { Box } from "@mui/material";

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useState } from "react";
import { AutoComplete, InputNumber, Modal, Button, Popover } from 'antd';

import AddRequirement from "./AddRequirement";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Swal from "sweetalert2";
import { customBaseUrl, httpPost, httpDelete, httpGet } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));





const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const theme = createTheme({
    palette: {
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
});



const EditCooperateRequirement = ({ item, tranData, setTranData, setVisible, selectedRecord,getCooperateRequirement }) => {

    const [options, setOptions] = useState([]);
    const [addRequest, setAddRequest] = useState('');
    const [requirements, setRequirements]=useState([])
    const [chipData, setChipData] = useState([]);

    const [open, setOpen] = useState(false);
    const [trans, setTrans] = useState({});
    const hide = () => {
        setOpen(false);
    };

    const handleVisibleChange = (newVisible) => {
        setOpen(newVisible);
    };



    const onSearch = (searchText) => {
        setOptions(
            !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
        );
    };



    const handleUpdateTier = async () => {
        showLoader()
        let res = await httpPost(
            `${customBaseUrl.kycUrl}/kyc/manage-tier/updateLimits`,
            tranData
        );

        if (res.status === true) {
            getCooperateRequirement();
            setTranData({...tranData,tier:'', singleTransferLimit:'', maxNumberOfTxnAllowed: '', maxAmountPerTxn: '', maximumLimit:''});
            hideLoader()
            Swal.fire({
                icon: 'success',
                title: 'Requirement Updated',
                showConfirmButton: false
            })
            setVisible(false)


        } else {
            setVisible(false)
            hideLoader();
            Swal.fire("Oops!", res.message, "error");
        }

    }

    const handleDelete = async (id) =>  {
            showLoader()
        const res = await httpDelete(`${customBaseUrl.kycUrl}/kyc/removeFromTierList/${id}`);
        if (res.status) {
            getRequirement();
            Swal.fire('Done',res.message, 'Deleted')
            hideLoader();

        }else{
            hideLoader();
            console.log(res);
            Swal.fire('Error',res.message,'failed')
        }

    };


    const deleteRequirement = (data) => {
        Swal.fire({
          title: "Are you sure?",
          text: `You want to remove ${data.items} from requirements?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove it!",
        }).then((result) => {
          if (result.value) {
            handleDelete(data.id);
          }
        });
      };


    const getRequirement = async()=>{
        let res = await httpGet(`${customBaseUrl.kycUrl}/kyc/getTierList/${selectedRecord.tiers[selectedRecord.tiers.length-1]}`)
        if(res.status){
            setRequirements(res.data)
        }
        
    }

    useEffect(() => {
        getRequirement()
        setTranData(selectedRecord)

    }, [open])

    return (
        <div sx={style}>

            <h4>
                Edit {selectedRecord.tiers==='tier1'?'Tier 1':selectedRecord.tiers==='tier2'? 'Tier 2': selectedRecord.tiers==='tier3'?'Tier 3': selectedRecord.tiers==='tier4'?'Tier 4':selectedRecord.tiers}
            </h4>
            <div className="Container">
                <div className="d-flex flex-row">
                    <span className="px-1">
                        <label>No of external daily transaction</label>
                        <input type='number' className="form-control"
                        style={{
                            width: 300,
                        }}
                         value={tranData.maxNumberOfTxnAllowed}
                         onChange={(e) => {setTranData({...tranData, maxNumberOfTxnAllowed: e.target.value})}}
                         />
                    </span>
                    <span className="px-2">
                        <label>Daily limit for external transfer</label>
                        <input type='number' className="form-control"
                        style={{
                            width: 300,
                        }}
                         value={tranData.maximumLimit}
                         onChange={(e) => {setTranData({...tranData, maximumLimit: e.target.value})}}
                         />
                    </span>
                </div>

                <div className="d-flex  py-5">
                    <span style={{flexDirection:'column'}}>
                        <label>Single transfer limit for external</label>
                        <br/>
                        <input type='number'  className="form-control"
                        style={{
                            width: 300,
                        }}
                         value={tranData.singleTransferLimit}
                         onChange={(e) => {setTranData({...tranData, singleTransferLimit: e.target.value})}}
                         />
                    </span>
                    
                </div>
            </div>
            <div className="d-flex justify-content-end my-3">
                <div>
                    <ThemeProvider theme={theme}>
                        <Button type="button" className="btn btn-secondary" onClick={() => { setOpen(true) }}
                        >Add Requirement</Button>
                    </ThemeProvider>


                </div>
            </div>

            <Paper
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                }}
                component="ul"
            >
                {requirements.map((data) => {
                    let icon;


                    return (
                        <ListItem key={data}>
                            <Chip
                                icon={icon}
                                label={data.items}
                                onDelete={()=>deleteRequirement(data)}
                            />
                        </ListItem>
                    );
                })}
            </Paper>

            <div className="d-flex justify-content-end my-3">
                <button type="button" className="btn btn-danger" onClick={handleUpdateTier} >Save Changes</button>
            </div>


            <Modal
                visible={open}
                centered
                onCancel={() => setOpen(false)}
                footer={[]}
                width={450}
            >
                <AddRequirement
                    selectedRecord={selectedRecord}
                    setOpen={setOpen}
                />
            </Modal>
        </div>

    )
}



const copReq = [
    { reqItem: "Phone Validation", value: 1 },
    { reqItem: "Email Validation", value: 2 },
    { reqItem: "KYC Validation", value: 4 },
    { reqItem: "Address Validation", value: 20 },
    { reqItem: 'Identity Validation', value: 19 },
    { reqItem: "kycReqRequest", value: 99 },

]


const timeSlots = Array.from(new Array(24 * 2)).map(
    (_, index) =>
        `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${index % 2 === 0 ? '00' : '30'
        }`,
);

export default EditCooperateRequirement