import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker, Row, Typography, Input } from 'antd';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { hideLoader, showLoader } from '../../../utils/loader';
import { emailValidator } from '../../../utils/helper';
import validator from 'validator';
import { customBaseUrl, httpPost } from '../../../store/http';
const { Paragraph, Title } = Typography;

const UpdateAdmninPass = ({ showModal, hideModal, email }) => {


    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [pin, setPin] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      showLoader();
      if (validator.isEmpty(password)) {
        hideLoader()
        Swal.fire('Oops!', 'Password cannot be empty', 'error');
        return;
      }
          if (validator.isEmpty(pin)) {
        hideLoader()
        Swal.fire('Oops!', 'Pin cannot be empty', 'error');
      }
      const postData = {
        phoneOrEmail: email,
        newPassword: password,
        otp: Number(pin),
      };
      const res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password`,
        postData,
        true
      );
      if (res.status) {
        hideLoader()
        Swal.fire('Done', res.message, 'success').then(() => {
        hideModal(false)
        });
      } else {
        hideLoader()
        Swal.fire('Oops!', res.message, 'error');
      }
    };
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      width='50%'
    >
        <div className="cw-content">
        <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh' }}
      >
        <div className='wrapper' style={{ width: '50%' }}>
          <Title level={4} align='middle'>
            Reset your password
          </Title>
         
          <Input.Password
            placeholder='New Password'
            size='large'
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <br />

          <Input.Password
            placeholder='Confirm Password'
            size='large'
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <br />
          <br />

          <Input
            size='large'
            placeholder='otp'
            onChange={(e) => setPin(e.target.value)}
          />

          <Button
            type='primary'
            htmlType='submit'
            size='large'
            block
            style={{  marginTop: '45px' }}
            onClick={handleSubmit}
          >
            Save Password
          </Button>
        </div>
      </Row>
           
        </div>


    </Modal>
  );
};

export default UpdateAdmninPass;
