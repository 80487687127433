import React, { useEffect, useState } from 'react';
import frontArrow from '../../assets/images/front_arrow.svg';
import backArrow from '../../assets/images/back_arrow.svg';
import check from '../../assets/images/check.svg';
import blocked from '../../assets/images/blocked.svg';
import upload from '../../assets/images/upload.svg';
import pending from '../../assets/images/pending.svg';
import StatImage from './StatImage';
import { TierDetails } from './index';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import Swal from 'sweetalert2';
import PinModal from '../../components/Modal/Pin/PinModal';

export default function Tier1({
  kycStatus,
  handlePage,
  page,
  userDetails,
  setShowAlertType,
  setShowAlert,
  teir1Status,
  businessName,
  setBusinessName,
  Btype,
  setBtype,
  Bdescription,
  setBdescription,
  setViewData,
  setShowViewDocModal,
  handleApprove,
  handleReject,
  setShowModal,
  setName,
  user,
  handleUpdateStatus,
  getBussinessLogo,
  businessLogo,
  handleRequirement,
  tier1Data,
  getKycStatus,
  handleUpdatRequirement,
  businessTypes
}) {
  console.log('tier1-user', teir1Status.business_logo_reqId)
  console.log('user', user);
  //console.log('tier1-tier1Data', tier1Data);
const [showOTPModal, setShowOTPModal] = React.useState(false);
const [otp, setOTP] = React.useState('');
const [type, setType] = React.useState('')
const [email, setEmail] = useState([]);
const [phone, setPhone] = useState([]);

const handleConfirmOTP = async() => {
  let d =new Date();
  showLoader();
  let data =
    {
      otp,
      phoneOrEmail: type=='phone'?userDetails.phoneNumber: userDetails.email
    }
  
  let res;
  if (type === 'email'){
/*    res = await httpPost(`${customBaseUrl.authUrl}/api/v1/auth/verify-email`,
    data)
*/
    setOTP('')
    res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify( data),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "emailKYC",
      customerName: user?.firstName+' '+ user?.lastName
    } )
  }
  else if(type === 'phone'){
    /*
    res = await httpPost(`${customBaseUrl.authUrl}/api/v1/auth/verify-phone`,
    data)
    */
    res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify( data),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "phoneKYC",
      customerName: user?.firstName+' '+ user?.lastName
    } )
  }

  if(res.status){
    getKycStatus();
    hideLoader();
    setShowOTPModal(false)
    Swal.fire('verified',res.message,'done')
  }else{
    hideLoader();
    Swal.fire('Oops!', res.message, 'error')
  }
}

  const handleSendOtp = async (type, value) => {
    setType(type)
    showLoader();
      let res;
    if (type === 'email'){
        res = await httpGet(`${customBaseUrl.authUrl}/api/v1/auth/resend-otp-mail/${value}`)
    }
    else if (type === 'phone'){
      res = await httpGet(`${customBaseUrl.authUrl}/api/v1/auth/resend-otp/${value}`)
    }


    if (res.status) {
      hideLoader ();
      Swal.fire('Done', res.message, 'success');
      setShowOTPModal(true);
    } else {
      hideLoader ();
      Swal.fire('Opps!', res.message, 'error');
    }

  }

  useEffect(()=>{
    const email1 = kycStatus?.tier1?.filter(
      (e) => e?.requirementType === 'EMAIL'
    )[0];
    const phone1 =kycStatus?.tier1?.filter(
      (e) => e?.requirementType === 'PHONE_NUMBER'
    )[0];
    setEmail(email1);
    setPhone(phone1)

  },[kycStatus])
 
  return (
    <div className='tier' id='tier1'>
      {/* <div className='head my-5'>Upgrade KYC Level</div> */}
      <div className='info my-3'>
        <p className='info-1'>Tier 1</p>
        <div className='info-2'>
          <p className='info-2-t'>status</p>
          <p
            className={`${phone?.status === 'APPROVED' &&
              email?.status === 'APPROVED'
              ? 'info-2-b-true'
              : 'info-2-b-false'
              } px-3 py-1`}
          >
            {phone?.status === 'APPROVED' &&
              email?.status === 'APPROVED'
              ? 'Completed'
              : 'Not Completed'}
          </p>
        </div>
      </div>
      <div className='table my-2'>
        <table className='w-100'>
          <thead>
            <tr className=''>
              <th className='p-2'>Kyc Requirement</th>
              <th className='p-2'>Status</th>
              <th className='p-1'>Action</th>
            </tr>
          </thead>
          <tbody>
            {/*
              user.corporate === true ? (
                <>
                  <tr className=''>
                    <td className='p-3'>Business Logo </td>
                    <td>
                      <div
                        className={
                          
                          teir1Status?.logo_status === 'APPROVE'? 'status-true':
                          teir1Status?.logo_status === 'REJECT'? 'status-false':
                          'status-pending'
                        }
                      >
                        <img
                          src={
                            teir1Status?.logo_status === 'APPROVE'? check:
                            teir1Status?.logo_status === 'REJECT'? blocked:
                            pending
                          }
                          alt=''
                          className={`me-2 ${teir1Status?.logo_status
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>
                          {teir1Status?.logo_status === 'UPLOADED' ? 'Pending': teir1Status?.logo_status==='APPROVE'? 'Approved':teir1Status?.logo_status ==='REJECT'?'Rejected' : teir1Status?.logo_status==='UNDER REVIEW'?'UNDER REVIEW':'Not Completed'}
                        </span>
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {businessLogo?.adminStatus === 'UPLOADED'? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${teir1Status?.logo_status === 'APPROVE' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          setShowModal(true);
                          setName('logo');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {teir1Status?.logo_status === 'APPROVE' ? '  Uploaded' : teir1Status?.logo_status === 'REJECT'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: businessLogo.imageUrl,
                            title: 'BUSINESS LOGO',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      <button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${teir1Status?.logo_status === 'APPROVE' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleApprove(teir1Status?.business_logo_reqId)}
                        
                      >
                        <StatImage
                        
                          className={`me-2 ${ businessLogo?.adminStatus === 'APPROVE'
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        {// Approve 
                        }
                        { businessLogo?.adminStatus === 'APPROVE' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleReject(teir1Status?.business_logo_reqId) }
                      >
                        { businessLogo?.adminStatus === 'REJECT' ? 'Rejected' : 'Reject'}
                      </button>
                    </td>
                  </tr>
                  <tr className=''>
                    <td className='p-3'>Business Name {tier1Data.business_name}</td>
                    <td>
                      <div
                        className={
                          teir1Status.business_status === 'APPROVE' ? 'status-true' : teir1Status.business_status === 'REJECT' ?'status-false': 'status-pending'
                        }
                      >
                        <img
                          src={teir1Status.business_status === 'APPROVE' ? check : teir1Status.business_status === 'REJECT' ? blocked: pending}
                          alt=''
                          className={`me-2 ${teir1Status.business_status === 'APPROVE' ? 'status-true' : teir1Status.business_status === 'REJECT' ?'status-false': 'status-pending'
                            }`}
                        />
                        <span>
                          {teir1Status.business_status === 'APPROVE' ? 'Approved' : teir1Status.business_status === 'REJECT' ?'Rejected': teir1Status.business_status === 'UNDER REVIEW' ? 'Under Review' : 'Not Completed'}
                        </span>
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <TierDetails
                        name={businessName}
                        verified={teir1Status.business_status==='APPROVE'? true:false}
                        setChange={setBusinessName}
                      />
                      <div
                        onClick={() => {teir1Status.business_status?handleUpdatRequirement('BUSINESS_NAME',businessName,1): handleRequirement('BUSINESS_NAME', businessName,1)}}
                        className={`action-2 ${teir1Status.business_status === 'APPROVE'? 'btn disabled' : 'btn'
                          } my-2`}
                      >
                        {//<img src={frontArrow} alt='' className='me-2' />
                        }
                        <span>
                      {teir1Status?.business_name === true
                            ? 'Submitted'
                            : 'Submit'}
                        </span>
                      </div>
                      <button
                        className={` btn btn-outline-success btn-sm ms-2' ${teir1Status.business_status === 'APPROVE' ? 'btn disabled' : 'btn'
                          } my-2`}
                          onClick={() => handleApprove(teir1Status.business_name_reqId)}

                       // onClick={() =>  handleUpdateStatus('APPROVED', teir1Status.business_name_reqId)}
                      >
                      
                       {teir1Status.business_status === 'APROVE' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'                        
                        onClick={() => handleReject(teir1Status.business_name_reqId) }
                      >                        
                        Reject
                      </button>
                    </td>
                  </tr>
                  <tr className=''>
                    <td className='p-3'>Business Description </td>
                    <td>
                      <div
                        className={
                          teir1Status.description_status === 'APPROVE' ? 'status-true' : teir1Status.description_status === 'REJECT' ?'status-false': 'status-pending'
                        }
                      >
                        <img
                          src={teir1Status.description_status === 'APPROVE' ? check : teir1Status.description_status === 'REJECT' ? blocked: pending}
                          alt=''
                          className={`me-2 ${teir1Status.description_status === 'APPROVE' ? 'status-true' : teir1Status.description_status === 'REJECT' ?'status-false': 'status-pending'
                            }`}
                        />
                        <span>
                          {teir1Status.description_status === 'APPROVE' ? 'Approved' : teir1Status.description_status === 'REJECT' ?'Rejected': teir1Status.description_status === 'UNDER REVIEW' ? 'Under Review' : 'Not completed'}
                        </span>
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <TierDetails
                        name={Bdescription}
                        verified={teir1Status.description_status === 'APPROVE'?true:false}
                        setChange={setBdescription}
                      />
                      <div
                        onClick={() => {teir1Status.business_status?handleUpdatRequirement('BUSINESS_DESCRIPTION',Bdescription,1):handleRequirement('BUSINESS_DESCRIPTION', Bdescription,1)}}
                        className={`action-2 ${teir1Status.description_status === 'APPROVE'? 'btn disabled' : 'btn'
                          } my-2`}
                      >
                        {//<img src={frontArrow} alt='' className='me-2' />
                        }
                        <span>
                      {teir1Status?.description_status === true
                            ? 'Submitted'
                            : 'Submit'}
                        </span>
                      </div>
                      <button
                        className={` btn btn-outline-success btn-sm ms-2' ${teir1Status.description_status === 'APPROVE' ? 'btn disabled' : 'btn'
                          } my-2`}
                          onClick={() => handleApprove(teir1Status.business_description_reqId)}

                       // onClick={() =>  handleUpdateStatus('APPROVED', teir1Status.business_name_reqId)}
                      >
                      
                       {teir1Status.description_status === 'APROVE' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'                        
                        onClick={() => handleReject(teir1Status.business_description_reqId) }
                      >                        
                        Reject
                      </button>
                    </td>
                  </tr>

                  <tr className=''>
                    <td className='p-3'>Business Type </td>
                    <td>
                      <div
                        className={
                          teir1Status.type_status === 'APPROVE' ? 'status-true' : teir1Status.type_status === 'REJECT' ?'status-false': 'status-pending'
                        }
                      >
                        <img
                          src={teir1Status.type_status === 'APPROVE' ? check : teir1Status.type_status === 'REJECT' ? blocked: pending}
                          alt=''
                          className={`me-2 ${teir1Status.type_status === 'APPROVE' ? 'status-true' : teir1Status.type_status === 'REJECT' ?'status-false': 'status-pending'
                            }`}
                        />
                        <span>
                          {teir1Status.type_status === 'APPROVE' ? 'Approved' : teir1Status.type_status === 'REJECT' ?'Rejected': teir1Status.type_status === 'UNDER REVIEW' ? 'Under Review' : 'Not completed'}
                        </span>
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                        <select disabled ={teir1Status.type_status === 'APPROVE' ? true:false}  onChange={(e)=>{setBtype(e.target.value)}}  value={Btype?businessTypes?.filter(e=>e?.businessType === Btype)[0]?.businessType:''} >
                            <option value= ''>Select Business Type</option>
                            {Object.keys(businessTypes).map((key) => (
                                <option key={key} value={businessTypes[key].businessType}>{businessTypes[key].businessType}</option>
                            ))}
                        </select>
                      
                      <div
                        onClick={() => {teir1Status.type_status?handleUpdatRequirement('BUSINESS_TYPE',Btype,1):handleRequirement('BUSINESS_TYPE', Btype,1)}}
                        className={`action-2 ${teir1Status.type_status === 'APPROVE'? 'btn disabled' : 'btn'
                          } my-2`}
                      >
                        {//<img src={frontArrow} alt='' className='me-2' />
                        }
                        <span>
                      {teir1Status?.type_status === true
                            ? 'Submitted'
                            : 'Submit'}
                        </span>
                      </div>
                      <button
                        className={` btn btn-outline-success btn-sm ms-2' ${teir1Status.type_status === 'APPROVE' ? 'btn disabled' : 'btn'
                          } my-2`}
                          onClick={() => handleApprove(teir1Status.business_type_reqId)}

                       // onClick={() =>  handleUpdateStatus('APPROVED', teir1Status.business_name_reqId)}
                      >
                      
                       {teir1Status.type_status === 'APROVE' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'                        
                        onClick={() => handleReject(teir1Status.business_type_reqId) }
                      >                        
                        Reject
                      </button>
                    </td>
                  </tr>

                  
                </>
              )
                : ""
            */}

            <tr className=''>
              <td className='p-1'>Phone Number Verification</td>
              <td>
                <div
                  className={
                    phone?.status === 'APPROVED'
                      ? 'status-true'
                      : 'status-pending'
                  }
                >

                  <span>
                    {phone?.status === 'APPROVED'
                      ? 'Verified'
                      : 'Pending'}
                  </span>
                </div>
              </td>
              <td className='d-flex justify-content-start align-items-center'>
                <div>
                  <span>
                   <input 
                   value={userDetails?.phoneNumber?.replace('+','')}
                   placeholder='phone' disabled={phone?.status === 'APPROVED'?true : false}/>
                  </span>
                </div>               
             
              <div onClick={()=>handleSendOtp('phone',userDetails?.phoneNumber?.replace('+',''))}
                     className={`action-2 ${phone?.status === 'APPROVED'? 'btn disabled': 'btn'
                          } my-2 `}
                      >
                      <img src={frontArrow} alt='' className='me-2' />
                        
                        <span>
                      {phone?.status === 'APPROVED'? 'Verified': 'verify'}
                        </span>
              </div>  
              </td>
              
            </tr>

            <tr className=''>
              <td className='p-3'>Email Verification</td>
              <td>
                <div
                  className={
                    email?.status === 'APPROVED'
                      ? 'status-true'
                      : 'status-pending'
                  }
                >
                  <span>
                    {email?.status === 'APPROVED'
                      ? 'Verified'
                      : 'Pending'}
                  </span>
                </div>
              </td>
              <td className='d-flex justify-content-start align-items-center'>
                <div>
                  <span>
                   <input disabled
                   value={userDetails?.email}
                   placeholder='email'/>
                  </span>
                </div>
                <div onClick={()=>handleSendOtp('email',userDetails?.email)}
                     className={`action-2 ${email?.status === 'APPROVED'? 'btn disabled': 'btn'
                          } my-2 `}
                      >
                      <img src={frontArrow} alt='' className='me-2' />
                        
                        <span>
                      {email?.status === 'APPROVED'? 'Verified': 'verify'}
                        </span>
              </div>  
              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div className='tier-foot mb-2'>
        {/* <div className='tier-foot-1'>
              NB: Supported files for upload includes, jpeg, png, pdf
            </div> */}
        <div className='tier-foot-2 me-0'>
          <div
            className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('previous');
            }}
            
          >
            <img className='me-2' src={backArrow} alt='' /> Previous
          </div>{' '}
          <div className='page mx-1'>{page}</div>{' '}
          <div
             className={`${page === 4 
               ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('next');
            }}
          >
            Next
            <img className='ms-2' src={frontArrow} alt='' />
          </div>
        </div>
      </div>

        {showOTPModal? 
         <PinModal
         center
         showModal={showOTPModal}
         hideModal={setShowOTPModal}
         otp={otp}
         setOtp={setOTP}
         numInputs={6}
         separator=''
         buttonLabel='Verify'
         title='OTP Authentication'
         description='Please input the OTP sent to you'
         handleSubmit={handleConfirmOTP}
       />
        :''}

    </div>
  );
}
