import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import useMatStyles from "../style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt, TextField } from "@mui/material";
import { Button, Input, Space } from "antd";
import { ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import ViewTransaction from "./modals/ViewTransaction";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { currencyFormat, transformName } from "../../../utils/helper";
import FilterTransactions from "./modals/filter";
//import { Modal } from 'antd';
import ExportTrans from './modals/export'
import Exp from "../../../assets/images/export.svg";
import { NotificationManager } from "react-notifications";
import AccountStatement from "./modals/statement";
import moment from "moment";
import { ProfileContext } from "../../../store/context/ProfileContext";
import { useContext } from "react";
import { CSVLink } from "react-csv";
import generatePDF from "./generatePDF";
import GenerateStatement from "./generateStatement";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: []

}

const SMUTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const styles = useMatStyles();
  const { profile } = useContext(ProfileContext);
  let roles = localStorage.getItem('roles');
  const statistics = ["Total Debit", "Total Credit", "Total Transaction"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalDebit, setTotalDebit] = useState(0)
  const [totalCreditDebit, setTotalCreditDebit] = useState(0)
  const [showExport, setShowExport] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [searchField, setSearchField] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState([]);
  const [overallAnalysis, setOverall] = useState([]);



  // const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState(initial)
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });

    //  filtered? await getTransactions(newPage, pagination.pageSize): await getFilteredTransactions(newPage, pagination.pageSize) ;
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
   // filtered ? await getTransactions(0, value): await getFilteredTransactions(0, value);
  };

  const viewTransactions = (row) => {
    setTransaction(row);
    setShowTransactionReceiptModal(true);
  };

  const handleReverseTransaction = async (row) => {

    //return alert(JSON.stringify(row));
    showLoader();
    let reverseData =
    {
      tranCrncy: row.tranCrncyCode,
      tranDate: row.tranDate,
      tranId: row.tranId,
      tranAmount: row?.tranAmount
    }

    /*const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/reverse`,
      reverseData
    );*/
    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(reverseData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "reversal",
      customerName: row?.senderName
    } )
    if (res.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: res.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      // await getTransactions(pagination.current, pagination.pageSize);
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: res.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const reverseTransaction = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reverse this transaction?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.value) {
        handleReverseTransaction(row);
      }
    });
  };
  const dropDownOptions = ["View"];
  const handleDropdownAction = (item, row) => {
    if (item === "View") viewTransactions(row);
    if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1  ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){
      if (item === "Reverse") reverseTransaction(row);
    }else{
      NotificationManager.error('not authorize')
    }
  };
  const columns = React.useMemo(() => [
    {
      field: "paymentReference",
      headerName: "Reference ID",
      width: 200,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true,
      renderCell: ({ row }) => {
        const { paymentReference = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {paymentReference.length > 40
              ? paymentReference.slice(0, -25) + "..."
              : paymentReference}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.paymentReference.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row.paymentReference}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "tranId",
      headerName: "Transaction ID",
      width: 200,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true,
      renderCell: ({ row }) => {
        const { tranId = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {tranId.length > 40
              ? tranId.slice(0, -35) + "..."
              : tranId}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.tranId.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row.tranId}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "tranDate",
      headerName: "Date",
      width: 100,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "senderName",
      headerName: "Sender's Name",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "acctNum",
      headerName: "Sender's Account",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "tranCategory",
      headerName: "Category",
      description: "Category Column",
      sortable: true,
      width: 200,
      headerClassName: styles.tableHeader,
    },
    
    {
      field: "tranAmount",
      headerName: "Amount",
      width: 100,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "tranNarrate",
      headerName: "Narration",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },

    {
      field: "partTranType",
      headerName: "Type",
      width: 120,
      editable: false,
      sortable: true,
      description: "Status Column",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div>
            <span
              style={{
                color: `${row.partTranType === "C" ? "#27AE60" : "#EB5757"}`,
              }}
            >
              {row.partTranType === "C" ? "Credit" : "Debit"}
            </span>
          </div>
        );
      },
    },
    {
      field: "receiverName",
      headerName: "Recipient",
      width: 200,
      editable: false,
      sortable: true,
      description: "Recipient Column",
      headerClassName: styles.tableHeader,
     
    },

    {
      field: "id",
      headerName: "Action ",
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={dropDownOptions}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ]);
  const getTransactions = async (page, size) => {
    setTransactions([]);
    showLoader();

    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/all/transactions?page=${pages}&size=${limit}`
    );
    if (response.status === true) {
      setTransactions(response.data.content);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalElements);
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const getTotalCredit = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-credit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalCredit(res.data);
      hideLoader();
    } else {
      hideLoader();
    }

  };

  const getTotalDebit = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-debit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalDebit(res.data.amount);
      hideLoader();
    } else {
      hideLoader();
    }

  };

  const getTotalDebitCredit = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-credit-debit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalCreditDebit(res.data);
      hideLoader();
    } else {
      hideLoader();
    }

  };
  // const showModal = () => {
  //   setVisible(true);
  // };
  // const handleCancel = () => {
  //   setVisible(false);
  // };


  useEffect(() => {
    // filtered ? getTransactions() : getFilteredTransactions();
    getFilteredTransactions()
    // getTotalCredit();
    fetchOverview()
    // getTotalDebit();
    // getTotalDebitCredit();
  }, [filtered]);
  useEffect(() => {
   if(!search.searchText) {
    // getTransactions();
   }
  }, [search.searchText]);

  const handleShowFilter = () => {
    setShowFilter(true)
    setSearch({ ...search, searchText: '' })
  }


  const getFilteredTransactions = async (page, size) => {
    setTransactions([]);
    showLoader();
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);

    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.smWalletUrl}/api/v1/wallet/stransaction-list?fromdate=${moment(searchData?.searchDate[0] ?? date).format('YYYY-MM-DD')}&todate=${moment(searchData?.searchDate[1] ?? new Date()).format('YYYY-MM-DD')}`
    );
    if (response.status === true) {
      hideLoader();
      setTransactions(response.data.transaction);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalItems);
    } else {
      hideLoader();
    }
  };
  const handleFilter = () => {
    console.log('searchData', searchData);
    getFilteredTransactions();
    //getTransactions();
    setFiltered(false)
    setShowFilter(false)
    setSearch({ ...search, searchText: '' })



  }
  const handleClearFilter = () => {
    //  return alert(JSON.stringify(transactions))
    setSearch({ ...search, searchText: '' })
    setSearchData(initial)
    setShowFilter(false)
    setFiltered(true)
  }


  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const getTransaction = async (e) => {
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);
    e.preventDefault();
    
    showLoader();
    const response = await httpGet(

      `${customBaseUrl.smWalletUrl}/api/v1/wallet/stransaction-list?fromdate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD')}&todate=${moment(filteredDate?.todate ?? new Date()).format('YYYY-MM-DD')}`
    );
    
    const filter = clean(filteredData);

    if (response.status === true) {
      console.log('filter', filteredData);
      const filtered = response?.data?.transaction?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      setTransactions(filtered);
      //setFiltered(response.data.content)
      setMetaData(filtered?.length);
      //  console.log('filter', fil)
      if(filtered?.length < 1) NotificationManager.error('Record not found' );
      
      hideLoader();
    } else {
      NotificationManager.error(response?.message )
      setTransactions([]);
  
      hideLoader();
    }
  };

  const fetchOverview = async () => {
    const res = await httpGet(
      `${customBaseUrl.smWalletUrl}/api/v1/wallet/stransaction Stats`
    );
    
    if (res?.status) {
      //setFiltered(response.data.content)
      setOverall(res.data);
      hideLoader();
    } else {
      console.log(res)
      hideLoader();   
     }

     };
  

  const overall = [
    {
      title: 'Total Balance',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.totalBalance??0)}</h6>, count: ''},
      id: 1,
    },
    {
      title: 'Total Deposit',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.totalDeposit??0)}</h6>, count: ''},
      id: 2,
    },
    {
      title: 'Total Withdrawal',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.totalWithdrawal??0)}</h6>, count: ''},
      id: 3,
    },
    /*{
      title: 'Total Revenue',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(overallAnalysis?.totalRevenue??0)}</h6>, count: ''},
      id: 4,
    },
    /*{
      title: 'Total Commission to Merchant',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 5,
    },
    {
      title: 'Total Commission to Aggregator',
      value: {value: <h6 style={{fontSize: 14}}>{currencyFormat(0)}</h6>, count: 0},
      id: 6,
    }, */ 
  ];

  let category = ['COMMISSION', 'TRANSFER', 'REVERSAL', 'WITHDRAW', 'FUNDING', 'AIRTIME_TOPUP', 'DATA_TOPUP', 'CABLE']
  return (
    <Layout>

      {!showStatement?
        <>
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
        {overall?.map(e =>
          <Paper elevation={1} className={styles.card} style={{ textAlign: 'center'}}>
          <div className="id-left">{e?.title}</div>
          <br/>
          <div className="id-right">{e?.value?.value}</div>
          <br/>
          <div className="id-right " style={{ bottom: 0}}></div>
      </Paper>
        ) }

        {/*statistics.map((item, index) => (
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              <span style={{ float: "left" }} className={styles.diTop}>
                {item}
              </span>
              {item === 'Total Credit' ? <span className={styles.money}><br/>  {currencyFormat(totalCredit)}</span> :
                item === 'Total Debit' ? <span className={styles.money}><br/> {currencyFormat(totalDebit)}</span> :
                  <span className={styles.money}><br/> {currencyFormat(totalCreditDebit)}</span>}
            </div>
          </Paper>
        ))*/}
      </MatGrid>
      <div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10, backgroundColor: "#ff6700"}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div>
      <div hidden={!searchField}  style={{background:'#ffffff', padding: 10}}>
        <form onSubmit={getTransaction}>
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Category</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, tranCategory: e?.target?.value})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>

          {
            category?.map((e,i)=>
            <option key={i} value={e}>{e.replace('_',' ')}</option>
          )
          }
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Transaction Type</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, partTranType: e?.target?.value})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value="D" >DEBIT</option>
          <option value="C">CREDIT</option>         
        </select>        
      </div>
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      <div className="row">
        
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Reference Number</span>        
          <input type="text" aria-label="Ref" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, paymentReference: e?.target?.value})}

          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Transaction Id</span>        
          <input type="text" class="form-control"  aria-label="tranId"
            onChange={(e)=>setFilteredData({...filteredData, tranId: e?.target?.value})}

          />
        </div>
      </div>
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Account Number</span>        
          <input type="text" aria-label="First name" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Email</span>        
          <input type="email" class="form-control"  aria-label="Email"
             onChange={(e)=>setFilteredData({...filteredData, createdEmail: e?.target?.value})}
          />
        </div>
      </div>
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Search</button>
      </div>
      </form>
      </div>
      
      <div className="d-flex justify-content-between">
        <div style={tool} class="col-md-5">

          {/*<div className={styles.searchRow}>
            <Input
              className={styles.searchInput}
              placeholder="Search Transactions by Id"
              value={search.searchText}
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              prefix={<SearchOutlined className={styles.searchIcon} />}
            />
            
          </div>

          <div className={styles.searchRow}>
          {<Button onClick={handleSearch}>Search</Button>
          }
        </div>
        <div className={styles.searchRow}>
          <FilterList onClick={handleShowFilter} />
          Filter
        </div>*/}
        </div>
        {/* <div >
          <MatButton
            variant="contained" 
            style={{marginRight:20}}
            onClick={()=>setShowStatement(true)}
          >
            <img src={Exp} alt="export" />
            <div>Generate Statement</div>
          </MatButton>  */}
          {/*<MatButton
            //className="update-button-1"
            onClick={() => setShowExport(true)}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>Export Data</div>
      </MatButton>*/}
        {/* </div> */}
      </div>

      <div >
        <div class="col-md-12">
 <MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <img src={Exp} alt="export" />           
            <CSVLink
          data={transactions}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`Transactions-history.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </MatButton> 
          <MatButton
            //className="update-button-1"
            onClick={() => generatePDF(transactions, filteredDate)}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>DOWNLOAD AS PDF</div>
          </MatButton>           
          
      </div>
      </div>

      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            className={styles.table}
            /*rows={search.searchText ? transactions.filter(req => req?.paymentReference.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.tranCategory?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.senderName?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.tranDate.includes(search?.searchText) || req?.tranAmount === (search?.searchText.toLowerCase())) :
              filtered ? transactions : transactions.filter((req) =>
               /* req["tranCategory"]?.toLowerCase()
                  .includes(searchData?.searchCategory?.toLowerCase())
                &&*//*
                req?.["partTranType"]?.toLowerCase()
                  ?.includes(searchData?.searchType?.toLowerCase())                
              )}*/
              rows={transactions}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            //  paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
             onPageChange={(newPage) => handleChangePage(newPage)}
            
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          />
        </div>
      </MatGrid>
      </>
      : 
      <GenerateStatement setShowStatement={setShowStatement}/>
      }
      {showTransactionReceiptModal && (
        <ViewTransaction
          showModal={showTransactionReceiptModal}
          setShowModal={setShowTransactionReceiptModal}
          transaction={transaction}
        />
      )}

      {showFilter && (
        <FilterTransactions
          showModal={showFilter}
          hideModal={setShowFilter}
          clearFilter={handleClearFilter}
          searchData={searchData}
          setSearchData={setSearchData}
          handleFilters={handleFilter}
        />)
      }
{/*showStatement && (
        <AccountStatement
          showModal={showStatement}
          hideModal={setShowStatement}
          clearFilter={handleClearFilter}
          searchData={searchData}
          setSearchData={setSearchData}
          handleFilters={handleFilter}
        />)
*/}

      {/* <Modal
        visible={visible}
        title="Title"
        //onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}>
        <ExportTrans />
      </Modal> */}


      {showExport ? (
        <ExportTrans title={filtered ? 'Transactions' : searchData?.searchCategory} showModal={showExport} hideModal={setShowExport} dataList={transactions} />
      ) : (
        ""
      )}



    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default SMUTransactions;
