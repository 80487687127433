import React, { useEffect } from 'react';
import { Modal } from 'antd';
import FileSaver from 'file-saver';
import axios from 'axios'
import fileDownload from 'js-file-download'
import html2canvas from 'html2canvas';
//var fs = require('fs'),
//request = require('request');
import { saveAs } from 'file-saver'


export default function ViewDocModal({
  showModal,
  hideModal,
  title,
  className,
  data,
}) {

  const downloadImage = () => {
    handleImageDownload()
    saveAs(data.url, data.title) // Put your image url here.
  }


  const handleImageDownload = async () => {
    const element = document.getElementById('print'),
    canvas = await html2canvas(element,{useCORS:true,allowTaint: true, width:window.screen.availWidth, height: window.screen.availHeight, x: 0, y: window.pageYOffset}),
    data = canvas.toDataURL('image/jpg'),
    link = document.createElement('a');
 
    link.href = data;
    link.download = 'downloaded-image.jpg';
 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);  };

    useEffect(()=>{
      console.log('data', data);
    },[]);

  return (
    <Modal
      className={className}
      title={title || ''}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={800}
      footer={0}
    >
      <h4 className='text-center'>{data.title}</h4>
      <div id="print" className='text-center mt-5'>
        {data.url ? (
          <img  src={data.url} alt='doc' style={{ width: '90%' }} />
        ) : (
          <span>No document found {data?.url}</span>
        )}
      </div>

     

      <div className={data.url ? 'text-center mt-5' : 'd-none'}>
      
      {//<button className='btn btn-outline-success' onClick={downloadImage}>Download!</button>
      }
     
      
      </div>
    </Modal>
  );
}
