import React, { useEffect } from 'react';
import frontArrow from '../../assets/images/front_arrow.svg';
import backArrow from '../../assets/images/back_arrow.svg';
import check from '../../assets/images/check.svg';
import blocked from '../../assets/images/blocked.svg';
import upload from '../../assets/images/upload.svg';
import pending from '../../assets/images/pending.svg';
import StatImage from './StatImage';
import { TierDetails } from './index';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import Swal from 'sweetalert2';
import PinModal from '../../components/Modal/Pin/PinModal';
import { useState } from 'react';

export default function Tier4({
  handlePage,
  page,
  userDetails,
  teir1Status,
  businessName,
  setBusinessName,
  Btype,
  setBtype,
  Bdescription,
  setBdescription,
  setViewData,
  setShowViewDocModal,
  handleApprove,
  handleReject,
  setShowModal,
  setName,
  user,
  kycStatus,
  handleUpdateStatus,
  businessLogo,
  handleRequirement,
  tier1Data,
  getKycStatus,
  handleUpdatRequirement
}) {
  console.log('tier1-user', teir1Status.business_logo_reqId)
  console.log('user', user);
  //console.log('tier1-tier1Data', tier1Data);
const [showOTPModal, setShowOTPModal] = React.useState(false);
const [tin, setTin] = React.useState('');
const [type, setType] = React.useState('')
const [cac, setCac] = useState('');
const [reference, setReference] = useState('');

 //let Mem = tier1Data?.filter(item => item.reqItem === 'MEMART')
 //let Tin = tier1Data?.filter(item => item.reqItem === 'TIN')[0]

 useEffect(()=>{
  const cac1 = kycStatus?.tier4?.filter(
    (e) => e?.requirementType === 'CAC'
  )[0];
  const tin1 = kycStatus?.tier4?.filter(
    (e) => e?.requirementType === 'TIN'
  )[0];
  const reference1 = kycStatus?.tier4?.filter(
    (e) => e?.requirementType === 'REFERENCE'
  )[0];
  setCac(cac1);
  setTin(tin1);
  setReference(reference1);
},[])

  return (
    <div className='tier' id='tier1'>
      {/* <div className='head my-5'>Upgrade KYC Level</div> */}
      <div className='info my-3'>
        <p className='info-1'>Tier 4 </p>
        <div className='info-2'>
          <p className='info-2-t'>status</p>
          <p
            className={`${cac?.status === 'APPROVED' &&
              tin?.status === 'APPROVED'
              ? 'info-2-b-true'
              : 'info-2-b-false'
              } px-3 py-1`}
          >
            {cac?.status === 'APPROVED' &&
              tin?.status === 'APPROVED'
              ? 'Completed'
              : 'Not Completed'}
          </p>
        </div>
      </div>
      <div className='table my-2'>
        <table className='w-100'>
          <thead>
            <tr className=''>
              <th className='p-2'>Kyc Requirement</th>
              <th className='p-2'>Status</th>
              <th className='p-1'>Action</th>
            </tr>
          </thead>
          <tbody>
           
                <>
                  <tr className=''>
                    <td className='p-3'>CAC Document-Certificate  </td>
                    <td>
                      <div
                        className={
                          cac?.status === 'APPROVED'? 'status-true':
                          cac?.status === 'REJECTED'? 'status-false':
                          'status-pending'
                        }
                      >
                        <img
                          src={
                            cac?.status === 'APPROVED'? check:
                            cac?.status === 'REJECTED'? blocked:
                            pending
                          }
                          alt=''
                          className={`me-2 ${cac?.status
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>
                          {cac?.status}
                        </span> 
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {cac?.attachment? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${cac?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          setShowModal(true);
                          setName('CAC');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {cac?.status === 'APPROVED' ? '  Uploaded' : cac?.status === 'REJECTED'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: cac?.attachment,
                            title: 'CAC certificate',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      {/*<button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${cac?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleUpdateStatus('APPROVED', 'CAC')}
                        
                      >
                        <StatImage
                          srcImage={
                            cac?.status === 'APPROVED'? check:
                            cac?.status === 'REJECTED'? blocked:
                            pending}
                          className={`me-2 ${cac?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        {/* Approve *//*}
                        {cac?.status === 'APPROVED' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleUpdateStatus('REJECTED', 'CAC') }
                      >
                        {cac?.status === 'REJECTED' ? 'Rejected' : 'Reject'}
                          </button>*/}
                    </td>
                  </tr>

                  {/*<tr className=''>
                    <td className='p-3'>CAC Document-Memart  </td>
                    <td>
                      <div
                        className={
                            cac?.status === 'APPROVED'? 'status-true':
                            cac?.status === 'REJECT'? 'status-false':
                          'status-pending'
                        }
                      >
                        <img
                          src={
                            cac?.status === 'APPROVED'? check:
                            cac?.status === 'REJECT'? blocked:
                            pending
                          }
                          alt=''
                          className={`me-2 ${cac?.status
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        <span>
                          {cac?.status === 'UPLOADED' ? 'Pending': cac?.status==='APPROVED'? 'Approved':cac?.status ==='REJECTED'?'Rejected' : 'Not Completed'}
                        </span> 
                      </div>
                    </td>
                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {kycStatus?.userCAC?.fileName? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${cac?.status === 'APPROVED' ? 'btn ' : 'btn'
                          } my-2`}
                        onClick={() => {
                          setShowModal(true);
                          setName('memart');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {cac?.status === 'APPROVED' ? '  Uploaded' : cac?.status === 'REJECT'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: kycStatus?.userCAC?.imageUrl,
                            title: 'CAC certificate',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      <button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${cac?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleUpdateStatus('APPROVED', 'CAC')}
                        
                      >
                        <StatImage
                          srcImage={
                            cac?.status === 'APPROVED'? check:
                            cac?.status === 'REJECT'? blocked:
                            pending}
                          className={`me-2 ${cac?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        {/* Approve */}{/*
                        {cac?.status === 'APPROVED' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleUpdateStatus('REJECTED', 'CAC') }
                      >
                        {cac?.status === 'REJECTED' ? 'Rejected' : 'Reject'}
                      </button>
                    </td>
                  </tr>
                           */} 

                  <tr className=''>
                    <td className='p-3'>Tax Identification Number(TIN) </td>
                  
                    <td>
                      <div
                        className={
                          tin?.status === 'APPROVED' ? 'status-true' : tin?.status === 'REJECTED' ?'status-false': 'status-pending'
                        }
                      >
                        <img
                          src={tin?.status === 'APPROVED' ? check : tin?.status === 'REJECTED' ? blocked: pending}
                          alt=''
                          className={`me-2 ${tin?.status === 'APPROVED' ? 'status-true' : tin?.status === 'REJECTED' ?'status-false': 'status-pending'
                            }`}
                        />
                        <span>
                          {tin?.status}
                        </span>
                      </div>
                    </td>

                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {tin?.attachment? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${tin?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          setShowModal(true);
                          setName('TIN');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {tin?.status === 'APPROVED' ? '  Uploaded' : tin?.status === 'REJECTED'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: tin?.attachment,
                            title: 'TIN certificate',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>

                      {/*<button
                        //className='btn btn-outline-success btn-sm ms-2'
                        className={`action-2  btn btn-outline-success my-2' ${tin?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}

                        onClick={() =>  handleUpdateStatus('APPROVED', 'TIN')}
                        
                      >
                        <StatImage
                          srcImage={
                            tin?.status === 'APPROVED'? check:
                            tin?.status === 'REJECTED'? blocked:
                            pending}
                          className={`me-2 ${tin?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                            }`}
                        />
                        {/* Approve *//*}
                        {tin?.status === 'APPROVED' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'
                        onClick={() => handleUpdateStatus('REJECTED', 'TIN') }
                      >
                        {tin?.status === 'REJECTED' ? 'Rejected' : 'Reject'}
                      </button>*/}
                    </td>





                    {/*<td className='d-flex justify-content-start align-items-center'>
                      <TierDetails
                        name={tin}
                        verified={Tin?.status==='APPROVE'? true:false}
                        setChange={setTin}
                      />
                      <div
                        onClick={() => {Tin?.status?handleUpdatRequirement('TIN',tin,4): handleRequirement('TIN', tin,4)}}
                        className={`action-2 ${Tin?.status === 'APPROVE'? 'btn disabled' : 'btn'
                          } my-2`}
                      >
                        {//<img src={frontArrow} alt='' className='me-2' />
                        }
                        <span>
                      {Tin?.status === true
                            ? 'Submitted'
                            : 'Submit'}
                        </span>
                      </div>
                      <button
                        className={` btn btn-outline-success btn-sm ms-2' ${Tin?.status === 'APPROVE' ? 'btn disabled' : 'btn'
                          } my-2`}
                          onClick={() => handleApprove(Tin.id)}

                       // onClick={() =>  handleUpdateStatus('APPROVED', teir1Status.business_name_reqId)}
                      >
                      
                       {Tin?.status === 'APROVE' ? 'Approved' : 'Approve'}
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm ms-2'                        
                        onClick={() => handleReject(Tin?.id) }
                      >                        
                        Reject
                      </button>
                        </td>*/}
                  </tr>
                  <tr className=''>
                    <td className='p-3'>Reference </td>
                  
                    <td>
                      <div
                        className={
                          reference?.status === 'APPROVED' ? 'status-true' : reference?.status === 'REJECTED' ?'status-false': 'status-pending'
                        }
                      >
                        <img
                          src={reference?.status === 'APPROVED' ? check : reference?.status === 'REJECTED' ? blocked: pending}
                          alt=''
                          className={`me-2 ${reference?.status === 'APPROVED' ? 'status-true' : reference?.status === 'REJECTED' ?'status-false': 'status-pending'
                            }`}
                        />
                        <span>
                          {reference?.status}
                        </span>
                      </div>
                    </td>

                    <td className='d-flex justify-content-start align-items-center'>
                      <div className='action-2 btn my-2 action-d ms-0'>
                      {reference?.attachment? 'Uploaded' : 'Not uploaded'}
                      </div>
                      <div
                        //className='action-2 btn my-2'
                        className={`action-2  btn my-2' ${reference?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                          } my-2`}
                        onClick={() => {
                          setShowModal(true);
                          setName('reference');
                        }}
                        //disabled={teir1Status.business_logo = true}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span> {reference?.status === 'APPROVED' ? '  Uploaded' : reference?.status === 'REJECTED'?'Upload new': 'Upload'}</span>
                      </div>
                  
                      <div
                        className='action-2 btn my-2'
                        onClick={() => {
                          setViewData({
                            url: tin?.attachment,
                            title: 'Reference',
                          });
                          setShowViewDocModal(true);
                        }}
                      >
                        <img src={upload} alt='' className='me-2' />
                        <span>View</span>
                      </div>
                    </td>

                  </tr>
                </>
          </tbody>
        </table>
      </div>
      <div className='tier-foot mb-2'>
        {/* <div className='tier-foot-1'>
              NB: Supported files for upload includes, jpeg, png, pdf
            </div> */}
        <div className='tier-foot-2 me-0'>
          <div
            className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('previous');
            }}
            
          >
            <img className='me-2' src={backArrow} alt='' /> Previous
          </div>{' '}
          <div className='page mx-1'>{page}</div>{' '}
          <div
             className={`${page === 4 
               ? 'btn disabled ' : 'btn'}`}
            onClick={() => {
              handlePage('next');
            }}
          >
            Next
            <img className='ms-2' src={frontArrow} alt='' />
          </div>
        </div>
      </div>       

    </div>
  );
}
