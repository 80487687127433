import React, { useState, useEffect } from "react";
import moment from "moment";
import Layout from "../../../components/Layout";
import useMatStyles from "../style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt } from "@mui/material";
import { Button, Input, Space, Row } from "antd";
import { ArrowBack, ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { transformName } from "../../../utils/helper";
import ExportData from "../../WayaOfficial/modal/exportData";
import Summary from "../../users/Requests/summary/summary";
import { useNavigate } from "react-router-dom";
import RejectPaymentRequest from "./modals/reject";
import SendPaymentRequest from "./modals/sendRequest";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}
const PaymentRequest = () => {
  const [transactions, setTransactions] = useState([]);
  const styles = useMatStyles();
  const statistics = ["All Payment Request", "Settle Payments", "Rejected Payments"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalPending, setTotalPending] = useState(0)
  const [totalSettled, setTotalSettled ] = useState(0)
  const [showExport, setShowExport] = useState(false);
  const [isWaya, setIsWaya] = useState(true)
  const [viewSummary, setViewSummary] = useState(false)
  const [reason, setReason] = useState('');
  const [rejectionModal, setRejectionModal] = useState(false)
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [viewSendReq, setViewSendReq] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [selectedRecord, setSelectedRecord] = useState([])
  const [searchData, setSearchData] = useState(initial)
  const [viewReject, setViewReject] = useState(false);
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const viewTransactions = (row) => {
    setTransaction(row);
    setShowTransactionReceiptModal(true);
  };

  const handleReverseTransaction = async (row) => {
    //return alert(JSON.stringify(row));
    showLoader();
    let reverseData =
    {
      tranCrncy: row.tranCrncyCode,
      tranDate: row.tranDate,
      tranId: row.tranId,
    }

    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/reverse`,
      reverseData
    );
    if (response.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      await getTransactions(pagination.current, pagination.pageSize);
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const reverseTransaction = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reverse this transaction?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.value) {
        handleReverseTransaction(row);
      }
    });
  };
  const dropDownOptions = ["View QR Code", "Settle", "Reject"];
  const handleDropdownAction =async (item, row) => {
    await setSelectedRecord(row)
    if (item === "Settle") setViewSummary(true);
    if (item === "Reject") setViewReject(true);
  };
  const isWayaOption = ["Waya Users", "Non Waya Users"];
  const handleIsWayaAction = (item, row) => {
    if (item === "Waya Users") setIsWaya(true);
    if (item === "Non Waya Users") setIsWaya(false);
  };
  const columns = React.useMemo(() => [
    {
      field: "createdAt",
      headerName: "Date Created",
      width: 250,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true, 
      renderCell: ({row}) => row.createdAt
    },
    {
      field: "senderName",
      headerName: "Request Creator",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "receiverName",
      headerName: "Receiver ",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "amount",
      headerName: "Amount",
      description: "Category Column",
      sortable: true,
      width: 100,
      headerClassName: styles.tableHeader,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "reference",
      headerName: "Reference",
      width: 200,
      editable: false,
      headerClassName: styles.tableHeader,
    },
   
    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: false,
      sortable: true,
      description: "Recipient Column",
      headerClassName: styles.tableHeader,
    },

    {
      field: "id",
      headerName: "Action", 
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={dropDownOptions}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ]);
  const getTransactions = async (page, size) => {
    setTransactions([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request-transactions?isWaya=${isWaya}&page=${pages}&size=${limit}`
       );
    if (response.status === true) {
      console.log(response.data);
      setTransactions(response.data.request);
      //setFiltered(response.data.content)
      setMetaData(response?.data?.totalItems);
      hideLoader();
    } else {
      hideLoader();
    }
  };

 

  const getTotalPending = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request/all-transactions-filter?filter=PENDING`
    );
    if (res.status === true) {
        //setFiltered(response.data.content)
      setTotalPending(res?.data?.totalItems);
      hideLoader();
    } else {
      hideLoader();
    }
    
  };

  const getTotalSettled = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.paymentRequestUrl}/payment-request/all-transactions-filter?filter=PAID`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalSettled(res?.data?.totalItems);
      hideLoader();
    } else {
      hideLoader();
    }
    
  };


  useEffect(() => {
    getTransactions();
    getTotalPending();
    getTotalSettled();
  }, [ isWaya]);

  const handleShowFilter = () => {
    setShowFilter(true)
    setSearch({ ...search, searchText: '' })
  }

  const handleFilter = () => {
    console.log('searchData', searchData);
    //getTransactions();
    setFiltered(false)
    setShowFilter(false)
    setSearch({ ...search, searchText: '' })



  }
  const handleClearFilter = () => {
    //  return alert(JSON.stringify(transactions))
    setSearch({ ...search, searchText: '' })
    setSearchData(initial)
    setShowFilter(false)
    setFiltered(true)
  }

  const handleContinue = async () => {
    showLoader();

    const res = await httpPost(
      `${customBaseUrl.paymentRequestUrl}/settle-payment-request-waya-users/${selectedRecord.reference}/${pin}/${selectedRecord?.receiverId}`
    );

    if (res?.status) {
      hideLoader();
      Swal.fire({
        title: ' Alert',
        text: res?.message,
        timer: 3000
      })
      console.log(res)
    } else{
    Swal.fire('Error', res?.message)
    hideLoader();
     }
  }

  const handleSubmit = async () => {
    showLoader();
   
    const res = await httpPost(
      `${customBaseUrl.paymentRequestUrl}/completePaymentWithOTP/${selectedRecord.reference}/${otp}/${selectedRecord?.receiverId}`
    );

    if (res?.status) {
      Swal.fire('Done', res?.message, 'success');

      setViewSummary(false);
      getTransactions();
      setSelectedRecord([])
      setOtp(''); 
      setPin('')
      hideLoader();
      console.log(res)
    }
    else{
      Swal.fire('Error', res?.message, 'failed');
      setViewSummary(false);
      setSelectedRecord([])
      setOtp('');
       setPin('')
    hideLoader();
  }
  }


  const handleReject = async () => {
    if (selectedRecord?.status==='PAID') return ''
    showLoader();
    let Data= {
      "note": reason,
      "userId": selectedRecord?.receiverId
    }
    const res = await httpPut(`${customBaseUrl.paymentRequestUrl}/reject-payment-request/${selectedRecord.reference}`,
    Data)

    if(res.status){
      hideLoader();
      Swal.fire('Done', res?.message)      
      setViewSummary(false);
      getTransactions();
      setSelectedRecord([])
      setReason('')
      setViewReject(false)

    }else{
      hideLoader();
      console.log(res)
      Swal.fire('Error', res?.message)
      setSelectedRecord([])
      setReason('')
      setViewSummary(false);
    }
  }
  const SendUserOptions = [
    <div><Input placeholder="search" onChange={(e) => alert(e.value.target)} /></div>,
    "Send to Single User",
    "Send Using Webforms",
    "Upload Excel File to Send",
  ];
  return (
    <Layout>

      {!viewSummary?<>
      <MatGrid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        className={styles.matGrid}
      >
        {statistics.map((item, index) => (
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              <span style={{ float: "left" }} className={styles.diTop}>
                {item}
              </span>
              <br />
              <br />

              {item==='All Payment Request'?<span className={styles.money}> {metaData}</span>:
              item==='Rejected Payments'?<span className={styles.money}> {totalPending}</span>:
              <span className={styles.money}> {totalSettled}</span>}
            </div>
          </Paper>
        ))}
      </MatGrid>

      <div style={tool} >

        <div className={styles.searchRow}>
          <Input
            className={styles.searchInput}
            placeholder="Search Transactions"
            value={search.searchText}
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>
       {/* <div className={styles.searchRow}>
          <Button onClick={handleSearch}>Search</Button>

          </div>*/}
           <AntDropdown
          dropDownOptions={isWayaOption}
          handleDropdownAction={(item) => handleIsWayaAction(item)}
          isArrow
        >
        <div className={styles.searchRow}>
          <FilterList onClick={handleShowFilter} />
          Filter
        </div>
        </AntDropdown>
     
     <Row type="flex" justify="end" align="middle">
        
      <Button
        style={{ fontSize: "10px", width:100, background: "#27AE60", color: "#fff" }}
        onClick={() => setShowExport(true)}
      >
        {" "}
        Export Data
      </Button>
     {/* <Button
        style={{ fontSize: "10px", width:100, background: "#27AEFF", color: "#fff" }}
      >
        {" "}
        Reverse Payment
      </Button>
        */}
      <Button
      onClick={()=>{setViewSendReq(true)}}
        size="medium"
        style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
      >
        New Payment Request
      </Button>
      
  </Row>
      </div>


      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            className={styles.table}
            rows={search.searchText ? transactions.filter(req => req?.createdAt.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.senderName?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.receiverName?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.amount ==(search?.searchText) || req?.status.toLowerCase()?.includes (search?.searchText.toLowerCase())) :
              filtered ? transactions : transactions.filter((req) =>
                req["tranCategory"]?.toLowerCase()
                  .includes(searchData?.searchCategory?.toLowerCase())
                &&
                req?.["partTranType"]?.toLowerCase()
                  ?.includes(searchData?.searchType?.toLowerCase())
                &&
                req["tranAmount"] === (Number(searchData?.searchAmount))
                &&
                new Date(req?.["tranDate"]).getTime() >= new Date(searchData?.searchDate[0]).getTime()
                && new Date(req?.["tranDate"]).getTime() <= new Date(searchData?.searchDate[1]).getTime()


              )}
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          />
        </div>
        {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={transactions} file={"Payment_Requests"} />
      }

      
      
      </MatGrid></>:
      <div>
        <ArrowBack onClick={()=>{setViewSummary(false); setOtp(''); setPin('')}}>Back</ArrowBack>
        <Summary displayData={selectedRecord} setViewReject={setViewReject} setSummary={setViewSummary} pin={pin} setPin={setPin}  otp={otp} setOtp={setOtp} handleSubmit={handleSubmit} handleContinue={handleContinue} handleReject={handleReject}
        />
        </div>
      }
    
    {
      viewReject && <RejectPaymentRequest showModal={viewReject} hideModal={setViewReject} handleReject={handleReject} reason={reason} setReason={setReason}/>
    }
    {
      viewSendReq && <SendPaymentRequest showModal={viewSendReq} hideModal={setViewSendReq} getTransactions={getTransactions}/>
    }
  
    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default PaymentRequest;
