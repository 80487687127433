import { ArrowBack } from '@mui/icons-material';
import React, { useState }   from 'react'
import { Button as MatButton } from "@mui/material";

import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpGet, httpGetPdf } from '../../../store/http';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import statementPDF from './statementPDF';
import { currencyFormat } from '../../../utils/helper';

const GenerateStatement = ({setShowStatement, wallets}) => {
    const [postData, setPostData] = useState([]);
    const [format, setFormat] = useState('csv');
    const [transactions, setTransactions] = useState([]);

    const getHistory = async (e) => {
        e.preventDefault();
        showLoader();
        const response = await httpGetPdf(
          `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/export/pdf/${postData?.account}?fromdate=${moment(postData?.fromdate).format('YYYY-MM-DD')}&todate=${moment(postData?.todate).format('YYYY-MM-DD')}`
        );
     const file = new Blob([response]);
    const fileURL = URL.createObjectURL(file);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = `Statement of Account.pdf`;
    alink.click();
    hideLoader();

      };

    return(
        <div>
            {!wallets?.length > 0 ?<ArrowBack onClick={()=>setShowStatement(false)}/>:''}
            <form  onSubmit={getHistory}>
      
      {!wallets?.length > 0 ? <div>      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Account Number</span>        
          <input type="text" aria-label="accountNo" class="form-control"
            onChange={(e)=>setPostData({...postData, account: e?.target?.value})}
          />
        </div>        
      </div> : 
        <div>
        <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Select Account</span>
            <select class="form-select" id="inputGroupSelect01" required
          onChange={(e)=>setPostData({...postData, account: e?.target?.value})}
        >
          <option selected>Choose...</option>
          {
                wallets?.map((e,i)=>
                    <option value={e.accountNo}>
                       {e?.description} - {e?.accountNo} - {e?.acct_name} - {currencyFormat(e?.clr_bal_amt)}
                    </option>
                )
            }
        </select>
        </div>
      </div>}
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setPostData({...postData, fromdate: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setPostData({...postData, todate: e?.target?.value})}
          />
        </div>
      </div>
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Get statement</button>
      </div>
      </form>
    
      {transactions?.length > 0 && 
      <>
      <div className="input-group mb-3 col mt-3">
          <span className="input-group-text">Download Format</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFormat(e?.target?.value)}
          >
          <option value='csv'>CSV</option>
          <option value='pdf' >PDF</option>
        </select>        
      </div>
      <div class="col-12 text-center">
      {format === 'csv'? (<MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <CSVLink
          data={transactions}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`account_statament.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD
        </CSVLink>
          </MatButton>):
          (<MatButton
            //className="update-button-1"
            onClick={() => statementPDF(transactions, postData)}
            variant="contained" 
            color="primary"
          >
            <div>DOWNLOAD</div>
          </MatButton>)}
      </div>
      </>}
        </div>
    )
}

export default GenerateStatement;