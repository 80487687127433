import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker } from 'antd';
import Wrapper from './style';
import { StepForwardOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { datePickerValueManager } from '@mui/x-date-pickers/DatePicker/shared';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../../store/httpCalls';
import { hideLoader, showLoader } from '../../../utils/loader';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';

const CreateAccount = ({ showModal, hideModal }) => {

  const { RangePicker } = DatePicker;

  const [fundingType, setFundingType] = useState('transferFromWayaOfficialAccount');
  const [fundingWallet, setFundingWallet] = useState('');
  const [fundingCard, setFundingCard] = useState('');
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [walletList, setWalletList] = useState([]);
  const [query, setQuery] = useState('');
 
  // let initial = {
  //   searchCategory,
  //   searchType,
  //   searchAccount,
  //   searchVendo,
  //   searchStatus,
  //   searchAmount,
  //   searchDate,

  // }

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }

  const handleSelectOption = (selectedUser) => {
    setData({ ...data, userId: selectedUser.userId, fullName: `${selectedUser.firstName} ${selectedUser.surname}`});
    setQuery(`${selectedUser.firstName} ${selectedUser.surname}`);
   /* setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);*/
    setOptions([]);
  };

  const handleSubmit = async(e) => {
   // return alert(JSON.stringify(data))
    showLoader();

    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/create-wallet`,
        data
    );
    if (response.status === true) {
        hideModal(false);
        Swal.fire('Success','Wallet Created Successfully', 'success');     
     hideLoader();
    } else {
        Swal.fire('Error', response.message, 'error');
      hideLoader();
    }

  }

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      width='50%'
    >
       
      <Wrapper>
        <div className="cw-content">
        <h3>
        Create Account
        </h3>
          <div className='col-md-8'  >
            <div className="input-content" style={{marginTop: 30}}>
              <div className="label2">Select User Type </div>
              <select value={data.stimulated} onChange={(e) => { setData({ ...data, simulated: e.target.value }) }} className='label2 border border-dark '>
              <option value=''>Select User Type</option>
                <option value='true'>Stimulated User</option>
                <option value='false'>Non Stimulated User</option>
              </select>
            </div>
            <div className="input-content" style={{marginTop: 30}}>
              <div className="label2">Select Account Owner</div>
              <input
            type='text'
            class='form-control'
            id='validationCustom01'
            required
            value = {query}
            onChange={handleSearch}
            placeholder='Search Account Owner'
          />
           <SearchDropdown options={options} handleClick={handleSelectOption} />

             
            </div>           
            </div>
        </div>
      </Wrapper>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyItems: 'center',
          justifyContent: 'center',
          marginTop: '50px',
        }}
      >
       
        <Button
          style={{
            background: '#FF4B01',
            color: '#fff',
            fontSize: '14px',
            alignItems: 'center',
            
          }}
            onClick={handleSubmit}
        >
          Create Wallet
        </Button>
      </div>
    </Modal>
  );
};

export default CreateAccount;
