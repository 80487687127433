import { Modal } from 'antd';
import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react';
import cameraImg from '../../assets/images/camera-alt-01.svg';
import eventIcon from '../../assets/images/eventIcon.png';
import validateImage from '../../utils/validateImage';
import Button from '../../components/Button/Button';
import { customBaseUrl, httpGet } from '../../store/http';

const BVNModal = (props) => {
  const [imageFile, setImageFile] = useState({});
  const {
    setBvnData,
    bvnData,
    showModal,
    hideModal,
    handleLinkBvn,
    name,
    setPostData,
    postData,
    loading,
    previewImage,
    setPreviewImage,
    setIdType,
    profile,
    bvnStat,
    handleBVNOTPResend,
    nibssUrl
  } = props;
  const [disable, setDisable] = useState(true);
  const [isBVN, setIsBVN] = useState(false);
  const handlePreview = (e) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setDisable(false);
      setImageFile(imageData);
      setPostData({ ...postData, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(imageData);
    } else {
      Swal.fire('Oops!', `${validFormat.message}`, 'error');
    }
  };

  const connectClick = (e) => {
   
    const title = `WINDOW TITLE`;
    console.log(nibssUrl[0]);
    console.log('http', nibssUrl);

    const url = nibssUrl;
    const popup = window.open(
      url,
      title,
      `width=${500},
      height=${400},
      left=${200},
      top=${200}`
    );
    //setExternalPopup(popup);
  };

  useEffect(() => {
    // connectClick();
    // hideModal(false);
  }, []);
  return (
    <Modal
      visible={showModal}    
      onCancel={() => {
        hideModal(false);
         setBvnData({})
      }
      }
      footer={null}
    >
         <h4 className="d-flex justify-content-center py-3" >
                Add BVN 
            </h4>
            {/*!bvnStat?<div>
           
                <label >BVN</label>
                <input
                        onChange={(e)=>setBvnData({...bvnData, bvn: e.target.value})}
                    value={bvnData.bvn}
                    className="d-flex justify-content-center"
                    style={{
                        width: 400,
                    }}
                />
                 <label >First Name</label>
                <input
                   onChange={(e)=>setBvnData({...bvnData, firstName: e.target.value})}
                   value={bvnData.firstName}
                    className="d-flex justify-content-center"
                    style={{
                        width: 400,
                    }}
                />
                 <label >Last Name</label>
                <input
                   onChange={(e)=>setBvnData({...bvnData, lastName: e.target.value})}
                    value={bvnData.lastName}
                    className="d-flex justify-content-center"
                    style={{
                        width: 400,
                    }}
                />
                 <label >DOB</label>
                <input
                   onChange={(e)=>setBvnData({...bvnData, dob: e.target.value})}
                    value={bvnData.dob}
                    className="d-flex justify-content-center"
                    style={{
                        width: 400,
                    }}
                />
                 <label >Phone Number</label>
                <input
                   onChange={(e)=>setBvnData({...bvnData, phoneNumber: e.target.value})}
                    value={bvnData.phoneNumber}
                    className="d-flex justify-content-center"
                    style={{
                        width: 400,
                    }}
                />
               

                <div className="d-flex justify-content-center py-5">
                    <button onClick={handleLinkBvn} type="button" class="btn btn-danger">Submit</button>
                </div>
            </div>:
            (<div>
                <div className="d-flex justify-content-center py-5">
                    <h4>BVN is linked</h4>
                </div>
            <button onClick={handleBVNOTPResend} type="button" class="btn btn-danger">Resend OTP</button>

            </div>)
            */}
            <label >BVN</label>
                <input
                        onChange={(e)=>setBvnData({...bvnData, bvn: e.target.value})}
                    value={bvnData.bvn}
                    className="d-flex justify-content-center"
                    style={{
                        width: 400,
                    }}
                />
                <div className="d-flex justify-content-center py-5">
                    <button onClick={handleLinkBvn} type="button" class="btn btn-danger">Submit</button>
                </div>
    
    </Modal>
  );
};

export default BVNModal;
