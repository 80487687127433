import React, { useState } from "react";
import { Modal, Button, DatePicker } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import moment from "moment";
import Swal from "sweetalert2";

const initial ={
  type: 'corporate',
  number: 0
}
const CreateSMU = ({ showModal, hideModal}) => {
  const [data, setData] = useState(initial);

const handleCreate = async (e) => {
    e.preventDefault();
  showLoader();
  const response = await httpPost(
    `${customBaseUrl.smAuthUrl}/api/v1/sUser/users/generate`,
    data
  );
  if (response.status === true) {
    Swal.fire('Done', response?.message);
    NotificationManager.success(response?.message)
    hideLoader();
    hideModal(false);
  } else {
    NotificationManager.error(response?.message)
    hideLoader();
  }
};

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <form onSubmit={handleCreate} >
        <div className="title">CREATE NEW SM USERS</div>
        <div className="cw-content">
          <div className="input-content border-dark">
            <div className="label">USER TYPE</div>
            <select 
            value={data?.type}
            onChange={(e)=>setData({...data, type: e?.target?.value})}
            required name="" id="" className="border border-dark">
              <option value="corporate">BUSINESS</option>
              <option value="user">INDIVIDUAL</option>
            </select>
          </div>
          <div className="input-content border-dark">
            <div className="label">NUMBER OF USERS</div>
            <input type='number' min={0} value={data?.number} onChange={(e)=>setData({...data, number: e?.target?.value})} className="form-input" required/>
          </div>
        </div>
        <div className="text-center">
            <button className="btn"
                style={{
                    marginTop: '50px',
                    marginLeft: "auto",
                    marginRight: "auto",
                    background: "#FF4B01",
                    color: "#fff",
                    borderRadius: "3px",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 25px",
                  }}
            >
                CREATE NOW
            </button>
        </div>
        </form>
      </Wrapper>
    </Modal>
  );
};

export default CreateSMU;
