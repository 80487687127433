import React from "react";
import { InputNumber, Row, Col, AutoComplete, Button } from 'antd';

const GenerateUsers = ({setVisible}) => {

    
    const items = [
        {
            value: 'Personal',
        },
        {
            value: 'Corporate',
        },
    ];


    return (
        <div>
            <div className="d-flex justify-content-center">
                <h5>Generate Users for Simulation</h5>
            </div>
            <div>
                <Row className="my-5">
                    <Col span={12}>
                    <label>Numbers users to Generate</label>
                        <InputNumber
                            style={{
                                width: 320,
                            }}
                        />
                    </Col>
                    
                </Row>
                <Row>
                    <Col span={12}>
                        <label>User Type</label>
                        <AutoComplete
                            style={{
                                width: 320,
                            }}
                            options={items}
                            filterOption={(inputValue, item) =>
                                item.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-end">
                    <Button
                        style={{ background: "#FF4B01", fontSize: "15px", color: "#fff" }}>
                        Generate Users
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default GenerateUsers