import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { GetSvgIcon } from '../../../components/svg';
import { BusinessTypeWrapper as Wrapper } from './style';
import AntTable from '../../../components/CustomTable/AntTable';
import Layout from '../../../components/Layout';
import { customBaseUrl, httpDelete, httpGet } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import CreateBusinessType from '../../../components/Modal/Kyc/CreateBusinessType';

const Index = () => {
  const [businessTypes, setBusinessTypes] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    {
      title: 'BUSINESS TYPE',
      dataIndex: 'businessType',
    },
    {
      title: 'DATE CREATED',
      dataIndex: 'createdAt',
    },
    {
      title: '',
      dataIndex: 'products',
    },
  ];

  const handleGetBusiness = async () => {
    setLoading(true);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=40`
    );
    // if (res.status) {
    //   setBusinessTypes(res.data);
    // }
    setBusinessTypes(res.businessTypeList);
    setLoading(false);
    setPagination(pagination);
  };

  const handleDeleteBusiness = async (id) => {
    showLoader();
    const res = await httpDelete(
      `${customBaseUrl.authUrl}/api/v1/business/type/delete/${id}`
    );
    if (res.status === 200) {
      Swal.fire(res.message);
    }
    hideLoader(false);
  };

  useEffect(() => {
    handleGetBusiness();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Wrapper>
        <>
          {' '}
          <div className='pricingHeader'>
            <div className='firstSide'>
              <div className='filter'>
                <GetSvgIcon type='filter' />
                <div>Search by ....</div>
              </div>
              <div className='searchCont'>
                <GetSvgIcon type='search' className='icon' />
                <input
                  type='text'
                  placeholder='Search Users by Name, Email or Date'
                />
              </div>
            </div>
            <div className='btnCont' onClick={() => setShowModal(true)}>
              <GetSvgIcon type='edit-alt' />
              <div>Create New Business Type</div>
            </div>
          </div>
          <div className='pricingTable'>
            <AntTable
              pagination={pagination}
              users={businessTypes}
              loading={loading}
              handleGetNext={handleGetBusiness}
              columns={columns}
            />
          </div>
        </>
      </Wrapper>
      {showModal ? (
        <CreateBusinessType showModal={showModal} hideModal={setShowModal} />
      ) : (
        ''
      )}
    </Layout>
  );
};

export default Index;
