import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { Modal } from 'antd';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../../store/httpCalls';

const Invite = ({ showModal, hideModal, className, roles, corporateId,getAllTeamMembers}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [roleId, setRoleId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')

    const [normalUserId, setNormalUserId] = useState('')
  
 

  const handleAssign = async() => {
  let res;
  let d = new Date();

  let data ={
    "corporateUserId": corporateId,
     normalUserId,
     roleId,
     "email": email,
     "inviteeFullName": fullName,
     "phone": phoneNumber,
  }
  showLoader();
   res = await httpPost(`${customBaseUrl.roleUrl}/api/v1/user-access/admin/invite/corporate`, data)

      if(res.status === false){
        hideLoader()
        console.log(res)
        Swal.fire('Failed', res.message, 'Error')
      
      }else{
        hideLoader();
        Swal.fire("Done", 'New team member added', "Done")
        getAllTeamMembers()
      hideModal(false)
      }

  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}

    >
        <div style={{textAlign:'center '}}>
          <h5>Invite Team Member</h5>
          <div>
            Please enter email to invite team members to have access to your dashboard
          </div>
        </div>

        <div style={{ position: 'relative' }} class="col-md-12 mt-4">
          <label for='validationCustom01' class='form-label'>
            Email Addres
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            value={email}
            onChange = {(e)=>setEmail(e.target.value)}
            required
          />
        </div>

        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom02' class='form-label'>
            Full Name
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom02'
            onChange = {(e)=>setFullName(e.target.value)}
            value={fullName}
            required
          />
        </div>


        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom02' class='form-label'>
            Phone Number
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom02'
            onChange = {(e)=>setPhoneNumber(e.target.value)}
            value={phoneNumber}
            required
          />
        </div>
        <div style={{ position: 'relative' }} class="col-md-12">
          <label for='validationCustom03' class='form-label'>
            Select Role
          </label>
          <select value={roleId} onChange={(e)=>setRoleId(e.target.value)}   class='form-control border'
            id='validationCustom03'>
            <option value="">Select Role</option>
            {roles?.map((e,i)=>
                <option value={e?.id}>{(e?.name)?.replace(/_/g, ' ')}</option>
            )}
          </select>
       
        </div>

        <div class='col-12  mt-5'>
          <button class='btn btn-danger col-12'  type='submit' onClick={handleAssign}>
            Invite Team Member
          </button>
        </div>

                   
        
    </Modal>
  );
};

export default Invite;
