import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import NaijaStates from 'naija-state-local-government';
import '../../../index.scss';
import { customBaseUrl, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const CreateCardType = ({ showModal, hideModal, title, className, getCardTypes }) => {
  const [data, setData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    let res = await httpPost(`${customBaseUrl.cardUrl}/admin/create-card/${data?.type}`)

    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      hideModal(false);
      getCardTypes();
    } else {
      Swal.fire('Error', res?.message, 'error');
    }
  };

 
  const states = NaijaStates.states();

  return (
    <Modal
      className={className}
      title='Create Card Type'
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <form class='row g-3 needs-validation' novalidate>
        <div className='col-10 mt-2 mb-3 mx-auto'>
          <div class='input-group'>
          <label class='input-group-text' for='inputGroupFile02'>
              Type
            </label>
            <input
              type='text'
              class='form-control'
              id='inputGroupFile02'
              required
              value={data?.name}
              onChange={(e) => setData({ ...data, type: e.target.value })}
            />
           
          </div>
        </div>

        <div class='col-12 text-center mt-2'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateCardType;
