import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, DatePicker, Modal } from "antd";
import "../../../index.scss";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Divider, Pagination, Typography } from "@mui/material";
import { Stack } from "react-bootstrap";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

const initialState = {
  email: "",
  businessType: "",
  orgEmail: "",
  orgName: "",
  orgPhone: "",
  officeAddress: "",
  state: "",
  city: "",
  firstName: "",
  surname: "",
  dateOfBirth: "",
  phoneNumber: "",
  password: "",
  gender: "",
  referenceCode: "",
  admin: true,
};
const CreateBulkForm = ({ showModal, hideModal, title,userType, className }) => {
  const [data, setData] = useState([{ ...initialState }]);
  const [user, setUser] = useState(initialState);
  const [page, setPage] = useState(1);
  const [showUserType, setShowUserType] = useState(false);
  const [businessTypes,setBusinessTypes] = useState([]);
  const handleSubmit = async (e) => {
    let res =  {};
    showLoader();
    if (userType === "individual") {
        
      res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/sUser/users/bulk-user/private`,
        {
            usersList:data
        });
    } else if (userType === "corporate") {
      res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/sUser/users/bulk-user/corporate`, 
        {
            usersList:data
        });
    } else {
      //this should not actually happen but we validate anyways
      hideLoader();
      Swal.fire({
        title: "Error",
        text: "Please select a user type",
        icon: "error",
      });
    }

    
    if (res.status) {
      hideLoader();
      Swal.fire("Done", res.data, "success");
      hideModal(false);
    } else {
      console.log(res);
      hideLoader();
      Swal.fire("Error", res.message, "error");
    }
  };
  const clearState = () => {
    setUser({ ...initialState });
  };
  const handleChange = (event, value) => {
    const isEmpty = checkProperties(user);
    if (isEmpty) {
      Swal.fire("Warning", "Please fill all the fields", "warning");
    } else {
      console.log(user);
      event.preventDefault();
      setData((prevState) => [user, ...prevState]);
      setPage(data.length + 1);
      clearState();
    }
  };

  const handlePaginateChange = (event, pageV) => {
    const currentIndex = pageV - 1;
    setPage(pageV);
    setUser(data.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(data);
  };

  useEffect(() => {
    console.log(data);
    let dataRec = data;
    dataRec[page - 1] = user;
    setData(dataRec);
  }, [user]);
  
  const getBusinessTypes = async () =>{
    let response = await httpGet(`${customBaseUrl.authUrl}/api/v1/business/type/find/all`);
    // console.log(response);
    setBusinessTypes(response.businessTypeList);
  }

  useEffect( ()=>{
    getBusinessTypes();
  },[])
  const handleRemoveUser = async () => {
    let current = page === 1 ? 1 : page - 1;
    await setData((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...initialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };


  return (
    <>
   
      <Modal
        className={className}
        title={title}
        centered
        visible={showModal}
        onOk={() => hideModal(false)}
        onCancel={() => hideModal(false)}
        width={1000}
        footer={0}
      >
        {/* <Divider>
        <div style={{ marginBottom: "20px" }}>
          <Typography>Page: {page}</Typography>
        </div>
      </Divider> */}
        <form class="row g-3 needs-validation" >
          <div class="col-md-6">
            <label
              style={{ marginRight: "20px" }}
              for="validationCustom04"
              class="form-label"
            >
              <b> Account type</b>
            </label>
            <label>{userType}</label>
            <div class="invalid-feedback">Account type is required.</div>
          </div>
          {userType === "corporate" ? (
            <>
              <div class="col-md-6">
                <label for="validationCustom04" class="form-label">
                  Business type
                </label>
                <select
                  class="form-select"
                  id="validationCustom04"
                  required
                  value={user?.businessType}
                  onChange={(e) =>
                    setUser({ ...user, businessType: e.target.value })
                  }
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  {businessTypes.length !== 0? businessTypes.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.businessType}
                    </option>
                  )):<></>}
                </select>
                <div class="invalid-feedback">Business type is required.</div>
              </div>

              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  Organization Email
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="validationCustom02"
                  value={user.orgEmail}
                  required
                  onChange={(e) =>
                    setUser({ ...user, orgEmail: e.target.value })
                  }
                />
                <div class="invalid-feedback">
                  Organization email is required.
                </div>
              </div>

              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  Organization Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  value={user.orgName}
                  required
                  onChange={(e) =>
                    setUser({ ...user, orgName: e.target.value })
                  }
                />
                <div class="invalid-feedback">
                  Organization name is required.
                </div>
              </div>

              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  Organization Phone
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  value={user.orgPhone}
                  required
                  onChange={(e) =>
                    setUser({ ...user, orgPhone: e.target.value })
                  }
                />
                <div class="invalid-feedback">
                  Organization phone is required.
                </div>
              </div>

              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  Office address
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  value={user.officeAddress}
                  required
                  onChange={(e) =>
                    setUser({ ...user, officeAddress: e.target.value })
                  }
                />
                <div class="invalid-feedback">Office address is required.</div>
              </div>
              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  State
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  value={user.state}
                  required
                  onChange={(e) => setUser({ ...user, state: e.target.value })}
                />
                <div class="invalid-feedback">State is required.</div>
              </div>
              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  City
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="validationCustom02"
                  value={user.city}
                  required
                  onChange={(e) => setUser({ ...user, city: e.target.value })}
                />
                <div class="invalid-feedback">City is required.</div>
              </div>
            </>
          ) : (
            ""
          )}
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Email Address
            </label>
            <input
              type="email"
              class="form-control"
              id="validationCustom02"
              value={user.email}
              required
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <div class="invalid-feedback">Email is required.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom01" class="form-label">
              First name
            </label>
            <input
              type="text"
              class="form-control"
              id="validationCustom01"
              value={user.firstName}
              required
              onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            />
            <div class="invalid-feedback">Please choose a first name.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Last name
            </label>
            <input
              type="text"
              class="form-control"
              id="validationCustom02"
              value={user.surname}
              required
              onChange={(e) => setUser({ ...user, surname: e.target.value })}
            />
            <div class="invalid-feedback">Please choose a last name.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Date of birth
            </label>
            <DatePicker
              value={
                user.dateOfBirth ? moment(user.dateOfBirth, "YYYY-MM-DD") : null
              }
              className="form-control"
              id="validationCustom02"
              onChange={(e, str) =>
                setUser({ ...user, dateOfBirth: e.format("YYYY-MM-DD") })
              }
            />
            <div class="invalid-feedback">Date of birth is required.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom04" class="form-label">
              Gender
            </label>
            <select
              class="form-select"
              id="validationCustom04"
              required
              value={user.gender}
              onChange={(e) => setUser({ ...user, gender: e.target.value })}
            >
              <option selected disabled value="">
                Choose...
              </option>
              {[
                { label: "Male", value: "MALE" },
                { label: "Female", value: "FEMALE" },
              ].map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div class="invalid-feedback">Gender is required.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Phone Number
            </label>
            <input
              type="text"
              class="form-control"
              id="validationCustom02"
              value={user.phoneNumber}
              required
              onChange={(e) =>
                setUser({ ...user, phoneNumber: e.target.value })
              }
            />
            <div class="invalid-feedback">Phone number is required.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Password
            </label>
            <input
              type="password"
              class="form-control"
              id="validationCustom02"
              value={user.password}
              required
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <div class="invalid-feedback">Password is required.</div>
          </div>
          <div class="col-md-6">
            <label for="validationCustom02" class="form-label">
              Referal Code
            </label>
            <input
              type="text"
              class="form-control"
              id="validationCustom02"
              value={user.referenceCode}
              onChange={(e) =>
                setUser({ ...user, referenceCode: e.target.value })
              }
            />
          </div>
          <div style={{ marginTop: "10px" }} className="row">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "200px",
                gap: "10px",
              }}
              className="col-md-3"
            >
              <Button
                className="iconUI"
                style={{ color: "#C4C4C4", border: "1px solid #C4C4C4" }}
                onClick={handleChange}
                icon={<PlusCircleOutlined />}
              >
                Add More User
              </Button>
            </div>
            <div className="col-md-3">
              <Button
                className="iconUI"
                onClick={handleRemoveUser}
                style={{ color: "#FF4B01", border: "1px solid #FF4B01" }}
                icon={<CloseOutlined />}
              >
                Remove User
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="iconUI"
                type="ghost"
                onClick={handleSubmit}
                style={{ color: "#fff", background: "#FF4B01", float: "right" }}
              >
                Create Users
              </Button>
            </div>
          </div>

          <div className="row" style={{ marginTop: "7px" }}>
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <Stack spacing={2}>
                <Pagination
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    alignSelf: "center",
                  }}
                  count={data.length}
                  page={page}
                  onChange={handlePaginateChange}
                />
              </Stack>
            </div>
            <div className="col-md-3"></div>
          </div>

          {/* <div class="col-12 text-center mt-5">
          <button class="btn btn-primary" type="submit" onClick={handleSubmit}>
            Submit form
          </button>
        </div> */}
        </form>
      </Modal>
    </>
  );
};
export default CreateBulkForm;
