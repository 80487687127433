import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import NaijaStates from 'naija-state-local-government';
import '../../../index.scss';
import { customBaseUrl, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';

const DeliveryFee = ({ showModal, hideModal, title, className, getDeliveryFee }) => {
  const [data, setData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    let res = await httpPost(`${customBaseUrl.cardUrl}/admin/create-delivery-fee/?city=${data?.city}&fee=${data?.fee}&state=${data?.state}`)

    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      hideModal(false);
      getDeliveryFee();
    } else {
      Swal.fire('Error', res?.message, 'error');
    }
  };

 
  const states = NaijaStates.states();

  return (
    <Modal
      className={className}
      title='Create Branch'
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <form class='row g-3 needs-validation' novalidate>

        <div className='col-10 mt-2 mb-3 mx-auto'>
          <div class='input-group'>
          <label class='input-group-text' for='inputGroupFile02'>
              City
            </label>
            <input
              type='text'
              required
              value={data?.city}
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setData({ ...data, city: e.target.value })}
            />
          </div>
        </div>

        <div className='col-10 mt-2 mb-3 mx-auto'>
          <div class='input-group'>
          <label class='input-group-text' for='inputGroupFile02'>
              State
            </label>
            <select
              aria-label="select state"
              className="form-select border"
              required
              value={data?.state}
              id="stateSelect"
              onChange={(e) => setData({ ...data, state: e.target.value })}
            >
              <option value="">select state</option>
              {states?.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-10 mt-2 mb-3 mx-auto'>
          <div class='input-group'>
          <label class='input-group-text' for='inputGroupFile02'>
              Fee
            </label>
            <input
              type='number'
              required
              value={data?.fee}
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setData({ ...data, fee: e.target.value })}
            />
           
          </div>
        </div>

        <div class='col-12 text-center mt-2'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeliveryFee;
