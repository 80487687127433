import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { DatePicker, Modal } from 'antd';
import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';

const CreateNewPayoutDate = ({ showModal, hideModal,newDayCount, setNewDayCount, setViewEditDate, viewEditDate, record, className}) => {

    const [ newDate, setDate] = useState('')
    const [userType, setUserType] = useState('')


    const handleCreateNew = async () =>{
        showLoader();
        const data ={
            "payoutDate": newDate,
            "userType": userType
        }
        
       // alert(new Date(newDate))
       const res = await httpPost(`${customBaseUrl.commissionUrl}/api/v1/admin/manage-payout-date`,
       data
       )

       if(res?.status){
        hideLoader()
        hideModal(false)
        Swal.fire('Done', res.message)
       }else{
        hideLoader();
        Swal.fire('error',res.message)
       }
    }

    const handleUpdate = async () =>{
        showLoader();
        const data ={
            "payoutDate": newDate
        }
        
       // alert(new Date(newDate))
       const res = await httpPut(`${customBaseUrl.commissionUrl}/api/v1/admin/update-payout-date-by-id/${record?.id}`,
       data
       )
        console.log('dat',res)
       if(res?.status){
        hideLoader()
        hideModal(false)
        Swal.fire('Done', res.message)
       }else{
        hideLoader();
        Swal.fire('error',res.message)
       }
    }
  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}
    >
        <div class='col-12 text-center font-weight-bold'>
        </div>
       
       
       {!record?.payoutDate? <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>
          <label for='validationCustom05' class='form-label'>
            Select User Type 
          </label>
          <div class='input-content mb-2'>
          <select className='border border-dark rounded' value={userType} onChange={(e)=>setUserType(e.target.value)}>
            <option value='ROLE_USER'>ROLE_USER</option>
            <option value ='ROLE_CORP_ADMIN'>ROLE_CORP_ADMIN</option>
          </select>
          </div>
          <div class='input-content mb-2'>
          <DatePicker   onChange={(e)=>{ setDate(e)}  } />
          </div>
          <button class='btn btn-primary' type='submit' onClick={handleCreateNew}>
            Save
          </button>        
        </div>:
        <div class='col-md-12' style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}>       
         
         <div class='input-content mb-2'>
         <p>
            <label> Previous Date</label>
            </p>
            <input class='input-content' disabled value={`${new Date(record?.payoutDate).getDate()} - ${new Date(record?.payoutDate).getMonth()}-${new Date(record?.payoutDate).getFullYear()}`}/>
        </div>
       
        <div class='input-content mb-2'>
        <p>
        <label> Select New Date</label>
        </p>
        <DatePicker   onChange={(e)=>{ setDate(e)}  } />
        </div>
        <button class='btn btn-primary' type='submit' onClick={handleUpdate}>
          Update
        </button>  
        </div>}
     
    </Modal>
  );
};

export default CreateNewPayoutDate;
