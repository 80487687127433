import React from "react";
import { InputNumber, Row, Col, AutoComplete, Button, DatePicker } from 'antd';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const { RangePicker } = DatePicker;


const DebitUsers = ({ setDebitVisible }) => {


    const items = [
        {
            value: 'Personal',
        },
        {
            value: 'Corporate',
        },
    ];


    return (
        <div>
            <div className="d-flex justify-content-center">
                <h5>Debit Simulated users</h5>
            </div>
            <div>
                <Row >
                    <Col span={12}>
                        <p> Users Role</p>
                        
                        <AutoComplete
                            style={{
                                width: 320,
                            }}
                            options={items}
                            filterOption={(inputValue, item) =>
                                item.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <p> Amount</p>
                        <InputNumber
                            style={{
                                width: 320,
                            }}
                        />

                    </Col>

                </Row>
                <Row className="my-5">
                    <Col span={12}>
                        <p>Date Range</p>
                        <RangePicker
                            style={{
                                width: 320,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <p>Value Date</p>
                        <DatePicker
                            style={{
                                width: 320,
                            }}
                            format={dateFormatList}
                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-end">
                    <Button
                        style={{ background: "#FF4B01", fontSize: "15px", color: "#fff" }}>
                        Debit Users
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default DebitUsers