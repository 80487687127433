import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row,} from "antd";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpGet, httpPost, httpPut } from "../../../store/http";
import { Grid, Switch } from "@mui/material";
import { useMatStyles } from "./style";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import ExportData from "./exportData";
import { Add, ArrowBack, FilterList, Settings } from "@mui/icons-material";
import ReferralSettings from "./settings";
import AssignCode from "./assignCode";
import RefferalWebForm from "./webForm";
import UploadExcel from "./excelUpload";
import SingleUserBonus from "./singleUserBonus";
import { CSVLink } from "react-csv";
import RefTypeModal from "./newTypeModal";
import EditTypeModal from "./EditTypeModal";
import Swal from "sweetalert2";

const ReferralTypes = ({setShowRefTypes}) => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showAssignCode, setShowAssignCode ] = useState(false)
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "referralUser",
    searchText: "",
  });
  const [allTransfers, setAllTransfers] = useState(0);
  const [allReferrals, setAllreferrals] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [showEditType, setShowEditType] = useState(false);
  const [reversalDays, setReveversalDays] = useState([]);
  const [filterValue, setFilterValue] = useState(false);
  const [showMultiple, setShowMuliple] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
const [selectedRow, setSelectRow ] = useState([]);
  const [ showSendSingle, setShowSendSingle] = useState(false);
  const [allFilteredreferrals,setAllFilteredreferrals] = useState([]);
  const [fileteredMeta, setFilteredMeta] = useState(0);
  const [stat, setStat] = useState([]);
  const [searchField, setSearchField] = useState(false);
  const [field, setField] = useState('all');
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState('all');

  const getReferralTypes = async (page, size) => {
    setAllreferrals([]);
    showLoader();
    const pages = page ?? pagination.current;
    const limit = size ? size : pagination.pageSize;
    console.log('pages',pages)
    let request = await httpPost(
      `${customBaseUrl.referralUrl}/api/v1/referral_type/view_report`,
      {
        pageNo: pages,
        pageSize: limit,
      }
    );
      console.log(request.data);
    if (request.status) {
      console.log('dtaa',request.data.content);
      setAllreferrals(request.data.content);
      setAllTransfers(request.data.totalElements);
    }
    hideLoader();
  };
  const getSearchRef = async (e, page, size) => {
    e.preventDefault()
    setAllreferrals([]);
    showLoader();
    const pages = page ?? pagination.current;
    const limit = size ? size : pagination.pageSize;
    console.log('pages',pages)
    let request = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/admin/fetchAllReferralUsers?field=${field}&pageNo=${pages+1}&pageSize=${limit}&value=${value}`
    );
      console.log(request.data);
    if (request.status) {
      console.log('dtaa',request.data.content);
      setAllreferrals(request.data.content);
      setAllTransfers(request.data.totalElements);
    }
    hideLoader();
  };

  const getTemporalReversalDays = async () => {
    
    let request = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/config`
    );
      console.log(request.data);
    if (request.status) {
      setReveversalDays(request.data);
    }
    hideLoader();
  };


  const getStat = async () => {
    
    let request = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/admin/analysis`
    );
      console.log(request.data);
    if (request.status) {
      setStat(request?.data);
    }
    hideLoader();
  };

  const handleToggler = async(row, status) =>{
    showLoader();

    let res= await httpPut(`${customBaseUrl.referralUrl}/api/v1/referral_type/update_referral_type/${row?.referralTypeId}/${status}`,
    {}
  )

    if(res.status){
        hideLoader()
        getReferralTypes()
        Swal.fire('Success', res.message,'Done')
    }else{
        console.log('err',res)
        hideLoader();
        Swal.fire('failed', res.message,'Error')

    }


}

  useEffect(() => {
    getStat();
   getReferralTypes();
    // getTemporalReversalDays();
}, []);



const getFilteredReferral = async (page, size) => {
  setFilterValue(true)
  setAllFilteredreferrals([]);
  showLoader();
  const pages = page==0 ? page : pagination.current;
  const limit = size ? size : pagination.pageSize;
  
  let request = await httpGet(
    `${customBaseUrl.referralUrl}/api/v1/admin/filter-users-with-referrals?page=${pages}&size=${limit}&value=${search?.searchText}`
  );
    console.log(request.data);
  if (request.status) {
    console.log('dtaa',request.data.users);
    setAllFilteredreferrals(request.data.users);
    setFilteredMeta(request.data.totalItems);
  }
  hideLoader();
};
 

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getReferralTypes(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getReferralTypes(newPage, pagination.pageSize);
  };

  const handleDropdownAction = (item, row) => {
    setSelectRow(row)
    if (item === "Edit") setShowEditType(true);
    // if (item === "Set as Default") ViewTransfer(row);
  };

  var searchInput = useRef({});
  const columns =  React.useMemo(() =>  [
    
    { field: 'referralTypeId',
      headerName: 'ID',
      width: 200 ,
      headerClassName: styles.tableHeader,

    },
    { field: 'referralDescription',
      headerName: 'Referral Description',
      width: 200 ,
      headerClassName: styles.tableHeader,
    },
    { field: 'accountTypeEnum',
      headerName: 'Account Type',
      width: 200 ,
      headerClassName: styles.tableHeader,

    },
    { field: 'defaultType',
      headerName: 'Is Default',
      width: 200 ,
      headerClassName: styles.tableHeader,
      renderCell: ({row}) =><Switch
      checked={row?.defaultType}
      onChange={(e)=>{
        console.log({ dddd: e.target.checked})
        handleToggler(row, e.target.checked)
      }}
      name="defaultType"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
    },
       
    { field: 'numberOfTransactionRequired',
    headerName: 'Is Number of Tran Count Required',
    width: 250 ,
    headerClassName: styles.tableHeader,
    renderCell: ({row}) => row?.numberOfTransactionRequired ? <div style={{color:'green'}}>TRUE</div> : <div style={{color:'red'}}>FALSE</div>


  },
  { field: 'numberCount',
    headerName: "Number of Count",
    width: 200,
    headerClassName: styles.tableHeader,

    },
    
  { field: 'dateCreated',
    headerName: "Date Created",
    width: 200,
    headerClassName: styles.tableHeader,

    },

    { field: 'dateModified',
    headerName: 'Date Modified',
    width: 250 ,
    headerClassName: styles.tableHeader,
    renderCell: ({row}) => row?.referral ? row?.referral.createdAt : 'N/A'


  },
 
   
    { field: 'bonus',
    headerName: "Action ",
    width: 150,
    headerClassName: styles.tableHeader,
    renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={ [ "Edit"]}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },

  ]);

  const filterOptions = [
    "Transactions Done After Referral",
    "Bonus Earned by Users"
  ];
  const handleFilterDropdown = (item) => {
    if (item === "Transactions Done After Referral") navigate('/referral/no_of_transactions');
    if (item === "Bonus Earned by Users") navigate('/referral/bonus_earned');

    //if (item === "Send Using Webforms") setShowTransferForms(true);
  };

  const bulkOptions = [
    "Multiple Web",
    "Excel Upload"
  ];
  const handleBulkDropdown = (item) => {

    if (item === "Multiple Web") setShowMuliple(true);
    if (item === "Excel Upload") setShowExcel(true);

    //if (item === "Send Using Webforms") setShowTransferForms(true);
  };

  const exportData = allReferrals.map((el) => {
      return {
        "Referrer Referral Code": el?.referenceCode,
        "Referral Referral Code": el?.referral ? el?.referral.referralCode : '',
        "Referrer Name": el.name,
        "Referral Name": el?.referral ? el?.referral.name : '',
        "Referrer Phone": el?.phoneNumber?.toString(),
        "Referrer Email": el.email,
        "Referral Phone": el?.referral ? el?.referral.phoneNumber?.toString() : '',
        "referrer Account Creation Date": el.createdAt,
        "referral Account Creation Date": el?.referral ? el?.referral.createdAt : '',
        "Status": el.active,
        'Account Type': el?.corporate ? 'Corporate' : 'Personal',
      };
})
const category = ['all', 'name', 'email', 'phoneNumber','referenceCode']

  return (
    <>

<div style={tool}>
      
        <div>
            <ArrowBack
              onClick={()=>{setShowRefTypes(false)}}
            />
          {/* </AntDropdown> */}
      </div>

        <Row type="flex" justify="end" align="middle">
      
            <Button
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <CSVLink
          data={exportData}
          style={{            
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`Referrals.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </Button> 
                  
          <Button
              onClick={() => setShowEditDays(true)}
              size="medium"
              style={{  fontSize: "14px",  }}
            >
              <Row>
              <Add/>
              Add Referral Type
              </Row>  
            </Button>    
        </Row>
      </div>

      <div>
      
      <Grid
      getRowId ={row=>row.referralTypeId}
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allReferrals?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
        getRowId={row=>row.referralTypeId}
         className={styles.table}
         density="comfortable"
        rows={allReferrals }
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={ allTransfers}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}
      </div>
      
    
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allReferrals}  />
      }
      {
        showEditDays && 
        <RefTypeModal getReferralTypes={getReferralTypes} showModal={showEditDays} hideModal={setShowEditDays} title="Create Referal Type" className={styles.modal} />
      }
      {
        showEditType && 
        <EditTypeModal typeData={selectedRow} getReferralTypes={getReferralTypes} showModal={showEditType} hideModal={setShowEditType} title="Create Referal Type" className={styles.modal} />
      }
    </>
  );
};

const gridStyle = {
  width: "40%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "50px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default ReferralTypes;