import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import wayabankLogo from "../../../assets/images/logo.svg";
import React, { useEffect } from "react";
import { Button } from "antd";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import { Print, Save } from "@mui/icons-material";

function setPageSize(cssPageSize) {
  const style = document.createElement('style');
  style.innerHTML = `@page {size: ${cssPageSize}; margin: 0}`;
  style.id = 'page-orientation';
  document.head.appendChild(style);
}


const ReverseBillPayment = ({ transaction,wallets,reverseData,handleReverse, setReverseData, setShowModal, showModal,orientation = 'landscape' }) => {
  transaction = Object.fromEntries(
    Object.entries(transaction).filter(
      ([_, v]) => v != null && v != undefined && v != ""
    )
  );

  useEffect(() => {
    setPageSize(orientation);
    console.log('tran',transaction)

    return () => {
        const child = document.getElementById('page-orientation');
        child.parentNode.removeChild(child);
    };
}, [orientation]);
  return (
    <>
      <Dialog  maxWidth="md" open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle id="alert-dialog-title">
          <div className="row">
              <div className="col-md-10">{"Reverse Transaction"}</div>
            <div className="col-md-2">
                <Button icon={<CloseOutlined />} onClick={() => setShowModal(false)}></Button>
            </div>
          </div>

          <div id="logo" style={{ textAlign: "center" }}></div>
        </DialogTitle>
        <DialogContent>
            <div>
                <label>Select Official Account</label>
                <select
            class='form-select border'
            id='validationCustom07'
            required
            onChange={(e)=>setReverseData({...reverseData, officeDebitAccount: e.target.value}) }
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { wallets?.length>0 &&
            wallets?.map((option) => (
              <option key={option.accountNo} value={option?.accountNo}>
                {option?.acct_name +' - '+option?.accountNo}
              </option>
            ))}
          </select>
            </div>
        </DialogContent>
        <div style={{flexDirection:'row', marginLeft: '40%'}}>
         <Button className="btn btn-primary mb-2"  onClick={handleReverse}>Reverse</Button>
      
        </div>

      </Dialog>
    </>
  );
};
export default ReverseBillPayment;
