import styled from 'styled-components';

export const CommingSoonWrapper = styled.div`
.body { margin: 0; padding: 0; }
html { height: 100%; cursor: default; }
.body {
    font-family: "Inter", sans-serif; /* Default font */
    width: 100%; height: 100%;
    text-align: center;
    display: table;
    opacity: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 200px;
    animation: fadein 3s forwards;
    
}
h2 {
    font-weight: 300;
    margin-bottom: 25px;
}
img {
    width: 50%; /* Image width */
    border: 0; /* For IE */
    /* Aspect ratio of image, gives initial dimension to prevent text moving down once image is loaded */
    /* When changing logo, change this also */
    aspect-ratio: 125 / 50;
}
#main {
    vertical-align: middle;
    display: table-cell;
    
}
/* Progress bar */
#progress {
    width: 60%; /* Bar width */
    margin: 0 auto;
    border-radius: 10px; /* Roundness */
    background-color: #a8a8a8; /* Background colour */
    padding: 7px 5px;
    /* border: 5px solid #a8a8a8; Border */
}
#fill {
    padding: 2px 0;
    background-color: #ff6700; /* Fill colour */
    width: 35%; /* Bar percentage, change text below also */
    border-radius: 10px; /* Roundness */
    animation: proanimate 2s;
}
#barpercent h4 {
    color: white; /* Bar percentage text colour */
    margin: 0; padding: 0;
    opacity: 1;
    animation: fadein 3s forwards;
}

/* Fades in animation */
@keyframes fadein  {
    from { opacity: 0; }
}
/* Animates bar on page load */
@keyframes proanimate {
    from { width: 0%; }
}

@media screen and (max-width:600px) {
    img {
        width: 60%;
    }
    h1 {
        font-size: 1.3em;
    }
    h2 {
        margin: 1.1em;
        font-size: 1.1em;
    }
    #progress {
        width: 80%; /* Bar width */
    }
}`;