import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    mTitle: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#000000',
      marginBottom: '62px',
    },
    btnCont: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    bcItem: {
      background: '#FF4B01',
      borderRadius: '3px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
      padding: '16px 23px 14px 23px',
      width: '48.5%',
      marginBottom: '16px',
    },
    soWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    soLabel: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
      color: '#000000',
      marginBottom: '9px',
    },
    soInput: {
      background: ' #FFFFFF',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      borderRadius: '5px',
      padding: '12px',
      marginBottom: '75px',
    },
    soButton: {
      background: ' #FF4B01',
      borderRadius: '3px',
      padding: '10px 36px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
    },
    diTop: {
      marginBottom: "10px",
      fontFamily: "'Lato'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "50px",
      float: "right",
      color: "#333333",
    },
    outlinedButton: {
      color: "#FF4B01",
      height: "45px",
      float: "right",
      // marginRight: "5px",
      border: "1px solid #ff6700",
      borderRadius: "4px",
      background:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgb(255 255 255 / 0%) 100%)",
      fontFamily: "'Lato'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0.3px",
      color: "#ff6700",
  
      "&:hover": {
        color: "#ff6700",
        border: "1px solid #ff6700",
        borderRadius: "4px",
        background:
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgb(255 255 255 / 0%) 100%)",
      },
      "&:focus": {
        background:
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgb(255 255 255 / 0%) 100%)",
        color: "#ff6700",
        border: "1px solid #ff6700",
        borderRadius: "4px",
      },
    },
    filledButton: {
      height: "45px",
      background: "#ff6700",
      float: "right",
      width: "100%",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      fontFamily: "'Lato'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "19px",
      letterSpacing: "0.3px",
      color: "#FFFFFF",
      "&:hover": {
        background: "#ff6700",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        color: "#FFFFFF",
      },
      "&:focus": {
        background: "#ff6700",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        color: "#FFFFFF",
      },
    },
    matGrid: {
      marginTop: "30px",
      marginBottom: "30px",
    },
    dibot: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "19px",
      margin: "10px",
      color: "#3C345399",
    },
    money: {
      fontFamily: "'Lato'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "28px",
      lineHeight: "41px",
      color: "#333333",
      width: "80%",
    
    },
    card: {
      padding: "12px",
      width: "30%",
      background: "#FFFFFF",
      boxShadow: "0px 21px 45px rgba(30, 66, 138, 0.1)",
      borderRadius: "10px",
    },
    tableHeader: {
      background: "#ff6700",
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      // lineHeight: "15px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      color: "#ffffff",
    },
    table: {
      background: "#FFFFFF",
      borderRadius: "10px",
      fontFamily: "'Lato'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#666666",
    },
  }));
  export default useStyles;