import React, { useState, useEffect } from "react";
import { Modal, Tabs } from "antd";
import CustomTabs from "../../CustomTabs";
import ManageWallet from "../../ManageWallet";
import ManageBankCards from "../../ManageBankCards";

import { SettingsWrapper as Wrapper } from "./style";
import { customBaseUrl, httpGet } from "../../../store/http";
import Summary from "../../SummaryPage/summary";
import { Withdrawal } from "../../Withdrawal";
import { Transfer } from "../../Transfer";
import avatar from "../../../assets/icons/avatar.svg";
import { ComingSoonComponent } from "../../../views/coming-soom";
import ManageBankAccount from "../../ManageBankAccount";
import { Requests } from "../../../views/users/Requests";
import { BillsPayment } from "../../../views/users/BillsPayment";
const { TabPane } = Tabs;

const UserActionsModal = ({
  showModal,
  hideModal,
  title,
  className,
  userId,
  record
}) => {
  const [loading, setLoading] = useState(false);
  // const [profiles, setProfiles] = useState({});
  const [walletList, setWalletList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [wdBankList, setWdBankList] = useState([]);
  const [bankAccountList, setBankAccountList] = useState([]);
  const [currentView, setCurrentView] = useState("default");
  const [virtualAccount, setVirtualAccount] = useState({});
  const [charges, setCharges] = useState([]);
  const [billsCategory, setBillsCategory] = useState([]);
  const [ quickTeller, setQuickTeller] = useState(false);
  const [banckCharges, setBankCharges] = useState({})
  const [internalCharges, setInternalCharges] = useState({});
  const [billCharges, setBillsCharges] = useState({})

  const getCharges = async () => {
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/bank/event/charges`
    );
    if (response?.status === true) {
      console.log('chargess', response.data)
      setCharges(response.data);
    }  };

    const getBankCharges = async () => {
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/pricing/user-products/${userId}/NIP_PAYOUT`
      );
      if (res?.status) {
        setBankCharges((res?.data));
      }
    };
  
    const getInternalCharges = async () => {
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/pricing/user-products/${userId}/WAYATRAN`
      );
      if (res?.status) {
        setInternalCharges((res?.data));
      }
    };
  
    const getBillCharges = async () => {
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/pricing/user-products/${userId}/QUICKTELLER`
      );
      if (res?.status) {
        setBillsCharges((res?.data));
      }
    };
  

  const getWallets = async () => {
    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/admin/user/accounts/${userId}`
    );
    if (res?.status) {
      setWalletList(res.data);
    }
  };

  const getVirtualAccounts = async () => {
    const res = await httpGet(
      `${customBaseUrl.accountCreationUrl}/account/getAccounts/${userId}`
    );
    if (res?.status) {
      setVirtualAccount(res.data);
    }
  };

  const getBankAccountList = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/admin/list/userBankAcct/${userId}`
    );
    if (res?.status) {
      setBankAccountList(res.data);
    }
  };

  const getBanks = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/BK`
    );
    const wdRes = await httpGet(
      `${customBaseUrl.cardUrl}/bankAccount/getBanks/WD`
    );
    if (res?.status) {
      setBankList(res.data);
      setWdBankList(wdRes.data);
    }
  };

  const getBillsCategory = async () => {
    let res;
    const resp = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`)

    let aggregator = resp?.data;

    let quick = aggregator.filter(e=>e.aggregator==='QUICKTELLER')

    if(quick[0].active){
      setQuickTeller(true)
      /* res = await httpGet(
        `${customBaseUrl.billPaymentUrl}/api/v1/getCategories`
      );*/
      res = await httpGet(
        `${customBaseUrl.billPaymentUrl}/api/v1/category`
      );
       }else{
     res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category`
    );
     }
    if (res?.status) {
      setBillsCategory(res.data);
    }
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      // getProfiles();
      getWallets();
      getBanks();
      getBankAccountList();
      getVirtualAccounts();
      getCharges();
      getBillsCategory();
      setLoading(false);
      getBankCharges();
      getBillCharges();
      getInternalCharges();
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className={className}
      title={title}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={1000}
      footer={0}
    >
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <img
          src={avatar}
          alt="avatar"
          style={{ height: "90px", width: "100%" }}
        />
      </div>
      <Wrapper>
        {currentView === "default" ? (
          <CustomTabs totalTabs="6">
            <TabPane tab="WALLETS" key="1">
              <ManageWallet
                record={record}
                userId={userId}
                walletList={walletList}
                virtualAccount={virtualAccount}
                loading={loading}
                getWallets={getWallets}
              />
            </TabPane>
            <TabPane tab="BANK ACCOUNTS" key="2">
              <div style={{ marginTop: "-180px" }}>
                <ManageBankAccount userId={userId} />
                {/* <ComingSoonComponent percentage={0} /> */}
              </div>
            </TabPane>
            <TabPane tab="BANK CARDS" key="3">
              <ManageBankCards userId={userId} bankList={bankList} walletList={walletList}/>
              {/* <ComingSoonComponent percentage={0} /> */}
            </TabPane>
            <TabPane tab="REQUEST" key="4">
              <div style={{ marginTop: "20px" }}>
                <Requests 
                record={record}
                walletList={walletList}
                 userId={userId}
                 charges ={charges}
                setCurrentView={setCurrentView}
                />
              </div>
            </TabPane>
            <TabPane tab="WITHDRAW" key="5">
            {/*  <div style={{ marginTop: "-180px" }}>
                <ComingSoonComponent percentage={70} />
              </div>*/}

               <Withdrawal
                userId={userId || null}
                bankAccountList={bankAccountList}
                walletList={walletList}
                bankList={wdBankList}
                charges ={banckCharges}
                setCurrentView={setCurrentView}
                record={record}
              /> 
            </TabPane>

            <TabPane tab="TRANSFER" key="6">
              {/* <ComingSoonComponent percentage={70} /> */}
              <Transfer
                walletList={walletList}
                userId={userId}
                charges ={internalCharges}
                setCurrentView={setCurrentView}
              />
            </TabPane>
            <TabPane tab="BILLS" key="7">
              <div style={{ marginTop: "20px" }}>
                <BillsPayment  
                record={record}
                billsCategory={billsCategory}
                walletList={walletList}
                 userId={userId}
                setCurrentView={setCurrentView}
                quickTeller={quickTeller}
                />
              </div>
            </TabPane>
          </CustomTabs>
        ) : (
          ""
        )}

        {currentView === "summary" ? (
          <div
            style={{
              width: "60%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Summary  
            displayData='' 
            handleClick ={()=>alert('done')}
            otp ='1234'
            setOtp = {()=>alert('222')}
            />
          </div>
        ) : (
          ""
        )}
      </Wrapper>
    </Modal>
  );
};

export default UserActionsModal;
