import React, { useState } from "react";
import { Modal, Button, DatePicker, Switch } from "antd";
import Wrapper from "./style";
import { CSVLink } from "react-csv";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import moment from "moment";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import UpdateConfig from "./updateConfig";

const BusinessConfig = ({ showModal, hideModal, user}) => {
  const [data, setData] = useState({});
  const [config, setConfig] = useState([]);
const [showUpdate, setShowUpdate] = useState(false);
const [selectedRow, setSelectedRow] = useState({});

    const getConfig = async() => {
      //showLoader();
      let res = await httpGet(`${customBaseUrl.smWalletUrl}/api/v1/wallet/simulation/config`)
      if(res?.status) {
        hideLoader();
        const bussConfig= res?.data?.filter(e=>e?.userType === user)
        bussConfig.sort((a, b) => {
          return a.id - b.id;
      });
        setConfig(bussConfig)
      }else {
        hideLoader();
        console.log(res);
      }
    }

 
    useEffect(() => {
      getConfig();
    }, []);
    const dropDownOptions = ['update']
    const handleDropdownAction = (item, row) => {
      if (item === 'update'){
        setSelectedRow(row);
        setShowUpdate(true);
      }
    }
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      width={'1000px'}
      onCancel={() => {
        hideModal(false);
      }}
      title=""
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
        width: "900px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "27px",
        }}
      >
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            hideModal(false);
          }}
        >
          X
        </div>
      </div>
      <Wrapper>
        <form >
        <div className="title text-center">SM USERS CONFIGURATION ({user === 'corporate' ? 'BUSINESS' : 'INDIVIDUAL'})</div>
        <div className="cw-content">
          <table className=".text-nowrap table">
            <thead>
                <tr>
                    <th className="th-sm ">Days</th>
                    <th className="th-sm ">No of SM Users</th>
                    <th className="th-sm ">Credit Amount</th><th>Debit Amount</th><th>Status</th><th>Action</th>
                </tr>
            </thead>
            <tbody>
                {config?.map((e,i)=>
                <tr><td><input disabled value={e?.day} name='day'/></td>
                <td><input disabled value={e.numberOfUsers} /></td>
                <td><input disabled  value={e.creditAmount} /></td>
                <td><input disabled  value={e.debitAmount}/></td>
                <td>{e?.active ? 'Active' : 'InActive'} </td>
                <td><AntDropdown
                  dropDownOptions={dropDownOptions}
                  handleDropdownAction={(item) => handleDropdownAction(item, e)}
                  isArrow
                />
          </td></tr>
                )}
            </tbody>
          </table>
        </div>
        {/*<div className="text-center">
            <button className="btn"
                style={{
                    marginTop: '50px',
                    marginLeft: "auto",
                    marginRight: "auto",
                    background: "#FF4B01",
                    color: "#fff",
                    borderRadius: "3px",
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 25px",
                  }}
            >
                SAVE CHANGES
            </button>
        </div> */}
        </form>
      </Wrapper>
      {
        showUpdate ?
        <UpdateConfig
          showModal={showUpdate}
          hideModal={setShowUpdate}
          selectedRow={selectedRow}
          getConfig={getConfig}
          />: ''
      }
    </Modal>
  );
};

export default BusinessConfig;
