import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { BugOutlined, CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";
import SearchDropdown from "../../CustomDropdown/SearchDropdown";
import { searchUsers } from "../../../store/httpCalls";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

const NonWayaWebForm = ({ showModal, hideModal, title, wallets , userId,className}) => {
    let d = new Date();
    let ref = d.getTime();
  const initialState = {
    tranCrncy: "NGN",
    eventId: "COMPAYM",
    transactionCategory: "COMMISSION",
    userId: userId,
    fullName:"",
    amount:"",
    emailOrPhoneNo:'',
    recipientName: "",
    customerDebitAccountNo:"",
    paymentReference: `WAYA${ref}`,
    tranNarration: "bulk waya web transfer",
    walletOp: []
  };
  const [data, setData] = useState(initialState);
  const [transactions, setTransactions] = useState([{ ...initialState }]);
  const [selectedWallet, setSelectedWallet] = useState({});
  const [options, setOptions] = useState([]);
  const [walletList, setWalletList] = useState([]);
  const [query, setQuery] = useState('');
  
  const [page, setPage] = useState(1);
  const clearState = () => {
    setData({ ...initialState });
    setWalletList([]);
  };
  useEffect(() => {
    console.log(selectedWallet);
  }, [selectedWallet]);

  const handleChange = (event, value) => {
    const isEmpty = checkProperties(data);
    if (isEmpty) {
      Swal.fire("Warning", "Please fill all the fields", "warning");
    } else {
      console.log(data);
      event.preventDefault();
      setTransactions((prevState) => [data, ...prevState]);
      setPage(transactions.length + 1);
      setQuery('');
      clearState();
    }
  };
  const handlePaginateChange = (event, pageV) => {
    const currentIndex = pageV - 1;
    setPage(pageV);
    setData(transactions.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(transactions);
  };
  useEffect(() => {
    console.log(transactions);
    let dataRec = transactions;
    dataRec[page - 1] = data;
    setTransactions(dataRec);
  }, [data]);

  const handleRemoveUser = async () => {
    let current = page === 1 ? 1 : page - 1;
    await setTransactions((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...initialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };

  const handleTransaction = async () => {
    showLoader();
    /*
    const response = await httpPost(
      `${customBaseUrl.commissionUrl}/api/v1/transfer/multiple-user-commission`,
      transactions
    );*/

    /*const response = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new-multiple`,
        transactions
        );
        */
    let response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
        {
          payload: JSON.stringify(transactions),
          "requestId": `${d.getTime()}`,
          "requestType": "MultiTransferToNonWaya",
          customerName: 'Multiple Transfer Non Waya'
        } )
      
        
    if (response.status === true) {
      hideLoader();
      Swal.fire('Done', response.message, 'success');
      hideModal(false)
    } else {
      hideLoader();
      Swal.fire('Error', response.message, 'error');
    }
  };



  const handleSelectOption = async (selectedUser) => {
    let fullName= `${selectedUser.firstName} ${selectedUser.surname}`
    setData({ ...data, actRefId: selectedUser.userId, fullName});
    setWalletList([]);
    await getWallets(selectedUser.userId,fullName);

    setQuery(fullName);
   /* setQuery(`${selectedUser.surname} ${selectedUser.firstName}`);*/
    setOptions([]);
  };


  const handleSearch = async (e) => {
    setQuery(e.target.value);
    setData({...data, fullName: e.target.value})
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }

  const getWallets = async (userId, fullName) => {
   //  return alert(fullName);
      const res = await httpGet(
        `${customBaseUrl.walletUrl}/api/v1/wallet/accounts/${userId}`
      );
     // alert(JSON.stringify(res));
      if (res?.status) {
        setWalletList(res.data);
       setData({...data, walletOp: res.data, fullName});
      }
    };
  


  return (
    <Modal
    className={className}
    centered
    visible={showModal}
    onOk={() => hideModal(false)}
    onCancel={() => hideModal(false)}
    width={800}
    footer={0}
    closable={false}
    title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >


        
      <Wrapper class='row g-3 needs-validation border mb-2 p-3'>
        <div style={{ position: 'relative' }} class="col-md-6">
          <label for='validationCustom01' class='form-label'>
            Sender's Name
          </label>
          <input
            type='text'
            class='form-control'
            id='validationCustom01'
            required
            onChange={handleSearch}
            value={data.fullName}
          />
                    {//}  <Button onClick={()=>{setQuery('');setData({...data, fullName:''})}}>x</Button>
             }
              <SearchDropdown options={options} handleClick={handleSelectOption} />
        </div>
        <div class='col-md-6'>
            <div className="label"> RECIPIENT NAME</div>
            <div className="input-content">
              <input
                class='form-control'
                value={data.recipientName}
                onChange={(e) =>{
                  setData({ ...data, recipientName: e.target.value })
                }
              }
              />
            </div>
          </div>
          <div class='col-md-6'>
            <div className="label"> RECIPIENT EMAIL or PHONE NO</div>
            <div className="input-content">
              <input
                class='form-control'
                value={data.emailOrPhoneNo}
                onChange={(e) =>{
                  setData({ ...data, emailOrPhoneNo: e.target.value })
                }
              }
              />
            </div>
          </div>
          <div class='col-md-6'>
            <div className="label"> Amount</div>
            <div className="input-content">
              <input
                class='form-control'
                value={data.amount}
                onChange={(e) =>{
                  setData({ ...data, amount: e.target.value })
                }
              }
              />
            </div>
          </div>
          
         
         <div class='col-md-6'>
          <label for='validationCustom06' class='form-label'>
            Select sender's wallet to perform this transaction
          </label>


          {/*data.customerDebitAccountNo?
            <select
             className='border border-dark rounded form-select' value={data?.customerDebitAccountNo}>
                <option value={data.customerDebitAccountNo}>{data.customerDebitAccountNo}</option>
            </select>:
            <select
            class='form-select'
            id='validationCustom06'
            required
            value={data.customerDebitAccountNo}
            onChange={(e) => setData({ ...data, customerDebitAccountNo: e.target.value })}
            className='border border-dark rounded'
          >
            <option selected disabled value=''>
              Choose...
            </option>
            { walletList.length>0 &&
            walletList.map((option) => (
              <option key={option.accountNo} value={option.accountNo}>
                {option.acct_name +' - '+option.accountNo}
              </option>
            ))}
          </select>*/}

            <select

            onChange={(e) =>{ setData({ ...data, customerDebitAccountNo: JSON.parse(e.target.value).accountNo })}}
             className='border border-dark rounded form-select'
             value={data.customerDebitAccountNo?JSON.stringify(data.walletOp?.filter((item) =>item.accountNo === data.customerDebitAccountNo)[0]):""}
            >
              <option value='' disabled>select wallet</option>
              {data?.walletOp.map((option) => (
              <option key={option.accountNo} value={JSON.stringify(option)}>
                {option.acct_name +' - '+option.accountNo}
              </option>
            ))
              }             


            </select>

          <div class='invalid-feedback'>Wallet account is required.</div>
        </div>
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleTransaction}
        >
          Proceed
        </Button> */}
      </div>
      <div style={{ marginTop: "10px" }} className="row">
        <div
          style={{
            
           
          }}
          className="col-md-2"
        >
          <Button
            className="iconUI"
            style={{ color: "#C4C4C4", border: "1px solid #C4C4C4" }}
            onClick={handleChange}
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <Button
            className="iconUI"
            onClick={handleRemoveUser}
            style={{ color: "#FF4B01", border: "1px solid #FF4B01" }}
            icon={<CloseOutlined />}
          >
            Remove 
          </Button>
        </div>
        <div className="col-md-6">
          <Button
            className="iconUI"
            type="ghost"
            onClick={handleTransaction}
            style={{ color: "#fff", background: "#FF4B01", float: "right" }}
          >
            Proceed
          </Button>
        </div>
      </div>

      <div className="row" style={{ marginTop: "7px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Stack spacing={2}>
            <Pagination
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                alignSelf: "center",
              }}
              count={transactions.length}
              page={page}
              onChange={handlePaginateChange}
            />
          </Stack>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Modal>
  );
};

export default NonWayaWebForm;
