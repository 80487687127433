import React, { useState } from 'react';
import { Pagination, Table } from "antd";
import Summary from './summary/summary';
import { customBaseUrl, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import Swal from 'sweetalert2';
import RejectPaymentRequest from '../../Transaction/PaymentRequest/modals/reject';

const  ReceivedRequests = ({request, meta,charges,record,   getReceivedRequests}) => {
  let [summary, setSummary] = useState(false);
  let [displayData, setDisplayData] = useState({});
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [viewReject, setViewReject] = useState(false);
  const [reason, setReason] = useState('')

  const handleChangePage = async (newPage) => {
   //alert(newPage)
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
     getReceivedRequests(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (page, size) => {
    //alert(size)
    setPagination({ ...pagination, current: 0, pageSize: size });
     getReceivedRequests(0, size);
  };

       const columns = [ 
        { 
        key: "reason", 
        title: "Activity", 
        dataIndex: "reason", 
        }, 
        { 
        key: "sender", 
        title: "From", 
        dataIndex: "senderName", 
        render: (text, record) =>  text
        }, 
        { 
        key: "amount", 
        title: "Amount", 
        dataIndex: "amount", 
        }, 
        { 
        key: "status", 
        title: "Status", 
        dataIndex: "status", 
        } 
      
        ] 
        const handleContinue = async () => {
          showLoader();

          const postData = {
            pin,
            referenceCode
          }

          const formBody = Object.keys(postData).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(postData[key])).join('&');

          const res = await httpPost(
            `${customBaseUrl.paymentRequestUrl}/settle-payment-request-waya-users/${referenceCode}/${pin}/${displayData?.receiverId}`
          );

          if (res?.status) {
            hideLoader();
            Swal.fire({
              title: ' Alert',
              text: res?.message,
            })
            console.log(res)
          } else{
          Swal.fire('Error', res?.message)
          hideLoader();
           }
        }
      
        const handleSubmit = async () => {
          showLoader();
          const postData = {
            pin,
            referenceCode,
            otp,
            receiverId: displayData?.receiverId
          }

         // const formBody = Object.keys(postData).map(key =>      encodeURIComponent(key) + '=' + encodeURIComponent(postData[key])).join('&');
          /*
          const res = await httpPost(
            `${customBaseUrl.paymentRequestUrl}/completePaymentWithOTP/${referenceCode}/${otp}/${displayData?.receiverId}`
          );
          */

          let d= new Date();
          const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
          {
            payload: JSON.stringify(postData),
            requestId: `REQ${d.getTime()}`,
            "requestType": "settlePaymentrequest",
            customerName: `${record?.firstName} ${record?.lastName}`
          } )

          if (res?.status) {
            Swal.fire('Done', res?.message, 'success');
            setSummary(false);
            getReceivedRequests();
            setReferenceCode('')
            hideLoader();
            console.log(res)
          }
          else{
            alert(JSON.stringify(res))
            Swal.fire('Error', res?.message, 'failed');

          hideLoader();
        }
        }
      

        const handleReject = async () => {

          if (displayData?.status==='PAID') return ''
          let Data= {
            "note": reason,
            "userId": displayData?.receiverId,
            referenceCode
          }
          showLoader();

         /*
          const res = await httpPut(`${customBaseUrl.paymentRequestUrl}/reject-payment-request/${referenceCode}`,
          Data)
          */
          let d= new Date();
          const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
          {
            payload: JSON.stringify(Data),
            requestId: `REQ${d.getTime()}`,
            "requestType": "rejectPaymentrequest",
            customerName: `${record?.firstName} ${record?.lastName}`
          } )

          if(res.status){
            hideLoader();
            Swal.fire('Done', res?.message)      
            setSummary(false);
            getReceivedRequests();
            setReferenceCode('')
            setDisplayData([])
            setReason('')
            setViewReject(false)
      
          }else{
            hideLoader();
            console.log(res)
            Swal.fire('Error', res?.message)
            setDisplayData([])
            setReason('')
            setSummary(false);
          }

        }
 return(

    <div>
        {!summary?<div className="table"> 
        <Table dataSource={request} columns={columns} pagination={false} 
         onRow={(record, rowIndex) => {
            return {
              onClick: event => {setDisplayData((record)); setSummary(true); setReferenceCode(record.reference)}, // click row
            };
          }}
        showHeader={true}
        /> 
         <Pagination  onShowSizeChange={handleChangeRowsPerPage} pageSize={pagination.pageSize} defaultCurrent={0} onChange={handleChangePage} total={meta}  />
        </div> :
        <Summary displayData={displayData} setViewReject={setViewReject} setSummary={setSummary} pin={pin} setPin={setPin}  otp={otp} setOtp={setOtp} handleSubmit={handleSubmit} handleContinue={handleContinue} handleReject={handleReject}
        />
}
    {
      viewReject && <RejectPaymentRequest showModal={viewReject} hideModal={setViewReject} handleReject={handleReject} reason={reason} setReason={setReason}/>
    }
    </div>
 )   


}

export default ReceivedRequests;