import React from 'react';

const tempUserIcon = () => (
  <svg
    width="38"
    height="37"
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_26073)">
      <path
        d="M31.2021 23.9922H31.1299V37H37.7061V30.4961C37.7061 26.9098 34.7884 23.9922 31.2021 23.9922Z"
        fill="#FF4B01"
      />
      <path
        d="M7.20996 23.9922C3.62371 23.9922 0.706055 26.9098 0.706055 30.4961V37H7.28223V23.9922H7.20996Z"
        fill="#FF4B01"
      />
      <path
        d="M19.2061 21.8242C25.223 21.8242 30.1182 16.9291 30.1182 10.9121C30.1182 4.89513 25.223 0 19.2061 0C13.1891 0 8.29395 4.89513 8.29395 10.9121C8.29395 16.9291 13.1891 21.8242 19.2061 21.8242ZM18.1221 4.33594H20.29V9.82812H25.7822V11.9961H18.1221V4.33594Z"
        fill="#FF4B01"
      />
      <rect
        x="7.1377"
        y="23.9922"
        width="23.9922"
        height="13.0078"
        fill="#FF4B01"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_26073">
        <rect
          width="37"
          height="37"
          fill="white"
          transform="translate(0.706055)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default tempUserIcon;
