import { customBaseUrl, httpGet } from './http';

// Search users
export const searchUsers = async (query) => {
  // console.log('##', query);
  const res = await httpGet(
    `${customBaseUrl.authUrl}/api/v1/search/search-profile-name/${query}`
  );
  if (res.status) {
    return res.data;
  }
  return [];
};
