import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => {
  return {
    updateButton: {
      borderRadius: '3px !important',
      textAlign: 'center',
      background: '#FF4B01',
      color: '#fff',
      height: '45px',
    },
    topDiv: {
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid #C4C4C4',
      paddingBottom: '27px',
      marginBottom: '45px',
    },
    title: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '29px',
      color: '#000000',
      marginBottom: '27px',
    },
    titleDet: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
    },
    tDL: {
      display: 'flex',
      alignItems: 'center',
    },
    tDlIcon: {
      marginRight: '16px',
    },
    tDLTextTop: {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '22px',
      color: '#000000',
      marginBottom: '5px',
    },
    tDLTextBot: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17px',
      color: '#6A6A6A',
    },
    bottomDiv: {
      paddingRight: '200px',
    },
    inputWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '35px',
    },
    inputCont: {
      dispaly: 'flex',
      width: '32%',
      flexDirection: 'column',
    },
    inputLabel: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
      color: '#000000',
      marginBottom: '9px',
    },
    inputInput: {
      background: '#FFFFFF',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      borderRadius: '5px !important',
      width: '100%',
      padding: '8px !important',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '12px',
      color: '#000000',
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
    },
    fTop: {
      marginBottom: '15px',
    },
    fBot: {
      display: 'flex',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '12px',
      color: '#000000',
    },
    postCardWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingRight: '100px',
    },
    postCard: {
      height: '160px',
      width: '32%',
      background: '#FFFFFF',
      boxShadow: '0px 0px 26px rgba(0, 0, 0, 0.13)',
      borderRadius: '12px',
      marginBottom: '25px',
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

export default useStyles;
