import React, {useState, useEffect} from "react";
import PinModal from "../../components/Modal/Pin/PinModal";
import { NotificationManager } from "react-notifications";
import { hideLoader, showLoader } from "../../utils/loader";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import { useNavigate } from "react-router-dom";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import AntTable from "../../components/CustomTable/AntTable";
import {DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid as MatGrid, Paper } from "@mui/material";
import { useMatStyles } from "../users/ManageRefferal/style";
import { Button, Modal, Row } from "antd";



const Approvers = ({setShowProfile}) => {
    const roles= localStorage.getItem('roles')
    const styles = useMatStyles();
    let navigate = useNavigate();
    const [isAdmin, setIsadmin] =useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [otp, setOtp]= useState('')
    const [approvers, setApprovers] = useState([]);
    const [user, setUser] = useState([]);
    const [showApprover, setShowApprover] = useState(false);
    const [pagination, setPagination] = useState({
      current: 0,
      pageSize: 10,
    });

    const handleValidate = async () => {
        showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/approver/verify-otp/owner?otp=${Number(otp)}`
       );
        if (res) {
          NotificationManager.success("Sucessful");
          setIsadmin(true)
          setIsModalVisible(false)
          hideLoader();    
        } else {
          hideLoader();
          // console.log('otp err', res)
          // setIsModalVisible(false)
          setShowProfile(false);
          navigate("../loan");
          NotificationManager.error('Not authorized');
        }
      }

    const sendOTP = async () => {
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/approver/send-otp/owner`
        );
        if (res === 'OTP sent to owner') {
          NotificationManager.success("OTP sent");
        }
      }
      const handleOtp = () => {
        console.log('roles', roles)
        if(roles?.indexOf('ROLE_ADMIN_OWNER') !==-1 || roles?.indexOf('ROLE_ADMIN_SUPER') !==-1){
          if (!isAdmin){
            setIsModalVisible(true);
                sendOTP()  
          }
          }else{
            window.open('/loan','_self')
            NotificationManager.error('Not authorized');
          }
       
      }

    const handleGetApprovers = async () => {
        const res = await httpGet(
          `${customBaseUrl.loanUrl}/api/v1/admin/all-approvers`
        );
        if (res.status = true) {
          console.log({ app: res.data})
          setApprovers(res.data)
        }
      }

      const handleAdd = async (e) => {
        e.preventDefault();
        const pData = 
          {
            email: user.email,
            role: user.role,
          }
        
        showLoader();
        const res = await httpPost(
          `${customBaseUrl.loanUrl}/api/v1/admin/assign-role`,
          pData
       );
        if (res.status) {
          NotificationManager.success(res.message);
          hideLoader();
          handleGetApprovers();
          setShowApprover(false);
    
        } else {
          hideLoader();
          // console.log('otp err', res)
          NotificationManager.error(res.message);
        }
      }
      const deleteApprover = async (val) => {
      
        showLoader();
        const res = await httpDelete(
          `${customBaseUrl.loanUrl}/api/v1/admin/delete-role/${val.email}`,
          {},
       );
        if (res.status) {
          NotificationManager.success(res.message);
          hideLoader();
          handleGetApprovers();
    
        } else {
          hideLoader();
          // console.log('otp err', res)
          NotificationManager.error(res.message);
        }
      }
      const handleDropdownAction = (item, data) => {

        if (item === "Delete") deleteApprover(data);    
        //if (item === "Send Using Webforms") setShowTransferForms(true);
      };
    useEffect(() => {
        handleOtp();
        handleGetApprovers();
    }, [])

    const columns = [
    
      { field: 'email',
        headerName: 'Name',
        width: 200 ,
        headerClassName: styles.tableHeader,
  
      },
      { field: 'email',
        headerName: 'Assigned Email',
        width: 200 ,
        headerClassName: styles.tableHeader,
  
  
      },
      { field: 'role',
        headerName: 'Assigned Role',
        width: 200 ,
        headerClassName: styles.tableHeader,
  
  
      },
      { field: 'id',
        headerName: 'Action',
        width: 200 ,
        headerClassName: styles.tableHeader,
        renderCell: ({row}) => <AntDropdown
                          dropDownOptions={[
                            "Delete",                          
                          ]}
                          handleDropdownAction={(item) => 
                            handleDropdownAction(item, row)
                          }
                          // userId={id}
                          isArrow
                        />       
      },      
    ];

    return(
        <div>
          <Modal
        // className={className}
        title="Add Approver"
        centered
        visible={showApprover}
        onOk={() => setShowApprover(false)}
        onCancel={() => setShowApprover(false)}
        width={500}
        footer={0}
      >
        {/* <Divider>
        <div style={{ marginBottom: "20px" }}>
          <Typography>Page: {page}</Typography>
        </div>
      </Divider> */}
        <form class="row g-3 needs-validation" onSubmit={handleAdd} >
          
              <div class="col-md-12">
                <label for="validationCustom04" class="form-label">
                  Role type
                </label>
                <select
                  class="form-select"
                  id="validationCustom04"
                  required
                  value={user?.role}
                  onChange={(e) =>
                    setUser({ ...user, role: e.target.value })
                  }
                >
                  <option selected disabled value="">
                    Choose...
                  </option>
                  <option value="Credit analyst">
                    Credit Analyst
                  </option>
                  <option value="Controller">
                    Controller
                  </option>
                  <option value="MD">
                    MD
                  </option>
                </select>
                <div class="invalid-feedback">Business type is required.</div>
              </div>

              <div class="col-md-12">
                <label for="validationCustom02" class="form-label">
                  Approver Email
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="validationCustom02"
                  value={user.email}
                  required
                  onChange={(e) =>
                    setUser({ ...user, email: e.target.value })
                  }
                />
          </div>
          <button className="btn" style={{ background: '#ff6700'}} type="submit">Add</button>
          </form>
          </Modal>          
          <div>
          <Row type="flex" justify="end" align="middle">
          <Button
            size="medium"
            style={{ background: "black", fontSize: "10px", color: "#fff" }}
            onClick={()=> setShowApprover(true)}
          >
            Add Approver
          </Button>
          </Row>
          </div>
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid 

         density="comfortable"
        rows={approvers }
            columns={columns}
            page={pagination.current}
            // paginationMode="server"
            pageSize={pagination.pageSize}
            // onPageSizeChange={(newPageSize) =>
            //   handleChangeRowsPerPage(newPageSize)
            // }
            // onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={approvers.length}

        />
        </div>
        </MatGrid>
            {isModalVisible&& <PinModal
                
                showModal={isModalVisible}
                hideModal={setIsModalVisible}
                otp={otp}
                setOtp={setOtp}
                numInputs={6}
                separator=''
                buttonLabel='Verify'
                title='Verify OTP'
                description='Please input the OTP sent to your Phone'
                handleSubmit={handleValidate}
                pinMode={true}

              />}
        </div>
    )
}

export default Approvers;