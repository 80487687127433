import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useMatStyles from "./style";
import wayabankLogo from "../../assets/images/logo.svg";
import { Card, CardContent, Paper, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import { Button } from "antd";
import { customBaseUrl, httpGet, httpPost } from "../../store/http";
import { NotificationManager } from "react-notifications";
const AdminAccountVerification = () => {
  const styles = useMatStyles();
  const [inputText, setInputText] = useState("");
  const [searchParams] = useSearchParams();
  const emailOrPhone = searchParams.get("emailOrPhone");

  useEffect(() => {
    //
    const session_var = localStorage.getItem("activation_request_trials");

    if (
      session_var ===
      "6B86B273FF34FCE19D6B804EFF5A3F5747ADA4EAA22F1D49C01E52DDB7875B4B"
    ) {
      sentOtp().finally(()=>{
        sessionStorage.removeItem("activation_request_trials")
      });
    }
  });

  const sentOtp = async () => {
    let response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${emailOrPhone}`
    );
    if (response.status === true) {
      NotificationManager.success(response.message, 3000);
    } else {
      NotificationManager.error(response.message);
    }
  };
  const handleVerifyOtp = async () => {
    let response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/auth/verify-otp`,
      {
        "otp": inputText,
        "phoneOrEmail": emailOrPhone
      }
    );
    if (response.status === true) {
      NotificationManager.success(response.message);
      window.location.href = "/";
    } else {
      NotificationManager.error(response.message);
    }
  };
  return (
    <div className={styles.otpWrapper}>
      <div className={styles.logo}>
        <img src={wayabankLogo} alt="wayaLogo" style={{ height: "46%" }} />
      </div>
      <div>
        <Card className={styles.paperCard} elevation={3}>
          <CardContent>
            <div className={styles.titleText} alignSelf={"center"}>
              Verify Your Account
            </div>
            <div className={styles.subTitleText}>
              Please Input the OTP sent to your <br /> mobile number.
            </div>
            <div className={styles.otpCont}>
              <OtpInput
                value={inputText}
                onChange={(e) => setInputText(e)}
                numInputs={6}
                separator={<span style={{ marginRight: "20px" }}> </span>}
                containerStyle={{ justifyContent: "center" }}
                inputStyle={styles.otpInput}
                isInputNum
              />
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button onClick={handleVerifyOtp} className={styles.updateButton}>
                Verify
              </Button>
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div onClick={sentOtp} className={styles.link}>
                Did not get OTP?
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default AdminAccountVerification;
