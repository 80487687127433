import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import { useFormik,Formik, Field,Form, useField } from 'formik';
import * as Yup from 'yup';
import '../index.scss';
import { customBaseUrl, httpGet, httpPost } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';




const CreateSingleUserModal = ({ showModal, hideModal, title, className }) => {
  const [data, setData] = useState({});
  const [accountType, setAccountType] = useState('');
  const [businessTypes,setBusinessTypes] = useState([]);

  const getBusinessTypes = async () =>{
    let response = await httpGet(`${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=50`);
    // console.log(response);
    setBusinessTypes(response.businessTypeList);
  }

  useEffect( ()=>{
    getBusinessTypes();
  },[])

  const handleSubmit = async (values) => {
   // e.preventDefault();
    showLoader();
    let res;
    if (accountType === 'individual') {
 /*     res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/admin/users/create-private`,
        {
          admin: false,
          ...data,
        }
      );

*/
      let d = new Date();
      res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({admin:false, ...values}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "createUser",
        customerName: values?.firstName+' '+values?.surname
      } )
  


    } else {
   /*   res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/admin/users/create-corporate`,
        {
          admin: false,
          ...data,
        }
      );*/

      let d = new Date();
      res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify({admin:false, ...values}),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "CORPORATEACCOUNT",
        customerName: values?.firstName+' '+values?.surname
      } )
    }

    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      hideModal(false);
    } else {
      Swal.fire('Error', res.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      orgEmail: '',
      orgPhone: '',
      orgName:'',
      businessType:'',
      officeAddress: '',
      state: '',
      city: '',
      email: '',
      firstName: '',
      surname: '',
      dateOfBirth: '',
      gender: '',
      phoneNumber: '',
      password: '',
      referenceCode:''
    },
    validationSchema: Yup.object({
      businessType: accountType === 'corporate'?Yup.string()
        .required('Required'): '',
      firstName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      surname: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      orgPhone: accountType === 'corporate'? Yup
         .string("Phone number is required")
         .matches(/^\d+$/, 'Phone number is not valid')  
          .min(13, "phone number should be of minimum 13 characters length")
          .max(13, "phone number should be of maximum 13 characters length")
          .required("phone number is required"): '',
      email: Yup.string().email('Invalid email address').required('Required'),
      orgEmail: accountType === 'corporate'?Yup.string().email('Invalid email address').required('Required'):'',
      phoneNumber: Yup
      .string("Phone number is required")
      .matches(/^\d+$/, 'Phone number is not valid')  
       .min(13, "phone number should be of minimum 13 characters length")
       .max(13, "phone number should be of maximum 13 characters length")
       .required("phone number is required"),
       password: Yup
       .string("Enter your password")
       .min(8, "Password should be of minimum 8 characters length")
       .required("Password is required"),

    }),
    onSubmit: values => {
      handleSubmit(values)
      //alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Modal
      className={className}
      title={title}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={1000}
      footer={0}
    >
      <form class='row g-3 needs-validation' onSubmit={formik.handleSubmit}>
        <div class='col-md-6'>
          <label for='validationCustom04' class='form-label'>
            Account type
          </label>
          <select
            class='form-select'
            id='validationCustom04'
            required
            onChange={(e) => setAccountType(e.target.value)}
            
          >
            <option selected disabled value=''>
              Choose...
            </option>
            {[
              { label: 'Individual', value: 'individual' },
              { label: 'Corporate', value: 'corporate' },
            ].map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
         
          <div style={{color:'red', fontSize:9}}></div>
        </div>
        {accountType === 'corporate' ? (
          <>
            <div class='col-md-6'>
              <label for='businessType' class='form-label'>
                Business type
              </label>
              <select
                class='form-select'
                id='businessType'
                required
               /* onChange={(e) =>
                  setData({ ...data, businessType: e.target.value })
                }*/
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.businessType}
              >
                <option selected disabled value=''>
                  Choose...
                </option>
                {businessTypes.length !== 0? businessTypes.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.businessType}
                    </option>
                  )):<></>}
              </select>
              {formik.touched.businessType && formik.errors.businessType ? (
         <div>{formik.errors.businessType}</div>
       ) : null}
              <div style={{color:'red', fontSize:9}}></div>
            </div>

            <div class='col-md-6'>
              <label for='orgEmail' class='form-label'>
                Organization Email
              </label>
              <input
                type='email'
                class='form-control'
                id='orgEmail'
               // value={data.orgEmail}
                required
                //onChange={(e) => setData({ ...data, orgEmail: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.orgEmail}
              />
               {formik.touched.orgEmail && formik.errors.orgEmail ? (
         <div>{formik.errors.orgEmail}</div>
       ) : null}
              <div style={{color:'red', fontSize:9}}>
              </div>
            </div>

            <div class='col-md-6'>
              <label for='orgName' class='form-label'>
                Organization Name
              </label>
              <input
                type='text'
                class='form-control'
                id='orgName'
               // value={data.orgName}
                required
               // onChange={(e) => setData({ ...data, orgName: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.orgName}
              />
               {formik.touched.orgName && formik.errors.orgName ? (
         <div>{formik.errors.orgName}</div>
       ) : null}
              <div style={{color:'red', fontSize:9}}></div>
            </div>

            <div class='col-md-6'>
              <label for='orgPhone' class='form-label'>
                Organization Phone
              </label>
              <input
                type='text'
                class='form-control'
                id='orgPhone'
               // value={data.orgPhone}
                required
               // onChange={(e) => setData({ ...data, orgPhone: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.orgPhone}
              />
              <div style={{color:'red', fontSize:9}}>
              {formik.touched.orgPhone && formik.errors.orgPhone ? (
         <div>{formik.errors.orgPhone}</div>
       ) : null}
              </div>
            </div>

            <div class='col-md-6'>
              <label for='officeAddress' class='form-label'>
                Office address
              </label>
              <input
                type='text'
                class='form-control'
                id='officeAddress'
               // value={data.officeAddress}
                required
               /* onChange={(e) =>
                  setData({ ...data, officeAddress: e.target.value })
                }*/
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.officeAddress}
              />
              <div style={{color:'red', fontSize:9}}></div>
            </div>
            <div class='col-md-6'>
              <label for='state' class='form-label'>
                State
              </label>
              <input
                type='text'
                class='form-control'
                id='state'
               // value={data.state}
                required
                //onChange={(e) => setData({ ...data, state: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
              />
              <div style={{color:'red', fontSize:9}}></div>
            </div>
            <div class='col-md-6'>
              <label for='city' class='form-label'>
                City
              </label>
              <input
                type='text'
                class='form-control'
                id='city'
                //value={data.city}
                required
                //onChange={(e) => setData({ ...data, city: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
              <div style={{color:'red', fontSize:9}}></div>
            </div>
          </>
        ) : (
          ''
        )}
        <div class='col-md-6'>
          <label for='email' class='form-label'>
            Email Address
          </label>
          <input
            type='email'
            class='form-control'
            id='email'
           // value={data.email}
            required
            //onChange={(e) => setData({ ...data, email: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
           {formik.touched.email && formik.errors.email ? (
         <div>{formik.errors.email}</div>
       ) : null}
          <div style={{color:'red', fontSize:9}}></div>
        </div>
        <div class='col-md-6'>
          <label for='firstName' class='form-label'>
            First name
          </label>
          <input
            type='text'
            class='form-control'
            id='firstName'
            //value={data.firstName}
            required
            //onChange={(e) => setData({ ...data, firstName: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          <div style={{color:'red', fontSize:9}}>
          {formik.touched.firstName && formik.errors.firstName ? (
         <div>{formik.errors.firstName}</div>
       ) : null}
          </div>
        </div>
        <div class='col-md-6'>
          <label for='surname' class='form-label'>
            Last name
          </label>
          <input
            type='text'
            class='form-control'
            id='surname'
            //value={data.surname}
            required
            //onChange={(e) => setData({ ...data, surname: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surname}
          />
          <div style={{color:'red', fontSize:9}}>
          {formik.touched.surname && formik.errors.surname ? (
         <div>{formik.errors.surname}</div>
       ) : null}
          </div>
        </div>
        <div class='col-md-6'>
          <label for='dateOfBirth' class='form-label'>
            Date of birth
          </label>
          <input
            type='date'
            class='form-control'
            id='dateOfBirth'
           // value={data.dateOfBirth}
            required
            //onChange={(e) => setData({ ...data, dateOfBirth: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateOfBirth}
          />
          <div style={{color:'red', fontSize:9}}></div>
        </div>
        <div class='col-md-6'>
          <label for='gender' class='form-label'>
            Gender
          </label>
          <select
            class='form-select'
            id='gender'
            required
            //onChange={(e) => setData({ ...data, gender: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
          >
            <option selected disabled value=''>
              Choose...
            </option>
            {[
              { label: 'Male', value: 'MALE' },
              { label: 'Female', value: 'FEMALE' },
            ].map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div style={{color:'red', fontSize:9}}></div>
        </div>
        <div class='col-md-6'>
          <label for='phoneNumber' class='form-label'>
            Phone Number
          </label>
          <input
            type='text'
            class='form-control'
            id='phoneNumber'
            //value={data.phoneNumber}
            required
            //onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
          />
          <div style={{color:'red', fontSize:9}}>
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
         <div>{formik.errors.phoneNumber}</div>
       ) : null}
            </div>
        </div>
        <div class='col-md-6'>
          <label for='password' class='form-label'>
            Password
          </label>
          <input
            type='password'
            class='form-control'
            id='password'
            //value={data.password}
            required
            //onChange={(e) => setData({ ...data, password: e.target.value })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <div style={{color:'red', fontSize:9}}>
          {formik.touched.password && formik.errors.password ? (
         <div>{formik.errors.password}</div>
       ) : null}
          </div>
        </div>
        <div class='col-md-6'>
          <label for='referenceCode' class='form-label'>
            Reference Code
          </label>
          <input
            type='text'
            class='form-control'
            id='referenceCode'
           // value={data.referenceCode}
            /*onChange={(e) =>
              setData({ ...data, referenceCode: e.target.value })
            }*/
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.referenceCode}
          />
        </div>
        <div class='col-12 text-center mt-5'>
          <button class='btn btn-primary' type='submit' >
            Submit form
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateSingleUserModal;
