import React,{useState} from "react";
import { Modal } from "react-bootstrap";
import useMatStyles from "../style";
import { DatePicker, Input, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useFormik,Formik, Field,Form, useField } from 'formik';
import * as Yup from 'yup';

const NewAdminUser = ({
  showModal,
  setShowModal,
  setData,
  data,
  units,
  handleSubmit,
  roles
}) => {
  const styles = useMatStyles();
  const [validateEmail, setValidateEmail] = useState(false);
  const [checkPhone, setCheckPhone] = useState('');

  const formik = useFormik({
    initialValues: {
            state: '',
      email: '',
      firstName: '',
      surname: '',
      dateOfBirth: '',
      gender: 'MALE',
      phoneNumber: '',
      password: '',
      referenceCode:'',
      roleName: '',
      accesstype: "",
      unit: ''
    },
    validationSchema: Yup.object({    
      firstName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      surname: Yup.string()                                     
        .max(20, 'Must be 20 characters or less')
        .required('Required'),      
      email: Yup.string().email('Invalid email address').required('Required')
      ,
      phoneNumber: Yup
      .string("Phone number is required")
      .matches(/^\d+$/, 'Phone number is not valid') 
       .min(13, "phone number should be of minimum 13 characters length")
       .max(13, "phone number should be of maximum 13 characters length")       
       .required("phone number is required"),
       password: Yup
       .string("Enter your password")
       .min(8, "Password should be of minimum 8 characters length")
       .required("Password is required"),
       accessType: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),

    }),
    onSubmit: values => {
      handleSubmit(values)
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const checkEmail=()=> false;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      width={1000}
    >
      <Modal.Header
        style={{
          borderBottom: "none",
          margin: "2rem 1rem 2rem 0",
        }}
        closeButton
      />

      <Modal.Body>
        <div className={styles.modalTitle}>Create New Admin User</div>
      <form class='row g-3 needs-validation' onSubmit={formik.handleSubmit}>

        <div className="row">
          <div className="col-md-6">
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>First Name</div>
              <input
               // value={data.firstName}
                className={styles.inputInput}
               /* onChange={(e) =>
                  setData({ ...data, firstName: e.target.value })
                }*/
                id='firstName'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                required
              />
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Last Name</div>
              <input
                //value={data.surname}
                className={styles.inputInput}
                //onChange={(e) => setData({ ...data, surname: e.target.value })}
                id='surname'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surname}
                required
              />
            </div>
            <div className={styles.inputCont}>
               <input
                  type='date'
                  class='form-control'
                  id='dateOfBirth'
                // value={data.dateOfBirth}
                  required
                  //onChange={(e) => setData({ ...data, dateOfBirth: e.target.value })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateOfBirth}
               />
            </div>
            <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
              <div className={styles.inputLabel}>Access Type</div>
              <select
                //onChange={(e) => setData({ ...data, gender: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accessType}
                className='form-select border'
                id='accessType'
                required
                placeholder="select access type"
              >
                <option>select access type</option>
                <option value={"initiator"}>Initiator</option>
                <option value={"approval"}>Approval</option>
              </select>
            </div>
            <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
              <div className={styles.inputLabel}>Select Unit</div>
              <select
                //onChange={(e) => setData({ ...data, gender: e.target.value })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.unit}
                className='form-select border'
                id='unit'
                required
                placeholder="select unit"
              >
                <option>select unit</option>
                {
                  units.map(e=>
                    <option value={e?.id}>{e?.name}</option>
                    )
                }
                
              </select>
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Reference Code</div>
              <input
              //  value={data.referenceCode}
                className={styles.inputInput}
               /* onChange={(e) =>
                  setData({ ...data, referenceCode: e.target.value })
                }*/
                id='referenceCode'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.referenceCode}
              />
            </div>
            
          </div>
          <div className="col-md-6">
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Phone Number</div>
              <input  
              required
              //  value={data.phoneNumber}
                className={styles.inputInput}
               /* onChange={(e) =>{
                  var numberRegex = /^\d+$/
                  const first3 = e.target.value.substring(0, 3);
                  if(first3 != '234'){
                    setCheckPhone('Phone Number must start with 234')
                  }else if(!numberRegex.test(e.target.value)){
                    setCheckPhone('Invalid phone number')
                  }                  
                  else if(e.target.value.length !==13 ){
                    setCheckPhone('Phone number must be 13 digits')
                  }
                  else{
                    setCheckPhone('')
                  }
                  setData({ ...data, phoneNumber: e.target.value })
                }}*/
                id='phoneNumber'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
              />
               <div style={{color:'red', fontSize:9}}>
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div>{formik.errors.phoneNumber}</div>
              ) : null}
            </div>

            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Email Address</div>
              <input
                id ='email'
                //value={data.email}
                className={styles.inputInput}
                /*onChange={(e) =>{
                if(e.target.value.indexOf('@wayapaychat.com')<0){
                  setValidateEmail('Invalid Email')
                }else{
                  setValidateEmail('')
                }
                setData({ ...data, email: e.target.value })
              }}*/
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              />
               <div style={{color:'red', fontSize:9}}>
          {formik.touched.email && formik.errors.email ? (
         <div>{formik.errors.email}</div>
       ) : null}
            </div>
            </div>
             
            <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
              <div className={styles.inputLabel}>gender</div>
              <select
                //onChange={(e) => setData({ ...data, gender: e.target.value })}
                id='gender'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
                className='form-select border'
                required
                placeholder="select gender"
              >
                <option>select gender</option>
                <option value={"MALE"}>MALE</option>
                <option value={"FEMALE"}>FEMALE</option>
              </select>
            </div>
            <div className={styles.inputCont}>
              <div className={styles.inputLabel}>Select Role</div>
              <select
                    className='form-select border'
                    // onChange={(e) => setData({ ...data, roleName: e.target.value })}
               id='roleName'
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               value={formik.values.roleName}
               required
               placeholder="select role"
              >
                <option>select role</option>
                {roles.length
                  ? roles.map((item) => (
                      <option value={item.name}>{item.name.replace(/_/g,' ')}</option>
                    ))
                  : ""}
              </select>
            </div> 
            
            <div className={styles.inputWrap}>
              <div className={styles.inputCont}>
                <div className={styles.inputLabel}>Password</div>
                <Input.Password
                  placeholder="password"
                  style={{
                    borderRadius: "5px",
                  }}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                 /* onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  value={data.password}
                  */
                 id='password'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </div>
              <div style={{color:'red', fontSize:9}}>
          {formik.touched.password && formik.errors.password ? (
         <div>{formik.errors.password}</div>
       ) : null}
            </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center btn">
         
            <button
            class='btn btn-danger text-white'
            type='submit'
          >
            Create New User
          </button>

        </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default NewAdminUser;
