import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { FaExternalLinkAlt, FaFilter, FaSistrix, FaBeer } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "../request-log/export";
import { Filter, FilterList, ThreeDRotation } from "@mui/icons-material";
import CopyToClipboardComponent from "../../../components/CopyToClipBoard";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import LoginTracker from "./loginTracker";

const AdminAuditLogs = () => {
  let roles= localStorage.getItem('roles')
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();

  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const styles = useMatStyles();
  const [logStat, setLogStat] = useState([])
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [totalRequest, setTotalRequest] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [status, setStatus] = useState('All')
  const [isTracker, setIsTracker] = useState(false)
  const getRequest = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let request;
    if(status==='All'){
    request = await httpGet(
      `${customBaseUrl.logUrl}/api/v1/requestlog/list?page=${pages}&size=${limit}`
    );}else{
       request = await httpGet(`${customBaseUrl.logUrl}/api/v1/requestlog/action/${status}`)   
      
    }
      console.log(request.data);
    if (request.status) {
      hideLoader()
      setAllRequests(request?.data?.requestLog)
      setTotalRequest(request.data.totalItems)
    }else{
      hideLoader();
      console.log(request)
    }
    hideLoader();
  };

  const getStat = async () => {
    let res = await httpGet(`${customBaseUrl.logUrl}/api/v1/requestlog/total-request`)

    if(res.status){
      setLogStat(res?.data)
    }
  }
  
  useEffect(() => {
    getStat();
    getRequest();
}, [status]);

  

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getRequest(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getRequest(newPage, pagination.pageSize);
  };

  const actionOptions = ["Approve", "Reject", "Delete"]
const handleAction = async(e, id)=>{
 // if(roles.indexOf('ROLE_ADMIN_OWNER') !==-1  ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1 ||roles.indexOf('ROLE_ADMIN_APPROVAL') !==-1) {
  let postData ={
    action: e==='Approve'?'APPROVED': e==='Reject'?'Reject':'Delete' ,
    requestId: id
  }
  showLoader()
 
 let res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/list`, postData)
  
  if(res.status){
    hideLoader();
    Swal.fire('Done', res.message)
    getRequest();
  }else{
    hideLoader()
    console.log(res)
    Swal.fire('Error', res?.message);
  }
/*}else{
  return NotificationManager.error('not authourize')
}*/
}

  var searchInput = useRef({});
  const columns = [    
    

    { field: 'createdBy',
      headerName: "Name",
      width: 250,
      headerClassName: styles.tableHeader,

    },
    { field: 'initiatorRole',
    headerName: "Role",
    headerClassName: styles.tableHeader,
    width: 200 
    },   
    { field: 'requestType',
    headerName: "Action Type",
    width: 200,
    headerClassName: styles.tableHeader,
    renderCell: ({row}) => row.requestType.includes('TRANSFER')?"TRANSFER":row.requestType?.toLowerCase()?.includes('paymentrequest')?"PAYMENT REQUEST":
    row.requestType.includes('UPDATE')?"UPDATE PROFILE":row.requestType.includes('ACTIVATION')?"ACCOUNT ACTIVATION":
    row.requestType.includes('ADDWALLET')?"ADD WALLET":row.requestType.includes('ADDACCOUNT')?"CREATE ACCOUNT":
    row.requestType.includes('ADDBANKACCOUNT')?"ADD BANK ACCOUNT":row.requestType.includes('DELETEBANKACCOUNT')?"DELETE BANK ACCOUNT":
    row.requestType.includes('ADDCARD')?"ADD BANK CARD":row.requestType.includes('DELETECARD')?"DELETE BANK CARD":
    row.requestType.includes('addKycRequirement')?"Add KYC Requirement":
    row.requestType.includes('updatingKycRequirement')?"Update KYC Requirement":
    row.requestType.includes('BILLSPAYMENT')?"BILLS PAYMENT":row.requestType
    },
   
    { field: 'dateCreated',
    headerName: "DATE",
    headerClassName: styles.tableHeader,
    width: 200 
    }   
   
  ];

  const filetrOp = ['APPROVED', 'REJECTED','Initiated', 'All']

  const handleFilter = (item)=>{
    setStatus(item)
  }
  
  return (
    <Layout>

     
        <Row type="flex" justify="start" align="left">     
        
        
        <Button
          style={{ fontSize: "10px", width:100, background: !isTracker?"#27AE60":"", color: !isTracker?"#fff":"#000" }}
          onClick={() => setIsTracker(false)}

        >          
          Audit Log
        </Button>       
        
        <Button
          style={{ fontSize: "10px", width:100, background: isTracker?"#27AE60":"", color: isTracker?"#fff":"#000" }}
          onClick={() => setIsTracker(true)}

        >          
          Login Tracker
        </Button>
       
    </Row>
{!isTracker?(<>
<div style={tool}>
 
<div  >
<Row  justify="start" >     

<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
          </Row>
        </div>
        <Row type="flex" justify="end" align="middle">     
        
        
            <Button
              style={{ fontSize: "10px", width:100, background: "#27AE60", color: "#fff" }}
              onClick={() => setShowExport(true)}

            >
              {" "}
              Export
            </Button>
           
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2 cards">
          <p>Total System Logs</p>
          <h2 className="text-left">{logStat?.totalRequest}</h2>
        </Card.Grid>        
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allRequests.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allRequests?.filter((request) =>
          request[search?.searchBy]
            ?.toLowerCase()
            ?.includes(search?.searchText?.toLowerCase())
        )}
            columns={columns}
            page={pagination.current}
          //  paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={totalRequest}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      </>): 
      <LoginTracker/>}
      
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests}  />
      }
    
    </Layout>
  );
};

const gridStyle = {
  width: "18%",
  textAlign: "center",
  backgroundColor: "#fff",
  fontWeight: "bold",
  marginLeft: "10px",
  display: 'flex',
  gap: '15px',
  marginTop: '30px',
  marginBottom: '30px',
  justifyContent:'space-between',
  flexDirection: 'column'
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default AdminAuditLogs;
