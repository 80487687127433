import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { GetSvgIcon } from '../svg';
import PayToEmail from './PayToEmail';
import PayToPhone from './PayToPhone';
import PayToWayaId from './PayToWayaId';
import { TransferWrapper as Wrapper } from './style';
import Summary from '../SummaryPage/summary';
import { customBaseUrl, httpPost } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';
import { emailValidator } from '../../utils/helper';
import { ProfileContext } from '../../store/context/ProfileContext';
import PayToAccount from './PayToAccount';

export const Transfer = ({ walletList, userId, charges }) => {
  const { profile } = useContext(ProfileContext);
  const [currentView, setCurrentView] = useState('default');
  const [tab, setTab] = useState('mail');
  const [data, setData] = useState({});
  const [walletListTwo, setWalletListTwo] = useState([]);
  const [balanceTo, setBalanceTo] = useState(null);
  const [balanceFrom, setBalanceFrom] = useState(null);
  const [requestType, setRequestType] = useState('wayaUser');
  const [balance, setBalance] = useState(null);
  const [displayData, setDisplayData] = useState({});
  const [otp, setOtp] = useState('');
  const [query, setQuery] = useState('');
  const [ selectedCharge, setSelectedCharge] = useState('');


  const handleSubmit = async () => {


    let d  = new Date();
    let ref = d.getTime();
    let transData = {
      amount: data.amount,
      benefAccountNumber: data.beneficialAcctNo,
      debitAccountNumber: data.senderAcctNo,
      paymentReference: `WAYA${ref}SELF`,
      tranCrncy: "NGN",
      tranNarration: "Waya self funding",
      tranType: "TRANSFER",
      userId: profile.userId

    }

    
    showLoader();
    /*
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/admin/sendmoney`,
        transData
    );
*/
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(transData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "TRANSFERToAccount",
      customerName: data?.acct_name
    } )
    if (res.status) {
      Swal.fire(res.message);
      hideLoader();
      setCurrentView('default');
      setData({});
      setOtp('');
    } else {
      hideLoader(false);
      Swal.fire('Error', res.message || 'Something went wrong', 'error');
    }
  };


  const handlePayToAccount = async () =>{
    let d  = new Date();
    let ref = d.getTime();
   
    if(!data || !data.crAccount){
    return    Swal.fire(`Incomplete infomation`);

    }

    showLoader()
    const localData = {
      "amount": data.amount,
      "benefAccountNumber": data.crAccount,
      "debitAccountNumber": data.senderAccountNumber,
      "paymentReference": ref,
      "tranCrncy": "NGN",
      "tranNarration": "internal transfer",
      "tranType": "LOCAL",
      "userId": profile.userId,
    }

  
/*
    let res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/admin/sendmoney`,
      localData
      );
*/
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(localData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "TRANSFERToAccount",
      customerName: data?.acct_name

    } )

      if (res.status) {
        Swal.fire(res.message);
        hideLoader();
        setCurrentView('default');
        setData({});
        setOtp('');
      } else {
        hideLoader(false);
        Swal.fire('Error', res.message || 'Something went wrong', 'error');
      }
  }



  const handleWallet = (acctNo) => {
    const removedSelected = [...walletList].filter(
      (item) => item.accountNo !== acctNo
    );
    setWalletListTwo(removedSelected);
  };

  const handlePayToEmail = async () => {
    if (!data.email && data.email !== '') {
      Swal.fire('Wrong', 'Email cannot be empty', 'error');
      hideLoader();
      return;
    }

    if (!emailValidator(data.email)) {
      Swal.fire('Wrong', 'Invalid email format', 'error');
      hideLoader();
      return;
    }
    if (!data.amount && data.email !== '') {
      Swal.fire('Wrong', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }

    const postData = {
      description: data.reason,
      amount: data.amount,
      senderId: userId,
      senderAccountNumber: data.senderAccountNumber,
      beneficiaryEmail: data.email
    };
   // console.log('parse', JSON.parse(postData))
    //console.log('stringi', JSON.stringify(postData))

//return alert(JSON.stringify(JSON.parse(postData)))
showLoader();

   /* const res = await httpPost(
      `${customBaseUrl.contactUrl}/contact/account/service/send/money/to/email/${data.email}`,
      postData
    );*/
    let d= new Date()
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "TRANSFERToEmail",
      customerName: data?.acct_name

    } )
    // console.log(res);
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      setCurrentView('default');
      setData({});
      setOtp('');
    } else {
      hideLoader();
      Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
    }
  };

  const handlePayToPhone = async () => {
    showLoader();

    if (!data.phoneNumber) {
      Swal.fire('Wrong', 'Phone number cannot be empty', 'error');
      hideLoader();
      return;
    }

    if (!data.amount) {
      Swal.fire('Wrong', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }

    const postData = {
      amount: data.amount,
      description: data.reason,
      senderId: userId,
      senderAccountNumber: data.senderAccountNumber,
      beneficiaryPhoneNo: data.phoneNumber
    };
/*
    const res = await httpPost(
      `${customBaseUrl.contactUrl}/contact/account/service/send/money/to/phoneNumber/${data.phoneNumber}`,
      postData
    );*/

    let d= new Date()
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "TRANSFERToPhone",
      customerName: data?.acct_name

    })
    // console.log(res);
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      setData({});
      setOtp('');
      setCurrentView('default');
    } else {
      hideLoader();
      Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
    }
  };

  const handlePayToId = async () => {
    showLoader();
    if (!data.userId) {
      Swal.fire('Wrong', 'User cannot be empty', 'error');
      hideLoader();
      return;
    }
    if (!data.amount) {
      Swal.fire('Wrong', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }

    const postData = {
      amount: data.amount,
      description: data.reason,
      senderId: userId,
      senderAccountNumber: data.senderAccountNumber,
      beneficiaryId: data?.userId
    };
/*
    const res = await httpPost(
      `${customBaseUrl.contactUrl}/contact/account/service/send/money/to/userId/${data.userId}`,
      postData
    );*/
    let d= new Date()
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "TRANSFERToUserId",
      customerName: data?.acct_name

    })
    // console.log(res);
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      setData({});
      setOtp('');
      setCurrentView('default');
    } else {
      hideLoader();
      Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
    }
  };

  const handlePayToNonWaya = async () => {
    showLoader();
    if (tab === 'email') {
      if (!data.email) {
        Swal.fire('Wrong', 'Email cannot be empty', 'error');
        hideLoader();
        return;
      }

      if (!emailValidator(data.email)) {
        Swal.fire('Wrong', 'Invalid email formtat', 'error');
        hideLoader();
        return;
      }
    }

    if (tab === 'phone') {
      if (!data.phoneNumber) {
        Swal.fire('Wrong', 'Phone number cannot be empty', 'error');
        hideLoader();
        return;
      }
    }

    if (!data.amount) {
      Swal.fire('Wrong', 'Amount cannot be empty', 'error');
      hideLoader();
      return;
    }

    const postData = {
      tranNarration: data.reason,
      tranCrncy: 'NGN',
      fullName: data.name,
      amount: Number(data.amount),
      senderId: profile.userId,
      customerDebitAccountNo: data.senderAccountNumber,
      paymentReference: `${data.senderAccountNumber}${Math.random(
        Math.random()
      )}`,
      emailOrPhoneNo: tab === 'mail' ? data.email : data.phoneNumber,
    };

    /*
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new`,
      postData
    );
    */
    let d= new Date()
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(postData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "TRANSFERToNonWaya",
      customerName: data?.acct_name

    })
    // console.log(res);
    if (res.status) {
      hideLoader();
      Swal.fire('Done', res.message, 'success');
      setData({});
      setOtp('');
      hideLoader();
      setCurrentView('default');
      setRequestType('wayaUser');
    } else {
      hideLoader();
      Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
    }
  };

  const handleSendOtp = async () => {
    const phone = profile.phoneNumber.slice(1);
    // const sender = '2348057661075';
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/otp/generate/${phone}`
    );

    if (res.status) {
      hideLoader();
      Swal.fire('Done', `Otp sent successful`, 'success');
    } else {
      hideLoader();
      Swal.fire('Wrong', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyOtp = async () => {
    showLoader();
    // const sender = tab === 'mail' ? data.email : data.phoneNumber;
    const phone = profile.phoneNumber.slice(1);
    const res = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/otp/payment/verify`,
      {
        otp,
        phoneOrEmail: phone,
      }
    );

    if (res.status) {
      if (tab === 'phone') {
        if (requestType === 'wayaUser') handlePayToPhone();
        if (requestType === 'nonWayaUser') handlePayToNonWaya();
      } else if (tab === 'wayaId') {
        handlePayToId();
      } else if (tab === 'mail') {
        if (requestType === 'wayaUser') handlePayToEmail();
        if (requestType === 'nonWayaUser') handlePayToNonWaya();
      } else if (tab === 'ownAccount') {
        handleSubmit();
      } else if ( tab === 'wayaAcc'){
        handlePayToAccount();
      }
      hideLoader();
    } else {
      hideLoader();
      Swal.fire('Wrong', res?.message,'Something went wrong', 'error');
    }
  };

  return (
    <Wrapper>
      {currentView === 'default' && (
        <div className='wrapper'>
          <div
            className='button-cont'
            onClick={() => setCurrentView('ownAccount')}
          >
            <GetSvgIcon type='wallet' />
            <div>Transfer to Own Account</div>
            <div>{`>`}</div>
          </div>
          <div
            className='button-cont'
            onClick={() => setCurrentView('otherAccount')}
          >
            <GetSvgIcon type='sync-alt' />
            <div>Transfer to Other Account</div>
            <div>{`>`}</div>
          </div>
        </div>
      )}

      {currentView === 'ownAccount' && (
        <div className='contentBar'>
          <div className='mb-2'>
            <select
              defaultValue={data.senderAcctNo}
              onChange={(e) => {
                const [accountNo, amount, acct_name] = e.target.value.split(',');
                setData({
                  ...data,
                  senderAcctNo: accountNo,
                  acct_name
                });
                handleWallet(accountNo);
                setBalanceFrom(amount);
              }}
            >
              <option value='' selected disabled>
                Transfer From
              </option>
              {walletList.length
                ? walletList.map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}, ${item?.acct_name}`}
                      key={item.accountNo}
                    >
                      {`${item?.acct_crncy_code}-${item?.accountNo}`}
                    </option>
                  ))
                : ''}
            </select>
            {data.senderAcctNo ? (
              <span className='input-detail'>
                Your Balance is N{balanceFrom || 0}.00
              </span>
            ) : (
              ''
            )}
          </div>

          <div className='mb-2'>
            <select
              defaultValue={data.beneficialAcctNo}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setData({
                  ...data,
                  beneficialAcctNo: accountNo,
                });
                setBalanceTo(amount);
              }}
            >
              <option value=''>Transfer to</option>
              {walletListTwo.length
                ? walletListTwo.map((item) => (
                    <option
                      value={`${item?.accountNo},${item?.clr_bal_amt}`}
                      key={item.accountNo}
                    >
                      {`${item?.acct_crncy_code}-${item?.accountNo}`}
                    </option>
                  ))
                : ''}
            </select>
            {data.beneficialAcctNo ? (
              <span className='input-detail'>
                Your Balance is N{balanceTo || 0}.00
              </span>
            ) : (
              ''
            )}
          </div>
          <input
            className='mb-2'
            type='text'
            placeholder='Amount'
            value={data.amount}
            onChange={(e) => {
              setData({ ...data, amount: e.target.value });
            }}
          />
          <button
            onClick={() => {
              handleSendOtp();
              setTab('ownAccount');
              setCurrentView('summary');
              setDisplayData({
                receiverName: data.beneficialAcctNo,
                amount: data.amount,
                senderName: data.acct_name||'',
                tranFees: charges?.status=='CUSTOM'?charges?.customAmount:charges?.generalAmount,
                bank: '',
                description: '',
              });
            }}
          >
            Transfer
          </button>
        </div>
      )}
      {currentView === 'otherAccount' && (
        <>
          <div className='tabBar'>
            <div
              onClick={() => {
                setTab('mail');
                setData({});
              }}
              className={`tabBar-item ${tab === 'mail' ? 'tB-active' : ''}`}
            >
              Pay with Email
            </div>
            <div
              onClick={() => {
                setTab('wayaId');
                setData({});
              }}
              className={`tabBar-item ${tab === 'wayaId' ? 'tB-active' : ''}`}
            >
              Pay with WAYA ID
            </div>
            <div
              onClick={() => {
                setTab('wayaAcc');
                setData({});
              }}
              className={`tabBar-item ${tab === 'wayaAcc' ? 'tB-active' : ''}`}
            >
              Pay to Account
            </div>
            <div
              onClick={() => {
                setTab('phone');
                setData({});
              }}
              className={`tabBar-item ${tab === 'phone' ? 'tB-active' : ''}`}
            >
              Pay to Phone
            </div>
          </div>
          <div className='contentBar'>
            {tab === 'mail' ? (
              <PayToEmail
                data={data}
                setData={setData}
                setRequestType={setRequestType}
                requestType={requestType}
              />
            ) : tab === 'wayaId' ? (
              <PayToWayaId
                setData={setData}
                data={data}
                setQuery={setQuery}
                query={query}
              />
            ) : tab === 'phone' ? (
              <PayToPhone
                data={data}
                setData={setData}
                setRequestType={setRequestType}
                requestType={requestType}
              />
            ): (
              <PayToAccount
                data={data}
                setData={setData}
                setRequestType={setRequestType}
                requestType={requestType}
              />
            )}

            <input
              className='mb-2'
              type='text'
              placeholder='Amount'
              value={data.amount}
              onChange={(e) => {
                setData({ ...data, amount: e.target.value });
              }}
            />
            <input
              className='mb-2'
              type='text'
              placeholder='Add a Note(optional)'
              value={data.reason}
              onChange={(e) => {
                setData({ ...data, reason: e.target.value });
              }}
            />
        <input
              className='mb-2'
              type='password'
              placeholder='Pin'
              value={data.pin}
              onChange={(e) => {
                setData({ ...data, pin: e.target.value });
              }}
            />

            <div className='mb-2'>
              <select
                defaultValue={data.senderAccountNumber}
                onChange={(e) => {
                  const [acccountNo, amount, acct_name] = e.target.value.split(',');
                  setData({
                    ...data,
                    senderAccountNumber: acccountNo, 
                    acct_name
                  });
                  setBalance(amount);
                }}
              >
                <option value='Select' selected disabled>
                  Transfer From
                </option>
                {walletList.length
                  ? walletList.map((item) => (
                      <option
                        value={`${item?.accountNo},${item?.clr_bal_amt}, ${item?.acct_name}`}
                        key={item.accountNo}
                      >
                        {`${item?.acct_crncy_code}-${item?.accountNo}-${item?.acct_name}`}
                      </option>
                    ))
                  : ''}
              </select>
              {data.senderAccountNumber ? (
                <span className='input-detail'>
                  Your Balance is N{balance || 0}.00
                </span>
              ) : (
                ''
              )}
            </div>
            <button
              onClick={() => {

                handleSendOtp();
                setCurrentView('summary');
                setDisplayData({
                  receiverName: tab === 'phone' ? data.phoneNumber : tab === 'wayaAcc'?data.crAccountName: data.email,
                  amount: data.amount,
                  senderName: data.acct_name||'',
                  bank: '',
                  description: data.reason,
                  tranFees: charges?.status=='CUSTOM'?charges?.customAmount:charges?.generalAmount,

                });
              }}
            >
              Transfer
            </button>
          </div>
        </>
      )}
      {currentView === 'summary' ? (
        <div
          style={{
            width: '60%',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <Summary
            displayData={displayData}
            otp={otp}
            setOtp={setOtp}
            handleClick={() => {
              handleVerifyOtp();
            }}
          />
        </div>
      ) : (
        ''
      )}
      {currentView !== 'default' && (
        <div className='backButton'>
          <button
            onClick={() => {
              setCurrentView('default');
              setData({});
              setOtp('');
            }}
          >
            Go Back
          </button>
        </div>
      )}
    </Wrapper>
  );
};
