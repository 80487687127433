import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import ColoredSwitcher from '../../../components/customSwitch/coloredSwitcher';
import { Modal } from 'antd';
import CreateNewCount from './updateDays';
import SearchDropdown from '../../../components/CustomDropdown/SearchDropdown';
import { searchUsers } from '../../../store/httpCalls';
import { useMatStyles } from './style';

const UpdateUserReferral = ({ showModal, hideModal, className, allReferralTypes, row, getTemporalReversalDays}) => {
  const styles = useMatStyles();
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [counts, setCounts] = useState([]);
  const [showCreateNew, setShowCreateNew] = useState(false)
  const [newDayCount,setNewDayCount] =useState(false)
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('')
 
  
  const handleSelectOption = (selectedUser) => {
    setSelectedUserId(selectedUser.userId);
    setQuery(selectedUser.firstName+' '+ selectedUser.surname)
    setOptions([]);
  };

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      const result = await searchUsers(e.target.value);
      setOptions(result);
    }
  
  }

  const handleUpdate = async() => {
    // if (!selectedUserId) return Swal.fire('Error', 'Select User', 'User Not Selected')
    showLoader();
    let res = await httpPut(`${customBaseUrl.referralUrl}/api/v1/update/referral/type/${row?.userId}/${data?.referralTypeId}`,
    {})


      if(res.status){
        hideLoader();
        Swal.fire("Done", res.message, "Done")
      hideModal(false)
      }else{
        hideLoader()
        console.log(res)
        Swal.fire('Failed', res.message, 'Error')
      }

  }

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => hideModal(false)}
      width={400}
      footer={0}

    >
        <div>
          <h5>Update Referral Type</h5>
        </div>

    
        <div style={{ marginTop: "5px" }} className={styles.inputCont}>
        <div >Referral Type</div>
          <select
            onChange={(e) => setData({
              ...data,
              referralTypeId: Number(e.target.value)
            })}
            // value={postData?.referralTypeId ?? null}
            className={`${styles.inputInput} border`}
            >
            <option  selected>...</option>  
            {allReferralTypes?.map(e=>
              <option value={e?.referralTypeId} key={e?.referralTypeId}>
                {e?.referralDescription}
              </option>
            )}              
          </select>
        </div>

        <div class='col-12 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={handleUpdate}>
            Save changes
          </button>
        </div>

                   
        
    </Modal>
  );
};

export default UpdateUserReferral;
