import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { BugOutlined, CloseCircleFilled, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import { Pagination, Stack } from "@mui/material";

function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

const WalletForms = ({ showModal, hideModal, title,className}) => {
    let d = new Date();
    let ref = d.getTime();
  const initialState = {
    accountName:"",
    description: "",
    crncyCode: "NGN",
    productCode: "OABAS",
    productGL: "11104",
    accountType: "Official"    

  };
  const [data, setData] = useState(initialState);
  const [accounts, setAccounts] = useState([{ ...initialState }]);
  
  const [page, setPage] = useState(1);
  const clearState = () => {
    setData({ ...initialState });
  };

  const handleChange = (event, value) => {
    const isEmpty = checkProperties(data);
    if (isEmpty) {
      Swal.fire("Warning", "Please fill all the fields", "warning");
    } else {
      console.log(data);
      event.preventDefault();
      setAccounts((prevState) => [data, ...prevState]);
      setPage(accounts.length + 1);
      clearState();
    }
  };
  const handlePaginateChange = (event, pageV) => {
    const currentIndex = pageV - 1;
    setPage(pageV);
    setData(accounts.find((item, index) => index === currentIndex));
    console.log(pageV);
    console.log(accounts);
  };
  useEffect(() => {
    console.log(accounts);
    let dataRec = accounts;
    dataRec[page - 1] = data;
    setAccounts(dataRec);
  }, [data]);

  const handleRemoveUser = async () => {
    let current = page === 1 ? 1 : page - 1;
    await setAccounts((prevState) =>
      page !== 1
        ? prevState.filter((_, i) => i !== current)
        : [{ ...initialState }]
    );
    await setPage((prevState) => (prevState === 1 ? 1 : prevState - 1));
  };

  const handleCreate = async () => {
    showLoader();

 

    const response = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/official/waya/account-multiple`,
        accounts
        );
        
    if (response[0].status) {
      hideLoader();
      Swal.fire('Done', "Account Created Successfully", 'success');
      hideModal(false)
    } else {
      hideLoader();
      Swal.fire('Error', response.message, 'error');
    }
  };




  return (
    <Modal
    className={className}
    centered
    visible={showModal}
    onOk={() => hideModal(false)}
    onCancel={() => hideModal(false)}
    width={800}
    footer={0}
    closable={false}
    title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >


        
      <Wrapper class='row g-3 needs-validation border mb-2 p-3'>
        
        <div class='col-md-6'>
            <div className="label"> Account Name</div>
            <div className="input-content">
              <input
                class='form-control'
                value={data.accountName}
                onChange={(e) =>{
                  setData({ ...data, accountName: e.target.value })
                }
              }
              />
            </div>
          </div> 
           
        <div class='col-md-6'>
            <div className="label"> Description</div>
            <div className="input-content">
              <input
                class='form-control'
                value={data?.description}
                onChange={(e) =>{
                  setData({ ...data, description: e.target.value })
                }
              }
              />
            </div>
          </div>      
      </Wrapper>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >

      </div>
      <div style={{ marginTop: "10px" }} className="row">
        <div
          style={{
            
           
          }}
          className="col-md-2"
        >
          <Button
            className="iconUI"
            style={{ color: "#C4C4C4", border: "1px solid #C4C4C4" }}
            onClick={handleChange}
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-2">
          <Button
            className="iconUI"
            onClick={handleRemoveUser}
            style={{ color: "#FF4B01", border: "1px solid #FF4B01" }}
            icon={<CloseOutlined />}
          >
            Remove 
          </Button>
        </div>
        <div className="col-md-6">
          <Button
            className="iconUI"
            type="ghost"
            onClick={handleCreate}
            style={{ color: "#fff", background: "#FF4B01", float: "right" }}
          >
            Proceed
          </Button>
        </div>
      </div>

      <div className="row" style={{ marginTop: "7px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <Stack spacing={2}>
            <Pagination
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                alignSelf: "center",
              }}
              count={accounts.length}
              page={page}
              onChange={handlePaginateChange}
            />
          </Stack>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Modal>
  );
};

export default WalletForms;
