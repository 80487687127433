import React, { useState } from 'react';
import { BankCardWrapper as Wrapper } from './style';
import More from '../../assets/icons/more.svg';
import BankLogo from '../../assets/icons/bankLogo.png';
import Close from '../../assets/icons/close.svg';

const BankCard = ({
  accName,
  accNumber,
  expDate,
  dropDownItem,
  handleDropDown,
  data,
  bankIcon,
}) => {
  const [dropDown, setDropDown] = useState(false);
  return (
    <Wrapper>
      <div className="bc-left">
        <div className="bc-left-item">
          <div className="bcl-title">Account Name</div>
          <div className="bcl-details">{accName}</div>
        </div>
        <div className="bc-left-item">
          <div className="bcl-title">Card Number</div>
          <div className="bcl-details">{accNumber}</div>
        </div>
        <div className="bc-left-item">
          <div className="bcl-title">VALID THRU</div>
          <div className="bcl-details">{expDate}</div>
        </div>
      </div>
      <div className="bc-right">
        <div className="bc-right-more">
          <img
            src={dropDown === true ? Close : More}
            alt="more"
            onClick={() => setDropDown(!dropDown)}
            onKeyPress={() => setDropDown(!dropDown)}
            role="presentation"
            tabIndex="-1"
          />

          {dropDown ? (
            <div className="bc-dropdown">
              {dropDownItem?.length
                ? dropDownItem.map((item) => (
                    <div
                      className={`bc-drop-item ${item.style}`}
                      key={item.id}
                      onClick={() => handleDropDown(item?.id, data)}
                    >
                      {item.label}
                    </div>
                  ))
                : ''}
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          {//<img src={bankIcon || BankLogo} alt="" />
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default BankCard;
