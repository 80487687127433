import styled from 'styled-components';

const Wrapper = styled.div`

.linkedCard {
    width: 100%;
    .card-div {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 160px;
      margin-botton: 35px;
    }
    .add-div {
      background: #ffffff;
      border: 1px dashed #c2c2c2;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #d7d7d7;
      display: flex;
      flex-direction: column;
      padding: 23px;
      width: 32%;
      justify-content: center;
      align-items: center;
    }
  }
`;
export default Wrapper;