import styled from "styled-components";

export const WalletCardWrapper = styled.div`
  height: 150px;
  width: 90%;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
   margin: 21px;
  /* width: 32%; */
  .wc-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wc-icon-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #a8a8a8;
      justify-self: center;
    }
    .wc-more {
      position: relative;
      cursor: pointer;
      .wc-dropdown {
        position: absolute;
        width: 160px;
        height: auto;
        right: 0;
        top: 28px;
        background: #ffffff;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
        border-radius: 3px;
        padding: 9px 12px;
        display: flex;
        flex-direction: column;
        .wc-drop-item {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          padding-bottom: 7px;
        }
      }
    }
  }
  .wc-value {
    background: "red";
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 31px;
    color: #000000;
  }
  .text-grey {
    color: #a8a8a8;
  }
  .wc-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wcb-item {
      display: flex;
      flex-direction: column;
      .wcb-title {
        font-weight: 200;
        font-size: 8px;
        line-height: 11px;
        color: #a8a8a8;
      }
      .wcb-detail {
        font-weight: 600;
        font-size: 10px;
        line-height: 11px;
        font-type: Lato;
        color: #000000;
        text-transform: capitalize;
      }
    }
  }
`;

export const BankCardWrapper = styled.div`
  height: 160px;
  padding: 17px 18px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .bc-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .bc-left-item {
      display: flex;
      flex-direction: column;
      .bcl-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #a8a8a8;
      }
      .bcl-details {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }
  }
  .bc-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .bc-right-more {
      position: relative;
      cursor: pointer;
      .bc-dropdown {
        position: absolute;
        width: 120px;
        height: auto;
        right: 0;
        top: 28px;
        background: #ffffff;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        padding: 9px 12px;
        display: flex;
        flex-direction: column;
        .bc-drop-item {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          padding-bottom: 7px;
        }
      }
    }
  }
`;
export const BankAccountWrapper = styled.div`
  height: 117px;
  padding: 17px 18px;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .bc-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .bc-left-item {
      display: flex;
      flex-direction: column;
      .bcl-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #a8a8a8;
      }
      .bcl-details {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }
  }
  .bc-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .bc-right-more {
      position: relative;
      cursor: pointer;
      .bc-dropdown {
        position: absolute;
        width: 120px;
        height: auto;
        right: 0;
        top: 28px;
        background: #ffffff;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        padding: 9px 12px;
        display: flex;
        flex-direction: column;
        .bc-drop-item {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          padding-bottom: 7px;
        }
      }
    }
  }
`;
