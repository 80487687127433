import React, {useEffect} from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { customBaseUrl, httpGet, httpPost } from '../../store/http';
import { hideLoader, showLoader } from '../../utils/loader';

export default function PayToAccount({
  data,
  setData,
  setRequestType,
  requestType,
}) {

const [postData, setPostData] =useState({})


const resolveBank = async (value) => {
    
    showLoader();
    if (value === '') {
      Swal.fire('Account Number is required');
      return;
    }
    const newPostData = {
      accountNumber: value,
      bankCode: postData.bankCode,
      // bankName: postData.bankName,
    };

    let res;
      res = await httpGet(
        `${customBaseUrl.walletUrl}api/v1/wallet/info/${value}`
      );
  
    
    if (res.status) {
      hideLoader();
      setData({
        ...data,
        crAccountName: res.data.acct_name ,
        crAccount: value,
      });
    } else {
        setData({
            ...data,
            crAccountName: '' ,
            crAccount: value,
          })
      Swal.fire(res.message);
    }
    hideLoader();
  };


  return (
    <>
        <input
                className="mb-2"
              type='text'
              placeholder="Recipient's Account Number"
              value={data.crAccount}
              onChange={(e) => {
                if (e.target.value?.length > 9) {
                  resolveBank(e.target.value);
                }
                setData({...data, crAccount: e.target.value})

              }}
            />
            <input
             className="mb-2"
            disabled={true}
              type='text'
              placeholder="Recipient's Account Name"
              value={data.crAccountName}
            />
        


    </>
  );
}
