import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import Wrapper from "./style";
import { CloseCircleFilled } from "@ant-design/icons";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../../utils/loader";
import BillsPayment from "./billspayment";

const PerformTransaction = ({ showModal, hideModal, title, wallets ,banks, userId}) => {
  const [data, setData] = useState({
    tranCrncy: "NGN",
    eventId: "COMPAYM",
    transactionCategory: "COMMISSION",
    userId:userId
  });
  const [selectedWallet, setSelectedWallet] = useState({});
  const [selectedRecipientType, setSelectedRecipientType] = useState("Single User");
  const [transactionType, setTransactionType] = useState('Wallet Transfer');
  const [receipientCategory, setReceipientCategory] = useState('waya');
  const [userData,setUserData]= useState({})
  const [userDataList, setUserDataList] = useState([])
  const [categories, setCategories] = useState([]);
  const [quickTeller, setQuickTeller] = useState(false)
  useEffect(() => {
    console.log(selectedWallet);
    getCategories();
  }, [selectedWallet]);
  
  const getCategories = async (userId) => {
    //  return alert(userId);

    let res;
    const resp = await httpGet(`${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`)

    let aggregator = resp?.data;

    let quick = aggregator.filter(e=>e.aggregator==='QUICKTELLER')

    if(quick[0].active){
      setQuickTeller(true)
     /*  res = await httpGet(
        `${customBaseUrl.billPaymentUrl}/api/v1/getCategories`
      );*/
      res = await httpGet(
        `${customBaseUrl.billPaymentUrl}/api/v1/category`
      );

       }else{
     res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category`
    );
   }

     // alert(JSON.stringify(res));
      if (res?.status) {
        setCategories(res.data);
      }
    };

  const handleTransaction = async () => {
    let d = new Date();
    let ref = d.getTime();
    const {acct_name, accountNo} = JSON.parse(selectedWallet);

    const walletToUserData = {
      amount: data.amount,
      customerCreditAccount: data.creditAccount ,
      officeDebitAccount: selectedWallet,
      paymentReference: `WAYA-${ref}`,
      tranCrncy: data.tranCrncy?data.tranCrncy:'NGN',
      tranNarration: transactionType==="Wallet Transfer"? "Official wallet to user wallet":transactionType==="Office Transfer"?"Official wallet to foficial wallet":transactionType==="Temporal Wallet"?"Transfer from temporal wallet to official account":'Official WAYA wallet to Bank Transfer',
      tranType: "TRANSFER",
      officeCreditAccount: data.creditAccount,
      bankCode: data.bankCode,
      bankName: data.bankName,
      crAccount: data.creditAccount,
      crAccountName: data.crAccountName,
      saveBen: true,
      transRef: `WAYA${ref}`,
      userId,
      walletAccountNo: selectedWallet,
      narration: data.narration||"waya transaction",
      transactionPin: data.pin,

    }


    let transferData={
      "amount": data?.amount,
      "customerCreditAccount": data.creditAccount,
      "officeDebitAccount": accountNo,
      "paymentReference": `WAYA${ref}`,
      "tranCrncy": 'NGN',
      "tranNarration": "official wallet to user wallet",
      "tranType": "TRANSFER"
      
    }

    const bankTransferData = {
      
        "amount": data?.amount,
        "narration": "string",
        transactionPin: data.pin,
        bankCode: data.bankCode,
        bankName: data.bankName,
        crAccount: data.creditAccount,
        crAccountName: data.crAccountName,
        saveBen: true,
        transRef: `WAYA${ref}`,
        userId,
        "walletAccountNo": accountNo
      }
    



    const nonWayaData = {
      amount: data.amount,
      emailOrPhoneNo: data.email || data.phone,
      fullName: data.fullname,
      officialAccountNumber: accountNo,
      paymentReference: `WAYA${ref}`,
      tranCrncy: "NGN",
      tranNarration: "Official wallet to non waya user" 
    }

    const temporalToOfficialData = {
      amount: data.amount,
      customerAccountNumber: data.customerAccount, 
      officialAccountNumber: selectedWallet,   
      transactionCategory: "TRANSFER",
      tranNarration: "Transfer from temporal to Official wallet",
      paymentReference: `WAYA${ref}`,
      tranCrncy: "NGN",


    }
    
    let NonWayaRequestData = {
      "amount": data.amount,
      "createdAt": d,
      "reason": data.reason,
      "receiverEmail": data.receiverEmail,
      "receiverName": data.receiverName,
      "receiverPhoneNumber": data.receiverPhoneNumber,
      "senderEmail": "admin@wayapaychat.com",
      "senderId": accountNo,
      "senderName": "WAYA OFFICIAL",
      "wayaOfficial": true
    }
    let wayaRequestData = {
      "amount": data.amount,
      "createdAt": d,
      "reason": data.reason,
      "receiverId": data.receiverId,
      "receiverEmail": data.receiverEmail,
      "receiverName": data.receiverName,
      "receiverPhoneNumber": data.receiverPhoneNumber,
      "senderEmail": "admin@wayapaychat.com",
      "senderId": accountNo,
      "senderName": "WAYA OFFICIAL",
      "wayaOfficial": true
    }

    let NonayaRequestData = {
      "amount": data.amount,
      "createdAt": d,
      "reason": data.reason,
      "receiverEmail": userData.email,
      "receiverName": userData.fullName,
      "receiverId": userData.receiverId,
      "receiverPhoneNumber": userData.phoneNumber,
      "senderEmail": "admin@wayapaychat.com",
      "senderId": selectedWallet,
      "senderName": "WAYA OFFICIAL",
      "wayaOfficial": true,

    }

    let officialTransferData={      
        "amount": data.amount,
        "officeCreditAccount": data.creditAccount,
        "officeDebitAccount": accountNo,
        "paymentReference": `WAYA-${ref}`,
        "tranCrncy": "NGN",
        "tranNarration": "Official wallet to foficial wallet",
        "tranType": "TRANSFER"      
    }

    showLoader();
    let response;
    if(transactionType === 'Wallet Transfer' && selectedRecipientType === 'Single User'){
     let d= new Date();
       response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
      {
        payload: JSON.stringify(transferData),
        "requestId": `REQ${d.getTime()}`,
        "requestType": "officialToWallet",
        customerName: acct_name
      } )
          /*
      response = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/official/user/transfer`,
        transferData
      )*/
    
    }else if(transactionType === 'Office Transfer' && selectedRecipientType === 'Single User' ){
        
      let d= new Date();
      response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
     {
       payload: JSON.stringify(officialTransferData),
       "requestId": `REQ${d.getTime()}`,
       "requestType": "fundingOfficial",
       customerName: acct_name
     } )
     /*/
        response = await httpPost(
          `${customBaseUrl.walletUrl}/api/v1/wallet/official/transfer`,
          officialTransferData
      
    )*/
  
  }else if(transactionType === 'Bank Transfer' && selectedRecipientType === 'Single User' ){
      
      let d= new Date();
      response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
     {
       payload: JSON.stringify(bankTransferData),
       "requestId": `REQ${d.getTime()}`,
       "requestType": "officialToBank",
       customerName: acct_name
     } )/*/
      response = await httpPost(
          `${customBaseUrl.withdrawalUrl}/admin/withdrawal/official/fund`,
          bankTransferData      
    )*/
  }else if(transactionType === 'Non Waya Transfer' && selectedRecipientType === 'Single User' ){
    let d= new Date();
    response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
   {
     payload: JSON.stringify(nonWayaData),
     "requestId": `REQ${d.getTime()}`,
     "requestType": "officialToNonwaya",
     customerName: acct_name
   } )/*/
    response = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/new-single-waya-official`,
        nonWayaData      
    )*/
  }else if(transactionType === 'Temporal Wallet' && selectedRecipientType === 'Single User' ){
      response = await httpPost(
        `${customBaseUrl.walletUrl}/api/v1/wallet/event/office/temporal-to-official`,
        temporalToOfficialData      
    )}else if(transactionType === 'Payment Request' && selectedRecipientType === 'Single User' && receipientCategory === 'nonWaya'){
      
      let d= new Date();
      response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
     {
       payload: JSON.stringify(NonWayaRequestData),
       "requestId": `REQ${d.getTime()}`,
       "requestType": "paymentRequest",
       customerName: acct_name
     } )
     /*
      response = await httpPost(
        `${customBaseUrl.paymentRequestUrl}/api/v1/admin/payment-request-non-waya`,
        NonWayaRequestData
      )
    */
    }else if(transactionType === 'Payment Request' && selectedRecipientType === 'Single User' && receipientCategory === 'waya'){
       
      let d= new Date();
      response = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
     {
       payload: JSON.stringify(wayaRequestData),
       "requestId": `REQ${d.getTime()}`,
       "requestType": "officialPayReq",
       customerName: acct_name
     } )
     /*/
      response = await httpPost(
          `${customBaseUrl.paymentRequestUrl}/api/v1/admin/waya/official-single`,
          wayaRequestData
        )*/
      }
    else{
      hideLoader()
      Swal.fire({
        title: "Error",
        text: "Try again later",
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    
    }
    /*
     response = await httpPost(
      `${customBaseUrl.commissionUrl}/api/v1/transfer/single-user-commission`,
      data
    );*/
    if (response.status === true) {

      hideLoader();
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
      hideModal(false);

    } else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };


  const resolveBank = async (value) => {
    showLoader();
    if (value === '') {
      Swal.fire('Account Number is required');
      return;
    }
    const newPostData = {
      accountNumber: value,
      bankCode: data.bankCode,
      // bankName: postData.bankName,
    };
    const res = await httpPost(
      `${customBaseUrl.cardUrl}/bankAccount/resolveAccountNumber/WD`,
      newPostData
    );


    if (res.status) {
      hideLoader();
      setData({
        ...data,
        crAccountName: res.data,
        creditAccount: value,
      });
    } else {
      Swal.fire(res.message);
    }
    hideLoader();
  };

  const searchUser = async(searchNum)=>{
    if(searchNum.length>6){      
      let res =await  httpGet(`${customBaseUrl.authUrl}/api/v1/search/search-profile-phoneNumber/${searchNum}`)
      if(res.status){
        console.log(res.data)
        setUserDataList(res.data)
      }
    }
  }
  
  const transactionTypes = ['Wallet Transfer', 'Bank Transfer', 'Bills Payment', 'Non Waya Transfer', 'Payment Request', 'Office Transfer'];
  const RecipientTypes = ['Single User', 'Excel Upload', 'web Forms' ];
  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      title={
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {title}
            <div
              style={{
                cursor: "pointer",
                fontSize: "22px",
                float: "right",
              }}
              onClick={() => {
                hideModal(false);
              }}
            >
              <CloseCircleFilled />
            </div>
          </div>
        </>
      }
      bodyStyle={{
        padding: "23px 73px 44px 73px",
        borderRadius: "12px",
      }}
    >
      <Wrapper>
        <div className="cw-content">
          <div className="input-content">
            <div className="label">Transaction Type </div>
            <select onChange={(e)=>{
              setTransactionType(e.target.value)
            }} className='border border-dark rounded'>
            <option value=''>...</option>
              {transactionTypes.map((item, index) => 
                 <option key={index} value={item}>{item}</option>
              )
            }
            </select>
          </div>
         {transactionType !=='Bills Payment' && <div>
          <div className="input-content">
            <div className="label">Select Waya Official Wallet</div>
          <select onChange={(e) =>{setSelectedWallet(e.target.value);console.log('wall',e.target.value)}} className='border border-dark rounded'>
             <option value=''>...</option>
              {wallets? wallets.map((x) => (
                <option key={`wallet${x.id}`} value={JSON.stringify(x)}>
                  {x.acct_name+ ' '+x?.accountNo}
                </option>
              )):''}
            </select>
          </div>
          
          <div className="input-content">
            <div className="label"> Recipient Type</div>
            <div className="input-content">
            <select value={selectedRecipientType} onChange={(e) => setSelectedRecipientType(e.target.value)} className='border border-dark rounded'>
            <option value=''>Single user</option>
        
            </select>
            </div>
          </div>
          </div>}

          {transactionType === 'Payment Request' && <div className="input-content">
            <div className="label"> Recipient category</div>
            <div className="input-content">
            <select value={receipientCategory} onChange={(e) => setReceipientCategory(e.target.value)} className='border border-dark rounded'>
            <option value='waya'>Waya user</option>
            <option value='nonWaya'>Non waya user</option>            
            </select>
            </div>
          </div>}


          {selectedRecipientType === 'Single User'  && (
          <div>
       {transactionType ==='Bank Transfer' &&<div>
        <div className="input-content">
            <div className="label">Select  Bank</div>
          <select onChange={(e) =>{ setData({...data, bankCode: JSON.parse(e.target.value).bankCode, bankName: JSON.parse(e.target.value).bankName})}} className='border border-dark rounded'>
             <option value=''>...</option>
              {banks? banks.map((x) => (
                <option key={`banks${x.id}`} value={JSON.stringify(x)}>
                  {x.bankName}
                </option>
              )):''}
            </select>
          </div>
          <div className="input-content">
            <div className="label">Account Number </div>
            <input
              value={data.creditAccount}
              onChange={(e) => {
                  if (e.target.value?.length > 9) {
                    resolveBank(e.target.value);
              }
              setData({...data, creditAccount: e.target.value})
            }}
            />
          </div>
          <div className="input-content">
            <div className="label">Account Name </div>
            <input
              value={data.crAccountName}
            />
          </div>
          </div>
          }
          {
            transactionType === 'Office Transfer' &&
              <div className="input-content">
              <div className="label">Recipient Wallet </div>
             
              <select onChange={(e) =>{
                setData({...data, creditAccount: e.target.value})}  } className='border border-dark rounded'>
             <option value=''>...</option>
              {wallets? wallets.map((x) => (
                <option key={`wallet${x.id}`} value={x.accountNo}>
                  {x.acct_name+ ' '+x?.accountNo}
                </option>
              )):''}
            </select>
            </div>
          }
         {transactionType !== 'Non Waya Transfer' &&transactionType !== 'Office Transfer' && transactionType !=='Bank Transfer' && transactionType !=='Payment Request' && transactionType !== 'Bills Payment' &&
         <div>
         <div className="input-content">
            <div className="label">Account Number </div>
            <input
              value={data.creditAccount}
              onChange={(e) => {
                setData({...data, creditAccount: e.target.value, customerAccount: e.target.value})
              }}
            />
          </div>
         {/*} <div className="input-content">
            <div className="label">Account Name </div>
            <input
              value={data.crAccountName}
              onChange = {e=>setData({...data, crAccountName: e.target.value})}
            />
          </div>
            */}
          </div>
          }
           {transactionType !=='Bills Payment' &&
          <div className="input-content">
            <div className="label">Amount </div>
            <input
              type="number"
              value={data.amount}
              onChange={(e) => setData({ ...data, amount: e.target.value })}
            />
          </div>}
          {transactionType ==='Non Waya Transfer'  &&<div>
          <div className="input-content">
            <div className="label"> Full Name</div>
            <div className="input-content">
              <input
                type= 'text'
                value={data.fullname}
                onChange={(e) =>
                  setData({ ...data, fullname: e.target.value })
                }
              />
            </div>
          </div>
          <div className="input-content">
            <div className="label"> Email Address</div>
            <div className="input-content">
              <input
                type= 'email'
                value={data.email}
                onChange={(e) =>
                  setData({ ...data, email: e.target.value })
                }
              />
            </div>
          </div>
          <div className="input-content">
            <div className="label">Mobile Number </div>
            <input
              value={data.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
            />
          </div>
          </div>}

          {transactionType === 'Payment Request' &&<div>
          {receipientCategory === 'waya' &&
         <div className="input-content">
         <div className="label"> Receiver ID {data.receiverId}</div>
         <div className="input-content">
           <input
             type= 'text'
             value={data.receiverId}
             onChange={(e) =>
               setData({ ...data, receiverId: e.target.value })
             }
           />
           </div>
           </div>
           }
          <div className="input-content">
            <div className="label"> Receiver Name</div>
            <div className="input-content">
              <input
                type= 'text'
                value={data.receiverName}
                onChange={(e) =>
                  setData({ ...data, receiverName: e.target.value })
                }
              />
            </div>
          </div>
          <div className="input-content">
            <div className="label"> Email Address</div>
            <div className="input-content">
              <input
                type= 'email'
                value={data.receiverEmail}
                onChange={(e) =>
                  setData({ ...data, receiverEmail: e.target.value })
                }
              />
            </div>
          </div>
          <div className="input-content">
            <div className="label">Mobile Number </div>
            <input
              value={data.receiverPhoneNumber}
              onChange={(e) => setData({ ...data, receiverPhoneNumber: e.target.value })}
            />
          </div>
          <div className="input-content">
            <div className="label"> Reason</div>
            <div className="input-content">
              <input
                type= 'email'
                value={data.reason}
                onChange={(e) =>
                  setData({ ...data, reason: e.target.value })
                }
              />
            </div>
          </div>
          </div>}
          {/*transactionType === 'Payment Request' && receipientCategory === 'waya' &&<div>

          <div className="input-content">
            <div className="label"> Receiver Phone number</div>
            <div className="input-content">
              <input
                type= 'text'
                placeholder='23480123456'
                onChange={(e) =>searchUser(e.target.value)}
              />
            </div>
          </div>
          <div>
            <select 
            className="input-content  border"
            onChange={(e)=>{setUserData({...userData, phoneNumber: JSON.parse(e.target.value).phoneNumber, email:JSON.parse(e.target.value).email, userId:JSON.parse(e.target.value).userId, fullName:JSON.parse(e.target.value).firstName+ ' '+JSON.parse(e.target.value).surname })}}>
              <option value=''>Choose</option>
              {userDataList.length>0&&userDataList.map(item=>(
                <option value={JSON.stringify(item)}>{item?.firstName} {item?.surname} </option>
              ))}
            </select>

            </div>

          <div className="input-content">
            <div className="label"> Email Address</div>
            <div className="input-content">
              <input
                value={userData?.email}
               
              />
            </div>
          </div>
          <div className="input-content">
            <div className="label">Mobile Number </div>
            <input
              value={userData?.phoneNumber}
            />
          </div>
          <div className="input-content">
            <div className="label"> Reason</div>
            <div className="input-content">
              <input
                type= 'email'
                value={data.reason}
                onChange={(e) =>
                  setData({ ...data, reason: e.target.value })
                }
              />
            </div>
          </div>
              </div>*/}
          {
            transactionType === 'Bills Payment' &&
            <BillsPayment quickTeller={quickTeller} wallets={wallets} categories={categories} hideModal={hideModal}/>
          }
          {transactionType !=='Bills Payment' && <div className="input-content">
            <div className="label">PIN </div>
            <input
               type='password'
              value={data.pin}
              onChange={(e) => setData({ ...data, pin: e.target.value })}
            />
          </div>}

          </div>     
          )}
        </div>
      </Wrapper>

      {transactionType !=='Bills Payment' &&     <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            background: "#FF4B01",
            color: "#fff",
            borderRadius: "3px",
            fontSize: "14px",
            lineHeight: "17px",
            display: "flex",
            alignItems: "center",
            padding: "10px 25px",
          }}
          onClick={handleTransaction}
        >
          Proceed
        </Button>
      </div>}
    </Modal>
  );
};

export default PerformTransaction;
