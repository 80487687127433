import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    mTitle: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#000000',
      marginBottom: '20px',
    },
    btnCont: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    bcItem: {
      background: '#FF4B01',
      borderRadius: '3px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
      padding: '16px 23px 14px 23px',
      width: '48.5%',
      marginBottom: '16px',
    },
    soWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    soLabel: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
      color: '#000000',
      marginBottom: '9px',
    },
    soInput: {
      background: ' #FFFFFF',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      borderRadius: '5px',
      padding: '12px',
      marginBottom: '75px',
    },
    soButton: {
      background: ' #FF4B01',
      borderRadius: '3px',
      padding: '10px 36px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
    },
    tableHeader: {
      background: "#ff6700",
      fontFamily: "'Inter'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      // lineHeight: "15px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      color: "#ffffff",
    },
  }));
  export default useStyles;