import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import {Button as MatButton} from "@mui/material"
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { ExportOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../components/Layout";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../assets/images/no-data.png";
import color from "../../theme/color";
import CreateSingleUserModal from "../../components/Modal/User/CreateSingleUser";
import AntTable from "../../components/CustomTable/AntTable";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import CreateBulkUser from "../../components/Modal/User/CreateBulkUser";
import UserActionsModal from "../../components/Modal/User/UserActions";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
} from "@mui/material";
import DeactivateUsers from "../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { transformName } from "../../utils/helper";
import { hideLoader, showLoader } from "../../utils/loader";
import { downloadFile } from "../../utils/fileDownloader";
import Swal from "sweetalert2";
import { getColumnSearchProps } from "../../components/CustomTableSearch";
import CreateBulkForm from "../../components/Modal/User/CreateBulkForm";
import { NotificationManager } from "react-notifications";
import EmailInvite from "./modals/emailInvite";
import SocialInvites from "./modals/socialInvites";
import ExportCSV from "./export";
import VerifyUser from "./verify";
import PinModal from "../../components/Modal/Pin/PinModal";
import { Lock } from "@mui/icons-material";
import moment from "moment";
import { CSVLink } from "react-csv";
import generatePDF from "./generatePdf";
const UserProfiles = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const roles = localStorage.getItem('roles');
  const [users, setUsers] = useState([]);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showBulkSms, setShowBulkSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showCreateUserExcel, setShowCreateUserExcel] = useState(false);
  const [showCreateUserForms, setShowCreateUserForms] = useState(false);
  const [showSocial, setShowSocial] = useState(false)
  const [showUserAction, setShowUserAction] = useState(false);
  const [showDeactivateUser, setShowDeactivateUser] = useState(false);
  const [searchField, setSearchField] = useState(false);
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 100,
  });
  const [filteredDate, setFilteredDate] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [field, setField] = useState('')
  const [selectedRecord, setRecord] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "lastName",
    searchText: "",
  });
  const [totalRegisteredUsers, setTotalRegisteredUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [totalCorporateUsers, setTotalCorporateUsers] = useState(0);
  const [totalIndividualUsers, setTotalIndividualUsers] = useState(0);
  const [showExport, setShowExport] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showVerifyAccount, setShowVerifyAccount] = useState(false);
  const [otp, setOtp] = useState('')
  const [phone, setPhone] = useState('')


  const handleGetUsers = async (params = {}) => {
    console.log('fffff',params)
    params?.preventDefault();
    setLoading(true);
    showLoader();
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);

    const pages = pagination.current ?? 0;
    const limit = pagination.pageSize ?? 100;

    const res = await httpPost(
        `${customBaseUrl.authUrl}/api/v1/admin/filterProfiles?endDate=${moment(filteredDate?.todate ?? new Date()).format('YYYY-MM-DD')}&field=${filteredData?.filterField ?? 'all'}&pageNo=${pages + 1}&pageSize=${limit}&value=${filteredData?.filterValue ?? 'all'}`
     );
    console.log(res);
    setLoading(false);
    if(res?.status===false) Swal.fire(res?.message)
    hideLoader();
    
    if(res?.status){
      const filter = clean(filteredData);
        setUsers(res?.data?.content);
      /*const filtered = res?.data?.content?.filter(e=>  {
        console.log('fd', filteredData)
        return Object.keys(filter).every(key => {
          return (key === 'firstName' || key === 'surname') ? filter[key]?.toLowerCase() === e[key]?.toLowerCase() : filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });*/
      //console.log('filtered', filtered)
      //if(filtered?.length < 1) NotificationManager.error('Record not found' );
      //setUsers(filtered);
      setPagination({
        ...params.pagination,
        total: res?.data?.totalElements,
      });
      hideLoader();
    } else {
      hideLoader()
      console.log(res);
    }
    hideLoader();
  };

  const handleDeleteUser = async (id) => {
  if(roles.indexOf('ROLE_ADMIN_OWNER') ===-1 ||roles.indexOf('ROLE_ADMIN_APP') ===-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') ===-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1) return NotificationManager.error('not authrize')

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        showLoader();
        const res = await httpDelete(
          `${customBaseUrl.authUrl}/api/v1/user/delete/${id}`
        );
        if (res.status === true) {
          hideLoader();
          NotificationManager.success("User Deleted Successfully");
          handleGetUsers({pagination});
        }
        else{
          hideLoader();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          })
        }
      }
    });
  };


  const getFilteredUsers = async (page, size) => {
    showLoader();
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);

    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpPost(
      `${customBaseUrl.authUrl}/api/v1/admin/filterProfiles?endDate=${moment(searchData?.searchDate ?? new Date()).format('YYYY-MM-DD')}&field=${filteredData?.filterField ?? 'all'}&pageNo=${pages + 1}&pageSize=${limit}&value=${filteredData?.filterValue ?? 'all'}`
    );
    if (response?.status === true) {

      hideLoader();
      setUsers(response?.data?.content);
      setPagination({
        ...pagination,
        total: response?.data?.totalElements,
      });
    } else {
      hideLoader();
    }
  };

  useEffect(() => {
    getFilteredUsers();
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetCredentials = (user) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you are about to send reset OTP to this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset!",
    }).then((result) => {
      if (result.value) {
        const res = httpGet(
          `${customBaseUrl.authUrl}/api/v1/password/change-password/byEmail?email=${user.email}`
        );
        if (res) {
          NotificationManager.success("Credentials Reset Successfully");
          // handleGetUsers({pagination});
        }
      }
    });
  }

  const handleRToggleLock = (userId, record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `you are about to ${record?.accountNonLocked?"UnLock" : "Lock"} this user!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${record?.accountNonLocked?"Yes, unblock!": "Yes, lock"}`,
    }).then((result) => {
      if (result.value) {
        const res = httpPost(
          `${customBaseUrl.authUrl}/api/v1/admin/users/lock-toggle/un-lock?userId=${userId}`,{}
        );
        if (res) {
          NotificationManager.success(res?.message);
          // handleGetUsers({pagination});
        }
      }
    });
  }
  const verify = async (email) => {
    showLoader()
    setPhone(email);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${email}`
    );
    if (res.status) {
      hideLoader();
      Swal.fire("Done", res.message, "success").then(() => {
        setShowVerify(false);
        setShowVerifyAccount(true);
      });
    } else {
      hideLoader();
      Swal.fire('error', res?.message)
    }
  }
  const completeVerification = async () => {
    showLoader();
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
    };
    const res = await httpPost(`${customBaseUrl.authUrl}/api/v1/auth/verify-otp`,
      postData,
    );
    if (res.status) {
      hideLoader();
      setOtp("");
      setPhone("");
      setShowVerifyAccount(false);
      // setShowVerifyOtp(false);
      Swal.fire("Done", res.message, "success")
    } else {
      hideLoader();
      setOtp("");
      Swal.fire("Oops!", res.message, "error");
    }
  };
  const handleDropdownAction = (item, id, record) => {
    setSelectedId(id);
    setRecord(record);
    localStorage.setItem("selectedUser", JSON.stringify(record));
    if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_APP') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){

    if (item === "Manage Transaction") setShowUserAction(true);
    if (item === "Manage Kyc") navigate(`/users_kyc/${id}`);
    if (item === "Deactivate User" || item === "Activate User") handleDactivateUser(id);
    if (item === "Manage Profile") handleOpenProfile(id);
    if (item === "Login Tracker") handleOpenLoginTracker(id);
    if (item === "Manage Team Members")navigate(`/users/manage_team/${id}`);
    if (item === "Verify") verify(record?.email??record?.phoneNumber);
    //  NotificationManager.warning("Coming Soon !");
    if (item === "Activity Log") navigate(`/user/activity_log/${id}`);
    if (item === "Delete User") handleDeleteUser(id);
    if (item === "Reset Credentials") handleResetCredentials(record);
    if (item === "Block User" || item === "Unblock User" 
    || item ==="Unlock Transaction" || item ==="Lock Transaction") handleRToggleLock(id, record);

    }else{
      NotificationManager.error('Not authorized');
    }
  };

  const handleOpenLoginTracker = (id) => {
    navigate(`/user/login_tracker/${id}`);
  };
  const handleOpenProfile = (id) => {
    const item = users.find((user) => user.userId === id);
    localStorage.setItem("user_profile", JSON.stringify(item));
    navigate(`/users/profile/${id}`);
  };

  var searchInput = useRef({});
  const columns = [
    {
      title: "SURNAME",
      dataIndex: "surname",
      sorter: (a, b) => a.surname - b.surname,
      width: "10%",
      ...getColumnSearchProps(
        "surname",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: "FIRSTNAME",
      dataIndex: "firstName",
      sorter: (a, b) => a.firstName - b.firstName,
      width: "10%",
      ...getColumnSearchProps(
        "firstName",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: "PHONE NUMBER",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      dataIndex: "phoneNumber",
      ...getColumnSearchProps(
        "phoneNumber",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: "EMAIL",
      sorter: (a, b) => a.email - b.email,
      dataIndex: "email",
      width: "10%",
      ...getColumnSearchProps("email", setSearchData, searchData, searchInput),
    },
    {
      title: "USER TYPE",
      dataIndex: "corporate",
      sorter: (a, b) => a.corporate - b.corporate,
      render: (usertype) => `${usertype === true ? "Business" : "Personal"}`,
    },
    {
      title: "STATUS",
      dataIndex: "active",
      sorter: (a, b) => a.active - b.active,
      render: (active) => {
        return active === true ? (
          <Badge status="success" text="Active" />
        ) : (
          <Badge status="error" text="Inactive" />
        );
      },
    },
    {
      title: "Account Locked",
      dataIndex: "accountNonLocked",
      render: (active) => {
        return active === true ? (
          "True"
        ) : (
          "False"
        );
      },
    },
    {
      title: "Account Delete",
      dataIndex: "deleted",
      render: (active) => {
        return active === true ? (
          "True"
        ) : (
          "False"
        );
      },
    },
    {
      title: "Account Expired",
      dataIndex: "accountNonExpired",
      render: (active) => {
        return active === true ? (
          "True"
        ) : (
          "False"
        );
      },
    },
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      render: (createdAt) => createdAt,
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "ACTION",
      dataIndex: "userId",
      render: (userId, record) => (
        <AntDropdown
          dropDownOptions={[
            "Manage Profile",
            "Manage Team Members",
            "Manage Transaction",
            "Manage Kyc",
            "Login Tracker",
            "Activity Log",
            "Reset Credentials",
            record?.active?"":'Verify',
            record?.active===true?"Deactivate User":"Activate User",
            record?.accountNonLocked?"Unblock User":"Block User",
            record?.accountNonLocked?"Unlock Transaction":"Lock Transaction",
           
          ]}
          handleDropdownAction={(item) =>
            handleDropdownAction(item, userId, record)
          }
          userId={userId}
          isArrow
        />
      ),
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const handleOpenDeactivateUsers = () => {
    if(roles.indexOf('ROLE_ADMIN_OWNER') !==-1 ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1) {
    setShowDeactivateUser(true);
    }else{
      return NotificationManager.error('not authrize!')
    }
  };
  const handleDactivateUser = async (userId) => {
    showLoader();
   /* const res = await httpPost(
      `/api/v1/admin/users/activation-toggle?userId=${userId}`,
      {},
      customBaseUrl.authUrl
    );
    */

    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify({userId}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "ACTIVATIONTOGGLE",
      customerName: selectedRecord?.firstName+' '+selectedRecord?.surname
    } )

    hideLoader();
    if (res.status === true) {
      setShowDeactivateUser(false);
      Swal.fire(res.message, "success");
      // handleGetUsers({ pagination });
    } else {
      Swal.fire(res.message, "error");
    }
  };

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const fields = ['regNo','emailOrPhone','name', 'profileType','organizationName','organizationType','businessType','date' ]
  const tCategory = ['BUSINESS','PERSONAL' ]

  return (
    <>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 6 }}
      ></Grid>
            <div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10, backgroundColor: "#ff6700"}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div>
      <div hidden={!searchField} style={{background: "#ffffff", padding: 10}}>
        <form onSubmit={handleGetUsers}>
      <div class="row">
      {/* <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Profile Type</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, filterField: 'profileType', filterValue: e?.target?.value})}
        >
          <option selected>Choose...</option>
          <option value='all'>ALL</option>
          <option value='BUSINESS'> BUSINESS</option>
          <option value='PERSONAL'> PERSONAL</option>

        
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Admin</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, admin: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true} >TRUE</option>
          <option value={false}>FALSE</option>         
        </select>        
      </div> */}
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      {/* <div className="row">
        
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">First Name</span>        
          <input type="text" aria-label="Ref" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, firstName: e?.target?.value})}

          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Last Name</span>        
          <input type="text" class="form-control"  aria-label="tranId"
            onChange={(e)=>setFilteredData({...filteredData, surname: e?.target?.value})}

          />
        </div>
      </div>
      <div className="row">
      
      <div class="input-group mb-3 col">
       <span class="input-group-text" id="basic-addon3">Phone Number</span>        
        <input type="text" aria-label="First name" class="form-control"
          onChange={(e)=>setFilteredData({...filteredData, phoneNumber: e?.target?.value})}
        />
      </div>
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Email</span>        
        <input type="email" class="form-control"  aria-label="Email"
           onChange={(e)=>setFilteredData({...filteredData, email: e?.target?.value})}
        />
      </div>
    </div> */}
      <div class="row">
      {/* <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Active</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, active: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true}> TRUE</option>
          <option value={false}> FALSE</option>

        
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Account Locked</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, deleted: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true} >TRUE</option>
          <option value={false}>FALSE</option>         
        </select>        
      </div> */}
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      
      <div class="row">
      {/* <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Account Expired</span>
        <select class="form-select" id="inputGroupSelect01"
          onChange={(e)=>setFilteredData({...filteredData, accountNonExpired: e?.target?.value === 'true' ? true : e?.target?.value === 'false' ? false: null})}
        >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true}> TRUE</option>
          <option value={false}> FALSE</option>

        
        </select>
      </div>
      <div className="input-group mb-3 col">
          <span className="input-group-text">Account Deleted</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, deleted: e?.target?.value === 'true' ?true : e?.target?.value === 'false' ? false: null})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value={true} >TRUE</option>
          <option value={false}>FALSE</option>         
        </select>        
      </div> */}
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      <div className="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Field</span>
        <select class="form-select" id="inputGroupSelect01" 
          required
          onChange={(e)=>{ 
            setField(e.target.value);      
            setFilteredData({...filteredData, filterField: e?.target?.value})
            setPagination({...pagination, current: 0})
          
          }
          }
        >
          <option selected>Choose...</option>
          {
            fields?.map((e,i)=>
            <option key={i} value={e}>{e.replace('_',' ').toUpperCase()}</option>
          )
          }
        </select>
      </div>
      {field === 'profileType'?<div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Value</span>
        <select class="form-select" id="inputGroupSelect01" 
        required
          onChange={(e)=>{
            setValue(e.target.value)      
            setFilteredData({...filteredData, filterValue: e?.target?.value})
            setPagination({...pagination, current: 0})
          
          }
          }
        >
          <option selected>Choose...</option>
          {
            tCategory?.map((e,i)=>
            <option key={i} value={e}>{e.replace('_',' ')}</option>
          )
          }
        </select>
      </div>: field === 'date' ?
        <div className="row">
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, filterField: 'date', filterValue: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>
      :
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Value</span>
        <input  
        onChange={(e)=>setFilteredData({...filteredData, filterValue: e?.target?.value})}

        class="form-select" id="inputGroupSelect01" />
      </div>}


      </div>
      
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Search</button>
      </div>
      </form>
      </div>
      <div >
        <div class="col-md-12">
          <MatButton
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <ExportOutlined/>         
            <CSVLink
          data={users}
          style={{            
            color: "#fff",
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`users.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </MatButton> 
          <MatButton
            //className="update-button-1"
            onClick={() => generatePDF(users, filteredDate)}
            variant="contained" 
            color="success"
          >
            <ExportOutlined/> 
            <div>DOWNLOAD AS PDF</div>
          </MatButton>           
          
      </div>
      </div>

      {users.length ? (
        <div className="mt-3">
          <AntTable
            pagination={pagination}
            users={users}
            loading={loading}
            handleGetNext={handleGetUsers}
            columns={columns}
          />
        </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No User yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Your users will appear here when you onboard them
          </p>
        </>
      )}

      {showDeactivateUser ? (
        <DeactivateUsers
          showModal={showDeactivateUser}
          hideModal={setShowDeactivateUser}
          title="Deactivate Single User"
          className={styles.modal}
          handleDactivateUser={handleDactivateUser}
          users={users}
        />
      ) : (
        ""
      )}
      {showCreateUser ? (
        <CreateSingleUserModal
          showModal={showCreateUser}
          hideModal={setShowCreateUser}
          title="Single User"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showCreateUserExcel ? (
        <CreateBulkUser
          showModal={showCreateUserExcel}
          hideModal={setShowCreateUserExcel}
          title="Upload Excel Sheet to Create Users"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showCreateUserForms ? (
        <CreateBulkForm
          showModal={showCreateUserForms}
          hideModal={setShowCreateUserForms}
          title="Create Single or Bulk Users Online"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showUserAction ? (
        <UserActionsModal
          showModal={showUserAction}
          hideModal={setShowUserAction}
          className={styles.modal}
          userId={selectedId}
          record = {selectedRecord}
        />
      ) : (
        ""
      )}

{showEmail  ? (
        <EmailInvite
          showModal={showEmail}
          hideModal={setShowEmail}
          className={styles.modal} 
          type='email'       
        />
      ) : (
        ""
      )}

{showBulkSms  ? (
        <EmailInvite
          showModal={showBulkSms}
          hideModal={setShowBulkSms}
          className={styles.modal} 
          type='sms'       
        />
      ) : (
        ""
      )}
      {showSocial  ? (
        <SocialInvites
          showModal={showSocial}
          hideModal={setShowSocial}
          className={styles.modal} 
        />
      ) : (
        ""
      )}

      {showExport? (
        <ExportCSV
          showModal={showExport}
          hideModal={setShowExport}
          dataList={users}
          />) : (
            ""
          )   
      }
      {showVerify && (
        <VerifyUser
        showModal={VerifyUser}
        hideModal={setShowVerify}        
    title = 'kkk'
    description='kkk'
    buttonLabel='kkkj'
    handleSubmit='kjs'
    loading='ooo'
        />     
      ) 
      }
 {showVerifyAccount ? (
        <PinModal
          center
          showModal={showVerifyAccount}
          hideModal={setShowVerifyAccount}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description={`Please input the OTP sent to ${phone}`}
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeVerification}
          loading={loading}
          numInputs={6}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )}
 
    </>
  );
};

const gridStyle = {
  width: "19%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default UserProfiles;
