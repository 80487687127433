import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
  } from "@mui/material";
  import '../../../index.scss';
import { customBaseUrl, httpGet, httpPost, httpPostUnreloaded, httpPut } from '../../../store/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import ColoredSwitcher from '../../../components/customSwitch/coloredSwitcher';
import { Modal, Pagination, Switch, Table } from 'antd';
import CreateNewPayoutDate from './newPayoutDate';
import { EditFilled } from '@ant-design/icons';
//import CreateNewCount from './updateDays';

const CHangePayout = ({ showModal, hideModal, className, setComOpt}) => {
  const [data, setData] = useState({});
  const { profile } = useContext(ProfileContext);
  const [counts, setCounts] = useState([]);
  const [showCreateNew, setShowCreateNew] = useState(false)
  const [newDayCount,setNewDayCount] =useState(false)
    const [payoutDate, setPayoutDate] = useState([]);
    const [payoutCount, setPayoutCount] = useState(0)
    const [pagination, setPagination] = useState({
        current: 0,
        pageSize: 20,
      });
    const [record, setRecord] = useState([])
    const [viewEditDate, setViewEditDate] = useState(false)
    const handleToggle = async (id) => {
      showLoader();
      const response = await httpPut(
        `${customBaseUrl.commissionUrl}/api/v1/admin/toggle-payout-date/${id}`
      );
      hideLoader();
      if (response.status === true) {
        Swal.fire(response.message)
        // handleProviderChange(vendorIndex, response?.data?.active);
        handleGetPyoutList(pagination.current, pagination.pageSize);
      }
    };

    const handleGetPyoutList = async (page, size) => {
        showLoader();
        const pages = page? page : pagination.current;
        const limit = size ? size : pagination.pageSize;
        
        let request = await httpGet(
          `${customBaseUrl.commissionUrl}/api/v1/admin/manage-payout-date/list?page=${page}&size=${size}`
        );
          console.log('lll',request?.data?.commissionPayoutList);
        if (request.status) {
            hideLoader()
          setPayoutDate(request?.data?.commissionPayoutList);
          setPayoutCount(request?.data?.totalItems);
        }else{
            console.log()
            hideLoader();
        }
      };

  
  
  const handleCreateNew = async() =>{
        showLoader();

        let res= await httpPost(`${customBaseUrl.referralUrl}/api/v1/admin/config/transaction-count`,
        {
            numberOfTransaction: newDayCount
        }  )

        if(res.status){
            setShowCreateNew(false)
            hideLoader()
            console.log(res)
            handleGetPyoutList(pagination.current,20);
            setNewDayCount('')
            Swal.fire('Success', res.message,'Done')
        }else{
            console.log('err',res)
            hideLoader();
            Swal.fire('failed', res.message,'Error')

        }
    

  }

  const handleEdit = (record) =>{
    setRecord(record)
    setViewEditDate(true)
  }

  const handleChangePage = async (newPage) => {
     console.log(newPage);
     setPagination({ ...pagination, current: newPage-1 });
     handleGetPyoutList(newPage-1, pagination.pageSize);
   };
   const handleChangeRowsPerPage = async (page, size) => {
     //alert(size)
     setPagination({ ...pagination, current: 0, pageSize: size });
     handleGetPyoutList(0, size);
   };
   const columns = [ 
    { 
    key: "payoutDate", 
    title: "Payout Date", 
    dataIndex: "payoutDate", 
    render: (text, record) => `${new Date(text).getDate()} - ${new Date(text).getMonth()} - ${new Date(text).getFullYear()}`

    }, 
    { 
    key: "active", 
    title: "Status", 
    dataIndex: "active", 
    render: (text, record) =>  text?'Active':'Inactive'
    }, 
    { 
    key: "userType", 
    title: "User Type", 
    dataIndex: "userType", 
    },
    {
        key: "act",
        title: ' ',
        dataIndex: 'active',
        render: (text, record)=> <Switch checked={text} onChange={()=>handleToggle(record.id)} />
    },
    /*{
        key: "act",
        title: ' ',
        dataIndex: 'active',
        render: (text, record)=> <EditFilled onClick={()=>{handleEdit(record)}} />
    }*/
  
    ]
  useEffect(() => {
    handleGetPyoutList(0,20);
  }, []);

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onCancel={() => {hideModal(false);setComOpt('history')}}
      width={600}
      footer={0}
    >
        <div class='col-6 text-end mt-5'>
          <button class='btn btn-primary' type='submit' onClick={()=>setShowCreateNew(true)}>
            Create New Payout Date
          </button>
        </div>

        <Card
          style={{
            transitionDuration: "0.3s",
            minHeight: "30vw",
            display: "block",
          }}
          sx={{ marginTop: "30px" }}
          raised={true}
        >

        <CardContent>
          
        <div className="table"> 
        <Table dataSource={payoutDate} columns={columns} pagination={false} 
         onRow={(record, rowIndex) => {
            return {
              onClick: event => {}, // click row
            };
          }}
        showHeader={true}
        /> 
         <Pagination  onShowSizeChange={handleChangeRowsPerPage} pageSize={pagination.pageSize} defaultCurrent={pagination.current} onChange={handleChangePage} total={payoutCount}  />
        </div>
        </CardContent>
          </Card>
                      {
                        showCreateNew &&
                            <CreateNewPayoutDate
                            showModal={showCreateNew}
                            hideModal= {setShowCreateNew}
                            />
                        
                    }
                     {
                        viewEditDate &&
                            <CreateNewPayoutDate
                            showModal={viewEditDate}
                            hideModal= {setViewEditDate}
                            viewEditDate={viewEditDate}
                            record={record}
                            />
                        
                    }
        
    </Modal>
  );
};

export default CHangePayout;
