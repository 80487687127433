import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import { Button, DatePicker, Divider, Input, Space, Table } from "antd";
import moment from "moment";
import { customBaseUrl, httpGet } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import { getColumnSearchProps } from "../../../components/CustomTableSearch";

const { RangePicker } = DatePicker;

const dateFormat = "DD MMM YYYY";

const UserLoginTracker = () => {
  
  const { id: id } = useParams();
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [dataSource, setDataSource] = useState(logs);
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  var searchInput = useRef({});
  const [value, setValue] = useState('');

  const FilterByNameInput = (
    <Input
      placeholder="Search By Login Date"
      value={value}
      onChange={e => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = logs.filter(entry =>
          moment(entry.loginDate).format("DD MMM YYYY hh:mm a").includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );
 

  const columns = [
    {
      title: "IP ADDRESS",
      dataIndex: "ip",
      key: "ip",
      sorter: (a, b) => a.ip - b.ip,
      ...getColumnSearchProps("ip", setSearchData, searchData, searchInput),
    },
    {
      title: "DEVICE",
      dataIndex: "device",
      key: "device",
      sorter: (a, b) => a.device - b.device,
      ...getColumnSearchProps("device", setSearchData, searchData, searchInput),
    },
    {
      title: "COUNTRY",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country - b.country,
      ...getColumnSearchProps(
        "country",
        setSearchData,
        searchData,
        searchInput
      ),
    },
    {
      title: FilterByNameInput,
      dataIndex: "loginDate",
      key: "loginDate",
      render: (text) =>`${moment(text).format("DD MMM YYYY hh:mm a").toLocaleUpperCase()}`, 
      sorter: (a, b) => a.loginDate - b.loginDate,

    },
  ];

  useEffect(async () => {
    showLoader();
    let response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/history/user-history/${id}`
    );
    setLogs(response.data);
    setDataSource(response.data);
    hideLoader();
  }, []);
  return (
    <Layout>
      <div style={{ cursor: "pointer" }} onClick={() => navigate("/users")}>
        <ArrowLeftOutlined
          style={{
            fontSize: "20px",
            fontWeight: "600",
            marginRight: "4px",
            marginTop: "10px",
          }}
        />
        <span className="me-1">back</span>
      </div>
      {/* <Divider /> */}
      {/* <section>
        <label style={{fontWeight:'bold', marginRight:'12px'}}>Duration</label>
        <RangePicker
          defaultValue={[
            moment("2015/01/01", dateFormat),
            moment("2015/01/01", dateFormat),
          ]}
          format={dateFormat}
        />
      </section> */}
      <Divider />
      {/* Grid Section Starts */}
      <section>
        <Table dataSource={dataSource} columns={columns} />
      </section>
    </Layout>
  );
};
export default UserLoginTracker;
