import React, { useContext, useEffect, useState } from "react";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import BankCard from "../CustomCard/bankcard";
import { hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";
import AddCard from "../../views/WayaOfficial/modal/AddCard";

const ManageBankCards = ({ userId, walletList, admin }) => {
  const [card, setCards] = useState([]);
  const [selectedUser] = useState(JSON.parse(localStorage.getItem("selectedUser")));
  const dropDownItem = [
    { id: "deleteCard", label: "Delete Card", style: "text-danger" },

  ];
  const [showAddModal, setShowAddModal] = useState(false);

  const handleDropDown = (id, item) => {
    if (id === "deleteCard") {
      deleteAccount(item?.cardNumber, userId, item?.accountName );
    }
  };

  const getCards = async () => {
    const res = await httpGet(
      `${customBaseUrl.cardUrl}/card/list/${userId}`
    );
    if (res?.status === true) {
      setCards(res?.data);
    }
  };

  const deleteAccount = async (cardNumber, userId, cardName) => {
    showLoader();
    /*const res = await httpDelete(
      `${customBaseUrl.cardUrl}/card/delete/${userid}/${cardNumber}`
    );
    */
   let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify({userId, cardNumber}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "DELETECARD",
      customerName: cardName
    } )
    if (res?.status) {
      getCards();
      hideLoader();
      Swal.fire(res?.message);
    } else {
      hideLoader();
      Swal.fire(res?.message);
    }
  };

  useEffect(() => {
    getCards();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log(card);
  return (
    <div className="linkedCard">
      <div className="card-div d-flex">
        {card?.map((item) => (
          <div
            key={Math.random}
            style={{
              width: "32%",
              marginRight: "41px",
            }}
          >
            <BankCard
              accName={item?.accountName}
              accNumber={item?.cardNumber}
              expDate={`${item?.expiryMonth}/${item?.expiryYear}`}
              dropDownItem={dropDownItem}
              handleDropDown={handleDropDown}
              data={item}
            />
          </div>
        ))}
      </div>
      <div className="add-div" onClick={() => setShowAddModal(true)}>
        <div>+</div>
        <div>ADD CARD </div>
      </div>

      {showAddModal ? (
        <AddCard admin={admin} userId={userId} walletList={walletList} getCards={getCards} hideModal={setShowAddModal} showModal={showAddModal} user={selectedUser} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ManageBankCards;
