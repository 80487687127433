import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet } from "../../../store/http";
import CreateBulkUser from "../../../components/Modal/User/CreateBulkUser";
import UserActionsModal from "../../../components/Modal/User/UserActions";
import { styled } from "@mui/material/styles";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import DeactivateUsers from "../../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import CreateBulkForm from "../../../components/Modal/User/CreateBulkForm";
import SendSingleUserModal from "../../../components/Modal/TransferToNonWayaUser/SendSingleUser";
import BulkTransfer from "../../../components/Modal/TransferToNonWayaUser/BulkTransfer";
import NonWayaWebForm from "../../../components/Modal/TransferToNonWayaUser/WebForm";
import { useTheme } from "styled-components";
import SearchInput from "../../../components/CustomInput/SearchInput";
import ExportData from "../../WayaOfficial/modal/exportData";
import TemporalPeriodModal from "../../../components/Modal/TransferToNonWayaUser/TemporalPeriod";

const NonWayaTransfer = () => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showSendUser, setShowSendUser] = useState(false);
  const [showTransferExcel, setShowTransferExcel] = useState(false);
  const [showTransferForms, setShowTransferForms] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [allTransfers, setAllTransfers] = useState(0);
  const [retrievedTransfers, setRetrievedTransfers] = useState(0);
  const [nonRetrievedTransfers, setNonRetrievedTransfers] = useState(0);
  const [allRequests, setAllRequests] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [reversalDays, setReveversalDays] = useState([]);
  const getTransactions = async (page, size) => {
    setAllRequests([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/non-waya/payment/list-transactions?page=${pages}&size=${limit}`
    );
      console.log(request.data);
    if (request.status) {
      setAllRequests(request.data.nonWayaList);
      setAllTransfers(request.data.totalItems);
    }
    hideLoader();
  };

  const getTemporalReversalDays = async () => {
    
    let request = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/config`
    );
      console.log(request.data);
    if (request.status) {
      setReveversalDays(request.data);
    }
    hideLoader();
  };

  useEffect(() => {
    getTransactions();
    getTemporalReversalDays();
}, []);

  const SendUserOptions = [
    "Send to Single User",
    "Send Using Webforms",
    "Upload Excel File to Send",
  ];

  const handleSendUserDropdownAction = (item) => {
    if (item === "Send to Single User") setShowSendUser(true);
    if (item === "Upload Excel File to Send") setShowTransferExcel(true);
    if (item === "Send Using Webforms") setShowTransferForms(true);
  };
 
  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };



  var searchInput = useRef({});
  const columns = [
    
    { field: 'fullName',
      headerName: 'Sender\'s Name',
      width: 150 
    },

    { field: 'emailOrPhone',
      headerName: "RECIPIENT'S EMAIL/ PHONE",
      width: 250 
    },
    { field: 'tranAmount',
    headerName: "AMOUNT",
    width: 100 
    },
    { field: 'tokenId',
    headerName: "Token",
    width: 150 
    },
    { field: 'status',
    headerName: "STATUS",
    width: 150 
    },
    { field: 'createdAt',
    headerName: "DATE",
    width: 150 
    }

  ];

  
  return (
    <Layout>

<div style={tool}>
<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.headerName !== ("ACTION"&&"AMOUNT"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
        </div>

        <Row type="flex" justify="end" align="middle">
      
        
        
            <Button
              style={{ fontSize: "10px", width:100, background: "#27AE60", color: "#fff" }}
              onClick={() => setShowExport(true)}

            >
              {" "}
              Export
            </Button>
            <AntDropdown
            dropDownOptions={SendUserOptions}
            handleDropdownAction={handleSendUserDropdownAction}
          >
            <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              New Transfer
            </Button>
          </AntDropdown> 
          <Button
              onClick={() => setShowEditDays(true)}
              size="medium"
              style={{ background: "#014BFF", fontSize: "10px", color: "#fff" }}
            >
              Edit No of Days
            </Button>    
        </Row>
      </div>




      

      <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>All Transfers</p>
          <h2 className="text-left">{allTransfers}</h2>
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle} className="sm-1">
          <p>Retrieved Transfers</p>
          <h2 className="text-left">{retrievedTransfers}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="sm-1">
          <p>Non Retrieved Transfers</p>
          <h2 className="text-left">{nonRetrievedTransfers}</h2>
        </Card.Grid>
        <Card.Grid style={gridStyle} className="sm-1">
          <p>No of Days for Reversal</p>
          <h2 className="text-left">{reversalDays.length>0? Object.keys(reversalDays).map((e,i)=><h2 key={i}>{reversalDays[e].days}</h2>):''}</h2>
        </Card.Grid>
      </Card>

      <Grid
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allRequests.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={allRequests.filter((request) =>
            request[search?.searchBy]
              .toLowerCase()
              .includes(search?.searchText.toLowerCase())
          )}
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={allTransfers}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      {showSendUser ? (
        <SendSingleUserModal
          showModal={showSendUser}
          hideModal={setShowSendUser}
          title="Send money to Single User"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showTransferExcel ? (
        <BulkTransfer
          showModal={showTransferExcel}
          hideModal={setShowTransferExcel}
          title="Upload Excel Sheet to send money to non Waya Users"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {
        showTransferForms? (
          <NonWayaWebForm
          showModal={showTransferForms}
          hideModal={setShowTransferForms}
          title="Send money to Non Waya Users with Web Form"
          className={styles.modal}
          wallets={[]}
          />
        ):("")
      }
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allRequests} file={"nonwaya-Transaction"}  />
      }
      {
        showEditDays && 
        <TemporalPeriodModal getTemporalReversalDays={getTemporalReversalDays} showModal={showEditDays} hideModal={setShowEditDays} title="Edit No of Days" className={styles.modal} />
      }
    </Layout>
  );
};

const gridStyle = {
  width: "23%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default NonWayaTransfer;
