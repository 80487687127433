import React from "react";
import { InputNumber, AutoComplete, Col, DatePicker, Button } from "antd";
import moment from 'moment';


const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const { RangePicker } = DatePicker;
const FundMassUsers = () => {

    const options = [
        {
            value: 'User',
        },
        {
            value: 'Admin',
        },
    ];


    return (
        <div >
            <div className="d-flex justify-content-center">
                <h4>
                    Fund Simulated Users
                </h4>
            </div>
            <div >
                <Col span={24}>
                    <div><label>Amount to Fund Simulated Users</label></div>
                    <InputNumber
                        type="number"
                        style={{
                            width: 500,
                        }}
                    />
                </Col>
                <Col span={24} className='my-3'>
                    <div><label>Users Role</label></div>
                    <AutoComplete
                        style={{
                            width: 500,
                        }}
                        options={options}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Col>
                <Col span={24}>
                    <div><label>Date Range</label></div>
                    <RangePicker
                        style={{
                            width: 500,
                        }}
                    />
                </Col>
                <Col span={24} className='my-3'>
                    <div><label>Value Date</label></div>
                    <DatePicker
                        style={{
                            width: 500,
                        }}
                        format={dateFormatList} />
                </Col>
                <Col span={24}>
                    <div><label>Count</label></div>
                    <InputNumber
                        type="number"
                        style={{
                            width: 500,
                        }}
                    />
                </Col>
            </div>
            <div className="my-3">
                <Button
                    style={{ background: "#FF4B01", fontSize: "15px", color: "#fff", width: 500, }}>
                    Fund Users
                </Button>
            </div>
        </div>
    )
}
export default FundMassUsers