import { Modal } from 'antd'
import React from 'react'


const LoanHistory = ({showMoadal, hideModal, data}) => {

    return (
        <Modal
        // className={className}
        title="Loan History"
        centered
        visible={showMoadal}
        onOk={() => hideModal(false)}
        onCancel={() => hideModal(false)}
        // width={500}
        footer={0}
      >
        {
            data?.creditHistory?.map(e => (
                <div className='row'>
                    <div className='col-8'>
                        {e?.institution}
                    </div>
                    <div className='col-2'>
                    </div>
                
                </div>
            ))
        }
        
          </Modal>
    )
}

export default LoanHistory;