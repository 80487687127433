import { createUseStyles } from 'react-jss';

const useMatStyles = createUseStyles(() => ({
  searchRow: {
    display: 'flex',
    height: '46px',
    alignItems: 'right',
    marginBottom: '12px',
    float: 'right'
  },
  selectType: {
    marginRight: '10px',
    borderRadius: '6px !important',
    // border: '0 !important',
    height: '100%',
    width: '171px',
    padding: '10px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FF4B01',
    background: '#FFEAE1',
    border: '1px solid #FF4B01 !important',
    boxSizing: 'border-box',
  },
  grow: {
    flexGrow: 1,
  },
  searchInput: {
    height: '100%',
    width: '279px',
    borderRadius: '6px',
    background: '#F5F5F5 !important',
    border: '1px solid #FF4B01',
    boxSizing: 'border-box',
    color: '#978C8A',
  },

  searchIcon: {
    background: '#F5F5F5',
    color: '#FF4B01',
  },

}));
export default useMatStyles;