import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import {Button as MatButton} from "@mui/material"
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { ExportOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../components/Layout";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../assets/images/no-data.png";
import color from "../../theme/color";
import CreateSingleUserModal from "../../components/Modal/User/CreateSingleUser";
import AntTable from "../../components/CustomTable/AntTable";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import CreateBulkUser from "../../components/Modal/User/CreateBulkUser";
import UserActionsModal from "../../components/Modal/User/UserActions";
import { styled } from "@mui/material/styles";

import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Grid as MatGrid,
} from "@mui/material";
import DeactivateUsers from "../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { transformName } from "../../utils/helper";
import { hideLoader, showLoader } from "../../utils/loader";
import { downloadFile } from "../../utils/fileDownloader";
import Swal from "sweetalert2";
import { getColumnSearchProps } from "../../components/CustomTableSearch";
import CreateBulkForm from "../../components/Modal/User/CreateBulkForm";
import { NotificationManager } from "react-notifications";
//  import EmailInvite from "./modals/emailInvite";
// import SocialInvites from "./modals/socialInvites";
//  import ExportCSV from "./export";
//  import VerifyUser from "./verify";
import PinModal from "../../components/Modal/Pin/PinModal";
import { ArrowForward, ArrowForwardIos, Lock, Margin } from "@mui/icons-material";
import moment from "moment";
import { CSVLink } from "react-csv";
import Approvers from "./approvers";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LoanSubmission from "./submission";
// import generatePDF from "./generatePdf";
//  import UserProfiles from "./profiles";
const LoanOverview = () => {
  const navigate = useNavigate();
  const history = useNavigate();
  const roles = localStorage.getItem('roles');
  const [users, setUsers] = useState([]);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showBulkSms, setShowBulkSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showCreateUserExcel, setShowCreateUserExcel] = useState(false);
  const [showCreateUserForms, setShowCreateUserForms] = useState(false);
  const [showSocial, setShowSocial] = useState(false)
  const [showUserAction, setShowUserAction] = useState(false);
  const [showDeactivateUser, setShowDeactivateUser] = useState(false);
  const [searchField, setSearchField] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 50,
  });
  const [filteredDate, setFilteredDate] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [selectedRecord, setRecord] = useState([]);
  const [isSmUsers, setIsSmUsers] = useState(false);
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [showSubmission, setShowSubmission] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "lastName",
    searchText: "",
  });
  const [totalRegisteredUsers, setTotalRegisteredUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [totalCorporateUsers, setTotalCorporateUsers] = useState(0);
  const [totalIndividualUsers, setTotalIndividualUsers] = useState(0);
  const [showExport, setShowExport] = useState(false);
  const [showProfiles, setShowProfile] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showVerifyAccount, setShowVerifyAccount] = useState(false);
  const [otp, setOtp] = useState('')
  const [phone, setPhone] = useState('')
  const [request, setRequest] = useState([]);
  const [meta, setMeta] = useState(0)
  const [aRoles, setRole] = useState([]);

  const inviteUserOptions = ["Via Social Media",  "Via Email"];
  const handleInviteUsersOption = (item) => {
    if (item === "Via Social Media") setShowSocial(true);
    if (item === "Via SMS") setShowBulkSms(true);
    if (item === "Via Email") setShowEmail(true);
  };
  const createUserOptions = [
    "Create Single User",
    "Upload Excel Sheet to Create",
    "Create Users with Forms",
  ];

  const handleCreateUserDropdownAction = (item) => {
   if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_APP') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){

    if (item === "Create Single User") setShowCreateUser(true);
    if (item === "Upload Excel Sheet to Create") setShowCreateUserExcel(true);
    if (item === "Create Users with Forms") setShowCreateUserForms(true);
   }else{
    NotificationManager.error('Not authorize')
   }
  };
  const downloadTemplateOptions = [
    "Download Template for Bulk Deactivation/Activation",
    "Bulk User Creation Template for Corporate",
    "Bulk User Creation Template for Individual",
  ];
  const handleDownloadTemplateDropdownAction = (item) => {
    if (item === "Download Template for Bulk Deactivation/Activation")
      downloadTemplates("deactivate");
    if (item === "Bulk User Creation Template for Corporate")
      downloadTemplates("corporate");
    if (item === "Bulk User Creation Template for Individual")
      downloadTemplates("individual");
  };

  const downloadTemplates = (type) => {
    if (type == "deactivate") {
      console.log("deactivate");
      const httpLink = `${customBaseUrl.authUrl}/api/v1/admin/bulk/account-activation/bulk-user-excel`;
      downloadFile(httpLink);
    } else if (type == "individual") {
      console.log("individual");
      const httpLink = `${customBaseUrl.authUrl}/api/v1/admin/download/bulk-user-excel?isCorporate=false`;
      downloadFile(httpLink);
    } else {
      console.log("corporate");
      const httpLink = `${customBaseUrl.authUrl}/api/v1/admin/download/bulk-user-excel?isCorporate=true`;
      downloadFile(httpLink);
    }
  };
  const dropDownOptions=(row) => [
    "Manage Profile",
    "Manage Team Members",
    "Manage Transaction",
    "Manage Kyc",
    "Login Tracker",
    "Login Log",
    "Activity Log",
    "Reset Credentials",
    "Deactivate User",
    "Delete User",
    "Block User",
    "Lock Transaction"

  ];

  const getRandomuserParams = (params) => ({
    size: params.pagination.pageSize ?? pagination.pageSize,
    page: params.pagination.current ?? pagination.current,
  });

  const handleGetUsers = async (params = {}) => {
    params.preventDefault();
    setLoading(true);
    showLoader();
    // https://services.staging.wayabank.ng/auth-service/api/v1/admin/users?page=0&size=20
    /*const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/users?${qs.stringify(
        getRandomuserParams(params)
      )}&sortBy=id&sortOrder=ASC`
    );*/
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(month - 1);  

    const res = await httpGet(
      `${isSmUsers ? customBaseUrl?.smAuthUrl : customBaseUrl.authUrl}/api/v1/admin/users-list?fromDate=${moment(filteredDate?.fromdate ?? date).format('YYYY-MM-DD') }&toDate=${moment(filteredDate?.todate?? (new Date()).setMonth(month + 1)).format('YYYY-MM-DD')}`
    );
    console.log(res);
    setLoading(false);
    if(res?.status===false) Swal.fire(res?.message)
    hideLoader();
    /*if (res?._embedded?.userResponse === undefined) {
      setLoading(true);
      let query = getRandomuserParams(params);
      const totalRecords = res?.page?.totalElements;
      const previousPagesRecords = (res?.page.number - 1) * res?.page.size;
      const remainingRecords = totalRecords - previousPagesRecords;
      if (remainingRecords > 0) {
        query.size = remainingRecords;

        const res2 = await httpGet(
          `${customBaseUrl.authUrl}/api/v1/admin/users?${qs.stringify(
            query
          )}&sortBy=id&sortOrder=ASC`
        );
        setLoading(false);
        setUsers(res2?._embedded?.userResponse);

        setPagination({
          ...params.pagination,
          total: res2.page.totalElements,
        });
      } else {
        NotificationManager.warning("no more records");
        return;
      }
    } else {
      const filter = clean(filteredData);

      const filtered = res?.data?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered)
      setUsers(filtered);
      setPagination({
        ...params.pagination,
        total: filtered?.length,
      });
    }*/
    if(res?.status){
      const filter = clean(filteredData);
        setUsers([]);
      const filtered = res?.data?.filter(e=>  {
        console.log('fd', filteredData)
        return Object.keys(filter).every(key => {
          return (key === 'firstName' || key === 'surname') ? filter[key]?.toLowerCase() === e[key]?.toLowerCase() : filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered)
      if(filtered?.length < 1) NotificationManager.error('Record not found' );
      setUsers(filtered);
      setPagination({
        ...params.pagination,
        total: filtered?.length,
      });
      hideLoader();
    } else {
      hideLoader()
      console.log(res);
    }
    hideLoader();
  };

  const handleDeleteUser = async (id) => {
  if(roles.indexOf('ROLE_ADMIN_OWNER') ===-1 ||roles.indexOf('ROLE_ADMIN_APP') ===-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') ===-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1) return NotificationManager.error('not authrize')

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        showLoader();
        const res = await httpDelete(
          `${customBaseUrl.authUrl}/api/v1/user/delete/${id}`
        );
        if (res.status === true) {
          hideLoader();
          NotificationManager.success("User Deleted Successfully");
          handleGetUsers({pagination});
        }
        else{
          hideLoader();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          })
        }
      }
    });
  };
  const getLaonRequest = async (page, size) => {
    // setAllreferrals([]);
    const approverRole = localStorage.getItem("approverRole");
    console.log({ approverRole });
    const status = approverRole === 'Credit analyst' ? 'Pending'
              : approverRole === 'Controller' ? 'First Level Approved'
              : 'Second Level Approved'
    showLoader();
    const pages = page ?? pagination.current;
    const limit = size ? size : pagination.pageSize;
    console.log('pages',pages)
    let request = await httpGet(
      `${customBaseUrl.loanUrl}/api/v1/approver/loan/by-status?page=${pages}&size=${limit}&status=${status}`
    );
      console.log(request.data);
    if (request.status) {
      hideLoader();
      console.log('dtaa',request.data);
      setRequest(request.data.loans);
      setMeta(request.data.totalItems);
    }
    hideLoader();
  };
  const handleGetApprovers = async () => {
    showLoader();
    const email = localStorage.getItem("email")
    const res = await httpGet(
      `${customBaseUrl.loanUrl}/api/v1/admin/all-approvers`
    );
    if (res.status === true) {
        hideLoader();
       const role = res.data.filter((e) => e.email === email);
       setRole(role[0]);
       console.log(( role ));
      //setApprovers(res.data)
    }
  }
  useEffect(() => {
    handleGetApprovers();
  }, [])
  useEffect(async () => {
    getLaonRequest();    
  },[aRoles]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetCredentials = (user) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you are about to send reset OTP to this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset!",
    }).then((result) => {
      if (result.value) {
        const res = httpGet(
          `${customBaseUrl.authUrl}/api/v1/password/change-password/byEmail?email=${user.email}`
        );
        if (res) {
          NotificationManager.success("Credentials Reset Successfully");
          // handleGetUsers({pagination});
        }
      }
    });
  }

  const handleRToggleLock = (userId, record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `you are about to ${record?.accountNonLocked?"UnLock" : "Lock"} this user!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${record?.accountNonLocked?"Yes, unblock!": "Yes, lock"}`,
    }).then((result) => {
      if (result.value) {
        const res = httpPost(
          `${customBaseUrl.authUrl}/api/v1/admin/users/lock-toggle/un-lock?userId=${userId}`,{}
        );
        if (res) {
          NotificationManager.success(res?.message);
          // handleGetUsers({pagination});
        }
      }
    });
  }
  const verify = async (email) => {
    showLoader()
    setPhone(email);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${email}`
    );
    if (res.status) {
      hideLoader();
      Swal.fire("Done", res.message, "success").then(() => {
        setShowVerify(false);
        setShowVerifyAccount(true);
      });
    } else {
      hideLoader();
      Swal.fire('error', res?.message)
    }
  }
  const completeVerification = async () => {
    showLoader();
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
    };
    const res = await httpPost(`${customBaseUrl.authUrl}/api/v1/auth/verify-otp`,
      postData,
    );
    if (res.status) {
      hideLoader();
      setOtp("");
      setPhone("");
      setShowVerifyAccount(false);
      // setShowVerifyOtp(false);
      Swal.fire("Done", res.message, "success")
    } else {
      hideLoader();
      setOtp("");
      Swal.fire("Oops!", res.message, "error");
    }
  };
  const handleDropdownAction = (item, record) => {
    //setSelectedId(id);
    console.log({ roles });
    setRecord(record);
    localStorage.setItem("selectedUser", JSON.stringify(record));
    //if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1 ||roles.indexOf('ROLE_ADMIN_APP') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){

    if (item === "View submission") setShowSubmission(true);
    // }else{
    //   NotificationManager.error('Not authorized');
    // }
  };

  const handleOpenLoginTracker = (id) => {
    navigate(`/user/login_tracker/${id}`);
  };
  const handleOpenProfile = (id) => {
    const item = users.find((user) => user.userId === id);
    localStorage.setItem("user_profile", JSON.stringify(item));
    navigate(`/users/profile/${id}`);
  };

  var searchInput = useRef({});
  const columns = [
    { field: 'Name',
        headerName: 'firstName',
        width: 300 ,
        headerClassName: styles.tableHeader,
        renderCell: ({row}) => row?.firstName + ' '+ row?.lastName
  
      },
      { field: 'phoneNumber',
        headerName: 'Contact',
        width: 300 ,
        headerClassName: styles.tableHeader,
        renderCell: ({row}) => <div style={{ marginTop: 3}}>
          <div>{row?.phoneNumber}</div>
          <div>{row?.emailAddress}</div>
        </div>
  
  
      },
      { field: 'typeOfLoan',
        headerName: 'Loan Type',
        width: 200 ,
        headerClassName: styles.tableHeader,
  
  
      },
    {
      headerName: "ACTION",
      field: "id",
      headerClassName: styles.tableHeader,
      renderCell: ({row}) => (
        <AntDropdown
          dropDownOptions={[
            "View submission",
          ]}
          handleDropdownAction={(item) =>
            handleDropdownAction(item, row)
          }
          isArrow
        />
      ),
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const handleOpenDeactivateUsers = () => {
    if(roles.indexOf('ROLE_ADMIN_OWNER') !==-1 ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1 ||roles.indexOf('ROLE_ADMIN_INITIATOR') !==-1) {
    setShowDeactivateUser(true);
    }else{
      return NotificationManager.error('not authrize!')
    }
  };
  const handleDactivateUser = async (userId) => {
    showLoader();
   /* const res = await httpPost(
      `/api/v1/admin/users/activation-toggle?userId=${userId}`,
      {},
      customBaseUrl.authUrl
    );
    */

    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify({userId}),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "ACTIVATIONTOGGLE",
      customerName: selectedRecord?.firstName+' '+selectedRecord?.surname
    } )

    hideLoader();
    if (res.status === true) {
      setShowDeactivateUser(false);
      Swal.fire(res.message, "success");
      // handleGetUsers({ pagination });
    } else {
      Swal.fire(res.message, "error");
    }
  };

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const getUsers = async (e) => {
    e.preventDefault();
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/admin/users-list?fromDate=${moment(filteredDate?.fromdate).format('YYYY-MM-DD')}&toDate=${moment(filteredDate?.todate).format('YYYY-MM-DD')}`
    );
    
    const filter = clean(filteredData);

    if (response.status === true) {
      console.log('filter', filteredData);
      const filtered = response?.data?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      console.log('filtered', filtered)
      setUsers(filtered);
      //setFiltered(response.data.content)
      //setMetaData(filtered?.length);
      //  console.log('filter', fil)
      
      hideLoader();
    } else {
      NotificationManager.error(response?.message)
      setUsers([]);
  
      hideLoader();
    }
  };
  const logout = (e) => {
    localStorage.clear();
    window.location.href = '/auth/loan/login';
  };
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getLaonRequest(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getLaonRequest(0, value);
  };
  return (
    <>
      {showSubmission ? (
      <LoanSubmission data={selectedRecord} show={showSubmission} hide={setShowSubmission} getReq={getLaonRequest}/>  
      ) :
      <>
      <div style={{ textAlign: 'end', justifyContent:'end'}}>
      <Button
         size="medium"
         style={{ textAlign: 'end', background: !showProfiles ? "#FF4B01" : "", fontSize: "10px", color: !showProfiles ? "#fff": "#000" }}
        onClick={logout}
        >
          Logout
        </Button>
      </div>
      <div style={tool}>
        <Button
         size="medium"
         style={{ background: !showProfiles ? "#FF4B01" : "", fontSize: "10px", color: !showProfiles ? "#fff": "#000" }}
        onClick={()=>setShowProfile(false)}
        >
          OVERVIEW
        </Button>
        <Button
         size="medium"
         style={{ background: showProfiles ? "#FF4B01" : "", fontSize: "10px", color:  showProfiles ? "#fff": "#000" }}
          onClick={()=>setShowProfile(true)}
        >
          APPROVERS MANAGEMENT
        </Button>
        <div/>
        <div/>
        
        <Row hidden type="flex" justify="end" align="middle">
          {/* <Button
            size="medium"
            style={{ background: "black", fontSize: "10px", color: "#fff" }}
            onClick={handleOpenDeactivateUsers}
          >
            Deactivate Users
          </Button>
          <AntDropdown
            dropDownOptions={inviteUserOptions}
            handleDropdownAction={handleInviteUsersOption}
          >
            <Button
              size="medium"
              style={{ background: "#0086E8", fontSize: "10px", color: "#fff" }}
            >
              Invite Users
            </Button>
          </AntDropdown> */}
          {/* <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
              onClick={()=>setShowExport(true)}
            >
              Export
            </Button>
          <AntDropdown
            size="medium"
            dropDownOptions={downloadTemplateOptions}
            handleDropdownAction={handleDownloadTemplateDropdownAction}
          >
            <Button
              style={{ fontSize: "10px", background: "#27AE60", color: "#fff" }}
            >
              {" "}
              Download Template
            </Button>
          </AntDropdown> */}
          {/* <AntDropdown
            dropDownOptions={createUserOptions}
            handleDropdownAction={handleCreateUserDropdownAction}
          >
            <Button
              size="medium"
              style={{ background: "#FF4B01", fontSize: "10px", color: "#fff" }}
            >
              <PlusOutlined />
              Create New User
            </Button>
          </AntDropdown>
          <Button
            hidden={true}
            size="medium"
            style={{ background: "#4A4AFF", fontSize: "10px", color: "#fff" }}
          >
            <PlusOutlined />
            Submit KYC
          </Button> */}
        </Row>
      </div>
      {showProfiles ? (
      <Approvers setShowProfile={setShowProfile} />
      ) : (
      <>
      {/* <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>Approved Load</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>See loan that has been approved</h6>
            <ArrowForwardIos style={{ color: '#ff6700'}} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan count</h6>
            <h5 style={{ color: 'green' }}>1000</h5>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan value</h6>
            <h5 style={{ color: 'green' }}>NGN1,000,0000</h5>
          </div>
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle} className="mx-1">
          <p>In-Progress Loan</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>See loan that are in progress</h6>
            <ArrowForwardIos style={{ color: '#ff6700'}} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan count</h6>
            <h5 style={{ color: 'green' }}>1000</h5>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan value</h6>
            <h5 style={{ color: 'green' }}>NGN1,000,0000</h5>
          </div>        </Card.Grid>
        <Card.Grid style={gridStyle} className="mx-1">
          <p>Disbursed Loan</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>See loan that has been disbursed</h6>
            <ArrowForwardIos style={{ color: '#ff6700'}} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan count</h6>
            <h5 style={{ color: 'green' }}>1000</h5>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan value</h6>
            <h5 style={{ color: 'green' }}>NGN1,000,0000</h5>
          </div>        </Card.Grid>
        <Card.Grid style={gridStyle} className="mx-1">
          <p>Installment Loan</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>See all installment loan</h6>
            <ArrowForwardIos style={{ color: '#ff6700'}} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan count</h6>
            <h5 style={{ color: 'green' }}>1000</h5>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan value</h6>
            <h5 style={{ color: 'green' }}>NGN1,000,0000</h5>
          </div>        </Card.Grid>
        <Card.Grid style={gridStyle} className="ml-1">
          <p>Repaid Loan</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>See all repaid loans</h6>
            <ArrowForwardIos style={{ color: '#ff6700'}} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan count</h6>
            <h5 style={{ color: 'green' }}>1000</h5>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan value</h6>
            <h5 style={{ color: 'green' }}>NGN1,000,0000</h5>
          </div>        </Card.Grid>
        <Card.Grid style={gridStyle} className="ml-1">
          <p>Pending Repayment Loan</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>See loan that are still pending on repayment</h6>
            <ArrowForwardIos style={{ color: '#ff6700'}} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan count</h6>
            <h5 style={{ color: 'green' }}>1000</h5>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Total approved loan value</h6>
            <h5 style={{ color: 'green' }}>NGN1,000,0000</h5>
          </div>        </Card.Grid>
      </Card> */}

            {/* <div>
        <MatButton 
        variant="contained" 
        style={{width: '100%', marginBottom: 10, backgroundColor: "#ff6700"}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</MatButton>
      </div> */}
      {request?.length ? (
        <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid 

         density="comfortable"
            rows={request }
            columns={columns}
            page={pagination.current}
            // paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={meta}

        />
        </div>
        </MatGrid>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Loan request will appear here
          </p>
        </>
      )}

      {showDeactivateUser ? (
        <DeactivateUsers
          showModal={showDeactivateUser}
          hideModal={setShowDeactivateUser}
          title="Deactivate Single User"
          className={styles.modal}
          handleDactivateUser={handleDactivateUser}
          users={users}
        />
      ) : (
        ""
      )}
      {showCreateUser ? (
        <CreateSingleUserModal
          showModal={showCreateUser}
          hideModal={setShowCreateUser}
          title="Single User"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showCreateUserExcel ? (
        <CreateBulkUser
          showModal={showCreateUserExcel}
          hideModal={setShowCreateUserExcel}
          title="Upload Excel Sheet to Create Users"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showCreateUserForms ? (
        <CreateBulkForm
          showModal={showCreateUserForms}
          hideModal={setShowCreateUserForms}
          title="Create Single or Bulk Users Online"
          className={styles.modal}
        />
      ) : (
        ""
      )}
      {showUserAction ? (
        <UserActionsModal
          showModal={showUserAction}
          hideModal={setShowUserAction}
          className={styles.modal}
          userId={selectedId}
          record = {selectedRecord}
        />
      ) : (
        ""
      )}


 {showVerifyAccount ? (
        <PinModal
          center
          showModal={showVerifyAccount}
          hideModal={setShowVerifyAccount}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description={`Please input the OTP sent to ${phone}`}
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeVerification}
          loading={loading}
          numInputs={6}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )}
    </>)}
    </>}
    </>
  );
};

const gridStyle = {
  width: "30%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  margin: "px5",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-around",
  marginBottom: "30px",
  margin: "40px"
};

export default LoanOverview;
