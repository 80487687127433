import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { Modal } from "antd";
import "./index.scss";
import "../../index.scss";
import PinModal from "../Pin/PinModal";
import Button from "../../Button/Button";
import { customBaseUrl, httpPost } from "../../../store/http";

const Index = (props) => {
  const { showModal, hideModal, className, profile } = props;
  const [showConfirmOtp, setShowConfirmOtp] = useState(false);
  // const [showBvnY, setShowBvnY] = useState(false);
  const [otp, setOtp] = useState("");

  const [dob, setDob] = useState(
    profile.dateOfBirth === "" ? "" : profile.dateOfBirth
  );
  const [bvnCode, setBvnCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isResendOtp, resendOtp] = useState(false);

  const handleLinkBvn = async () => {
    const postData = {
      bvn: bvnCode,
      dob: dob || profile.dateOfBirth,
      firstName: profile.firstName,
      lastName: profile.lastName,
      userId: profile.userId,
      phoneNumber: profile.phoneNumber,
    };
    const res = await httpPost(`${customBaseUrl.bvnUrl}/bvn/linkBvn`, postData);
    if (res.status) {
      setLoading(false);
      Swal.fire("Done", `${res.message}`, "success").then(() => {
        if (res.status === true) {
          setShowConfirmOtp(true);
          return;
        }
        hideModal(false);
      });
    } else {
      setLoading(false);
      if (res.message === "Bvn has already been added to this user Pls Validate your OTP") {
        setShowConfirmOtp(true);
        return;
      }
      Swal.fire("Oops!", res.message || "Something went wrong", "error");
    }
  };

  const resendBvnOtp = async () => {
    setLoading(true);
    const res = await httpPost(
      `${customBaseUrl.bvnUrl}/bvn/resendOTP/${profile.userId}`,
      {}
    );
    if (res.status) {
      setLoading(false);
      setOtp("");
    } else {
      setLoading(false);
      Swal.fire("Oops!", res.message || "Something went wrong", "error");
    }
  };

  useEffect(() => {
    if (isResendOtp) resendBvnOtp();
  }, [isResendOtp]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateBvnOtp = async () => {
    setLoading(true);
    const res = await httpPost(
      `${customBaseUrl.bvnUrl}/bvn/validateBvn/${profile.userId}/${otp}`,
      {}
    );
    if (res.status) {
      setLoading(false);
      setOtp("");
      Swal.fire("Done", `${res.message}`, "success");
      hideModal(false);
    } else {
      setLoading(false);
      Swal.fire("Oops!", res.message || "Something went wrong", "error");
    }
  };

  return (
    <Modal
      className={className}
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      <h5 className="text-center mb-5 mt-5">Link BVN</h5>
      <div>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <input
              placeholder="BVN (11 digits)"
              type="text"
              onChange={(e) => setBvnCode(e.target.value)}
            />
            <span className="input-detail">
              Enter your 11 digits BVN Number
            </span>
          </div>
          {profile.phoneNumber === "" ? (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Enter date of birth"
                type="date"
                onChange={(e) => setDob(e.target.value)}
              />
              <span className="input-detail">Enter date of birth</span>
            </div>
          ) : null}

          {/* <div className="inputbox-with-one-input">
            <input placeholder="Enter Verification Code" type="text" />
            <span className="input-detail">
              Enter verification code sent to you
            </span>
          </div> */}
        </form>
      </div>
      <div>
        <Button
          className="btn btn-primary text-white d-flex mx-auto mb-5 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            handleLinkBvn();
          }}
        >
          Next
        </Button>
      </div>

      {showConfirmOtp ? (
        <PinModal
          center
          showModal={showConfirmOtp}
          hideModal={setShowConfirmOtp}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your otp"
          description="Please input your 6 digit otp to confirm bvn"
          handleSubmit={validateBvnOtp}
          isResendOtp
          resendOtp={resendOtp}
          numInputs={6}
        />
      ) : (
        ""
      )}
    </Modal>
  );
};

Index.defaultProps = {
  id: "",
};

Index.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default Index;
