import React from "react";

export const VeriticalDropdown = () => {
  return (
    <svg
      width='9'
      height='26'
      viewBox='0 0 9 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.49967 19.334C5.53844 19.334 6.53466 19.6676 7.26917 20.2615C8.00369 20.8553 8.41634 21.6608 8.41634 22.5007C8.41634 23.3405 8.00369 24.146 7.26917 24.7398C6.53466 25.3337 5.53844 25.6673 4.49967 25.6673C3.46091 25.6673 2.46469 25.3337 1.73017 24.7398C0.995656 24.146 0.583008 23.3405 0.583008 22.5007C0.583008 21.6608 0.995656 20.8553 1.73017 20.2615C2.46469 19.6676 3.46091 19.334 4.49967 19.334ZM4.49967 9.83398C5.53844 9.83398 6.53466 10.1676 7.26917 10.7615C8.00369 11.3553 8.41634 12.1608 8.41634 13.0007C8.41634 13.8405 8.00369 14.646 7.26917 15.2398C6.53466 15.8337 5.53844 16.1673 4.49967 16.1673C3.46091 16.1673 2.46469 15.8337 1.73017 15.2398C0.995656 14.646 0.583008 13.8405 0.583008 13.0007C0.583008 12.1608 0.995656 11.3553 1.73017 10.7615C2.46469 10.1676 3.46091 9.83398 4.49967 9.83398ZM4.49967 0.333984C5.53844 0.333984 6.53466 0.667614 7.26917 1.26148C8.00369 1.85534 8.41634 2.6608 8.41634 3.50065C8.41634 4.3405 8.00369 5.14596 7.26917 5.73982C6.53466 6.33369 5.53844 6.66732 4.49967 6.66732C3.46091 6.66732 2.46469 6.33369 1.73017 5.73982C0.995656 5.14596 0.583008 4.3405 0.583008 3.50065C0.583008 2.6608 0.995656 1.85534 1.73017 1.26148C2.46469 0.667614 3.46091 0.333984 4.49967 0.333984Z'
        fill='#575757'
      />
    </svg>
  );
};

export const GetSvgIcon = ({ type, className }) => {
  if (type === 'settingIcon') {
    return (
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.165 7.5459C11.186 7.3709 11.2 7.1889 11.2 6.9999C11.2 6.8109 11.186 6.6289 11.158 6.4539L12.341 5.5299C12.446 5.4459 12.474 5.2919 12.411 5.1729L11.291 3.2339C11.221 3.1079 11.074 3.0659 10.948 3.1079L9.55498 3.6679C9.26098 3.4439 8.95298 3.2619 8.60998 3.1219L8.39998 1.6379C8.37898 1.4979 8.25998 1.3999 8.11998 1.3999H5.87998C5.73998 1.3999 5.62798 1.4979 5.60698 1.6379L5.39698 3.1219C5.05398 3.2619 4.73898 3.4509 4.45198 3.6679L3.05898 3.1079C2.93298 3.0589 2.78598 3.1079 2.71598 3.2339L1.59598 5.1729C1.52598 5.2989 1.55398 5.4459 1.66598 5.5299L2.84898 6.4539C2.82098 6.6289 2.79998 6.8179 2.79998 6.9999C2.79998 7.1819 2.81398 7.3709 2.84198 7.5459L1.65898 8.4699C1.55398 8.5539 1.52598 8.7079 1.58898 8.8269L2.70898 10.7659C2.77898 10.8919 2.92598 10.9339 3.05198 10.8919L4.44498 10.3319C4.73898 10.5559 5.04698 10.7379 5.38998 10.8779L5.59998 12.3619C5.62798 12.5019 5.73998 12.5999 5.87998 12.5999H8.11998C8.25998 12.5999 8.37898 12.5019 8.39298 12.3619L8.60298 10.8779C8.94598 10.7379 9.26098 10.5489 9.54798 10.3319L10.941 10.8919C11.067 10.9409 11.214 10.8919 11.284 10.7659L12.404 8.8269C12.474 8.7009 12.446 8.5539 12.334 8.4699L11.165 7.5459ZM6.99998 9.0999C5.84498 9.0999 4.89998 8.1549 4.89998 6.9999C4.89998 5.8449 5.84498 4.8999 6.99998 4.8999C8.15498 4.8999 9.09998 5.8449 9.09998 6.9999C9.09998 8.1549 8.15498 9.0999 6.99998 9.0999Z'
          fill='#828282'
        />
      </svg>
    );
  }

  if (type === "greenDot") {
    return (
      <svg
        width='8'
        height='8'
        viewBox='0 0 8 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='4' cy='4' r='4' fill='#27AE60' />
        <circle cx='4' cy='4' r='2' fill='#0E8741' />
      </svg>
    );
  }

  if (type === "backArrow") {
    return (
      <svg
        width='8'
        height='12'
        viewBox='0 0 8 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.33931 10.8717L0.901955 6.08599C0.432781 5.67305 0.453163 4.93571 0.944434 4.54932L6.38179 0.272751C7.03784 -0.243247 8 0.224099 8 1.05876V10.121C8 10.9816 6.98527 11.4402 6.33931 10.8717Z'
          fill='black'
        />
      </svg>
    );
  }

  if (type === "add") {
    return (
      <svg
        width='14'
        height='16'
        viewBox='0 0 14 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M7 0.706543C3.13996 0.706543 0 3.9489 0 7.9348C0 11.9207 3.13996 15.1631 7 15.1631C10.86 15.1631 14 11.9201 14 7.9348C14 3.94946 10.86 0.706543 7 0.706543ZM7 14.0433C3.73857 14.0433 1.08443 11.3031 1.08443 7.9348C1.08443 4.56646 3.73857 1.82633 7 1.82633C10.2614 1.82633 12.9156 4.56646 12.9156 7.9348C12.9156 11.3031 10.262 14.0433 7 14.0433Z'
            fill='#828282'
          />
          <path
            d='M9.71096 7.32487H7.54211V5.0853C7.54211 4.77623 7.29974 4.52539 6.99989 4.52539C6.70003 4.52539 6.45766 4.77623 6.45766 5.0853V7.32487H4.28881C3.98896 7.32487 3.74658 7.57572 3.74658 7.88478C3.74658 8.19385 3.98896 8.44469 4.28881 8.44469H6.45766V10.6843C6.45766 10.9933 6.70003 11.2442 6.99989 11.2442C7.29974 11.2442 7.54211 10.9933 7.54211 10.6843V8.44469H9.71096C10.0108 8.44469 10.2532 8.19385 10.2532 7.88478C10.2532 7.57572 10.0108 7.32487 9.71096 7.32487Z'
            fill='#828282'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect
              width='14'
              height='14.4565'
              fill='white'
              transform='translate(0 0.706543)'
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (type === "plusIcon") {
    return (
      <svg
        width='17'
        height='14'
        viewBox='0 0 17 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.845 12.8283V0.999819'
          stroke='#D7D7D7'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.69 6.91406H2'
          stroke='#D7D7D7'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }
  if (type === 'plusCircle') {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        clipRule='evenodd'
      >
        <path
          stroke='#ff4b01'
          d='M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z'
        />
      </svg>
    );
  }
  if (type === 'left arrow') {
    return (
      <svg
        height='24'
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        clipRule='evenodd'
        viewBox='0 0 24 24'
        style={{
          width: '15px',
        }}
        className={className}
      >
        <path
          stroke='#ff4b01'
          d='M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z'
        />
      </svg>
    );
  }
  if (type === 'right arrow') {
    return (
      <svg
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        clipRule='evenodd'
        style={{
          width: '15px',
        }}
        className={className}
      >
        <path
          stroke='#ff4b01'
          d='M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z'
        />
      </svg>
    );
  }
  if (type === "plusCircle") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          stroke="#ff4b01"
          d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"
        />
      </svg>
    );
  }
  if (type === "left arrow") {
    return (
      <svg
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 24 24"
        style={{
          width: "15px",
        }}
        className={className}
      >
        <path
          stroke="#ff4b01"
          d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"
        />
      </svg>
    );
  }
  if (type === "right arrow") {
    return (
      <svg
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        style={{
          width: "15px",
        }}
        className={className}
      >
        <path
          stroke="#ff4b01"
          d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"
        />
      </svg>
    );
  }
  if (type === "wallet") {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' fill='#ff6700' />
        <path
          d='M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z'
          fill='white'
        />
      </svg>
    );
  }
  if (type === "sync-alt") {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' fill='#ff6700' />
        <path d='M22 8L18 4V7H3V9H18V12L22 8Z' fill='white' />
        <path d='M2 16L6 20V17H21V15H6V12L2 16Z' fill='white' />
      </svg>
    );
  }

  if (type === "edit-alt") {
    return (
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.5 15.2676H16'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.25 1.51777C12.5815 1.18625 13.0312 1 13.5 1C13.7321 1 13.962 1.04572 14.1765 1.13456C14.391 1.2234 14.5858 1.35361 14.75 1.51777C14.9142 1.68192 15.0444 1.8768 15.1332 2.09127C15.222 2.30575 15.2678 2.53562 15.2678 2.76777C15.2678 2.99991 15.222 3.22979 15.1332 3.44426C15.0444 3.65874 14.9142 3.85361 14.75 4.01777L4.33333 14.4344L1 15.2678L1.83333 11.9344L12.25 1.51777Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }
  if (type === 'edit') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='15'
        height='24'
        viewBox='0 0 24 24'
      >
        <path d='M18.311 2.828l2.862 2.861-15.033 15.032-3.583.722.723-3.584 15.031-15.031zm0-2.828l-16.873 16.872-1.438 7.127 7.127-1.437 16.874-16.873-5.69-5.689z' />
      </svg>
    );
  }
  if (type === 'filter') {
    return (
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.6023 18H14.6597V16H10.6023V18ZM3.50195 6V8H21.76V6H3.50195ZM6.54496 13H18.717V11H6.54496V13Z'
          fill='black'
          fillOpacity='0.5'
        />
      </svg>
    );
  }
  if (type === "search") {
    return (
      <svg
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.3983 12.6498C9.97893 13.753 8.19274 14.2734 6.40337 14.1051C4.614 13.9368 2.95601 13.0925 1.76699 11.7441C0.577967 10.3957 -0.0526838 8.64459 0.00344984 6.8473C0.0595835 5.05 0.79828 3.34169 2.06913 2.07019C3.33998 0.798688 5.04742 0.059614 6.8438 0.0034516C8.64017 -0.0527108 10.3904 0.578262 11.7381 1.76789C13.0858 2.95753 13.9297 4.61637 14.0979 6.40665C14.2661 8.19693 13.7459 9.98404 12.6434 11.4041L16.7396 15.4871C17.0863 15.8327 17.0869 16.3941 16.7408 16.7404C16.3946 17.0868 15.8332 17.0865 15.4874 16.7399L11.4071 12.6498H11.3983ZM7.07129 12.3671C7.76707 12.3671 8.45604 12.23 9.09887 11.9636C9.74169 11.6972 10.3258 11.3067 10.8178 10.8145C11.3098 10.3222 11.7 9.73784 11.9663 9.09469C12.2326 8.45154 12.3696 7.76221 12.3696 7.06607C12.3696 6.36993 12.2326 5.6806 11.9663 5.03745C11.7 4.3943 11.3098 3.80991 10.8178 3.31767C10.3258 2.82542 9.74169 2.43495 9.09887 2.16855C8.45604 1.90214 7.76707 1.76503 7.07129 1.76503C5.66608 1.76503 4.31843 2.32353 3.3248 3.31767C2.33117 4.31181 1.77296 5.66015 1.77296 7.06607C1.77296 8.47199 2.33117 9.82033 3.3248 10.8145C4.31843 11.8086 5.66608 12.3671 7.07129 12.3671Z'
          fill='#E7472C'
        />
      </svg>
    );
  }
  if (type === "search-user") {
    return (
      <svg
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.9359 8C16.4986 8 18.5487 10 18.5487 12.5C18.5487 13.38 18.2924 14.21 17.8414 14.9L20.9986 18L19.5737 19.39L16.3756 16.32C15.6683 16.75 14.8277 17 13.9359 17C11.3733 17 9.32317 15 9.32317 12.5C9.32317 10 11.3733 8 13.9359 8ZM13.9359 10C13.2563 10 12.6045 10.2634 12.1239 10.7322C11.6433 11.2011 11.3733 11.837 11.3733 12.5C11.3733 13.163 11.6433 13.7989 12.1239 14.2678C12.6045 14.7366 13.2563 15 13.9359 15C14.6156 15 15.2674 14.7366 15.748 14.2678C16.2286 13.7989 16.4986 13.163 16.4986 12.5C16.4986 11.837 16.2286 11.2011 15.748 10.7322C15.2674 10.2634 14.6156 10 13.9359 10ZM8.29811 0C9.38556 0 10.4285 0.421427 11.1974 1.17157C11.9664 1.92172 12.3983 2.93913 12.3983 4C12.3983 4.91 12.0806 5.75 11.5578 6.43C10.6762 6.75 9.88695 7.26 9.23091 7.9L8.29811 8C7.21066 8 6.16775 7.57857 5.39881 6.82843C4.62987 6.07828 4.19788 5.06087 4.19788 4C4.19788 2.93913 4.62987 1.92172 5.39881 1.17157C6.16775 0.421427 7.21066 0 8.29811 0ZM0.0976562 16V14C0.0976562 11.88 3.49059 10.14 7.78558 10C7.45757 10.78 7.27306 11.62 7.27306 12.5C7.27306 13.79 7.66258 15 8.29811 16H0.0976562Z'
          fill='#828282'
        />
      </svg>
    );
  }
  if (type === "eyeIcon") {
    return (
      <svg
        width='15'
        height='11'
        viewBox='0 0 15 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.5 0.8125C4.375 0.8125 1.70625 2.75625 0.625 5.5C1.70625 8.24375 4.375 10.1875 7.5 10.1875C10.625 10.1875 13.2937 8.24375 14.375 5.5C13.2937 2.75625 10.625 0.8125 7.5 0.8125ZM7.5 8.625C5.775 8.625 4.375 7.225 4.375 5.5C4.375 3.775 5.775 2.375 7.5 2.375C9.225 2.375 10.625 3.775 10.625 5.5C10.625 7.225 9.225 8.625 7.5 8.625ZM7.5 3.625C6.4625 3.625 5.625 4.4625 5.625 5.5C5.625 6.5375 6.4625 7.375 7.5 7.375C8.5375 7.375 9.375 6.5375 9.375 5.5C9.375 4.4625 8.5375 3.625 7.5 3.625Z'
          fill='#D4AF37'
        />
      </svg>
    );
  }

  if (type === "rejectIcon") {
    return (
      <svg
        width='13'
        height='13'
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.5 0.25C3.04375 0.25 0.25 3.04375 0.25 6.5C0.25 9.95625 3.04375 12.75 6.5 12.75C9.95625 12.75 12.75 9.95625 12.75 6.5C12.75 3.04375 9.95625 0.25 6.5 0.25ZM9.625 8.74375L8.74375 9.625L6.5 7.38125L4.25625 9.625L3.375 8.74375L5.61875 6.5L3.375 4.25625L4.25625 3.375L6.5 5.61875L8.74375 3.375L9.625 4.25625L7.38125 6.5L9.625 8.74375Z'
          fill='#FF2500'
        />
      </svg>
    );
  }

  if (type === "approveIcon") {
    return (
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='0.5'
          y='0.5'
          width='24'
          height='24'
          rx='2.5'
          fill='#27AE60'
          fillOpacity='0.07'
          stroke='#27AE60'
        />
        <g clipPath='url(#clip0_1307_20347)'>
          <path
            d='M8.14305 13.0219C8.11306 11.3538 9.52274 10.3332 10.869 9.52111C12.6171 8.52727 14.4961 7.34859 15.1785 5.37443C15.4333 4.70088 15.8029 3.78909 16.6458 4.04373C17.4887 4.36668 17.6821 5.45646 17.5313 6.17359C17.3912 7.16226 16.7417 7.94664 16.1863 8.74081C15.736 9.54923 15.6128 10.8607 15.3377 11.7393C17.3756 11.7503 18.5334 11.6997 20.5701 11.7393C21.2242 12.3806 21.2242 13.6631 19.916 14.3044C20.5701 14.9457 20.5701 16.2283 19.262 16.8696C19.916 17.5109 19.916 18.7935 18.6079 19.4348C19.262 20.0761 19.262 21.3587 17.9539 22H15.3377C13.2854 22 8.16044 21.7771 8.14305 20.7174C8.14307 18.3872 8.14301 15.3521 8.14305 13.0219Z'
            fill='#27AE60'
          />
          <path
            d='M6.88816 21.9995H3V11.7139H6.88816V21.9995Z'
            fill='#27AE60'
          />
        </g>
        <defs>
          <clipPath id='clip0_1307_20347'>
            <rect
              width='18'
              height='18'
              fill='white'
              transform='translate(3 4)'
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (type === "declineIcon") {
    return (
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='0.5'
          y='0.5'
          width='24'
          height='24'
          rx='2.5'
          fill='#FF2500'
          fillOpacity='0.19'
          stroke='#FF2500'
        />
        <path
          d='M8.14305 12.9781C8.11306 14.6462 9.52273 15.6668 10.869 16.4789C12.617 17.4727 14.496 18.6514 15.1784 20.6256C15.4332 21.2991 15.8029 22.2109 16.6457 21.9563C17.4886 21.6333 17.6821 20.5435 17.5312 19.8264C17.3911 18.8377 16.7416 18.0534 16.1863 17.2592C15.736 16.4508 15.6127 15.1393 15.3376 14.2607C17.3755 14.2496 18.5333 14.3003 20.57 14.2607C21.224 13.6194 21.224 12.3368 19.9159 11.6955C20.57 11.0543 20.57 9.77166 19.2619 9.13037C19.9159 8.48907 19.9159 7.20648 18.6078 6.56518C19.2619 5.92389 19.2619 4.64129 17.9538 4H15.3376C13.2854 4 8.16044 4.22291 8.14305 5.28259C8.14307 7.61283 8.14301 10.6479 8.14305 12.9781Z'
          fill='#FF2500'
        />
        <path d='M6.88812 4.00047H3V14.2861H6.88812V4.00047Z' fill='#FF2500' />
      </svg>
    );
  }

  if (type === 'markUp') {
    return (
      <svg
        width='18'
        height='13'
        viewBox='0 0 18 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 1L6 12L1 7'
          stroke='#27AE60'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }

  if (type === 'recall') {
    return (
      <svg
        width='35'
        height='35'
        viewBox='0 0 35 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='35' height='35' rx='3' fill='#FF4B01' fillOpacity='0.16' />
        <rect
          x='0.5'
          y='0.5'
          width='34'
          height='34'
          rx='2.5'
          stroke='#FF4B01'
          strokeOpacity='0.5'
        />
        <path
          d='M18.0006 10.0016C20.1006 10.0016 22.1006 10.8016 23.6006 12.3016C26.7006 15.4016 26.7006 20.5016 23.6006 23.6016C21.8006 25.5016 19.3006 26.2016 16.9006 25.9016L17.4006 23.9016C19.1006 24.1016 20.9006 23.5016 22.2006 22.2016C24.5006 19.9016 24.5006 16.1016 22.2006 13.7016C21.1006 12.6016 19.5006 12.0016 18.0006 12.0016V16.6016L13.0006 11.6016L18.0006 6.60156V10.0016ZM12.3006 23.6016C9.70064 21.0016 9.30064 17.0016 11.1006 13.9016L12.6006 15.4016C11.5006 17.6016 11.9006 20.4016 13.8006 22.2016C14.3006 22.7016 14.9006 23.1016 15.6006 23.4016L15.0006 25.4016C14.0006 25.0016 13.1006 24.4016 12.3006 23.6016Z'
          fill='#FF4B01'
          fillOpacity='0.5'
        />
      </svg>
    );
  }

  if (type === 'editIcon') {
    return (
      <svg
        width='35'
        height='35'
        viewBox='0 0 35 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <rect
          x='0.5'
          y='0.5'
          width='34'
          height='34'
          rx='2.5'
          fill='#0086E8'
          fillOpacity='0.1'
          stroke='#0086E8'
        />
        <path
          d='M24.3906 17.9054L22.3937 19.9364L21.0719 18.5824L13.8437 25.9341L9.53125 27.7076L8.125 26.2773L9.86875 21.8911L17.0969 14.5394L15.7656 13.195L17.7625 11.1639L24.3906 17.9054ZM21.9625 9.59063C23.0594 8.475 24.8406 8.475 25.9375 9.59063C27.0344 10.7063 27.0344 12.5179 25.9375 13.6336L24.1375 15.4643L20.1625 11.4214L21.9625 9.59063ZM11.4625 22.9686L10.4687 25.3238L12.7844 24.3131L19.75 17.2188L18.4375 15.8839L11.4625 22.9686Z'
          fill='#0086E8'
        />
      </svg>
    );
  }
};
